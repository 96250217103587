export const cartDetails = {
  id: 'ID',
  proceedToCheckout: 'Przejdź do kasy',
  createQuoteFromCart: 'Zapytanie ofertowe',
  cartName: 'Koszyk #{{code}}',
  minOrderNotReached: 'Minimalna wartość zamówienia wynosi {{minOrderValue}} {{currency}}.',
  orderLocked: 'Uwaga: W tej chwili nie można składać zamówień! Prosimy o kontakt z działem obsługi klienta. Występują problemy z tym kontem użytkownika.',
  requestedRetrievalDateEmpty: 'Musisz wybrać żądaną datę wysyłki',
  requestedRetrievalDateAdded: 'Pomyślnie dodano żądaną datę wysyłki',
  requestedRetrievalDateInvalid: 'Żądana data wysyłki jest nieprawidłowa',
  s4omScheduleLinesQuantity: 'Ilość',
  s4omScheduleLinesEntryInfo: 'Potwierdzona data wysyłki - {{ quantity }} sztuk - to {{ date }}.',
  cartSubjectToPriceChange: 'Uwaga: Ceny nowo skonfigurowanych urządzeń są cenami katalogowymi. Cenę specyficzną dla klienta otrzymasz wraz z formalnym potwierdzeniem zamówienia.'
};

export const cartItems = {
  id: 'Numer artykułu',
  description: 'Opis',
  item: 'Pozycja',
  itemPrice: 'Cena artykułu',
  quantity: 'Ilość',
  equipmentNumber: 'Numer sprzętu: ',
  quantityTitle: 'Ilość reprezentuje całkowitą liczbę tego przedmiotu w koszyku.',
  total: 'Łącznie',
  cartTotal: 'Suma w koszyku ({{count}} przedmiotów)',
  cartTotal_plural: 'Suma w koszyku ({{count}} przedmiotów)',
  actions: 'Działania',
  salesUnit: 'UOM',
  reference: 'Odniesienie:',
  referencePlaceHolder: 'Odniesienie',
  additionalContactEmail: 'Dodatkowy kontaktowy adres e-mail:',
  additionalContactEmailPlaceHolder: 'Dodatkowy kontaktowy adres e-mail',
  comment: 'Komentarz:',
  commentPlaceHolder: 'Komentarz',
  additionalContactEmailWarning: 'Wprowadź prawidłowy adres e-mail. (Nieprawidłowe wiadomości e-mail nie zostaną zapisane). ',
  requestedDeliveryDate: 'Żądana data wysyłki/odbioru:',
  requestedDeliveryDateTitle: 'Żądana data wysyłki/odbioru',
  substitutionProductNotFound: 'Ten produkt został zastąpiony innym produktem, ale produkt zastępczy nie jest obecnie dostępny.',
};

export const orderCost = {
  orderSummary: 'Podsumowanie zamówienia',
  subtotal: 'Suma częściowa:',
  shipping: 'Wysyłka:',
  estimatedShipping: 'Szacowana Wysyłka:',
  discount: 'Zaoszczędzono:',
  salesTax: 'Podatek od sprzedaży:',
  grossTax: 'Suma zamówienia nie zawiera podatku w wysokości',
  grossIncludeTax: 'Suma zamówienia zawiera podatek w wysokości',
  total: 'Razem:',
  toBeDetermined: 'TBD',
  requestedDeliveryDate: 'Żądana data wysyłki/odbioru*',
  taxStatement: '*Wszystkie podane ceny są specyficznymi dla klienta cenami netto bez podatków. Użytkownik zostanie poinformowany o ostatecznej łącznej cenie netto z podatkami w wiadomości e-mail z potwierdzeniem zamówienia, ponieważ codziennie aktualizowane ceny netto są przeliczane w momencie składania zamówienia.',
  taxStatement2: 'W przypadku zamawiania akcesoriów lub części zamiennych może zostać naliczona dopłata za minimalną ilość, jeśli wartość netto bez podatku VAT nie osiągnie minimalnej wartości zamówienia.',
}

export const voucher = {
  coupon: 'Masz kupon?',
  coupon_plural: 'Kody kuponów',
  apply: 'Zastosuj',
  placeholder: 'Kod promocyjny',
  applyVoucherSuccess: '{{voucherCode}} został zastosowany.',
  removeVoucherSuccess: '{{voucherCode}} został usunięty.',
  anchorLabel: 'Wprowadź lub usuń kod kuponu',
  vouchersApplied: 'Zastosowane kupony',
  availableCoupons: 'Dostępne kupony',
  availableCouponsLabel: 'Możesz dodać te kupony do tego zamówienia.',
}

export const saveForLaterItems = {
  itemTotal: 'Zapisane na później ({{count}} elementów)',
  itemTotal_plural: 'Zapisane na później ({{count}} elementów)',
  cartTitle: 'Koszyk',
  saveForLater: 'Zapisz na później',
  moveToCart: 'Przenieś do koszyka',
  stock: 'Stan magazynowy',
  forceInStock: 'W magazynie',
}

export const cart = {
  cartDetails,
  cartItems,
  orderCost,
  voucher,
  saveForLaterItems
}
