import { Injectable } from "@angular/core";
import { ProductData } from '@spartacus/cart/base/root';
import { ImportProductsFromCsvService } from "@spartacus/cart/import-export/components";
import { FileValidationErrors } from "./import-file-services/rational-import-file.service";

@Injectable({
    providedIn: 'root'
})
export class RationalImportProductsFromFileService extends ImportProductsFromCsvService {

    constructor() {
        super();
    }

    /**
     * Validates data against following criteria
     * 
     * The file must include 2 columns - If not, an error message will be shown and the file will not be importet
     * The quantity column is mandatory - if it's left empty the line is considered invalid and an error message is shown
     * The quantity column must include an integer - if it has the wrong format the line is considered invalid and an error message is shown
     * The article number is mandatory - if it's left empty the line is considered invalid and an error message is shown
     * 
     * example data payload:
     * 
     * [
     *       [
     *           "2";
     *           "CB1GRRA.0000464\r"
     *      ],
     *      [
     *          "1";
     *          "CD1ERRA.0000001\r"
     *      ]
     * ]
     * 
     * @param data 
     * @returns boolean
     */
    isDataParsableToValidProducts(data: string[][], errors: FileValidationErrors): boolean {
        const decimalsRegex = new RegExp(/^[1-9]\d*(\.0+)?$/);
        let invalidQunatityLines = "";
        let missingArticleNumberLines = "";

        try {
            let successfulLoaded = 0;

            // for (let fields of data) {
            data.forEach((fields, i) => {
                const quantity: string = fields[0]
                const productCode: string = fields[1]
                const lineNumber = i + 1;

                if (!decimalsRegex.test(quantity)) {
                    invalidQunatityLines = (invalidQunatityLines.length == 0 ? "" : invalidQunatityLines + ", ") + lineNumber;
                    errors.invalidQunatity = { indexes: invalidQunatityLines }
                }
                if (productCode.trim().length < 1) {
                    missingArticleNumberLines = (missingArticleNumberLines.length == 0 ? "" : missingArticleNumberLines + ", ") + lineNumber;
                    errors.missingArticleNumber = { indexes: missingArticleNumberLines }
                }
                successfulLoaded++;

            });
            return data.length == successfulLoaded;
        } catch (error) {
            errors.notParsable = true;
            errors.importCartReopenDialogueText = true
            return false;
        }
    }

    override csvDataToProduct(csvData: string[][]): ProductData[] {
        return csvData.map((row: string[]) => ({
            productCode: row[1].replace(/(\r\n|\n|\r)/gm, ""),
            quantity: Number(row[0]),
        }));
    }



}