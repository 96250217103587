/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Optional,
} from '@angular/core';
import {
  UntypedFormBuilder,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutConfigService, CheckoutDeliveryModeComponent, CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import { RationalCartItemUpdateService } from '../../cart/base/cart-item/rational-cart-item-list-row/rational-cart-item-update.service';
import { FeatureConfigService } from '@spartacus/core';
import { filter, take } from 'rxjs';

@Component({
  selector: 'cx-delivery-mode',
  templateUrl: './checkout-delivery-mode.component.html',
  styleUrls: ['./checkout-delivery-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCheckoutDeliveryModeComponent extends CheckoutDeliveryModeComponent {

  constructor(
    protected override fb: UntypedFormBuilder,
    protected override checkoutConfigService: CheckoutConfigService,
    protected override activatedRoute: ActivatedRoute,
    protected override checkoutStepService: CheckoutStepService,
    protected override checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected rationalCartItemUpdateService : RationalCartItemUpdateService,
    protected override activeCartFacade: ActiveCartFacade
  ) {
    super(fb, checkoutConfigService, activatedRoute, checkoutStepService, checkoutDeliveryModesFacade, activeCartFacade);
  }

  @Optional() override featureConfigService = inject(FeatureConfigService, {
    optional: true,
  });

  cart$ = this.activeCartFacade.getActive();

  override changeMode(code: string | undefined, event?: Event): void {
    if (!code) {
      return;
    }
    const lastFocusedId = (<HTMLElement>event?.target)?.id;
    this.busy$.next(true);
    this.checkoutDeliveryModesFacade.setDeliveryMode(code).subscribe({
      complete: () => this.onSuccess(),
      error: () => this.onError(),
    });

    if (this.featureConfigService?.isEnabled('a11yCheckoutDeliveryFocus')) {
      const isTriggeredByKeyboard = (<MouseEvent>event)?.screenX === 0;
      if (isTriggeredByKeyboard) {
        this.restoreFocus(lastFocusedId, code);
        return;
      }
      this.mode.setValue({ deliveryModeId: code });
    }
  }

  protected override restoreFocus(lastFocusedId: string, code: string): void {
    this.isUpdating$
      .pipe(
        filter((isUpdating) => !isUpdating),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          document.querySelector('main')?.classList.remove('mouse-focus');
          this.mode.setValue({ deliveryModeId: code });
          document.getElementById(lastFocusedId)?.focus();
        }, 0);
      });
  }

  updateCartSpecialShippingInstructions(shippingAdditionalInfo: string) {
    this.rationalCartItemUpdateService.updateCartSpecialShippingInstructions(shippingAdditionalInfo).subscribe();
  }
}
