import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalAddedToCartDialogComponent } from './rational-added-to-cart-dialog.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { UrlModule, I18nModule, CmsConfig, provideConfig } from '@spartacus/core';
import { SpinnerModule, PromotionsModule, IconModule, ItemCounterModule, KeyboardFocusModule, DIALOG_TYPE } from '@spartacus/storefront';
import { RationalCartSharedModule } from '../../cart-item/rational-cart-shared.module';



@NgModule({
  declarations: [RationalAddedToCartDialogComponent],
  exports: [
    RationalAddedToCartDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SpinnerModule,
    PromotionsModule,
    UrlModule,
    IconModule,
    I18nModule,
    ItemCounterModule,
    KeyboardFocusModule,
    RationalCartSharedModule
  ], 
  providers: [
    provideConfig(<CmsConfig>{
      launch: {
        ADDED_TO_CART: {
          inlineRoot: true,
          component: RationalAddedToCartDialogComponent,
          dialogType: DIALOG_TYPE.DIALOG,
        },
      }
    })
  ]
})
export class RationalAddedToCartDialogModule { }
