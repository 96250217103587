<h2 class="cx-checkout-title d-none d-lg-block d-xl-block">
  {{ 'checkoutB2B.methodOfPayment.paymentType' | cxTranslate }}
</h2>

<ng-container *ngIf="paymentTypes$ | async as paymentTypes">
  <ng-container
    *ngIf="
      !!paymentTypes.length &&
        (typeSelected$ | async) &&
        !(isUpdating$ | async);
      else loading
    "
  >

    <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>

    <form #formCtrl="ngForm" [formGroup]="paymentForm">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <label>
            <span class="label-content">{{
              'checkoutB2B.poNumber' | cxTranslate
            }}</span>
            <input
              #poNumber
              required
              class="form-control"
              name="poNumber"
              autocomplete="off"
              formControlName="poNumber"
              type="text"
              placeholder="{{ 'checkoutB2B.placeholder' | cxTranslate }}"
              value="{{ cartPoNumber$ | async }}"
              (blur)="hideSuggestedPO();"
              (input)="onPoNumberInput($event)"
              (focus)="showAllSuggestedPONumbers()"
            />
          </label>
          <div *ngIf="filteredPONumbers.length && (showSuggestions | async)" class="autocomplete-items" style="width:100%">
            <div
              *ngFor="let sugPONumber of filteredPONumbers"
              (click)="selectPONumber(sugPONumber);"
              (pointerdown)="selectPONumber(sugPONumber)"
              class="autocomplete-item"
            >
              {{ sugPONumber }}
            </div>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="col-md-12 col-lg-6">
          <label class="cx-payment-type-container">
            <span class="label-content">{{
              'paymentTypes.title' | cxTranslate
            }}</span>
            <div class="form-check" *ngFor="let type of paymentTypes">
              <input
                id="paymentType-{{ type.code }}"
                class="form-check-input"
                role="radio"
                type="radio"
                aria-checked="true"
                (change)="changeType(type.code ?? '')"
                [value]="type.code"
                [checked]="type.code === typeSelected"
              />
              <label
                class="cx-payment-type-label form-check-label form-radio-label"
                for="paymentType-{{ type.code }}"
              >
                <div class="cx-payment-type">
                  {{ 'paymentTypes.paymentType_' + type?.code | cxTranslate }}
                </div>
              </label>
            </div>
          </label>
        </div>
      </div>
    </form>

    <rational-incoterms-and-payment-conditions></rational-incoterms-and-payment-conditions>

    <div class="cx-checkout-btns row">
      <div class="col-md-12 col-lg-6">
        <button class="btn btn-block btn-secondary" (click)="back()">
          {{ 'checkout.backToCart' | cxTranslate }}
        </button>
      </div>
      <div class="col-md-12 col-lg-6">
        <button class="btn btn-block btn-primary" (click)="next()" [disabled]="!formCtrl.form.valid">
          {{ 'common.continue' | cxTranslate }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="cx-spinner" *ngIf="!paymentTypesError">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>

