import {Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class RationalAutoCompleteService {

  private userEnteredValuesMap: { [key: string]: string[] } = {};

  loadUserEnteredValues(key: string): void {
    const name = 'userEnteredValues_'.concat(key);
    const savedValues = localStorage.getItem(name);
    if (savedValues) {
      this.userEnteredValuesMap[key] = JSON.parse(savedValues);
    } else {
      this.userEnteredValuesMap[key] = [];
    }
  }

  saveUserEnteredValues(key: string): void {
    const name = 'userEnteredValues_'.concat(key);
    localStorage.setItem(name, JSON.stringify(this.userEnteredValuesMap[key]));
  }

  addUserEnteredValue(key: string, value: string): void {
    if (value && !this.userEnteredValuesMap[key].includes(value)) {
      this.userEnteredValuesMap[key].push(value);
      this.saveUserEnteredValues(key);
    }
  }

  getFilteredSuggestions(key: string, value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.userEnteredValuesMap[key].filter(enteredValue =>
      enteredValue.toLowerCase().includes(filterValue)
    );
  }

  getAllSuggestions(key: string): string[] {
    return [...this.userEnteredValuesMap[key]];
  }


}
