export const pageMetaResolver = {
    category: {
      title: '{{count}} sonucu için {{query}}',
      title_plural: '{{count}} sonuçları için {{query}}',
    },
    checkout: {
      title: 'Ödeme',
    },
    search: {
      title: "'{{query}}' için {{count}} sonucu",
      title_plural: "'{{query}}' için {{count}} sonuçları",
      findProductTitle: "{{count}} kupon için sonuç '{{coupon}}'",
      findProductTitle_plural: "{{count}} kupon için sonuç '{{coupon}}'",
      default_title: 'Tüm ürünler',
    },
    product: {
      description: '{{description}}',
      heading: '{{heading}}',
      title: '{{title}}',
    },
  };
  
  export const spinner = {
    loading: 'Yükleniyor...',
  };
  
  export const navigation = {
    shopAll: 'Tümünü satın alın {{ navNode}} >',
    listPrice: {
      enableListPrice: 'Liste Fiyatını Etkinleştir',
      listPriceIsEnabled: 'Liste Fiyatı etkinleştirildi',
      listPriceIsDisabled: 'Liste Fiyatı devre dışı',
    },
  };
  
  export const searchBox = {
    placeholder: 'Burayı arayın...',
    ariaLabelInput: 'Burada arama yapın...',
    ariaLabelSuggestions: 'yazim öneri̇leri̇',
    ariaLabelProducts: 'ürün sonuçlari',
    initialDescription:
      'Otomatik tamamlama sonuçları mevcut olduğunda, incelemek için yukarı ve aşağı okları kullanın ve seçmek için enter tuşuna basın. Dokunmatik cihaz kullanıcıları, dokunarak veya kaydırma hareketleriyle keşfedin.',
    suggestionsResult: '{{ count }} öneri ve',
    suggestionsResult_plural: '{{ count }} öneriler ve',
    productsResult: '{{ count }} ürün mevcut.',
    productsResult_plural: '{{ count }} ürünleri mevcuttur.',
    resetLabel: 'Aramayı Sıfırla',
    help: {
      insufficientChars: 'Lütfen daha fazla karakter yazın',
      noMatch: 'Herhangi bir sonuç bulamadık',
      exactMatch: '{{ term }}',
      empty: 'Bize her şeyi sorun',
    },
  };
  
  export const sorting = {
    date: 'Tarih',
    orderNumber: 'Sipariş Numarası',
    rma: 'İade Numarası',
    replenishmentNumber: 'İkmal Numarası',
    nextOrderDate: 'Sonraki Sipariş Tarihi',
  };
  
  export const httpHandlers = {
    badGateway: 'Bir sunucu hatası oluştu. Lütfen daha sonra tekrar deneyin.',
    badRequestPleaseLoginAgain: '{{ errorMessage }}. Lütfen tekrar giriş yapın.',
    badRequestOldPasswordIncorrect: 'Eski şifre yanlış.',
    badRequestGuestDuplicateEmail:
      '{{ errorMessage }} e-posta zaten mevcut. Misafir hesabı kullanarak kaydolmak için lütfen farklı bir e-posta ile ödeme yapın.',
    conflict: 'Zaten var.',
    forbidden:
      'Bu işlemi gerçekleştirme yetkiniz yok. Bunun bir hata olduğunu düşünüyorsanız lütfen yöneticinize başvurun.',
    gatewayTimeout: 'Sunucu yanıt vermedi, lütfen daha sonra tekrar deneyin.',
    internalServerError:
      'Bir Dahili Sunucu Hatası oluştu. Lütfen daha sonra tekrar deneyin.',
    sessionExpired: 'Oturumunuzun süresi doldu. Lütfen tekrar giriş yapın.',
    unknownError: 'Bilinmeyen bir hata oluştu',
    unauthorized: {
      common: 'Bilinmeyen bir yetkilendirme hatası oluştu',
      invalid_client: 'Kötü istemci kimlik bilgileri',
    },
    validationErrors: {
      missing: {
        card_cardType:
          'Seçilen kredi kartı desteklenmiyor. Lütfen başka bir kart seçin.',
        card_accountNumber:
          'Girilen kredi kartı numarası geçerli değil.',
        card_cvNumber: 'Girilen güvenlik kodu geçerli değil.',
        card_expirationMonth:
          'Girilen kredi kartı son kullanma tarihi geçerli değil.',
        card_expirationYear:
          'Girilen kredi kartı son kullanma tarihi geçerli değil.',
        billTo_firstName: 'Girilen ad geçerli değildir',
        billTo_lastName: 'Girilen soyadı geçerli değil.',
        billTo_street1: 'Girilen adres geçerli değil.',
        billTo_street2: 'Girilen adres geçerli değil.',
        billTo_city:
          'Girilen şehir bu kredi kartı için geçerli değil.',
        billTo_state:
          'Girilen eyalet/il bu kredi kartı için geçerli değil.',
        billTo_country:
          'Girilen ülke/bölge bu kredi kartı için geçerli değil.',
        billTo_postalCode:
          'Girmiş olduğunuz kredi kartınız belirttiğiniz posta kodunda kullanılabilir değiil.',
        country: {
          isocode: 'Eksik ülke',
        },
      },
      invalid: {
        card_expirationMonth:
          'Girilen kredi kartı son kullanma tarihi geçerli değil.',
        firstName: 'Girilen Ad geçerli değil.',
        lastName: 'Girilen Soyadı geçerli değil.',
        password: 'Girilen şifre geçerli değil.',
        uid: 'UID geçerli değil.',
        code: 'Kod geçerli değil.',
        email: 'E-posta geçerli değil.',
      },
    },
    cartNotFound: 'Sepet bulunamadı.',
    invalidCodeProvided: 'Geçersiz kod sağlandı.',
  };
  
  export const miniCart = {
    item: '{{count}} ürün şu anda sepetinizde',
    item_plural: '{{count}} şu anda sepetinizde bulunan ürünler',
    total: '{{total}}',
    count: '{{count}}',
  };
  
  export const skipLink = {
    skipTo: 'Şuraya atla',
    labels: {
      header: 'Başlık',
      main: 'Ana İçerik',
      footer: 'Altbilgi',
      productFacets: 'Ürün Yönleri',
      productList: 'Ürün Listesi',
    },
  };
  
  export const formErrors = {
    globalMessage: 'Göndermeye çalıştığınız form hata içeriyor.',
    required: 'Bu alan zorunludur',
    cxInvalidEmail: 'Bu geçerli bir e-posta biçimi değildir',
    cxInvalidPassword: 'Bu geçerli bir parola biçimi değildir',
    cxPasswordsMustMatch: 'Parola alanları eşleşmelidir',
    cxEmailsMustMatch: 'E-posta alanları eşleşmelidir',
    cxStarRatingEmpty: 'Derecelendirme alanı zorunludur',
    cxNoSelectedItemToCancel: 'En az bir öğe seçin',
    cxNegativeAmount: 'Tutar sıfıra eşit veya sıfırdan büyük olmalıdır',
    cxContainsSpecialCharacters: 'Alan özel karakterler içeremez',
    date: {
      required: 'Bu alan zorunludur',
      min: "Tarih {{min}}'den önce olamaz",
      max: 'Tarih {{max}} değerinden sonra olamaz',
      pattern: 'Yyyy-aa-gg tarih biçimini kullanın',
      invalid: 'Geçerli bir tarih kullanın',
    },
    file: {
      invalidQunatity:
        'Satırlardaki ürün için geçersiz miktar: {{ indexes }}',
      missingArticleNumber: 'Satırlarda eksik madde numarası: {{ indexes }}',
      importCartReopenDialogueText:
        'Lütfen sepet içe aktarma diyaloğunu kapatın, ardından dosyanızı güncelleyin ve yüklemeyi yeniden deneyin.',
    },
  };
  
  export const errorHandlers = {
    scriptFailedToLoad: 'Komut dosyası yüklenemedi.',
    refreshThePage: 'Lütfen sayfayı yenileyin.',
  };
  
  export const common = {
    pageMetaResolver,
    spinner,
    navigation,
    searchBox,
    sorting,
    httpHandlers,
    miniCart,
    skipLink,
    formErrors,
    errorHandlers,
    common: {
      cancel: 'İptal',
      delete: 'Silme',
      remove: 'Kaldırmak',
      edit: 'Düzenle',
      restore: 'Geri Yükleme',
      back: 'Geri',
      submit: 'Gönder',
      continue: 'Devam et',
      save: 'Kaydet',
      done: 'Bitti',
      home: 'Ev',
      noData: 'Veri yok.',
      confirm: 'Onaylayın',
      more: 'daha fazla',
      close: 'Kapat',
      loading: 'Yükleniyor',
      menu: 'Menü',
      reset: 'Sıfırla',
      search: 'Arama',
      add: 'Ekle',
      userGreeting: 'Hoş geldiniz',
      privacySettings: 'Gizlilik Ayarları',
    },
  };
  