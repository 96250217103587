import { Injectable } from "@angular/core";
import { Command, CommandService, CommandStrategy, EventService, UserIdService } from "@spartacus/core";
import { OrderService } from "@spartacus/order/core";
import { Order, OrderPlacedEvent } from "@spartacus/order/root";
import { switchMap, tap } from "rxjs/operators";
import { RationalOrderConnector } from "./checkout-place-order.connector";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { Observable } from "rxjs";
import { RationalOrderFacade } from "./checkout-place-order.facade";

@Injectable({
    providedIn: 'root',
})
export class RationalOrderService extends OrderService implements RationalOrderFacade {

    constructor(
        protected override activeCartFacade: ActiveCartFacade,
        protected override userIdService: UserIdService,
        protected override commandService: CommandService,
        protected override orderConnector: RationalOrderConnector,
        protected override eventService: EventService) {
        super(activeCartFacade, userIdService, commandService, orderConnector, eventService);
    }

    protected placeQuoteOrderCommand: Command<boolean, Order> =
    this.commandService.create<boolean, Order>(
      (payload) =>
        this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) =>
            this.orderConnector.placeQuoteOrder(userId, cartId, payload).pipe(
              tap((order) => {
                
                this.placedOrder$.next(order);
                this.eventService.dispatch(
                  {
                    userId,
                    cartId,
                    /**
                     * As we know the cart is not anonymous (precondition checked),
                     * we can safely use the cartId, which is actually the cart.code.
                     */
                    cartCode: cartId,
                    order,
                  },
                  OrderPlacedEvent
                );
              })
            )
          )
        ),
      {
        strategy: CommandStrategy.CancelPrevious,
      }
    );

    placeQuoteOrder(termsChecked: boolean): Observable<Order> {
        return this.placeQuoteOrderCommand.execute(termsChecked);
      }
}
