import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CmsComponentData, ICON_TYPE, NavigationNode, NavigationService} from "@spartacus/storefront";
import {Observable} from "rxjs";
import {CmsNavigationComponent} from "@spartacus/core";
import {map} from "rxjs/operators";

@Component({
  selector: 'custom-header-links',
  templateUrl: './header-links.component.html',
  styleUrls: ['./header-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLinksComponent {
  iconTypes = ICON_TYPE;
  
  node$: Observable<NavigationNode> = this.service.createNavigation(
    this.componentData.data$
  );

  styleClass$: Observable<string | undefined> = this.componentData.data$.pipe(
    map((d) => d?.styleClass)
  );

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService
  ) {}

}
