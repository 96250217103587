export const orderDetails = {
  statusDisplay_open: 'Not yet processed',
  statusDisplay_processing: 'Partially processed',
  statusDisplay_completed: 'Completely processed',
  purchaseOrderNumber: 'PO Number',
  orderNumber: 'RATIONAL Order Number',
  methodOfPayment: 'Method of Payment',
  shippingMethod: 'Shipping Method',
  placedOn: 'Placed on',
  moreInformation: 'More Information',
  orderDocuments: 'Order Documents',
  billing: 'Invoice',
  invoiceNumber: 'Invoice Number',
  invoiceDate: 'Date',
  netAmount: 'Net Amount',
  noBillingInvoices: 'No billing information available yet.',
  noOrderDocuments: 'No order documents available yet.',
  noInvoiceDocuments:'No invoice documents available yet.',
  noShipmentDocuments: 'No shipping documents available yet.',
  shipping: {
    shipping: 'Shipping',
    shippingId: 'Shipping Id',
    deliveryDate: 'Dispatch/Pickup Date',
    quantity: 'Quantity',
    tracking: 'Tracking',
    noShippingInfo: 'No shipping information available yet.',
    noTrackingInfo: 'No tracking information available yet.'
  },
}

export const orderHistory = {
  orderHistory: 'Order history',
  orderId: 'Order number',
  date: 'Date',
  status: 'Status',
  PONumber: 'P.O. number',
  total: 'Total',
  noOrders: 'We don\'t have order data for this account yet.',
  startShopping: 'Start Shopping',
  sortBy: 'Sort by',
  sortOrders: 'Sort Orders',
  purchaseOrderNumber: 'P.O. number',
  notFound: 'No Orders Found',
  actions: 'Actions',
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'ALL ORDERS ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
