import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthGuard, CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { OutletModule, PromotionsModule, CardModule } from '@spartacus/storefront';
import { OrderConfirmationGuard, OrderDetailsService } from '@spartacus/order/components';
import { RationalOrderOverviewComponent } from './order-overview.component';
import { OrderFacade } from '@spartacus/order/root';
import { RationalOrderDocumentModule } from '../order-document/rational-order-document.module';

@NgModule({
  imports: [CommonModule, PromotionsModule, I18nModule, OutletModule, CardModule, RationalOrderDocumentModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OrderOverviewComponent: {
          component: RationalOrderOverviewComponent,
          guards: [OrderConfirmationGuard],
        },

        OrderConfirmationOverviewComponent: {
            component: RationalOrderOverviewComponent,
            providers: [
              {
                provide: OrderDetailsService,
                useExisting: OrderFacade,
              },
            ],
            guards: [OrderConfirmationGuard],
          },
      },
    }),
  ],
  declarations: [RationalOrderOverviewComponent],
  exports: [RationalOrderOverviewComponent],
})
export class RationalOrderOverviewModule {}
