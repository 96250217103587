<td role="cell">
    <div class="cx-table-item-container">
      <!-- Item Image -->
      <a
        [routerLink]="{ cxRoute: 'product', params: cartEntry.product } | cxUrl"
        tabindex="-1"
      >
        <cx-media
          [container]="cartEntry.product?.images?.['PRIMARY']"
          format="thumbnail"
        ></cx-media>
      </a>
      <div class="cx-info">
        <div *ngIf="cartEntry.product?.name" class="cx-name">
          <a
            class="cx-link"
            [routerLink]="
              { cxRoute: 'product', params: cartEntry.product } | cxUrl
            "
            >{{ cartEntry.product?.name }}</a
          >
        </div>
        <div class="listDescription" [innerHTML]="cartEntry.product?.description"></div>
        <div *ngIf="cartEntry.product?.code" class="cx-code">
          {{ 'cartItems.id' | cxTranslate }} {{ cartEntry.product?.code }}
        </div>
      </div>
    </div>
  </td>
  
  <!-- Action -->
  <td role="cell" class="cx-actions">
    <ng-container
      *ngIf="cartEntry.updateable"
      cxInnerComponentsHost
    ></ng-container>
    <ng-template #outOfStock>
      <span class="cx-out-of-stock">
        {{ 'addToCart.outOfStock' | cxTranslate }}
      </span>
    </ng-template>
    <button
      *ngIf="cartEntry.updateable"
      (click)="removeEntry(cartEntry)"
      [cxAtMessage]="'wishlist.itemRemoved' | cxTranslate"
      [disabled]="isLoading"
      class="link cx-action-link cx-remove-btn"
    >
      {{ 'common.remove' | cxTranslate }}
    </button>
  </td>
  