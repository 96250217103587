import { Injectable } from '@angular/core';
import { ImportCsvFileService } from '@spartacus/storefront';


@Injectable({
  providedIn: 'root',
})
export class RationalImportCsvFileService extends ImportCsvFileService {


  public override parse(
    csvString: string,
    separator: string,
    ignoreHeader = false
  ): string[][] {
    return csvString
      .split('\r\n')
      .filter(
        (str) =>
          !(str === null || str.match(/^ *$/) !== null)
      )
      .map((row) => row.split(separator).map((cell) => cell.replace(/"/g, '')))
      .filter(
        (value, index) => !(ignoreHeader && index === 0)
      );
  }
}
