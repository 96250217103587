export const organization = { 
    userRoles: {
      b2bcustomergroup: '顧客',
      b2bapprovergroup: '承認者',
      b2bmanagergroup: 'マネージャー',
      b2badmingroup: '管理者'
    },
    breadcrumb: '組織',
    notification: {
      noSufficientPermissions: 'このページにアクセスする権限がありません',
      notExist: 'この項目は存在しません',
      disabled: '無効な項目は編集できません'
    },
    confirmation: {
      cancel: 'キャンセル',
      confirm: '確認',
      disable: '無効',
      delete: '削除'
    },
    httpHandlers: {
      conflict: {
        budget: 'コード {{ code }} の予算は既に存在します。',
        costCenter: 'コード {{ code }} のコストセンターはすでに存在します。',
        unit: 'uid {{ code }} を持つ組織は既に存在します。',
        user: '電子メール {{ code }} を持つユーザーは既に存在します。',
        userGroup: 'id {{ code }}を持つユーザグループは既に存在します。',
        permission: 'コード {{ code }} を持つ承認権限が既に存在します。',
        unknown: 'サーバ検証エラー'
      }
    }
};
