import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CartQuickOrderFormComponent } from '@spartacus/cart/quick-order/components';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { EventService, GlobalMessageService, GlobalMessageType, UserIdService } from '@spartacus/core';
import { BehaviorSubject } from 'rxjs';
import { RationalAutoCompleteService } from '../../../services/rational-auto-complete-service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'cx-cart-quick-order-form',
  templateUrl: './rational-cart-quick-order-form.component.html',
  styleUrls: ['./rational-cart-quick-order-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCartQuickOrderFormComponent extends CartQuickOrderFormComponent implements OnInit {

  public filteredProductCodes: string[] = [];
  private key = 'quickOrderProductCode';
  showSuggestions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private hideSuggestionsTimeout: any;
  private userId = '';
  savedCart: boolean;
  private cartId = '';

  constructor(
    protected override activeCartService: ActiveCartFacade,
    protected override eventService: EventService,
    protected override formBuilder: UntypedFormBuilder,
    protected override globalMessageService: GlobalMessageService,
    private autoCompleteService: RationalAutoCompleteService,
    private multiCartService: MultiCartFacade,
    private route: ActivatedRoute,
    private userIdService: UserIdService
  ) {
    super(activeCartService, eventService, formBuilder, globalMessageService);
    this.autoCompleteService.loadUserEnteredValues(this.key);
    this.savedCart = false;
  }

  override ngOnInit(): void {
    this.cartId = this.route.snapshot.paramMap.get('savedCartId')!;
    if (this.cartId?.length === 0 || this.cartId === null) {
      this.savedCart = false;
    }
    else {
      this.savedCart = true;
    }
    super.ngOnInit();
  }

  onProductCodeInput(event: Event): void {
    const input = event.target as HTMLInputElement; // Cast event.target as HTMLInputElement
    const value = input.value;

    if (value.length > 1) {
      this.filteredProductCodes = this.autoCompleteService.getFilteredSuggestions(this.key, value);
      this.showSuggestions.next(true);
    } else {
      this.filteredProductCodes = [];
      this.showSuggestions.next(false);
    }

  }

  // Handle selection of a suggestion
  selectSuggestion(suggestion: string): void {
    this.quickOrderForm.controls['productCode'].setValue(suggestion);
  }

  // Hide suggestions when the input loses focus
  hideSuggestions(): void {
    clearTimeout(this.hideSuggestionsTimeout);
    this.hideSuggestionsTimeout = setTimeout(() => {
      this.filteredProductCodes = [];
      this.showSuggestions.next(false);
    }, 200);
  }

  showAllSuggestions(): void {
    this.filteredProductCodes = this.autoCompleteService.getAllSuggestions(this.key);
    this.showSuggestions.next(true);// Show all suggestions
  }

  override applyQuickOrder(): void {
      if (this.quickOrderForm.invalid) {
        this.quickOrderForm.markAllAsTouched();
        return;
      }

      let productCode = this.quickOrderForm.get('productCode')?.value;
      const quantity = this.quickOrderForm.get('quantity')?.value;

      //save productCode value in local storage to be used after that in auto complete
      this.autoCompleteService.addUserEnteredValue(this.key, productCode);

      this.watchAddEntrySuccessEvent();

      if ((this.cartId?.length === 0 || this.cartId === null) && productCode && quantity) {
        this.activeCartService.addEntry(productCode, quantity);
      }

      else if (productCode && quantity) {
        productCode = productCode.toUpperCase();
        this.userIdService.takeUserId().subscribe(userId => {
          this.userId = userId
        });
        this.multiCartService.addEntry(this.userId, this.cartId!, productCode, quantity);
        this.resetForm();
        this.multiCartService.getEntry(this.cartId!, productCode).subscribe(data => {
          const productName = data?.product?.name
          if(data !== undefined) {
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_CONFIRMATION)
            this.globalMessageService.add(
              { key: 'savedCartDetails.productAddedToCart',
                params: {
                  product: productName,
                },
              },
              GlobalMessageType.MSG_TYPE_CONFIRMATION);
          }
        })
      }
    }

}
