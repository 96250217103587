import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Region,
  Address,
  GlobalMessageService,
  TranslationService,
  UserAddressService
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { AddressFormComponent } from "@spartacus/user/profile/components";
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, switchMap, tap, BehaviorSubject, map } from 'rxjs';
@Component({
  selector: 'rational-ckeckout-address-form',
  styleUrls: ['./rational-checkout-address-form.component.scss'],
  templateUrl: './rational-checkout-address-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCheckoutAddressFormComponent extends AddressFormComponent {
  override regions$!: Observable<Region[]>;
  override selectedCountry$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() set asyncAddressData(value: Address | null) {
    if (value != null) {
      this.addressData = value;
      this.populateAddressData();
    }
  }

  constructor(
    protected override fb: UntypedFormBuilder,
    protected override userAddressService: UserAddressService,
    protected override globalMessageService: GlobalMessageService,
    protected override translation: TranslationService,
    protected override launchDialogService: LaunchDialogService,
    protected override userProfileFacade: UserProfileFacade
  ) {
    super(
      fb,
      userAddressService,
      globalMessageService,
      translation,
      launchDialogService,
      userProfileFacade
    );
  }
  override ngOnInit() {
    super.ngOnInit();
    // Fetching regions override to add empty region
    this.regions$ = this.selectedCountry$.pipe(
      switchMap((country) => this.userAddressService.getRegions(country)),
      switchMap((regions: Region[]) =>
        this.translation.translate('addressForm.selectOne').pipe(
          map((translatedName: string) => {
            const emptyRegion: Region = { isocode: '', name: translatedName };
            return [emptyRegion, ...regions];
          })
        )
      ),
      tap((regions: Region[]) => {
        const regionControl = this.addressForm.get('region.isocode');
        if (regions && regions.length > 0) {
          regionControl?.enable();
        } else {
          regionControl?.disable();
        }
      })

    );

  }

  override addressForm: UntypedFormGroup = this.fb.group({
    country: this.fb.group({
      isocode: [null, Validators.required],
    }),
    titleCode: [null],
    companyName: ['', Validators.required],
    firstName: [''],
    lastName: [''],
    line1: ['', Validators.required],
    line2: [''],
    town: ['', Validators.required],
    region: this.fb.group({
      isocode: [null],
    }),
    postalCode: ['', Validators.required],
    phone: '',
    cellphone: '',
    defaultAddress: [false],
  });


  populateAddressData() {
    if (this.addressData && Object.keys(this.addressData).length !== 0) {
      this.addressForm.patchValue(this.addressData);

      this.countrySelected(this.addressData.country);
      if (this.addressData.region && this.addressData.region.isocode != null) {
        this.regionSelected(this.addressData.region);
      }
    }
    this.addressForm.markAsDirty();
    this.addressForm.markAllAsTouched();
  }

  override verifyAddress(): void {
    if (this.addressForm.valid) {

      if (this.addressForm.dirty) {
        this.subscription.add(
          this.userAddressService
            .verifyAddress(this.addressForm.value)
            .subscribe((value) => {
              this.handleAddressVerificationResults(value);
            })
        );
      } else {
        // address form value not changed
        // ignore duplicate address
        this.submitAddress.emit(undefined);
      }
    } else {
      this.addressForm.markAllAsTouched();
    }
  }
}
