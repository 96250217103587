
import { NgModule } from "@angular/core";
import { ImportProductsFromCsvService } from '@spartacus/cart/import-export/components';
import { ImportCsvFileService } from '@spartacus/storefront';
import { RationalImportFileService } from './import-file-services/rational-import-file.service';
import { RationalImportProductsFromFileService } from './rational-import-products-from-file-service';

@NgModule({
  providers: [
    { provide: ImportProductsFromCsvService, useClass: RationalImportProductsFromFileService },
    { provide: ImportCsvFileService, useClass: RationalImportFileService }
  ]
})
export class RationalImportOrderEntriesModule {
}