import { Component, OnDestroy, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { SavedCartDetailsActionComponent, SavedCartDetailsService } from '@spartacus/cart/saved-cart/components';
import { OrderEntry } from '@spartacus/cart/base/root';
import { LaunchDialogService } from '@spartacus/storefront';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { RationalSavedCartService } from '../../rational-saved-cart.service';
import { GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';

@Component({
  selector: 'rational-saved-cart-detials-action',
  templateUrl: './rational-saved-cart-detials-action.component.html',
  styleUrls: ['./rational-saved-cart-detials-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RationalSavedCartDetialsActionComponent
  extends SavedCartDetailsActionComponent
  implements OnInit, OnDestroy
{
  private selectedEntries: OrderEntry[] = [];
  private selectedEntriesProductCodes: string[] = [];
  private cartId = '';

  ngOnInit(): void {
    this.savedCartService.selectedEntries.subscribe((entries) => {
      this.selectedEntries = entries;
    });
  }

  constructor(
    private activeCartService: ActiveCartService,
    private savedCartService: RationalSavedCartService,
    savedCartDetailsService: SavedCartDetailsService,
    private routingService: RoutingService,
    vcr: ViewContainerRef,
    launchDialogService: LaunchDialogService,
    private globalMessageService: GlobalMessageService,
    private route: ActivatedRoute,
  ) {
    super(savedCartDetailsService, vcr, launchDialogService);
  }

  addItemsToCart() {
    if (this.selectedEntries.length === 0 || this.selectedEntries == null) {
      this.globalMessageService.add(
        {key: 'savedCartDetails.selectedItemsAreEmpty'},
        GlobalMessageType.MSG_TYPE_INFO);
    }
    
    else {
      this.selectedEntries.forEach(entry => {
        this.selectedEntriesProductCodes.push(entry.product?.code!)
      })

      this.cartId = this.route.snapshot.paramMap.get('savedCartId')!;
      this.activeCartService.addEntries(this.selectedEntries);
      this.globalMessageService.add(
        {key: 'savedCartDetails.selectedItemsAddedToCart'},
        GlobalMessageType.MSG_TYPE_CONFIRMATION);
    }
  }

  goBack(): void {
    this.routingService.go(['my-account/saved-carts']);
  }

  override ngOnDestroy() {
    this.savedCartService.updateReferenceFromSavedCart(this.selectedEntriesProductCodes, this.cartId).pipe(first()).subscribe();
    super.ngOnDestroy()
  }
}
