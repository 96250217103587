export const addressForm = {
  title: '标题',
  defaultTitle: '标题',
  firstName: {
    label: '名',
    placeholder: '名',
  },
  lastName: {
    label: '姓',
    placeholder: '姓氏',
  },
  companyName: '公司名称',
  companyNamePlaceholder: '搜索公司名称',
  address1: '地址 1',
  address2: '地址 2（可选）',
  country: '国家',
  city: {
    label: '城市',
    placeholder: '城市',
  },
  state: '省',
  zipCode: {
    label: '邮政编码',
    placeholder: '邮政编码/邮政编码',
  },
  phoneNumber: {
    label: '电话号码',
    placeholder: '(555) 555 - 0123',
  },
  saveAsDefault: '保存为默认',
  chooseAddress: '选择地址',
  streetAddress: '街道地址',
  aptSuite: '公寓、套房',
  selectOne: '选择一个...',
  setAsDefault: '设置为默认',
  titleRequired: '标题为必填项。',
  userAddressAddSuccess: '新地址已成功添加！',
  userAddressUpdateSuccess: '地址更新成功！',
  userAddressDeleteSuccess: '地址删除成功！',
  invalidAddress: '地址无效',
};

export const addressBook = {
  addNewShippingAddress: '添加新送货地址',
  editShippingAddress: '编辑送货地址',
  areYouSureToDeleteAddress: '您确定要删除此地址吗？',
  addNewAddress: '添加新地址',
  addAddress: '添加地址',
  updateAddress: '更新地址',
  backToAddressList: '返回地址列表',
};

export const addressCard = {
  default: '默认',
  selected: '已选择',
  setAsDefault: '设置为默认',
  shipTo: '发货至',
  billTo: '账单收件人',
};

export const addressSuggestion = {
  verifyYourAddress: '验证您的地址',
  ensureAccuracySuggestChange: '为确保送货准确性，我们建议更改以下所选地址。',
  chooseAddressToUse: '请选择您希望使用的地址：',
  suggestedAddress: '建议地址',
  enteredAddress: '输入地址',
  editAddress: '编辑地址',
  saveAddress: '保存地址',
};

export const address = {
  addressForm,
  addressBook,
  addressSuggestion,
  addressCard,
};
