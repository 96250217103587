export const exportEntries= {
  exportEntries: {
    exportToCsv: 'Exporter au format CSV',
    exportMessage: 'Le fichier CSV sera téléchargé automatiquement sur votre appareil. Si ce n\'est pas le cas, veuillez vérifier les paramètres de votre navigateur.',
    columnNames: {
      code: 'Code',
      quantity: 'Quantité',
      name: 'Nom de l\'article',
      price: 'Prix',
    },
    exportProductToCsv: 'Exporter le panier au format CSV',
  }
}

export const importEntries= {
  importEntries: {
    importProducts: 'Importer le panier',
  }
}

export const importEntriesDialog= {
  importEntriesDialog: {
    importProducts: 'Importer le panier',
    importProductsSubtitle: 'Importer des produits par l\'importation d\'un fichier .CSV ou .XLSX',
    importProductsNewSavedCartSubtitle: 'Importer des produits en important un fichier .CSV ou .XLSX',
    importProductFileDetails: 'Le fichier importé doit contenir une liste de produits avec les colonnes Quantité et Numéro d\'article séparées par un point-virgule.',
    selectFile: 'Sélectionner un fichier',
    savedCartName: 'Nom du panier enregistré',
    savedCartDescription: 'Description du panier enregistré',
    optional: 'optionnel',
    charactersLeft: 'caractères à gauche : {{count}}',
    cancel: 'Annuler',
    upload: 'Télécharger',
    close: 'Fermer',
    summary: {
      info: 'Ne pas fermer ou actualiser cette fenêtre pendant l\'importation des produits.',
      loadedToCart: 'Les produits ont été chargés dans le panier {{ cartName }}',
      loaded: 'Les produits ont été chargés',
      loading: 'Les produits sont en cours de traitement... ({{ count }}/{{ total }})',
      successes: '{{ successesCount }} sur {{ total }} produits ont été importés avec succès.',
      warning: 'Le produit {{ count }} n\'a pas été importé en totalité.',
      warning_other: 'Les produits {{ count }} n\'ont pas été importés en totalité.',
      error: 'Le produit {{ count }} n\'a pas été importé.',
      error_other: 'Les produits {{ count }} n\'ont pas été importés.',
      messages: {
        unknownIdentifier: 'Le produit SKU "{{ productCode}}" n\'existe pas.',
        lowStock: 'La quantité de {{ productName}} : {{ quantity }} a été réduite à {{ quantityAdded }}.',
        noStock: '{{ productName}} est actuellement en rupture de stock.',
        unknownError: 'Problème non détecté avec "{{ productCode }}".',
        limitExceeded: 'Impossible d\'ajouter "{{ productCode }}". Limite dépassée.',
      },
      show: 'Afficher',
      hide: 'Cacher',
    }
  }
}
