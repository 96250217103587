<img
  *ngIf="media && media.src"
  [attr.src]="media.src | rationalImage | async"
  [attr.alt]="media.alt"
  [attr.role]="media.role"
  [attr.loading]="loading"
  (load)="loadHandler()"
  (error)="errorHandler()" 
/>


