export const addressForm = {
  title: 'Titolo',
  defaultTitle: 'Titolo',
  firstName: {
    label: 'Nome',
    placeholder: 'Nome',
  },
  lastName: {
    label: 'Cognome',
    placeholder: 'Cognome',
  },
  companyName: 'Nome dell\'azienda',
  companyNamePlaceholder: 'Cerca il nome dell\'azienda',
  address1: 'Via + Numero civico',
  address2: 'Indirizzo aggiuntivo',
  country: 'Paese',
  city: {
    label: 'Città',
    placeholder: 'Città',
  },
  state: 'Stato',
  zipCode: {
    label: 'Codice postale',
    placeholder: 'Codice postale',
  },
  phoneNumber: {
    label: 'Numero di telefono',
    placeholder: '(555) 555 - 0123',
  },
  saveAsDefault: 'Salva come predefinito',
  chooseAddress: 'Scegliere l\'indirizzo',
  streetAddress: 'Via + Numero civico',
  aptSuite: 'Interno',
  selectOne: 'Selezionarne uno...',
  setAsDefault: 'Impostare come predefinito',
  titleRequired: 'Il titolo è obbligatorio.',
  userAddressAddSuccess: 'Il nuovo indirizzo è stato aggiunto con successo!',
  userAddressUpdateSuccess: 'L\'indirizzo è stato aggiornato con successo!',
  userAddressDeleteSuccess: 'Indirizzo eliminato con successo!',
  invalidAddress: 'Indirizzo non valido',
}

export const addressBook = {
  addNewShippingAddress: 'Aggiungere un nuovo indirizzo di spedizione',
  editShippingAddress: 'Modificare l\'indirizzo di spedizione',
  areYouSureToDeleteAddress: 'Sei sicuro di voler cancellare questo indirizzo?',
  addNewAddress: 'Aggiungere un nuovo indirizzo',
  addAddress: 'Aggiungere indirizzo',
  updateAddress: 'Aggiornare l\'indirizzo',
  backToAddressList: 'Tornare all\'elenco degli indirizzi',
}

export const addressCard = {
  default: 'DEFAULT',
  selected: 'Selezionato',
  setAsDefault: 'Impostare come predefinito',
  shipTo: 'Spedire a',
  billTo: 'Fatturare a',
}

export const addressSuggestion = {
  verifyYourAddress: 'Verificare l\'indirizzo',
  ensureAccuracySuggestChange: 'Per garantire l\'accuratezza della consegna suggeriamo la modifica riportata di seguito.',
  chooseAddressToUse: 'Scegliere l\'indirizzo che si desidera utilizzare:',
  suggestedAddress: 'Indirizzo suggerito',
  enteredAddress: 'Indirizzo inserito',
  editAddress: 'Modificare indirizzo',
  saveAddress: 'Salvare indirizzo',
}

export const address = {
  addressForm,
  addressBook,
  addressSuggestion,
  addressCard
}
