import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { ProductDownloadsComponent } from './product-downloads.component';


@NgModule({
  declarations: [ProductDownloadsComponent],
  imports: [
    CommonModule,     
    I18nModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductDownloadsTabComponent: {
          component: ProductDownloadsComponent,
        },
      },
    }),
  ],
})
export class ProductDownloadsModule { }
