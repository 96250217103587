export const cartDetails = {
  id: 'ID',
  proceedToCheckout: 'Proceed to Checkout',
  createQuoteFromCart: 'Request Quote',
  cartName: 'Cart #{{code}}',
  minOrderNotReached: 'The minimum order value is {{minOrderValue}}{{currency}}.',
  orderLocked: 'Attention: You cannot place orders at the moment! Please contact your customer service. There are problems with this user account.',
  requestedRetrievalDateEmpty: 'You must choose a requested dispatch/pickup date',
  requestedRetrievalDateAdded: 'You have successfully added a requested dispatch/pickup date',
  requestedRetrievalDateInvalid: 'Requested dispatch/pickup date is invalid',
  s4omScheduleLinesQuantity: '-  Qty',
  s4omScheduleLinesEntryInfo: 'Confirmed dispatch/pickup date of - {{ quantity }} item(s) - is {{ date }}',
  cartSubjectToPriceChange: 'Attention: Prices for newly configured devices are list prices. You will receive your customer-specific price with the formal order confirmation.',
  removeInvalidEntries: 'Attention: Cart has been recalculated as some products are not available any more.'
}

export const cartItems = {
  id: 'Article Number',
  description: 'Description',
  item: 'Item',
  itemPrice: 'Item price',
  quantity: 'Qty',
  equipmentNumber: 'Equipment Number: ',
  quantityTitle: 'The quantity represents the total number of this item in your cart.',
  total: 'Total',
  cartTotal: 'Cart total ({{count}} item)',
  cartTotal_plural: 'Cart total ({{count}} items)',
  actions: 'Actions',
  salesUnit: 'UOM',
  reference: 'Reference:',
  referencePlaceHolder : 'Reference',
  additionalContactEmail : 'Additional Contact Email:',
  additionalContactEmailPlaceHolder : 'Additional Contact Email',
  comment : 'Comment:',
  commentPlaceHolder : 'Comment',
  additionalContactEmailWarning : 'Please enter a valid E-mail address. (Invalid E-mails will not be saved.) ',
  requestedDeliveryDate : 'Requested Dispatch/Pickup Date:',
  requestedDeliveryDateTitle : 'Requested dispatch/pickup date',
  substitutionProductNotFound : 'This product is substituted with another product, but substituted product is not currently available'
}

export const orderCost = {
  orderSummary: 'Order Summary',
  subtotal: 'Subtotal:',
  shipping: 'Shipping:',
  estimatedShipping: 'Estimated shipping:',
  discount: 'You saved:',
  salesTax: 'Sales Tax:',
  grossTax: 'The order total does not include tax of',
  grossIncludeTax: 'The order total includes tax of',
  total: 'Total:',
  toBeDetermined: 'TBD',
  requestedDeliveryDate: 'Requested dispatch/pickup date*',
  taxStatement: '*All prices shown are customer-specific net prices excluding taxes. You will be informed of your final total net price including taxes by e-mail with the order confirmation, as the daily updated net prices are recalculated at the time of ordering.',
  taxStatement2: 'When ordering accessories or spare parts, a minimum quantity surcharge may be levied if the net value excluding VAT does not reach the minimum order value.'
}

export const voucher = {
  coupon: 'Have a coupon?',
  coupon_plural: 'Coupon codes',
  apply: 'Apply',
  placeholder: 'Promo code',
  applyVoucherSuccess: '{{voucherCode}} has been applied.',
  removeVoucherSuccess: '{{voucherCode}} has been removed.',
  anchorLabel: 'Enter or remove your coupon code',
  vouchersApplied: 'Applied coupons',
  availableCoupons: 'Available coupons',
  availableCouponsLabel: 'You can add these coupons to this order.'
}

export const saveForLaterItems = {
  itemTotal: 'Saved for later ({{count}} item)',
  itemTotal_plural: 'Saved for later ({{count}} items)',
  cartTitle: 'Cart',
  saveForLater: 'Save For Later',
  moveToCart: 'Move To Cart',
  stock: 'Stock',
  forceInStock: 'In Stock'
}


export const cart = {
  cartDetails,
  cartItems,
  orderCost,
  voucher,
  saveForLaterItems
}
