<div class="row">
  <div class="col-12 col-md-4">
    <a
      [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
      class="cx-product-image-container"
      tabindex="-1"
    >
      <cx-media
        class="cx-product-image"
        [container]="product.images?.PRIMARY"
        format="product"
        [alt]="product.summary"
      ></cx-media>
    </a>
  </div>
  <div class="col-12 col-md-8">
    <a
      [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
      class="cx-product-name"
    >
      <h4 [innerHtml]="product.nameHtml"></h4>
    </a>
    <div class="listDescription" [innerHTML]="product?.description"></div>
    <div>
      <a
        [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
        class="cx-product-name"
      >{{ 'productDetails.id' | cxTranslate }} {{ product.code }}</a>
    </div>

    <ng-template [cxOutlet]="ProductListOutlets.ITEM_DETAILS">
      <cx-star-rating
        *ngIf="product.averageRating"
        [rating]="product?.averageRating"
      ></cx-star-rating>
      <div *ngIf="!product.averageRating" class="cx-product-no-review">
        {{ 'productDetails.noReviews' | cxTranslate }}
      </div>
    </ng-template>

    <div class="row addToCart">
     <div>
        <ng-template [cxOutlet]="ProductListOutlets.ITEM_ACTIONS">
          <cx-add-to-cart
            *ngIf="!hideAddToCartBtn && product.stock?.stockLevelStatus !== 'outOfStock'"
            [showQuantity]="false"
            [product]="product"
          ></cx-add-to-cart>
        </ng-template>
      </div>
    </div>
  </div>
</div>
