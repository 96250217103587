import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class RationalDatePickerFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '.';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10),
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ?  new Intl.NumberFormat('en', { minimumIntegerDigits: 2, useGrouping: false }).format(date.day) 
        + this.DELIMITER + 
        new Intl.NumberFormat('en', { minimumIntegerDigits: 2, useGrouping: false }).format(date.month) + 
        this.DELIMITER 
        + date.year : '';
    }
}
