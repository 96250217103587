import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { Observable } from "rxjs";
import { RationalCart } from "../model/rational-cart.model";
import { switchMap, take } from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export class RationalCheckoutLockInterceptor implements HttpInterceptor {
    constructor(private router: Router, private activeCartService: ActiveCartFacade) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isUserInCheckoutRoute(this.router.url)) {
            return next.handle(request);
        }

        return this.activeCartService.getActive().pipe(
            take(1),
            switchMap((cart) => {
                const orderLock = (cart as RationalCart).orderLock;
                if (orderLock) {
                    this.router.navigateByUrl('/cart');
                    return next.handle(request.clone({
                        url: ""
                    }));
                } else {
                    return next.handle(request);
                }
            })
        );
    }

    /**
 * Returns true if the parameter semantic route is part of "checkout"
 * Checkout semantic routes:
 * checkout
 * checkoutPaymentType
 * CheckoutDeliveryAddress
 * checkoutDeliveryMode
 * checkoutPaymentDetails
 * checkoutReviewOrder
 * checkoutLogin
 * @param semanticRoute
 */
    protected isUserInCheckoutRoute(semanticRoute?: string): boolean {
        return semanticRoute?.toLowerCase().includes('/checkout') ?? false;
    }
}
