<ng-container *ngIf="user$ | async as user; else login">
    <div class="cx-login-greet">
      {{ "common.userGreeting" | cxTranslate }} {{ user?.firstName }}
      <context-switcher></context-switcher>
    </div>
  </ng-container>
  
  <ng-template #login>
    <a role="link" [routerLink]="{ cxRoute: 'login' } | cxUrl">{{
      'miniLogin.signInRegister' | cxTranslate
    }}</a>
  </ng-template>
  