import { Injectable } from '@angular/core';
import { Order } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { OrderAdapter, OrderConnector } from '@spartacus/order/core';
import { RationalOrderOccAdapter } from './checkout-place-order.occ.adapter';

@Injectable({
    providedIn: 'root',
})
export class RationalOrderConnector extends OrderConnector {
  
    constructor(protected rationalAdapter: RationalOrderOccAdapter, protected override adapter: OrderAdapter) {
        super(adapter);
    }

  public placeQuoteOrder(
    userId: string,
    cartId: string,
    termsChecked: boolean
  ): Observable<Order> {    
    return this.rationalAdapter.placeQuoteOrder(userId, cartId, termsChecked);
  }
}
