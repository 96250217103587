<div class="header-links-container">
    <custom-header-links-ui [node]="node$ | async"></custom-header-links-ui>

    <div class="nav-wish-list">
        <a routerLink="my-account/wishlist">
            <div class="wishlist-icon">
                <cx-icon class="icon-raticons icon-icon_Heart"></cx-icon>
            </div>
        </a>
    </div>
</div>