import { DIALOG_TYPE, LayoutConfig } from "@spartacus/storefront";
import { RationalOrderDocumentComponent } from "./rational-order-document.component";

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        ORDER_DOCUMENTS = 'ORDER_DOCUMENTS'
    }
}

export const orderDocumentsLayoutConfig: LayoutConfig = {
    launch: {
      ORDER_DOCUMENTS: {
        inline: true,
        component: RationalOrderDocumentComponent,
        dialogType: DIALOG_TYPE.DIALOG,
      },
    },
  };