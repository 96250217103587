import { Injectable } from '@angular/core';
import {
  BasePageMetaResolver, BreadcrumbMeta, Occ, PageLinkService,
  ProductPageMetaResolver,
  ProductService,
  RoutingService,
  TranslationService
} from '@spartacus/core';
import PageType = Occ.PageType;
import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class RationalProductPageMetaResolver extends ProductPageMetaResolver
{
  constructor(
    protected override routingService: RoutingService,
    protected override productService: ProductService,
    protected override translation: TranslationService,
    protected override basePageMetaResolver: BasePageMetaResolver,
    protected override pageLinkService: PageLinkService
  ) {
    super(routingService, productService, translation, basePageMetaResolver, pageLinkService);
    this.pageType = PageType.PRODUCT_PAGE;
  }

  /**
   * Resolves breadcrumbs for the Product Detail Page. The breadcrumbs are driven by
   * a static home page crumb and a crumb for each category.
   */
  override resolveBreadcrumbs(): Observable<BreadcrumbMeta[]> {
    return combineLatest([
      this.product$.pipe(),
      this.translation.translate('common.home'),
    ]).pipe(
      map(([product, label]) => {
        const breadcrumbs = [];
        breadcrumbs.push({ label, link: '/' });
        for (const { name, code, url } of product.categories || []) {
          breadcrumbs.push({
            label: name || code,
            link: url,
          } as BreadcrumbMeta);
        }
        breadcrumbs.push({ label: product.name, link: undefined } as BreadcrumbMeta);
        return breadcrumbs;
      })
    );
  }

}
