export const addressForm = {
  title: 'Titel',
  defaultTitle: 'Titel',
  firstName: {
    label: 'Förnamn',
    placeholder: 'Förnamn',
  },
  lastName: {
    label: 'Efternamn',
    placeholder: 'Efternamn',
  },
  companyName: 'Företagets namn',
  companyNamePlaceholder: 'Sök efter företagsnamn',
  address1: 'Adress 1',
  address2: 'Adress 2 (valfritt)',
  country: 'Land',
  city: {
    label: 'Stad',
    placeholder: 'Stad',
  },
  state: 'stat',
  zipCode: {
    label: 'Postnummer',
    placeholder: 'Postnummer/Zip',
  },
  phoneNumber: {
    label: 'Telefonnummer',
    placeholder: '(555) 555 - 0123',
  },
  saveAsDefault: 'Spara som standard',
  chooseAddress: 'Välj adress',
  streetAddress: 'Gatuadress',
  aptSuite: 'Lägenhet, svit',
  selectOne: 'Välj en...',
  setAsDefault: 'Ställ in som standard',
  titleRequired: 'Titel är obligatoriskt.',
  userAddressAddSuccess: 'Ny adress lades till framgångsrikt!',
  userAddressUpdateSuccess: 'Adressen uppdaterades framgångsrikt!',
  userAddressDeleteSuccess: 'Adress raderad framgångsrikt!',
  invalidAddress: 'Ogiltig adress',
};

export const addressBook = {
  addNewShippingAddress: 'Lägg till en ny leveransadress',
  editShippingAddress: 'Redigera leveransadress',
  areYouSureToDeleteAddress: 'Är du säker på att du vill ta bort den här adressen?',
  addNewAddress: 'Lägg till ny adress',
  addAddress: 'Lägg till adress',
  updateAddress: 'Uppdatera adress',
  backToAddressList: 'Tillbaka till adresslistan',
};

export const addressCard = {
  default: 'FELAKTIG',
  selected: 'Vald',
  setAsDefault: 'Ställ in som standard',
  shipTo: 'Leveransadress',
  billTo: 'Fakturera till',
};

export const addressSuggestion = {
  verifyYourAddress: 'Verifiera din adress',
  ensureAccuracySuggestChange: 'För att säkerställa att leveransen är korrekt föreslår vi att du gör den ändring som anges nedan.',
  chooseAddressToUse: 'Vänligen välj vilken adress du vill använda:',
  suggestedAddress: 'Föreslagen adress',
  enteredAddress: 'Inmatad adress',
  editAddress: 'Redigera adress',
  saveAddress: 'Spara adress',
};

export const address = {
  addressForm,
  addressBook,
  addressSuggestion,
  addressCard,
};
