<ng-container *ngIf="configuration$ | async as configuration; else ghostForm">
    <ng-container *ngIf="hasAttributes(configuration); else noAttributes">
      <ng-container
        *ngTemplateOutlet="
          groups;
          context: {
            overviewGroups: configuration.overview?.groups,
            level: 1,
            idPrefix: ''
          }
        "
      ></ng-container>
    </ng-container>
  </ng-container>
  
  <ng-template #noAttributes>
    <div class="cx-no-attribute-value-pairs">
      <h2>{{ 'configurator.overviewForm.noAttributeHeader' | cxTranslate }}</h2>
      <p>{{ 'configurator.overviewForm.noAttributeText' | cxTranslate }}</p>
    </div>
  </ng-template>
  
  <ng-template
    #groups
    let-overviewGroups="overviewGroups"
    let-level="level"
    let-idPrefix="idPrefix"
  >
    <span class="cx-visually-hidden">
      {{ 'configurator.a11y.listOfAttributesAndValues' | cxTranslate }}
    </span>
  
    <ng-container *ngFor="let group of overviewGroups">
      <div
        id="{{ getGroupId(idPrefix, group.id) }}"
        [ngClass]="getGroupLevelStyleClasses(level, group.subGroups)"
      >
        <span class="cx-visually-hidden">
          {{
            'configurator.a11y.group'
              | cxTranslate
                : {
                    group: group.groupDescription
                  }
          }}
        </span>
        <h2 aria-hidden="true">
          <span>{{ group.groupDescription }}</span>
        </h2>
  
        <div
          *ngFor="let attributeOverview of group.attributes; let i = index"
          class="cx-attribute-value-pair"
          [ngClass]="getStyleClasses(group.attributes, i)"
        >
          <ng-container [ngSwitch]="attributeOverview?.type">
            <ng-container *ngSwitchCase="attributeOverviewType.GENERAL">
              <rational-configurator-overview-attribute
                [attributeOverview]="attributeOverview"
              >
              </rational-configurator-overview-attribute>
            </ng-container>
  
            <ng-container *ngSwitchCase="attributeOverviewType.BUNDLE">
              <rational-configurator-overview-attribute
                [attributeOverview]="attributeOverview"
              >
              </rational-configurator-overview-attribute>
            </ng-container>
  
            <ng-container *ngSwitchDefault>
              <rational-configurator-overview-attribute
                [attributeOverview]="attributeOverview"
              >
              </rational-configurator-overview-attribute>
            </ng-container>
          </ng-container>
        </div>
        <ng-container *ngIf="group.subGroups?.length > 0">
          <ng-container
            *ngTemplateOutlet="
              groups;
              context: {
                overviewGroups: group.subGroups,
                level: level + 1,
                idPrefix: getPrefixId(idPrefix, group.id)
              }
            "
          ></ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
  
  <ng-template #ghostForm>
    <ng-container *ngFor="let number of [0, 1, 2]">
      <div class="cx-ghost-group">
        <div class="cx-ghost-header ghost"></div>
        <div class="cx-ghost-body">
          <ng-container *ngFor="let number of [0, 1, 2, 3, 4, 5]">
            <div class="cx-ghost-attribute-value">
              <div class="cx-ghost-value ghost"></div>
            </div>
            <div class="cx-ghost-attribute-label">
              <div class="cx-ghost-label ghost"></div>
            </div>
            <div class="cx-ghost-attribute-price ghost"></div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>