import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContextSwitcherComponent } from './context-switcher.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { HttpErrorHandler, I18nModule } from '@spartacus/core';
import { BadContextSwitcherRequestHandler } from './handlers/bad-context-switcher-request.handler';
import { EndpointComponent } from './endpoint/endpoint.component';
import { SpinnerModule } from '@spartacus/storefront';



@NgModule({
  declarations: [ContextSwitcherComponent, EndpointComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    I18nModule,
    FormsModule,
    SpinnerModule
  ],
  exports: [ContextSwitcherComponent, EndpointComponent],
  providers: [
    {
      provide: HttpErrorHandler,
      useExisting: BadContextSwitcherRequestHandler,
      multi: true,
    },
  ]
})
export class ContextSwitcherModule { }
