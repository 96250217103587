<ng-container *ngIf="product?.description">
  
  <div class="info" (mouseenter)="onMouseEnter($event)">
    <i class="icon-info-sign"  (click)="onMouseEnter($event)"><cx-icon type="INFO"></cx-icon></i>
    
    <div class="extra-info-popup" [class.isVisbile]="isDescriptionVisible">
      <span class="extra-info" >
        <button type="button" class="close">
          <cx-icon type="CLOSE" class="closeButton" (click)="closePopup()"></cx-icon>
        </button>
        <div class="container overlay hoverData" [innerHTML]="product?.description"></div>
      </span>
    </div>
    
  </div>
</ng-container>