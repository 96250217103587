import { Component } from '@angular/core';
import { CartTotalsComponent } from '@spartacus/cart/base/components';

@Component({
  selector: 'cx-cart-totals',
  templateUrl: './rational-cart-totals.component.html',
})

export class RationalCartTotalComponent extends CartTotalsComponent {

}