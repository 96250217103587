import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  LanguageService,
  OccEndpointsService,
  Product,
  ProductService,
  RoutingService,
  isNotUndefined,
} from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  of,
  switchMap,
} from 'rxjs';

/*
 * This service is created to expose the getCode() funtionality from the CurrentProductService
 */
@Injectable({
  providedIn: 'root',
})
export class RationalCurrentProductService extends CurrentProductService {
  priceChanged$ = new Subject<Product>();

  constructor(
    private _routingService: RoutingService,
    private _productService: ProductService,
    private occEndpoints: OccEndpointsService,
    private languageService : LanguageService,
    protected http: HttpClient
  ) {
    super(_routingService, _productService);
    this.languageService.getActive().subscribe(data => {
            this.reloadCurrentProductPrice()
    });
  }

  public getCurrentProductCode(): Observable<string> {
    return super.getCode();
  }

  public getCurrentProductPrice(): Observable<Product | null> {
    return this.getCode().pipe(
      distinctUntilChanged(),
      switchMap((productCode: string) => {
        return productCode
          ? this.http.get<Product>(
              this.occEndpoints.buildUrl('/products/') + productCode + '/price'
            )
          : of(null);
      }),
      filter(isNotUndefined)
    );
  }

  public reloadCurrentProductPrice(): void {
    this.getCurrentProductPrice().subscribe((product) => {
      if (product != null) {
        this.priceChanged$.next(product);
      }
    });
  }
}
