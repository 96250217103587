export const quotes = {
  created: 'Cytat został utworzony',
  title: 'Cytaty',
  code: 'Kod',
  name: 'Nazwa',
  date: 'Data',
  state: 'Status',
  statusDisplay_BUYER_APPROVED: 'Kupujący - Zatwierdzone',
  statusDisplay_BUYER_DRAFT: 'Kupujący - wersja robocza',
  statusDisplay_BUYER_SUBMITTED: 'Kupujący - Przesłano',
  statusDisplay_BUYER_OFFER: 'Kupujący - Oferta',
  statusDisplay_BUYER_ACCEPTED: 'Kupujący - Zaakceptowany',
  statusDisplay_BUYER_REJECTED: 'Kupujący - Odrzucony',
  statusDisplay_BUYER_ORDERED: 'Kupujący - Zamówienie',
  statusDisplay_SELLER_REQUEST: 'Sprzedawca - żądanie',
  statusDisplay_SELLER_DRAFT: 'Sprzedający - Wersja robocza',
  statusDisplay_SELLER_SUBMITTED: 'Sprzedający - Przesłane',
  statusDisplay_SELLERAPPROVER_DRAFT: 'Sellerapprover - Wersja robocza',
  statusDisplay_SELLERAPPROVER_PENDING: 'Sprzedający zatwierdzający - Oczekujące',
  statusDisplay_SELLERAPPROVER_REJECTED: 'Sellerapprover - odrzucony',
  statusDisplay_SELLERAPPROVER_APPROVED: 'Sellerapprover - Zatwierdzony',
  noQuotes: 'Brak biletów',
  entries: {
    code: 'Kod',
    name: 'Nazwa',
    amount: 'Ilość',
    price: 'Cena',
  },
  totalPriceWithoutTax: 'Całkowita cena bez podatku:',
};
