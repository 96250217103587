export const savedCartDetails = {
  cartName: 'Nazwa',
  cartDescription: 'Opis',
  cartId: 'ID',
  dateSaved: 'Data zapisania',
  items: 'Pozycje',
  quantity: 'Ilość',
  total: 'Razem',
  editSavedCart: 'Edytuj zapisany koszyk',
  deleteSavedCart: 'Usuń zapisany koszyk',
  addSelectedItems: 'Dodaj wybrane pozycje',
  emptyCartItems: 'Puste pozycje w koszyku',
}

export const savedCartList = {
  savedCarts: 'Zapisane koszyki ({{count}})',
  cartName: 'Nazwa',
  cartId: 'Id',
  dateSaved: 'Data zapisania',
  cartDescription: 'Opis',
  quantity: 'Ilość',
  total: 'Razem',
  actions: 'Działania',
  makeCartActive: 'Aktywuj koszyk',
  notFound: 'Nie znaleziono zapisanych koszyków',
  swapCartNoActiveCart: 'Istniejący koszyk został pomyślnie aktywowany.',
  swapCartWithActiveCart: 'Istniejący koszyk został pomyślnie aktywowany przez {{cartName}}. Twoje poprzednie produkty zostały zapisane w koszyku {{previousCartName}}.',
}

export const savedCartCartPage = {
  messages: {
    cartSaved: 'Twoje produkty zostały pomyślnie zapisane na później w koszyku {{cartName}}.',
  },
  back: 'Powrót do przeglądu',
}

export const savedCartDialog = {
  saveForLater: 'Zapisz na później',
  itemsSavedForLater: 'Wszystkie produkty w koszyku zostaną zapisane na później.',
  savedCartName: 'Nazwa zapisanego koszyka',
  savedCartDescription: 'Opis zapisanego koszyka',
  optional: 'opcjonalny',
  charactersLeft: 'znaków pozostało: {{count}}',
  cancel: 'Anuluj',
  save: 'Zapisz',
  restore: 'Przywróć',
  followingCartDelete: 'Następujący zapisany koszyk zostanie usunięty',
  followingCartRestore: 'Następujący zapisany koszyk zostanie przywrócony jako aktywny koszyk',
  delete: 'Usuń',
  deleteCartSuccess: 'Pomyślnie usunięto koszyk',
  editCartSuccess: 'Pomyślnie edytowano koszyk',
  editSavedCart: 'Edytuj zapisany koszyk',
  deleteSavedCart: 'Usuń zapisany koszyk',
  restoreSavedCart: 'Przywróć zapisany koszyk',
  name: 'Nazwa',
  id: 'ID',
  description: 'Opis',
  quantity: 'QTY',
  total: 'Razem',
  keepCopySavedCart: 'Zachowaj kopię tego koszyka na liście zapisanych koszyków',
  isSharedCart: 'Udostępnij ten koszyk swojej organizacji',
  defaultCloneCartName: 'Kopia {{name}}',
  nameOfCloneCart: 'Nazwa skopiowanego koszyka',
}

export const addToSavedCart = {
  savedCarts: 'Zapisane koszyki',
  saveCartForLater: 'Zapisz koszyk na później',
  sharedCartMessageStart :'Dostępny jest udostępniony koszyk utworzony przez {{userName}} ({{userMail}}). Kliknij ',
  sharedCartMessageMiddle :'tutaj',
  sharedCartMessageEnd :', aby aktywować udostępniony koszyk. Aktywacja udostępnionego koszyka spowoduje usunięcie go z poziomu firmy do czasu ponownego udostępnienia, a także nadpisanie bieżącego osobistego koszyka.'
}

export const savedCart = {
  savedCartDetails,
  savedCartList,
  savedCartCartPage,
  savedCartDialog,
  addToSavedCart
}
