export const quickOrderCartForm = {
  entriesWasAdded: '({{ quantity }}) {{ product }} został dodany do koszyka',
  entryWasAdded: '{{ product }} został dodany do koszyka',
  noResults: 'Nie mogliśmy znaleźć żadnych produktów',
  stockLevelReached: 'Osiągnięto maksymalny poziom zapasów',
  title: 'Szybkie dodawanie do koszyka',
  productCodePlaceholder: 'Wprowadź numer artykułu',
  entryProductCode: 'Wprowadź numer artykułu dla szybkiego zamówienia',
  addToCart: 'Szybkie dodawanie do koszyka',
  product: 'Produkt',
  products: 'Produkty',
  productCodeLabel: 'ID produktu',
  quantityLabel: 'Ilość',
}

export const quickOrderForm = {
  placeholder: 'Wprowadź SKU produktu',
  listLimitReached: 'Limit produktów został osiągnięty',
  id: 'Numer artykułu {{ id }}',
}

export const quickOrderList = {
  addToCart: 'Dodaj do koszyka',
  emptyList: 'Pusta lista',
  header: 'Dodaj produkty/Skus',
  subHeader: 'Jednocześnie można dodać maksymalnie {{ limit }} ważnych jednostek SKU.',
  errorProceedingToCart: 'Błąd przejścia do koszyka.',
  warningProceedingToCart: 'Ostrzeżenie o przejściu do koszyka.',
  successfullyAddedToCart: 'Pomyślnie dodano do koszyka.',
  errors: {
    productIsOutOfStock: '{{ name }} (#{{code}}) jest niedostępny.',
  },
  warnings: {
    productWasReduced: 'Ilość dla {{ name }} (#{{code}}) (#{{code}}) została zmniejszona do {{ quantityAdded}}.',
  },
  successes: {
    productAddedToCart: '{{ name }} (#{{code}}) została dodana do koszyka.',
  },
}

export const quickOrderTable = {
  product: 'Produkt',
  actions: 'Działania',
  id: 'Numer artykułu',
  price: 'Cena',
  quantity: 'QTY',
  itemPrice: 'Cena artykułu',
  qty: 'Ilość',
  inStock: 'W magazynie',
  lowStock: 'Niski stan magazynowy',
  outOfStock: 'Brak w magazynie',
  listCleared: 'Lista szybkich zamówień została wyczyszczona',
  addedtoCart: 'Lista szybkich zamówień została dodana do koszyka',
}

export const quickOrder = {
  quickOrderCartForm,
  quickOrderForm,
  quickOrderList,
  quickOrderTable
}
