export const visualPicking = {
  visualPickingProductList: {
    id: 'Codice articolo',
  },
  visualPickingProductFilter: {
    input: {
      placeholder: 'Filtrare per nome o per codice articolo',
    },
  },
}

export const epdVisualization = {
  epdVisualization: {
    visualPicking
  }
}

