import { AuthConfig } from "angular-oauth2-oidc";

export const authConfig: AuthConfig = {
    skipIssuerCheck: true,
    redirectUri: window.location.origin,
    scope: 'offline_access openid email profile roles identities',
    responseType: 'code',
    showDebugInformation: true,
    strictDiscoveryDocumentValidation: false,
    waitForTokenInMsec: 30000
}
