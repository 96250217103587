/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { RationalConfiguratorPriceSummaryComponent } from './rational-configurator-price-summary.component';
import { RulebasedConfiguratorModule } from '@spartacus/product-configurator/rulebased';
import {MatExpansionModule} from '@angular/material/expansion';

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, CommonModule, I18nModule, RulebasedConfiguratorModule, MatExpansionModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ConfiguratorPriceSummary: {
          component: RationalConfiguratorPriceSummaryComponent,
        },
      },
    }),
  ],
  declarations: [RationalConfiguratorPriceSummaryComponent],
  exports: [RationalConfiguratorPriceSummaryComponent],
})
export class RationalConfiguratorPriceSummaryModule {}
