export const savedCartDetails = {
    cartName: "Namn",
    cartDescription: "Beskrivning",
    cartId: "ID",
    dateSaved: "Datum sparat",
    items: "Artiklar",
    quantity: "Kvantitet",
    total: "Totalt antal",
    editSavedCart: "Redigera sparad kundvagn",
    deleteSavedCart: "Ta bort sparad kundvagn",
    addSelectedItems: "Lägg till valda artiklar",
    emptyCartItems: "Tomma artiklar i kundvagnen",
    addItemsToCart: "Beställ valda artiklar",
    selectAll: "Välj alla",
    quickAddToCart: "Lägg till i sparad kundvagn snabbt",
    productAddedToCart: "{{product}}  har lagts till i den sparade kundvagnen.",
    selectedItemsAddedToCart: "Valda artiklar har lagts till i kundvagnen.",
    selectedItemsAreEmpty: "Du måste välja minst en vara för att lägga till i varukorgen.",
};

export const savedCartList = {
    savedCarts: "Sparade kundvagnar ({{count}})",
    cartName: "namn",
    cartId: "Id",
    dateSaved: "Datum sparad",
    cartDescription: "Beskrivning",
    quantity: "Antal",
    total: "Totalt antal",
    actions: "Åtgärder",
    makeCartActive: "Gör varukorgen aktiv",
    notFound: "Inga sparade kundvagnar hittades",
    swapCartNoActiveCart: "Befintlig kundvagn har aktiverats framgångsrikt.",
    swapCartWithActiveCart: "Befintlig varukorg aktiveras av {{cartName}} framgångsrikt. Dina tidigare artiklar sparades i en varukorg {{previousCartName}}.",
    editCart: 'Redigera kundvagn'
};

export const savedCartCartPage = {
    messages: {
        cartSaved: "Dina varor i varukorgen har sparats till senare i varukorgen {{cartName}}"
    },
    back: "Tillbaka till översikten"
};

export const savedCartDialog = {
    saveForLater: "Spara för senare",
    itemsSavedForLater: "Alla artiklar i din kundvagn sparas för senare användning",
    savedCartName: "Namn på sparad kundvagn",
    savedCartDescription: "Beskrivning av sparad kundvagn",
    optional: "valfritt",
    charactersLeft: "tecken kvar: {{count}}",
    cancel: "Avbryt",
    save: "Spara",
    restore: "Återställ",
    followingCartDelete: "Följande sparade kundvagn kommer att raderas",
    followingCartRestore: "Följande sparade varukorg kommer att återställas som den aktiva varukorgen",
    delete: "Ta bort",
    deleteCartSuccess: "Kundvagnen raderades framgångsrikt",
    editCartSuccess: "Redigering av kundvagn lyckades",
    editSavedCart: "Redigera sparad kundvagn",
    deleteSavedCart: "Ta bort sparad kundvagn",
    restoreSavedCart: "Återställ sparad kundvagn",
    name: "namn",
    id: "ID",
    description: "Beskrivning",
    quantity: "QTY",
    total: "Totalt",
    keepCopySavedCart: "Spara en kopia av den här varukorgen i listan över sparade varukorgar",
    isSharedCart: "Dela den här varukorgen med din organisation",
    defaultCloneCartName: "Kopia av {{name}}",
    nameOfCloneCart: "Namnet på den kopierade varukorgen"
};

export const addToSavedCart = {
    savedCarts: "Sparade vagnar",
    saveCartForLater: "Spara kundvagn för senare användning",
    sharedCartMessageStart: "En delad kundvagn finns tillgänglig skapad av {{userName}} ({{userMail}}). Klicka ",
    sharedCartMessageMiddle: "här",
    sharedCartMessageEnd: " för att aktivera den delade kundvagnen. Om du aktiverar den delade varukorgen kommer den att raderas från företagsnivå tills du har delat den igen, och den kommer även att skriva över din nuvarande personliga varukorg."
};

export const savedCart = {
    savedCartDetails,
    savedCartList,
    savedCartCartPage,
    savedCartDialog,
    addToSavedCart
  }