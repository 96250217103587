import { Observable, of, take } from "rxjs";
import { RationalOrderDocumentService } from "./rational-order-document.service";
import { ChangeDetectionStrategy, Component, OnInit, ViewContainerRef } from "@angular/core";
import { RationalOrderDocument } from "../../model/rational-order-document.model";
import { DIALOG_TYPE, FocusConfig, ICON_TYPE, LAUNCH_CALLER, LaunchDialogService, LayoutConfig } from "@spartacus/storefront";
import { Order } from "@spartacus/order/root";
import { OrderDetailsService } from "@spartacus/order/components";



@Component({
    selector: 'cx-order-documents',
    templateUrl: './rational-order-document.component.html',
    styleUrls: ['./rational-order-document.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
export class RationalOrderDocumentComponent {
    iconTypes = ICON_TYPE;
    orderCode : string = "";

    constructor(
        protected rationalOrderDocumentService : RationalOrderDocumentService,
        protected launchDialogService : LaunchDialogService,
        protected vcr: ViewContainerRef
    ){
      this.launchDialogService.data$.subscribe((data) => {
        this.orderCode = data.orderCode;
        this.documents$ = this.rationalOrderDocumentService.getOrderDocuments(this.orderCode, this.orderDocumentCategoryType);
      })
    }


    orderDocumentCategoryType = "C";
    isDownloadsVisible = false;
  
    closePopup() {  
        this.isDownloadsVisible = false;
    } 


    documents$: Observable<RationalOrderDocument[]> = this.rationalOrderDocumentService.getOrderDocuments(this.orderCode, this.orderDocumentCategoryType);

    getFileNameWithType(document : RationalOrderDocument) {
        return document.Filename.concat('.').concat(document.MimeType.split("/").pop()!);
    }

    downloadDocument(orderDocument: RationalOrderDocument) {
      const a = document.createElement('a');
      return this.rationalOrderDocumentService.downloadDocument(orderDocument).subscribe((response) => {
        const file = new Blob([response], { type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        a.href = fileURL;
        a.download = this.getFileNameWithType(orderDocument);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(fileURL);
      });
    }

    isPopupOpen: boolean = false;

// If Want to use Function for popup
    openPopup (): void {
    this.isPopupOpen = !this.isPopupOpen;
    }

    focusConfig: FocusConfig = {
        trap: true,
        block: true,
        autofocus: 'button',
        focusOnEscape: true,
    };

    onMouseClick() {
        this.launchDialogService.openDialog(
        "ORDER_DOCUMENTS",
        undefined,
        undefined,
        undefined
      );
    }
      
        defaultAddedToCartLayoutConfig: LayoutConfig = {
        launch: {
          ORDER_DOCUMENTS: {
            inlineRoot: true,
            component: RationalOrderDocumentComponent,
            dialogType: DIALOG_TYPE.DIALOG,
          },
        },
      };

      close(reason: string): void {
        this.launchDialogService.closeDialog(reason)
      }
}