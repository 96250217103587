<ng-template cxOutletRef="MiniCart">
  <div class="nav-cart">
    <a [routerLink]="{ cxRoute: 'cart' } | cxUrl">
      <div class="mini-cart-icon">
        <cx-icon class="icon-raticons icon-supermarket"></cx-icon>

        <div class="mini-cart-count">
          <span class="nav-items-total">
            {{ "miniCart.count" | cxTranslate: { count: quantity$ | async } }}
          </span>
        </div>
      </div>
    </a>
  </div>
</ng-template>
