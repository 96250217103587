export const checkoutAddress = {
  shippingAddress: '送货地址',
  selectYourShippingAddress: '选择您的送货地址：',
  selectYourDeliveryAddress: '选择您的送货地址：',
  defaultShippingAddress: '默认送货地址',
  addNewAddress: '添加新地址',
  shipToThisAddress: '发货至此地址',
  addressSearch: '地址搜索',
  addressSearchMinLength: '搜索文本应至少包含 {{length}} 个字符',
  submitAddressSearch: '输入',
  errorSearchingSalesforce: '暂时无法完成搜索，请稍后再试',
  deliveryAddressSelected: '已选择送货地址',
};

export const checkoutOrderConfirmation = {
  confirmationOfOrder: '确认订单：',
  confirmationOfQuote: '确认收到报价：',
  thankYou: '感谢您的订单！',
  thankYouQuote: '感谢您的报价请求！',
  invoiceHasBeenSentByEmailLine1: '请注意，该订单可能需要一个工作日才能显示在您的订单历史记录中。一旦出现在您的订单历史记录中，它将包括正式的订单确认、RATIONAL确认的发货/提货日期以及完整的价格信息，包括适用的税费。同时，如果您对此订单有任何疑问，请联系您的RATIONAL客户服务部门，并注明上述订单ID。',
  invoiceHasBeenSentByEmailLine2: '请注意，您的报价请求不会转入您在网上商店的订单历史记录中。如果您对此报价请求有任何疑问，请联系您的 RATIONAL 客户服务部门，并注明上述网店报价编号或您的采购单编号。',
  quoteHasBeenSentByEmailLine1: '请注意，您的报价请求不会转入您在网上商店的订单历史记录中。',
  quoteHasBeenSentByEmailLine2: '如果您对此报价请求有任何疑问，请联系您的 RATIONAL 客户服务部门，并注明上述网店报价编号或您的采购单编号。',
  orderItems: '订购项目',
  orderPlacedSuccessfully: '订购成功',
  createAccount: '创建账户？',
  createAccountForNext: '为<{{email}}>创建一个账户，以便下次访问时更快地结账。',
};

export const checkoutReview = {
  review: '订单摘要',
  orderItems: '订购项目',
  autoReplenishOrder: '自动补货订单',
  confirmThatRead: '我确认我已阅读并同意',
  placeOrder: '下订单',
  placeQuoteOrder: '请求报价',
  scheduleReplenishmentOrder: '计划补货订单',
  orderType_PLACE_ORDER: '无（仅限一次性订单）',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: '补货订单',
  termsAndConditions: '条款和条件',
  editShippingAddress: '编辑送货地址',
  poNumber: '采购单编号',
  every: '每个',
  startOn: '开始日期',
  dayOfMonth: '日',
  repeatOnDays: '在随后几天重复',
  recurrencePeriodType_DAILY: '日',
  recurrencePeriodType_WEEKLY: '周',
  recurrencePeriodType_MONTHLY: '月',
};

export const checkoutShipping = {
  shippingMethod: '送货方式',
  standardDelivery: '标准送货',
  premiumDelivery: '高级送货',
};

export const checkoutPO = {
  noPoNumber: '无',
  poNumber: '您的采购单号',
  costCenter: '成本中心',
  placeholder: '输入您的采购单编号...',
  availableLabel: '您可选择的送货地址取决于成本中心/单位',
};

export const checkoutProgress = {
  methodOfPayment: '付款方式',
  deliveryAddress: '送货地址',
  deliveryMode: '送货方式',
  paymentDetails: '付款详情',
  reviewOrder: '订单摘要',
};

export const checkoutB2B = {
  checkoutB2B: {
  poNumber: '您的采购单号',
  placeholder: '输入您的采购单编号...',
  methodOfPayment: {
    paymentType: '付款方式',
  },
  progress: {
    methodOfPayment: '付款方式',
  },
  review: {
    poNumber: '您的采购单号',
  },
}
};

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: '国际贸易术语',
    paymentConditions: '付款条件',
  }
};

export const checkoutMode = {
  deliveryMethod: '交货方式',
  deliveryEntries: '准备发货的物品',
  specialShippingInstructions: '特殊运输说明',
  specialShippingInstructionsPlaceholder: '特殊运输说明',
};


  export const checkout = {
    checkoutAddress,
    checkoutOrderConfirmation,
    checkoutReview,
    checkoutShipping,
    checkoutPO,
    checkoutProgress,
    checkout: {
      backToCart: '返回购物车',
      invalid: {
        accountType: '您的帐户不允许您在此结账。'
      }
    },
    checkoutMode
  }