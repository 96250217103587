import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { OutletModule, PromotionsModule, CardModule, provideOutlet, FormErrorsModule, PasswordVisibilityToggleModule, PwaModule, OutletPosition } from '@spartacus/storefront';
import { RationalOrderConfirmationItemsComponent } from './order-confirmation-items.component';
import { RationalOrderConfirmationShippingComponent } from './order-confirmation-shipping.component';
import { OrderConfirmationGuard, OrderConfirmationModule, OrderConfirmationOrderEntriesContext, OrderConfirmationShippingComponent, OrderDetailBillingComponent, OrderDetailsService } from '@spartacus/order/components';
import { OrderConfirmationOrderEntriesContextToken, OrderFacade, OrderOutlets } from '@spartacus/order/root';
import { RationalOrderConfirmationThankYouMessageComponent } from './order-confirmation-thank-you-message/order-confirmation-thank-you-message.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RationalOrderConfirmationTotalsComponent } from './order-confirmation-totals/order-confirmation-totals.component';
import { RationalCartSharedModule } from '../../cart/base/cart-item/rational-cart-shared.module';
import { RationalCartBaseModule } from '../../cart/base/cart-base.module';
import { AbstractOrderContextModule } from '@spartacus/cart/base/components';


@NgModule({
  imports: [
    CommonModule,
    CardModule,
    PwaModule,
    PromotionsModule,
    I18nModule,
    ReactiveFormsModule,
    FormErrorsModule,
    OutletModule.forChild(),
    PasswordVisibilityToggleModule,
    OrderConfirmationModule,
    RationalCartSharedModule,
    RationalCartBaseModule,
    AbstractOrderContextModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OrderConfirmationItemsComponent: {
          component: RationalOrderConfirmationItemsComponent,
          providers: [
            {
              provide: OrderDetailsService,
              useExisting: OrderFacade,
            },
          ],
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationShippingComponent: {
          component: RationalOrderConfirmationShippingComponent,
          providers: [
            {
              provide: OrderDetailsService,
              useExisting: OrderFacade,
            },
          ],
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationThankMessageComponent: {
          component: RationalOrderConfirmationThankYouMessageComponent,
          providers: [
            {
              provide: OrderDetailsService,
              useExisting: OrderFacade,
            },
          ],
          guards: [OrderConfirmationGuard],
        },
        ReplenishmentConfirmationMessageComponent: {
          component: RationalOrderConfirmationThankYouMessageComponent,
          providers: [
            {
              provide: OrderDetailsService,
              useExisting: OrderFacade,
            },
          ],
          guards: [OrderConfirmationGuard],
        },
        RationalOrderConfirmationTotalsComponent: {
          component: RationalOrderConfirmationTotalsComponent,
          providers: [
            {
              provide: OrderDetailsService,
              useExisting: OrderFacade,
            },
          ],
          guards: [OrderConfirmationGuard],
        },
        ReplenishmentConfirmationTotalsComponent: {
          component: RationalOrderConfirmationTotalsComponent,
          providers: [
            {
              provide: OrderDetailsService,
              useExisting: OrderFacade,
            },
          ],
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationBillingComponent: {
          component: OrderDetailBillingComponent,
          providers: [
            {
              provide: OrderDetailsService,
              useExisting: OrderFacade,
            },
          ],
          guards: [OrderConfirmationGuard],
        },

      },
    }),
    {
      provide: OrderConfirmationOrderEntriesContextToken,
      useExisting: OrderConfirmationOrderEntriesContext,
    },
    provideOutlet({
      id: OrderOutlets.CONSIGNMENT_DELIVERY_INFO,
      component: OrderConfirmationShippingComponent,
    }),
  ],
  declarations: [RationalOrderConfirmationItemsComponent, RationalOrderConfirmationShippingComponent, RationalOrderConfirmationThankYouMessageComponent, RationalOrderConfirmationTotalsComponent],
  exports: [ RationalOrderConfirmationItemsComponent, RationalOrderConfirmationShippingComponent, RationalOrderConfirmationThankYouMessageComponent, RationalOrderConfirmationTotalsComponent],
})
export class RationalOrderConfirmationModule {}
