import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
} from '@spartacus/checkout/base/components';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { RationalCheckoutPaymentTypeComponent } from './checkout-payment-type.component';
import { RationalIncotermsAndPaymentConditionsModule } from '../checkout-incoterms-and-payment-conditions/checkout-incoterms-and-payment-conditions.module';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    RationalIncotermsAndPaymentConditionsModule,
    ConfigModule.withConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPaymentType: {
          component: RationalCheckoutPaymentTypeComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartValidationGuard],
        },
      },
    }),
  ],
  declarations: [RationalCheckoutPaymentTypeComponent],
  exports: [RationalCheckoutPaymentTypeComponent],
})
export class RationalCheckoutPaymentTypeModule {}
