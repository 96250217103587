export const productDetails = {
    id: "Makale Numarası",
    quantity: "Miktar",
    productDetails: "Ürün Detayları",
    specification: "Teknik Özellikler",
    reviews: "Yorumlar",
    shipping: "Nakliye",
    share: "Paylaş",
    showReviews: "Yorumları göster",
    noReviews: "",
    productPrice: "Ürün fiyatı",
    priceLoading: "Ürün fiyatı hesaplanıyor",
    productRelations: {
        productRelationsHeader: "Uyumlu Aksesuarlar"
    },
    disclaimer: {
        title: 'Görsellerin kullanımına ilişkin not:',
        text: 'Bu web sitesinde gösterilen görseller yalnızca açıklama amaçlıdır ve gerçek ürün özelliklerinin veya teslimat kapsamının bağlayıcı bir temsili değildir. Ürünlerin tam spesifikasyonları, özellikleri ve teslimat kapsamı, ilgili ürün açıklamasında metin biçiminde bulunabilir.'
    }
};

export const productList = {
    filterBy: {
        label: "Şuna göre filtrele",
        action: "Şuna göre filtrele"
    },
    appliedFilter: "Uygulanan filtre:",
    showLess: "Daha az göster...",
    showMore: "Daha fazla göster...",
    sortBy: "Göre sırala",
    backToTopBtn: "BAŞA DÖN",
    showMoreBtn: "DAHA FAZLASINI GÖSTER",
    productSearchPagination: "Ürün arama sayfalandırması"
};

export const productFacetNavigation = {
    filterBy: {
        label: "Şuna göre filtrele",
        action: "Şuna göre filtrele"
    },
    appliedFilter: "Uygulanan filtre:",
    showLess: "Daha az göster...",
    showMore: "Daha fazla göster...",
    sortBy: "Göre sırala"
};

export const productSummary = {
    id: "Makale Numarası",
    showReviews: "Yorumları göster",
    share: "Paylaş",
    newItemPrice: "Yeni ürün fiyatı",
    discount: "Görüntülenen fiyatlara {{discount}} tutarındaki bireysel indiriminiz dahildir.",
    productSubstitutionAndProductExists: "Ürün {{product1}}, ürün {{product2}} ile değiştirilir, böylece {{product2}} ürününe yönlendirilirsiniz.",
    productSubstitutionAndProductDoesntExist: "Ürün {{product1}}, ürün {{product2}} ile değiştirilmiştir, ancak {{product2}} şu anda mevcut değil."
};

export const productReview = {
    overallRating: "Genel Değerlendirme",
    reviewTitle: "İnceleme Başlığı",
    writeYourComments: "Yorumlarınızı yazın",
    rating: "Değerlendirme",
    reviewerName: "Hakem adı (isteğe bağlı)",
    writeReview: "Bir İnceleme Yazın",
    more: "Daha Fazla Yorum Göster",
    less: "Daha Az Yorum Göster",
    thankYouForReview: "İnceleme için teşekkür ederiz! İncelemelerin burada görünmeden önce gözden geçirilmesi gerekebileceğini unutmayın."
};

export const addToCart = {
    itemsAddedToYourCart: "Sepetinize eklenen ürün(ler)",
    itemsIncrementedInYourCart: "Bu ürün zaten sepetinizdeydi. Miktar güncellendi.",
    items: "ÜRÜNLER",
    updatingCart: "Sepet güncelleniyor...",
    addToCart: "Sepete Ekle",
    viewCart: "Sepeti Görüntüle",
    proceedToCheckout: "Ödeme İşlemine Geçin",
    quantity: "Miktar",
    outOfStock: "Stokta yok",
    inStock: "Stokta var",
    selectStyleAndSize: "Stil ve beden seçin",
    removeFromCart: "Ürünü Sepetten Çıkar",
    closeModal: "Modalı Kapat"
};

export const TabPanelContainer = {
    tabs: {
        ProductDetailsTabComponent: "Ürün Detayları",
        ProductSpecsTabComponent: "Teknik Özellikler",
        ProductReviewsTabComponent: "Yorumlar",
        deliveryTab: "Nakliye",
        ProductDownloadsTabComponent: "İndirmeler"
    }
};

export const addToWishList = {
    add: "İstek Listesine Ekle",
    remove: "İstek Listesinden Çıkar",
    anonymous: "İstek listesine eklemek için giriş yapın"
};

export const itemCounter = {
    removeOne: "Bir tanesini çıkarın",
    addOneMore: "Bir tane daha ekle",
    quantity: "Miktar"
};

export const productView = {
    gridView: "Izgara Görünümüne geçmek için seçin",
    listView: "Liste Görünümüne geçmek için seçin"
};

export const standardDelivery = {
    standardDeliveryRange: "{{from}} ile {{to}} gün arasında gönderime hazır"
};

export const downloads = {
    downloads: {
        noDownloads: "Bu ürün için indirme yok",
        documentTitle: "Belge Başlığı",
        toolbox: "Araç Kutusu'da daha fazlası",
        allDocuments: "Tümü",
        languagesDropdown: "Dil:",
        originalFileName: "Dosya Adı",
        languages: "Diller",
        readableTypeMime: "Mime Türü",
        fileSize: "Dosya Boyutu",
        download: "İndir"
    }
};

export const productReferences = {
    productReferences: {
        installationvariants: "Kurulum Varyasyonları",
        feedingsystems: "Yükleme Sistemleri",
        groomingaccessoires: "Bakım Ürünleri",
        cookaccessoire: "Pişirme Aksesuarları",
        cookaccessoirespecial: "Özel Aksesuarlar",
        extractorhood: "Davlumbazlar",
        deviceinstallation: "Birim Kurulumu"
    }
};

export const product = {
    productDetails,
    productList,
    productFacetNavigation,
    productSummary,
    productReview,
    addToCart,
    TabPanelContainer,
    addToWishList,
    itemCounter,
    productView
};
