export const configurator = {
  configurator: {
    header: {
      consistent: "Consistente",
      complete: "Completo",
      configId: "ID de configuração",
      toconfig: "Configurar",
      toConfigReadOnly: "Mostrar detalhes",
      editConfiguration: "Editar configuração",
      displayConfiguration: "Exibir configuração",
      resolveIssues: "Resolver problemas",
      resolveIssue: "Resolver problema",
      resolveConflicts: "Resolver falhas",
      conflictWarning: "Falha deve ser resolvida para continuar",
      updateMessage: "A configuração está sendo atualizada em segundo plano",
      conflictsResolved: "Falhas foram resolvidas",
      showMore: "mostrar mais",
      showLess: "mostrar menos",
      items: "{{count}} item",
      items_other: "{{count}} de itens",
      show: "mostrar",
      hide: "ocultar",
      multipleWarnings: "Há vários avisos.",
      reviewWarnings: "Revise esses avisos",
      multipleErrors: "Há vários erros.",
      reviewErrors: "Revise esses erros",
      kbKeyName: "Nome do Kb",
      kbKeyLogsys: "Sistema lógico do Kb",
      kbKeyVersion: "Versão do Kb",
      kbKeyBuildNr: "Número de compilação do Kb",
    },
    tabBar: {
      configuration: "Configuração",
      overview: "Visão geral",
    },
    notificationBanner: {
      numberOfIssues: "O problema {{count}} deve ser resolvido antes do checkout.",
      numberOfIssues_other: "Os problemas {{count}} devem ser resolvidos antes do checkout.",
      numberOfConflicts: "O conflito {{count}} deve ser resolvido antes do checkout.",
      numberOfConflicts_other: "Os conflitos {{count}} devem ser resolvidos antes do checkout.",
    },
    attribute: {
      id: "ID",
      quantity: "Qtd.",
      caption: "Atributos",
      notSupported: "O tipo de atributo não é compatível.",
      requiredAttribute: "{{param}} obrigatório",
      defaultRequiredMessage: "Digite um valor para o campo obrigatório",
      singleSelectRequiredMessage: "Selecione um valor",
      singleSelectAdditionalRequiredMessage: "Selecione ou digite um valor",
      multiSelectRequiredMessage: "Selecione um ou mais valores",
      wrongNumericFormat: "Formato incorreto, esse atributo numérico deve ser inserido de acordo com o padrão {{Muster}}",
      wrongNumericFormatMessage: "Digite o número no seguinte formato",
      wrongIntervalFormat: "Insira um valor dentro dos limites indicados",
      deselectionNotPossible: "Adicionar um produto diferente antes de remover este",
      dropDownSelectMsg: "Fazer uma seleção",
      noOptionSelectedMsg: "Nenhuma opção selecionada",
      notVisibleAttributeMsg: "Esse atributo não está visível para o usuário",
    },
    button: {
      previous: "Anterior",
      next: "Seguinte",
      back: "Voltar",
      more: "mais",
      less: "menos",
      deselect: "Desmarcar",
      select: "Selecionar",
      add: "Adicionar",
      remove: "Remover",
      exit: "Sair da configuração",
      exitMobile: "Sair",
      cancelConfiguration: "Cancelar configuração",
      cancelConfigurationMobile: "Cancelar",
      filterOverview: "Filtro",
      filterOverviewWithCount: "Filtro ({{numAppliedFilters}})",
    },
    icon: {
      groupComplete: "Completo",
      groupIncomplete: "Incompleto",
      groupConflict: "Conflito",
      subgroup: "tem subgrupos",
    },
    priceSummary: {
      basePrice: "Preço base",
      selectedOptions: "Opções selecionadas",
      totalPrice: "Total",
      totalPricePerItem: "Total por item",
    },
    addToCart: {
      button: "Adicionar ao carrinho",
      buttonAfterAddToCart: "Continuar para o carrinho",
      buttonForQuote: "Continuar com a cotação",
      buttonUpdateCart: "Concluído",
      buttonDisplayOnly: "Concluído",
      buttonClose: "Fechar",
      confirmation: "A configuração foi adicionada ao carrinho",
      confirmationUpdate: "O carrinho foi atualizado com a configuração",
      confirmationQuoteUpdate: "A cotação foi atualizada com a configuração",
      selectVariant: "Foi encontrada mais de uma variante existente para sua configuração. Nesse caso, entre em contato com o atendimento ao cliente para selecionar a variante correta.",
      addMatchingVariant: "A variante existente para sua configuração foi adicionada ao carrinho de compras.",
      quantity: "Quantidade",
    },
    overviewForm: {
      noAttributeHeader: "Nenhum resultado",
      noAttributeText: "Remova o(s) filtro(s) para ver o conteúdo da Visão Geral",
      itemPrice: "Preço do item",
    },
    overviewSidebar: {
      menu: "Menu",
      filter: "Filtro",
    },
    overviewFilter: {
      title: "Selecionar filtros",
      byOption: "Filtrar por opção",
      byGroup: "Filtro por grupo",
      byPrice: "Opções relevantes para o preço",
      mySelections: "Minhas Seleções",
      removeAll: "Remover tudo",
      removeAllFilters: "Remover todos os filtros",
      removeByPrice: "Remover filtro de preço relevante",
      removeMySelections: "Remover o filtro Minhas Seleções",
      removeByGroup: "Remover filtro de grupo para o grupo {{group}}",
      noFiltersAvailable: "Nenhum filtro disponível",
    },
    group: {
      general: "Geral",
      conflictHeader: "Resolver conflitos",
      conflictGroup: "Conflito para {{attribute}}",
    },
    conflict: {
      suggestionTitle: "Sugestão {{number}}",
      suggestionText: "Alterar o valor de \\{{attribute }}\\",
      viewConflictDetails: "Conflito detectado - Exibir detalhes",
      conflictDetected: "Conflito detectado",
      viewConfigurationDetails: "Exibir na configuração",
    },
    restartDialog: {
      title: "Configuração inacabada",
      description: "Há uma configuração inacabada de uma sessão anterior. Deseja retomar ou iniciar uma nova configuração?",
      restartButton: "Iniciar nova",
      resumeButton: "Resumir",
    },
    httpHandlers: {
      makeToStockBaseProductIssue: "Este é um produto de base e não pode ser adicionado ao carrinho; em vez disso, escolha uma variante do produto",
    },
  a11y: {
    configureProduct: "Configurar produto",
    showDetailsProduct: "Mostrar detalhes do produto",
    cartEntryBundleInfo: "Existe um item",
    cartEntryBundleInfo_other: "Existem itens {{items}}",
    cartEntryBundleName: "Item {{name}}",
    cartEntryBundleNameWithQuantity: "Item {{name}} quantidade do item {{quantity}}",
    cartEntryBundleNameWithPrice: "Item {{name}} preço do item {{price}}",
    cartEntryBundleNameWithPriceAndQuantity: "Item {{name}} preço do item {{price}} quantidade do item {{quantity}}",
    cartEntryBundle: "O produto tem os seguintes atributos e valores selecionados",
    cartEntryInfoIntro: "O atributo {{attribute}} tem o valor selecionado {{value}}",
    cartEntryInfo: "Nome do atributo",
    nameOfAttribute: "Valor do atributo {{attribute}}",
    valueOfAttribute: "{{value}} para o atributo {{attribute}}",
    forAttribute: "Valor {{value}} do atributo {{attribute}}",
    valueOfAttributeFull: "Valor {{value }} do atributo {{attribute }}",
    valueOfAttributeFullWithPrice: "Valor {{value }} do atributo {{attribute }}, Sobretaxa {{price }}",
    selectedValueOfAttributeFull: "Valor selecionado {{value }} do atributo {{attribute }}",
    selectedValueOfAttributeFullWithPrice: "Valor selecionado {{value }} do atributo {{attribute }}, Sobretaxa {{price }}",
    readOnlyValueOfAttributeFullWithPrice: "Valor somente leitura {{value }} do atributo {{attribute }}, Sobretaxa {{price }}",
    readOnlyValueOfAttributeFull: "Valor somente leitura {{value }} do atributo {{attribute }}",
    valueOfAttributeBlank: "O valor do atributo {{ attribute }} está em branco",
    value: "Valor {{value }}",
    attribute: "Atributo {{attribute}}",
    requiredAttribute: "O atributo {{param}} é obrigatório",
    listOfAttributesAndValues: "Lista de atributos e seus valores",
    editAttributesAndValues: "Editar valores de atributos",
    group: "{{group}} grupo de atributos",
    itemOfAttributeSelected: "O item {{item}} do atributo {{attribute}} está selecionado. {{itemIndex}} de {{itemCount}}",
    itemOfAttributeSelectedWithPrice: "O item {{item}} do atributo {{attribute}} está selecionado, Sobretaxa {{price}}. {{itemIndex}} de {{itemCount}}",
    itemOfAttributeSelectedPressToUnselect: "O item {{item}} do atributo {{attribute}} está selecionado. Pressione o botão para cancelar a seleção. {{itemIndex}} de {{itemCount}}",
    itemOfAttributeSelectedPressToUnselectWithPrice: "Item {{item}} of attribute {{attribute}} is selected, Sobretaxa {{price}} Pressione o botão para cancelar a seleção. {{itemIndex}} de {{itemCount}}",
    itemOfAttributeUnselected: "O item {{item}} do atributo {{attribute}} não está selecionado. Pressione o botão para selecionar. {{itemIndex}} de {{itemCount}}",
    itemOfAttributeUnselectedWithPrice: "Item {{item}} do atributo {{attribute}} não está selecionado, Sobretaxa {{price}}. Pressione o botão para selecionar. {{itemIndex}} de {{itemCount}}",
    selectNoItemOfAttribute: "Para não selecionar nenhum item para o atributo {{attribute}}, pressione o botão. {{itemIndex}} de {{itemCount}}",
    itemOfAttribute: "Item do atributo {{attribute}}",
    itemOfAttributeFull: "Item {{item}} do atributo {{attribute}}",
    itemOfAttributeFullWithPrice: "Item {{item}} do atributo {{attribute}}, Sobretaxa {{price}}",
    itemOfAttributeFullWithQuantity: "Item {{item}} do atributo {{attribute}}, Quantidade {{quantity}}",
    itemOfAttributeFullWithPriceAndQuantity: "Item {{item}} do atributo {{attribute}}, Quantidade {{quantity}}, Sobretaxa {{price}}",
    itemDescription: "Descrição do item {{item}}",
    listbox: "Caixa de listagem com valores {{count}}.",
    valueSurcharge: "Valor da sobretaxa",
    conflictDetected: "Conflito detectado! Verifique os conflitos no topo da lista de grupos.",
    conflictsInConfiguration: "Há conflitos em sua configuração. Número de conflitos",
    listOfGroups: "Lista de grupos",
    inListOfGroups: "Você está na lista de grupos",
    groupName: "Grupo {{group}}",
    groupBack: "Você está em um subgrupo. Selecione para voltar.",
    conflictBack: "Você está no solucionador de conflitos. Selecione para voltar ou selecionar as próximas guias para resolver conflitos.",
    iconConflict: "O grupo tem conflitos.",
    iconIncomplete: "O grupo tem atributos necessários sem valores selecionados.",
    iconComplete: "O grupo está completo.",
    iconSubGroup: "O grupo tem um subgrupo.",
    next: "Navegar para o próximo grupo.",
    previous: "Navegar para o grupo anterior.",
    showMoreProductInfo: "Mostrar mais informações sobre o produto {{product}} ou continuar na configuração.",
    showLessProductInfo: "Mostrar menos informações sobre o produto {{product}} ou continuar na configuração.",
    productName: "Nome do produto",
    productCode: "Código do produto",
    productDescription: "Descrição do produto",
    kbKeyName: "Nome do Kb {{name}}",
    kbKeyLogsys: "Sistema lógico do Kb {{logsys}}",
    kbKeyVersion: "Versão do Kb {{version}}",
    kbKeyBuildNr: "Número de compilação do Kb {{number}}",
    configurationPage: "Você está na página de configuração.",
    configurationPageLink: "Navegue até a página de configuração.",
    overviewPage: "Você está na página de visão geral. Verifique os atributos e seus valores selecionados com o modo de leitura do seu leitor de tela.",
    overviewPageLink: "Navegue até a página de visão geral da configuração.",
    navigateToConflict: "Siga o link para obter mais detalhes sobre o conflito do atributo {{attribute}}.",
    numericIntervalStandard: "Insira um valor entre {{minValue}} e {{maxValue}}",
    numericIntervalStandardOpen: "(Os pontos finais não estão incluídos)",
    numericIntervalStandardLowerEndpointNotIncluded: "(O ponto de extremidade inferior não está incluído)",
    numericIntervalStandardUpperEndpointNotIncluded: "(O ponto final superior não está incluído)",
    numericInfiniteIntervalMinValueIncluded: "Digite um valor maior ou igual a {{value}}",
    numericInfiniteIntervalMinValue: "Digite um valor maior que {{value}}",
    numericInfiniteIntervalMaxValueIncluded: "Insira um valor menor ou igual a {{value}}",
    numericInfiniteIntervalMaxValue: "Digite um valor menor que {{value}}",
    numericIntervalSingleValue: "Digite {{value}}",
    combinedIntervalsText: "{{combinedInterval}} ou {{newInterval}}",
    additionalValue: "Você também pode inserir um valor adicional.",
    addToCartPrices: "Preço base {{basePrice}}, Opções selecionadas {{selectedOptions}}, Preço total {{totalPrice}}",
    filterOverview: "Abrir o menu de filtro da visão geral da configuração",
    filterOverviewWithCount: "Abrir o menu de filtros da visão geral da configuração - {{numAppliedFilters}} filtros ativos",
    closeFilterMenu: "Fechar o menu de filtros da visão geral da configuração e aplicar os filtros selecionados",
    filterOverviewByPrice: "Filtrar a visão geral da configuração por opções relevantes para o preço",
    filterOverviewByMySelections: "Filtrar a visão geral da configuração por suas seleções",
    filterOverviewByGroup: "Filtrar a visão geral da configuração por grupo {{groupName}}",
    closeConflictSolverModal: "Fechar modal do solucionador de conflitos",
    closeRestartDialog: "Feche a caixa de diálogo \\Configuração inacabada\\ e volte para a página de detalhes do produto",
    description: "Clique para ver uma descrição para este valor",
  },
    variantCarousel: {
      title: "Versões pré-configuradas",
    },
  },
  }

