export const savedCartDetails = {
    cartName: "Název",
    cartDescription: "Popis",
    cartId: "ID",
    dateSaved: "Datum uložení",
    items: "Položky",
    quantity: "Množství",
    total: "Celkem",
    editSavedCart: "Upravit uložený košík",
    deleteSavedCart: "Smazat uložený košík",
    addSelectedItems: "Přidat vybrané položky",
    emptyCartItems: "Prázdný košík Položky",
    addItemsToCart: "Pedir položky",
    selectAll: "Vybrat vše",
    quickAddToCart: "Rychle přidat do uloženého košíku",
    productAddedToCart: "{{product}} byl přidán do uloženého košíku",
    selectedItemsAddedToCart: "Vybrané položky byly úspěšně přidány do košíku.",
    selectedItemsAreEmpty: "Musíte vybrat alespoň jednu položku, kterou chcete přidat do košíku.",
};

export const savedCartList = {
    savedCarts: "Uložené košíky ({{count}})",
    cartName: "Název",
    cartId: "Id",
    dateSaved: "Datum uložení",
    cartDescription: "Popis",
    quantity: "Množství",
    total: "Celkem",
    actions: "Akce",
    makeCartActive: "Učinit košík aktivním",
    notFound: "Nebyl nalezen žádný uložený košík",
    swapCartNoActiveCart: "Stávající košík je úspěšně aktivován.",
    swapCartWithActiveCart: "Stávající košík je úspěšně aktivován pomocí {{cartName}}. Vaše předchozí položky byly uloženy do košíku {{previousCartName}}.",
    editCart: "Upravit uložený košík"
};

export const savedCartCartPage = {
    messages: {
        cartSaved: "Vaše položky košíku byly úspěšně uloženy na později do košíku \'{{cartName}}'."
    },
    back: "Zpět na přehled"
};

export const savedCartDialog = {
    saveForLater: "Uložit na později",
    itemsSavedForLater: "Všechny položky ve vašem košíku budou uloženy na později",
    savedCartName: "Název uloženého košíku",
    savedCartDescription: "Uložený košík Popis",
    optional: "volitelné",
    charactersLeft: "zbývající znaky: {{count}}",
    cancel: "Zrušit",
    save: "Uložit",
    restore: "Obnovit",
    followingCartDelete: "Následující uložený košík bude smazán",
    followingCartRestore: "Následující uložený košík bude obnoven jako aktivní košík",
    delete: "Smazat",
    deleteCartSuccess: "Košík byl úspěšně smazán",
    editCartSuccess: "Košík úspěšně upraven",
    editSavedCart: "Upravit uložený košík",
    deleteSavedCart: "Smazat uložený košík",
    restoreSavedCart: "Obnovit uložený košík",
    name: "Název",
    id: "ID",
    description: "Popis",
    quantity: "MNOŽSTVÍ",
    total: "Celkem",
    keepCopySavedCart: "Ponechte si kopii tohoto vozíku v seznamu uložených vozíků",
    isSharedCart: "Sdílejte tento košík se svou organizací",
    defaultCloneCartName: "Kopie {{name}}",
    nameOfCloneCart: "Název zkopírovaného košíku"
};

export const addToSavedCart = {
    savedCarts: "Uložené vozíky",
    saveCartForLater: "Uložit košík na později",
    sharedCartMessageStart: "K dispozici je sdílený košík vytvořený uživatelem {{userName}}. ({{userMail}}). Kliknutím ",
    sharedCartMessageMiddle: "zde",
    sharedCartMessageEnd: " sdílený košík aktivujete. Aktivace sdíleného košíku jej odstraní z úrovně společnosti, dokud jej znovu nesdílíte, a také přepíše váš aktuální osobní košík."
};

export const savedCart = {
    savedCartDetails,
    savedCartList,
    savedCartCartPage,
    savedCartDialog,
    addToSavedCart
};
