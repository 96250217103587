export const configurator = {
  configurator: {
    header: {
      consistent: 'Cohérent',
      complete: 'Complet',
      configId: 'Configuration ID',
      toconfig: 'Configurer',
      toConfigReadOnly: 'Afficher les détails',
      editConfiguration: 'Modifier la configuration,',
      displayConfiguration: 'Afficher la configuration',
      resolveIssues: 'Résoudre les problèmes',
      resolveIssue: 'Résoudre un problème',
      resolveConflicts: 'Résoudre les conflits',
      conflictWarning: 'Le conflit doit être résolu pour continuer',
      updateMessage: 'La configuration est mise à jour en arrière-plan',
      conflictsResolved: 'Les conflits ont été résolus',
      showMore: 'afficher plus',
      showLess: 'afficher moins',
      items: 'Éléments {{count}}',
      items_other: 'éléments {{count}}',
      show: 'afficher',
      hide: 'cacher',
      multipleWarnings: 'Il y a plusieurs avertissements.',
      reviewWarnings: 'Examinez ces avertissements',
      multipleErrors: 'Il y a plusieurs erreurs.',
      reviewErrors: 'Passez en revue ces erreurs',
      kbKeyName: 'Nom de Kb',
      kbKeyLogsys: 'Système logique',
      kbKeyVersion: 'Kb version',
      kbKeyBuildNr: 'Numéro de construction du logiciel',
    },
    tabBar: {
      configuration: 'Configuration du système',
      overview: 'Vue d\'ensemble',
    },
    notificationBanner: {
      numberOfIssues: 'Le problème {{count}} doit être résolu avant le paiement.',
      numberOfIssues_other: 'Les problèmes {{count}} doivent être résolus avant le paiement.',
      numberOfConflicts: 'Les conflits {{count}} doivent être résolus avant le paiement.',
      numberOfConflicts_other: 'Les conflits {{count}} doivent être résolus avant le paiement.',
    },
    attribute: {
      id: 'Numéro d\'article',
      quantity: 'Qté',
      caption: 'Caractéristiques',
      notSupported: 'Le type de caractéristique n\'est pas pris en charge.',
      requiredAttribute: '{{param}} obligatoire',
      defaultRequiredMessage: 'Saisir une valeur pour le champ obligatoire',
      singleSelectRequiredMessage: 'Sélectionner une valeur',
      singleSelectAdditionalRequiredMessage: 'Sélectionner ou saisir une valeur',
      multiSelectRequiredMessage: 'Sélectionner une ou plusieurs valeurs',
      wrongNumericFormat: 'Format incorrect, cette caractéristique doit être saisie selon le modèle {{pattern}}',
      wrongNumericFormatMessage: 'Entrez le nombre dans le format suivant',
      wrongIntervalFormat: 'Entrez une valeur dans les limites indiquées',
      deselectionNotPossible: 'Ajouter un autre produit avant de supprimer celui-ci',
      dropDownSelectMsg: 'Effectuer une sélection',
      noOptionSelectedMsg: 'Aucune option sélectionnée',
      notVisibleAttributeMsg: 'Cette caractéristique n\'est pas visible pour l\'utilisateur',
    },
    button: {
      previous: 'Précédent',
      next: 'Suivant',
      back: 'Retour',
      more: 'plus',
      less: 'moins',
      deselect: 'Désélectionner',
      select: 'Sélectionner',
      add: 'Ajouter',
      remove: 'Enlever',
      exit: 'Quitter la configuration',
      exitMobile: 'Quitter',
      cancelConfiguration: 'Annuler la configuration',
      cancelConfigurationMobile: 'Annuler',
      filterOverview: 'Filtre',
      filterOverviewWithCount: 'Filtre ({{numAppliedFilters}})',
    },
    icon: {
      groupComplete: 'Complet',
      groupIncomplete: 'Incomplet',
      groupConflict: 'Conflit',
      subgroup: 'a des sous-groupes',
    },
    priceSummary: {
      basePrice: 'Prix de base',
      selectedOptions: 'Options sélectionnées',
      totalPrice: 'Total',
      totalPricePerItem: 'Total par article',
    },
    addToCart: {
      button: 'Ajouter au panier',
      buttonAfterAddToCart: 'Poursuivre le panier',
      buttonForQuote: 'Continuer vers le devis',
      buttonUpdateCart: 'Fait',
      buttonDisplayOnly: 'Terminé',
      buttonClose: 'Fermer',
      confirmation: 'La configuration a été ajoutée au panier',
      confirmationUpdate: 'Le panier a été mis à jour avec la configuration',
      confirmationQuoteUpdate: 'Le devis a été mis à jour avec la configuration',
      quantity: 'Qté',
      selectVariant: "Plus d'une variante existante a été trouvée pour votre configuration. Dans ce cas, veuillez contacter le service clientèle pour choisir la bonne variante.",
      addMatchingVariant: 'La variante existante pour votre configuration a été ajoutée au panier.'
    },
    overviewForm: {
      noAttributeHeader: 'Aucun résultat,',
      noAttributeText: 'Supprimer le(s) filtre(s) pour voir le contenu de l\'aperçu,',
      itemPrice: 'Prix de l\'article',
    },
    overviewSidebar: {
      menu: 'Menu',
      filter: 'Filtre',
    },
    overviewFilter: {
      title: 'Sélectionner les filtres',
      byOption: 'Filtrer par option',
      byGroup: 'Filtre par groupe',
      byPrice: 'Options relatives au prix',
      mySelections: 'Mes sélections',
      removeAll: 'Supprimer tout',
      removeAllFilters: 'Supprimer tous les filtres',
      removeByPrice: 'Supprimer le filtre relatif au prix',
      removeMySelections: 'Supprimer le filtre Mes sélections',
      removeByGroup: 'Supprimer le filtre de groupe pour le groupe {{group}}',
      noFiltersAvailable: 'Aucun filtre disponible'
    },
    group: {
      general: 'Généralités',
      conflictHeader: 'Résoudre les conflits',
      conflictGroup: 'Conflit pour {{attribute}}'
    },
    conflict: {
      suggestionTitle: 'Suggestion {{number}}',
      suggestionText: 'Modifier la valeur pour {{attribute}}',
      viewConflictDetails: 'Conflit détecté - Voir les détails',
      conflictDetected: 'Conflit détecté',
      viewConfigurationDetails: 'Vue de la configuration'
    },
    restartDialog: {
      title: 'Configuration inachevée',
      description: 'Il existe une configuration inachevée provenant d\'une session précédente. Souhaitez-vous reprendre ou commencer une nouvelle configuration ?',
      restartButton: 'Commencer une nouvelle configuration',
      resumeButton: 'Reprendre',
    },
    httpHandlers: {
      makeToStockBaseProductIssue: 'Ce produit est un produit de base et ne peut pas être ajouté au panier ; choisissez une variante de produit à la place.',
    },
    a11y: {
      configureProduct: 'Configurer le produit',
      showDetailsProduct: 'Afficher les détails du produit',
      cartEntryBundleInfo: 'Il y a un article ',
      cartEntryBundleInfo_other: 'Il y a des articles {{items}} ',
      cartEntryBundleName: 'Article {{name}}',
      cartEntryBundleNameWithQuantity: 'Article {{name}} quantité de l\'article {{quantity}}',
      cartEntryBundleNameWithPrice: 'Article {{name}} prix de l\'article {{price}}',
      cartEntryBundle: 'Article {{name}} prix de l\'article {{price}} quantité de l\'article {{quantity}}',
      cartEntryInfoIntro: 'Le produit possède les caractéristiques et les valeurs sélectionnées suivantes',
      cartEntryInfo: 'La caractéristiques {{attribute}} a pour valeur sélectionnée {{value}}',
      nameOfAttribute: 'Nom de l\'attribut',
      valueOfAttribute: 'Valeur de la caractéristique {{attribute}}',
      forAttribute: 'Valeur {{value}} de la caractéristique {{attribute}}',
      valueOfAttributeFull: 'Valeur {{value}} de la caractéristique {{attribute}}',
      valueOfAttributeFullWithPrice: 'Valeur {{value}} de la caractéristique {{attribute}}, Supplément {{price}}',
      selectedValueOfAttributeFull: 'Valeur sélectionnée {{value}} de l\'attribut {{attribute}}',
      selectedValueOfAttributeFullWithPrice: 'Valeur sélectionnée {{value}} de la caractéristique {{attribute}}, Supplément {{price}},',
      readOnlyValueOfAttributeFullWithPrice: 'Valeur en lecture seule {{value}} de la caractéristique {{attribute}}, Supplément {{price}},',
      readOnlyValueOfAttributeFull: 'Valeur en lecture seule {{value}} de la caractéristique {{attribute}}',
      valueOfAttributeBlank: 'La valeur de la caractéristique {{attribute}} est vide',
      value: 'Valeur {{value}}',
      attribute: 'Caractéristique {{attribute}}',
      requiredAttribute: 'L\'attribut {{param}} est obligatoire',
      listOfAttributesAndValues: 'Liste des caractéristiques et de leurs valeurs',
      editAttributesAndValues: 'Modifier les valeurs des attributs',
      group: 'Groupe de caractéristiques {{group}}',
      itemOfAttributeSelected: 'L\'élément {{item}} de la caractéristique {{attribute}} est sélectionné. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttributeSelectedWithPrice: 'L\'élément {{ item }} de la caractéristique {{attribute}} est sélectionné, Supplément {{price}}. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttributeSelectedPressToUnselect: 'L\'élément {{ item }} de la caractéristiques {{attribute}} est sélectionné. Appuyez sur le bouton pour désélectionner. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttributeSelectedPressToUnselectWithPrice: 'L\'élément {{ item }} de la caractéristiques {{attribute}} est sélectionné, Supplément {{price}} Appuyez sur le bouton pour désélectionner. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttributeUnselected: 'L\'élément {{ item }} de la caractéristique {{attribute}} n\'est pas sélectionné. Appuyez sur le bouton pour sélectionner. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttributeUnselectedWithPrice: 'L\'élément {{ item }} de la caractéristique {{attribute}} n\'est pas sélectionné, Supplément {{price}}. Appuyez sur le bouton pour sélectionner. {{ itemIndex }} de {{ itemCount }}',
      selectNoItemOfAttribute: 'Pour ne sélectionner aucun élément pour  la caractéristique {{attribute}}, appuyez sur le bouton. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttribute: 'Élément de  la caractéristique {{attribute}}',
      itemOfAttributeFull: 'Elément {{item}} de  la caractéristique {{attribute}}',
      itemOfAttributeFullWithPrice: 'Article {{item}} de  la caractéristique {{attribute}}, Supplément {{price}}',
      itemOfAttributeFullWithQuantity: 'Article {{item}} de  la caractéristique {{attribute}}, Quantité {{quantity}}',
      itemOfAttributeFullWithPriceAndQuantity: 'Article {{item}} de l\'attribut {{attribute}}, Quantité {{quantity}}, Supplément {{price}}',
      itemDescription: 'Description de l\'élément {{ item }}',
      listbox: 'Boîte de liste avec des valeurs {{ count }}.',
      valueSurcharge: 'Supplément de valeur',
      conflictDetected: 'Conflit détecté ! Vérifiez les conflits en haut de la liste des groupes.',
      conflictsInConfiguration: 'Il y a des conflits dans votre configuration. Nombre de conflits',
      listOfGroups: 'Liste des groupes',
      inListOfGroups: 'Vous êtes dans la liste des groupes',
      groupName: 'Groupe {{group}}',
      groupBack: 'Vous êtes dans un sous-groupe. Sélectionnez pour revenir en arrière.',
      conflictBack: 'Vous êtes dans l\'outil de résolution des conflits. Sélectionnez pour revenir en arrière ou sélectionnez les onglets suivants pour résoudre les conflits.',
      iconConflict: 'Le groupe a des conflits.',
      iconIncomplete: 'Le groupe a des caractéristiques obligatoires sans valeurs sélectionnées.',
      iconComplete: 'Le groupe est complet.',
      iconSubGroup: 'Le groupe a un sous-groupe.',
      next: 'Naviguer vers le groupe suivant.',
      previous: 'Naviguer vers le groupe précédent.',
      showMoreProductInfo: 'Afficher plus d\'informations sur le produit {{product}} ou passer à la configuration.',
      showLessProductInfo: 'Afficher moins d\'informations sur le produit {{product}} ou passer à la configuration.',
      productName: 'Nom du produit',
      productCode: 'Code du produit',
      productDescription: 'Description du produit',
      kbKeyName: 'Kb nom {{name}}',
      kbKeyLogsys: 'Système logique {{logsys}}',
      kbKeyVersion: 'Version de Kb {{version}}',
      kbKeyBuildNr: 'Numéro de build de Kb {{number}}',
      configurationPage: 'Vous êtes sur la page de configuration.',
      configurationPageLink: 'Naviguez vers la page de configuration.',
      overviewPage: 'Vous êtes sur la page d\'aperçu. Vérifiez les caractéristiques et leurs valeurs sélectionnées avec le mode lecture de votre lecteur d\'écran.',
      overviewPageLink: 'Naviguez vers la page de présentation de la configuration.',
      navigateToConflict: 'Suivez le lien pour obtenir plus de détails sur le conflit pour la caractéristique {{attribute}}.',
      numericIntervalStandard: 'Saisissez une valeur comprise entre {{ minValue }} et {{ maxValue }}.',
      numericIntervalStandardOpen: '(Les points d\'extrémité ne sont pas inclus)',
      numericIntervalStandardLowerEndpointNotIncluded: '(L\'extrémité inférieure n\'est pas incluse)',
      numericIntervalStandardUpperEndpointNotIncluded: '(L\'extrémité supérieure n\'est pas incluse)',
      numericInfiniteIntervalMinValueIncluded: 'Saisir une valeur supérieure ou égale à {{value}}',
      numericInfiniteIntervalMinValue: 'Saisir une valeur supérieure à {{value}}',
      numericInfiniteIntervalMaxValueIncluded: 'Saisir une valeur inférieure ou égale à {{value}}',
      numericInfiniteIntervalMaxValue: 'Entrez une valeur inférieure à {{value}}',
      numericIntervalSingleValue: 'Entrez {{value}}',
      combinedIntervalsText: '{{ combinedInterval }} ou {{ newInterval }}',
      additionalValue: 'Vous pouvez également saisir une valeur supplémentaire.',
      addToCartPrices: 'Prix de base {{basePrice}}, Options sélectionnées {{selectedOptions}}, Prix total {{totalPrice}}',
      filterOverview: 'Ouvrir le menu de filtrage de l\'aperçu de la configuration',
      filterOverviewWithCount: 'Ouvrir le menu des filtres de la vue d\'ensemble de la configuration - {{numAppliedFilters}} filtres actifs',
      closeFilterMenu: 'Fermer le menu des filtres de la vue d\'ensemble de la configuration et appliquer les filtres sélectionnés',
      filterOverviewByPrice: 'Filtrer la vue d\'ensemble de la configuration par options pertinentes en termes de prix',
      filterOverviewByMySelections: 'Filtrer la vue d\'ensemble de la configuration en fonction de vos sélections',
      filterOverviewByGroup: 'Filtrer la vue d\'ensemble de la configuration par groupe {{groupName}}',
      closeConflictSolverModal: 'Fermer la fenêtre modale de résolution des conflits',
      closeRestartDialog: 'Fermer la boîte de dialogue « Configuration non terminée » et revenir à la page des détails du produit.',
      description: 'Cliquez pour voir la description de cette valeur',
    },
    variantCarousel: {
      title: 'Appareils préconfigurés',
    },
  },
};
