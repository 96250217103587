
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { OccEndpointsService, ProductReferenceService, StateWithProduct } from '@spartacus/core';
import { HttpClient } from '@angular/common/http';
import { RelationList } from './model/product-relations.model';

@Injectable({
  providedIn: 'root',
})
export class RationalProductRelationsService extends ProductReferenceService {
  constructor(protected override store: Store<StateWithProduct>,
              protected http: HttpClient,
              protected occEndpointsService: OccEndpointsService) {
    super(store);
  }

  getRelationList(
    code: string,
  ): Observable<RelationList> {
    return this.http.get<RelationList>(this.occEndpointsService.buildUrl('relations/productRelations/${productCode}?fields=DEFAULT,references(target(images(FULL)))', {
      urlParams: { productCode: code }
    }));
  }

}
