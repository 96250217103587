export const exportEntries = {
  exportEntries: {
    exportToCsv: 'Eksport do CSV',
    exportMessage: 'Plik CSV zostanie automatycznie pobrany na urządzenie, jeśli tak się nie stanie, sprawdź ustawienia przeglądarki.',
    columnNames: {
      code: 'Kod',
      quantity: 'Ilość',
      name: 'Nazwa',
      price: 'Cena',
    },
    exportProductToCsv: 'Eksportuj koszyk do CSV',
  },
}

export const importEntries = {
  importEntries: {
    importProducts: 'Importuj koszyk',
  },
};

export const importEntriesDialog = {
  importEntriesDialog: {
    importProducts: 'Importuj koszyk',
    importProductsSubtitle: 'Import produktów przez import pliku .CSV lub .XLSX',
    importProductsNewSavedCartSubtitle: 'Importuj produkty poprzez import pliku .CSV lub .XLSX',
    importProductFileDetails: 'Importowany plik powinien zawierać listę produktów z kolumnami Ilość i numer artykułu oddzielonymi średnikiem.',
    selectFile: 'Wybierz plik',
    savedCartName: 'Nazwa zapisanego koszyka',
    savedCartDescription: 'Opis zapisanego koszyka',
    optional: 'opcjonalnie',
    charactersLeft: 'znaków w lewo: {{count}}',
    cancel: 'Anuluj',
    upload: 'Wyślij',
    close: 'Zamknij',
    summary: {
      info: 'Nie zamykaj ani nie odświeżaj tego okna podczas importowania produktów.',
      loadedToCart: 'Produkty zostały załadowane do koszyka {{ cartName }}.',
      loaded: 'Produkty zostały załadowane',
      loading: 'Produkty są przetwarzane... ({{ count }}/{{ total }})',
      successes: '„{{ successesCount }} z {{ total }} produktów zostało pomyślnie zaimportowanych.',
      warning: '{{ count }} produktów nie zostało zaimportowanych w całości.',
      warning_other: '{{ count }} produktów nie zostało zaimportowanych w całości.',
      error: '{{ count }} produkt nie został zaimportowany.',
      error_other: '{{ count }} produktów nie zostało zaimportowanych.',
      messages: {
        unknownIdentifier: 'Jednostka SKU produktu "{{ productCode}}" nie istnieje.',
        lowStock: '„Ilość dla {{ productName }}: {{ quantity }} została zmniejszona do {{ quantityAdded }}.',
        noStock: '{{ productName }} jest obecnie niedostępny.',
        unknownError: 'Nierozpoznany problem z "{{ productCode }}".',
        limitExceeded: 'Nie można dodać "{{ productCode }}". Przekroczono limit.',
      },
      show: 'Pokaż',
      hide: 'Ukryj',
    },
  },
};
