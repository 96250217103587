export const addressForm= {
  title: 'Título',
  defaultTitle: 'Título',
  firstName: {
    label: 'Nombre de pila',
    placeholder: 'Nombre de pila',
  },
  lastName: {
    label: 'Apellido',
    placeholder: 'Apellido',
  },
  companyName: 'Nombre de la empresa',
  companyNamePlaceholder: 'Buscar nombre de empresa',
  address1: 'Calle + Número de casa',
  address2: 'Dirección adicional',
  country: 'País',
  city: {
    label: 'Ciudad',
    placeholder: 'Ciudad',
  },
  state: 'Estado',
  zipCode: {
    label: 'Código postal',
    placeholder: 'Código postal',
  },
  phoneNumber: {
    label: 'Número de teléfono',
    placeholder: '(555) 555 - 0123',
  },
  saveAsDefault: 'Guardar por defecto',
  chooseAddress: 'Elija una dirección',
  streetAddress: 'Calle + Número de casa',
  aptSuite: 'Dirección adicional',
  selectOne: 'Seleccione uno...',
  setAsDefault: 'Establecer por defecto',
  titleRequired: 'El título es obligatorio.',
  userAddressAddSuccess: '¡Nueva dirección añadida con éxito!',
  userAddressUpdateSuccess: 'Dirección actualizada correctamente',
  userAddressDeleteSuccess: 'Dirección eliminada correctamente',
  invalidAddress: 'Dirección no válida',
}

export const addressBook = {
  addNewShippingAddress: 'Añadir una nueva dirección de envío',
  editShippingAddress: 'Modificar dirección de envío',
  areYouSureToDeleteAddress: '¿Está seguro de que desea eliminar esta dirección?',
  addNewAddress: 'Añadir nueva dirección',
  addAddress: 'Añadir dirección',
  updateAddress: 'Actualizar dirección',
  backToAddressList: 'Volver a la lista de direcciones',
}

export const addressCard = {
  default: 'estándar',
  selected: 'Seleccionado',
  setAsDefault: 'Establecer como predeterminado',
  shipTo: 'Enviar a',
  billTo: 'Facturar a',
}

export const addressSuggestion = {
  verifyYourAddress: 'Verifique su dirección',
  ensureAccuracySuggestChange: 'Para garantizar la exactitud de la entrega,le proponemos el cambio seleccionado a continuación.',
  chooseAddressToUse: 'Por favor,elija la dirección que desea utilizar:',
  suggestedAddress: 'Dirección propuesta',
  enteredAddress: 'Dirección indicada',
  editAddress: 'Modificar dirección',
  saveAddress: 'Guardar dirección',
}

export const address = {
  addressForm,
  addressBook,
  addressSuggestion,
  addressCard
}
