export const paymentForm = {
  payment: 'Pagamento',
  choosePaymentMethod: 'Scegliere un metodo di pagamento',
  paymentType: 'Tipo di pagamento',
  accountHolderName: {
    label: 'Nome del titolare del conto',
    placeholder: 'Nome del titolare del conto',
  },
  cardNumber: 'Numero della carta',
  expirationDate: 'Data di scadenza',
  securityCode: 'Codice di sicurezza (CVV)',
  securityCodeTitle: 'Numero di verifica della carta',
  saveAsDefault: 'Salva come predefinito',
  setAsDefault: 'Imposta come predefinito',
  billingAddress: 'Indirizzo di fatturazione',
  sameAsShippingAddress: 'Uguale all\'indirizzo di spedizione',
  selectOne: 'Selezionarne uno...',
  monthMask: 'MM',
  yearMask: 'AAAA',
  expirationYear: 'Anno di scadenza {{ selected }}',
  expirationMonth: 'Mese di scadenza {{ selected }}',
  useThisPayment: 'Utilizzare questo pagamento',
  addNewPayment: 'Aggiungere un nuovo pagamento',
  changePayment: 'Modificare il pagamento',
}

export const paymentMethods = {
  paymentMethods: 'Metodi di pagamento',
  newPaymentMethodsAreAddedDuringCheckout: 'I nuovi metodi di pagamento vengono aggiunti durante il checkout.',
  invalidField: 'Campo non valido: {{ field }}',
}

export const paymentCard = {
  deleteConfirmation: 'Sei sicuro di voler eliminare questo metodo di pagamento?',
  setAsDefault: 'Impostare come predefinito',
  expires: 'Scadenza: {{ month }}/{{ year }}',
  defaultPaymentMethod: 'âœ” DEFAULT',
  selected: 'Selezionato',
}

export const paymentTypes = {
  title: 'Metodo di pagamento',
  paymentType_CARD: 'Carta di credito',
  paymentType_ACCOUNT: 'Conto corrente',
  paymentType_INVOICE: 'Fattura',
}

export const payment = {
  paymentForm,
  paymentMethods,
  paymentCard,
  paymentTypes
}
