export const orderDetails = {
  statusDisplay_open: 'Not yet processed',
  statusDisplay_processing: 'Partially processed',
  statusDisplay_completed: 'Completely processed',
  purchaseOrderNumber: 'PO Number',
  orderNumber: 'RATIONAL Order Number',
  methodOfPayment: 'Method of Payment',
  shippingMethod: 'Shipping Method',
  placedOn: 'Placed on',
  moreInformation: 'More Information',
  orderDocuments: 'Order Documents',
  billing: 'Invoice',
  invoiceNumber: 'Invoice Number',
  invoiceDate: 'Date',
  netAmount: 'Net Amount',
  noBillingInvoices: 'No billing information available yet.',
  noOrderDocuments: 'No order documents available yet.',
  noInvoiceDocuments:'No invoice documents available yet.',
  noShipmentDocuments: 'No shipping documents available yet.',
  shipping: {
    shipping: 'Shipping',
    shippingId: 'Shipping Id',
    deliveryDate: 'Dispatch/Pickup Date',
    quantity: 'Quantity',
    tracking: 'Tracking',
    noShippingInfo: 'No shipping information available yet.',
    noTrackingInfo: 'No tracking information available yet.'
  },
  orderId: 'Order #',
  replenishmentId: 'Replenishment #',
  purchaseOrderId: 'Purchase Order #',
  emptyPurchaseOrderId: 'None',
  none: 'None {{value}}',
  placed: 'Placed',
  placedBy: 'Placed By',
  unit: 'Unit',
  costCenter: 'Cost Center',
  costCenterAndUnit: 'Cost Center / Unit',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  payByAccount: 'Pay by Account',
  paidByCreditCard: '(paid by credit card)',
  status: 'Status',
  active: 'Active',
  shippedOn: 'Shipped on',
  startOn: 'Start On',
  nextOrderDate: 'Next Order Date',
  frequency: 'Frequency',
  cancelled: ' Cancelled',
  deliveryStatus_IN_TRANSIT: 'In Transit',
  deliveryStatus_READY_FOR_PICKUP: 'Ready for Pickup',
  deliveryStatus_READY_FOR_SHIPPING: 'Ready for Shipping',
  deliveryStatus_WAITING: 'Waiting',
  deliveryStatus_DELIVERING: 'Delivering',
  deliveryStatus_PICKPACK: 'Preparing for Shipment',
  deliveryStatus_PICKUP_COMPLETE: 'Pickup Complete',
  deliveryStatus_DELIVERY_COMPLETED: 'Delivery Complete',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Payment Issue',
  deliveryStatus_READY: 'In Process',
  deliveryStatus_DELIVERY_REJECTED: 'Delivery Rejected',
  deliveryStatus_SHIPPED: 'Shipped',
  deliveryStatus_TAX_NOT_COMMITTED: 'Tax Issue',
  deliveryStatus_CANCELLED: 'Cancelled',
  statusDisplay_cancelled: 'Cancelled',
  statusDisplay_cancelling: 'Cancel Pending',
  statusDisplay_confirmed: 'Order confirmed',
  statusDisplay_created: 'Order created',
  statusDisplay_error: 'Pending',
  statusDisplay_Error: 'Pending',
  statusDisplay_pending: {
  approval: 'Pending Approval',
  merchant: {
      approval: 'Pending Merchant Approval'
    }
  },
  statusDisplay_approved: 'Approved',
  statusDisplay_rejected: 'Rejected',
  statusDisplay_merchant: {
    approved: 'Merchant Approved',
    rejected: 'Merchant Rejected'
  },
  statusDisplay_assigned: {
    admin: 'Assigned To Administrator'
  },
  consignmentTracking: {
    action: 'Track package',
    dialog: {
      header: 'Tracking Information',
      shipped: 'Shipped',
      estimate: 'Estimated Delivery',
      carrier: 'Delivery Service',
      trackingId: 'Tracking Number',
      noTracking: 'The package has not been dispatched from the warehouse. The tracking information will be available after the package is shipped.',
      loadingHeader: 'Consignment Tracking'
    }
  },
  cancellationAndReturn: {
    returnAction: 'Request a Return',
    cancelAction: 'Cancel Items',
    item: 'Item',
    itemPrice: 'Item Price',
    quantity: 'Max Quantity',
    returnQty: 'Quantity to Return',
    cancelQty: 'Quantity to Cancel',
    setAll: 'Set all quantities to maximum',
    totalPrice: 'Total',
    submit: 'Submit Request',
    returnSuccess: 'Your return request ({{rma}}) was submitted',
    cancelSuccess: 'Your cancellation request was submitted (original order {{orderCode}} will be updated)'
  },
  cancelReplenishment: {
  title: 'Cancel Replenishment',
  description: 'Cancel any future replenishment order?',
  accept: 'Yes',
  reject: 'No',
  cancelSuccess: 'Replenishment order #{{replenishmentOrderCode}} has been successfully cancelled'
  }
}

export const orderHistory = {
  orderHistory: 'Order history',
  orderId: 'Order number',
  date: 'Date',
  status: 'Status',
  PONumber: 'P.O. number',
  total: 'Total',
  noOrders: 'We don\'t have order data for this account yet.',
  startShopping: 'Start Shopping',
  sortBy: 'Sort by',
  sortOrders: 'Sort Orders',
  purchaseOrderNumber: 'P.O. number',
  notFound: 'No Orders Found',
  actions: 'Actions',
  filters: {
    orderNumber: 'Order Number',
    orderNumberPlaceholder: 'Enter Order Number',
    PONumber: 'PO Number',
    PONumberPlaceholder: 'Enter PO Number',
    dateRange: 'Date Range',
    dateRanges: {
      LAST_30_DAYS: 'Last 30 Days',
      LAST_3_MONTHS: 'Last 3 Months',
      LAST_6_MONTHS:'Last 6 Months',
      CURRENT_YEAR:'Current Year',
      LAST_YEAR: 'Last Year',
      LAST_2_YEARS: 'Year before Last'
    },
    applyFilters: 'Apply Filters',
    clearFilters: 'Reset Filters',
  },
  jumpToPageLabel: 'Jump to',
  jumpToPageInput: 'Page number'
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'ALL ORDERS ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
