import { RoutesConfig, RoutingConfig } from '@spartacus/core';

export const rationalCustomStorefrontRoutesConfig: RoutesConfig = {

  // pdp routes
  product: {
    paths: ['product/:productCode/details'],
    paramsMapping: { productCode: 'code' },
  },

};

export const rationalCustomRoutingConfig: RoutingConfig = {
  routing: {
    routes: rationalCustomStorefrontRoutesConfig,
  },
};