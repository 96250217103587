<ng-container *ngIf="orderEntry$ | async as orderEntry">
    <ng-container *ngIf="hasScheduleLines(orderEntry)">
        <div class="cx-code">
            <div *ngFor="let scheduleLine of orderEntry.scheduleLines; let i = index" class="cx-schedule-line-info" attr.aria-describedby="{{ getScheduleLineInfoId(i) }}">
                <div id="{{ getScheduleLineInfoId(i) }}">
                    {{
                        'cartDetails.s4omScheduleLinesEntryInfo'| cxTranslate: { quantity: scheduleLine.confirmedQuantity, date: scheduleLine.confirmedAt | cxDate: 'dd.MM.yyyy' } 
                    }}
                </div>
                <!-- <div class="cx-value" aria-hidden="true">
                    {{ scheduleLine?.confirmedAt | cxDate: 'M/d/yyyy' }}
                </div>
                <div class="cx-label" aria-hidden="true">
                    {{ 'cartDetails.s4omScheduleLinesQuantity' | cxTranslate }}
                </div>
                <div class="cx-value" aria-hidden="true">
                    {{ scheduleLine?.confirmedQuantity }}
                </div> -->
            </div>
        </div>
    </ng-container>
</ng-container>