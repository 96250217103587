import { NgModule } from '@angular/core';
import {
    IconModule,
    OutletPosition,
    OutletService,
    provideOutlet,
} from '@spartacus/storefront';
import { ConfiguratorIssuesNotificationModule } from '@spartacus/product-configurator/common';
import { RationalConfiguratorCartEntryInfoComponent } from './rational-configurator-cart-entry-info.component';
import { CommonModule } from '@angular/common';
import { UrlModule, I18nModule } from '@spartacus/core';
import { RationalConfigureCartEntryModule } from '../rational-configure-cart-entry/rational-configure-cart-entry.module';
import { RationalOutletsService } from '../../rational-id/services/rational-outlets.service';
import { RationalCartOutlets } from '../../model/rational-cart-outlets.model';

@NgModule({
    imports: [
        CommonModule,
        UrlModule,
        I18nModule,
        IconModule,
        ConfiguratorIssuesNotificationModule,
        RationalConfigureCartEntryModule,
    ],
    declarations: [RationalConfiguratorCartEntryInfoComponent],
    providers: [

        {
            provide: OutletService,
            useClass: RationalOutletsService
        },
        provideOutlet({
            id: RationalCartOutlets.DETAILS,
            position: OutletPosition.AFTER,
            component: RationalConfiguratorCartEntryInfoComponent,
        }),
    ],
})
export class RationalConfiguratorCartEntryInfoModule { }

