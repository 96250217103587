
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { OccEndpointsService } from "@spartacus/core";
import { Observable } from "rxjs";
import { ProductDownloadsResponse } from "./product-downloads-response";


@Injectable({
    providedIn: 'root',
})
export class ProductDownloadsOCCService {
    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
    ) { }


    getProductDownloads(productCode: String, languageIsoCode: String): Observable<ProductDownloadsResponse> {
        return this.http.get<ProductDownloadsResponse>(this.getProductDownloadsUrl(productCode, languageIsoCode));
    }


    protected getProductDownloadsUrl(productCode: String, languageIsoCode: String) {
        return this.occEndpoints.buildUrl('/downloads/productDownloads/' + productCode + '/' + languageIsoCode);
    }
}
