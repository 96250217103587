const visualPicking = {
  visualPickingProductList: {
    id: 'Article Number'
  },
  visualPickingProductFilter: {
    input: {
      placeholder: 'Filter by name or Article Number'
    }
  }
}

export const epdVisualization = {
  epdVisualization: {
    visualPicking
  }
};
