import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { FocusConfig, ICON_TYPE, LaunchDialogService } from '@spartacus/storefront';
import { Observable, of, timer } from 'rxjs';
import { RationalOrderInvoice } from 'src/app/custom/order/order-details/order-information.model';

@Component({
  selector: 'cx-billing-invoices-dialog',
  templateUrl: './order-billing-invoices.component.html',
  styleUrls: ['./order-billing-invoices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderBillingInvoicesDialogComponent implements OnDestroy {
  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button.btn-primary',
    focusOnEscape: true,
  };

  @ViewChild('dialogElement') dialogElement: ElementRef | undefined;

  visibleLineIndex = -1;
  iconTypes = ICON_TYPE;

  orderInvoiceDocumentCategoryType = "M";

  productInvoices$: Observable<RationalOrderInvoice[]> | undefined;

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    if (!this.dialogElement?.nativeElement.contains(event.target)) {
      this.close('Clicked outside dialog');
    }
  }

  constructor(
    protected el: ElementRef,
    private ref: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService
  ) {

    timer(1000).subscribe(() => {
      this.launchDialogService.data$.subscribe((data) => {
        this.productInvoices$ = of(data.invoices);
        this.ref.detectChanges();
      });
    });
  }


  showInvoiceDocuments(index: number) {
    if (this.visibleLineIndex == index) {
      this.visibleLineIndex = -1;
    } else {
      this.visibleLineIndex = index;
    }
  }


  close(reason: string): void {
    this.launchDialogService.closeDialog(reason)
  }

  ngOnDestroy(): void {
    this.close('close dialog on component destroy');
  }
}
