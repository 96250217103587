export const orderApprovalDetails = {
  back: 'Volver a la lista',
  showForm_APPROVE: 'Confirmar pedido...',
  showForm_REJECT: 'Cancelar pedido...',
  form: {
    title_APPROVE: 'Confirmar pedido {{orderCode}} con el valor {{orderTotal}}',
    title_REJECT: 'Cancelar pedido {{orderCode}} con el valor {{orderTotal}}',
    submit_APPROVE: 'Confirmar',
    submit_REJECT: 'Cancelar',
    cancel: 'Cancelar',
    comment_APPROVE: {
      label: 'Comentario (opcional,máximo 255 caracteres)',
      placeholder: '',
    },
    comment_REJECT: {
      label: 'Comentario (máximo 255 caracteres)',
      placeholder: '',
    },
  },
  permissionResults: {
    header: 'Detalles del pedido del cliente',
    permission: 'Autorización',
    approver: 'Responsable de autorización',
    status: 'Estado',
    approverComments: 'Comentarios',
    noApprovalComments: 'Ninguno',
    permissionType_B2BBudgetExceededPermission: 'Se ha superado el presupuesto asociado al centro de coste elegido',
    permissionType_B2BOrderThresholdPermission: 'El total del pedido ha superado el límite',
    permissionType_B2BOrderThresholdTimespanPermission: 'El total del pedido ha superado el límite por pedido',
  }
}

export const orderApprovalList = {
  orderCode: 'Pedido #',
  POCode: 'PEDIDO',
  placedBy: 'Realizado por',
  date: 'Fecha',
  status: 'Estado',
  total: 'Total',
  none: 'Ninguno',
  emptyList: 'No hay pedidos para aprobar en este momento.',
}

export const orderApprovalGlobal = {
  notification: {
    noSufficientPermissions: 'No hay permisos suficientes para acceder a esta página.',
  }
}

export const orderApproval = {
  orderApprovalDetails,
  orderApprovalList,
  orderApprovalGlobal
}
