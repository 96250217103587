export const orderDetails = {
  orderId: "订单号",
  orderNumber: "网店订单号",
  replenishmentId: "补货编号",
  purchaseOrderId: "采购单号",
  purchaseOrderNumber: "您的采购单号",
  emptyPurchaseOrderId: "无",
  none: "无 {{value}}",
  placed: "订购",
  placedBy: "订购者",
  unit: "单位",
  costCenter: "成本中心",
  costCenterAndUnit: '成本中心/单位',
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "付款方式",
  payByAccount: "通过账户支付",
  paidByCreditCard: "(用信用卡支付）",
  status: "状态",
  active: "已激活",
  shippedOn: "已发货日期",
  shippingMethod: "发货方式",
  placedOn: "订购日期",
  startOn: "开始日期",
  nextOrderDate: "下次订购日期",
  frequency: "频率",
  cancelled: "取消",
  deliveryStatus_IN_TRANSIT: "转运中",
  deliveryStatus_READY_FOR_PICKUP: "准备取货",
  deliveryStatus_READY_FOR_SHIPPING: "准备发货",
  deliveryStatus_WAITING: "等待",
  deliveryStatus_DELIVERING: "发货",
  deliveryStatus_PICKPACK: "准备装运",
  deliveryStatus_PICKUP_COMPLETE: "取货完成",
  deliveryStatus_DELIVERY_COMPLETED: "交货完成",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "付款问题",
  deliveryStatus_READY: "处理中",
  deliveryStatus_DELIVERY_REJECTED: "拒绝发货",
  deliveryStatus_SHIPPED: "已发货",
  deliveryStatus_TAX_NOT_COMMITTED: "税务问题",
  deliveryStatus_CANCELLED: "取消",
  statusDisplay_cancelled: "取消",
  statusDisplay_cancelling: "取消待定",
  statusDisplay_confirmed: "订单已确认",
  statusDisplay_completed: "订单已送达",
  statusDisplay_created: "创建订单",
  statusDisplay_error: "待处理",
  statusDisplay_Error: "待处理",
  statusDisplay_processing: "已创建订单",
  statusDisplay_open: "已创建订单",
  statusDisplay_pending: {
      approval: "待审批",
      merchant: {
          Approval: "待商户批准"
      }
  },
  statusDisplay_approved: "已批准",
  statusDisplay_rejected: "拒绝",
  statusDisplay_merchant: {
      Approved: "商家已批准",
      Rejected: "商家拒绝",
  },
  statusDisplay_assigned: {
      admin: "分配给管理员"
  },
  consignmentTracking: {
    action: "跟踪包裹",
    dialog: {
      header: "跟踪信息",
      shipped: "已发货",
      estimate: "预计送达",
      carrier: "送货服务",
      trackingId: "跟踪号码",
      noTracking: "包裹尚未从仓库发出。跟踪信息将在包裹寄出后提供。",
      loadingHeader: "货物跟踪"
    }
  },
  cancellationAndReturn: {
    returnAction: "申请退货",
    cancelAction: "取消项目",
    item: "项目",
    itemPrice: "项目价格",
    quantity: "最大数量",
    returnQty: "退货数量",
    cancelQty: "取消数量",
    setAll: "将所有数量设为最大值",
    totalPrice: "总计",
    submit: "提交退货申请",
    returnSuccess: "您的退货申请（{{rma}}）已提交。",
    cancelSuccess: "您的取消订单申请已提交（原始订单 {{orderCode}} 将被更新）"
  },
  cancelReplenishment: {
    title: "取消补货",
    description: "取消今后的补货订单？",
    accept: "是",
    reject: "否",
    cancelSuccess: "补货订单 #{{replenishmentOrderCode}} 已成功取消。"
  }
};

export const orderHistory = {
  orderHistory: "历史订单",
  orderId: "订单 #",
  placedBy: "联系人",
  emptyPurchaseOrderId: "无",
  date: "日期",
  status: "状态",
  total: "总计",
  noOrders: "我们没有此账户的订单记录。",
  noReplenishmentOrders: "我们没有该账户的补货订单记录。",
  startShopping: "开始购物",
  sortBy: "排序方式",
  replenishmentOrderHistory: "补货订单历史记录",
  replenishmentOrderId: "补货 #",
  purchaseOrderNumber: "采购单号",
  startOn: "开始时间",
  frequency: "频率",
  nextOrderDate: "下一个订单日期",
  cancel: "取消订单",
  cancelled: "取消",
  replenishmentHistory: "补货历史",
  notFound: "未找到订单"
};

export const wishlist = {
  empty: "您的愿望清单中还没有产品"
};

export const myAccount = {
  orderDetails,
  orderHistory,
  wishlist
};
