import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { OrderBillingInvoicesDialogComponent } from './order-billing-invoices/order-billing-invoices.component';

import '@spartacus/storefront';
import { OrderItemShippingDialogComponent } from './order-item-shipping/order-item-shipping.component';

declare module '@spartacus/storefront' {
  const enum LAUNCH_CALLER {
    BILLING_INVOICES = 'BILLING_INVOICES',
    SHIPPING_ITEMS = 'SHIPPING_ITEMS'
  }
}

export const defaultOrderInformationLayoutConfig: LayoutConfig = {
  launch: {
    BILLING_INVOICES: {
      inline: true,
      component: OrderBillingInvoicesDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
    SHIPPING_ITEMS: {
      inline: true,
      component: OrderItemShippingDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    }
  }
};
