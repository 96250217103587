<form class="cx-place-order-form form-check" [formGroup]="checkoutSubmitForm">
    <div class="form-group">
      <label>
        <input
          formControlName="termsAndConditions"
          class="scaled-input form-check-input"
          type="checkbox"
        />
        <span class="form-check-label">
          {{ 'checkoutReview.confirmThatRead' | cxTranslate }}          
          <a
            href="{{termsAndConditionsLink$ | async}}"
            class="cx-tc-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ 'checkoutReview.termsAndConditions' | cxTranslate }}
          </a>
        </span>
      </label>
    </div>
  
    <button
      (click)="submitForm()"
      class="btn btn-primary btn-block"
      [disabled]="termsAndConditionInvalid"
      [cxAtMessage]="'checkoutReview.orderInProcess' | cxTranslate"
    >
      {{ 'checkoutReview.placeOrder' | cxTranslate }}
    </button>

    <button
      (click)="submitQuoteForm()"
      class="btn btn-primary btn-block"
      [disabled]="termsAndConditionInvalid"
      [cxAtMessage]="'checkoutReview.orderInProcess' | cxTranslate"
    >
      {{ 'checkoutReview.placeQuoteOrder' | cxTranslate }}
    </button>
  </form>
  