import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrderConfirmationTotalsComponent, OrderDetailsService } from '@spartacus/order/components';
import { Order, OrderFacade } from '@spartacus/order/root';
import { Observable } from 'rxjs';

@Component({
  selector: 'cx-order-confirmation-totals',
  templateUrl: './order-confirmation-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalOrderConfirmationTotalsComponent extends OrderConfirmationTotalsComponent {
  override order$: Observable<Order | undefined> = this.orderDetailsService.getOrderDetails();

  constructor(protected override orderFacade: OrderFacade, protected orderDetailsService: OrderDetailsService) {
      super(orderFacade);
  }

}
