
<div class="rational-delivery-address-search-box" [attr.aria-label]="'searchBox.productSearch' | cxTranslate" role="search">

  <form [formGroup]="checkoutAddressSearchForm" (sumbit)="search(searchInput.value);">

    <label class="searchbox" [class.dirty]="!!searchInput.value">

        <!-- x icon to cancel search -->
      <button
        [disabled]="!searchInput.value || isLoadingResults"
        [attr.aria-label]="'common.reset' | cxTranslate"
        (mousedown)="clear(searchInput)"
        (keydown.enter)="clear(searchInput)"
        class="reset"
      >
        <cx-icon [type]="iconTypes.RESET"></cx-icon>
      </button>

      <input
        #searchInput
        id="deliveryAddressSearchInput"
        name="searchInput"
        formControlName="searchInput"
        [readonly]="isLoadingResults"
        [placeholder]="'addressForm.companyNamePlaceholder' | cxTranslate"
        autocomplete="off"
        aria-describedby="initialDescription"
        aria-controls="results"
        [attr.aria-label]="'addressForm.companyNamePlaceholder' | cxTranslate"
        (input)="changeSearchIcon()"
        (click)="toggle()"
        (keydown.escape)="close()"
        (keydown.enter)="
          close();
          search(searchInput.value);
        "
        (keydown.arrowup)="focusPreviousChild($any($event))"
        (keydown.arrowdown)="focusNextChildOnDownButtonClick($any($event))"
      />

      <!-- magnifier icon to do the search -->
      <button
        *ngIf="isLoadingResults != true"
        [attr.aria-label]="'common.search' | cxTranslate"
        class="search"
        type="submit"
        (click)="search(searchInput.value);"
      >
        <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
      </button>

      <!-- loading icon -->
      <div *ngIf="isLoadingResults">
        <div class="search-box-spinner">
          <div class="loader-container" role="status">
            <div class="loader">{{ 'spinner.loading' | cxTranslate }}</div>
          </div>
        </div>
      </div>
    </label>
  </form>


  <div
    *ngIf="results$ | async as result"
    class="delivery-address-search-results"
    id="delivery-address-search-results"
    (click)="close()"
    role="dialog"
  >
    <div
      *ngIf="result?.length == 0"
      class="message"
    >{{ 'searchBox.help.noMatch' | cxTranslate}}
    </div>

    <ul
      *ngIf="result && result!.length > 0"
      class="delivery-addresses-suggestions"
      attr.aria-label="{{ 'searchBox.ariaLabelSuggestions' | cxTranslate }}"
      role="listbox"
    >
      <li *ngFor="let address of result">
        <a
          role="option"
          [innerHTML]="address.addressText | cxHighlight: searchInput.value"
          (keydown.arrowup)="focusPreviousChild($any($event))"
          (keydown.arrowdown)="focusNextChildOnDownButtonClick($any($event))"
          (keydown.enter)="close()"
          (keydown.escape)="close()"
          (blur)="close()"
          (mousedown)="preventDefault($event)"
          (click)="
            dispatchSuggestionSelectedEvent({
              selectedAddress: address
            });
          "
        >
        </a>
      </li>
    </ul>
  </div>

  <div class="searchBoxWarningContainer">
    <div class="warning" *ngIf="checkoutAddressSearchForm.get('searchInput')?.errors?.['minlength']">
      {{'checkoutAddress.addressSearchMinLength' | cxTranslate: { length: minCharactersBeforeRequest} }}
    </div>
    <div class="error" *ngIf="salesforceSearchFailed">
      {{'checkoutAddress.errorSearchingSalesforce' | cxTranslate }}
    </div>
  </div>
</div>
