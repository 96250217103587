export const addressForm = {
  title: 'Titre',
  defaultTitle: 'Titre',
  firstName: {
    label: 'Prénom de la personne',
    placeholder: 'Nom de famille',
  },
  lastName: {
    label: 'Nom de famille',
    placeholder: 'Nom de famille',
  },
  companyName: 'Nom de l\'entreprise',
  companyNamePlaceholder: 'Rechercher le nom de l\'entreprise',
  address1: 'Rue + Numéro de maison',
  address2: 'Adresse complémentaire',
  country: 'Pays d\'origine',
  city: {
    label: 'Ville',
    placeholder: 'Ville',
  },
  state: 'Etat/ Région',
  zipCode: {
    label: 'Code postal',
    placeholder: 'Code postal/Zip',
  },
  phoneNumber: {
    label: 'Numéro de téléphone',
    placeholder: '(555) 555 - 0123',
  },
  saveAsDefault: 'Enregistrer comme adresse par défaut',
  chooseAddress: 'Choisir l\'adresse',
  streetAddress: 'Rue + Numéro de maison',
  aptSuite: 'Adresse supplémentaire',
  selectOne: 'Sélectionnez une adresse...',
  setAsDefault: 'Définir par défaut',
  titleRequired: 'Le titre est obligatoire.',
  userAddressAddSuccess: 'La nouvelle adresse a été ajoutée avec succès !',
  userAddressUpdateSuccess: 'L\'adresse a été mise à jour avec succès !',
  userAddressDeleteSuccess: 'L\'adresse a été supprimée avec succès !',
  invalidAddress: 'Adresse invalide',
}

export const addressBook = {
  addNewShippingAddress: 'Ajouter une nouvelle adresse de livraison',
  editShippingAddress: 'Modifier l\'adresse de livraison',
  areYouSureToDeleteAddress: 'Êtes-vous sûr de vouloir supprimer cette adresse ?',
  addNewAddress: 'Ajouter une nouvelle adresse',
  addAddress: 'Ajouter une adresse',
  updateAddress: 'Mettre à jour l\'adresse',
  backToAddressList: 'Retour à la liste d\'adresses',
}

export const addressCard = {
  default: 'PAR DÉFAUT',
  selected: 'Sélectionné',
  setAsDefault: 'Défini comme valeur par défaut',
  shipTo: 'Expédier à',
  billTo: 'Facturer à',
}

export const addressSuggestion = {
  verifyYourAddress: 'Vérifiez votre adresse',
  ensureAccuracySuggestChange: 'Pour garantir l\'exactitude de la livraison, nous vous suggérons de modifier l\'adresse sélectionnée ci-dessous.',
  chooseAddressToUse: 'Veuillez choisir l\'adresse que vous souhaitez utiliser :',
  suggestedAddress: 'Adresse suggérée',
  enteredAddress: 'Adresse saisie',
  editAddress: 'Modifier l\'adresse',
  saveAddress: 'Enregistrer l\'adresse',
}

export const address = {
  addressForm,
  addressBook,
  addressSuggestion,
  addressCard,
}
