export const organization = { 
    userRoles: {
      b2bcustomergroup: '客户',
      b2bapprovergroup: '审批人',
      b2bmanagergroup: '经理',
      b2badmingroup: '行政管理'
    },
    breadcrumb: '组织机构',
    notification: {
      noSufficientPermissions: '没有足够权限访问此页面',
      notExist: '此项目不存在',
      disabled: '您无法编辑已禁用的项目'
    },
    confirmation: {
      cancel: '取消',
      confirm: '确认',
      disable: '禁用',
      delete: '删除'
    },
    httpHandlers: {
      conflict: {
        budget: '代码为 {{ code }} 的预算已经存在。',
        costCenter: '代码为 {{ code }}} 的成本中心已存在。',
        unit: 'uid {{ code }} 的组织单位已存在。',
        user: '电子邮件 {{ code }} 的用户已存在',
        userGroup: '用户组，id {{ code }} 已存在',
        permission: '代码为 {{ code }}} 的审批权限已存在。',
        unknown: '服务器验证错误。'
      }
    }
};
