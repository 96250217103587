import { Component, Input } from '@angular/core';
import { OrderConsignedEntriesComponent } from '@spartacus/order/components';

@Component({
  selector: 'rational-order-consigned-entries',
  templateUrl: './rational-order-consigned-entries.component.html',
  styleUrl: './rational-order-consigned-entries.component.scss'
})
export class RationalOrderConsignedEntriesComponent extends OrderConsignedEntriesComponent {

  @Input() showOrderInformationLinks : Boolean = false;
}
