<div class="cx-document-body">
  <ng-container *ngIf="documents$ | async as documentsList; else documentsLoadingSpinner">
    <ng-container *ngIf="documentsList && documentsList.length > 0; else nodocumentsList">
      <table role="table">

        <thead *ngIf="showTableHead">
          <tr role="row" class="cx-item-list-header">
            <th role="columnheader" class="cx-item-list-desc">
              {{ 'downloads.documentTitle' | cxTranslate }}
            </th>
            <th role="columnheader" class="cx-item-list-qty">
              {{ 'downloads.download' | cxTranslate }}
            </th>
          </tr>
        </thead>

        <tbody class="cx-item-list-items">
          <ng-container *ngFor="let document of  documentsList; let i = index">
            <tr>
              <td role="cell">
                <div class="cx-value">{{getFileNameWithType(document)}} </div>
              </td>
              <td role="cell" class="downloadIcon">
                <div class="cx-value">
                  <a class="download-link" role="button" (click)="downloadDocument(document)">
                    <cx-icon [type]="iconTypes.DOWNLOAD" class="cx-documents-list-attachment-icon" title="{{
                        'downloads.download' | cxTranslate
                      }}"></cx-icon>
                  </a>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>


    <ng-template #nodocumentsList>
      <div class="no-documents-list">
        <div *ngIf="documentType == 'order'">
          {{ 'orderDetails.noOrderDocuments' | cxTranslate }}
        </div>
        <div *ngIf="documentType == 'invoice'">
          {{ 'orderDetails.noInvoiceDocuments' | cxTranslate }}
        </div>
        <div *ngIf="documentType == 'shipping'">
          {{ 'orderDetails.noShipmentDocuments' | cxTranslate }}
        </div>        
      </div>
    </ng-template>
  </ng-container>

  <ng-template #documentsLoadingSpinner>
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</div>