export const pageMetaResolver = {
  category: {
    title: '{{count}} resultado para {{query}}',
    title_plural: '{{count}} resultados para {{query}}',
  },
  checkout: {
    title: 'Finalizar compra',
  },
  search: {
    title: "{{count}} Resultado para '{{query}}'",
    title_plural: "{{count}} resultados para '{{query}}'",
    findProductTitle: "{{count}} resultado para cupom '{{coupon}}'",
    findProductTitle_plural: "{{count}} resultados para cupom '{{coupon}}'",
    default_title: 'Todos os produtos',
  },
  product: {
    description: '{{description}}',
    heading: '{{heading}}',
    title: '{{title}}',
  },
};

export const spinner = {
  loading: 'Carregando...',
};

export const navigation = {
  shopAll: 'Comprar tudo {{ navNode}} >',
  listPrice: {
    enableListPrice: 'Ativar preço de tabela',
    listPriceIsEnabled: 'O preço de tabela está ativado',
    listPriceIsDisabled: 'O preço de tabela está desativado',
  },
};

export const searchBox = {
  placeholder: 'Pesquisar aqui...',
  ariaLabelInput: 'Pesquisar aqui...',
  ariaLabelSuggestions: 'sugestões de digitação',
  ariaLabelProducts: 'resultados do produto',
  initialDescription:
    'Quando os resultados do preenchimento automático estiverem disponíveis, use as setas para cima e para baixo para revisar e digite para selecionar. Usuários de dispositivos sensíveis ao toque, explorem com o toque ou com gestos de deslizar.',
  suggestionsResult: '{{ count }} sugestão e',
  suggestionsResult_plural: '{{ count }} sugestões e',
  productsResult: '{{ count }} produto está disponível',
  productsResult_plural: '{{ count }} produtos estão disponíveis.',
  resetLabel: 'Redefinir pesquisa',
  help: {
    insufficientChars: 'Por favor, digite mais caracteres',
    noMatch: 'Não foi possível encontrar nenhum resultado',
    exactMatch: '{{term }}',
    empty: 'Pergunte-nos qualquer coisa',
  },
};

export const sorting = {
  date: 'Data',
  orderNumber: 'Número do pedido',
  rma: 'Número de devolução',
  replenishmentNumber: 'Número de reabastecimento',
  nextOrderDate: 'Data do próximo pedido',
};

export const httpHandlers = {
  badGateway: 'Ocorreu um erro no servidor. Tente novamente mais tarde.',
  badRequestPleaseLoginAgain: '{{ errorMessage }}. Faça login novamente.',
  badRequestOldPasswordIncorrect: 'A senha antiga está incorreta.',
  badRequestGuestDuplicateEmail:
    '{{ errorMessage }} O e-mail já existe. Faça o checkout com um e-mail diferente para se registrar usando uma conta de convidado.',
  conflict: 'Já existe.',
  forbidden:
    'Você não está autorizado a realizar essa ação. Entre em contato com o administrador se achar que isso é um erro.',
  gatewayTimeout: 'O servidor não respondeu, tente novamente mais tarde.',
  internalServerError:
    'Ocorreu um erro interno do servidor. Tente novamente mais tarde.',
  sessionExpired: 'Sua sessão expirou. Faça login novamente.',
  unknownError: 'Ocorreu um erro desconhecido',
  unauthorized: {
    common: 'Ocorreu um erro de autorização desconhecido',
    invalid_client: 'Credenciais de cliente com má avaliação',
  },
  validationErrors: {
    missing: {
      card_cardType:
        'O cartão de crédito selecionado não é compatível. Por favor, selecione outro.',
      card_accountNumber:
        'O número do cartão de crédito inserido não é válido.',
      card_cvNumber: 'O código de segurança inserido não é válido.',
      card_expirationMonth:
        'A data de validade do cartão de crédito inserida não é válida.',
      card_expirationYear:
        'A data de validade do cartão de crédito inserida não é válida.',
      billTo_firstName: 'O primeiro nome inserido não é válido.',
      billTo_lastName: 'O sobrenome inserido não é válido.',
      billTo_street1: 'O endereço inserido não é válido.',
      billTo_street2: 'O endereço inserido não é válido.',
      billTo_city:
        'A cidade informada não é válida para esse cartão de crédito.',
      billTo_state:
        'O estado/província informado não é válido para esse cartão de crédito.',
      billTo_country:
        'O país/região informado não é válido para esse cartão de crédito.',
      billTo_postalCode:
        'O código postal não é válido para esse cartão de crédito.',
      country: {
        isocode: 'País incompleto',
      },
    },
    invalid: {
      card_expirationMonth:
        'A data de validade do cartão de crédito inserida não é válida.',
      firstName: 'O primeiro nome inserido não é válido.',
      lastName: 'O sobrenome inserido não é válido.',
      password: 'A senha inserida não é válida.',
      uid: 'O UID não é válido.',
      code: 'O código não é válido.',
      email: 'O e-mail não é válido.',
    },
  },
  cartNotFound: 'Carrinho não encontrado.',
  invalidCodeProvided: 'Código inválido fornecido.',
};

export const miniCart = {
  item: '{{count}} item atualmente em seu carrinho',
  item_plural: '{{count}} itens atualmente em seu carrinho',
  total: '{{total}}',
  count: '{{count}}',
};

export const skipLink = {
  skipTo: 'Pular para',
  labels: {
    header: 'Cabeçalho',
    main: 'Conteúdo principal',
    footer: 'Rodapé',
    productFacets: 'Facetas do produto',
    productList: 'Lista de produtos',
  },
};

export const formErrors = {
  globalMessage: 'O formulário que você está tentando enviar contém erros.',
  required: 'Este campo é obrigatório',
  cxInvalidEmail: 'Este não é um formato de e-mail válido',
  cxInvalidPassword: 'Este não é um formato de senha válido',
  cxPasswordsMustMatch: 'Os campos de senha devem corresponder',
  cxEmailsMustMatch: 'Os campos de e-mail devem corresponder',
  cxStarRatingEmpty: 'O campo Rating é obrigatório',
  cxNoSelectedItemToCancel: 'Selecione pelo menos um item',
  cxNegativeAmount: 'O valor deve ser igual ou maior que zero',
  cxContainsSpecialCharacters: 'O campo não pode conter caracteres especiais',
  date: {
    required: 'Este campo é obrigatório',
    min: 'A data não pode ser anterior a {{min}}',
    max: 'A data não pode ser posterior a {{max}}',
    pattern: 'Use o formato de data aaaa-mm-dd',
    invalid: 'Use uma data válida',
  },
  file: {
    invalidQunatity:
      'Quantidade inválida para o produto nas linhas: {{indexes }}',
    missingArticleNumber: 'Número de artigo ausente nas linhas: {{ indexes }}',
    importCartReopenDialogueText:
      'Feche a caixa de diálogo de importação do carrinho, atualize seu arquivo e tente fazer o upload novamente.',
  },
};

export const errorHandlers = {
  scriptFailedToLoad: 'Falha ao carregar o script.',
  refreshThePage: 'Atualize a página.',
};

export const common = {
  pageMetaResolver,
  spinner,
  navigation,
  searchBox,
  sorting,
  httpHandlers,
  miniCart,
  skipLink,
  formErrors,
  errorHandlers,
  common: {
    cancel: 'Cancelar',
    delete: 'Excluir',
    remove: 'Remover',
    edit: 'Editar',
    restore: 'Restaurar',
    back: 'Voltar',
    submit: 'Enviar',
    continue: 'Continuar',
    save: 'Salvar',
    done: 'Feito',
    home: 'Início',
    noData: 'Não há dados.',
    confirm: 'Confirmar',
    more: 'mais',
    close: 'Fechar',
    loading: 'Carregando',
    menu: 'Menu',
    reset: 'Reiniciar',
    search: 'Pesquisar',
    add: 'Adicionar',
    userGreeting: 'Bem-vindo',
    privacySettings: 'Configurações de privacidade',
  },
};
