import { HttpClient } from "@angular/common/http";
import { OccEndpointsService, RoutingService, UserIdService } from "@spartacus/core";
import { Observable, catchError, combineLatest, map, of } from "rxjs";
import { Injectable } from "@angular/core";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { RationalCart } from "src/app/custom/model/rational-cart.model";

@Injectable({
    providedIn: 'root',
})
export class RationalOrderSummaryService {
    userId: string | undefined
    cartId: string | undefined

    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected userIdService: UserIdService,
        protected activeCartService: ActiveCartFacade,
        protected routingService: RoutingService
    ) { }


    public updateRequestedretrievaldate(requestedRetrievalDate: string | null) {
        this.userIdService.takeUserId().subscribe(userId => {
            this.userId = userId
          });

          this.activeCartService.getActiveCartId().subscribe(cartId => {
            this.cartId = cartId
          });

        const url = this.occEndpoints.buildUrl('users/${userId}/carts/${cartId}/requestedretrievaldate?requestedRetrievalAt=' + requestedRetrievalDate, {
            urlParams: { userId: this.userId, cartId: this.cartId },
          });
        this.http.put<string>(url, requestedRetrievalDate).subscribe(() => {
          this.activeCartService.reloadActiveCart();
        });
    }

    public removeRequestedretrievaldate() {
      this.userIdService.takeUserId().subscribe(userId => {
          this.userId = userId
        });

        this.activeCartService.getActiveCartId().subscribe(cartId => {
          this.cartId = cartId
          const url = this.occEndpoints.buildUrl('users/${userId}/carts/${cartId}/requestedretrievaldate?', {
              urlParams: { userId: this.userId, cartId: this.cartId },
            });
          this.http.delete<string>(url).pipe(
            catchError((error) => {
                throw (error);
            })
          );
        });
  }

    public getEarliestDeliveryDate(): Observable<String> {
      let url = ''
      this.userIdService.takeUserId().subscribe(userId => {
        this.userId = userId
      });

      this.activeCartService.getActiveCartId().subscribe(cartId => {
        this.cartId = cartId
        url = this.occEndpoints.buildUrl('users/${userId}/carts/${cartId}/earliestDeliveryDate', {
          urlParams: { userId: this.userId, cartId: this.cartId },
        });
      });


      return this.http.get<string>(url, { responseType: 'text' as 'json'});
  }


  checkDeliveryDateIsValid(): Observable<boolean > {
    return combineLatest([
      this.activeCartService.getActive().pipe(map((cart) => (cart as RationalCart).requestedRetrievalAt)),
      this.getEarliestDeliveryDate()
    ]).pipe(
      map(([cartDeliveryData, earliest]) => {
        if (this.formatResponseDate(cartDeliveryData).getTime() < this.formatEarliestDate(earliest).getTime()) {
          return false;
        } else {
          return true;
        }
      }),
      
      catchError((error) => {
        this.routingService.go({
          cxRoute: 'cart',
        });
        return of(false)
      })
    );
  }

  formatResponseDate(dateString: String): Date {
    const [year, month, day] = dateString.split('-');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  formatEarliestDate(dateString: String): Date {
    const [day, month, year] = dateString.split('.');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

}
