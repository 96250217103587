import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { RationalOrderSummaryComponent } from './order-summary/rational-order-summary.component';
import { RationalCartTotalComponent } from './cart-total/rational-cart-totals.component';
import { RationalAddedToCartDialogModule } from './added-to-cart-dialog/rational-added-to-cart-dialog/rational-added-to-cart-dialog.module';
import { RationalCartDetailsModule } from './cart-details/rational-cart-details/rational-cart-details.module';
import { RationalCartProceedToCheckoutModule } from './cart-proceed-to-checkout/rational-cart-proceed-to-checkout.module';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { IconModule } from '@spartacus/storefront';
import '../../model/rational.order.model';
import { RationalCheckoutOrderSummaryComponent } from './checkout-order-summary/rational-checkout-order-summary.component';
import { RationalDatePickerFormatter } from '../../rational-id/services/rational-date-picker-formatter';
import { RationalConfirmationOrderSummaryComponent } from './confirmation-order-summary/rational-confirmation-order-summary.component';
import { RationalActiveCartService } from './rational-active-cart.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    RationalAddedToCartDialogModule,
    RationalCartDetailsModule,
    RationalCartProceedToCheckoutModule,
    FormsModule,
    NgbModule,
    NgbDatepickerModule,
    IconModule
  ],
  declarations: [
    RationalOrderSummaryComponent,
    RationalCheckoutOrderSummaryComponent,
    RationalConfirmationOrderSummaryComponent,
    RationalCartTotalComponent
    ],
  exports: [
    RationalOrderSummaryComponent,
    RationalCheckoutOrderSummaryComponent,
    RationalConfirmationOrderSummaryComponent,
    RationalCartTotalComponent
    ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartTotalsComponent: {
          component: RationalCartTotalComponent,
        },
        RationalCheckoutOrderSummaryComponent: {
          component: RationalCheckoutOrderSummaryComponent,
        },
      }
    }),
    { 
      provide: NgbDateParserFormatter,
      useClass: RationalDatePickerFormatter
    },
    RationalActiveCartService,
    {
      provide: ActiveCartFacade,
      useExisting: RationalActiveCartService,
    },
  ]
})

export class RationalCartBaseModule { }
