import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { ImportExportOrderEntriesComponent } from '@spartacus/cart/import-export/components';
import { ContextService, LaunchDialogService } from '@spartacus/storefront';
import { Observable, of} from 'rxjs';
import { RationalSavedCartService } from 'src/app/custom/saved-cart/rational-saved-cart.service';

@Component({
  selector: 'cx-import-export-order-entries',
  templateUrl: './import-export-order-entries.component.html',
  styleUrls: ['./import-export-order-entries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalImportExportOrderEntriesComponent extends ImportExportOrderEntriesComponent implements OnInit{

  cart$: Observable<Cart> | undefined;
  shouldShowSharedCartNotifications$ = of(false);

  constructor(
    protected override contextService: ContextService,
    protected rationalSavedCartService: RationalSavedCartService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected activeCartService: ActiveCartFacade) {
    super(contextService);
  }
  ngOnInit(): void {
    this.cart$ = this.activeCartService.getActive();

    this.cart$?.subscribe((cart: Cart) => {
      if (!cart || !cart.entries || cart.entries.length == 0) {
        this.shouldShowSharedCartNotifications$ = of(true);
      } else {
        this.shouldShowSharedCartNotifications$ = of(false);
      }
    });
  }

}
