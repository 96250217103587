export const organization = {
  organization: {
    enabled: 'Activo',
    disabled: 'Desactivado',
    enable: 'Activar',
    disable: 'Desactivar',
    name: 'Nombre',
    code: 'Código',
    done: 'hecho',
    cancel: 'Cancelar',
    add: 'Añadir',
    create: 'Crear {{name}}',
    edit: 'Editar',
    save: 'Guardar {{name}}',
    delete: 'Borrar',
    assign: 'Gestionar',
    active: 'Activo',
    status: 'Estado',
    details: 'Detalles',
    messages: {
      emptyList: 'La lista está vacía',
    },
    userRoles: {
      b2bcustomergroup: 'Cliente',
      b2bapprovergroup: 'Responsable de autorización',
      b2bmanagergroup: 'Gestor',
      b2badmingroup: 'Administrador',
    },
    breadcrumb: 'Organización',
    notification: {
      noSufficientPermissions:
        'No hay permisos suficientes para acceder a esta página',
      notExist: 'Este elemento no existe',
      disabled: 'No puede editar un elemento desactivado',
    },
    confirmation: {
      cancel: 'CANCELAR',
      confirm: 'CONFIRMAR',
      disable: 'DESHABILITAR',
      delete: 'BORRAR',
    },
    httpHandlers: {
      conflict: {
        budget: 'El presupuesto con el código {{ code }} ya existe.',
        costCenter: 'El centro de costes con el código {{ code }} ya existe.',
        unit: 'La unidad organizativa con  identificación de usuario  {{ code }} ya existe.',
        user: 'El usuario con correo electrónico {{ code }} ya existe.',
        userGroup: 'Grupo de usuarios con id {{ code }} ya existe',
        permission:
          'El permiso de aprobación con el código {{ code }} ya existe.',
        unknown: 'Error de validación del servidor.',
      },
    },
  },
};
