import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import {
  CommonConfiguratorUtilsService,
  ConfigureCartEntryComponent,
} from '@spartacus/product-configurator/common';

@Component({
  selector: 'rational-configure-cart-entry',
  templateUrl: './rational-configure-cart-entry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalConfigureCartEntryComponent extends ConfigureCartEntryComponent {
  constructor(protected override commonConfigUtilsService: CommonConfiguratorUtilsService, protected winRef: WindowRef,) {
    super(commonConfigUtilsService);
  }

  isItCartPage(): boolean | undefined {
    return this.winRef.nativeWindow?.location.href.includes('/cart');
  }
}
