export const orderDetails = {
  orderId: "Número do pedido",
  orderNumber: "Número do pedido webshop",
  replenishmentId: "Número de reabastecimento",
  purchaseOrderId: "Número do pedido de compra",
  purchaseOrderNumber: "Seu número de pedido de compra",
  emptyPurchaseOrderId: "Nenhum",
  none: "Nenhum {{value}}",
  placed: "Inserido",
  placedBy: "Inserido por",
  unit: "Unidade",
  costCenter: "Centro de custo",
  costCenterAndUnit: 'Centro de custo / unidade',
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  methodOfPayment: "Método de pagamento",
  payByAccount: "Pagamento por conta",
  paidByCreditCard: "(pago com cartão de crédito)",
  status: "Status",
  active: "Ativo",
  shippedOn: "Enviado em",
  shippingMethod: "Método de envio",
  placedOn: "Colocado em",
  startOn: "Início em",
  nextOrderDate: "Data do próximo pedido",
  frequency: "Frequência",
  cancelled: "Cancelado",
  deliveryStatus_IN_TRANSIT: "Em trânsito",
  deliveryStatus_READY_FOR_PICKUP: "Pronto para coleta",
  deliveryStatus_READY_FOR_SHIPPING: "Pronto para envio",
  deliveryStatus_WAITING: "Em espera",
  deliveryStatus_DELIVERING: "Entrega",
  deliveryStatus_PICKPACK: "Preparando para remessa",
  deliveryStatus_PICKUP_COMPLETE: "Retirada concluída",
  deliveryStatus_DELIVERY_COMPLETED: "Entrega concluída",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Emissão de pagamento",
  deliveryStatus_READY: "Em processo",
  deliveryStatus_DELIVERY_REJECTED: "Entrega rejeitada",
  deliveryStatus_SHIPPED: "Enviado",
  deliveryStatus_TAX_NOT_COMMITTED: "Emissão de imposto",
  deliveryStatus_CANCELLED: "Cancelado",
  statusDisplay_cancelled: "Cancelado",
  statusDisplay_cancelling: "Cancelamento pendente",
  statusDisplay_confirmed: "Pedido confirmado",
  statusDisplay_completed: "Ordem entregue",
  statusDisplay_created: "Pedido criado",
  statusDisplay_error: "Pendente",
  statusDisplay_Error: "Pendente",
  statusDisplay_processing: "Ordem criada",
  statusDisplay_open: "Ordem criada",
  statusDisplay_pending: {
      approval: "Aprovação pendente",
      merchant: {
          Approval: "Aprovação pendente do comerciante"
      }
  },
  statusDisplay_approved: "Aprovado",
  statusDisplay_rejected: "Rejeitado",
  statusDisplay_merchant: {
      Approved: "Comerciante aprovado",
      Rejected: "Comerciante rejeitado",
  },
  statusDisplay_assigned: {
      admin: "Atribuído ao administrador"
  },
  consignmentTracking: {
    action: "Rastrear pacote",
    dialog: {
      header: "Informações de rastreamento",
      shipped: "Enviado",
      estimate: "Entrega estimada",
      carrier: "Serviço de entrega",
      trackingId: "Número de rastreamento",
      noTracking: "O pacote ainda não foi despachado do armazém. As informações de rastreamento estarão disponíveis depois que o pacote for enviado.",
      loadingHeader: "Rastreamento de consignação"
    }
  },
  cancellationAndReturn: {
    returnAction: "Solicitar uma devolução",
    cancelAction: "Cancelar itens",
    item: "Item",
    itemPrice: "Preço do item",
    quantity: "Quantidade máxima",
    returnQty: "Quantidade a ser devolvida",
    cancelQty: "Quantidade a ser cancelada",
    setAll: "Definir todas as quantidades como máximas",
    totalPrice: "Total",
    submit: "Enviar solicitação",
    returnSuccess: "Sua solicitação de devolução ({{rma}}) foi enviada",
    cancelSuccess: "Sua solicitação de cancelamento foi enviada (o pedido original {{orderCode}} será atualizado)"
  },
  cancelReplenishment: {
    title: "Cancelar reabastecimento",
    description: "Cancelar qualquer pedido de reabastecimento futuro?",
    accept: "Sim",
    reject: "Não",
    cancelSuccess: "O pedido de reabastecimento #{{replenishmentOrderCode}} foi cancelado com sucesso"
  }
};

export const orderHistory = {
  orderHistory: "Histórico do pedido",
  orderId: "Número do pedido",
  placedBy: "Pessoa de contato",
  emptyPurchaseOrderId: "Não há",
  date: "Data do pedido",
  status: "Status",
  total: "Total",
  noOrders: "Não temos registros de pedidos para essa conta.",
  noReplenishmentOrders: "Não temos registros de pedidos de reabastecimento para esta conta.",
  startShopping: "Iniciar compras",
  sortBy: "Ordenar por",
  replenishmentOrderHistory: "Histórico de pedidos de reabastecimento",
  replenishmentOrderId: "Reabastecimento #",
  purchaseOrderNumber: "Nº DO PEDIDO",
  startOn: "Início em",
  frequency: "Frequência",
  nextOrderDate: "Data do próximo pedido",
  cancel: "Cancelamento",
  cancelled: "Cancelado",
  replenishmentHistory: "Histórico de reabastecimento",
  notFound: "Nenhum pedido encontrado"
};

export const wishlist = {
  empty: "Ainda não há produtos em sua lista de desejos"
};

export const myAccount = {
  orderDetails,
  orderHistory,
  wishlist
};
