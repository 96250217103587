<ng-container *ngIf="order$ | async as order" >
    <ng-container *ngIf="!(isOrderLoading$ | async) ">
        <div class="row justify-content-end">
            <div class="cx-summary col-sm-12 col-md-6 col-lg-5 col-xl-4">
                <div class="cx-summary-heading">
                    {{ 'orderCost.orderSummary' | cxTranslate }}
                </div>

                <div class="cx-summary-partials">
                    <div class="cx-summary-row cx-summary-total">
                        <div class="col-6 cx-summary-label">
                            {{ 'orderCost.total' | cxTranslate }}
                        </div>

                        <div class="col-6 cx-summary-amount">
                            {{ order.totalPrice?.formattedValue }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
