export const cartDetails = {
  id: 'ID',
  proceedToCheckout: 'Přejít k pokladně',
  createQuoteFromCart: 'Požádat o cenovou nabídku',
  cartName: 'Košík #{{code}}',
  minOrderNotReached: 'Minimální hodnota objednávky je {{minOrderValue}}{{currency}}.',
  orderLocked: 'Pozor: V současné době nelze zadávat objednávky! Obraťte se prosím na svůj zákaznický servis. S tímto uživatelským účtem jsou problémy.',
  requestedRetrievalDateEmpty: 'Musíte zvolit požadované datum dodání',
  requestedRetrievalDateAdded: 'Úspěšně jste přidali požadované datum dodání',
  requestedRetrievalDateInvalid: 'Požadované datum dodání je neplatné',
  s4omScheduleLinesQuantity: 'Množství',
  s4omScheduleLinesEntryInfo: 'Potvrzené datum odeslání/vyzvednutí - {{quantity}} položky(í) - je {{date}}.',
  cartSubjectToPriceChange: 'Pozor: Ceny nově nakonfigurovaných zařízení jsou ceníkové. Cenu specifickou pro vašeho zákazníka obdržíte s formálním potvrzením objednávky.',
};

export const cartItems = {
  id: 'Číslo článku',
  description: 'Popis',
  item: 'Položka',
  itemPrice: 'Cena položky',
  quantity: 'Množství',
  equipmentNumber: 'Číslo zařízení:',
  quantityTitle: 'Množství představuje celkový počet této položky ve vašem košíku.',
  total: 'Celkem',
  cartTotal: 'Košík celkem (položka {{count}})',
  cartTotal_plural: 'Košík celkem ({{count}} položek)',
  actions: 'Akce',
  salesUnit: 'UOM',
  reference: 'Odkaz:',
  referencePlaceHolder: 'Odkaz',
  comment: 'Komentář:',
  commentPlaceHolder: 'Komentář',
  additionalContactEmailWarning: 'Zadejte prosím platnou e-mailovou adresu. (Neplatné e-maily nebudou uloženy.)',
  requestedDeliveryDate: 'Požadované datum odeslání/vyzvednutí:',
  requestedDeliveryDateTitle: 'Požadované datum odeslání/vyzvednutí',
  substitutionProductNotFound: 'Tento výrobek je nahrazen jiným výrobkem, ale nahrazovaný výrobek není v současné době k dispozici.',
};

export const orderCost = {
  orderSummary: 'Shrnutí objednávky',
  subtotal: 'Dílčí součet:',
  shipping: 'Odeslaní:',
  estimatedShipping: 'Odhadovaná doprava:',
  discount: 'Ušetřili jste:',
  salesTax: 'DPH:',
  grossTax: 'Celková částka objednávky nezahrnuje DPH ve výši',
  grossIncludeTax: 'Celková částka objednávky zahrnuje daň ve výši',
  total: 'Celkem:',
  toBeDetermined: 'TBD',
  requestedDeliveryDate: 'Požadované datum odeslání/vyzvednutí*',
  taxStatement: '*Všechny uvedené ceny jsou netto ceny bez daně pro konkrétního zákazníka. O konečné celkové netto ceně včetně daní budete informováni e-mailem s potvrzením objednávky, protože denně aktualizované netto ceny jsou přepočítávány v okamžiku objednání.',
  taxStatement2: 'Při objednávání příslušenství nebo náhradních dílů může být účtován příplatek za minimální množství, pokud netto hodnota bez DPH nedosahuje minimální hodnoty objednávky.',
};

export const saveForLaterItems = {
  itemTotal: 'Uloženo na později (položka {{count}})',
  itemTotal_plural: 'Uloženo na později ({{count}} položek)',
  cartTitle: 'Košík',
  saveForLater: 'Uložit na později',
  moveToCart: 'Přesunout do košíku',
  stock: 'Skladem',
  forceInStock: 'Skladem',
};

export const cart = {
  cartDetails,
  cartItems,
  orderCost,
  saveForLaterItems
};
