<ng-container *ngIf="configuration$ | async as configuration">
  <ng-container *ngIf="configuration.pricingEnabled">
    <div class="cx-price-summary-container">
      <div class="cx-total-summary">
        <ng-container  *ngIf="!configuration.hideBasePriceAndSelectedOptions">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="cx-summary-row cx-selected-options">
                  <div class="cx-label">
                    {{ 'configurator.priceSummary.selectedOptions' | cxTranslate }}:
                  </div>

                </div>
              </mat-panel-title>

            </mat-expansion-panel-header>

            <div class="cx-var-conds cx-summary-row cx-selected-options"
                 *ngFor="let item of getRationalPriceSummary(configuration?.priceSummary)">

              <div class="cx-label">{{ item.name }}</div>
              <div class="cx-amount">{{ item.price?.formattedValue }}</div>

            </div>
          </mat-expansion-panel>

        </ng-container>
        <div class="cx-summary-row cx-total-price">
          <div class="cx-label">
            {{ 'configurator.priceSummary.totalPricePerItem' | cxTranslate }}:
          </div>
          <div class="cx-amount">
            {{ configuration?.priceSummary?.currentTotal?.formattedValue }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
