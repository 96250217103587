export const organization = {
  organization: {
    enabled: 'Attivo',
    disabled: 'Disabilitato',
    enable: 'Abilitato',
    disable: 'Disattivare',
    name: 'Nome',
    code: 'Codice',
    done: 'fatto',
    cancel: 'Annullamento',
    add: 'Aggiungi',
    create: 'Crea {{name}}',
    edit: 'Modifica',
    save: 'Salva {{name}}',
    delete: 'Elimina',
    assign: 'Gestisci',
    active: 'Attivo',
    status: 'Stato',
    details: 'Dettagli',
    messages: {
      emptyList: 'L\'elenco è vuoto',
    },
    userRoles: {
      b2bcustomergroup: 'Cliente',
      b2bapprovergroup: 'Autorizzante',
      b2bmanagergroup: 'Responsabile',
      b2badmingroup: 'Amministratore',
    },
    breadcrumb: 'Organizzazione',
    notification: {
      noSufficientPermissions:
        'Non si ha il permesso ad accedere a questa pagina',
      notExist: 'Questo articolo non esiste',
      disabled: 'Non è possibile modificare un elemento inattivo',
    },
    confirmation: {
      cancel: 'ANNULLARE',
      confirm: 'CONFERMARE',
      disable: 'DISABILITARE',
      delete: 'CANCELLARE',
    },
    httpHandlers: {
      conflict: {
        budget: 'Il budget con codice {{ code }} esiste già.',
        costCenter: 'Il centro di costo con codice {{ code }} esiste già.',
        unit: 'Unità organizzativa con utente id {{ code }} già esistente.',
        user: 'Utente con email {{code }} già esistente.',
        userGroup: 'Il gruppo di utenti con id {{code}} esiste già.',
        permission:
          'Il permesso di approvazione con codice {{ code }} esiste già.',
        unknown: 'Errore di convalida del server.',
      },
    },
  },
};
