<h2 class="cx-checkout-title d-none d-lg-block d-xl-block">
  {{ 'checkoutMode.deliveryMethod' | cxTranslate }}
</h2>

<ng-container
  *ngIf="
    !(isUpdating$ | async) &&
      (supportedDeliveryModes$ | async) as supportedDeliveryModes;
    else loading
  "
>


  <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
  <div [formGroup]="mode" class="cx-delivery-mode-wrapper">
    <div role="radiogroup">
      <div class="form-check" *ngFor="let mode of supportedDeliveryModes">
        <input
          [attr.aria-checked]="getAriaChecked(mode.code)"
          class="form-check-input"
          role="radio"
          type="radio"
          id="deliveryMode-{{ mode.code }}"
          (change)="changeMode(mode.code, $event)"
          (click)="changeMode(mode.code, $event)"
          [value]="mode.code"
          formControlName="deliveryModeId"
        />
        <label
          class="cx-delivery-label form-check-label form-radio-label"
          for="deliveryMode-{{ mode.code }}"
        >
          <div class="cx-delivery-mode">
            {{ mode.name }}
            <span class="cx-delivery-mode-description"
              >({{ mode.description }})</span
            >
          </div>
          <div class="cx-delivery-price">
            {{ mode.deliveryCost?.formattedValue }}
          </div>
          <div class="cx-delivery-details"></div>
        </label>
      </div>
    </div>
    <ng-template
      [cxOutlet]="CartOutlets.DELIVERY_MODE"
      [cxOutletContext]="{
        item: activeCartFacade.getActive() | async
      }"
    >
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="activeCartFacade.hasPickupItems() | async">
  <h2 class="cx-checkout-title d-none d-lg-block d-xl-block">
    {{ 'checkoutMode.deliveryEntries' | cxTranslate }}
  </h2>

  <ng-template
    [cxOutlet]="CartOutlets.CART_ITEM_LIST"
    [cxOutletContext]="{
      items: activeCartFacade.getDeliveryEntries() | async,
      readonly: true
    }"
  >
  </ng-template>
</ng-container>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>

<ng-container cxInnerComponentsHost></ng-container>

<div *ngIf="cart$ | async as cart" class="specialShippingInstructions">
  {{ 'checkoutMode.specialShippingInstructions' | cxTranslate }}
  <textarea class="form-control specialShippingInstructionsTextArea" #specialShippingInstructions placeholder="{{ 'checkoutMode.specialShippingInstructionsPlaceholder' | cxTranslate }}" (blur)="updateCartSpecialShippingInstructions(specialShippingInstructions.value)" id="updateCartSpecialShippingInstructions" name="updateCartSpecialShippingInstructions" value="{{cart.specialShippingInstructions}}">
    {{ cart.specialShippingInstructions }}
  </textarea>
</div>

<div class="row cx-checkout-btns">
  <div class="col-md-12 col-lg-6">
    <button class="btn btn-block btn-secondary" (click)="back()">
      {{ backBtnText | cxTranslate }}
    </button>
  </div>
  <div class="col-md-12 col-lg-6">
    <button
      class="btn btn-block btn-primary"
      [disabled]="deliveryModeInvalid || (isSetDeliveryModeHttpError$ | async)"
      (click)="next()"
    >
      {{ 'common.continue' | cxTranslate }}
    </button>
  </div>
</div>

