import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalConfiguratorVariantCarouselComponent } from './configurator-variant-carousel/rational-configurator-variant-carousel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { CarouselModule, ProductCarouselModule } from '@spartacus/storefront';
import { RationalMediaModule } from '../../cmscomponents/media/rational-media.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [RationalConfiguratorVariantCarouselComponent],
  exports: [RationalConfiguratorVariantCarouselComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    I18nModule,
    CarouselModule,
    RationalMediaModule,
    RouterModule,
    UrlModule,
    ProductCarouselModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ConfiguratorVariantCarousel: {
          component: RationalConfiguratorVariantCarouselComponent,
        },
      },
    }),
  ],
})
export class RationalVariantCarouselModule {}
