import { Component, ViewEncapsulation } from '@angular/core';
import { LoginComponent } from '@spartacus/user/account/components';
import { RationalB2BUser } from '../../model/b2buser.model';
import { Observable } from 'rxjs';


@Component({
  selector: 'cx-login',
  templateUrl: './rational-login.component.html',
  styleUrls: ['./rational-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalLoginComponent extends LoginComponent{
  override user$!: Observable<RationalB2BUser | undefined>;

}
