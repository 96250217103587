import { Component, ViewEncapsulation } from '@angular/core';
import { MediaComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-media',
  templateUrl: './rational-media.component.html',
  styleUrls: ['./rational-media.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalMediaComponent extends MediaComponent {

}
