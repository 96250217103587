/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Configurator } from '@spartacus/product-configurator/rulebased/core/model/configurator.model';
import { ConfiguratorPriceSummaryComponent } from '@spartacus/product-configurator/rulebased';
import { RationalPriceSummary, VarConds } from '../../model/rational.product-configurator.model';

@Component({
  selector: 'cx-configurator-price-summary',
  templateUrl: './rational-configurator-price-summary.component.html',
  styleUrl: './rational-configurator-price-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalConfiguratorPriceSummaryComponent extends ConfiguratorPriceSummaryComponent {

 override configuration$: Observable<Configurator.Configuration> =
    this.configRouterExtractorService.extractRouterData().pipe(
      switchMap((routerData) => {
       return this.configuratorCommonsService.getConfiguration(
         routerData.owner
       );
      })
    );


  getRationalPriceSummary(priceSummary: any): VarConds[] {
    if(priceSummary){
      return (priceSummary as RationalPriceSummary).selectedOptionsVarConds!;
    }

    return [];
  }

}
