import { Component } from '@angular/core';
import { Product, ProductScope } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'rational-standard-delivery',
  templateUrl: './standard-delivery.component.html',
  styleUrls: ['./standard-delivery.component.scss']
})
export class StandardDeliveryComponent {

  product$: Observable<Product | null> = this.currentProductService.getProduct([
    ProductScope.DETAILS
  ]);

  constructor(protected currentProductService: CurrentProductService) { }

}

