import { Component, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { OrderDetailActionsComponent, OrderDetailsService } from '@spartacus/order/components';
import { Order } from '@spartacus/order/root';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, of, take } from 'rxjs';

@Component({
  selector: 'cx-order-details-actions',
  templateUrl: './rational-order-detail-actions.component.html',
  styleUrl: './rational-order-detail-actions.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RationalOrderDetailActionsComponent extends OrderDetailActionsComponent {

  constructor(
    protected launchDialogService : LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected override orderDetailsService: OrderDetailsService){
    super(orderDetailsService);
  }

  isOrderLoading$: Observable<boolean> = typeof this.orderDetailsService.isOrderDetailsLoading === 'function' ? this.orderDetailsService.isOrderDetailsLoading() : of(false);


  showOrderDocuments(order : Order) {
    
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.ORDER_DOCUMENTS,
      undefined,
      this.vcr,
      { orderCode: order.code }
    );
 
    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }
  }

}
