export const orderApprovalDetails = {
    back: "返回列表",
    showForm_APPROVE: "批准订单...",
    showForm_REJECT: "拒绝订单...",
    form: {
        title_APPROVE: "批准订单 {{orderCode}} 总计 {{orderTotal}}",
        title_REJECT: "拒绝订单 {{orderCode}} 总计 {{orderTotal}}",
        submit_APPROVE: "批准",
        submit_REJECT: "拒绝",
        cancel: "取消",
        comment_APPROVE: {
            label: "备注（可选，最多 255 个字符）",
            placeholder: "备注（最多 255 个字符）"
        },
        comment_REJECT: {
            label: "备注（最多 255 个字符）",
            placeholder: "备注（最多 255 个字符）"
        }
    },
    permissionResults: {
        header: "客户审批详情",
        permission: "权限",
        approver: "批准人",
        status: "状态",
        approverComments: "批准人备注",
        noApprovalComments: "无",
        permissionType_B2BBudgetExceededPermission: "已超出与所选成本中心相关的预算",
        permissionType_B2BOrderThresholdPermission: "订单总额超出每个订单限额",
        permissionType_B2BOrderThresholdTimespanPermission: "订单总额超出每个时间段限额"
    }
};

export const orderApproval = {
    orderApprovalDetails,
};
