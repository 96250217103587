import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AuthGuard,
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { IconModule, ListNavigationModule } from '@spartacus/storefront';
import { RationalOrderHistoryComponent } from './rational-order-history.component';
import { RationalOrderHistoryFiltersComponent } from './rational-order-history-filters/rational-order-history-filters.component';
import { OrderHistoryAdapter } from '@spartacus/order/core';
import { RationalOccOrderHistoryAdapter } from './rational-occ-order-history.adapter';
import { RationalOrderHistoryService } from './rational-order-history-service';
import { OrderHistoryFacade } from '@spartacus/order/root';
import { OrderHistoryService } from '@spartacus/order/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    IconModule,
  ],
  declarations: [
    RationalOrderHistoryComponent,
    RationalOrderHistoryFiltersComponent,
  ],
  exports: [
    RationalOrderHistoryComponent,
    RationalOrderHistoryFiltersComponent,
  ],
  providers: [
    { provide: OrderHistoryAdapter, useClass: RationalOccOrderHistoryAdapter },
    {
      provide: RationalOccOrderHistoryAdapter,
      useClass: RationalOccOrderHistoryAdapter,
    },
    RationalOrderHistoryService,
    {
      provide: OrderHistoryService,
      useValue: RationalOrderHistoryService,
    },
    provideConfig(<CmsConfig>{
      cmsComponents: {
        AccountOrderHistoryComponent: {
          component: RationalOrderHistoryComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
})
export class RationalOrderHistoryModule {}
