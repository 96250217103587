<rational-shared-cart-notification></rational-shared-cart-notification>
<ng-container *ngIf="savedCarts$ | async as savedCarts">
  <ng-container *ngIf="!(isLoading$ | async); else loading">
    <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
    <div class="cx-saved-cart-list-header">
      <h2>
        {{
          'savedCartList.savedCarts' | cxTranslate: { count: savedCarts.length }
        }}
      </h2>
    </div>

    <ng-container *ngIf="savedCarts!.length > 0; else noSavedCarts">
      <table class="table cx-saved-cart-list-table">
        <thead class="cx-saved-cart-list-thead-mobile">
          <th scope="col">
            {{ 'savedCartList.cartName' | cxTranslate }}
          </th>
          <th scope="col">{{ 'savedCartList.cartId' | cxTranslate }}</th>
          <th scope="col">{{ 'savedCartList.dateSaved' | cxTranslate }}</th>
          <th scope="col" class="cx-saved-cart-list-th-qty">
            {{ 'savedCartList.quantity' | cxTranslate }}
          </th>
          <th scope="col">
            {{ 'savedCartList.actions' | cxTranslate }}
          </th>
        </thead>
        <tbody>
          <tr
            *ngFor="let savedCart of savedCarts"
            (click)="goToSavedCartDetails(savedCart)"
          >
            <td class="cx-saved-cart-list-cart-name">
              <div class="cx-table-label-mobile cx-saved-cart-list-label">
                {{ 'savedCartList.cartName' | cxTranslate }}
              </div>
              <a
                [routerLink]="
                  {
                    cxRoute: 'savedCartsDetails',
                    params: { savedCartId: savedCart?.code }
                  } | cxUrl
                "
                class="cx-saved-cart-list-value"
              >
                {{ savedCart?.name }}</a
              >
            </td>
            <td class="cx-saved-cart-list-cart-id">
              <div class="cx-table-label-mobile cx-saved-cart-list-label">
                {{ 'savedCartList.cartId' | cxTranslate }}
              </div>
              <a
                [routerLink]="
                  {
                    cxRoute: 'savedCartsDetails',
                    params: { savedCartId: savedCart?.code }
                  } | cxUrl
                "
                class="cx-saved-cart-list-value"
                >{{ savedCart?.code }}</a
              >
            </td>
            <td class="cx-saved-cart-list-date-saved">
              <div class="cx-table-label-mobile cx-saved-cart-list-label">
                {{ 'savedCartList.dateSaved' | cxTranslate }}
              </div>
              <a
                [routerLink]="
                  {
                    cxRoute: 'savedCartsDetails',
                    params: { savedCartId: savedCart?.code }
                  } | cxUrl
                "
                class="cx-saved-cart-list-value"
                >{{ savedCart?.saveTime | cxDate: 'dd.MM.yyyy' }}</a
              >
            </td>
            <td class="cx-saved-cart-list-quantity">
              <div class="cx-table-label-mobile cx-saved-cart-list-label">
                {{ 'savedCartList.quantity' | cxTranslate }}
              </div>
              <a
                [routerLink]="
                  {
                    cxRoute: 'savedCartsDetails',
                    params: { savedCartId: savedCart?.code }
                  } | cxUrl
                "
                class="cx-saved-cart-list-value"
              >
                {{ savedCart?.totalUnitCount }}</a
              >
            </td>

            <td class="cx-saved-cart-list-make-cart-active">
              <button #element class="btn btn-tertiary cx-saved-cart-make-active no-margin">
                <a [routerLink]="{ cxRoute: 'savedCartsDetails', params: { savedCartId: savedCart?.code } } | cxUrl" class="cx-saved-cart-list-value">
                  {{ 'savedCartList.editCart' | cxTranslate }}
                </a>
              </button>

              <button #element class="btn btn-tertiary cx-saved-cart-make-active" (click)="openDialog($event, savedCart)">
                <span>{{ 'savedCartList.makeCartActive' | cxTranslate }}</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <!-- NO SAVED CART CONTAINER -->
    <ng-template #noSavedCarts>
      <div class="cx-saved-cart-list-no-saved-carts row">
        <div class="col-sm-12 col-md-6 col-lg-4">
          <div>{{ 'savedCartList.notFound' | cxTranslate }}</div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</ng-container>
