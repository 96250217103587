export const orderApprovalDetails = {
  back: 'Zurück zur Liste',
  showForm_APPROVE: 'Bestellung bestätigen...',
  showForm_REJECT: 'Bestellung stornieren...',
  form: {
    title_APPROVE: 'Bestellung {{orderCode}} mit dem Wert {{orderTotal}} bestätigen',
    title_REJECT: 'Bestellung {{orderCode}} mit dem Wert {{orderTotal}} stornieren',
    submit_APPROVE: 'Bestätigen',
    submit_REJECT: 'Stornieren',
    cancel: 'Abbrechen',
    comment_APPROVE: {
      label: 'Kommentar (optional, maximal 255 Zeichen)',
      placeholder: ''
    },
    comment_REJECT: {
      label: 'Kommentar (maximal 255 Zeichen)',
      placeholder: ''
    }
  },
  permissionResults: {
    header: 'Kunde Bestelldetails',
    permission: 'Genehmigung',
    approver: 'Genehmiger',
    status: 'Status',
    approverComments: 'Kommentare Genehmiger',
    noApprovalComments: 'Keine',
    permissionType_B2BBudgetExceededPermission: 'Das Budget der gewählten Kostenstelle wurde überschritten',
    permissionType_B2BOrderThresholdPermission: 'Die Bestellsumme hat das Limit pro Bestellung überschritten',
    permissionType_B2BOrderThresholdTimespanPermission: 'Die Bestellmenge hat das Limit pro Zeitraum überschritten'
  }
}

export const orderApprovalList = {
  orderCode: 'Bestellung #',
  POCode: 'PO #',
  placedBy: 'Angelegt von',
  date: 'Datum',
  status: 'Status',
  total: 'Summe',
  none: 'Keins',
  emptyList: 'Es gibt aktuell keine Bestellungen die genehmigt werden müssen.'
}

export const orderApprovalGlobal = {
  notification: {
    noSufficientPermissions: 'Keine Berechtigungen für den Zugriff auf diese Seite.'
  }
}

export const orderApproval = {
  orderApprovalDetails,
  orderApprovalList,
  orderApprovalGlobal
}