import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { CsvFileValidationErrors, FileReaderService, ImportCsvFileService } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RationalImportExportConfig } from "./../../../rational-import-export-config";
import { RationalImportCsvFileService } from './rational-import-csv-file.service';
import { RationalImportXlsxFileService } from './rational-import-xlsx-file.service';
import { RationalImportProductsFromFileService } from '../rational-import-products-from-file-service';

export enum FILE_EXTENSION {
  CSV = 'csv',
  XLSX = 'xlsx',
  TXT = 'txt',
  NOT_SUPPORTED = "none"
}

export interface FileValidationErrors extends CsvFileValidationErrors {
  fileNotAllowed?: boolean;
  invalidQunatity?: {
    indexes: string;
  };
  missingArticleNumber?: {
    indexes: string;
  };
  importCartReopenDialogueText?: boolean;
}

/**
 * We use ImportCsvFileService as parent generic Sevice
 * RationalImportCsvFileService to overide the parse function
 * RationalImportXlsxFileService to overide most of the funtions to adapt to xlsx files
 * 
 * RationalImportFileService acts as a splitter that uses RationalImportCsvFileService or RationalImportXlsxFileService according to the file type
 */


@Injectable({
  providedIn: 'root',
})
export class RationalImportFileService {
  fileExtension: FILE_EXTENSION = FILE_EXTENSION.NOT_SUPPORTED;

  constructor(
    protected fileReaderService: FileReaderService,
    protected importExportConfig: RationalImportExportConfig,
    protected rationalImportXlsxFileService: RationalImportXlsxFileService,
    protected rationalImportCsvFileService: RationalImportCsvFileService,
    protected rationalImportProductsFromFileService: RationalImportProductsFromFileService
  ) {
  }
  /**
   * Load csv/xlsx/txt file.
   *
   * @param file File we want to load as CSV/txt/xlsx
   * @param separator Separator for CSV data.
   * @return {Observable<string[][]>} Imported file
   */
  loadFile(file: File, separator: string): Observable<string[][]> {
    const errors: FileValidationErrors = {};
    this.fileExtension = this.getFileExtension(file.name, errors);

    if (this.fileExtension == FILE_EXTENSION.CSV || this.fileExtension == FILE_EXTENSION.TXT) {
      return this.rationalImportCsvFileService.loadFile(file, separator);

    } else {
      return this.rationalImportXlsxFileService.loadFile(file);
    }
  }

  validateFile(
    file: File,
    {
      separator,
      isDataParsable,
      maxEntries,
    }: {
      separator: string;
      isDataParsable?: (data: string[][], errors: FileValidationErrors) => boolean;
      maxEntries?: number;
    }
  ): Observable<FileValidationErrors | null> {

    const validationErrors: FileValidationErrors = {};
    const fileExtension: FILE_EXTENSION = this.getFileExtension(file.name, validationErrors);

    return (
      this.loadTextFile(file, fileExtension) as Observable<string>
    ).pipe(
      tap((data: string) => {
        this.validateEmpty(data, validationErrors);
        this.validateIsSupportedFileExtension(fileExtension, validationErrors);
      }),
      map((res) => this.parseTextFile(res, fileExtension, separator)),
      tap((data: string[][]) => {
        // use isDataParsableToValidProducts from rationalImportProductsFromFileService as it has more error handling
        this.validateNotParsable(data, validationErrors, this.rationalImportProductsFromFileService.isDataParsableToValidProducts);
        this.validateTooManyEntries(data, validationErrors, maxEntries);

        if (Object.keys(validationErrors).length > 0) {
          validationErrors.importCartReopenDialogueText = true;
        }
      }),
      catchError((errors) => of(errors)),
      map(() =>
        Object.keys(validationErrors).length === 0 ? null : validationErrors
      )
    )
  }


  validateEmpty(data: string, errors: FileValidationErrors): void {
    if (data.toString().length === 0) {
      errors.empty = true;
      throw errors;
    }
  }


  validateNotParsable(
    data: string[][],
    errors: FileValidationErrors,
    isDataParsable?: (data: string[][], errors: FileValidationErrors) => boolean
  ): void {
    if (isDataParsable && !isDataParsable(data, errors)) {
      errors.notParsable = true;
      throw errors;
    }
  }


  validateTooManyEntries(
    data: string[][],
    errors: FileValidationErrors,
    maxEntries?: number
  ): void {
    if (maxEntries && data.length > maxEntries) {
      errors.tooManyEntries = { maxEntries };
      throw errors;
    }
  }


  private getFileExtension(filename: string, errors: ValidationErrors): FILE_EXTENSION {
    const fileExtension = filename.split('.').pop() ?? "";
    switch (fileExtension) {
      case "csv": {
        return FILE_EXTENSION.CSV;
      }
      case "txt": {
        return FILE_EXTENSION.TXT;
      }
      case "xlsx": {
        return FILE_EXTENSION.XLSX;
      }
    }
    return FILE_EXTENSION.NOT_SUPPORTED;
  }


  
  private validateIsSupportedFileExtension(
    fileExtension: FILE_EXTENSION,
    errors: FileValidationErrors,
  ): void {
    if (fileExtension == FILE_EXTENSION.NOT_SUPPORTED) {
      errors.fileNotAllowed = true;
      throw errors;
    }
  }

  
  private loadTextFile(file: File, fileExtension: FILE_EXTENSION): Observable<string> {
    if (fileExtension == FILE_EXTENSION.CSV || fileExtension == FILE_EXTENSION.TXT) {
      return this.fileReaderService.loadTextFile(file) as Observable<string>;
    } else {
      return this.rationalImportXlsxFileService.loadTextFile(file) as Observable<string>
    }
  }

  private parseTextFile(fileContent: string, fileExtension: FILE_EXTENSION, separator: string,): string[][] {
    if (fileExtension == FILE_EXTENSION.CSV || fileExtension == FILE_EXTENSION.TXT) {
      return this.rationalImportCsvFileService.parse(fileContent, separator);
    } else {
      return this.rationalImportXlsxFileService.parse(fileContent);
    }
  }

}
