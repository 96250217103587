export const checkoutAddress = {
  shippingAddress: 'Lieferadresse',
  selectYourShippingAddress: 'Wählen Sie die Lieferadresse:',
  selectYourDeliveryAddress: 'Wählen Sie die Lieferadresse:',
  defaultShippingAddress: 'Standard Lieferadresse',
  addNewAddress: 'Neue Adresse hinzufügen',
  addressSearch: 'Adresssuche',
  shipToThisAddress: 'Als Lieferadresse wählen',
  addressSearchMinLength: 'Der Suchtext sollte mindestens {{length}} Zeichen lang sein',
  submitAddressSearch: 'Eingeben',
  errorSearchingSalesforce: 'Die Suche kann momentan nicht durchgeführt werden, bitte versuchen Sie es später noch einmal',
  deliveryAddressSelected: 'Lieferadresse ausgewählt'
}

export const checkoutOrderConfirmation = {
  confirmationOfOrder: 'Empfangsbestätigung der Bestellung:',
  confirmationOfQuote: 'Bestätigung des Angebotseingangs:',
  thankYou: 'Vielen Dank für Ihre Bestellung!',
  thankYouQuote: 'Vielen Dank für Ihre Angebotsanfrage!',
  invoiceHasBeenSentByEmailLine1: 'Bitte beachten Sie, dass es bis zu einem Arbeitstag dauern kann, bis diese Bestellung in Ihrer Bestellhistorie erscheint. Dort finden Sie neben der formellen Auftragsbestätigung auch das von RATIONAL bestätigte Versand- bzw.',
  invoiceHasBeenSentByEmailLine2: 'Abholdatum sowie die vollständigen Preisinformationen inklusive der anfallenden Steuern. Sollten Sie in der Zwischenzeit Fragen zu dieser Bestellung haben, wenden Sie sich bitte an Ihren RATIONAL-Kundenservice und geben Sie die oben angegebene Bestell-ID an.',
  quoteHasBeenSentByEmailLine1:'Bitte beachten Sie, dass Angebotsanfragen nicht in Ihre Bestellhistorie im Webshop übernommen werden. Wenn Sie Fragen zu dieser Angebotsanfrage haben, wenden Sie sich bitte an Ihren RATIONAL-Kundendienst',
  quoteHasBeenSentByEmailLine2:'und geben Sie die oben angegebene Webshop-Angebotsnummer oder Ihre eigene Auftrags-/Referenz-Nr. an.',
  orderItems: 'Auftragspositionen',
  orderPlacedSuccessfully: 'Bestellung wurde erfolgreich anlegt',
  createAccount: 'Registrieren?',
  createAccountForNext: 'Legen Sie einen Account für <{{email}}> an, damit Sie nächstes mal schneller auschecken können.'
}

export const checkoutReview = {
  review: 'Bestellübersicht',
  orderItems: 'Auftragspositionen',
  autoReplenishOrder: 'Bestellung automatisch auffüllen',
  confirmThatRead: 'Ich bestätige, dass ich folgende Dokumente gelesen und akzeptiert habe',
  placeOrder: 'Bestellung aufgeben',
  placeQuoteOrder: 'Angebot anfragen',
  scheduleReplenishmentOrder: 'Nachschubauftrag planen',
  orderType_PLACE_ORDER: 'Keiner (einmaliger Auftrag)',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Bestellung aufgeben',
  termsAndConditions: 'Geschäftsbedingungen',
  editShippingAddress: 'Lieferadresse bearbeiten',
  poNumber: 'Bestellnummer',
  every: 'Jede',
  startOn: 'Startdatum',
  dayOfMonth: 'Am Tag',
  repeatOnDays: 'Wiederholung an den folgenden Tagen',
  recurrencePeriodType_DAILY: 'Tag(e)',
  recurrencePeriodType_WEEKLY: 'Woche(n)',
  recurrencePeriodType_MONTHLY: 'Monat(e)'
}

export const checkoutShipping = {
  shippingMethod: 'Versandmethode',
  standardDelivery: 'Standard Lieferung',
  premiumDelivery: 'Premium Lieferung'
}

export const checkoutPO = {
  noPoNumber: 'Keine',
  poNumber: 'Eigene Bestellnummer',
  costCenter: 'Rechnung',
  placeholder: 'Eigene Bestellnummer eingeben...',
  availableLabel: 'Die verfügbaren Versandadressen sind abhängig von der Kostenstelle'
}

export const checkoutProgress = {
  methodOfPayment: 'Zahlungsmethode',
  deliveryAddress: 'Lieferadresse',
  deliveryMode: 'Versandart',
  paymentDetails: 'Zahlungsdetails',
  reviewOrder: 'Bestellübersicht'
}

export const checkoutB2B = {
  checkoutB2B: {
    poNumber: 'Eigene Bestellnummer*',
    placeholder: 'Eigene Bestellnummer eingeben...',
    costCenter: 'Rechnung',
    availableLabel: 'Die verfügbaren Versandadressen sind abhängig von der Kostenstelle',
    methodOfPayment: {
      paymentType: 'Zahlungsmethode'
    },
    progress: {
      methodOfPayment: 'Zahlungsmethode'
    },
    review: {
      poNumber: 'Eigene Bestellnummer'
    }
  }
}

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: 'Incoterms',
    paymentConditions: 'Zahlungsziel'
  }
}

export const checkoutMode = {
    deliveryMethod: 'Versandart',
    deliveryEntries: 'Zu versendende Artikel',
    specialShippingInstructions: 'Besondere Versandanweisungen',
    specialShippingInstructionsPlaceholder: 'Besondere Versandanweisungen'
}

export const checkout = {
  checkoutAddress,
  checkoutOrderConfirmation,
  checkoutReview,
  checkoutShipping,
  checkoutPO,
  checkoutProgress,
  checkoutMode,
  checkout : {
    backToCart: 'Zurück zum Warenkorb',
    invalid: {
      accountType: 'Ihr Account hat keine Berechtigungen zum auschecken.'
    }
  }
}
