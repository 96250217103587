export const addressForm = {
  title: 'Title',
  defaultTitle: 'Title',
  firstName: {
    label: 'First name',
    placeholder: 'First name'
  },
  lastName: {
    label: 'Last name',
    placeholder: 'Last name'
  },
  companyName: 'Company name',
  companyNamePlaceholder: 'Search for company name',
  address1: 'Street + House number',
  address2: 'Additional address',
  country: 'Country',
  city: {
    label: 'City',
    placeholder: 'City'
  },
  state: 'State',
  zipCode: {
    label: 'Zip code',
    placeholder: 'Postal code/Zip'
  },
  phoneNumber: {
    label: 'Phone number (optional)',
    placeholder: '(555) 555 - 0123'
  },
  saveAsDefault: 'Save as default',
  chooseAddress: 'Choose address',
  streetAddress: 'Street + House number',
  aptSuite: 'Additional address',
  selectOne: 'Select one...',
  setAsDefault: 'Set as default',
  titleRequired: 'Title is required.',
  userAddressAddSuccess: 'New address was added successfully!',
  userAddressUpdateSuccess: 'Address updated successfully!',
  userAddressDeleteSuccess: 'Address deleted successfully!',
  invalidAddress: 'Invalid Address'
}

export const addressBook = {
  addNewShippingAddress: 'Add a new shipping address',
  editShippingAddress: 'Edit shipping address',
  areYouSureToDeleteAddress: 'Are you sure you want to delete this address?',
  addNewAddress: 'Add new address',
  addAddress: 'Add address',
  updateAddress: 'Update address',
  backToAddressList: 'Back to address list'
}

export const addressCard = {
  default: 'DEFAULT',
  selected: 'Selected',
  setAsDefault: 'Set as default',
  shipTo: 'Ship To',
  billTo: 'Bill To'
}
export const addressSuggestion = {
  verifyYourAddress: 'Verify your address',
  ensureAccuracySuggestChange: 'To ensure delivery accuracy, we suggest the change selected below.',
  chooseAddressToUse: 'Please choose which address you would like to use:',
  suggestedAddress: 'Suggested address',
  enteredAddress: 'Entered address',
  editAddress: 'Edit address',
  saveAddress: 'Save address'
}

export const address = {
  addressForm,
  addressBook,
  addressSuggestion,
  addressCard
}
