<ng-container>
    <ng-select id="title-code-select" class="context-switcher-container" 
        [searchable]="true" 
        [clearable]="false"
        [hideSelected]="true" 
        [items]="options$" 
        bindValue="uid" 
        (change)="updateCurrentUserContext($event)"
        [(ngModel)]="selectB2BUnitId"
    >
        <ng-template ng-option-tmp ng-label-tmp let-item="item">
            {{ resolveDisplayName(item.uid) }}
        </ng-template>
    </ng-select>
</ng-container>