<ng-container *ngIf="!(isPriceLoaded$ | async); else showProductData ">
  <div class="price-spinner">
    <cx-spinner></cx-spinner>
    <p>{{'productDetails.priceLoading' | cxTranslate}}</p>
  </div>
</ng-container>

<ng-template #showProductData>
  <ng-container *ngIf="product$ | async as product else failedToLoadData">
    <ng-template [cxOutlet]="outlets.PRICE" [cxOutletContext]="{ product: product }">
      <div [attr.aria-label]="'productSummary.newItemPrice' | cxTranslate" class="price">
        {{ product?.price?.formattedValue }}
      </div>
    </ng-template>

    <ng-template [cxOutlet]="outlets.SUMMARY" [cxOutletContext]="{ product: product }">
      <p [innerHTML]="product?.summary" class="summary"></p>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #failedToLoadData>
  <span></span>
</ng-template>