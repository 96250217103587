import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { CheckoutPlaceOrderComponent } from '@spartacus/checkout/base/components';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { UntypedFormBuilder } from '@angular/forms';
import { GlobalMessageService, GlobalMessageType, LanguageService, OccEndpointsService, RoutingService } from '@spartacus/core';
import { RationalOrderFacade } from './checkout-place-order.facade';
import { RationalOrderSummaryService } from '../../cart/base/order-summary/rational-order-summary.service';
import { first, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'cx-place-order',
  templateUrl: './checkout-place-order.component.html',
  styleUrls: ['./checkout-place-order.component.scss']
})
export class RationalCheckoutPlaceOrderComponent extends CheckoutPlaceOrderComponent implements OnInit {

  constructor(
    protected override orderFacade: RationalOrderFacade,
    protected override routingService: RoutingService,
    protected override fb: UntypedFormBuilder,
    protected override launchDialogService: LaunchDialogService,
    protected override vcr: ViewContainerRef,
    private globalMessageService: GlobalMessageService,
    private rationalOrderSummaryService: RationalOrderSummaryService,
    protected occEndpoints: OccEndpointsService,
    private languageService : LanguageService,
    protected http: HttpClient,
  ) {
    super(orderFacade, routingService, fb, launchDialogService, vcr);

    this.languageService.getActive().subscribe(() => {
      this.termsAndConditionsLink$ = this.getTermsAndConditionsLink();
    })
  }
  ngOnInit(): void {
    this.termsAndConditionsLink$ = this.getTermsAndConditionsLink();
  }

  termsAndConditionsLink$ : Observable<string> | undefined;
    
  public getTermsAndConditionsLink(): Observable<string> {
    return this.http.get<string>(this.occEndpoints.buildUrl('termsandconditions'), { responseType: 'text' as 'json'});
  }

  submitQuoteForm(): void {
    if (this.checkoutSubmitForm.valid) {
      this.placedOrder = this.launchDialogService.launch(
        LAUNCH_CALLER.PLACE_ORDER_SPINNER,
        this.vcr
      );

      this.orderFacade.placeQuoteOrder(this.checkoutSubmitForm.valid).subscribe({
        error: () => {
          if (!this.placedOrder) {
            return;
          }

          this.placedOrder
            .subscribe((component) => {
              this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
              if (component) {
                component.destroy();
              }
            })
            .unsubscribe();
        },
        next: () => this.onSuccess(),
      });
    } else {
      this.checkoutSubmitForm.markAllAsTouched();
    }
  }

  override submitForm(): void {
    this.rationalOrderSummaryService.checkDeliveryDateIsValid().pipe(
      first(),
      tap((isValid) => {
        if (!isValid) {
          this.globalMessageService.add({ key: 'cartDetails.requestedRetrievalDateInvalid' }, GlobalMessageType.MSG_TYPE_ERROR);
        } else {
          super.submitForm();
        }
      })
    ).subscribe(_ => "");
  }
}
