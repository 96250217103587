import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import { CheckoutB2BStepsSetGuard } from '@spartacus/checkout/b2b/components';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { CardModule, IconModule, SearchBoxModule, SpinnerModule } from '@spartacus/storefront';
import { AddressFormModule } from "@spartacus/user/profile/components";
import { RationalCheckoutB2BStepsSetGuard } from '../rational-checkout-b2b-steps-set.guard';
import { RationalCheckoutDeliveryAddressSearchBoxComponent } from './checkout-delivery-address-search-box/checkout-delivery-address-search-box.component';
import { RationalCheckoutDeliveryAddressComponent } from './checkout-delivery-address.component';
import { RationalCheckoutAddressFormModule } from './rational-checkout-address-form/rational-checkout-address-form.module';

@NgModule({
  imports: [
    CommonModule,
    AddressFormModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    IconModule,
    SearchBoxModule,
    ReactiveFormsModule,
    RationalCheckoutAddressFormModule
  ],
  providers: [
    { provide: CheckoutB2BStepsSetGuard, useExisting: RationalCheckoutB2BStepsSetGuard },

    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutDeliveryAddress: {
          component: RationalCheckoutDeliveryAddressComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartValidationGuard]
        }
      }
    })
  ],

  declarations: [RationalCheckoutDeliveryAddressComponent, RationalCheckoutDeliveryAddressSearchBoxComponent],
  exports: [RationalCheckoutDeliveryAddressComponent, RationalCheckoutDeliveryAddressSearchBoxComponent],
})
export class RationalCheckoutDeliveryAddressModule { }
