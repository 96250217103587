export const productDetails = {
  id: 'Numer artykułu',
  quantity: 'Ilość',
  productDetails: 'Szczegóły produktu',
  specification: 'Cechy techniczne',
  reviews: 'Recenzje',
  shipping: 'Wysyłka',
  share: 'Udostępnij',
  showReviews: 'Pokaż opinie',
  noReviews: '',
  productPrice: 'Cena produktu',
  priceLoading:'Cena produktu jest obliczana',
  productRelations :{
    productRelationsHeader: 'Kompatybilne akcesoria',
  },
  disclaimer: {
    title: 'Uwaga dotycząca korzystania z obrazów:',
    text: "Zdjęcia przedstawione na niniejszej stronie internetowej mają charakter wyłącznie ilustracyjny i nie stanowią wiążącego odzwierciedlenia rzeczywistych cech produktu ani zakresu dostawy. Dokładne specyfikacje, właściwości i zakres dostawy produktów można znaleźć w odpowiednim opisie produktu w formie tekstowej."
  }
}

export const productList = {
  filterBy: {
    label: 'Filtruj według',
    action: 'Filtruj według',
  },
  appliedFilter: 'Zastosowany filtr:',
  showLess: 'Pokaż mniej...',
  showMore: 'Pokaż więcej...',
  sortBy: 'Sortuj według',
  backToTopBtn: 'POWRÓT DO GÓRY',
  showMoreBtn: 'POKAŻ WIĘCEJ',
  productSearchPagination: 'Paginacja wyszukiwania produktów',
}

export const productFacetNavigation = {
  filterBy: {
    label: 'Filtruj według',
    action: 'Filtruj według',
  },
  appliedFilter: 'Zastosowany filtr:',
  showLess: 'Pokaż mniej...',
  showMore: 'Pokaż więcej...',
  sortBy: 'Sortuj według',
}

export const productSummary = {
  id: 'Numer artykułu',
  showReviews: 'Pokaż recenzje',
  share: 'Udostępnij',
  newItemPrice: 'Cena nowego artyku?u',
  discount: 'Wyświetlane ceny zawierają indywidualny rabat w wysokości {{discount}}.',
  productSubstitutionAndProductExists: 'Produkt {{product1}} został zastąpiony produktem {{product2}}, w związku z czym użytkownik został przekierowany do produktu {{product2}}.',
  productSubstitutionAndProductDoesntExist: 'Produkt {{product1}} został zastąpiony produktem {{product2}}, ale {{product2}} nie jest obecnie dostępny.',
}

export const productReview = {
  overallRating: 'Ogólna ocena',
  reviewTitle: 'Tytuł recenzji',
  writeYourComments: 'Napisz swój komentarz',
  rating: 'Ocena',
  reviewerName: 'Imię i nazwisko recenzenta (opcjonalnie)',
  writeReview: 'Napisz recenzję',
  more: 'Pokaż więcej recenzji',
  less: 'Pokaż mniej recenzji',
  thankYouForReview: 'Dziękujemy za recenzję! Pamiętaj, że recenzje mogą wymagać sprawdzenia przed pojawieniem się tutaj.',
}

export const addToCart = {
  itemsAddedToYourCart: 'Produkty dodane do koszyka',
  itemsIncrementedInYourCart: 'Ten produkt był już w koszyku. Ilość została zaktualizowana.',
  items: 'pozycje',
  updatingCart: 'Aktualizacja koszyka...',
  addToCart: 'Dodaj do koszyka',
  viewCart: 'Zobacz koszyk',
  proceedToCheckout: 'Przejdź do kasy',
  quantity: 'Ilość',
  outOfStock: 'Brak w magazynie',
  inStock: 'W magazynie',
  selectStyleAndSize: 'Wybierz styl i rozmiar, aby sprawdzić stan magazynowy',
  removeFromCart: 'Usuń produkt z koszyka',
  closeModal: 'Zamknij Modal',
}

export const TabPanelContainer = {
  tabs: {
    ProductDetailsTabComponent: 'Szczegóły produktu',
    ProductSpecsTabComponent: 'Cechy techniczne',
    ProductReviewsTabComponent: 'Recenzje',
    deliveryTab: 'Wysyłka',
    ProductDownloadsTabComponent: 'Pliki do pobrania',
  }
}

export const addToWishList = {
  add: 'Dodaj do listy życzeń',
  remove: 'Usuń z listy życzeń',
  anonymous: 'Zaloguj się, aby dodać do listy życzeń',
}

export const itemCounter = {
  removeOne: 'Usuń jeden',
  addOneMore: 'Dodaj jeszcze jeden',
  quantity: 'Ilość',
}

export const productView = {
  gridView: 'Wybierz, aby przejść do widoku siatki',
  listView: 'Wybierz, aby zmienić na widok listy',
}

export const standardDelivery = {
  standardDelivery: {
    standardDeliveryRange: 'Gotowy do wysyłki od {{from}} do {{to}} dni',
  }
}

export const downloads = {
  downloads: {
    noDownloads: 'Brak pobrań dla tego produktu',
    documentTitle: 'Tytuł dokumentu',
    originalFileName: 'Nazwa pliku',
    languages: 'Języki',
    readableTypeMime: 'Typ Mime',
    fileSize: 'Rozmiar pliku',
    download: 'Pobieranie',
    toolbox: 'Więcej w Przyborniku',
    allDocuments: 'Wszystkie',
    languagesDropdown: 'Język:'
  }
}

export const productReferences = {
  productReferences: {
    installationvariants: 'Warianty konfiguracji',
    feedingsystems: 'Systemy ładowania',
    groomingaccessoires: 'Produkty do pielęgnacji',
    cookaccessoire: 'Akcesoria do gotowania',
    cookaccessoirespecial: 'Akcesoria specjalne',
    extractorhood: 'Okapy',
    deviceinstallation: 'Instalacja urządzenia',
  },
}

export const productImageZoom = {
  productImageZoomTrigger: {
    expand: 'Rozwiń obraz',
  },
  productImageZoomDialog: {
    close: 'Zamknij',
  },
}

export const product = {
  productDetails,
  productList,
  productFacetNavigation,
  productSummary,
  productReview,
  addToCart,
  TabPanelContainer,
  addToWishList,
  itemCounter,
  productView,
}
