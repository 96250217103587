<div *ngIf="entries$ | async as entries">
    <button
    *ngIf="entries.length > 0"
    class="btn cx-export-btn btn-block btn-primary"
    (click)="exportCsv(entries)"
  >
    {{ 'exportEntries.exportProductToCsv' | cxTranslate }}
  </button>

</div>
