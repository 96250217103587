import { ChangeDetectionStrategy, Component} from '@angular/core';
import { OrderConfirmationTotalsComponent, OrderDetailsService } from '@spartacus/order/components';
import { Order, OrderFacade } from '@spartacus/order/root';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CmsRationalOrderConfirmationTotalsComponent } from 'src/app/custom/model/rational-cms.model';

@Component({
  selector: 'cx-order-confirmation-totals',
  templateUrl: './order-confirmation-totals.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalOrderConfirmationTotalsComponent extends OrderConfirmationTotalsComponent {

  override order$: Observable<Order | undefined> = this.orderDetailsService.getOrderDetails();
  public componentData$: Observable<CmsRationalOrderConfirmationTotalsComponent> = this.componentData.data$;


  constructor(protected override orderFacade: OrderFacade, protected orderDetailsService: OrderDetailsService, protected componentData: CmsComponentData<CmsRationalOrderConfirmationTotalsComponent>) {
      super(orderFacade);
  }


}
