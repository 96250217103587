<ng-container *ngIf="cartModification$ | async as cartModification">
  <div class="alert alert-info" *ngIf="isVisible">
    <span class="alert-icon">
      <cx-icon [type]="iconTypes.INFO"></cx-icon>
    </span>
    <span>
      {{
      'validation.' + cartModification.statusCode
        | cxTranslate
        : {
          quantity: cartModification.quantityAdded
        }
      }}
    </span>

    <button class="close" type="button" (click)="isVisible = !isVisible">
      <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
    </button>
  </div>
</ng-container>
