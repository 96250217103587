import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from "@spartacus/core";
import { FocusConfig, HamburgerMenuService, ICON_TYPE, KeyboardFocusService } from "@spartacus/storefront";
import { UserAccountFacade } from "@spartacus/user/account/root";
import { Observable } from "rxjs";
import { RationalB2BUser } from '../../model/b2buser.model';

@Component({
  selector: 'custom-header-outlet',
  templateUrl: './header-outlet.component.html',
  styleUrls: ['./header-outlet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderOutletComponent implements OnInit, AfterViewInit {
  iconTypes = ICON_TYPE;
  isLoggedIn$: Observable<boolean> = this.authService.isUserLoggedIn();
  //user$: Observable<User | undefined> = this.userAccountFacade.get();
  user$: Observable<RationalB2BUser | undefined> = this.userAccountFacade.get()

  isExpanded$: Observable<boolean> = this.hamburgerMenuService.isExpanded;

  private keyboardFocusConfig: FocusConfig = {
    focusOnEscape: true,
    focusOnDoubleEscape: true,
  };
  
  globalMsgElementPosition: any;
  globalMsgElementPositionIsAbsolute: boolean = false;

  @ViewChild('globalMsg') set globalMsgElement(globalMsgElement: any) {
    this.globalMsgElementPosition = globalMsgElement?.nativeElement.offsetTop;
  };


  ngAfterViewInit() {
    this.globalMsgElementPosition = this.globalMsgElement?.nativeElement.offsetTop
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.globalMsgElementPosition) {
      this.globalMsgElementPositionIsAbsolute = true;
    } else {
      this.globalMsgElementPositionIsAbsolute = false;
    }
  }

  @HostListener('keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent): void {
    this.keyboardFocusService.handleEscape(
      this.elementRef.nativeElement,
      this.keyboardFocusConfig,
      event
    );
  }

  constructor(
    private hamburgerMenuService: HamburgerMenuService,
    private authService: AuthService,
    private userAccountFacade: UserAccountFacade,
    protected elementRef: ElementRef<HTMLElement>,
    protected keyboardFocusService: KeyboardFocusService) {
  }


  ngOnInit(): void {
    // empty OnInit method
  }

  collapseMenuIfClickOutside(event: any): void {
    const element = event.target;
    if (
      element.nodeName.toLowerCase() === 'header' &&
      element.className.includes('is-expanded')
    ) {
      this.collapseMenu();
    }
  }

  collapseMenu(): void {
    this.hamburgerMenuService.toggle(true);
  }


}



