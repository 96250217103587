<div class="image">
  <a
    [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
    class="cx-product-image-container"
    tabindex="-1"
  >
    <cx-media
      class="cx-product-image"
      [container]="product.images?.PRIMARY"
      format="product"
      [alt]="product.summary"
    ></cx-media>
  </a>
</div>
<div class="bottom">
  <div>
    <a
      [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
      class="cx-product-name"
      [innerHTML]="product.nameHtml"
    ></a><rational-product-description [product]="product"></rational-product-description>
  </div>
  <div>
    <a
      [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
      class="cx-product-name"
    >{{ 'productDetails.id' | cxTranslate }} {{ product.code }}</a>
  </div>
  <div class="addToCart">
    <div>
      <ng-template [cxOutlet]="ProductListOutlets.ITEM_ACTIONS">
        <cx-add-to-cart *ngIf="!hideAddToCartBtn && product.stock?.stockLevelStatus !== 'outOfStock'" [showQuantity]="false" [product]="product">
        </cx-add-to-cart>
      </ng-template>
    </div>
  </div>
</div>
