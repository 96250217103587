import { DeliveryMode } from '@spartacus/cart/base/root';
import { Card } from '@spartacus/storefront';


/**
 * Get card for delivery mode without delivery cost
 */
export function rationalDeliveryModeCard(
  textTitle: string,
  deliveryMode: DeliveryMode
): Card {
  return {
    title: textTitle,
    textBold: deliveryMode.name,
    text: [
      deliveryMode.description,
    ],
  } as Card;
}


