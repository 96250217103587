export const paymentForm = {
  payment: 'Paiement',
  choosePaymentMethod: 'Choisissez une méthode de paiement',
  paymentType: 'Type de paiement',
  accountHolderName: {
    label: 'Nom du titulaire du compte',
    placeholder: 'Nom du titulaire du compte',
  },
  cardNumber: 'Numéro de carte',
  expirationDate: 'Date d\'expiration',
  securityCode: 'Code de sécurité (CVV)',
  securityCodeTitle: 'Valeur de vérification de la carte',
  saveAsDefault: 'Enregistrer comme valeur par défaut',
  setAsDefault: 'Définir par défaut',
  billingAddress: 'Adresse de facturation',
  sameAsShippingAddress: 'Identique à l\'adresse de livraison',
  selectOne: 'Sélectionner un...',
  monthMask: 'MM',
  yearMask: 'AAAA',
  expirationYear: 'Année d\'expiration {{ selected }}',
  expirationMonth: 'Mois d\'expiration {{ selected }}',
  useThisPayment: 'Utiliser ce paiement',
  addNewPayment: 'Ajouter un nouveau paiement',
  changePayment: 'Modifier le paiement',
}

export const paymentMethods = {
  paymentMethods: 'Modes de paiement',
  newPaymentMethodsAreAddedDuringCheckout: 'Les nouveaux modes de paiement sont ajoutés lors de la validation de la commande.',
  invalidField: 'InvalidField : {{ field }}',
}

export const paymentCard = {
  deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer ce mode de paiement ?',
  setAsDefault: 'Défini par défaut',
  expires: 'Expire : {{ month }}/{{ year }}',
  defaultPaymentMethod: 'âœ » PAR DÉFAUT',
  selected: 'Sélectionné',
}

export const paymentTypes = {
  title: 'Mode de paiement',
  paymentType_CARD: 'Carte de crédit',
  paymentType_ACCOUNT: 'Compte',
  paymentType_INVOICE: 'Facture',
}

export const payment = {
  paymentForm,
  paymentMethods,
  paymentCard,
  paymentTypes
}
