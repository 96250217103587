import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { PageComponentModule } from '@spartacus/storefront';
import { RationalImportExportOrderEntriesComponent } from './import-export-order-entries.component';
import { ImportOrderEntriesModule, ExportOrderEntriesModule } from '@spartacus/cart/import-export/components';
import { RationalSharedCartNotificationModule } from "../../../../base/shared-cart-notification/shared-cart-notification.module";

@NgModule({
  imports: [
    PageComponentModule,
    ConfigModule.withConfig(<CmsConfig>{
        cmsComponents: {
            ImportExportOrderEntriesComponent: {
                component: RationalImportExportOrderEntriesComponent,
            },
        },
    }),
    I18nModule,
    UrlModule,
    ImportOrderEntriesModule,
    ExportOrderEntriesModule,
    CommonModule,
    RationalSharedCartNotificationModule
],
  exports: [RationalImportExportOrderEntriesComponent],
  declarations: [RationalImportExportOrderEntriesComponent],
})
export class RationalImportExportOrderEntriesModule {}
