import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalCartDetailsComponent } from './rational-cart-details.component';
import { provideConfig, CmsConfig, FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CartCouponModule, CartValidationWarningsModule } from '@spartacus/cart/base/components';
import { PromotionsModule } from '@spartacus/storefront';
import { RationalCartSharedModule } from '../../cart-item/rational-cart-shared.module';
import { RationalSharedCartNotificationModule } from "../../shared-cart-notification/shared-cart-notification.module";



@NgModule({
  declarations: [RationalCartDetailsComponent],
  imports: [
    CommonModule,
    RationalCartSharedModule,
    CommonModule,
    CartCouponModule,
    RouterModule,
    UrlModule,
    PromotionsModule,
    FeaturesConfigModule,
    I18nModule,
    CartValidationWarningsModule,
    RationalSharedCartNotificationModule
],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartComponent: {
          component: RationalCartDetailsComponent,
        },
      },
    }),
  ],
})
export class RationalCartDetailsModule { }
