import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { RationalConfiguratorOverviewAttributeComponent } from './rational-configurator-overview-attribute.component';
import { ConfiguratorPriceModule } from '@spartacus/product-configurator/rulebased';

@NgModule({
  imports: [CommonModule, I18nModule, ConfiguratorPriceModule],
  declarations: [RationalConfiguratorOverviewAttributeComponent],
  exports: [RationalConfiguratorOverviewAttributeComponent],
})
export class RationalConfiguratorOverviewAttributeModule {}