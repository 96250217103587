import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CheckoutPaymentTypeComponent } from '@spartacus/checkout/b2b/components';
import { CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { GlobalMessageService } from '@spartacus/core';


@Component({
  selector: 'cx-payment-type',
  templateUrl: './checkout-payment-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCheckoutPaymentTypeComponent extends CheckoutPaymentTypeComponent {

  public paymentForm : UntypedFormGroup =  this.fb.group({
    poNumber: ['', Validators.required]
  });

  constructor(
    protected _checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected _checkoutStepService: CheckoutStepService,
    protected _activatedRoute: ActivatedRoute,
    protected fb: UntypedFormBuilder,
    protected _globalMessageService: GlobalMessageService,
  ) {
      super(_checkoutPaymentTypeFacade, _checkoutStepService, _activatedRoute, _globalMessageService);
      this.cartPoNumber$.subscribe((poNumber) =>
        this.paymentForm.controls['poNumber'].setValue(poNumber)
      );
  }


  override next(): void {
    if (!this.typeSelected || this.paymentForm.get('poNumber')?.value == '') {
      return;
    }
    super.next();
  }
}
