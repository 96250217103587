import { Component, OnInit } from '@angular/core';
import { AuthRedirectService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { RationalAuthService } from '../services/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';

export const ERROR_CONTEXT_SWITCHING = 'error-context-switching-msg';

@Component({
  selector: 'rational-login',
  templateUrl: './rational-id-login.component.html',
  styleUrls: ['./rational-id-login.component.scss'],
})
export class RationalIdLoginComponent implements OnInit {
  
  loadingLogin: boolean = false;

  constructor(
    protected authService: RationalAuthService,
    protected authRedirectService: AuthRedirectService,
    protected oAuthService: OAuthService,
    protected globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.oAuthService.events.subscribe((item) => {
      if (item.type == 'token_received') {
        this.authRedirectService.redirect();
      }
    });

    this.tryLogin();
    this.showMsgIfExits();
  }

  login(): void {
    this.loadingLogin = true
    this.authService.loginRationalId();
  }

  private tryLogin(): void {
    const errorDuringLogin: string | null = window.localStorage.getItem(ERROR_CONTEXT_SWITCHING) ?? null

    if (!errorDuringLogin) {
      this.loadingLogin = true
      this.authService.loginRationalId();
    }
  }

  private showMsgIfExits(): void {
    const contextSwitchingMsg: string | null = window.localStorage.getItem(ERROR_CONTEXT_SWITCHING) ?? null;
    if (contextSwitchingMsg) {
      window.localStorage.removeItem(ERROR_CONTEXT_SWITCHING);
      this.globalMessageService.add(
        contextSwitchingMsg,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }  
}

