import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UrlModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { RationalConfigureCartEntryComponent } from './rational-configure-cart-entry.component';


@NgModule({
  imports: [CommonModule, UrlModule, I18nModule, IconModule, RouterModule],
  declarations: [RationalConfigureCartEntryComponent],
  exports: [RationalConfigureCartEntryComponent],
})
export class RationalConfigureCartEntryModule {}