import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FocusConfig,
  ICON_TYPE,
  LaunchDialogService,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { RationalOrderItemShipping } from 'src/app/custom/order/order-details/order-information.model';
import { RationalOrderDetailsService } from '../order-information.service';

@Component({
  selector: 'cx-order-shipping-dialog',
  templateUrl: './order-item-shipping.component.html',
  styleUrls: ['./order-item-shipping.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderItemShippingDialogComponent implements OnDestroy {
  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button.btn-primary',
    focusOnEscape: true,
  };

  @ViewChild('dialogElement') dialogElement: ElementRef | undefined;

  isClearing: boolean = false;
  ordershippingDocumentCategoryType = "J";
  visibleLineIndex = -1;
  visibleTrackingLineIndex = -1;

  iconTypes = ICON_TYPE;

  orderItemShippingList$: Observable<RationalOrderItemShipping[]> | undefined;

  @HostListener('click', ['$event'])
  handleClick(event: UIEvent): void {
    if (!this.dialogElement?.nativeElement.contains(event.target)) {
      this.close('Clicked outside dialog');
    }
  }

  constructor(
    protected el: ElementRef,
    protected launchDialogService: LaunchDialogService,
    protected rationalOrderDetailsService: RationalOrderDetailsService
  ) {
    this.launchDialogService.data$.subscribe((data) => {
      const orderCode = data.orderCode;
      const entryNumber = data.entryNumber;
      this.orderItemShippingList$ = this.showShippingItems(
        orderCode,
        entryNumber
      );
    });
  }

  showShippingItems(
    orderId: string | undefined,
    entryNumber: number | undefined
  ): Observable<RationalOrderItemShipping[]> {
    return this.rationalOrderDetailsService.getOrderItemShippingItems(
      orderId ?? '',
      entryNumber + ''
    ) as Observable<RationalOrderItemShipping[]>;
  }
  
  showShippingDocuments(index: number) {
    this.visibleTrackingLineIndex = -1;
    if(this.visibleLineIndex == index) {
      this.visibleLineIndex = -1;
    } else{
      this.visibleLineIndex = index;
    }
  }

  showTrackingDocuments(index: number) {
    this.visibleLineIndex = -1;
    if(this.visibleTrackingLineIndex == index) {
      this.visibleTrackingLineIndex = -1;
    } else{
      this.visibleTrackingLineIndex = index;
    }
  }

  close(reason: string): void {
    this.launchDialogService.closeDialog(reason);
  }

  ngOnDestroy(): void {
    this.close('close dialog on component destroy');
  }
}
