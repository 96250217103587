export const quickOrderCartForm = {
  entriesWasAdded: '({{ quantity }}) {{ product }} è stato aggiunto al carrello',
  entryWasAdded: '{{ product }} è stato aggiunto al carrello',
  noResults: 'Non è stato possibile trovare alcun prodotto',
  stockLevelReached: 'Livello di stock massimo raggiunto',
  title: 'Aggiungere direttamente al carrello',
  productCodePlaceholder: 'Inserire il codice articolo',
  entryProductCode: 'Inserire il codice articolo per ordine immediato',
  addToCart: 'Aggiungere al carrello',
  product: 'Prodotto',
  products: 'Prodotti',
  productCodeLabel: 'ID prodotto',
  quantityLabel: 'Quantità'
}

export const  quickOrderForm = {
  placeholder: 'Inserire SKU prodotto',
  listLimitReached: 'Il limite di articoli è stato raggiunto',
  id: 'Codice articolo {{ id }}'
}

export const  quickOrderList = {
  addToCart: 'Aggiungere al carrello',
  emptyList: 'Elenco vuoto',
  header: 'Aggiungere prodotti/SKU',
  subHeader: 'È possibile aggiungere fino a {{ limit }} SKU validi alla volta.',
  errorProceedingToCart: 'Errore nel procedere al carrello.',
  warningProceedingToCart: 'Avviso durante l\'aggiunta al carrello.',
  successfullyAddedToCart: 'Aggiunto al carrello con successo.',
  errors: {
    productIsOutOfStock: '{{name }} (#{{code}}) è esaurito.'
  },
  warnings: {
    productWasReduced: 'La quantità di {{name }} (#{{code}}) è stata ridotta a {{quantityAdded}}.'
  },
  successes: {
    productAddedToCart: '{{name }} (#{{code}}) ) è stato aggiunto al carrello.'
  }
}

export const quickOrderTable = {
  product: 'Prodotto',
  actions: 'Azioni',
  id: 'Codice articolo',
  price: 'Prezzo',
  quantity: 'Q.TÀ',
  itemPrice: 'Prezzo articolo',
  qty: 'Quantità',
  inStock: 'Disponibile',
  lowStock: 'Poca disponibilità',
  outOfStock: 'Esaurito',
  listCleared: 'La lista degli ordini rapidi è stata cancellata',
  addedtoCart: 'La lista degli ordini rapidi  è stata aggiunta al carrello'
}

export const quickOrder = {
  quickOrderCartForm,
  quickOrderForm,
  quickOrderList,
  quickOrderTable
}
