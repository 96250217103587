import {
  SAVED_CART_CLONE_CART_PROCESS_ID,
  SAVED_CART_RESTORE_CART_PROCESS_ID,
  SAVED_CART_SAVE_CART_PROCESS_ID,
} from '@spartacus/cart/saved-cart/core';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';

export const SAVE_SHARED_CART = '[Saved Shared Cart] Save Shared Cart';
export const RESTORE_SAVED_SHARED_CART =
  '[Saved Cart] Restore Saved Shared Cart';
export const CLONE_SAVED_SHARED_CART = '[Saved Cart] Clone Saved Shared Cart';

export class SaveSharedCart extends StateUtils.EntityLoadAction {
  override readonly type = SAVE_SHARED_CART;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      saveCartName?: string;
      saveCartDescription?: string;
      isSharedWithB2BUnit?: boolean;
    }
  ) {
    super(PROCESS_FEATURE, SAVED_CART_SAVE_CART_PROCESS_ID);
  }
}

export declare class SaveCart extends StateUtils.EntityLoadAction {
  payload: {
    userId: string;
    cartId: string;
    saveCartName?: string;
    saveCartDescription?: string;
    isSharedWithB2BUnit?: boolean;
  };
  readonly type = '[Saved Cart] Save Cart';
  constructor(payload: {
    userId: string;
    cartId: string;
    saveCartName?: string;
    saveCartDescription?: string;
    isSharedWithB2BUnit?: boolean;
  });
}

export class RestoreSavedSharedCart extends StateUtils.EntityLoadAction {
  override readonly type = RESTORE_SAVED_SHARED_CART;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
    }
  ) {
    super(PROCESS_FEATURE, SAVED_CART_RESTORE_CART_PROCESS_ID);
  }
}

export class CloneSavedSharedCart extends StateUtils.EntityLoadAction {
  override readonly type = CLONE_SAVED_SHARED_CART;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      saveCartName?: string;
    }
  ) {
    super(PROCESS_FEATURE, SAVED_CART_CLONE_CART_PROCESS_ID);
  }
}
