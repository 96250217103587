import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { B2BCheckoutDeliveryAddressComponent } from '@spartacus/checkout/b2b/components';
import { CheckoutCostCenterFacade, CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import {
  Address,
  EventService,
  GlobalMessageService,
  TranslationService,
  UserAddressService,
  UserCostCenterService,
  UserIdService
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { CheckoutDeliveryAddressesService } from './checkout-delivery-address-occ.service';
import { Card } from '@spartacus/storefront';

@Component({
  selector: 'cx-delivery-address',
  templateUrl: './checkout-delivery-address.component.html',
  styleUrls: ['./checkout-delivery-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCheckoutDeliveryAddressComponent extends B2BCheckoutDeliveryAddressComponent {
  constructor(
    protected override userAddressService: UserAddressService,
    protected override checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected override activatedRoute: ActivatedRoute,
    protected override translationService: TranslationService,
    protected override activeCartFacade: ActiveCartFacade,
    protected override checkoutStepService: CheckoutStepService,
    protected override checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected override globalMessageService: GlobalMessageService,
    protected override checkoutCostCenterFacade: CheckoutCostCenterFacade,
    protected override checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected override userCostCenterService: UserCostCenterService,
    protected checkoutDeliveryAddressesService: CheckoutDeliveryAddressesService,
    protected userIdService: UserIdService,
    protected eventService: EventService
  ) {
    super(
      userAddressService,
      checkoutDeliveryAddressFacade,
      activatedRoute,
      translationService,
      activeCartFacade,
      checkoutStepService,
      checkoutDeliveryModesFacade,
      globalMessageService,
      checkoutCostCenterFacade,
      checkoutPaymentTypeFacade,
      userCostCenterService
    );
  }

  protected selectedSearchAddress$!: Observable<Address>;

  override getSupportedAddresses(): Observable<Address[]> {
    return this.checkoutDeliveryAddressesService.getDeliveryAddresses();
  }

  override showNewAddressForm() {
    super.showNewAddressForm();
    this.isAccountPayment = false
  }

  populateSelectedAddressData(event: any) {
    this.selectedSearchAddress$ = of(event.selectedAddress.address);
  }

  override getCardContent(
    address: Address,
    selected: any,
    textDefaultDeliveryAddress: string,
    textShipToThisAddress: string,
    textSelected: string,
    textPhone: string,
    textMobile: string
  ): Card {

    const card = super.getCardContent(address, selected, textDefaultDeliveryAddress, textShipToThisAddress, textSelected, textPhone, textMobile);
    card.textBold = address.companyName ?? '';

    return card;
  }

}
