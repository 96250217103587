import { Component, ViewEncapsulation } from '@angular/core';
import { OrderSummaryComponent } from '@spartacus/cart/base/components';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { RationalOrderSummaryService } from './rational-order-summary.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { ICON_TYPE } from '@spartacus/storefront';
import { RationalCart } from 'src/app/custom/model/rational-cart.model';
import { first } from 'rxjs/operators';
import { CustomDatepickerI18n } from '../../../rational-id/services/rational-date-picker-i18n.component';
import '@angular/localize/init';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rational-cx-order-summary',
  templateUrl: './rational-order-summary.component.html',
  styleUrls: ['./rational-order-summary.component.scss'],
  providers: [{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }],
  encapsulation: ViewEncapsulation.None,
})
export class RationalOrderSummaryComponent extends OrderSummaryComponent {
  model!: NgbDateStruct;
  today = new Date();
  minDate!: NgbDateStruct;
  iconTypes = ICON_TYPE;
  requestedRetrievalAt: string | undefined;
  private entriesSubscription = new Subscription();

  constructor(
    private ngbCalendar: NgbCalendar,
    private rationalOrderSummaryService: RationalOrderSummaryService,
    private activeCartService: ActiveCartFacade,
  ) {
    super();
  }

  public isWeekend = (date: NgbDate) =>
    this.ngbCalendar.getWeekday(date) == 6 ||
    this.ngbCalendar.getWeekday(date) == 7;

  public onDateSelect($event: any) {    
    this.rationalOrderSummaryService.updateRequestedretrievaldate(
      this.dateBackendFormatter($event)
    );
  }

  public disableTyping($event: any) {
    $event.target.disabled = true;
  }

  private dateBackendFormatter = (date: { day: any; month: any; year: any }) =>
    `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day
      .toString()
      .padStart(2, '0')}`;

  dateFormatterDashes(dateString: String): NgbDateStruct {
    const [year, month, day] = dateString.split('-');
    const date = {
      day: parseInt(day.split(' ')[0].trim()),
      month: parseInt(month),
      year: parseInt(year),
    };
    return date;
  }

  dateFormatterDots(dateString: String): NgbDateStruct {
    const [day, month, year] = dateString.split('.');
    const date = {
      day: parseInt(day.split(' ')[0].trim()),
      month: parseInt(month),
      year: parseInt(year),
    };
    return date;
  }

  override ngOnInit(): void {
    this.activeCartService.getActive().pipe(first()).subscribe(cart => {          
      if ((cart as RationalCart).requestedRetrievalAt) {
        this.model = this.dateFormatterDashes((cart as RationalCart).requestedRetrievalAt);
      }      
    })
    
    this.entriesSubscription = this.activeCartService.getEntries().subscribe((entries) => {    
      if(entries.length > 0) {
        this.rationalOrderSummaryService
         .getEarliestDeliveryDate()
         .subscribe((data) => {
           this.minDate = this.dateFormatterDots(data)
         });
      }
    })

    if (this.outlet?.context$) {
      this.subscription.add(
        this.outlet.context$.subscribe((context) => (this.cart = context))
      );
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.entriesSubscription.unsubscribe()
  }
}
