export const paymentForm = {
    payment: "付款",
    choosePaymentMethod: "选择付款方式",
    paymentType: "付款类型",
    accountHolderName: {
        label: "账户持有人姓名",
        placeholder: "账户持有人姓名"
    },
    cardNumber: "卡号",
    expirationDate: "有效期",
    securityCode: "安全码 (CVV)",
    securityCodeTitle: "卡验证值",
    saveAsDefault: "保存为默认值",
    setAsDefault: "设置为默认",
    billingAddress: "账单地址",
    sameAsShippingAddress: "与送货地址相同",
    selectOne: "选择一个...",
    monthMask: "月",
    yearMask: "年",
    expirationYear: "到期年份 {{ selected }}",
    expirationMonth: "到期月份 {{ selected }}",
    useThisPayment: "使用此付款方式",
    addNewPayment: "添加新付款方式",
    changePayment: "更改付款方式"
};

export const paymentMethods = {
    paymentMethods: "付款方式",
    newPaymentMethodsAreAddedDuringCheckout: "在结账时添加新付款方式。",
    invalidField: "无效字段： {{ field }}"
};

export const paymentCard = {
    deleteConfirmation: "您确定要删除此付款方式吗？",
    setAsDefault: "设为默认",
    expires: "过期： {{month }}/{{ year }}",
    defaultPaymentMethod: "✓ 默认",
    selected: "已选择"
};

export const paymentTypes = {
    title: "付款方式",
    paymentType_CARD: "信用卡",
    paymentType_ACCOUNT: "账户",
    paymentType_INVOICE: "发票"
};

export const payment = {
    paymentForm,
    paymentMethods,
    paymentCard,
    paymentTypes
};
