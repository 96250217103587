<ng-container *ngIf="order$ | async as order">
    <div class="cx-page-header">
      <div *ngIf="!order.quote; else quoteHeader">
        <span class="cx-page-title">
          {{ 'checkoutOrderConfirmation.confirmationOfOrder' | cxTranslate }}
          {{ order.code }}
        </span>
      </div>
      <ng-template #quoteHeader>
        <span class="cx-page-title">
          {{ 'checkoutOrderConfirmation.confirmationOfQuote' | cxTranslate }}
          {{ order.code }}
        </span>
      </ng-template>
    </div>
  
    <div class="cx-order-confirmation-message">
      <div *ngIf="!order.quote; else quote">
        <h2>{{ 'checkoutOrderConfirmation.thankYou' | cxTranslate }}</h2>
        <p>
          {{ 'checkoutOrderConfirmation.invoiceHasBeenSentByEmailLine1' | cxTranslate }} <br>
          {{ 'checkoutOrderConfirmation.invoiceHasBeenSentByEmailLine2' | cxTranslate }}
        </p>
      </div>
      <ng-template #quote>
        <h2>{{ 'checkoutOrderConfirmation.thankYouQuote' | cxTranslate }}</h2>
        <p>
          {{ 'checkoutOrderConfirmation.quoteHasBeenSentByEmailLine1' | cxTranslate }} <br>
          {{ 'checkoutOrderConfirmation.quoteHasBeenSentByEmailLine2' | cxTranslate }} <br>
        </p>
      </ng-template>
    </div>
  
    <div *ngIf="isGuestCustomer">
      <cx-guest-register-form
        [guid]="orderGuid!"
        [email]="order.paymentInfo?.billingAddress?.email!"
      ></cx-guest-register-form>
    </div>
  
    <cx-add-to-home-screen-banner></cx-add-to-home-screen-banner>
  </ng-container>
  
