export const configurator = {
  configurator: {
    header: {
      consistent: 'Einheitlich',
      complete: 'Vollständig',
      configId: 'Konfiguration ID',
      toconfig: 'Konfigurieren',
      toConfigReadOnly: 'Details anzeigen',
      editConfiguration: 'Konfiguration bearbeiten,',
      displayConfiguration: 'Konfiguration anzeigen',
      resolveIssues: 'Probleme beheben',
      resolveIssue: 'Problem auflösen',
      resolveConflicts: 'Konflikte auflösen',
      conflictWarning: 'Konflikt muss aufgelöst werden, um fortzufahren',
      updateMessage: 'Die Konfiguration wird im Hintergrund aktualisiert',
      conflictsResolved: 'Konflikte wurden behoben',
      showMore: 'mehr anzeigen',
      showLess: 'weniger anzeigen',
      items: '{{count}} Artikel',
      items_other: '{{count}} Elemente',
      show: 'anzeigen',
      hide: 'ausblenden',
      multipleWarnings: 'Es gibt mehrere Warnungen.',
      reviewWarnings: 'Überprüfen Sie diese Warnungen',
      multipleErrors: 'Es liegen mehrere Fehler vor.',
      reviewErrors: 'Überprüfen Sie diese Fehler',
      kbKeyName: 'Kb-Name',
      kbKeyLogsys: 'Kb logisches System',
      kbKeyVersion: 'Kb-Version',
      kbKeyBuildNr: 'Kb Build-Nummer',
    },
    tabBar: {
      configuration: 'Konfiguration',
      overview: 'Übersicht',
    },
    notificationBanner: {
      numberOfIssues: '{{count}} Probleme müssen vor dem Auschecken behoben sein.',
      numberOfIssues_other: '{{count}} Probleme müssen vor dem Auschecken behoben werden.',
      numberOfConflicts: '{{count}} Konflikt muss vor dem Auschecken gelöst werden.',
      numberOfConflicts_other: '{{count}} Konflikte müssen vor dem Auschecken gelöst werden.',
    },
    attribute: {
      id: 'Artikelnummer',
      quantity: 'Menge',
      caption: 'Attribute',
      notSupported: 'Attribut Typ wird nicht unterstützt.',
      requiredAttribute: '{{param}} erforderlich',
      defaultRequiredMessage: 'Geben Sie einen Wert für das erforderliche Feld ein',
      singleSelectRequiredMessage: 'Wählen Sie einen Wert',
      singleSelectAdditionalRequiredMessage: 'Wählen Sie einen Wert oder geben Sie einen Wert ein',
      multiSelectRequiredMessage: 'Wählen Sie einen oder mehrere Werte aus',
      wrongNumericFormat: 'Falsches Format, dieses numerische Attribut sollte nach dem Muster {{Muster}} eingegeben werden',
      wrongNumericFormatMessage: 'Geben Sie die Zahl in folgendem Format ein',
      wrongIntervalFormat: 'Geben Sie einen Wert innerhalb der angegebenen Grenzen ein',
      deselectionNotPossible: 'Fügen Sie ein anderes Produkt hinzu, bevor Sie dieses entfernen',
      dropDownSelectMsg: 'Eine Auswahl treffen',
      noOptionSelectedMsg: 'Keine Option ausgewählt',
      notVisibleAttributeMsg: 'Dieses Attribut ist für den Benutzer nicht sichtbar',
    },
    button: {
      previous: 'Vorherige',
      next: 'Nächste',
      back: 'Zurück',
      more: 'mehr',
      less: 'weniger',
      deselect: 'Aufheben der Auswahl',
      select: 'Wählen Sie',
      add: 'Hinzufügen',
      remove: 'Entfernen',
      exit: 'Beenden Konfiguration',
      exitMobile: 'Beenden',
      cancelConfiguration: 'Abbrechen Konfiguration',
      cancelConfigurationMobile: 'Abbrechen',
      filterOverview: 'Filtern',
      filterOverviewWithCount: 'Filter ({{numAppliedFilters}})',
    },
    icon: {
      groupComplete: 'Vollständig',
      groupIncomplete: 'Unvollständig',
      groupConflict: 'Konflikt',
      subgroup: 'hat Untergruppen',
    },
    priceSummary: {
      basePrice: 'Grundpreis',
      selectedOptions: 'Ausgewählte Optionen',
      totalPrice: 'Gesamt',
      totalPricePerItem: 'Gesamt pro Artikel',
    },
    addToCart: {
      button: 'Zum Warenkorb hinzufügen',
      buttonAfterAddToCart: 'Weiter zum Warenkorb',
      buttonForQuote: 'Weiter zum Angebot',
      buttonUpdateCart: 'Erledigt',
      buttonDisplayOnly: 'Erledigt',
      buttonClose: 'Schließen',
      confirmation: 'Die Konfiguration wurde dem Warenkorb hinzugefügt',
      confirmationUpdate: 'Warenkorb wurde mit der Konfiguration aktualisiert',
      confirmationQuoteUpdate: 'Angebot wurde mit der Konfiguration aktualisiert',
      quantity: 'Menge',
      selectVariant: 'Es wurde mehr als eine existierende Variante für Ihre Konfiguration gefunden. Bitte wenden Sie sich in diesem Fall an den Kundendienst, um die richtige Variante auszuwählen.',
      addMatchingVariant: 'Die existierende Variante für Ihre Konfiguration wurde zum Warenkorb hinzugefügt.'
    },
    overviewForm: {
      noAttributeHeader: 'Keine Ergebnisse,',
      noAttributeText: 'Entfernen Sie den/die Filter, um den Inhalt der Übersicht zu sehen,',
      itemPrice: 'Artikel Preis',
    },
    overviewSidebar: {
      menu: 'Menü',
      filter: 'Filter',
    },
    overviewFilter: {
      title: 'Filter auswählen',
      byOption: 'Nach Option filtern',
      byGroup: 'Nach Gruppe filtern',
      byPrice: 'Preisrelevante Optionen',
      mySelections: 'Meine Auswahlen',
      removeAll: 'Alle entfernen',
      removeAllFilters: 'Alle Filter entfernen',
      removeByPrice: 'Preisrelevante Filter entfernen',
      removeMySelections: 'Filter „Meine Auswahlen“ entfernen',
      removeByGroup: 'Gruppenfilter für die Gruppe {{group}} entfernen',
      noFiltersAvailable: 'Keine Filter verfügbar',
    },
    group: {
      general: 'Allgemein',
      conflictHeader: 'Konflikte auflösen',
      conflictGroup: 'Konflikt für {{attribute}}',
    },
    conflict: {
      suggestionTitle: 'Vorschlag {{number}}',
      suggestionText: 'Wert für Attribut ändern',
      viewConflictDetails: 'Konflikt erkannt - Details anzeigen',
      conflictDetected: 'Konflikt erkannt',
      viewConfigurationDetails: 'Ansicht in Konfiguration',
    },
    restartDialog: {
      title: 'Unbeendete Konfiguration',
      description: 'Es gibt eine unvollendete Konfiguration aus einer früheren Sitzung. Möchten Sie fortfahren oder eine neue Konfiguration beginnen?',
      restartButton: 'Neu beginnen',
      resumeButton: 'Fortsetzen',
    },
    httpHandlers: {
      makeToStockBaseProductIssue: 'Dieses Produkt ist ein Basisprodukt und kann nicht in den Warenkorb gelegt werden; wählen Sie stattdessen eine Produktvariante',
    },
    a11y: {
      configureProduct: 'Produkt konfigurieren',
      showDetailsProduct: 'Details zum Produkt anzeigen',
      cartEntryBundleInfo: 'Es gibt einen Artikel ',
      cartEntryBundleInfo_other: 'Es gibt {{items}} Artikel ',
      cartEntryBundleName: 'Artikel {{ name }}',
      cartEntryBundleNameWithQuantity: 'Artikel {{ name }} Artikelmenge {{Menge}}',
      cartEntryBundleNameWithPrice: 'Artikel {{ name }} Artikelpreis {{price}}',
      cartEntryBundle: 'Artikel {{ name }} Artikelpreis {{price}} Artikelmenge {{Menge}}',
      cartEntryInfoIntro: 'Das Produkt hat die folgenden Attribute und ausgewählten Werte',
      cartEntryInfo: 'Attribut {{ attribute }} hat ausgewählten Wert {{value}}',
      nameOfAttribute: 'Name des Attributs',
      valueOfAttribute: 'Wert des Attributs {{ attribute }}',
      forAttribute: '{{ value }} für Attribut {{ attribute }}',
      valueOfAttributeFull: 'Wert {{ value }} von Attribut {{ attribute }}',
      valueOfAttributeFullWithPrice: 'Wert {{ value }} von Attribut {{ attribute }}, Aufpreis {{ price }}',
      selectedValueOfAttributeFull: 'Ausgewählter Wert {{ value }} von Attribut {{ attribute }}',
      selectedValueOfAttributeFullWithPrice: 'Ausgewählter Wert {{ value }} von Attribut {{ attribute }}, Aufpreis {{ price }},',
      readOnlyValueOfAttributeFullWithPrice: 'Nur-Lese-Wert {{ value }} von Attribut {{ attribute }}, Aufpreis {{ price }},',
      readOnlyValueOfAttributeFull: 'Nur-Lese-Wert {{ value }} von Attribut {{ attribute }}',
      valueOfAttributeBlank: 'Wert des Attributs {{ attribute }} ist leer',
      value: 'Wert {{ value }}',
      attribute: 'Attribut {{ attribute }}',
      requiredAttribute: 'Attribut {{param}} ist erforderlich',
      listOfAttributesAndValues: 'Liste der Attribute und ihrer Werte',
      editAttributesAndValues: 'Werte von Attributen bearbeiten',
      group: '{{ group }} Gruppe von Attributen',
      itemOfAttributeSelected: 'Element {{ item }} von Attribut {{ attribute }} ist ausgewählt. {{ itemIndex }} von {{ itemCount }}',
      itemOfAttributeSelectedWithPrice: 'Artikel {{ item }} von Attribut {{ attribute }} ist ausgewählt, Aufpreis {{ price }}. {{ itemIndex }} von {{ itemCount }}',
      itemOfAttributeSelectedPressToUnselect: 'Artikel {{ item }} des Attributs {{ attribute }} ist ausgewählt. Drücken Sie die Taste , um die Auswahl aufzuheben. {{ itemIndex }} von {{ itemCount }}',
      itemOfAttributeSelectedPressToUnselectWithPrice: 'Artikel {{ item }} des Attributs {{ attribute }} ist ausgewählt, Aufpreis {{ price }} Drücken Sie die Taste, um die Auswahl aufzuheben. {{ itemIndex }} von {{ itemCount }}',
      itemOfAttributeUnselected: 'Artikel {{ item }} des Attributs {{ attribute }} ist abgewählt. Drücken Sie die Taste, um auszuwählen. {{ itemIndex }} von {{ itemCount }}',
      itemOfAttributeUnselectedWithPrice: 'Item {{ item }} of attribute {{ attribute }} is unselected, Surcharge {{ price }}. Drücken Sie die Taste zur Auswahl. {{ itemIndex }} von {{ itemCount }}',
      selectNoItemOfAttribute: 'Um keinen Artikel für das Attribut {{ attribute }} auszuwählen, drücken Sie die Taste. {{ itemIndex }} von {{ itemCount }}',
      itemOfAttribute: 'Element des Attributs {{ attribute }}',
      itemOfAttributeFull: 'Element {{ item }} von Attribut {{ attribute }}',
      itemOfAttributeFullWithPrice: 'Artikel {{ item }} von Attribut {{ attribute }}, Aufpreis {{ price }}',
      itemOfAttributeFullWithQuantity: 'Artikel {{ item }} von Attribut {{ attribute }}, Menge {{ quantity }}',
      itemOfAttributeFullWithPriceAndQuantity: 'Artikel {{ item }} von Attribut {{ attribute }}, Menge {{ quantity }}, Aufpreis {{ price }}',
      itemDescription: 'Beschreibung für Artikel {{ item }}',
      listbox: 'Listbox mit {{ count }} Werten.',
      valueSurcharge: 'Wert-Zuschlag',
      conflictDetected: 'Konflikt erkannt! Prüfen Sie die Konflikte am Anfang der Gruppenliste.',
      conflictsInConfiguration: 'Es gibt Konflikte in Ihrer Konfiguration. Anzahl der Konflikte',
      listOfGroups: 'Liste der Gruppen',
      inListOfGroups: 'Sie befinden sich in der Gruppenliste',
      groupName: 'Gruppe {{ group }}',
      groupBack: 'Sie befinden sich in einer Untergruppe. Wählen Sie, um zurück zu gehen.',
      conflictBack: 'Sie befinden sich im Konfliktlöser. Wählen Sie, um zurückzugehen, oder wählen Sie die nächsten Registerkarten, um Konflikte zu lösen.',
      iconConflict: 'Gruppe hat Konflikte.',
      iconIncomplete: 'Die Gruppe hat erforderliche Attribute ohne ausgewählte Werte.',
      iconComplete: 'Die Gruppe ist vollständig.',
      iconSubGroup: 'Die Gruppe hat eine Untergruppe.',
      next: 'Navigieren Sie zur nächsten Gruppe.',
      previous: 'Navigieren Sie zur vorherigen Gruppe.',
      showMoreProductInfo: 'Mehr Informationen für das Produkt {{ product }} anzeigen oder weiter zur Konfiguration.',
      showLessProductInfo: 'Weniger Informationen für das Produkt {{ product }} anzeigen oder weiter zur Konfiguration.',
      productName: 'Produktname',
      productCode: 'Produkt-Code',
      productDescription: 'Beschreibung des Produkts',
      kbKeyName: 'Kb Name {{name}}',
      kbKeyLogsys: 'Kb logisches System {{logsys}}',
      kbKeyVersion: 'Kb Version {{version}}',
      kbKeyBuildNr: 'Kb-Build-Nummer {{number}}',
      configurationPage: 'Sie befinden sich auf der Konfigurationsseite.',
      configurationPageLink: 'Navigieren Sie zur Konfigurationsseite.',
      overviewPage: 'Sie befinden sich auf der Übersichtsseite. Überprüfen Sie die Attribute und ihre ausgewählten Werte mit dem Lesemodus Ihres Bildschirmlesegeräts.',
      overviewPageLink: 'Navigieren Sie zur Übersichtsseite der Konfiguration.',
      navigateToConflict: 'Folgen Sie dem Link, um weitere Details zum Konflikt für das Attribut {{ attribute }} zu erhalten.',
      numericIntervalStandard: 'Geben Sie einen Wert zwischen {{ minValue }} und {{ maxValue }} ein.',
      numericIntervalStandardOpen: '(Endpunkte sind nicht enthalten)',
      numericIntervalStandardLowerEndpointNotIncluded: '(Der untere Endpunkt ist nicht eingeschlossen)',
      numericIntervalStandardUpperEndpointNotIncluded: '(Der obere Endpunkt ist nicht eingeschlossen)',
      numericInfiniteIntervalMinValueIncluded: 'Geben Sie einen Wert ein, der größer oder gleich {{ value }} ist.',
      numericInfiniteIntervalMinValue: 'Geben Sie einen Wert ein, der größer ist als {{ value }}',
      numericInfiniteIntervalMaxValueIncluded: 'Geben Sie einen Wert ein, der kleiner oder gleich {{ value }} ist',
      numericInfiniteIntervalMaxValue: 'Geben Sie einen Wert ein, der kleiner ist als {{ value }}',
      numericIntervalSingleValue: 'Geben Sie {{ value }}',
      combinedIntervalsText: '{{ combinedInterval }} oder {{ newInterval }}',
      additionalValue: 'Sie können auch einen zusätzlichen Wert eingeben.',
      addToCartPrices: 'Grundpreis {{basePrice}}, Ausgewählte Optionen {{selectedOptions}}, Gesamtpreis {{totalPrice}}',
      filterOverview: 'Filtermenü der Konfigurationsübersicht öffnen',
      filterOverviewWithCount: 'Öffnen Sie das Konfigurationsübersichts-Filtermenü - {{numAppliedFilters}} aktive Filter',
      closeFilterMenu: 'Schließen Sie das Filtermenü der Konfigurationsübersicht und wenden Sie die ausgewählten Filter an',
      filterOverviewByPrice: 'Konfigurationsübersicht nach preisrelevanten Optionen filtern',
      filterOverviewByMySelections: 'Konfigurationsübersicht nach Ihren Auswahlen filtern',
      filterOverviewByGroup: 'Konfigurationsübersicht nach Gruppe filtern {{groupName}}',
      closeConflictSolverModal: 'Konfliktlöser-Modal schließen',
      closeRestartDialog: 'Schließen Sie den Dialog Unfertige Konfiguration und navigieren Sie zurück zur Produktdetailseite',
      description: 'Klicken Sie, um eine Beschreibung für diesen Wert anzuzeigen',
    },
    variantCarousel: {
      title: 'Vorkonfigurierte Geräte',
    },
  },
}
