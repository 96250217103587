export const checkoutAddress = {
  shippingAddress: '配送先住所',
  selectYourShippingAddress: '配送先住所を選択してください：',
  selectYourDeliveryAddress: '配送先住所を選択してください：',
  defaultShippingAddress: 'デフォルトの配送先住所',
  addNewAddress: '新しい住所を追加する',
  shipToThisAddress: 'この住所に発送する',
  addressSearch: '住所検索',
  addressSearchMinLength: '検索テキストは{{length}}文字以上で入力してください。',
  submitAddressSearch: '入力された住所',
  errorSearchingSalesforce: '現在検索をすることが出来ません。時間を置いて再度お試し下さい。',
  deliveryAddressSelected: '配送先住所が選択されました',
};

export const checkoutOrderConfirmation = {
  confirmationOfOrder: '注文の確認：',
  confirmationOfQuote: '見積内容の確認：',
  thankYou: 'ご注文ありがとうございました！',
  thankYouQuote: 'お見積のご依頼ありがとうございました！',
  invoiceHasBeenSentByEmailLine1: 'この注文がお客様の注文履歴に表示されるまで、最大1営業日かかります。注文履歴には、ラショナルが確認した発送日/集荷日、および税込販売金額の情報が記載されます。',
  invoiceHasBeenSentByEmailLine2: '税込の販売金額が表示されます。このご注文についてご質問がある場合は、カスタマーサービスまでお問い合わせください。',
  quoteHasBeenSentByEmailLine1: '御見積依頼はWebshopのご注文履歴には反映されませんのでご注意下さい。',
  quoteHasBeenSentByEmailLine2: 'このお見積依頼についてご質問がある場合は、カスタマーサービスまでお問い合わせください。その際、お客様ご注文番号の上に表示されるお見積番号をお伝え下さい。',
  orderItems: '注文商品',
  orderPlacedSuccessfully: '注文が完了しました。',
  createAccount: 'アカウントを作成しますか？',
  createAccountForNext: '<{{email}}のアカウントを作成すると、次回のチェックアウトがよりスムーズになります。',
};

export const checkoutReview = {
  review: '注文内容',
  orderItems: '注文商品',
  autoReplenishOrder: '自動補充注文',
  confirmThatRead: 'を読み、同意したことを確認します。',
  placeOrder: '注文',
  placeQuoteOrder: '見積依頼',
  scheduleReplenishmentOrder: '補充注文のスケジュール',
  orderType_PLACE_ORDER: 'なし（1回のみの注文）',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: '補充注文',
  termsAndConditions: '取引条件',
  editShippingAddress: '配送先住所の編集',
  poNumber: '注文番号',
  every: 'すべて',
  startOn: '開始日',
  dayOfMonth: '当日',
  repeatOnDays: '翌日も繰り返す',
  recurrencePeriodType_DAILY: '日',
  recurrencePeriodType_WEEKLY: '週',
  recurrencePeriodType_MONTHLY: '月',
};

export const checkoutShipping = {
  shippingMethod: '配送方法',
  standardDelivery: 'スタンダード配送',
  premiumDelivery: 'プレミアム配送',
};

export const checkoutPO = {
  noPoNumber: 'なし',
  poNumber: '貴社ご注文番号',
  costCenter: 'コストセンター',
  placeholder: '貴社ご注文番号を入力してください。',
  availableLabel: '利用可能な配送先は、コストセンターによって異なります。',
};

export const checkoutProgress = {
  methodOfPayment: 'お支払い方法',
  deliveryAddress: '配送先住所',
  deliveryMode: '配送方法',
  paymentDetails: 'お支払い内容',
  reviewOrder: 'ご注文内容',
};

export const checkoutB2B = {
  checkoutB2B: {
  poNumber: '貴社ご注文番号',
  placeholder: '貴社ご注文番号を入力してください。',
  methodOfPayment: {
    paymentType: 'お支払い方法',
  },
  progress: {
    methodOfPayment: 'お支払い方法',
  },
  review: {
    poNumber: '貴社ご注文番号',
  },
}
};

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: 'インコタームズ',
    paymentConditions: 'お支払い条件',
  }
};

export const checkoutMode = {
  deliveryMethod: '配送方法',
  deliveryEntries: '発送品目',
  specialShippingInstructions: '特別な発送方法',
  specialShippingInstructionsPlaceholder: '特別配送について',
};

  export const checkout = {
    checkoutAddress,
    checkoutOrderConfirmation,
    checkoutReview,
    checkoutShipping,
    checkoutPO,
    checkoutProgress,
    checkout: {
      backToCart: 'カートに戻る',
      invalid: {
        accountType: 'お客様のアカウントでは、ここでチェックアウトをすることが出来ません。'
      }
    },
    checkoutMode
  }