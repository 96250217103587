import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  Occ,
  OccEndpointsService,
  UserIdService,
} from '@spartacus/core';
import { OccOrderHistoryAdapter } from '@spartacus/order/occ';
import { OrderHistoryList } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { RationalOrderHistoryFilters } from './rational-order-history-filters/rational-order-history-filters.model';

@Injectable()
export class RationalOccOrderHistoryAdapter extends OccOrderHistoryAdapter {
  constructor(
    protected override http: HttpClient,
    protected override occEndpoints: OccEndpointsService,
    protected override converter: ConverterService,
    protected userIdService: UserIdService
  ) {
    super(http, occEndpoints, converter);
  }

  override loadHistory(
    userId: string,
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    filters?: RationalOrderHistoryFilters
  ): Observable<OrderHistoryList> {
    const params: { [key: string]: string } = {};
    if (pageSize) {
      params['pageSize'] = pageSize.toString();
    }
    if (currentPage) {
      params['currentPage'] = currentPage.toString();
    }
    if (sort) {
      params['sort'] = sort.toString();
    }

    const url = this.occEndpoints.buildUrl('orderHistory', {
      urlParams: { userId },
      queryParams: params,
    });

    return this.http.post<Occ.OrderHistoryList>(url, filters);
  }
}
