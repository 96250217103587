<ng-container *ngIf="data$ | async as data">
    <cx-generic-link *ngIf="data.link && data.link.url && (data.link.target && data.link.target === 'true')"
                     [target]="data.link.target" [url]="data.link.url">
      <div class="home-product relative box ratio_16_9 xs-ratio_1_1">
        <div class="absolute top-0 width-100">
          <div class="imageFrame box ratio_16_9 xs-ratio_1_1 of-hidden">

            <picture class="absolute top-0 width-100">
              <source *ngIf="data.media && data.media.url" srcset="{{ this.resolveAbsoluteUrl(data.media.url) }}" media="(min-width:769px)">
              <source *ngIf="data.responsiveMedia && data.responsiveMedia.url" srcset="{{ this.resolveAbsoluteUrl(data.responsiveMedia.url) }}" media="(max-width:768px)">
              <img *ngIf="data.media && data.media.url" src="{{ this.resolveAbsoluteUrl(data.media.url) }}" alt="{{ data.media.altText }}" class="img-responsive">
            </picture>
          </div>
        </div>
        <div class="home-product-text text-center fc-white absolute top-0 width-100">
          <div class="home-product-text-inner absolute width-100">
            <div class="fs-32 fs-xs-23 fw-bold">{{ data.title }}</div>
            <div class="fs-18 fs-xs-16 fw-normal">{{ data.subTitle }}</div>
          </div>
        </div>
      </div>
    </cx-generic-link>

    <cx-generic-link *ngIf="data.link && data.link.url && (!data.link.target || data.link.target === 'false')"
                     [url]="data.link.url">
      <div class="home-product relative box ratio_16_9 xs-ratio_1_1">
        <div class="absolute top-0 width-100">
          <div class="imageFrame box ratio_16_9 xs-ratio_1_1 of-hidden">
            <picture class="absolute top-0 width-100">
              <source *ngIf="data.media && data.media.url" srcset="{{ this.resolveAbsoluteUrl(data.media.url) }}" media="(min-width:769px)">
              <source *ngIf="data.responsiveMedia && data.responsiveMedia.url" srcset="{{ this.resolveAbsoluteUrl(data.responsiveMedia.url) }}" media="(max-width:768px)">
              <img *ngIf="data.media && data.media.url" src="{{ this.resolveAbsoluteUrl(data.media.url) }}" alt="{{ data.media.altText }}" class="img-responsive">
            </picture>
          </div>
        </div>
        <div class="home-product-text text-center fc-white absolute top-0 width-100">
          <div class="home-product-text-inner absolute width-100">
            <div class="fs-32 fs-xs-23 fw-bold">{{ data.title }}</div>
            <div class="fs-18 fs-xs-16 fw-normal">{{ data.subTitle }}</div>
          </div>
        </div>
      </div>
    </cx-generic-link>

</ng-container>


