import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalCartProceedToCheckoutComponent } from './rational-cart-proceed-to-checkout.component';
import { ProgressButtonModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';


@NgModule({
  declarations: [RationalCartProceedToCheckoutComponent],
  exports: [RationalCartProceedToCheckoutComponent],
  imports: [
    CommonModule,
    ProgressButtonModule,
    RouterModule,
    I18nModule,
    UrlModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartProceedToCheckoutComponent: {
          component: RationalCartProceedToCheckoutComponent,
        },
      },
    }),
  ],
})
export class RationalCartProceedToCheckoutModule { }
