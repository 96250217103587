export const savedCartDetails = {
    cartName: "Nome da empresa",
    cartDescription: "Descrição",
    cartId: "ID",
    dateSaved: "Data em que foi salvo",
    items: "Itens",
    quantity: "Quantidade",
    total: "Total",
    editSavedCart: "Editar carrinho salvo",
    deleteSavedCart: "Excluir carrinho salvo",
    addSelectedItems: "Adicionar itens selecionados",
    emptyCartItems: "Itens do carrinho vazio",
    addItemsToCart: "Pedir itens selecionados",
    selectAll: "Selecionar tudo",
    quickAddToCart: "Adicionar rapidamente ao carrinho salvo",
    productAddedToCart: "O {{product}} foi adicionado ao carrinho salvo.",
    selectedItemsAddedToCart: "Os itens selecionados foram adicionados com sucesso ao carrinho.",
    selectedItemsAreEmpty: "Você deve selecionar pelo menos um item para adicionar ao carrinho.",
};

export const savedCartList = {
    savedCarts: "Carrinhos salvos ({{count}})",
    cartName: "Nome do carrinho",
    cartId: "Id",
    dateSaved: "Data em que foi salvo",
    cartDescription: "Descrição do item",
    quantity: "Quantidade",
    total: "Total",
    actions: "Ações",
    makeCartActive: "Tornar o carrinho ativo",
    notFound: "Nenhum carrinho salvo encontrado",
    swapCartNoActiveCart: "O carrinho existente foi ativado com sucesso.",
    swapCartWithActiveCart: "O carrinho existente foi ativado por {{cartName}} com sucesso. Seus itens anteriores foram salvos em um carrinho {{previousCartName}}.",
    editCart: "Editar carrinho"
};

export const savedCartCartPage = {
    messages: {
        cartSaved: "Os itens do seu carrinho foram salvos com sucesso para mais tarde no carrinho \'{{cartName}}\'"
    },
    back: "Voltar à visão geral"
};

export const savedCartDialog = {
    saveForLater: "Salvar para mais tarde",
    itemsSavedForLater: "Todos os itens em seu carrinho serão salvos para mais tarde",
    savedCartName: "Nome do carrinho salvo",
    savedCartDescription: "Descrição do carrinho salvo",
    optional: "opcional",
    charactersLeft: "caracteres restantes: {{count}}",
    cancel: "Cancelar",
    save: "Salvar",
    restore: "Restaurar",
    followingCartDelete: "O carrinho salvo a seguir será excluído",
    followingCartRestore: "O carrinho salvo a seguir será restaurado como o carrinho ativo",
    delete: "Excluir",
    deleteCartSuccess: "Carrinho excluído com êxito",
    editCartSuccess: "Carrinho editado com sucesso",
    editSavedCart: "Editar carrinho salvo",
    deleteSavedCart: "Excluir carrinho salvo",
    restoreSavedCart: "Restaurar carrinho salvo",
    name: "Nome do carrinho",
    id: "ID",
    description: "Descrição do carrinho",
    quantity: "QTY",
    total: "Total",
    keepCopySavedCart: "Mantenha uma cópia desse carrinho na lista de carrinhos salvos",
    isSharedCart: "Compartilhar este carrinho com sua organização",
    defaultCloneCartName: "Cópia de {{name}}",
    nameOfCloneCart: "Nome do carrinho copiado"
};

export const addToSavedCart = {
    savedCarts: "Carrinhos salvos",
    saveCartForLater: "Salvar carrinho para mais tarde",
    sharedCartMessageStart: "Está disponível um carrinho compartilhado criado por {{userName}} ({{userMail}}). Clique ",
    sharedCartMessageMiddle: "aqui",
    sharedCartMessageEnd: " para ativar o carrinho compartilhado. A ativação do carrinho compartilhado o excluirá do nível da empresa até que você o compartilhe novamente e também substituirá o seu carrinho pessoal atual."
};

export const savedCart = {
    savedCartDetails,
    savedCartList,
    savedCartCartPage,
    savedCartDialog,
    addToSavedCart
};
