<ng-container *ngIf="product$ | async as product" class="product-variants-container">
    <div class="variant-section" *ngIf="product.baseOptions?.length">
      <cx-product-variant-style-selector
        *ngIf="variants[variantType.STYLE]"
        [variants]="variants[variantType.STYLE]"
      ></cx-product-variant-style-selector>
      <cx-product-variant-size-selector
        *ngIf="variants[variantType.SIZE]"
        [product]="product"
        [variants]="variants[variantType.SIZE]"
      ></cx-product-variant-size-selector>
      <cx-product-variant-color-selector
        *ngIf="variants[variantType.COLOR]"
        [product]="product"
        [variants]="variants[variantType.COLOR]"
      ></cx-product-variant-color-selector>
    </div>
  </ng-container>