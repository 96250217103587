export const orderApprovalDetails = {
    back: "Listeye Geri Dön",
    showForm_APPROVE: "Siparişi Onaylayın...",
    showForm_REJECT: "Siparişi Reddet...",
    form: {
        title_APPROVE: "Toplamı {{orderTotal}} olan {{orderCode}} siparişini onaylayın",
        title_REJECT: "Reject order {{orderCode}} totalling {{orderTotal}}",
        submit_APPROVE: "Onaylamak",
        submit_REJECT: "Reddet",
        cancel: "İptal",
        comment_APPROVE: {
            label: "Yorum (isteğe bağlı, maksimum 255 karakter)",
            placeholder: "Yorum (maksimum 255 karakter)"
        },
        comment_REJECT: {
            label: "Yorum (maksimum 255 karakter)",
            placeholder: "Yorum (maksimum 255 karakter)"
        }
    },
    permissionResults: {
        header: "Müşteri Onay Detayları",
        permission: "İzin",
        approver: "Onaylayan",
        status: "Durum",
        approverComments: "Onaylayan Yorumları",
        noApprovalComments: "Hiçbiri",
        permissionType_B2BBudgetExceededPermission: "Seçilen maliyet merkezi ile ilişkili bütçe aşılmıştır",
        permissionType_B2BOrderThresholdPermission: "Sipariş toplamı sipariş başına limiti aştı",
        permissionType_B2BOrderThresholdTimespanPermission: "Sipariş toplamı zaman aralığı başına sınırı aştı"
    }
};

export const orderApproval = {
    orderApprovalDetails,
};
