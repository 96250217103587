import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrderConfirmationThankYouMessageComponent, OrderDetailsService } from '@spartacus/order/components';
import { OrderFacade } from '@spartacus/order/root';
import { GlobalMessageService, TranslationService } from '@spartacus/core';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'cx-order-confirmation-thank-you-message',
    templateUrl: './order-confirmation-thank-you-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalOrderConfirmationThankYouMessageComponent extends OrderConfirmationThankYouMessageComponent {

    constructor(private orderDetailsService: OrderDetailsService, 
        protected override orderFacade: OrderFacade, 
        protected override globalMessageService: GlobalMessageService,
        protected override translationService: TranslationService) {
        super(orderFacade, globalMessageService, translationService)
    }

    override ngOnInit(): void {
        this.order$ = this.orderDetailsService.getOrderDetails().pipe(
          tap((order) => {
            this.isGuestCustomer =
              order && 'guestCustomer' in order
                ? order.guestCustomer ?? false
                : false;
            this.orderGuid = order?.guid;
          })
        );
      }
}
