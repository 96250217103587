import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Address, WindowRef } from '@spartacus/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CheckoutDeliveryAddressesService } from '../checkout-delivery-address-occ.service';

export interface CheckoutDeliveryAddressSuggestion {
  address: Address;
  addressText: string;
}

@Component({
  selector: 'rational-delivery-address-search-box',
  templateUrl: './checkout-delivery-address-search-box.component.html',
  styleUrls: ['./checkout-delivery-address-search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCheckoutDeliveryAddressSearchBoxComponent {

  protected results$!: Observable<CheckoutDeliveryAddressSuggestion[]> | null;

  protected salesforceSearchFailed: boolean = false;
  protected isLoadingResults: boolean = false;
  protected minCharactersBeforeRequest = 3;

  protected iconTypes = ICON_TYPE;
  protected resultsQuerySelector = '#delivery-address-search-results';

  @Output() selectAddress = new EventEmitter<any>();


  protected checkoutAddressSearchForm: UntypedFormGroup = this.fb.group({
    searchInput: ['', Validators.minLength(this.minCharactersBeforeRequest)]
  });

  constructor(
    private checkoutDeliveryAddressesService: CheckoutDeliveryAddressesService,
    protected fb: UntypedFormBuilder,
    protected winRef: WindowRef,
  ) { }

  search(query: string): void {
    if (query.length < this.minCharactersBeforeRequest) {
      return;
    }
    this.salesforceSearchFailed = false;
    this.isLoadingResults = true;
    this.results$ = this.searchAddresses(query);

  }

  searchAddresses(query: string): Observable<CheckoutDeliveryAddressSuggestion[]> {
    return this.checkoutDeliveryAddressesService.searchForDeliveryAddresses(query).pipe(
      map((results: Address[]) => {
        this.focusNextChild();
        this.isLoadingResults = false;
        return this.getAddressesSuggestions(results);
      }),
      catchError((error) => {
        this.isLoadingResults = false;
        this.salesforceSearchFailed = true;
        this.focusNextChild();
        throw error;
      })
    )
  }

  private getAddressesSuggestions(addresses: Address[]): CheckoutDeliveryAddressSuggestion[] {
    const addressesList: CheckoutDeliveryAddressSuggestion[] = addresses.map((addressItem: Address) => {
      return {
        address: addressItem,
        addressText: this.getAddressSuggestionText(addressItem)
      }
    });

    return addressesList;
  }

  private getAddressSuggestionText(address: Address): string {
    const addressString =
      [address.companyName,
      address.line1,
      address.postalCode,
      address.town,
      address.country?.name
      ].filter(Boolean).join(", ");
    return addressString;
  }

  changeSearchIcon() {
    this.salesforceSearchFailed = false;
  }

  dispatchSuggestionSelectedEvent(eventData: any): void {
    this.selectAddress.emit(eventData);
    this.close();
    this.focusSearchBox(false);
  }

  open() {
    setTimeout(() => {
      this.setResultsVisability(true);
    });
  }

  close() {
    // Use timeout to detect changes
    setTimeout(() => {
      this.setResultsVisability(false);
    });
  }

  toggle(){
    const element = this.winRef.document.querySelector(this.resultsQuerySelector) as HTMLElement;
    const display= element.style.display;
    if (element != null) {
      element.style.display = display ==  'block' ? 'none': 'block';
    }
  }

  private setResultsVisability(visible: boolean) {
    const element = this.winRef.document.querySelector(this.resultsQuerySelector) as HTMLElement;
    if (element != null) {
      element.style.display = visible ? 'block' : 'none';
    }

  }


  // Return result list as HTMLElement array
  private getResultElements(): HTMLElement[] {
    return Array.from(
      this.winRef.document.querySelectorAll(
        '.delivery-addresses-suggestions > li a, .suggestions > li a'
      )
    );
  }

  // Return focused element as HTMLElement
  private getFocusedElement(): HTMLElement {
    return <HTMLElement>this.winRef.document.activeElement;
  }


  private getFocusedIndex(): number {
    return this.getResultElements().indexOf(this.getFocusedElement());
  }

  // Focus on previous item in results list
  focusPreviousChild(event: UIEvent) {
    event.preventDefault(); // Negate normal keyscroll
    const [results, focusedIndex] = [
      this.getResultElements(),
      this.getFocusedIndex(),
    ];
    // Focus on last index moving to first
    if (results.length) {
      if (focusedIndex < 1) {
        results[results.length - 1].focus();
      } else {
        results[focusedIndex - 1].focus();
      }
    }
  }

  focusNextChildOnDownButtonClick(event: UIEvent) {
    event.preventDefault(); // Negate normal keyscroll
    this.focusNextChild();
  }

  // Focus on next item in results list
  focusNextChild() {
    const [results, focusedIndex] = [
      this.getResultElements(),
      this.getFocusedIndex(),
    ];
    // Focus on first index moving to last
    if (results.length) {
      if (focusedIndex >= results.length - 1) {
        results[0].focus();
      } else {
        results[focusedIndex + 1].focus();
      }
    }
  }


  preventDefault(ev: UIEvent): void {
    ev.preventDefault();
  }

  focusSearchBox(focus: boolean) {
    const searhcBox = this.winRef.document.querySelector('#deliveryAddressSearchInput') as HTMLElement;
    if (focus) {
      searhcBox.focus();
    } else {
      searhcBox.blur();
    }
  }
  /**
   * Clears the search box input field
   */
  clear(el: HTMLInputElement): void {
    el.value = '';
    this.clearResults();

    // Use Timeout to run after blur event to prevent the searchbox from closing on mobile
    setTimeout(() => {
      // Retain focus on input lost by clicking on icon
      el.focus();
    });
  }


  clearResults() {
    this.results$ = null;
  }
}
