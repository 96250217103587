export const organization = { 
    userRoles: {
      b2bcustomergroup: 'Müşteri',
      b2bapprovergroup: 'Onaylayan',
      b2bmanagergroup: 'Müdür',
      b2badmingroup: 'Yönetici'
    },
    breadcrumb: 'Organizasyon',
    notification: {
      noSufficientPermissions: 'Bu sayfaya erişmek için yeterli izin yok',
      notExist: 'Bu öğe mevcut değil',
      disabled: 'Devre dışı bırakılmış bir öğeyi düzenleyemezsiniz'
    },
    confirmation: {
      cancel: 'İPTAL',
      confirm: 'ONAYLA',
      disable: 'DEVRE DIŞI BIRAK',
      delete: 'SİL'
    },
    httpHandlers: {
      conflict: {
        budget: '{{ code }} kodlu bütçe zaten mevcut.',
        costCenter: '{{ code }} kodlu maliyet merkezi zaten mevcut.',
        unit: 'uid {{ code }} ile organizasyonel birim zaten mevcut.',
        user: '{{ code }} e-postalı kullanıcı zaten var',
        userGroup: '{{ code }} id’li Kullanıcı Grubu zaten mevcut',
        permission: '{{ code }} kodlu Onay İzni zaten mevcut.',
        unknown: 'Sunucu doğrulama hatası.'
      }
    }
};
