export const exportEntries = {
  exportEntries: {
    exportToCsv: "CSVにエクスポート",
    exportMessage: "CSVファイルはあなたのデバイスに自動的にダウンロードされます。もしダウンロードされない場合、ブラウザ設定をご確認下さい。",
    columnNames: {
      code: "コード",
      quantity: "数量",
      name: "商品名",
      price: "価格"
    },
    exportProductToCsv: "カートをCSVにエクスポート"
  }
}

export const importEntries = {
  importEntries: {
    importProducts: "カートをインポート"
  }
}

export const importEntriesDialog = {
  importEntriesDialog: {
    importProducts: ".CSVまたは.XLSXファイルをインポートして商品を読み込む",
    importProductsSubtitle: ".CSVまたは.XLSXファイルをインポートして商品を読み込む",
    importProductsNewSavedCartSubtitle: "インポートするファイルには、数量と商品番号の列がセミコロンで区切られた商品リストが含まれていなければなりません。",
    importProductFileDetails: "ファイルを選択",
    selectFile: "保存されたカート名",
    savedCartName: "保存されたカートの説明",
    savedCartDescription: "任意",
    charactersLeft: "残り文字数 {{count}}",
    cancel: "キャンセル",
    upload: "アップロード",
    close: "閉じる",
    summary: {
      info: "商品のインポート中は、このウィンドウを閉じたり更新したりしないでください。",
      loadedToCart: "商品がカート {{ cartName }} に読み込まれました",
      loaded: "商品が読み込まれました",
      loading: "商品は処理中です... ({{ count }}/{{ total }})",
      successes: "'{{ successesCount }}商品のうち{{ total }}が正常にインポートされました。",
      warning: "{{ count }}商品は完全にインポートされませんでした。",
      warning_other: "{{ count }}商品は完全にインポートされませんでした。",
      error: "{{ count }}商品はインポートされませんでした。",
      error_other: "{{ count }}商品はインポートされませんでした。",
      messages: {
        unknownIdentifier: "商品SKU '{{ productCode}}' が存在しません。",
        lowStock: "{{ productName }}の数量： {{ quantity }}は {{ quantityAdded }}に減りました。",
        noStock: "{{ productName }} は現在在庫切れです。",
        unknownError: "'{{ productCode }}' に認識できない問題があります。",
        limitExceeded: "'{{ productCode }}' を追加できません。制限を超えました。"
      },
      show: "表示する",
      hide: "隠す"
    }
  }
}
