export const configurator = {
  configurator: {
    header: {
      consistent: "一致",
      complete: "完全",
      configId: "コンフィギュレーションID",
      toconfig: "設定",
      toConfigReadOnly: "詳細表示",
      editConfiguration: "構成の編集",
      displayConfiguration: "構成の表示",
      resolveIssues: "問題の解決",
      resolveIssue: "問題の解決",
      resolveConflicts: "不一致の解決",
      conflictWarning: "不一致を解決しないと続行出来ません。",
      updateMessage: "コンフィグレーションはバックグラウンドで更新されています。",
      conflictsResolved: "不一致は解消されました。",
      showMore: "もっと見る",
      showLess: "少なく表示する",
      items: "{{count}}件",
      items_other: "{{count}}件",
      show: "表示",
      hide: "非表示",
      multipleWarnings: "複数の警告があります。",
      reviewWarnings: "これらの警告を確認してください",
      multipleErrors: "複数のエラーがあります。",
      reviewErrors: "これらのエラーを確認してください",
      kbKeyName: "Kb名",
      kbKeyLogsys: "Kbロジカルシステム",
      kbKeyVersion: "Kbバージョン",
      kbKeyBuildNr: "Kbバージョン",
    },
    tabBar: {
      configuration: "コンフィギュレーション",
      overview: "概要",
    },
    notificationBanner: {
      numberOfIssues: "チェックアウト前に{{count}}個の問題を解決する必要があります。",
      numberOfIssues_other: "チェックアウト前に{{count}}個の問題を解決する必要があります。",
      numberOfConflicts: "チェックアウト前に {{count}} 個の不一致を解決する必要があります。",
      numberOfConflicts_other: "チェックアウト前に {{count}} 個の不一致を解決する必要があります。",
    },
    attribute: {
      id: "ID",
      quantity: "数量",
      caption: "属性タイプはサポートされていません。",
      notSupported: "属性タイプはサポートされていません。",
      requiredAttribute: "{{param}} は必須です",
      defaultRequiredMessage: "必須項目に値を入力",
      singleSelectRequiredMessage: "値を選択",
      singleSelectAdditionalRequiredMessage: "値を選択または入力",
      multiSelectRequiredMessage: "1つ以上の値を選択してください",
      wrongNumericFormat: "入力形式が間違っています。この数値属性は {{pattern}}に従って入力する必要があります。",
      wrongNumericFormatMessage: "次の形式で数値を入力してください",
      wrongIntervalFormat: "指定範囲内で値を入力して下さい",
      deselectionNotPossible: "この製品を削除する前に、別の製品を追加してください",
      dropDownSelectMsg: "選択してください",
      noOptionSelectedMsg: "オプションが選択されていません",
      notVisibleAttributeMsg: "この属性はユーザーには表示されません。",
    },
    button: {
      previous: "前へ",
      next: "次へ",
      back: "戻る",
      more: "もっと見る",
      less: "表示を減らす",
      deselect: "選択解除",
      select: "選択する",
      add: "追加する",
      remove: "削除する",
      exit: "設定を終了する",
      exitMobile: "終了",
      cancelConfiguration: "設定のキャンセル",
      cancelConfigurationMobile: "キャンセル",
      filterOverview: "フィルター",
      filterOverviewWithCount: "フィルター ({{numAppliedFilters}})",
    },
    icon: {
      groupComplete: "完了",
      groupIncomplete: "未完了",
      groupConflict: "競合",
      subgroup: "にはサブグループがあります。",
    },
    priceSummary: {
      basePrice: "基本価格",
      selectedOptions: "選択されたオプション",
      totalPrice: "合計",
      totalPricePerItem: "項目ごとの合計",
    },
    addToCart: {
      button: "カートに入れる",
      buttonAfterAddToCart: "カートに進む",
      buttonForQuote: "見積もりに進む",
      buttonUpdateCart: "完了",
      buttonDisplayOnly: "完了",
      buttonClose: "閉じる",
      confirmation: "コンフィギュレーションがカートに追加されました",
      confirmationUpdate: "コンフィギュレーションがカートに追加されました",
      confirmationQuoteUpdate: "見積りが更新されました",
      selectVariant: "あなたの設定に複数の既存のバリアントが見つかりました。この場合、カスタマーサービスに連絡して正しいバリアントを選択してください。",
      addMatchingVariant: "既存のバリアントがショッピングカートに追加されました。",
      quantity: "数量",
    },
    overviewForm: {
      noAttributeHeader: "結果がありません、",
      noAttributeText: "フィルターを外すと概要が表示されます、",
      itemPrice: "商品価格",
    },
    overviewSidebar: {
      menu: "メニュー",
      filter: "フィルター",
    },
    overviewFilter: {
      title: "フィルターを選択",
      byOption: "オプションで絞り込む",
      byGroup: "グループで絞り込む",
      byPrice: "価格関連オプション",
      mySelections: "マイセレクション",
      removeAll: "すべて削除",
      removeAllFilters: "すべてのフィルターを削除",
      removeByPrice: "価格関連フィルターを削除",
      removeMySelections: "マイ・セレクション・フィルターを削除",
      removeByGroup: "グループ{{group}}のグループフィルターを削除",
      noFiltersAvailable: "フィルターはありません",
    },
    group: {
      general: "一般",
      conflictHeader: "不一致の解決",
      conflictGroup: "{{attribute}}の不一致",
    },
    conflict: {
      suggestionTitle: "提案 {{number}}",
      suggestionText: "\\{{ attribute }}\\の値を変更して下さい。",
      viewConflictDetails: "不一致が検出されました - 詳細を見る",
      conflictDetected: "不一致が検出されました",
      viewConfigurationDetails: "コンフィギュレーションを見る",
    },
    restartDialog: {
      title: "フィルターを選択",
      description: "以前のセッションで未完了のコンフィグレーションがあります。コンフィギュレーションを再開しますか？",
      restartButton: "新しく開始",
      resumeButton: "再開",
    },
    httpHandlers: {
      makeToStockBaseProductIssue: "この書品はベース商品のためカートに追加できません。製品バリエーションを選択して下さい。",
    },
    a11y: {
      configureProduct: "製品を構成する",
      showDetailsProduct: "商品の詳細を表示する",
      cartEntryBundleInfo: "商品があります",
      cartEntryBundleInfo_other: "{{items}} 商品があります",
      cartEntryBundleName: "商品 {{name}}",
      cartEntryBundleNameWithQuantity: "商品 {{name}} 商品数量 {{quantity}}",
      cartEntryBundleNameWithPrice: "商品 {{name}} 商品価格 {{price}}",
      cartEntryBundleNameWithPriceAndQuantity: "商品 {{name}} 商品価格 {{price}} 商品数量 {{quantity}}",
      cartEntryBundle: "商品には以下の属性と選択値があります。",
      cartEntryInfoIntro: "属性 {{attribute}} は選択値 {{value}} を持ちます。",
      cartEntryInfo: "属性の名前",
      nameOfAttribute: "属性 {{attribute}} の値",
      valueOfAttribute: "属性 {{attribute}} の値 {{value}}",
      forAttribute: "属性 {{attribute}} の値 {{value}}",
      valueOfAttributeFull: "属性 {{attribute}} の値 {{value}}",
      valueOfAttributeFullWithPrice: "属性 {{attribute}} の値 {{value}}、追加料金 {{price}}",
      selectedValueOfAttributeFull: "属性 {{attribute}} の選択値 {{value}}",
      selectedValueOfAttributeFullWithPrice: "属性 {{attribute}} の選択値 {{value}}、追加料金 {{price}}",
      readOnlyValueOfAttributeFullWithPrice: "属性 {{attribute}} の読み取り専用値 {{value}}、追加料金 {{price}}、",
      readOnlyValueOfAttributeFull: "属性 {{attribute}} の読み取り専用値 {{value}}",
      valueOfAttributeBlank: "属性 {{attribute}} の値は空欄です。",
      value: "値 {{value}}",
      attribute: "属性 {{attribute}}",
      requiredAttribute: "{{param}} は必須です",
      listOfAttributesAndValues: "属性とその値のリスト",
      editAttributesAndValues: "属性の値の編集",
      group: "属性の {{group}}グループ",
      itemOfAttributeSelected: "属性 {{attribute}} のアイテム {{item}} が選択されています。{{itemCount}} の {{itemIndex}}",
      itemOfAttributeSelectedWithPrice: "属性 {{attribute}} のアイテム {{item}} が選択され、追加料金は {{price}}です。{itemCount }} の {{itemIndex}}",
      itemOfAttributeSelectedPressToUnselect: "属性 {{attribute}} のアイテム {{item}} が選択されました。ボタンを押すと選択解除されます。{{itemCount }} の {{itemIndex}}",
      itemOfAttributeSelectedPressToUnselectWithPrice: "属性 {{attribute}} のアイテム {{item}} が選択され、追加料金は {{price}}です。ボタンを押すと選択解除されます。{{itemCount }} の {{itemIndex}}",
      itemOfAttributeUnselected: "属性 {{attribute}} のアイテム {{item}} が未選択です。ボタンを押して選択して下さい。{{itemCount }} の {{itemIndex}}",
      itemOfAttributeUnselectedWithPrice: "属性 {{attribute}} のアイテム {{item}} が未選択で、追加料金は {{price}}です。ボタンを押して選択して下さい。{{itemCount }} の {{itemIndex}}",
      selectNoItemOfAttribute: "属性 {{attribute}} のアイテムを選択しない場合はボタンを押して選択して下さい。{itemCount }} の {{itemIndex}}",
      itemOfAttribute: "属性 {{attribute}} のアイテム",
      itemOfAttributeFull: "属性 {{attribute}} のアイテム {{item}}",
      itemOfAttributeFullWithPrice: "属性 {{attribute}} のアイテム {{item}} 、追加料金 {{price}}",
      itemOfAttributeFullWithQuantity: "属性 {{attribute}} のアイテム {{item}} 、数量 {{quantity}}",
      itemOfAttributeFullWithPriceAndQuantity: "属性 {{attribute}} のアイテム {{item}} 、数量 {{quantity}}、追加料金 {{price}}",
      itemDescription: "アイテム {{item}} の説明",
      listbox: "{{count}}個のリストボックス",
      valueSurcharge: "追加料金",
      conflictDetected: "不一致が検出されました",
      conflictsInConfiguration: "コンフィギュレーションに不一致があります。不一致の数",
      listOfGroups: "グループ一覧",
      inListOfGroups: "グループリストにいます",
      groupName: "グループ {{group}}",
      groupBack: "サブグループにいます。戻る場合は選択してください。",
      conflictBack: "コンフリクトソルバーにいます。戻るか、次のタブを選択して不一致を解決してください。",
      iconConflict: "グループに不一致があります。",
      iconIncomplete: "グループには選択されていない必須属性があります。",
      iconComplete: "グループが完成しました。",
      iconSubGroup: "グループにサブグループがあります。",
      next: "次へ",
      previous: "前へ",
      showMoreProductInfo: "製品 {{product}} の詳細情報を表示するか、設定に進みます。",
      showLessProductInfo: "製品 {{product}} の情報減らすか、設定に進みます。",
      productName: "製品名",
      productCode: "製品コード",
      productDescription: "製品説明",
      kbKeyName: "Kb名",
      kbKeyLogsys: "Kbロジカルシステム",
      kbKeyVersion: "Kbバージョン",
      kbKeyBuildNr: "Kbバージョン",
      configurationPage: "コンフィギュレーションページにいます。",
      configurationPageLink: "コンフィギュレーションページに移動します。",
      overviewPage: "概要ページにいます。スクリーン・リーダーのリーディング・モードで属性と選択された値を確認してください。",
      overviewPageLink: "設定概要ページに移動します。",
      navigateToConflict: "属性{{attribute}}の不一致については、リンクを参照して下さい。",
      numericIntervalStandard: "{{minValue}} から {{maxValue}} の値を入力して下さい。",
      numericIntervalStandardOpen: "(エンドポイントは含まない）",
      numericIntervalStandardLowerEndpointNotIncluded: "(下限は含まない）",
      numericIntervalStandardUpperEndpointNotIncluded: "(上限は含まない）",
      numericInfiniteIntervalMinValueIncluded: "{{value}} 以上の値を入力して下さい。",
      numericInfiniteIntervalMinValue: "{{value}} より大きい値を入力して下さい。",
      numericInfiniteIntervalMaxValueIncluded: "{{value}} 以下の値を入力して下さい。",
      numericInfiniteIntervalMaxValue: "{{value}} より小さい値を入力して下さい。",
      numericIntervalSingleValue: "{{value}} を入力",
      combinedIntervalsText: "{{combinedInterval}} または {{newInterval}}",
      additionalValue: "追加の値を入力することもできます。",
      addToCartPrices: "基本価格 {{basePrice}}、選択オプション {{selectedOptions}}、合計価格 {{totalPrice}}",
      filterOverview: "フィルター",
      filterOverviewWithCount: "フィルター ({{numAppliedFilters}})",
      closeFilterMenu: "設定概要フィルターメニューを閉じ、選択したフィルターを適用する",
      filterOverviewByPrice: "価格による構成概要の絞り込み - 関連オプション",
      filterOverviewByMySelections: "選択したオプションで構成概要をフィルター",
      filterOverviewByGroup: "グループ{{groupName}}で設定概要をフィルター",
      closeConflictSolverModal: "コンフリクトソルバーのモードを閉じる",
      closeRestartDialog: "\\Unfinished Configuration\\ のダイアログを閉じて、商品詳細ページに戻る。",
      description: "以前のセッションで未完了のコンフィグレーションがあります。コンフィギュレーションを再開しますか？",
    },
      variantCarousel: {
        title: "設定済みバージョン",
      },
}
}