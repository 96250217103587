import { OrderEntry } from '@spartacus/cart/base/root';
declare module '@spartacus/cart/base/root' {
    interface OrderEntry {
      reference?: string;
      scheduleLines?: ScheduleLine[];
    }
  }

declare module '@spartacus/cart/base/root' {
  interface Cart {
    comment?: string;
    additionalContactEmail?: string;
    specialShippingInstructions?: string;
    isSharedWithB2BUnit?: boolean;
    isShared?: boolean;
  }
}

declare module '@spartacus/order/root' {
  interface Order {
    comment?: string;
    additionalContactEmail?: string;
    quote?: boolean;
    paymentConditions?: String[];
    specialShippingInstructions?: string;
  }

  interface OrderHistory {
    specialShippingInstructions?: string;
  }
}



export interface ScheduleLine {
  confirmedAt?: Date;
  confirmedQuantity?: number;
}
