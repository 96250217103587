export const quickOrderCartForm = {
  entriesWasAdded: '({{ quantity }}) {{ product }} a été ajouté au panier',
  entryWasAdded: '{{ product }} a été ajouté au panier',
  noResults: 'Nous n\'avons trouvé aucun produit',
  stockLevelReached: 'Le niveau de stock maximum a été atteint',
  title: 'Ajouter rapidement au panier',
  productCodePlaceholder: 'Saisir le numéro d\'article',
  entryProductCode: 'Entrer le numéro d\'article pour une commande rapide',
  addToCart: 'Ajouter rapidement au panier',
  product: 'Produits',
  products: 'Produit',
  productCodeLabel: 'ID du produit',
  quantityLabel: 'Qté',
}

export const quickOrderForm = {
  placeholder: 'Saisir l\'UGS du produit',
  listLimitReached: 'La limite de produit a été atteinte',
  id: 'Numéro d\'article {{ id }}',
}

export const quickOrderList = {
  addToCart: 'Ajouter au panier',
  emptyList: 'Liste vide',
  header: 'Ajouter des produits/Skus',
  subHeader: 'Vous pouvez ajouter jusqu\'à {{ limit }} SKU valides à la fois.',
  errorProceedingToCart: 'Erreur lors de l\'ajout au panier.',
  warningProceedingToCart: 'Avertissement de procéder au panier.',
  successfullyAddedToCart: 'Ajouté avec succès au panier.',
  errors: {
    productIsOutOfStock: '{{ name }} (#{{code}}) est en rupture de stock.',
  },
  warnings: {
    productWasReduced: 'La quantité pour {{ name }} (#{{code}}) a été réduite à {{ quantityAdded }}.',
  },
  successes: {
    productAddedToCart: 'La quantité de {{ name }} (#{{code}}) a été réduite à {{ quantityAdded}}. (#{{code}}) a été ajouté au panier.',
  }
}

export const quickOrderTable = {
  product: 'Produit',
 actions: 'Promotions',
  id: 'Numéro d\'article',
  price: 'Prix',
  quantity: 'QTÉ',
  itemPrice: 'Prix de l\'article',
  qty: 'Qté',
  inStock: 'En stock',
  lowStock: 'Stock réduit',
  outOfStock: 'En rupture de stock',
  listCleared: 'La liste de commande rapide a été effacée',
  addedtoCart: 'La liste de commande rapide a été ajoutée au panier',
}

export const quickOrder = {
  quickOrderCartForm,
  quickOrderForm,
  quickOrderList,
  quickOrderTable
}
