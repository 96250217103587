import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { RationalHomepagetileComponent } from "./rationalhomepagetile/rational-homepagetile.component";
import { RationalHomepagetileContainerComponent } from "./rationalhomepagetilecontainer/rational-homepagetile-container.component";
import { CarouselModule, GenericLinkModule, IconModule, PageComponentModule, SearchBoxModule } from "@spartacus/storefront";
import { CommonModule } from "@angular/common";
import { RationalBannerContainerComponent } from './rationalbannercomponent/rationalbannercomponentcontainer/rational-banner-container.component';
import { RationalSearchBoxComponent } from './navigation/rational-search-box/rational-search-box.component';
import { RouterModule } from '@angular/router';
import { RationalMediaModule } from './media/rational-media.module';

@NgModule({
  declarations: [
    RationalHomepagetileContainerComponent,
    RationalHomepagetileComponent,
    RationalBannerContainerComponent,
    RationalSearchBoxComponent
  ],
  imports: [
    CarouselModule,
    GenericLinkModule,
    PageComponentModule,
    CommonModule,
    RouterModule,
    RationalMediaModule,
    IconModule,
    UrlModule,
    I18nModule,
    SearchBoxModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        RationalHomepageTileComponent: {
          component: RationalHomepagetileComponent,
        },
        RationalHomepageTileContainerComponent: {
          component: RationalHomepagetileContainerComponent,
        },
        RationalBannerComponentContainer: {
          component: RationalBannerContainerComponent,
        },
        SearchBoxComponent: {
          component: RationalSearchBoxComponent
        }
      },
    }),
  ],
  exports: [RationalSearchBoxComponent],
})
export class CmsComponentsModule { }
