<div class="searchbox-container" [attr.aria-label]="'searchBox.productSearch' | cxTranslate" role="search">
  <label class="searchbox" [class.dirty]="!!searchInput.value" #searchBoxLabel>
    <button
      [attr.aria-label]="'common.reset' | cxTranslate"
      (mousedown)="clear(searchInput)"
      (keydown.enter)="clear(searchInput)"
      class="reset"
    >
      <cx-icon [type]="iconTypes.RESET"></cx-icon>
    </button>

    <div role="presentation" class="search-icon" (click)="showAllSuggestedWords()" style="cursor:pointer">
      <cx-icon [type]="iconTypes.SEARCH"></cx-icon>
    </div>

    <button
      [attr.aria-label]="'common.search' | cxTranslate"
      class="search"
      (click)="open()"
    >
      <cx-icon [type]="iconTypes.SEARCH" (click)="showAllSuggestedWords()" style="cursor:pointer"></cx-icon>
    </button>
    <input
      #searchInput
      [placeholder]="'searchBox.placeholder' | cxTranslate"
      autocomplete="off"
      aria-describedby="initialDescription"
      aria-controls="results"
      [attr.aria-label]="'searchBox.placeholder' | cxTranslate"
      (focus)="open()"
      (click)="open()"
      (input)="search(searchInput.value) ; onSearchInput($event)"
      (blur)="close($any($event)); hideSuggestions()"
      (keydown.escape)="close($any($event))"
      (keydown.enter)="
        close($any($event), true);
        launchSearchResult($any($event), searchInput.value);
        updateChosenWord(searchInput.value)
      "
      (keydown.arrowup)="focusPreviousChild($any($event))"
      (keydown.arrowdown)="focusNextChild($any($event))"
      value="{{ chosenWord }}"
      type="text"

    />
<!--    [name]="isMobileView ? 'searchBoxMob' : 'searchBoxDesk'"-->
    <div *ngIf="filteredSuggestions.length && (showSuggestions | async)" class="autocomplete-items" >
      <div
        *ngFor="let suggestion of filteredSuggestions"
        (click)="selectSuggestion(suggestion)"
        (pointerdown)="selectSuggestion(suggestion)"
        class="autocomplete-item"
      >
        {{ suggestion }}
      </div>
    </div>

  </label>
</div>

<div
  *ngIf="results$ | async as result"
  class="results"
  id="results"
  (click)="close($any($event), true)"
  role="dialog"
>
  <div
    *ngIf="result.message"
    class="message"
    [innerHTML]="result.message"
  ></div>

  <div
    class="suggestions">
  <ul

    attr.aria-label="{{ 'searchBox.ariaLabelSuggestions' | cxTranslate }}"
    role="listbox"
  >
    <li *ngFor="let suggestion of result.suggestions">
      <a
        role="option"
        [innerHTML]="suggestion | cxHighlight: searchInput.value"
        [routerLink]="
          {
            cxRoute: 'search',
            params: { query: suggestion }
          } | cxUrl
        "
        (keydown.arrowup)="focusPreviousChild($any($event))"
        (keydown.arrowdown)="focusNextChild($any($event))"
        (keydown.enter)="close($any($event), true)"
        (keydown.escape)="close($any($event), true)"
        (blur)="close($event)"
        (mousedown)="preventDefault($event)"
        (click)="
          dispatchSuggestionEvent({
            freeText: searchInput.value,
            selectedSuggestion: suggestion,
            searchSuggestions: result.suggestions ?? []
          });
          updateChosenWord(suggestion)
        "
      >
      </a>
    </li>
  </ul>
</div>
  <div class="products">
  <ul

    *ngIf="result.products"
    attr.aria-label="{{ 'searchBox.ariaLabelProducts' | cxTranslate }}"
    role="listbox"

  >
    <li *ngFor="let product of result.products">
      <a
        role="option"
        [routerLink]="
          {
            cxRoute: 'product',
            params: product
          } | cxUrl
        "
        [class.has-media]="config.displayProductImages"
        (keydown.arrowup)="focusPreviousChild($any($event))"
        (keydown.arrowdown)="focusNextChild($any($event))"
        (keydown.enter)="close($any($event), true)"
        (keydown.escape)="close($any($event), true)"
        (blur)="close($any($event))"
        (mousedown)="preventDefault($event)"
        (click)="
          dispatchProductEvent({
            freeText: searchInput.value,
            productCode: product.code
          })
        "
      >
        <cx-media
          *ngIf="config.displayProductImages"
          [container]="product.images?.PRIMARY"
          format="thumbnail"
          class="max-height"
          role="presentation"
        ></cx-media>
        <div class="code" [innerHTML]="product.code"></div>
        <div class="name" [innerHTML]="product.nameHtml"></div>
      </a>
    </li>
  </ul>
  <span id="initialDescription" class="cx-visually-hidden">
    {{ 'searchBox.initialDescription' | cxTranslate }}
  </span>
  <div
    *ngIf="result.suggestions?.length || result.products?.length"
    aria-live="assertive"
    class="cx-visually-hidden"
  >
    {{
      'searchBox.suggestionsResult'
        | cxTranslate: { count: result.suggestions?.length }
    }}
    {{
      'searchBox.productsResult'
        | cxTranslate: { count: result.products?.length }
    }}
    {{ 'searchBox.initialDescription' | cxTranslate }}
  </div>
</div>
</div>
