export const orderApprovalDetails = {
    back: "Tillbaka till listan",
    showForm_APPROVE: "Godkänna order...",
    showForm_REJECT: "Avvisa order...",
    form: {
        title_APPROVE: "Godkänna order {{orderCode}} totalt {{orderTotal}}",
        title_REJECT: "Avvisa order {{orderCode}} totalt {{orderTotal}}",
        submit_APPROVE: "Godkänna",
        submit_REJECT: "Avvisa",
        cancel: "Avbryt",
        comment_APPROVE: {
            label: "Kommentar (valfritt, max 255 tecken)",
            placeholder: "Kommentar (max 255 tecken)"
        },
        comment_REJECT: {
            label: "Kommentar (max 255 tecken)",
            placeholder: "Kommentar (max 255 tecken)"
        }
    },
    permissionResults: {
        header: "Detaljer om godkännande av kund",
        permission: "Tillstånd",
        approver: "Godkännare",
        status: "Status för godkännande",
        approverComments: "Kommentarer från godkännaren",
        noApprovalComments: "Ingen",
        permissionType_B2BBudgetExceededPermission: "Budgeten som är kopplad till det valda kostnadsstället har överskridits",
        permissionType_B2BOrderThresholdPermission: "Orderns totalbelopp överskred gränsen per order",
        permissionType_B2BOrderThresholdTimespanPermission: "Ordersumman överskred gränsen per tidsintervall"
    }
};

export const orderApproval = {
    orderApprovalDetails,
};
