export const productDetails = {
  id: 'Artikelnummer',
  quantity: 'Menge',
  productDetails: 'Informationen zum Artikel',
  specification: 'Spezifikationen',
  reviews: 'Bewertungen',
  shipping: 'Versand',
  share: 'Teilen',
  showReviews: 'Bewertungen anzeigen',
  noReviews: '',
  productPrice: 'Artikel Preis',
  priceLoading:'Der Produktpreis wird ermittelt',
  productRelations: {
    productRelationsHeader: 'Kompatibles Zubehör',
  }
}

export const productList = {
  filterBy: {
    label: 'Filtern nach',
    action: 'Filtern nach'
  },
  appliedFilter: 'Angewendete Filter:',
  showLess: 'Weniger anzeigen...',
  showMore: 'Mehr anzeigen...',
  sortBy: 'Sortieren nach',
  backToTopBtn: 'ZURÜCK AN DEN ANFANG',
  showMoreBtn: 'ZEIG MEHR',
  productSearchPagination: 'Artikel Suche'
}

export const productFacetNavigation = {
  filterBy: {
    label: 'Filtern nach',
    action: 'Filtern nach'
  },
  appliedFilter: 'Angewendete Filter:',
  showLess: 'Weniger anzeigen...',
  showMore: 'Mehr anzeigen...',
  sortBy: 'Sortieren nach'
}

export const productSummary = {
  id: 'Artikelnummer',
  showReviews: 'Bewertungen anzeigen',
  share: 'Teilen',
  newItemPrice: 'Neuer Artikelpreis',
  discount: 'In den angezeigten Preisen ist Ihr kundenindividueller Nachlass in Höhe von {{discount}} verrechnet.',
  productSubstitutionAndProductExists: 'Produkt {{product1}} wird durch Produkt {{product2}} ersetzt, daher werden Sie zu Produkt {{product2}} weitergeleitet',
  productSubstitutionAndProductDoesntExist: 'Produkt {{product1}} wird durch Produkt {{product2}} ersetzt, aber {{product2}} ist derzeit nicht verfügbar'
}

export const productReview = {
  overallRating: 'Gesamtbewertung',
  reviewTitle: 'Titel der Bewertung',
  writeYourComments: 'Schreibe einen Kommentar',
  rating: 'Bewertung',
  reviewerName: 'Name Ersteller (optional)',
  writeReview: 'Schreibe eine Bewertung',
  more: 'Mehr Bewertungen anzeigen',
  less: 'Weniger Bewertungen anzeigen',
  thankYouForReview: 'Vielen Dank für Ihre Bewertung! Beachten Sie, dass Bewertungen möglicherweise überprüft werden müssen, bevor sie veröffentlicht werden.'
}

export const addToCart = {
  itemsAddedToYourCart: 'Artikel wurde zu Ihrem Warenkorb hinzugefügt',
  itemsIncrementedInYourCart: 'Dieser Artikel war bereits in Ihrem Warenkorb. Die Menge wurde aktualisiert.',
  items: 'Artikel',
  updatingCart: 'Warenkorb wird aktualisert...',
  addToCart: 'In den Warenkorb',
  viewCart: 'Warenkorb anzeigen',
  proceedToCheckout: 'Zur Kasse gehen',
  quantity: 'Menge',
  outOfStock: 'Nicht lagerhaltig',
  inStock: 'Lagerhaltig',
  selectStyleAndSize: 'Wählen Sie Typ und Größe aus, um den Lagerbestand zu überprüfen',
  removeFromCart: 'Artikel aus Warenkorb entfernen',
  closeModal: 'Warenkorb schließen'
}

export const TabPanelContainer = {
  tabs: {
    ProductDetailsTabComponent: 'Informationen zum Artikel',
    ProductSpecsTabComponent: 'Technische Merkmale',
    ProductReviewsTabComponent: 'Bewertungen',
    deliveryTab: 'Versand',
    ProductDownloadsTabComponent: 'Downloads'
  }
}

export const addToWishList = {
  add: 'Zur Wunschliste hinzufügen',
  remove: 'Aus Wunschliste entfernen',
  anonymous: 'Melden Sie sich an, um den Artikel in die Wunschliste hinzuzufügen.'
}

export const itemCounter = {
  removeOne: 'Entfernen',
  addOneMore: 'Hinzufügen',
  quantity: 'Menge'
}

export const productView = {
  gridView: 'Rasteransicht aktivieren',
  listView: 'Listenansicht aktivieren'
}

export const standardDelivery = {
  standardDelivery: {
      standardDeliveryRange: 'Lieferbereit innerhalb von {{from}} bis {{to}} Tagen'
  }
}

export const downloads = {
  downloads: {
    noDownloads: 'Keine Downloads für dieses Produkt',
    documentTitle: 'Dokumenttitel',
    originalFileName: 'Dateiname',
    languages: 'Sprachen',
    readableTypeMime: 'Mimetyp',
    fileSize: 'Dateigröße',
    download: 'Herunterladen'
  }
}

export const productReferences = {
  productReferences: {
    installationvariants: 'Aufstellvarianten',
    feedingsystems: 'Beschickungssysteme',
    groomingaccessoires: 'Pflegeprodukte',
    cookaccessoire: 'Garzubehör',
    cookaccessoirespecial: 'Spezialzubehör',
    extractorhood: 'Abzugshauben',
    deviceinstallation: 'Geräteinstallation'
  }
}

export const product = {
  productDetails,
  productList,
  productFacetNavigation,
  productSummary,
  productReview,
  addToCart,
  TabPanelContainer,
  addToWishList,
  itemCounter,
  productView,
}
