export const organization = { 
    userRoles: {
      b2bcustomergroup: 'Zákazník',
      b2bapprovergroup: 'Schvalovatel',
      b2bmanagergroup: 'Manažer',
      b2badmingroup: 'Administrátor'
    },
    breadcrumb: 'Organizace',
    notification: {
      noSufficientPermissions: 'Pro přístup na tuto stránku nemáte dostatečná oprávnění',
      notExist: 'Tato položka neexistuje',
      disabled: 'Nelze upravovat zakázanou položku'
    },
    confirmation: {
      cancel: 'ZRUŠIT',
      confirm: 'POTVRDIT',
      disable: 'ZAKÁZAT',
      delete: 'DELETE'
    },
    httpHandlers: {
      conflict: {
        budget: 'Rozpočet s kódem {{code }} již existuje.',
        costCenter: 'Nákladové středisko s kódem {{code }} již existuje.',
        unit: 'Organizační jednotka s uid {{code }} již existuje.',
        user: 'Uživatel s e-mailem {{code }} již existuje.',
        userGroup: 'Skupina uživatelů s id {{code }} již existuje.',
        permission: 'Oprávnění ke schvalování s kódem {{code }} již existuje.',
        unknown: 'Chyba ověření serveru.'
      }
    }
};
