export const paymentForm = {
  payment: 'Płatność',
  choosePaymentMethod: 'Wybierz metodę płatności',
  paymentType: 'Typ płatności',
  accountHolderName: {
    label: 'Nazwa właściciela konta',
    placeholder: 'Imię i nazwisko posiadacza konta',
  },
  cardNumber: 'Numer karty',
  expirationDate: 'Data ważności',
  securityCode: 'Kod bezpieczeństwa (CVV)',
  securityCodeTitle: 'Wartość weryfikacji karty',
  saveAsDefault: 'Zapisz jako domyślne',
  setAsDefault: 'Ustaw jako domyślne',
  billingAddress: 'Adres rozliczeniowy',
  sameAsShippingAddress: 'Taki sam jak adres wysyłki',
  selectOne: 'Wybierz jeden...',
  monthMask: 'MM',
  yearMask: 'RRRR',
  expirationYear: 'Rok wygaśnięcia {{ selected }}',
  expirationMonth: 'Miesiąc wygaśnięcia {{ selected }}',
  useThisPayment: 'Użyj tej płatności',
  addNewPayment: 'Dodaj nową płatność',
  changePayment: 'Zmień płatność',
}

export const paymentMethods = {
  paymentMethods: 'Metody płatności',
  newPaymentMethodsAreAddedDuringCheckout: 'Nowe metody płatności są dodawane podczas realizacji zakupu.',
  invalidField: 'InvalidField: {{ field }}',
}

export const paymentCard = {
  deleteConfirmation: 'Czy na pewno chcesz usunąć tę metodę płatności?',
  setAsDefault: 'Ustaw jako domyślną',
  expires: 'Expires: {{ month }}/{{ year }}',
  defaultPaymentMethod: '✓ DOMYŚLNE',
  selected: 'Wybrany',
}

export const paymentTypes = {
  title: 'Metoda płatności',
  paymentType_CARD: 'Karta kredytowa',
  paymentType_ACCOUNT: 'Konto',
  paymentType_INVOICE: 'Faktura',
}

export const payment = {
  paymentForm,
  paymentMethods,
  paymentCard,
  paymentTypes
}
