<div class="cx-order-items container" *ngIf="order$ | async as order">
  <h4 class="cx-order-items-header">
    {{ 'checkoutOrderConfirmation.orderItems' | cxTranslate }}
  </h4>

  <cx-promotions
    [promotions]="order.appliedOrderPromotions || []"
  ></cx-promotions>

  <rational-cart-item-list [cxAbstractOrderContext]="{id: order.code,type: abstractOrderType.ORDER}"
          [items]="order.entries"
          [readonly]="true"
          [promotionLocation]="promotionLocation"
  ></rational-cart-item-list>
</div>
