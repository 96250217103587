import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TabParagraphContainerComponent } from '@spartacus/storefront';

@Component({
  selector: 'rational-tab-paragraph-container',
  templateUrl: './rational-tab-paragraph-container.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalTabParagraphContainerComponent extends TabParagraphContainerComponent {

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.winRef.nativeWindow?.location.href.includes('/product/')) {
      this.activeTabNum = -1;
    }
  }
}
