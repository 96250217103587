import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StateWithMultiCart } from '@spartacus/cart/base/core';
import { ActiveCartFacade, Cart, MultiCartFacade, OrderEntry } from '@spartacus/cart/base/root';
import { SavedCartService } from '@spartacus/cart/saved-cart/core';
import { ConverterService, EventService, OccEndpointsService, StateWithProcess, UserIdService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { combineLatest, Observable, Subject, switchMap, finalize} from 'rxjs';
import { LoadingOverlayService } from '../layout/loading-overlay/loading-ovarlay.service';
import { rationaOCCEndpoints } from '../rational-backend-occ-endpoint/rational-backend-occ-endpoint.module';
import { HttpClient } from '@angular/common/http';
import { RationalSavedCartActions } from './action/index';
import {
  SavedCartActions
} from '@spartacus/cart/saved-cart/core';

@Injectable({
  providedIn: 'root'
})
export class RationalSavedCartService extends SavedCartService {


  private cartRestored = new Subject<boolean>();
  selectedEntries = new Subject<OrderEntry[]>();
  cartRestoredevent$ = this.cartRestored.asObservable();

  constructor(
    override store: Store<StateWithMultiCart | StateWithProcess<void>>,
    override userIdService: UserIdService,
    override userAccountFacade: UserAccountFacade,
    override multiCartService: MultiCartFacade,
    override eventService: EventService,
    private launchDialogService: LaunchDialogService,
    private loadingOverlayService: LoadingOverlayService,
    protected occEndpoints: OccEndpointsService,
    protected http: HttpClient,
    protected converterService: ConverterService,
    private activeCartFacade: ActiveCartFacade
  ) {
    super(store, userIdService, userAccountFacade, multiCartService, eventService);

    combineLatest(this.getSaveCartProcessLoading(), this.getRestoreSavedCartProcessLoading())
      .subscribe(([cartIsBeingSaved, cartIsBeingRestored]) => {
        if (cartIsBeingSaved || cartIsBeingRestored) {
          this.loadingOverlayService.setIsLoading(true);
        } else {
          this.loadingOverlayService.setIsLoading(false);
        }
      });

    combineLatest(this.getCloneSavedCartProcessLoading(), this.getRestoreSavedCartProcessLoading())
      .subscribe(([cartIsBeingCloned, cartIsBeingRestored]) => {
        if (cartIsBeingCloned || cartIsBeingRestored) {
          this.loadingOverlayService.setIsLoading(true);
        } else {
          this.loadingOverlayService.setIsLoading(false);
        }
      });
  }


  override restoreSavedCart(cartId: string): void {
    this.launchDialogService.closeDialog("Saved cart is being restored");
    this.userIdService.takeUserId(true).pipe(
      finalize(() => {
        this.getSharedCart();
        this.cartRestored.next(false);
      })
    ).subscribe({
      next: (userId) => {
        this.store.dispatch(
          new SavedCartActions.RestoreSavedCart({
            userId,
            cartId,
          })
        );

      },
      error: (err) => {

      }
    });
  }

   restoreSavedSharedCart(cartId: string): void {
    this.launchDialogService.closeDialog("Saved cart is being restores");

    this.userIdService.takeUserId(true).subscribe({
      next: (userId) => {
        return this.store.dispatch(
          new RationalSavedCartActions.RestoreSavedSharedCart({
            userId,
            cartId,
          })
        );
      },
      error: () => {
        // TODO: for future releases, refactor this part to thrown errors
      },
    });
    this.cartRestored.next(false);
  }



  override cloneSavedCart(cartId: string, saveCartName?: string) {
    this.launchDialogService.closeDialog("Active cart is being saved and saved cart is being restores");

    super.cloneSavedCart(cartId, saveCartName);
  }

  cloneSavedSharedCart(cartId: string, saveCartName?: string) {
    this.launchDialogService.closeDialog("Active cart is being saved and saved cart is being restores");

    this.userIdService.takeUserId(true).subscribe({
      next: (userId) => {
        return this.store.dispatch(
          new RationalSavedCartActions.CloneSavedSharedCart({ userId, cartId, saveCartName })
        );
      },
      error: () => {
        // TODO: for future releases, refactor this part to thrown errors
      },
    });
  }


  getSharedCart(): Observable<Cart> {
    return this.http.get<Cart>(this.occEndpoints.buildUrl(rationaOCCEndpoints.sharedCart.getSharedCart));
  }

  updateReferenceFromSavedCart(entryProductCodes: string[], cartId: string): Observable<Cart> {
    const userId$ = this.userIdService.takeUserId();
    const activeCart$ = this.activeCartFacade.getActiveCartId();
    return combineLatest([userId$, activeCart$])
    .pipe(switchMap(([userId, activeCartId]) => {
      const formData = new FormData();
      formData.append('entryProductCodes', entryProductCodes.toString());
      formData.append('savedCartId', cartId)
      formData.append('activeCartId', activeCartId!)
      return this.http.post<Cart>(this.occEndpoints.buildUrl(
       rationaOCCEndpoints.sharedCart.updateReferenceFromSavedCart, {urlParams: {userId: userId, cartId: activeCartId}}), formData);
      }))
    }

  override saveCart({
    cartId,
    saveCartName,
    saveCartDescription,
    isSharedWithB2BUnit
  }: {
    cartId: string;
    saveCartName?: string;
    saveCartDescription?: string;
    isSharedWithB2BUnit?: boolean
  }): void {
    this.userIdService.takeUserId(true).subscribe({
      next: (userId) => {
        return this.store.dispatch(
          new RationalSavedCartActions.SaveSharedCart({
            userId,
            cartId,
            saveCartName,
            saveCartDescription,
            isSharedWithB2BUnit
          })
        );
      },
      error: () => {
        // TODO: for future releases, refactor this part to thrown errors
      },
    });
  }

}
