<div class="product-downloads" *ngIf="productDownloadsResponse$ | async as downloads">

    <div *ngIf="
        downloads.productDownloads == null || downloads.productDownloads?.length == 0
        else downloadsTable">
        {{ 'downloads.noDownloads' | cxTranslate }}
    </div>




    <ng-template #downloadsTable>

        <div class="container">

            <div class="row languages-dropdown-row">
                <div class="col-12 col-lg-6 mr-auto product-downloads-dropdown">
                    <label class="languages-dropdown-label">{{ 'downloads.languagesDropdown' | cxTranslate }}</label>
                    <div class="languages-dropdown">
                        <ng-select [searchable]="false" [clearable]="false" placeholder="{{selectedLanguage}}"
                            (change)="getProductDownloads($event)" [(ngModel)]="selectedLanguage">
                            <ng-option *ngFor="let lang of downloads.languages" [value]="lang.isocode">{{
                                lang.name
                                }}</ng-option>
                            <ng-option value="mul"> {{ 'downloads.allDocuments' | cxTranslate }}</ng-option>
                        </ng-select>
                    </div>

                </div>
                <div
                    class="col-auto ml-auto ml-lg-0 d-flex justify-content-center align-items-center toolbox-link-container">
                    <a href="{{downloads.toolboxLink}}" target="_blank" class="toolbox-link">{{ 'downloads.toolbox' |
                        cxTranslate }}</a><br>
                </div>
            </div>
        </div>



        <table role="table" id="product-downloads-table" class="table cx-product-downloads-table">
            <thead class="cx-product-downloads-thead-mobile">
                <tr role="row">
                    <th role="columnheader">
                        {{ 'downloads.documentTitle' | cxTranslate }}
                    </th>
                    <th role="columnheader">
                        {{ 'downloads.languages' | cxTranslate }}
                    </th>
                    <th role="columnheader">
                        {{ 'downloads.readableTypeMime' | cxTranslate }}
                    </th>
                    <th role="columnheader">
                        {{ 'downloads.fileSize' | cxTranslate }}
                    </th>
                    <th role="columnheader">
                        {{ 'downloads.download' | cxTranslate }}
                    </th>

                </tr>
            </thead>

            <tbody>
                <tr role="row" *ngFor="let download of downloads.productDownloads">

                    <td role="cell" class="cx-order-history">
                        <div class="cx-downloads-item-label">{{ 'downloads.documentTitle' | cxTranslate }}</div>
                        <div class="cx-downloads-item-value download-name">{{ download.localizedName }}</div>
                    </td>

                    <td role="cell" class="cx-order-history">
                        <ng-container *ngIf="download.languages">
                            <div class="cx-downloads-item-label"> {{ 'downloads.languages' | cxTranslate }} </div>
                            <div class="cx-downloads-item-value"> {{ download.languages.join(', ') }}</div>
                        </ng-container>
                    </td>

                    <td role="cell" class="cx-order-history">
                        <ng-container *ngIf="download.mimeType">
                            <div class="cx-downloads-item-label"> {{ 'downloads.readableTypeMime' | cxTranslate }}
                            </div>
                            <div class="cx-downloads-item-value"> {{ download.mimeType }}</div>
                        </ng-container>
                    </td>

                    <td role="cell" class="cx-order-history">
                        <ng-container *ngIf="download.fileSize">
                            <div class="cx-downloads-item-label"> {{ 'downloads.fileSize' | cxTranslate }} </div>
                            <div class="cx-downloads-item-value"> {{ (download.fileSize / 1024)| number : '1.0-0' }}KB
                            </div>
                        </ng-container>
                    </td>

                    <td role="cell" class="cx-order-history">

                        <div class="cx-downloads-item-label"> {{ 'downloads.download' | cxTranslate }} </div>
                        <div class="cx-downloads-item-value">
                            <a class="download-link" role="button" (click)="openDocument(download)">{{
                                'downloads.download' | cxTranslate }}</a>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
    </ng-template>

</div>