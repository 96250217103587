export const configurator = {
  configurator: {
    header: {
      consistent: "Konsekvent",
      complete: "Komplett",
      configId: "Konfigurations-ID",
      toconfig: "Konfigurera",
      toConfigReadOnly: "Visa detaljer",
      editConfiguration: "Redigera konfiguration,",
      displayConfiguration: "Visa konfiguration",
      resolveIssues: "Lösa problem",
      resolveIssue: "Lösa problem",
      resolveConflicts: "Lösa konflikter",
      conflictWarning: "Konflikten måste lösas för att fortsätta",
      updateMessage: "Konfigurationen uppdateras i bakgrunden",
      conflictsResolved: "Konflikterna har lösts",
      showMore: "Visa mer",
      showLess: "Visa mindre",
      items: "{{count}} objekt",
      items_other: "{{count}} objekt",
      show: "visa",
      hide: "dölja",
      multipleWarnings: "Det finns flera varningar.",
      reviewWarnings: "Granska dessa varningar",
      multipleErrors: "Det finns flera fel.",
      reviewErrors: "Granska dessa fel",
      kbKeyName: "Kb namn",
      kbKeyLogsys: "Kb logiskt system",
      kbKeyVersion: "Kb-version",
      kbKeyBuildNr: "Kb byggnummer",
    },
    tabBar: {
      configuration: "Konfiguration",
      overview: "Översikt",
    },
    notificationBanner: {
      numberOfIssues: "{{count}} problem måste lösas före utcheckning.",
      numberOfIssues_other: "{{count}} problem måste lösas före utcheckning.",
      numberOfConflicts: "{{count}} konflikter måste lösas före utcheckning.",
      numberOfConflicts_other: "{{count}} konflikter måste lösas före utcheckning.",
    },
    attribute: {
      id: "ID",
      quantity: "Antal",
      caption: "Attribut",
      notSupported: "Attributtyp stöds inte.",
      requiredAttribute: "{{param}} krävs",
      defaultRequiredMessage: "Ange ett värde för det obligatoriska fältet",
      singleSelectRequiredMessage: "Välj ett värde",
      singleSelectAdditionalRequiredMessage: "Välj eller ange ett värde",
      multiSelectRequiredMessage: "Välj ett eller flera värden",
      wrongNumericFormat: "Fel format, detta numeriska attribut ska anges enligt mönstret {{Munster}}",
      wrongNumericFormatMessage: "Ange siffran i följande format",
      wrongIntervalFormat: "Ange ett värde inom de angivna gränserna",
      deselectionNotPossible: "Lägg till en annan produkt innan du tar bort den här",
      dropDownSelectMsg: "Gör ett val",
      noOptionSelectedMsg: "Inget alternativ valt",
      notVisibleAttributeMsg: "Detta attribut är inte synligt för användaren",
    },
    button: {
      previous: "Föregående",
      next: "Nästa",
      back: "Tillbaka",
      more: "mer",
      less: "mindre",
      deselect: "Välj bort",
      select: "Välj",
      add: "Lägg till",
      remove: "Ta bort",
      exit: "Avsluta konfiguration",
      exitMobile: "Avsluta",
      cancelConfiguration: "Avbryt konfiguration",
      cancelConfigurationMobile: "Avbryt",
      filterOverview: "Filtrera",
      filterOverviewWithCount: "Filtrera ({{numAppliedFilters}})",
    },
    icon: {
      groupComplete: "Komplett",
      groupIncomplete: "Ofullständig",
      groupConflict: "Konflikt",
      subgroup: "har undergrupper",
    },
    priceSummary: {
      basePrice: "Grundpris",
      selectedOptions: "Valda alternativ",
      totalPrice: "Totalbelopp",
      totalPricePerItem: "Totalt per artikel",
    },
    addToCart: {
      button: "Lägg till i kundvagn",
      buttonAfterAddToCart: "Fortsätt till varukorg",
      buttonForQuote: "Fortsätt till offert",
      buttonUpdateCart: "Klar",
      buttonDisplayOnly: "Klar",
      buttonClose: "Stäng",
      confirmation: "Konfigurationen har lagts till i varukorgen",
      confirmationUpdate: "Kundvagnen har uppdaterats med konfigurationen",
      confirmationQuoteUpdate: "Offerten har uppdaterats med konfigurationen",
      selectVariant: "Mer än en befintlig variant hittades för din konfiguration. Kontakta i så fall kundtjänst för att välja rätt variant.",
      addMatchingVariant: "Den befintliga varianten för din konfiguration har lagts till i kundvagnen.",
      quantity: "Antal",
    },
    overviewForm: {
      noAttributeHeader: "Inga resultat,",
      noAttributeText: "Ta bort filter för att se översiktsinnehåll,",
      itemPrice: "Artikelpris",
    },
    overviewSidebar: {
      menu: "meny",
      filter: "Filtrera",
    },
    overviewFilter: {
      title: "Välj filter",
      byOption: "Filtrera efter alternativ",
      byGroup: "Filtrera efter grupp",
      byPrice: "Prisrelevanta alternativ",
      mySelections: "Mina val",
      removeAll: "Ta bort alla",
      removeAllFilters: "Ta bort alla filter",
      removeByPrice: "Ta bort prisrelevant filter",
      removeMySelections: "Ta bort filter för mina val",
      removeByGroup: "Ta bort gruppfilter för grupp {{group}}",
      noFiltersAvailable: "Inga filter tillgängliga",
    },
    group: {
      general: "Allmänt",
      conflictHeader: "Lösa konflikter",
      conflictGroup: "Konflikt för {{attribute}}",
    },
    conflict: {
      suggestionTitle: "Förslag {{count}}",
      suggestionText: "Ändra värdet för \\{{attribute}}\\",
      viewConflictDetails: "Konflikt upptäckt - Visa detaljer",
      conflictDetected: "Konflikt upptäckt",
      viewConfigurationDetails: "Visa i konfiguration",
    },
    restartDialog: {
      title: "Oavslutad konfiguration",
      description: "Det finns en oavslutad konfiguration från en tidigare session. Vill du återuppta eller starta en ny konfiguration?",
      restartButton: "Starta ny",
      resumeButton: "Återuppta",
    },
    httpHandlers: {
      makeToStockBaseProductIssue: "Den här produkten är en basprodukt och kan inte läggas i varukorgen; välj en produktvariant istället",
    },
    a11y: {
      configureProduct: "Konfigurera produkt",
      showDetailsProduct: "Visa detaljer om produkten",
      cartEntryBundleInfo: "Det finns en artikel",
      cartEntryBundleInfo_other: "Det finns {{items}} artiklar",
      cartEntryBundleName: "Artikel {{name}}",
      cartEntryBundleNameWithQuantity: "Artikel {{name }} artikelkvantitet {{quantity}}",
      cartEntryBundleNameWithPrice: "Artikel {{name }} artikelpris {{price}}",
      cartEntryBundleNameWithPriceAndQuantity: "Artikel {{name }} artikelpris {{price}} artikelkvantitet {{quantity}}",
      cartEntryBundle: "Produkten har följande attribut och valda värden",
      cartEntryInfoIntro: "Attribut {{attribute}} har valt värde {{value}}",
      cartEntryInfo: "Attributets namn",
      nameOfAttribute: "Värde för attribut {{attribute}}",
      valueOfAttribute: "{{value}} för attribut {{attribute}}",
      forAttribute: "Värde {{value}} för attribut {{attribute}}",
      valueOfAttributeFull: "Värde {{value}} för attribut {{attribute}}",
      valueOfAttributeFullWithPrice: "Värde {{value}} för attribut {{attribute}}, Tilläggsavgift {{price}}",
      selectedValueOfAttributeFull: "Valt värde {{value}} för attribut {{attribute}}",
      selectedValueOfAttributeFullWithPrice: "Valt värde {{value}} för attribut {{attribute}}, Tilläggsavgift {{price}},",
      readOnlyValueOfAttributeFullWithPrice: "Skrivskyddat värde {{value}} för attribut {{attribute}}, Tilläggsavgift {{price}},",
      readOnlyValueOfAttributeFull: "Skrivskyddat värde {{value}} för attributet {{attribute}}",
      valueOfAttributeBlank: "Värdet för attributet {{attribute}} är tomt",
      value: "Värde {{value}}",
      attribute: "Attribut {{attribute}}",
      requiredAttribute: "Attributet {{param}} är obligatoriskt",
      listOfAttributesAndValues: "Lista över attribut och deras värden",
      editAttributesAndValues: "Redigera värden för attribut",
      group: "{{group }} grupp av attribut",
      itemOfAttributeSelected: "Objektet {{item}} i attributet {{attribute}} är valt. {{itemIndex}} av {{itemCount}}",
      itemOfAttributeSelectedWithPrice: "Artikel {{article}} i attributet {{attribute}} är vald, Tilläggsavgift {{price}}. {{itemIndex}} av {{itemCount}}",
      itemOfAttributeSelectedPressToUnselect: "Artikel {{item}} i attributet {{attribute}} är vald. Tryck på knappen för att avmarkera. {{itemIndex}} av {{itemCount}}",
      itemOfAttributeSelectedPressToUnselectWithPrice: "Artikel {{article}} med attribut {{attribute}} är vald, Tilläggsavgift {{price}} Tryck på knappen för att avmarkera. {{itemIndex}} av {{itemCount}}",
      itemOfAttributeUnselected: "Artikel {{item}} i attributet {{attribute}} är avmarkerad. Tryck på knappen för att välja. {{itemIndex}} av {{itemCount}}",
      itemOfAttributeUnselectedWithPrice: "Artikel {{article}} i attribut {{attribute}} är omarkerad, Tilläggsavgift {{price}}. Tryck på knappen för att välja. {{itemIndex}} av {{itemCount}}",
      selectNoItemOfAttribute: "Om du inte vill välja något objekt för attributet {{attribute}} trycker du på knappen. {{itemIndex}} av {{itemCount}}",
      itemOfAttribute: "Objekt för attribut {{attribute}}",
      itemOfAttributeFull: "Objekt {{objekt}} för attribut {{attribute}}",
      itemOfAttributeFullWithPrice: "Artikel {{article}} i attributet {{attribute}}, Tilläggsavgift {{price}}",
      itemOfAttributeFullWithQuantity: "Artikel {{article}} i attributet {{attribute}}, Kvantitet {{quantity}}",
      itemOfAttributeFullWithPriceAndQuantity: "Artikel {{article}} av attribut {{attribute}}, Kvantitet {{quantity}}, Tilläggsavgift {{price}}",
      itemDescription: "Beskrivning för artikel {{article}}",
      listbox: "Listbox med {{count}} värden.",
      valueSurcharge: "Värde tilläggsavgift",
      conflictDetected: "Konflikt upptäckt! Kontrollera konflikterna högst upp i grupplistan.",
      conflictsInConfiguration: "Det finns konflikter i din konfiguration. Antal konflikter",
      listOfGroups: "Lista över grupper",
      inListOfGroups: "Du befinner dig i grupplistan",
      groupName: "Grupp {{group}}",
      groupBack: "Du befinner dig i en undergrupp. Välj för att gå tillbaka.",
      conflictBack: "Du befinner dig i konfliktlösaren. Välj för att gå tillbaka eller välj nästa flik för att lösa konflikter.",
      iconConflict: "Gruppen har konflikter.",
      iconIncomplete: "Gruppen har obligatoriska attribut utan valda värden.",
      iconComplete: "Gruppen är komplett.",
      iconSubGroup: "Gruppen har en undergrupp.",
      next: "Navigera till nästa grupp.",
      previous: "Navigera till föregående grupp.",
      showMoreProductInfo: "Visa mer information för produkten {{product}} eller fortsätt till konfigurationen.",
      showLessProductInfo: "Visa mindre information för produkten {{product}} eller fortsätt till konfigurationen.",
      productName: "Produktens namn",
      productCode: "Produktens kod",
      productDescription: "Beskrivning av produkten",
      kbKeyName: "Kb-namn {{name}}",
      kbKeyLogsys: "Kb logiskt system {{logsys}}",
      kbKeyVersion: "Kb-version {{version}}",
      kbKeyBuildNr: "Kb byggnummer {{number}}",
      configurationPage: "Du befinner dig på konfigurationssidan.",
      configurationPageLink: "Navigera till konfigurationssidan.",
      overviewPage: "Du befinner dig på översiktssidan. Kontrollera attributen och deras valda värden med läsläget i din skärmläsare.",
      overviewPageLink: "Navigera till sidan för konfigurationsöversikt.",
      navigateToConflict: "Följ länken för att få mer information om konflikt för attributet {{attribute}}.",
      numericIntervalStandard: "Ange ett värde mellan {{minValue}} och {{maxValue}}",
      numericIntervalStandardOpen: "(Slutpunkter inkluderas inte)",
      numericIntervalStandardLowerEndpointNotIncluded: "(Den nedre ändpunkten ingår inte)",
      numericIntervalStandardUpperEndpointNotIncluded: "(Den övre ändpunkten är inte inkluderad)",
      numericInfiniteIntervalMinValueIncluded: "Ange ett värde som är större än eller lika med {{value}}",
      numericInfiniteIntervalMinValue: "Ange ett värde som är större än {{value}}",
      numericInfiniteIntervalMaxValueIncluded: "Ange ett värde som är mindre än eller lika med {{value}}",
      numericInfiniteIntervalMaxValue: "Ange ett värde som är mindre än {{value}}",
      numericIntervalSingleValue: "Ange {{value}}",
      combinedIntervalsText: "{{kombineratIntervall}} eller {{nyttIntervall}}",
      additionalValue: "Du kan också ange ett ytterligare värde.",
      addToCartPrices: "Baspris {{basePrice}}, Valda alternativ {{selectedOptions}}, Totalpris {{totalPrice}}",
      filterOverview: "Öppna filtermenyn för konfigurationsöversikt",
      filterOverviewWithCount: "Öppna filtermenyn i konfigurationsöversikten - {{numAppliedFilters}} aktiva filter",
      closeFilterMenu: "Stäng filtermenyn för konfigurationsöversikt och tillämpa de valda filtren",
      filterOverviewByPrice: "Filtrera konfigurationsöversikten efter prisrelevanta alternativ",
      filterOverviewByMySelections: "Filtrera konfigurationsöversikten efter dina val",
      filterOverviewByGroup: "Filtrera konfigurationsöversikten efter grupp {{groupName}}",
      closeConflictSolverModal: "Stäng konfliktlösarens dialogruta",
      closeRestartDialog: "Stäng dialogrutan ”Oavslutad konfiguration” och navigera tillbaka till sidan med produktdetaljer",
      description: "Klicka för att se en beskrivning av detta värde",
    },
      variantCarousel: {
        title: "Förkonfigurerade versioner",
      },
}
}