import { ChangeDetectionStrategy, Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { CartItemContextSource, CartItemListRowComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade, CartItemContext } from '@spartacus/cart/base/root';
import { WindowRef } from '@spartacus/core';
import { InvoiceQueryParams, InvoicesFields, PDFInvoicesFacade } from '@spartacus/pdf-invoices/root';
import { ICON_TYPE, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, take } from 'rxjs';
import { RationalOrderInvoice } from 'src/app/custom/order/order-details/order-information.model';
import { RationalOrderSummaryService } from '../../order-summary/rational-order-summary.service';
import { RationalOrderDetailsService } from '../../../../order/order-details/order-information.service';
import { RationalCartItemUpdateService } from './rational-cart-item-update.service';
import { RationalCartOutlets } from 'src/app/custom/model/rational-cart-outlets.model';
import { RationalAutoCompleteService } from '../../../../services/rational-auto-complete-service';

@Component({
  selector: '[rational-cart-item-list-row], rational-cart-item-list-row',
  templateUrl: './rational-cart-item-list-row.component.html',
  styleUrls: ['./rational-cart-item-list-row.component.scss'],
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCartItemListRowComponent extends CartItemListRowComponent implements OnInit {
  @Input() showOrderInformationLinks: Boolean = false;
  readonly RationalCartOutlets = RationalCartOutlets;
  private cartReferenceText:string = "";

  public filteredReferences: string[] = [];
  private key = 'cartReference';

  showSuggestions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private hideSuggestionsTimeout: any;
  /* For Enum use in HTML */
  ICON_TYPE = ICON_TYPE;


  // Contains the initial query parameters and will be updated with current state of filters
  _initQueryParams: InvoiceQueryParams = {
    fields: InvoicesFields.FULL
  };

  // Triggers events on chaning the page, sort options
  queryParams$ = new BehaviorSubject<InvoiceQueryParams>(this._initQueryParams);


  orderInvoiceList: RationalOrderInvoice[] | undefined;
  constructor(
    protected override cartItemContextSource: CartItemContextSource,
    protected rationalCartItemUpdateService: RationalCartItemUpdateService,
    protected rationalOrderDetailsService : RationalOrderDetailsService,
    private activeCartService: ActiveCartFacade,
    protected winRef: WindowRef,
    private rationalOrderSummaryService: RationalOrderSummaryService,
    protected pdfInvoicesFacade: PDFInvoicesFacade,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    private autoCompleteService: RationalAutoCompleteService
  ) {
    super(cartItemContextSource);

    this.autoCompleteService.loadUserEnteredValues(this.key);
  }


  ngOnInit(): void {
    if(this.isItOrderPage()){
      this.getInvoices();
    }

    this.quantityControl.enable({emitEvent:false});

    this.cartReferenceText = this.item.reference!;
  }

  onReferenceInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (value.length > 1) {
      this.filteredReferences = this.autoCompleteService.getFilteredSuggestions(this.key, value);

      this.showSuggestions.next(true);
    } else {
      this.filteredReferences = [];
      this.showSuggestions.next(false) ;
    }

  }
  showAllSuggestions(): void {
    this.filteredReferences = this.autoCompleteService.getAllSuggestions(this.key);
    this.showSuggestions.next(true);
  }

  selectSuggestion(suggestion: string,  entryNumber: number): void {
    const inputElements = (document.getElementsByName('reference'.concat(entryNumber!.toString())))[0] as HTMLInputElement;
    inputElements.value = suggestion;
  }

  hideSuggestions(): void {
    clearTimeout(this.hideSuggestionsTimeout);
    this.hideSuggestionsTimeout = setTimeout(() => {
      this.filteredReferences = [];
      this.showSuggestions.next(false);
    }, 200);
  }

  override removeItem() {
    super.removeItem();
    this.activeCartService.getEntries().subscribe((entries) => {
      if (entries.length == 0) {
        this.rationalOrderSummaryService.removeRequestedretrievaldate();
      }
    });
  }

  setEntryReference(entryNumber: number, reference: string): void {
    //stop sending updating cart reference if reference text not changed
    if (reference.trim() === this.cartReferenceText) {
      return;
    }

    //save reference value in local storage to be used after that in auto complete
    this.autoCompleteService.addUserEnteredValue(this.key, reference);
    
    this.rationalCartItemUpdateService.updateOrderEntry(entryNumber, reference).subscribe(response => {
      this.cartReferenceText = reference;
    });
  }

  isItCartPage(): boolean | undefined {
    return this.winRef.nativeWindow?.location.href.includes('/cart');
  }

  isItOrderPage(): boolean | undefined {
    return this.winRef.nativeWindow?.location.href.includes('/my-account/order');
  }

  getInvoices() {
    return this.pdfInvoicesFacade.getInvoicesForOrder(this._initQueryParams).subscribe(
      (orderInvoiceResponse) => {
        this.orderInvoiceList = (orderInvoiceResponse.invoices as RationalOrderInvoice[]);
      }
    );
  }


  showBillingInvoices(event: UIEvent, productCode: String | undefined) {
    const productInvoices = this.getInvoicesForProducts(productCode ?? '');
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.BILLING_INVOICES,
      undefined,
      this.vcr,
      { invoices: productInvoices }
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }

    event.stopPropagation();
  }


  getInvoicesForProducts(productCode: String): RationalOrderInvoice[] {
    return this.orderInvoiceList?.filter(invoice => (invoice as RationalOrderInvoice).productCode == productCode) ?? [];
  }


  showShippingItems(
    event: UIEvent,
    orderId: string | undefined,
    entryNumber: number | undefined
  ) {
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.SHIPPING_ITEMS,
      undefined,
      this.vcr,
      { orderCode: orderId, entryNumber: entryNumber }
    );

    if (dialog) {
      dialog.pipe(take(1)).subscribe();
    }

    event.stopPropagation();
  }
}
