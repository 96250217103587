export const organization = { 
    userRoles: {
      b2bcustomergroup: 'Kund',
      b2bapprovergroup: 'Godkännare',
      b2bmanagergroup: 'Chef',
      b2badmingroup: 'Administratör'
    },
    breadcrumb: 'Organisation',
    notification: {
      noSufficientPermissions: 'Inga tillräckliga behörigheter för att komma åt denna sida',
      notExist: 'Det här objektet finns inte',
      disabled: 'Du kan inte redigera ett inaktiverat objekt'
    },
    confirmation: {
      cancel: 'AVBRYT',
      confirm: 'BEKRÄFTA',
      disable: 'INAKTIVERA',
      delete: 'RADERA'
    },
    httpHandlers: {
      conflict: {
        budget: 'Budget med kod {{ code }} finns redan.',
        costCenter: 'Kostnadsställe med kod {{ code }} finns redan.',
        unit: 'Organisationsenhet med uid {{ code }} finns redan.',
        user: 'Användare med e-post {{ code }} finns redan',
        userGroup: 'Användargrupp med id {{ code }} finns redan',
        permission: 'Godkännandebehörighet med koden {{ code }} finns redan',
        unknown: 'Fel vid servervalidering.'
      }
    }
};
