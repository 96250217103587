<ng-container *ngIf="cartEntry">
  <label *ngIf="isDisabled()" class="disabled-link">
    <ng-container *ngIf="isDisabled(); then configureText"> </ng-container>
  </label>

  <ng-container *ngIf="isItCartPage()">
    <a *ngIf="!isDisabled()" class="link cx-action-link"
        [routerLink]="
          {
            cxRoute: getRoute(),
            params: {
              ownerType: getOwnerType(),
              entityKey: getEntityKey(),
            }
          } | cxUrl
        "
        [queryParams]="getQueryParams()"
        cxAutoFocus
        attr.aria-describedby="{{ getResolveIssuesA11yDescription() }}"
    >
      <ng-container *ngIf="!isDisabled(); then configureText"> </ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="!isItCartPage()">
    <a *ngIf="!isDisabled()" class="link cx-action-link"
        [routerLink]="
          {
            cxRoute: getRoute(),
            params: {
              ownerType: getOwnerType(),
              entityKey: getEntityKey(),
              displayOnly: getDisplayOnly()
            }
          } | cxUrl
        "
        [queryParams]="getQueryParams()"
        cxAutoFocus
        attr.aria-describedby="{{ getResolveIssuesA11yDescription() }}"
    >
      <ng-container *ngIf="!isDisabled(); then configureText"> </ng-container>
    </a>
  </ng-container>



</ng-container>

<ng-template #configureText>
  <ng-container *ngIf="readOnly">
    {{ 'configurator.header.displayConfiguration' | cxTranslate }}
  </ng-container>

  <ng-container *ngIf="!readOnly && !msgBanner">
    {{ 'configurator.header.editConfiguration' | cxTranslate }}
  </ng-container>

  <ng-container *ngIf="!readOnly && msgBanner">
    {{ 'configurator.header.resolveIssues' | cxTranslate }}
  </ng-container>
</ng-template>
