import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { RoutingService, TranslationService } from '@spartacus/core';
import { OrderHistoryComponent } from '@spartacus/order/components';
import {
  OrderHistoryFacade,
  ReplenishmentOrderHistoryFacade,
} from '@spartacus/order/root';
import '../../model/rational.order.model';
import { RationalOccOrderHistoryAdapter } from './rational-occ-order-history.adapter';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RationalOrderHistoryFilters } from './rational-order-history-filters/rational-order-history-filters.model';
import { Observable, of } from 'rxjs';
import { OrderHistoryList } from '@spartacus/order/root';
import { RationalOrderHistoryService } from './rational-order-history-service';

@Component({
  selector: 'cx-order-history',
  templateUrl: './rational-order-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalOrderHistoryComponent extends OrderHistoryComponent {
  private NEW_PAGE_SIZE = 25;
  private orderHistoryfilters: RationalOrderHistoryFilters = {};

  protected filterForm: UntypedFormGroup = this.fb.group({
    paginationNumber: [0],
  });

  constructor(
    protected override routing: RoutingService,
    protected override orderHistoryFacade: RationalOrderHistoryService,
    protected override translation: TranslationService,
    protected override replenishmentOrderHistoryFacade: ReplenishmentOrderHistoryFacade,
    protected orderHistoryAdapter: RationalOccOrderHistoryAdapter,
    private ref: ChangeDetectorRef,
    protected fb: UntypedFormBuilder
  ) {
    super(
      routing,
      orderHistoryFacade,
      translation,
      replenishmentOrderHistoryFacade
    );
  }


  override changeSortCode(sortCode: string): void {
    this.sortType = sortCode;
    this.startPaginationFromZero();
    this.loadOrdersWithFilters(this.orderHistoryfilters, 0);
  }

  override pageChange(page: number): void {
    this.loadOrdersWithFilters(this.orderHistoryfilters, page);
  }

  protected jumpToPage() {
    const paginationNumberControl = this.filterForm.get('paginationNumber');
    const paginationNumber = paginationNumberControl?.getRawValue()
      ? paginationNumberControl?.getRawValue() - 1
      : 0;

    this.loadOrdersWithFilters(this.orderHistoryfilters, paginationNumber);
  }

  protected changeFilters(newFilters: RationalOrderHistoryFilters) {
    this.orderHistoryfilters = newFilters;
    this.startPaginationFromZero();
    this.loadOrdersWithFilters(newFilters, 0);
  }

  protected loadOrdersWithFilters(
    filters: RationalOrderHistoryFilters,
    currentPage: number
  ): void {
    this.fetchOrdersWithFilters(filters, currentPage).subscribe((data) => {
      this.orders$ = of(data);
      this.isLoaded$ = of(true);
      this.ref.detectChanges();
    });
  }

  protected fetchOrdersWithFilters(
    filters: RationalOrderHistoryFilters,
    currentPage: number
  ): Observable<OrderHistoryList> {
    return this.orderHistoryFacade.loadHistoryWithSpecialFilters(
      this.NEW_PAGE_SIZE,
      currentPage,
      this.sortType,
      filters
    );

  }

  private startPaginationFromZero() {
    const paginationNumberControl = this.filterForm.get('paginationNumber');
    paginationNumberControl?.setValue(0);
  }
}
