import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Product, ProductService, TranslationService } from '@spartacus/core';
import { ConfiguratorRouterExtractorService } from '@spartacus/product-configurator/common';
import {
  Configurator,
  ConfiguratorCommonsService,
  ConfiguratorVariantCarouselComponent,
  RulebasedConfiguratorConnector,
  StateWithConfigurator,
} from '@spartacus/product-configurator/rulebased';
import { map, Observable, of, switchMap } from 'rxjs';
import { RationalVariant } from 'src/app/custom/model/rational.product-configurator.model';

@Component({
  selector: 'cx-configurator-variant-carousel',
  templateUrl: './rational-configurator-variant-carousel.component.html',
  styleUrl: './rational-configurator-variant-carousel.component.scss',
})
export class RationalConfiguratorVariantCarouselComponent extends ConfiguratorVariantCarouselComponent {
  constructor(
    protected override productService: ProductService,
    protected override translationService: TranslationService,
    protected override configuratorRouterExtractorService: ConfiguratorRouterExtractorService,
    protected override configuratorCommonsService: ConfiguratorCommonsService,
    protected store: Store<StateWithConfigurator>,
    protected configuratorCommonsConnector: RulebasedConfiguratorConnector
  ) {
    super(
      productService,
      translationService,
      configuratorRouterExtractorService,
      configuratorCommonsService
    );
  }

  variants$: Observable<Observable<RationalVariant | undefined>[]> =
    this.configuration$.pipe(
      switchMap((configuration) =>
        this.configuratorCommonsConnector.searchVariants(configuration)
      ),
      map((variants: RationalVariant[]) => {
        return variants.map((variant: RationalVariant) => {
          return of(variant);
        });
      })
    );
}
