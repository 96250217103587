export const organization = {
  organization: {
    enabled: 'Aktywny',
    disabled: 'Wyłączony',
    enable: 'Włącz',
    disable: 'Wyłącz',
    name: 'Nazwa',
    code: 'Kod',
    done: 'zrobione',
    cancel: 'Anuluj',
    add: 'Dodaj',
    create: 'Utwórz {{name}}',
    edit: 'Edytuj',
    save: 'Zapisz {{name}}',
    delete: 'Usuń',
    assign: 'Zarządzaj',
    active: 'Aktywny',
    status: 'Status',
    details: 'Szczegóły',
    messages: {
      emptyList: 'Lista jest pusta'
    },
    userRoles: {
      b2bcustomergroup: 'Klient',
      b2bapprovergroup: 'Zatwierdzający',
      b2bmanagergroup: 'Menedżer',
      b2badmingroup: 'Administrator'
    },
    breadcrumb: 'Organizacja',
    notification: {
      noSufficientPermissions: 'Brak wystarczających uprawnień, aby uzyskać dostęp do tej strony',
      notExist: 'Ten element nie istnieje',
      disabled: 'Nie można edytować wyłączonego elementu'
    },
    confirmation: {
      cancel: 'ANULUJ',
      confirm: 'POTWIERDŹ',
      disable: 'WYŁĄCZ',
      delete: 'USUŃ'
    },
    httpHandlers: {
      conflict: {
        budget: 'Budżet z kodem {{ code }} już istnieje.',
        costCenter: 'Centrum kosztów z kodem {{ code }} już istnieje.',
        unit: 'Jednostka organizacyjna z uid {{ code }} już istnieje.',
        user: 'Użytkownik z adresem e-mail {{ code }} już istnieje',
        userGroup: 'Grupa użytkowników o identyfikatorze {{ code }} już istnieje',
        permission: 'Uprawnienie zatwierdzania z kodem {{ code }} już istnieje.',
        unknown: 'Błąd walidacji serwera.'
      }
    }
  }
}

