export const orderDetails = {
    orderId: "Sipariş #",
    orderNumber: "Webshop Sipariş Numarası",
    replenishmentId: "İkmal #",
    purchaseOrderId: "Satınalma Siparişi #",
    purchaseOrderNumber: "Satınalma Sipariş Numaranız",
    emptyPurchaseOrderId: "Hiçbiri",
    none: "Hiçbiri {{value}}",
    placed: "Yerleştirildi",
    placedBy: "Tarafından yerleştirildi",
    unit: "Birim",
    costCenter: "Maliyet Merkezi",
    costCenterAndUnit: 'Maliyet Merkezi / Birim',
    costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
    methodOfPayment: "Ödeme Yöntemi",
    payByAccount: "Hesaba Göre Ödeme",
    paidByCreditCard: "(kredi kartı ile ödenir)",
    status: "Durum",
    active: "Aktif",
    shippedOn: "Üzerinde sevk",
    shippingMethod: "Nakliye Yöntemi",
    placedOn: "Üzerine yerleştirildi",
    startOn: "Başla",
    nextOrderDate: "Sonraki Sipariş Tarihi",
    frequency: "Frekans",
    cancelled: "İptal Edildi",
    deliveryStatus_IN_TRANSIT: "Transit Halinde",
    deliveryStatus_READY_FOR_PICKUP: "Teslim Almaya Hazır",
    deliveryStatus_READY_FOR_SHIPPING: "Sevkiyata Hazır",
    deliveryStatus_WAITING: "Bekliyorum",
    deliveryStatus_DELIVERING: "Teslimat",
    deliveryStatus_PICKPACK: "Sevkiyat için Hazırlık",
    deliveryStatus_PICKUP_COMPLETE: "Pikap Tamamlandı",
    deliveryStatus_DELIVERY_COMPLETED: "Teslimat Tamamlandı",
    deliveryStatus_PAYMENT_NOT_CAPTURED: "Ödeme Sorunu",
    deliveryStatus_READY: "İşlem Devam Ediyor",
    deliveryStatus_DELIVERY_REJECTED: "Teslimat Reddedildi",
    deliveryStatus_SHIPPED: "Sevkiyat",
    deliveryStatus_TAX_NOT_COMMITTED: "Vergi Sorunu",
    deliveryStatus_CANCELLED: "İptal Edildi",
    statusDisplay_cancelled: "İptal Edildi",
    statusDisplay_cancelling: "Beklemede İptal",
    statusDisplay_confirmed: "Sipariş onaylandı",
    statusDisplay_completed: "Sipariş teslim edildi",
    statusDisplay_created: "Sipariş oluşturuldu",
    statusDisplay_error: "Beklemede",
    statusDisplay_Error: "Beklemede",
    statusDisplay_processing: "Sipariş oluşturuldu",
    statusDisplay_open: "Sipariş oluşturuldu",
    statusDisplay_pending: {
        approval: "Onay Bekliyor",
        merchant: {
            Approval: "Satıcı Onayı Bekleniyor"
        }
    },
    statusDisplay_approved: "Onaylandı",
    statusDisplay_rejected: "Reddedildi",
    statusDisplay_merchant: {
        Approved: "Tüccar Onaylı",
        Rejected: "Tüccar Reddedildi",
    },
    statusDisplay_assigned: {
        admin: "Yöneticiye Atandı"
    },
    consignmentTracking: {
      action: "İzleme Bilgileri",
      dialog: {
        header: "Sevkiyat Takibi",
        shipped: "Sevkiyat",
        estimate: "Tahmini Teslimat",
        carrier: "Teslimat Hizmeti",
        trackingId: "Takip Numarası",
        noTracking: "Paket depodan gönderilmemiştir. Takip bilgileri, paket gönderildikten sonra mevcut olacaktır.",
        loadingHeader: "Kargo Takibi"
      }
    },
    cancellationAndReturn: {
      returnAction: "İade Talebi",
      cancelAction: "Öğeleri İptal Et",
      item: "Öğe",
      itemPrice: "Ürün Fiyatı",
      quantity: "Maksimum Miktar",
      returnQty: "İade Edilecek Miktar",
      cancelQty: "İptal Edilecek Miktar",
      setAll: "Tüm miktarları maksimuma ayarlayın",
      totalPrice: "Toplam",
      submit: "Talep Gönder",
      returnSuccess: "İade talebiniz ({{rma}}) gönderildi",
      cancelSuccess: "İptal talebiniz gönderildi (orijinal sipariş {{orderCode}} güncellenecektir)"
    },
    cancelReplenishment: {
      title: "Yenilemeyi İptal Et",
      description: "Gelecekteki ikmal siparişlerini iptal edeyim mi?",
      accept: "Evet",
      reject: "Hayır",
      cancelSuccess: "İkmal siparişi #{{replenishmentOrderCode}} başarıyla iptal edildi."
    }
  };
  
export const orderHistory = {
    orderHistory: "Sipariş geçmişi",
    orderId: "Sipariş #",
    placedBy: "İrtibat kişisi",
    emptyPurchaseOrderId: "Hiçbiri",
    date: "Tarih",
    status: "Durum",
    total: "Toplam",
    noOrders: "Bu hesap için sipariş kaydımız yok.",
    noReplenishmentOrders: "Bu hesap için yenileme siparişi kaydımız yok.",
    startShopping: "Alışverişe Başlayın",
    sortBy: "Göre sırala",
    replenishmentOrderHistory: "İkmal Sipariş Geçmişi",
    replenishmentOrderId: "İkmal #",
    purchaseOrderNumber: "PO #",
    startOn: "Başla",
    frequency: "Frekans",
    nextOrderDate: "Sonraki Sipariş Tarihi",
    cancel: "İptal",
    cancelled: "İptal Edildi",
    replenishmentHistory: "İkmal Geçmişi",
    notFound: "Sipariş Bulunamadı"
  };
  
export const wishlist = {
    empty: "İstek listenizde henüz ürün yok"
  };
  
export const myAccount = {
    orderDetails,
    orderHistory,
    wishlist
};
