export const orderDetails = {
  orderId: 'Order #',
  orderNumber: 'RATIONAL Order Number',
  replenishmentId: 'Replenishment #',
  purchaseOrderId: 'Purchase Order #',
  purchaseOrderNumber: 'Your Purchase Order Number',
  emptyPurchaseOrderId: 'None',
  none: 'None {{value}}',
  placed: 'Placed',
  placedBy: 'Placed By',
  unit: 'Unit',
  costCenter: 'Cost Center',
  costCenterAndUnit: 'Cost Center / Unit',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  methodOfPayment: 'Method of Payment',
  payByAccount: 'Pay by Account',
  paidByCreditCard: '(paid by credit card)',
  status: 'Status',
  active: 'Active',
  shippedOn: 'Shipped on',
  shippingMethod: 'Shipping Method',
  placedOn: 'Placed on',
  startOn: 'Start On',
  nextOrderDate: 'Next Order Date',
  frequency: 'Frequency',
  cancelled: ' Cancelled',
  deliveryStatus_IN_TRANSIT: 'In Transit',
  deliveryStatus_READY_FOR_PICKUP: 'Ready for Pickup',
  deliveryStatus_READY_FOR_SHIPPING: 'Ready for Shipping',
  deliveryStatus_WAITING: 'Waiting',
  deliveryStatus_DELIVERING: 'Delivering',
  deliveryStatus_PICKPACK: 'Preparing for Shipment',
  deliveryStatus_PICKUP_COMPLETE: 'Pickup Complete',
  deliveryStatus_DELIVERY_COMPLETED: 'Delivery Complete',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Payment Issue',
  deliveryStatus_READY: 'In Process',
  deliveryStatus_DELIVERY_REJECTED: 'Delivery Rejected',
  deliveryStatus_SHIPPED: 'Shipped',
  deliveryStatus_TAX_NOT_COMMITTED: 'Tax Issue',
  deliveryStatus_CANCELLED: 'Cancelled',
  statusDisplay_cancelled: 'Cancelled',
  statusDisplay_cancelling: 'Cancel Pending',
  statusDisplay_confirmed: 'Order confirmed',
  statusDisplay_completed: 'Order delivered',
  statusDisplay_created: 'Order created',
  statusDisplay_error: 'Pending',
  statusDisplay_Error: 'Pending',
  statusDisplay_processing: 'Order created',
  statusDisplay_open: 'Order created',
  statusDisplay_pending: {
  approval: 'Pending Approval',
  merchant: {
      approval: 'Pending Merchant Approval'
    }
  },
  statusDisplay_approved: 'Approved',
  statusDisplay_rejected: 'Rejected',
  statusDisplay_merchant: {
    approved: 'Merchant Approved',
    rejected: 'Merchant Rejected'
  },
  statusDisplay_assigned: {
    admin: 'Assigned To Administrator'
  },
  consignmentTracking: {
    action: 'Track package',
    dialog: {
      header: 'Tracking Information',
      shipped: 'Shipped',
      estimate: 'Estimated Delivery',
      carrier: 'Delivery Service',
      trackingId: 'Tracking Number',
      noTracking: 'The package has not been dispatched from the warehouse. The tracking information will be available after the package is shipped.',
      loadingHeader: 'Consignment Tracking'
    }
  },
  cancellationAndReturn: {
    returnAction: 'Request a Return',
    cancelAction: 'Cancel Items',
    item: 'Item',
    itemPrice: 'Item Price',
    quantity: 'Max Quantity',
    returnQty: 'Quantity to Return',
    cancelQty: 'Quantity to Cancel',
    setAll: 'Set all quantities to maximum',
    totalPrice: 'Total',
    submit: 'Submit Request',
    returnSuccess: 'Your return request ({{rma}}) was submitted',
    cancelSuccess: 'Your cancellation request was submitted (original order {{orderCode}} will be updated)'
  },
  cancelReplenishment: {
  title: 'Cancel Replenishment',
  description: 'Cancel any future replenishment order?',
  accept: 'Yes',
  reject: 'No',
  cancelSuccess: 'Replenishment order #{{replenishmentOrderCode}} has been successfully cancelled'
  }
}

export const orderHistory = {
  orderHistory: 'Order history',
  orderId: 'Order #',
  placedBy: 'Contactperson',
  emptyPurchaseOrderId: 'None',
  date: 'Date',
  status: 'Status',
  total: 'Total',
  noOrders: 'We have no order records for this account.',
  noReplenishmentOrders: 'We have no replenishment order records for this account.',
  startShopping: 'Start Shopping',
  sortBy: 'Sort by',
  replenishmentOrderHistory: 'Replenishment Order History',
  replenishmentOrderId: 'Replenishment #',
  purchaseOrderNumber: 'PO #',
  startOn: 'Start On',
  frequency: 'Frequency',
  nextOrderDate: 'Next Order Date',
  cancel: 'Cancel',
  cancelled: 'Cancelled',
  replenishmentHistory: 'Replenishment History',
  notFound: 'No Orders Found'
}

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'ORDERS',
    OrderReturnRequestListComponent: 'QUOTES'
  }
}

export const wishlist = {
  empty: 'No products in your wish list yet'
}

export const myAccount = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer,
  wishlist
}
