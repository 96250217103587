export const quotes = {
  created: 'Quote was created',
  title: 'Quotes',
  code: 'Code',
  name: 'Name',
  date: 'Date',
  state: 'Status',
  statusDisplay_BUYER_APPROVED: 'Buyer - Approved',
  statusDisplay_BUYER_DRAFT: 'Buyer - Draft',
  statusDisplay_BUYER_SUBMITTED: 'Buyer - Submitted',
  statusDisplay_BUYER_OFFER: 'Buyer - Offer',
  statusDisplay_BUYER_ACCEPTED: 'Buyer - Accepted',
  statusDisplay_BUYER_REJECTED: 'Buyer - Rejected',
  statusDisplay_BUYER_ORDERED: 'Buyer - Ordered',
  statusDisplay_SELLER_REQUEST: 'Seller - Request',
  statusDisplay_SELLER_DRAFT: 'Seller - Draft',
  statusDisplay_SELLER_SUBMITTED: 'Seller - Submitted',
  statusDisplay_SELLERAPPROVER_DRAFT: 'Sellerapprover - Draft',
  statusDisplay_SELLERAPPROVER_PENDING: 'Sellerapprover - Pending',
  statusDisplay_SELLERAPPROVER_REJECTED: 'Sellerapprover - Rejected',
  statusDisplay_SELLERAPPROVER_APPROVED: 'Sellerapprover - Approved',
  noQuotes: 'Keine Angebote',
  entries: {
    code: 'Code',
    name: 'Name',
    amount: 'Quantity',
    price: 'Price'
  },
  totalPriceWithoutTax: 'Total price without Tax:'
}

