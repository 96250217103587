import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { ProductDownloadsComponent } from './product-downloads.component';
import { FormsModule } from '@angular/forms';  
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [ProductDownloadsComponent],
  imports: [
    CommonModule,     
    I18nModule,
    FormsModule,
    NgSelectModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductDownloadsTabComponent: {
          component: ProductDownloadsComponent,
        },
      },
    }),
  ],
})
export class ProductDownloadsModule { }
