<div
  class="incotermsAndConditions"
  *ngIf="cart$ | async as cart">

  <!-- Incoterms  -->
  <div class="row" *ngIf="cart.incoterms.length > 0">
    <div class="col-md-12 col-lg-6">
      <div class="title">
        {{ "checkoutTermsAndConditions.incoterms" | cxTranslate }}
      </div>
      <div class="value">
        <ng-container *ngFor="let incoterm of cart.incoterms">
          <div>{{ incoterm }}</div>
        </ng-container>
      </div>
    </div>
  </div>


  <!-- Payment Conditions  -->
  <div class="row" *ngIf="cart.paymentConditions.length > 0">
    <div class="col-md-12 col-lg-6">
      <div class="title">
        {{ "checkoutTermsAndConditions.paymentConditions" | cxTranslate }}
      </div>
      <div class="value">
        <ng-container *ngFor="let paymentCondition of cart.paymentConditions">
          <div>{{ paymentCondition }}</div>
        </ng-container> 
      </div>
    </div>
  </div>

</div>
