<div class="cx-shippings-information-dialog"  (esc)="close('Escape clicked')"  [cxFocus]="focusConfig">
  <div class="cx-product-shippings-information-container" #dialogElement>


    <!-- Modal Header -->
    <div class="modal-header cx-product-shipping-docs-header">
      <div class="cx-product-shipping-docs-title modal-title">
        {{ 'orderDetails.shipping.shipping' | cxTranslate }}
      </div>

      <a (click)="close('Close Product Shipping Information Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="close" type="button">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </a>
    </div>

    <!-- Modal Body -->
    <div class="cx-product-shipping-docs-body">
      <ng-container *ngIf="orderItemShippingList$ | async as shippingDocuments; else shippingsloadingSpinner">
        <ng-container *ngIf="shippingDocuments && shippingDocuments.length > 0; else noShippingInfo">

           <table role="table">
            <thead>
              <tr role="row" class="cx-item-list-header">
                <th role="columnheader" class="cx-item-list-desc">
                  {{ 'orderDetails.shipping.shippingId' | cxTranslate }}
                </th>
                <th role="columnheader" class="cx-item-list-qty">
                  {{ 'orderDetails.shipping.deliveryDate' | cxTranslate }}
                </th>
                <th role="columnheader" class="cx-item-list-qty">
                  {{ 'orderDetails.shipping.quantity' | cxTranslate }}
                </th>
                <th role="columnheader" class="cx-item-list-qty">
                  {{ 'orderDetails.shipping.tracking' | cxTranslate }}
                </th>
                <th role="columnheader" class="cx-item-list-qty">
                  {{ 'downloads.download' | cxTranslate }}
                </th>
              </tr>
            </thead>


            <tbody class="cx-item-list-items">
              <ng-container *ngFor="let shippingDoc of shippingDocuments; let i = index">
                <tr>
                  <td role="cell">
                    <div class="cx-mobile-header"> {{ 'orderDetails.shipping.shippingId' | cxTranslate }}</div>
                    <div class="cx-value">{{shippingDoc.documentId}} </div>
                  </td>


                  <td role="cell">
                    <div class="cx-mobile-header">{{ 'orderDetails.shipping.deliveryDate' | cxTranslate }}</div>
                    <div class="cx-value">{{shippingDoc.deliveryDate | cxDate: 'dd.MM.yyyy'}}</div>
                  </td>

                  <td role="cell">
                    <div class="cx-mobile-header">{{ 'orderDetails.shipping.quantity' | cxTranslate }}</div>
                    <div class="cx-value">{{shippingDoc.deliveryQuantity | number: '1.0-0' }}</div>
                  </td>

                  <td role="cell" class="downloadLink">
                    <div class="cx-mobile-header">{{ 'orderDetails.shipping.tracking' | cxTranslate }}</div>
                    <div class="cx-value" (click)="showTrackingDocuments(i)" [class.is-active]="visibleTrackingLineIndex == i">
                      <cx-icon class="icon-raticons icon-arrow_down"></cx-icon>
                    </div>
                  </td>
                  
                  <td role="cell" class="downloadLink">
                    <div class="cx-mobile-header">{{ 'downloads.download' | cxTranslate }}</div>
                    <div class="cx-value" (click)="showShippingDocuments(i)"  [class.is-active]="visibleLineIndex == i">
                      <cx-icon class="icon-raticons icon-arrow_down"></cx-icon>
                    </div>
                  </td>
                </tr>

                <ng-container *ngIf="visibleTrackingLineIndex == i">
                  <tr>
                    <td colspan="5">
                      <cx-order-tracking-documents [shippingNumber]="shippingDoc.documentId" />
                    </td>
                  </tr>
                </ng-container>

                <ng-container *ngIf="visibleLineIndex == i">
                  <tr>
                    <td colspan="5">
                      <cx-order-documents-list  [documentId]="shippingDoc.documentId" [orderDocumentCategoryType]="ordershippingDocumentCategoryType" />
                    </td>
                  </tr>
                </ng-container>


              </ng-container>
            </tbody>
          </table>
        </ng-container>


        <ng-template #noShippingInfo>
          <div>
            {{ 'orderDetails.shipping.noShippingInfo' | cxTranslate }}
          </div>
        </ng-template>
      </ng-container>

      <ng-template #shippingsloadingSpinner>
        <div class="cx-spinner">
            <cx-spinner></cx-spinner>
        </div>
      </ng-template>

    </div>
  </div>
</div>
