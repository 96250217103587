<ng-container>
  
  <ng-container *ngIf="loadingLogin">
    <cx-spinner></cx-spinner>
    <div class="spinner-text"> <p>{{ 'loginForm.redirect' | cxTranslate}}</p> </div>
  </ng-container>

  <ng-container *ngIf="!loadingLogin">
  <button (click)="login()" class="btn btn-block btn-primary">
    {{ "loginForm.signIn" | cxTranslate }}
  </button>
</ng-container>

</ng-container>