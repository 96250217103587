import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import {
  GenericLinkModule,
  GlobalMessageComponentModule, HamburgerMenuModule,
  IconModule, KeyboardFocusModule, ListNavigationModule, OutletModule,

  OutletRefModule, PageLayoutModule,
  PageSlotModule, ProductListModule,
  SkipLinkModule, StarRatingModule,
} from '@spartacus/storefront';
import {RationalProductListItemComponent} from "./rational-product-list-item/rational-product-list-item.component";
import {RationalProductListComponent} from "./rational-product-list/rational-product-list.component";
import {RationalProductGridItemComponent} from "./rational-product-grid-item/rational-product-grid-item.component";
import {AddToCartModule} from "@spartacus/cart/base/components/add-to-cart";
import { RationalMediaModule } from '../cmscomponents/media/rational-media.module';
import { ProductDescriptionModule } from '../product/product-description/product-description.module';

@NgModule({
  declarations: [
    RationalProductListItemComponent,
    RationalProductListComponent,
    RationalProductGridItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    IconModule,
    I18nModule,
    PageSlotModule,
    GlobalMessageComponentModule,
    OutletRefModule,
    PageLayoutModule,
    SkipLinkModule,
    KeyboardFocusModule,
    HamburgerMenuModule,
    GenericLinkModule,
    ProductListModule,
    ListNavigationModule,
    StarRatingModule,
    OutletModule,
    AddToCartModule,
    RationalMediaModule,
    ProductDescriptionModule,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        CMSProductListComponent: {
          component: RationalProductListComponent,
        },
        ProductGridComponent: {
          component: RationalProductListComponent,
        },
        SearchResultsListComponent: {
          component: RationalProductListComponent,
        },
      },
    }),
  ],
  exports: [],
})
export class SearchresultModule {
}
