import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '@spartacus/core';

const I18N_VALUES = {
	de: {
		weekdays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
		months: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
	},
    en: {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
	es: {
		weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
        months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
	},
    fr: {
        weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
        months: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
	it: {
		weekdays: ['Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'],
        months: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
	},
    pl: {
        weekdays: ['Po', 'Wt', 'Sr', 'Cz', 'Pi', 'So', 'Ni'],
        months: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paz', 'Lis', 'Gru'],
    },
};

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
    currentLocale!: string
    constructor(private languageService: LanguageService,) {
        super();
        this.languageService.getActive().subscribe(data => {
            this.currentLocale = data
        });
      }
	
	rollbackLocale : string = 'en';

	getWeekdayLabel(weekday: number): string {
		if((I18N_VALUES as { [key: string]: any })[this.currentLocale] === undefined) {
			return ((I18N_VALUES as { [key: string]: any })[this.rollbackLocale]).weekdays[weekday - 1];
		}
		return ((I18N_VALUES as { [key: string]: any })[this.currentLocale]).weekdays[weekday - 1];
	}
	getMonthShortName(month: number): string {
		if((I18N_VALUES as { [key: string]: any })[this.currentLocale] === undefined) {
			return ((I18N_VALUES as { [key: string]: any })[this.rollbackLocale]).months[month - 1];
		}
		return ((I18N_VALUES as { [key: string]: any })[this.currentLocale]).months[month - 1];
	}
	getMonthFullName(month: number): string {
		return this.getMonthShortName(month);
	}
	getDayAriaLabel(date: NgbDateStruct): string {
		return `${date.day}-${date.month}-${date.year}`;
	}
}