import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {I18nModule, UrlModule} from "@spartacus/core";
import {IconModule} from "@spartacus/storefront";
import {RationalCartItemValidationWarningComponent} from "./rational-cart-item-validation-warning.component";



@NgModule({
  declarations: [RationalCartItemValidationWarningComponent],
  imports: [
    CommonModule,
    RouterModule, I18nModule, UrlModule, IconModule
  ],
  exports: [RationalCartItemValidationWarningComponent],
})
export class RationalCartItemValidationWarningModule { }
