export const orderDetails = {
  statusDisplay_open: 'noch nicht bearbeitet',
  statusDisplay_processing: 'Teilweise bearbeitet',
  statusDisplay_completed: 'Vollständig bearbeitet',
  purchaseOrderNumber: 'Eigene Bestellnummer',
  orderNumber: 'RATIONAL Auftragsnummer',
  methodOfPayment: 'Zahlungsmethode',
  shippingMethod: 'Versandmethode',
  placedOn: 'Bestellt am',
  moreInformation: 'Mehr Informationen',
  orderDocuments: 'Auftragsbestätigung',
  billing: 'Rechnung',
  invoiceNumber: 'Rechnungsnummer',
  invoiceDate: 'Datum',
  netAmount: 'Nettopreis',
  noBillingInvoices: 'Noch keine Rechnungsinformationen verfügbar.',
  noOrderDocuments: 'Noch keine Auftragsdokumente verfügbar.',
  noInvoiceDocuments: 'Noch keine Rechnungsdokumente verfügbar.',
  noShipmentDocuments: 'Noch keine Lieferdokumente verfügbar.',
  shipping: {
    shipping: 'Lieferung',
    shippingId: 'Lieferung Id',
    deliveryDate: 'Versand-/Abholdatum',
    quantity: 'Menge',
    tracking: 'Sendungsverfolgung',
    noShippingInfo: 'Noch keine Lieferinformationen verfügbar.',
    noTrackingInfo: 'Noch keine Sendungsverfolgung verfügbar.',
  },
  orderId: 'Auftrag #',
  replenishmentId: 'Nachtrag #',
  purchaseOrderId: 'Auftragsbestätigung #',
  emptyPurchaseOrderId: 'Keine',
  none: 'Keine {{value}}',
  placed: 'Angelegt',
  placedBy: 'Angelegt von',
  unit: 'Unit',
  costCenter: 'Kostenstelle',
  costCenterAndUnit: 'Kostenstelle und Einheit',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  payByAccount: 'Zahlen auf Rechnung',
  paidByCreditCard: '(mit Kreditkarte gezahlt)',
  status: 'Status',
  active: 'Aktiv',
  shippedOn: 'Versanddatum',
  startOn: 'Startdatum',
  nextOrderDate: 'Nächster Bestelltermin',
  frequency: 'Zeitabstand',
  cancelled: 'Abgebrochen',
  deliveryStatus_IN_TRANSIT: 'In Bearbeitung',
  deliveryStatus_READY_FOR_PICKUP: 'Abholbereit',
  deliveryStatus_READY_FOR_SHIPPING: 'Versandfertig',
  deliveryStatus_WAITING: 'Wartezustand',
  deliveryStatus_DELIVERING: 'In Zustellung',
  deliveryStatus_PICKPACK: 'Vorbereitung für den Versand',
  deliveryStatus_PICKUP_COMPLETE: 'Abholung erfolgt',
  deliveryStatus_DELIVERY_COMPLETED: 'Lieferung komplett',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Zahlungsfehler',
  deliveryStatus_READY: 'In Arbeit',
  deliveryStatus_DELIVERY_REJECTED: 'Lieferung abgelehnt',
  deliveryStatus_SHIPPED: 'Versand',
  deliveryStatus_TAX_NOT_COMMITTED: 'Steuer fehlerhaft',
  deliveryStatus_CANCELLED: 'Abgebrochen',
  statusDisplay_cancelled: 'Abgebrochen',
  statusDisplay_cancelling: 'Abbruch läuft',
  statusDisplay_confirmed: 'Bestellung bestätigt',
  statusDisplay_created: 'Bestellung angelegt',
  statusDisplay_error: 'Ausstehend',
  statusDisplay_Error: 'Ausstehend',
  statusDisplay_pending: {
    approval: 'Genehmigung ausstehend',
    merchant: {
      approval: 'Händlergenehmigung steht aus'
    }
  },
  statusDisplay_approved: 'Genehmigt',
  statusDisplay_rejected: 'Abgelehnt',
  statusDisplay_merchant: {
    approved: 'Kunde Genehmigt',
    rejected: 'Kunde Abgelehnt'
  },
  statusDisplay_assigned: {
    admin: 'Dem Administrator zugewiesen'
  },
  consignmentTracking: {
    action: 'Sendungsverfolgung',
    dialog: {
      header: 'Tracking Informationen',
      shipped: 'Versendet',
      estimate: 'Vorraussichtliche Lieferung',
      carrier: 'Zustelldienst',
      trackingId: 'Tracking Nummer',
      noTracking: 'Das Paket hat das Lager noch nicht verlassen. Die Tracking Information steht nach dem Versand zur Verfügung.',
      loadingHeader: 'Sendungsverfolgung'
    }
  },
  cancellationAndReturn: {
    returnAction: 'Retoure anmelden',
    cancelAction: 'Artikel stornieren',
    item: 'Artikel',
    itemPrice: 'Artikel Preis',
    quantity: 'Max Menge',
    returnQty: 'Menge zum retounieren',
    cancelQty: 'Zu stornierende Menge',
    setAll: 'Alle Mengen maximieren',
    totalPrice: 'Gesamt',
    submit: 'Anfrage bestätigen',
    returnSuccess: 'Ihre Retoure ({{rma}}) wurde bestätigt',
    cancelSuccess: 'Ihre Stornierung wurde bestätigt (der Auftrag {{orderCode}} wird aktualisiert)'
  },
  cancelReplenishment: {
    title: 'Auffüllauftrag stornieren',
    description: 'Möchten Sie einen späteren Auffüllauftrag stornieren?',
    accept: 'Ja',
    reject: 'Nein',
    cancelSuccess: 'Auffüllauftrag #{{replenishmentOrderCode}} erfolgreich abgebrochen'
  }
};

export const orderHistory = {
  orderHistory: 'Bestellhistorie',
  orderId: 'Auftragsnummer',
  date: 'Datum',
  status: 'Status',
  PONumber: 'Eigene Bestellnummer',
  total: 'Gesamt',
  noOrders: 'Wir haben keine Auftragsdaten für dieses Konto.',
  startShopping: 'Einkaufen starten',
  sortBy: 'Sortieren nach',
  sortOrders: 'Aufträge sortieren',
  purchaseOrderNumber: 'Eigene Bestellnummer',
  notFound: 'Keine Aufträge gefunden',
  actions: 'Aktionen',
  filters: {
    orderNumber: 'Auftragsnummer',
    orderNumberPlaceholder: 'Auftragsnummer eingeben',
    PONumber: 'Eigene Auftragsnummer',
    PONumberPlaceholder: 'Eigene Auftragsnummer eingeben',
    dateRange: 'Zeitraum',
    dateRanges: {
      LAST_30_DAYS: 'Letzte 30 Tage',
      LAST_3_MONTHS: 'Letzte 3 Monate',
      LAST_6_MONTHS:'Letzte 6 Monate',
      CURRENT_YEAR:'Aktuelles Jahr ',
      LAST_YEAR: 'Letztes Jahr',
      LAST_2_YEARS: 'Vorletztes Jahr'
    },
    applyFilters: 'Filter anwenden',
    clearFilters: 'Filter zurücksetzen',
  },
  jumpToPageLabel: 'Springe zu',
  jumpToPageInput: 'Seitenummer',
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'ALLE BESTELLUNGEN ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
