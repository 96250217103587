<ng-container *ngIf="cartShared && (sharedCart$ | async) as sharedCart" class="sharedCartMesaage">
  <div *ngIf="sharedCart.isShared">
    <a>{{'addToSavedCart.sharedCartMessageStart'| cxTranslate: { userName: sharedCart.savedBy?.name, userMail:
      sharedCart.savedBy!.uid}
      }}</a>
    <a class="clickableLine"
      (click)="openDialog(sharedCart, savedCartFormType.RESTORE)">{{'addToSavedCart.sharedCartMessageMiddle'|
      cxTranslate}}</a>
    <a>{{'addToSavedCart.sharedCartMessageEnd'| cxTranslate}}</a>
  </div>
</ng-container>