import { SAVED_CART_SAVE_CART_PROCESS_ID } from '@spartacus/cart/saved-cart/core';
import { PROCESS_FEATURE, StateUtils } from '@spartacus/core';


export const SAVE_SHARED_CART = '[Saved Shared Cart] Save Shared Cart';


export class SaveSharedCart extends StateUtils.EntityLoadAction {
  override readonly type = SAVE_SHARED_CART;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      saveCartName?: string;
      saveCartDescription?: string;
      isSharedWithB2BUnit?: boolean
    }
  ) {
    super(PROCESS_FEATURE, SAVED_CART_SAVE_CART_PROCESS_ID);
    
  }  
}

export declare class SaveCart extends StateUtils.EntityLoadAction {
  payload: {
      userId: string;
      cartId: string;
      saveCartName?: string;
      saveCartDescription?: string;
      isSharedWithB2BUnit?: boolean
  };
  readonly type = "[Saved Cart] Save Cart";
  constructor(payload: {
      userId: string;
      cartId: string;
      saveCartName?: string;
      saveCartDescription?: string;
      isSharedWithB2BUnit?: boolean
  });
}


