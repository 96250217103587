import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BannerCarouselComponent, CmsComponentData, ICON_TYPE } from "@spartacus/storefront";
import { CmsService, Config } from '@spartacus/core';
import { RationalCmsBannerCarouselDataModel } from './rational-cms-banner-carousel-data-model';
import { CmsBannerCarouselComponent, ContentSlotComponentData } from '@spartacus/core';

@Component({
  selector: 'custom-rationalbannercomponentcontainer',
  templateUrl: './rational-banner-container.component.html',
  styleUrls: ['./rational-banner-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalBannerContainerComponent extends BannerCarouselComponent implements OnInit {

  @Input() indicatorIcon = ICON_TYPE.CIRCLE;
  @Input() previousIcon = ICON_TYPE.CARET_LEFT;
  @Input() nextIcon = ICON_TYPE.CARET_RIGHT;


  components$: Observable<Observable<ContentSlotComponentData>[]>;

  resolveAbsoluteUrl(url: string): string {
    return !url || url.startsWith('http') || url.startsWith('//')
      ? url
      : this.getBaseUrl() + url;
  }

  protected getBaseUrl(): string {
    return (
      this.config.backend?.media?.baseUrl ??
      this.config.backend?.occ?.baseUrl ??
      ''
    );
  }

  constructor(
    private _componentData: CmsComponentData<RationalCmsBannerCarouselDataModel>,
    private _cmsService: CmsService,
    protected config: Config) {
    super(_componentData as CmsComponentData<CmsBannerCarouselComponent>, _cmsService);

    this.components$ = this._componentData.data$.pipe(
      map((data) => data.bannerItems?.trim().split(' ') ?? []),
      map((codes) =>
        codes.map(
          (code) => this._cmsService.getComponentData(code))
      )
    );

  }

  ngOnInit(): void {
    // empty OnInit method
  }

}
