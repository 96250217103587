export const quickOrderCartForm = {
  entriesWasAdded: '({{ quantity }}) {{ product }} ha sido añadido a la cesta',
  entryWasAdded: '{{ product }} ha sido añadido a la cesta',
  noResults: 'No se ha encontrado ningún producto',
  stockLevelReached: 'Se ha alcanzado el nivel máximo de existencias',
  title: 'Añadir rápidamente a la cesta',
  productCodePlaceholder: 'Introduzca el número de artículo',
  entryProductCode: 'Introduzca el número de artículo para un pedido rápido',
  addToCart: 'Pedido rápido',
  product: 'Producto',
  products: 'Productos',
  productCodeLabel: 'Producto ID',
  quantityLabel: 'Cantidad',
}

export const quickOrderForm = {
  placeholder: 'Introducir SKU del producto',
  listLimitReached: 'Se ha alcanzado el límite de productos',
  id: 'Número de artículo {{ id }}',
}

export const quickOrderList = {
  addToCart: 'Añadir al carrito',
  emptyList: 'Lista vacía',
  header: 'Añadir Productos/Skus',
  subHeader: 'Puede añadir hasta {{ limit }} SKU válidas a la vez.',
  errorProceedingToCart: 'Error al ir a la cesta.',
  warningProceedingToCart: 'Advertencia al añadir a la cesta.',
  successfullyAddedToCart: 'Añadido correctamente a la cesta.',
  errors: {
    productIsOutOfStock: '{{ name }} (#{{code }}) está agotado.',
  },
  warnings: {
    productWasReduced: 'La cantidad de {{ name }} (#{{code }}) se ha reducido a {{ quantityAdded}}.',
  },
  successes: {
    productAddedToCart: '{{ name }} (#{{code }}) fue añadido al carrito.',
  },
}

export const quickOrderTable = {
  product: 'Producto',
  actions: 'Acciones',
  id: 'Número de artículo',
  price: 'Precio',
  quantity: 'Cantidad',
  itemPrice: 'Precio del artículo',
  qty: 'Ctd',
  inStock: 'Disponible',
  lowStock: 'Stock limitado',
  outOfStock: 'Agotado',
  listCleared: 'La lista de pedidos rápidos ha sido borrada',
  addedtoCart: 'Lista de pedido rápido se ha añadido a la cesta',
}

export const quickOrder = {
  quickOrderCartForm,
  quickOrderForm,
  quickOrderList,
  quickOrderTable
}
