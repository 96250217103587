export const checkoutAddress = {
  shippingAddress: 'Adres wysyłki',
  selectYourShippingAddress: 'Wybierz adres wysyłki:',
  selectYourDeliveryAddress: 'Wybierz adres dostawy:',
  defaultShippingAddress: 'Domyślny adres dostawy',
  addNewAddress: 'Dodaj nowy adres',
  shipToThisAddress: 'Wyślij na ten adres',
  addressSearch: 'Wyszukiwanie adresu',
  addressSearchMinLength: 'Szukany tekst powinien zawierać co najmniej {{length}} znaków.',
  submitAddressSearch: 'Enter',
  errorSearchingSalesforce: 'W tej chwili nie można zakończyć wyszukiwania, spróbuj ponownie później',
  deliveryAddressSelected: 'Wybrano adres dostawy'
};

export const checkoutOrderConfirmation = {
  confirmationOfOrder: 'Potwierdzenie zamówienia:',
  confirmationOfQuote: 'Potwierdzenie otrzymania wyceny:',
  thankYou: 'Dziękujemy za zamówienie!',
  thankYouQuote: 'Dziękujemy za prośbę o wycenę!',
  invoiceHasBeenSentByEmailLine1: 'Należy pamiętać, że pojawienie się tego zamówienia w historii zamówień może potrwać do jednego dnia roboczego. W historii pojawi się formalne potwierdzenie zamówienia wraz z datą wysyłki/odbioru potwierdzoną przez firmę RATIONAL oraz',
  invoiceHasBeenSentByEmailLine2: 'pełne informacje o cenach wraz z obowiązującymi podatkami. W razie jakichkolwiek pytań dotyczących tego zamówienia należy skontaktować się z działem obsługi klienta RATIONAL i powołać się na podany powyżej identyfikator zamówienia.',
  quoteHasBeenSentByEmailLine1: 'Należy pamiętać, że zapytania ofertowe nie są przenoszone do historii zamówień w sklepie internetowym. W przypadku pytań dotyczących zapytania ofertowego należy skontaktować się z działem obsługi klienta RATIONAL.',
  quoteHasBeenSentByEmailLine2: 'i powołaj się na numer oferty w sklepie internetowym podany powyżej lub swój numer pocztowy.',
  orderItems: 'Pozycje zamówienia',
  orderPlacedSuccessfully: 'Zamówienie złożone pomyślnie',
  createAccount: 'Założyć konto?',
  createAccountForNext: 'Utwórz konto dla <{{email}}>, aby szybciej zrealizować zamówienie podczas następnej wizyty.',
};

export const checkoutReview = {
  review: 'Podsumowanie zamówienia',
  orderItems: 'Zamów produkty',
  autoReplenishOrder: 'Automatyczne uzupełnianie zamówienia',
  confirmThatRead: 'Potwierdzam, że przeczytałem i zgadzam się z regulaminem',
  placeOrder: 'Złóż zamówienie',
  placeQuoteOrder: 'Zapytanie ofertowe',
  scheduleReplenishmentOrder: 'Zaplanuj zamówienie uzupełnienia',
  orderType_PLACE_ORDER: 'Brak (tylko jednorazowe zamówienie)',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Uzupełnij zamówienie',
  termsAndConditions: 'Warunki i postanowienia',
  editShippingAddress: 'Edytuj adres wysyłki',
  poNumber: 'Numer zamówienia zakupu',
  every: 'Każdy',
  startOn: 'Rozpocznij w dniu',
  dayOfMonth: 'W dniu',
  repeatOnDays: 'Powtórz w kolejnych dniach',
  recurrencePeriodType_DAILY: 'Dzień(y)',
  recurrencePeriodType_WEEKLY: 'Tydzień',
  recurrencePeriodType_MONTHLY: 'Miesiąc',
};

export const checkoutShipping = {
  shippingMethod: 'Metoda wysyłki',
  standardDelivery: 'Dostawa standardowa',
  premiumDelivery: 'Dostawa Premium',
};

export const checkoutPO = {
  noPoNumber: 'Brak',
  poNumber: 'Numer zamówienia zakupu',
  costCenter: 'Centrum kosztów',
  placeholder: 'Wprowadź własny numer zamówienia...',
  availableLabel: 'Dostępne adresy wysyłki zależą od jednostki centrum kosztów.',
};

export const checkoutProgress = {
  methodOfPayment: 'Metoda płatności',
  deliveryAddress: 'Adres wysyłki',
  deliveryMode: 'Sposób dostawy',
  paymentDetails: 'Szczegóły płatności',
  reviewOrder: 'Podsumowanie zamówienia',
};

export const checkoutB2B = {
  checkoutB2B: {
    poNumber: 'Numer zamówienia*',
    placeholder: 'Wprowadź własny numer zamówienia...',
    costCenter: 'Centrum kosztów',
    availableLabel: 'Dostępne adresy wysyłki zależą od jednostki centrum kosztów.',
    methodOfPayment: {
      paymentType: 'Rodzaj płatności',
    },
    progress: {
      methodOfPayment: 'Metoda płatności',
    },
    review: {
      poNumber: 'Numer zamówienia',
    },
  },
};

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: 'Incoterms',
    paymentConditions: 'Warunki płatności',
  },
};

export const checkoutMode = {
  deliveryMethod: 'Sposób dostawy',
  deliveryEntries: 'Przedmioty do wysyłki',
  specialShippingInstructions: 'Specjalne instrukcje dotyczące wysyłki',
  specialShippingInstructionsPlaceholder: 'Specjalne instrukcje dotyczące wysyłki'
};


export const checkout = {
  checkoutAddress,
  checkoutOrderConfirmation,
  checkoutReview,
  checkoutShipping,
  checkoutPO,
  checkoutProgress,
  checkout: {
    backToCart: 'Powrót do koszyka',
    invalid: {
      accountType: 'Twoje konto nie pozwala na realizację transakcji w tym miejscu.',
    },
  },
  checkoutMode
}
