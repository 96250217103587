import { Injectable } from "@angular/core";
import { OrderEntry } from "@spartacus/cart/base/root";
import { ExportOrderEntriesToCsvService } from "@spartacus/cart/import-export/components";
import { ExportColumn } from "@spartacus/cart/import-export/core";
import { GlobalMessageService, TranslationService } from "@spartacus/core";
import { ExportCsvFileService } from "@spartacus/storefront";
import { Observable, of } from "rxjs";
import { RationalImportExportConfig } from "../../rational-import-export-config";

@Injectable({
    providedIn: 'root',
})
export class RationalExportOrderEntriesToCsvService extends ExportOrderEntriesToCsvService {
    constructor(
        protected override exportCsvFileService: ExportCsvFileService,
        protected override importExportConfig: RationalImportExportConfig,
        protected override globalMessageService: GlobalMessageService,
        protected override translationService: TranslationService
    ) {
        super(exportCsvFileService, importExportConfig, globalMessageService, translationService);
    }

    protected override columns: ExportColumn[] = [
        {
            name: {
                key: 'quantity',
            },
            value: 'quantity',
        },
        {
            name: {
                key: 'code',
            },
            value: 'product.code',
        },
    ];

    protected override get separator(): string | undefined {
        return this.importExportConfig.cartImportExport?.file.separator;
    }

    protected override getResolvedEntries(entries: OrderEntry[]): Observable<string[][]> {
        const values = this.limitValues(this.resolveValues(entries));
        const result = of(values);
        return result;
    }
}