import { Component, Input } from '@angular/core';
import { Product, WindowRef } from '@spartacus/core';


@Component({
  selector: 'rational-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.scss']
})
export class ProductDescriptionComponent{

  constructor(protected winRef: WindowRef){}

  @Input() product : Product | undefined;
  isDescriptionVisible = false;
  
  closePopup() {  
    this.isDescriptionVisible = false;
  }

  onMouseEnter(event: MouseEvent) {
    this.isDescriptionVisible = true;
  }
}