<div class="rational-cx-checkout-order-summary">

<div class="cx-summary-heading">
    {{ 'orderCost.orderSummary' | cxTranslate }}
  </div>
  
  <div class="cx-summary-partials" *ngIf="order$ |async as order">
    <div class="cx-summary-row">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.subtotal' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ order.subTotal?.formattedValue }}
      </div>
    </div>
    <div class="cx-summary-row cx-summary-total">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.total' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ order.totalPriceWithTax?.formattedValue }}
      </div>
    </div>
    <div class="cx-summary-row cx-summary-statement">
      <a>{{ 'orderCost.taxStatement' | cxTranslate}}</a>
      <a>{{ 'orderCost.taxStatement2' | cxTranslate}}</a>
    </div>
<!--     <div class="cx-summary-row" *ngIf="cart.totalDiscounts?.value > 0">
      {{ 'orderCost.discount' | cxTranslate }}
      {{ cart.totalDiscounts?.formattedValue }}
    </div> -->
    <ng-template #cartWithoutNet>
      <div class="cx-summary-row">
        {{
          order.totalPriceWithTax?.value !== order.totalPrice?.value
            ? ('orderCost.grossTax' | cxTranslate)
            : ('orderCost.grossIncludeTax' | cxTranslate)
        }}
        {{ order.totalTax?.formattedValue }}.
      </div>
    </ng-template>
  </div>

  
<!--   
  <cx-applied-coupons
    [vouchers]="cart.appliedVouchers"
    [isReadOnly]="true"
  ></cx-applied-coupons> -->
</div>