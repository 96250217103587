import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CartProceedToCheckoutComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { RationalCart } from 'src/app/custom/model/rational-cart.model';
import { RationalOrderSummaryService } from '../order-summary/rational-order-summary.service';

@Component({
  selector: 'cx-cart-proceed-to-checkout',
  templateUrl: './rational-cart-proceed-to-checkout.component.html',
  styleUrls: ['./rational-cart-proceed-to-checkout.component.scss']
})
export class RationalCartProceedToCheckoutComponent extends CartProceedToCheckoutComponent {
  disableProceedToCheckout = false;
  private dateSubscription = new Subscription();

  constructor(router: Router, private activeCartService: ActiveCartFacade, private globalMessageService: GlobalMessageService, private rationalOrderSummaryService: RationalOrderSummaryService, override cd: ChangeDetectorRef) {
    super(router);

    activeCartService.getActive().pipe(
      map((cart) => (cart as RationalCart).orderLock)
    ).subscribe(response => {
      this.disableProceedToCheckout = response;
      if (response) {
        globalMessageService.add(
          { key: 'cartDetails.orderLocked' },
          GlobalMessageType.MSG_TYPE_WARNING
        );
      }
    });


    this.dateSubscription = this.activeCartService.getActive().pipe(
      map((cart) => (cart as RationalCart).requestedRetrievalAt)
    ).subscribe(response => {
      if (!response) {
        this.disableProceedToCheckout = true;
        this.globalMessageService.add(
          { key: 'cartDetails.requestedRetrievalDateEmpty' },
          GlobalMessageType.MSG_TYPE_INFO
        );
      }
      else {
        this.rationalOrderSummaryService.getEarliestDeliveryDate().subscribe(earliest => {
          if (this.formatResponseDate(response).getTime() < this.formatEarliestDate(earliest).getTime()) {
            this.disableProceedToCheckout = true;
            cd.detectChanges()
            this.globalMessageService.add(
              { key: 'cartDetails.requestedRetrievalDateInvalid' },
              GlobalMessageType.MSG_TYPE_ERROR
            );
          }
          else {
            this.disableProceedToCheckout = false;
            this.globalMessageService.add(
              { key: 'cartDetails.requestedRetrievalDateAdded' },
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_INFO)
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR)
          }
        })
      }
    });
  }

  override ngOnDestroy(): void {
    this.dateSubscription.unsubscribe()
    super.ngOnDestroy()
  }

  formatResponseDate(dateString: String): Date {
    const [year, month, day] = dateString.split('-');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  formatEarliestDate(dateString: String): Date {
    const [day, month, year] = dateString.split('.');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

}
