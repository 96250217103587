import { Injectable } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  UserIdService,
} from '@spartacus/core';
import {
  OrderDetailsService,
  OrderReturnService,
} from '@spartacus/order/components';
import {
  CancelOrReturnRequestEntryInput,
  Order,
  OrderReturnRequestFacade,
} from '@spartacus/order/root';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import { OccEndpointsService } from '@spartacus/core';
import { ReturnRequestEntryInputList } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RationalOrderReturnService extends OrderReturnService {
  constructor(
    protected override orderDetailsService: OrderDetailsService,
    protected override returnRequestService: OrderReturnRequestFacade,
    protected override routing: RoutingService,
    protected override globalMessageService: GlobalMessageService,

    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected userIdService: UserIdService
  ) {
    super(
      orderDetailsService,
      returnRequestService,
      routing,
      globalMessageService
    );
  }

  order$: Order | null = null;

  override getForm(): Observable<UntypedFormGroup> {
    return this.getOrder().pipe(
      tap((order) => {
        this.order$ = order;
        if (!this.form || this.form.get('orderCode')?.value !== order.code) {
          this.buildCustomForm(order);
        }
      }),
      map(() => this.form)
    );
  }

  private buildCustomForm(order: Order): void {
    this.form = new UntypedFormGroup({});

    // add custom fields to form
    this.form.addControl('orderCode', new UntypedFormControl(order.code));
    this.form.addControl('returnComment', new UntypedFormControl(''));
    this.form.addControl('attachments', new UntypedFormControl([]));

    const entryGroup = new UntypedFormGroup(
      {},
      { validators: [ValidateQuantityToReturn] }
    );
    this.form.addControl('entries', entryGroup);

    (order.entries || []).forEach((entry) => {
      const key = entry?.entryNumber?.toString() ?? '';
      entryGroup.addControl(
        key,
        new UntypedFormControl(0, {
          validators: [
            Validators.min(0),
            Validators.max(this.getMaxAmendQuantity(entry)),
          ],
        })
      );
    });
  }

  override save(): void {
    const orderCode = this.form.value.orderCode;
    const entries = this.form.value.entries;

    // returned entries/products
    const inputs: CancelOrReturnRequestEntryInput[] = Object.keys(entries)
      .filter((entryNumber) => <number>entries[entryNumber] > 0)
      .map((entryNumber) => {
        const currentOrderEntry = this.order$?.entries?.at(Number(entryNumber));

        return {
          orderEntryNumber: Number(entryNumber),
          quantity: <number>entries[entryNumber],
          productCode: currentOrderEntry?.product?.code ?? '',
          productName: currentOrderEntry?.product?.name ?? '',
        } as CancelOrReturnRequestEntryInput;
      });

    // attachments
    const attachments: File[] = Array.prototype.slice.call(
      this.form.get('attachments')?.value
    );

    const returnRequestInput: RationalOrderReturnRequest = {
      orderCode: orderCode,
      orderCreationDate: this.order$?.created,
      returnComment: this.form.value.returnComment ?? '',
      returnRequestEntryInputs: inputs,
    };

    this.form.reset();

    this.userIdService.takeUserId().subscribe((userId) => {
      this.sendOrderReturnRequest(userId, returnRequestInput, attachments)
        .pipe(
          catchError((error) => {
            throw error;
          })
        )
        .subscribe((response) => {
          this.handleResponse(response);
        });
    });
  }

  private sendOrderReturnRequest(
    userId: string,
    returnRequestData: RationalOrderReturnRequest,
    attachments: File[]
  ): Observable<any> {
    const orderCode = returnRequestData.orderCode;

    const url = this.occEndpoints.buildUrl('returnOrder', {
      urlParams: { userId, orderCode },
    });

    const formData = new FormData();
    formData.append('returnRequestData', JSON.stringify(returnRequestData));

    attachments?.forEach((attachment) => {
      formData.append('attachments', attachment);
    });

    return this.http.post(url, formData);
  }

  private handleResponse(response: any) {
    if (response instanceof HttpErrorResponse) {
      return;
    }
    this.globalMessageService.add(
      { key: 'orderDetails.cancellationAndReturn.returnSuccess' },
      GlobalMessageType.MSG_TYPE_CONFIRMATION
    );
    this.routing.go({
      cxRoute: 'orders',
    });
  }
}

function ValidateQuantityToReturn(control: AbstractControl) {
  if (!control.value) {
    return null;
  }
  const quantity = Object.values(control.value as number).reduce(
    (acc: number, val: number) => acc + val,
    0
  );
  return quantity > 0 ? null : { cxNoSelectedItemToReturn: true };
}

export interface RationalOrderReturnRequest
  extends ReturnRequestEntryInputList {
  orderCreationDate?: Date;
  returnComment?: string;
  returnRequestEntryInputs?: RationalCancelOrReturnRequestEntryInput[];
}

export interface RationalCancelOrReturnRequestEntryInput {
  productCode?: string;
  orderEntryNumber?: number;
  quantity?: number;
}
