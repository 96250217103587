<div class="product-downloads" *ngIf="productDownloadsResponse$ | async as downloads">

    <div *ngIf="
        downloads.productDownloads == null || downloads.productDownloads?.length == 0
        else downloadsTable">
        {{ 'downloads.noDownloads' | cxTranslate }}
    </div>


    <ng-template #downloadsTable>
        <table role="table" id="product-downloads-table" class="table cx-product-downloads-table">
            <thead class="cx-product-downloads-thead-mobile">
                <tr role="row">
                    <th role="columnheader">
                        {{ 'downloads.documentTitle' | cxTranslate }}
                    </th>
                    <th role="columnheader">
                        {{ 'downloads.languages' | cxTranslate }}
                    </th>
                    <th role="columnheader">
                        {{ 'downloads.readableTypeMime' | cxTranslate }}
                    </th>
                    <th role="columnheader">
                        {{ 'downloads.fileSize' | cxTranslate }}
                    </th>
                    <th role="columnheader">
                        {{ 'downloads.download' | cxTranslate }}
                    </th>

                </tr>
            </thead>

            <tbody>
                <tr role="row" *ngFor="let download of downloads.productDownloads">

                    <td role="cell" class="cx-order-history">
                        <div class="cx-downloads-item-label">{{ 'downloads.documentTitle' | cxTranslate }}</div>
                        <div class="cx-downloads-item-value download-name">{{ download.localizedName }}</div>
                    </td>

                    <td role="cell" class="cx-order-history">
                        <ng-container *ngIf="download.languages">
                            <div class="cx-downloads-item-label"> {{ 'downloads.languages' | cxTranslate }} </div>
                            <div class="cx-downloads-item-value"> {{ download.languages.join(', ') }}</div>
                        </ng-container>
                    </td>

                    <td role="cell" class="cx-order-history">
                        <ng-container  *ngIf="download.mimeType">
                            <div class="cx-downloads-item-label"> {{ 'downloads.readableTypeMime' | cxTranslate }} </div>
                            <div class="cx-downloads-item-value"> {{ download.mimeType }}</div>
                        </ng-container>
                    </td>

                    <td role="cell" class="cx-order-history" >
                        <ng-container  *ngIf="download.fileSize">
                            <div class="cx-downloads-item-label"> {{ 'downloads.fileSize' | cxTranslate }} </div>
                            <div class="cx-downloads-item-value"> {{ (download.fileSize / 1024)| number : '1.0-0' }}KB</div>
                        </ng-container>
                    </td>

                    <td role="cell" class="cx-order-history">

                        <div class="cx-downloads-item-label"> {{ 'downloads.download' | cxTranslate }} </div>
                        <div class="cx-downloads-item-value">
                            <a class="download-link"  role="button" (click)="openDocument(download)">{{ 'downloads.download' | cxTranslate }}</a>
                        </div>
                    </td>

                </tr>
            </tbody>
        </table>
    </ng-template>

</div>
