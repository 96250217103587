import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CartActions } from '@spartacus/cart/base/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import {
  GlobalMessageType,
  LoggerService,
  normalizeHttpError,
} from '@spartacus/core';
import { RationalSavedCartService } from '../../saved-cart/rational-saved-cart.service';
import { RationalSavedCartActions } from '../../saved-cart/action/index';
import {
  SavedCartActions,
  SavedCartConnector,
} from '@spartacus/cart/saved-cart/core';
import { RationalCartConnector } from '../../saved-cart/connector/rational-cart.connector';
import { GlobalMessageService } from '@spartacus/core';

/**
 * This effect is responsible for reloading the cart after a saved cart is successfully saved.
 * @remarks
 * This effect listens to the SAVE_CART_SUCCESS action and creates a new effect that clones the saved cart.
 * The cloned saved cart is then used to reload the cart, which updates the cart with the latest saved cart data.
 */

@Injectable()
export class RationalMultiCartEffects {
  constructor(
    private actions$: Actions,
    protected activeCartService: ActiveCartFacade,
    protected rationalSavedCartService: RationalSavedCartService,
    protected savedCartConnector: SavedCartConnector,
    protected globalMessageService: GlobalMessageService,
    private rationalCartConnector: RationalCartConnector
  ) {}

  protected logger = inject(LoggerService);

  saveCarts$: Observable<
    | SavedCartActions.SaveCartFail
    | SavedCartActions.SaveCartSuccess
    | SavedCartActions.SaveCart
    | CartActions.LoadCartSuccess
    | CartActions.ClearCartState
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(RationalSavedCartActions.SAVE_SHARED_CART),
      map((action: RationalSavedCartActions.SaveCart) => action.payload),
      switchMap(({ userId, cartId, saveCartName, saveCartDescription, isSharedWithB2BUnit}) => {
        return this.rationalCartConnector
          .save(userId, cartId, saveCartName, saveCartDescription, isSharedWithB2BUnit)
          .pipe(
            switchMap((savedCart: Cart) => {
              return [
                new CartActions.ClearCartState(),
                new CartActions.LoadCartSuccess({
                  userId,
                  cartId,
                  cart: savedCart,
                }),
                new SavedCartActions.SaveCartSuccess({
                  userId,
                  cartId,
                  saveCartName,
                  saveCartDescription,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                new SavedCartActions.SaveCartFail({
                  userId,
                  cartId,
                  saveCartName,
                  saveCartDescription,
                  error: normalizeHttpError(error, this.logger),
                })
              )
            )
          );
      })
    )
  );


  restoreSavedSharedCart$: Observable<
    | SavedCartActions.RestoreSavedCartFail
    | SavedCartActions.RestoreSavedCartSuccess
    | SavedCartActions.LoadSavedCarts
    | SavedCartActions.SaveCart
    | CartActions.LoadCartSuccess
    | CartActions.SetActiveCartId
    | RationalSavedCartActions.RestoreSavedSharedCart
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(RationalSavedCartActions.RESTORE_SAVED_SHARED_CART),
      map(
        (action: RationalSavedCartActions.RestoreSavedSharedCart) =>
          action.payload
      ),
      withLatestFrom(this.activeCartService.getActive()),
      switchMap(([{ userId, cartId }, activeCart]) => {
        debugger;
        const actions: any[] = [];

        if ((activeCart?.entries ?? []).length > 0) {
          if (activeCart.code) {
            // delete current active cart (instead of restoring it)
            actions.push(
              new CartActions.DeleteCart({
                userId: userId,
                cartId: activeCart.code,
              })
            );
          }
        }

        return this.savedCartConnector.restoreSavedCart(userId, cartId).pipe(
          switchMap((savedCart: Cart) => {
            this.globalMessageService.add(
              {
                key: 'savedCartList.swapCartNoActiveCart',
                params: { cartName: cartId },
              },
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
            return [
              ...actions,
              new CartActions.LoadCartSuccess({
                userId,
                cartId,
                cart: savedCart,
                extraData: { active: true },
              }),
              new SavedCartActions.RestoreSavedCartSuccess({ userId, cartId }),
            ];
          }),
          catchError((error: HttpErrorResponse) =>
            of(
              new SavedCartActions.RestoreSavedCartFail({
                userId,
                cartId,
                error: normalizeHttpError(error, this.logger),
              })
            )
          )
        );
      })
    )
  );

  cloneSavedSharedCart$: Observable<
    | SavedCartActions.CloneSavedCartFail
    | SavedCartActions.CloneSavedCartSuccess
    | SavedCartActions.CloneSavedCart
    | SavedCartActions.RestoreSavedCart
    | SavedCartActions.LoadSavedCarts
    | RationalSavedCartActions.RestoreSavedSharedCart
    | RationalSavedCartActions.CloneSavedSharedCart
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(RationalSavedCartActions.CLONE_SAVED_SHARED_CART),
      map(
        (action: RationalSavedCartActions.CloneSavedSharedCart) =>
          action.payload
      ),
      switchMap(({ userId, cartId, saveCartName }) => {
        return this.savedCartConnector
          .cloneSavedCart(userId, cartId, saveCartName)
          .pipe(
            switchMap((_) => {
              return [
                new SavedCartActions.CloneSavedCartSuccess({
                  userId,
                  cartId,
                  saveCartName,
                }),
                new RationalSavedCartActions.RestoreSavedSharedCart({
                  userId,
                  cartId,
                }),
                new SavedCartActions.LoadSavedCarts({ userId }),
              ];
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                new SavedCartActions.CloneSavedCartFail({
                  userId,
                  cartId,
                  saveCartName,
                  error: normalizeHttpError(error, this.logger),
                })
              )
            )
          );
      })
    )
  );
}
