import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';
import { FormErrorsModule, IconModule, KeyboardFocusModule, NgSelectA11yModule } from '@spartacus/storefront';
import { AddressFormModule } from "@spartacus/user/profile/components";
import { RationalCheckoutAddressFormComponent } from './rational-checkout-address-form.component';

@NgModule({
  imports: [
    CommonModule,
    AddressFormModule,
    NgSelectA11yModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    IconModule,
    I18nModule,
    FormErrorsModule,
    KeyboardFocusModule,
  ],
  declarations: [RationalCheckoutAddressFormComponent],
  exports: [RationalCheckoutAddressFormComponent],
})
export class RationalCheckoutAddressFormModule { }
