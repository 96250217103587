export const orderDetails = {
    orderId: "Order #",
    orderNumber: "Webbutikens ordernummer",
    replenishmentId: "Påfyllning #",
    purchaseOrderId: "Inköpsorder #",
    purchaseOrderNumber: "Ditt inköpsordernummer",
    emptyPurchaseOrderId: "Ingen",
    none: "Ingen {{value}}",
    placed: "Placerad",
    placedBy: "Placerad av",
    unit: "Enhet",
    costCenter: "Kostnadsställe",
    costCenterAndUnit: 'Kostnadsställe / Enhet',
    costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
    methodOfPayment: "Betalningsmetod",
    payByAccount: "Betala via konto",
    paidByCreditCard: "(betalas med kreditkort)",
    status: "Status på konto",
    active: "Aktiv",
    shippedOn: "Skickad på",
    shippingMethod: "Leveransmetod",
    placedOn: "Placerad på",
    startOn: "Börja på",
    nextOrderDate: "Datum för nästa order",
    frequency: "Frekvens",
    cancelled: "Avbeställd",
    deliveryStatus_IN_TRANSIT: "I transit",
    deliveryStatus_READY_FOR_PICKUP: "Redo för upphämtning",
    deliveryStatus_READY_FOR_SHIPPING: "Redo för leverans",
    deliveryStatus_WAITING: "Väntar på",
    deliveryStatus_DELIVERING: "Levererar",
    deliveryStatus_PICKPACK: "Förbereder för leverans",
    deliveryStatus_PICKUP_COMPLETE: "Upphämtning klar",
    deliveryStatus_DELIVERY_COMPLETED: "Leverans slutförd",
    deliveryStatus_PAYMENT_NOT_CAPTURED: "Betalning utfärdad",
    deliveryStatus_READY: "I process",
    deliveryStatus_DELIVERY_REJECTED: "Leverans avvisad",
    deliveryStatus_SHIPPED: "Avsänd",
    deliveryStatus_TAX_NOT_COMMITTED: "Skattefråga",
    deliveryStatus_CANCELLED: "Annullerad",
    statusDisplay_cancelled: "Annullerad",
    statusDisplay_cancelling: "Avbeställning Väntar",
    statusDisplay_confirmed: "Order bekräftad",
    statusDisplay_completed: "Order levererad",
    statusDisplay_created: "Order skapad",
    statusDisplay_error: "Avvaktande",
    statusDisplay_Error: "Avvaktar",
    statusDisplay_processing: "Order skapad",
    statusDisplay_open: "Order skapad",
    statusDisplay_pending: {
        approval: "Avvaktande godkännande",
        merchant: {
            Approval: "Väntar på godkännande av handlare"
        }
    },
    statusDisplay_approved: "Godkänd",
    statusDisplay_rejected: "Avvisad",
    statusDisplay_merchant: {
        Approved: "Handlare godkänd",
        Rejected: "Handlare avvisad",
    },
    statusDisplay_assigned: {
        admin: "Tilldelad till administratör"
    },
    consignmentTracking: {
      action: "Spåra paket",
      dialog: {
        header: "Spårningsinformation",
        shipped: "Skickat",
        estimate: "Beräknad leverans",
        carrier: "Leveransservice",
        trackingId: "Spårningsnummer",
        noTracking: "Paketet har inte skickats från lagret. Spårningsinformationen kommer att vara tillgänglig när paketet har skickats.",
        loadingHeader: "Spårning av sändning"
      }
    },
    cancellationAndReturn: {
      returnAction: "Begär en retur",
      cancelAction: "Avbeställ artiklar",
      item: "Artikel",
      itemPrice: "Pris för artikel",
      quantity: "Max antal",
      returnQty: "Kvantitet att returnera",
      cancelQty: "Kvantitet att avbeställa",
      setAll: "Ställ in alla kvantiteter till max",
      totalPrice: "Totalt antal",
      submit: "Skicka begäran",
      returnSuccess: "Din returbegäran ({{rma}}) har skickats.",
      cancelSuccess: "Din avbokningsbegäran skickades (originalorder {{orderCode}} kommer att uppdateras)"
    },
    cancelReplenishment: {
      title: "Avbryt påfyllning",
      description: "Avboka en framtida påfyllningsorder?",
      accept: "Ja",
      reject: "Nej",
      cancelSuccess: "Påfyllningsorder #{{replenishmentOrderCode}} har framgångsrikt annullerats."
    }
  };
  
export const orderHistory = {
    orderHistory: "Orderhistorik",
    orderId: "Beställning #",
    placedBy: "Kontaktperson",
    emptyPurchaseOrderId: "Ingen",
    date: "Datum",
    status: "Status",
    total: "Totalt",
    noOrders: "Vi har inga orderposter för det här kontot.",
    noReplenishmentOrders: "Vi har inga orderposter för påfyllning för detta konto.",
    startShopping: "Börja handla",
    sortBy: "Sortera efter",
    replenishmentOrderHistory: "Orderhistorik för påfyllning",
    replenishmentOrderId: "Påfyllnad #",
    purchaseOrderNumber: "PO #",
    startOn: "Starta på",
    frequency: "Frekvens",
    nextOrderDate: "Datum för nästa order",
    cancel: "Avbeställ order",
    cancelled: "Avbeställd",
    replenishmentHistory: "Påfyllnadshistorik",
    notFound: "Inga beställningar hittade"
  };
  
export const wishlist = {
    empty: "Inga produkter i din önskelista ännu"
  };
  
export const myAccount = {
    orderDetails,
    orderHistory,
    wishlist
};
