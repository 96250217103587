import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule, provideDefaultConfig } from '@spartacus/core';
import {ConfiguratorOverviewBundleAttributeModule } from '@spartacus/product-configurator/rulebased';
import { RationalConfiguratorOverviewFormComponent } from './rational-configurator-overview-form.component';
import { CommonModule } from '@angular/common';
import { RationalConfiguratorOverviewAttributeModule } from '../../overview-attribute/rational-overview-attribute/rational-configurator-overview-attribute.module';

@NgModule({
    imports: [
      CommonModule,
      RationalConfiguratorOverviewAttributeModule,
      ConfiguratorOverviewBundleAttributeModule,
      I18nModule,
      ConfigModule.withConfig({
        cmsComponents: {
            ConfiguratorOverviewForm: {
                component: RationalConfiguratorOverviewFormComponent,
              },
        }
    } as CmsConfig)
    ],
    providers: [
      provideDefaultConfig(<CmsConfig>{
        cmsComponents: {
          ConfiguratorOverviewForm: {
            component: RationalConfiguratorOverviewFormComponent,
          },
        },
      }),
    ],
    declarations: [RationalConfiguratorOverviewFormComponent],
    exports: [RationalConfiguratorOverviewFormComponent],
  })
export class RationalConfiguratorOverviewFormModule{ }