import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'custom-header-link-flyout',
  templateUrl: './header-link-flyout.component.html',
  styleUrls: ['./header-link-flyout.component.scss']
})
export class HeaderLinkFlyoutComponent {

  @Input() linkNode: any;

}
