import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutB2BStepsSetGuard } from '@spartacus/checkout/b2b/components';
import { CheckoutCostCenterFacade, CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import {
    CheckoutDeliveryAddressFacade,
    CheckoutDeliveryModesFacade,
    CheckoutPaymentFacade,
    CheckoutStep
} from '@spartacus/checkout/base/root';
import { GlobalMessageService, GlobalMessageType, RoutingConfigService } from '@spartacus/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RationalOrderSummaryService } from '../cart/base/order-summary/rational-order-summary.service';

@Injectable({
    providedIn: 'root',
})
export class RationalCheckoutB2BStepsSetGuard extends CheckoutB2BStepsSetGuard implements CanActivate {

    constructor(
        protected override checkoutStepService: CheckoutStepService,
        protected override routingConfigService: RoutingConfigService,
        protected override checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
        protected override checkoutPaymentFacade: CheckoutPaymentFacade,
        protected override checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
        protected override router: Router,
        protected override checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
        protected override checkoutCostCenterFacade: CheckoutCostCenterFacade,
        protected override activeCartFacade: ActiveCartFacade,
        private globalMessageService: GlobalMessageService,
        private rationalOrderSummaryService: RationalOrderSummaryService,
    ) {
        super(
            checkoutStepService,
            routingConfigService,
            checkoutDeliveryAddressFacade,
            checkoutPaymentFacade,
            checkoutDeliveryModesFacade,
            router,
            checkoutPaymentTypeFacade,
            checkoutCostCenterFacade,
            activeCartFacade
        );
    }


    protected override isDeliveryAddressAndCostCenterSet(
        step: CheckoutStep,
        isAccountPayment: boolean
    ): Observable<boolean | UrlTree> {
        return combineLatest([
            this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
                filter((state) => !state.loading),
                map((state) => state.data)
            )
        ]).pipe(
            map(([deliveryAddress]) => {
                if (deliveryAddress && Object.keys(deliveryAddress).length) {
                    return true;
                } else {
                    return this.getUrl(step.routeName);
                }
            })
        );
    }


    // override canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    //     return combineLatest([this.checkDeliveryDateIsValid(), super.canActivate(route)]).pipe(
    //         map(([deliveryDateValidity, canActivate]) => {
    //             return deliveryDateValidity && canActivate;
    //         })
    //     );
    // }
    //
    // checkDeliveryDateIsValid(): Observable<boolean | UrlTree> {
    //     return this.rationalOrderSummaryService.checkDeliveryDateIsValid().pipe(
    //         map((isValid) => {
    //             if (!isValid) {
    //                 this.globalMessageService.add({ key: 'cartDetails.requestedRetrievalDateInvalid' }, GlobalMessageType.MSG_TYPE_ERROR);
    //             }
    //             return isValid;
    //         }),
    //
    //     )
    // }
}
