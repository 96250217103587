import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { SavedCartDetailsItemsComponent } from '@spartacus/cart/saved-cart/components';
import { SavedCartOutlets } from '../../savedcart-outlets.module';

@Component({
  selector: 'rational-saved-cart-details-items',
  templateUrl: './rational-saved-cart-detials-items.component.html',
  styleUrls: ['./rational-saved-cart-detials-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RationalSavedCartDetialsItemsComponent extends SavedCartDetailsItemsComponent {
  readonly SavedCartOutlets = SavedCartOutlets;
}
