export const productDetails = {
    id: "Číslo článku",
    quantity: "Množství",
    productDetails: "Podrobnosti o produktu",
    specification: "Technické vlastnosti",
    reviews: "Recenze",
    shipping: "Přeprava",
    share: "Sdílet",
    showReviews: "Zobrazit recenze",
    noReviews: "",
    productPrice: "Cena produktu",
    priceLoading: "Cena produktu je vypočítána",
    productRelations: {
        productRelationsHeader: "Kompatibilní příslušenství"
    },
    disclaimer: {
        title: 'Poznámka k použití obrázků:',
        text: 'Obrázky uvedené na této webové stránce slouží pouze pro ilustrativní účely a nejedná se o závaznou reprezentaci skutečných vlastností produktu nebo rozsahu dodávky. Přesné specifikace, vlastnosti a rozsah dodávky produktů lze nalézt v příslušném textovém popisu produktu.'
    }
};

export const productList = {
    filterBy: {
        label: "Filtrovat podle",
        action: "Filtrovat podle"
    },
    appliedFilter: "Použitý filtr:",
    showLess: "Zobrazit méně...",
    showMore: "Zobrazit více...",
    sortBy: "Seřadit podle",
    backToTopBtn: "ZPĚT NA VRCHOL",
    showMoreBtn: "ZOBRAZIT VÍCE",
    productSearchPagination: "Stránkování při vyhledávání produktů"
};

export const productFacetNavigation = {
    filterBy: {
        label: "Filtrovat podle",
        action: "Filtrovat podle"
    },
    appliedFilter: "Použitý filtr:",
    showLess: "Zobrazit méně...",
    showMore: "Zobrazit více...",
    sortBy: "Seřadit podle"
};

export const productSummary = {
    id: "Číslo článku",
    showReviews: "Zobrazit recenze",
    share: "Sdílet",
    newItemPrice: "Cena nového zboží",
    discount: "Zobrazené ceny zahrnují vaši individuální slevu ve výši {{discount}}.",
    productSubstitutionAndProductExists: "Produkt {{product1}} je nahrazen produktem {{product2}}, a proto jste přesměrováni na produkt {{product2}}.",
    productSubstitutionAndProductDoesntExist: "Produkt {{product1}} je nahrazen produktem {{product2}}, ale {{product2}} není momentálně k dispozici."
};

export const productReview = {
    overallRating: "Celkové hodnocení",
    reviewTitle: "Název recenze",
    writeYourComments: "Napište svůj komentář",
    rating: "Hodnocení",
    reviewerName: "Jméno recenzenta (nepovinné)",
    writeReview: "Napsat recenzi",
    more: "Zobrazit další recenze",
    less: "Zobrazit méně recenzí",
    thankYouForReview: "Děkujeme za recenzi! Upozorňujeme, že recenze mohou vyžadovat přezkoumání předtím, než se zde objeví."
};

export const addToCart = {
    itemsAddedToYourCart: "Položka(y) přidaná(é) do košíku",
    itemsIncrementedInYourCart: "Tato položka již byla vložena do vašeho košíku. Množství bylo aktualizováno.",
    items: "položky",
    updatingCart: "Aktualizace košíku...",
    addToCart: "Přidat do košíku",
    viewCart: "Zobrazit košík",
    proceedToCheckout: "Přejít k pokladně",
    quantity: "Množství",
    outOfStock: "Není skladem",
    inStock: "Skladem",
    selectStyleAndSize: "Vyberte styl a velikost pro kontrolu skladových zásob",
    removeFromCart: "Odebrat produkt z košíku",
    closeModal: "Zavřít Modal"
};

export const TabPanelContainer = {
    tabs: {
        ProductDetailsTabComponent: "Podrobnosti o produktu",
        ProductSpecsTabComponent: "Technické vlastnosti",
        ProductReviewsTabComponent: "Recenze",
        deliveryTab: "Přeprava",
        ProductDownloadsTabComponent: "Ke stažení"
    }
};

export const addToWishList = {
    add: "Přidat do seznamu přání",
    remove: "Odebrat ze seznamu přání",
    anonymous: "Přihlaste se pro přidání do seznamu přání"
};

export const itemCounter = {
    removeOne: "Odebrat jeden",
    addOneMore: "Přidat jeden další",
    quantity: "Množství"
};

export const productView = {
    gridView: "Vyberte pro změnu na zobrazení mřížky",
    listView: "Vyberte pro změnu na zobrazení seznamu"
};

export const standardDelivery = {
    standardDeliveryRange: "Připraveno k odeslání s {{from}} do {{to}} dnů"
};

export const downloads = {
    downloads: {
        noDownloads: "Pro tento produkt nejsou ke stažení žádné položky",
        documentTitle: "Název dokumentu",
        toolbox: "Více v sadě nástrojů",
        allDocuments: "Všechny",
        languagesDropdown: "Jazyk:",
        originalFileName: "Název souboru",
        languages: "Jazyky",
        readableTypeMime: "Typ Mime",
        fileSize: "Velikost souboru",
        download: "Stáhnout"
    }
};

export const productReferences = {
    productReferences: {
        installationvariants: "Varianty nastavení",
        feedingsystems: "Načítací systémy",
        groomingaccessoires: "Výrobky pro péči",
        cookaccessoire: "Příslušenství pro vaření",
        cookaccessoirespecial: "Speciální příslušenství",
        extractorhood: "Odsavače par",
        deviceinstallation: "Instalace jednotek"
    }
};

export const product = {
    productDetails,
    productList,
    productFacetNavigation,
    productSummary,
    productReview,
    addToCart,
    TabPanelContainer,
    addToWishList,
    itemCounter,
    productView,
};
