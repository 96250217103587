import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  AuthMultisiteIsolationService,
  AuthRedirectService,
  AuthService,
  AuthStorageService,
  OCC_USER_ID_CURRENT,
  RoutingService,
  StateWithClientAuth,
  UserIdService,
} from '@spartacus/core';
import { RationalOAuthLibWrapperService } from './oauth-lib-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class RationalAuthService extends AuthService {
  constructor(
    protected override store: Store<StateWithClientAuth>,
    protected override userIdService: UserIdService,
    protected override oAuthLibWrapperService: RationalOAuthLibWrapperService,
    protected override authStorageService: AuthStorageService,
    protected override authRedirectService: AuthRedirectService,
    protected override routingService: RoutingService,
    protected override authMultisiteIsolationService?: AuthMultisiteIsolationService
  ) {
    super(
      store,
      userIdService,
      oAuthLibWrapperService,
      authStorageService,
      authRedirectService,
      routingService,
      authMultisiteIsolationService
    );
  }


  // override this to be sure it uses rational id auth

  override async loginWithCredentials(
    userId: string,
    password: string
  ): Promise<void> {
    const uid = userId;

    try {
      this.oAuthLibWrapperService.initLoginFlow();

      // OCC specific user id handling. Customize when implementing different backend
      this.userIdService.setUserId(OCC_USER_ID_CURRENT);

      this.store.dispatch(new AuthActions.Login());
    } catch {}
  }

  loginRationalId() {
    //console.log("reached rational id login")
    this.oAuthLibWrapperService.initLoginFlow();
    this.userIdService.setUserId(OCC_USER_ID_CURRENT);
    this.store.dispatch(new AuthActions.Login());
  }
}
