import { TranslationChunksConfig } from '@spartacus/core';
import { RationalTranslations } from './RationalTranslations';


const rationalChunks : TranslationChunksConfig =  {
  importExport: []
};

export const RationalTranslationConfig = {
  resources: RationalTranslations,
  chunks: rationalChunks
};
