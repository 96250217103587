export const productDetails = {
  id: 'Article Number',
  quantity: 'Qty',
  productDetails: 'Product Details',
  specification: 'Technical Features',
  reviews: 'Reviews',
  shipping: 'Shipping',
  share: 'Share',
  showReviews: 'Show reviews',
  noReviews: '',
  productPrice: 'Product price',
  priceLoading:'Product price is calculated',
  productRelations :{
    productRelationsHeader: 'Compatible Accessories',
  },
  disclaimer: {
    title: 'Note on the use of images:',
    text: 'The images shown on this website are for illustrative purposes only and are not a binding representation of the actual product features or scope of delivery. The exact specifications, properties and scope of delivery of the products can be found in the respective product description in text form.'
  }
}

export const productList = {
  filterBy: {
    label: 'Filter by',
    action: 'Filter by'
  },
  appliedFilter: 'Applied filter:',
  showLess: 'Show less...',
  showMore: 'Show more...',
  sortBy: 'Sort by',
  backToTopBtn: 'BACK TO TOP',
  showMoreBtn: 'SHOW MORE',
  productSearchPagination: 'Product search pagination'
}

export const productFacetNavigation = {
  filterBy: {
    label: 'Filter by',
    action: 'Filter by'
  },
  appliedFilter: 'Applied filter:',
  showLess: 'Show less...',
  showMore: 'Show more...',
  sortBy: 'Sort by'
}

export const productSummary = {
  id: 'Article Number',
  showReviews: 'Show reviews',
  share: 'Share',
  newItemPrice: 'New item price',
  discount: 'The displayed prices include your individual discount in the amount of {{discount}}.',
  productSubstitutionAndProductExists: 'Product {{product1}} is substituted with product {{product2}}, thus you are redirected to product {{product2}}',
  productSubstitutionAndProductDoesntExist: 'Product {{product1}} is substituted with product {{product2}}, but {{product2}} is not currently available'
}

export const productReview = {
  overallRating: 'Overall Rating',
  reviewTitle: 'Review Title',
  writeYourComments: 'Write your comments',
  rating: 'Rating',
  reviewerName: 'Reviewer name (optional)',
  writeReview: 'Write a Review',
  more: 'Show More Reviews',
  less: 'Show Less Reviews',
  thankYouForReview: 'Thank you for the review! Note that reviews may require review before appearing here.'
}

export const addToCart = {
  itemsAddedToYourCart: 'Item(s) added to your cart',
  itemsIncrementedInYourCart: 'This item was already in your cart. The quantity was updated.',
  items: 'items',
  updatingCart: 'Updating cart...',
  addToCart: 'Add to Cart',
  viewCart: 'View Cart',
  proceedToCheckout: 'Proceed to Checkout',
  quantity: 'Qty',
  outOfStock: 'Out of stock',
  inStock: 'In stock',
  selectStyleAndSize: 'Select style and size to check stock',
  removeFromCart: 'Remove Product from Cart',
  closeModal: 'Close Modal'
}

export const TabPanelContainer = {
  tabs: {
    ProductDetailsTabComponent: 'Product Details',
    ProductSpecsTabComponent: 'Technical Features',
    ProductReviewsTabComponent: 'Reviews',
    deliveryTab: 'Shipping',
    ProductDownloadsTabComponent: 'Downloads'
  }
}

export const addToWishList = {
  add: 'Add to Wish List',
  remove: 'Remove from Wish List',
  anonymous: 'Sign in to add to wish list'
}

export const itemCounter = {
  removeOne: 'Remove one',
  addOneMore: 'Add one more',
  quantity: 'Quantity'
}

export const productView = {
  gridView: 'Select to change to Grid View',
  listView: 'Select to change to List View'
}

export const standardDelivery = {
  standardDelivery: {
      standardDeliveryRange: 'Ready to ship within {{from}} to {{to}} days'
  }
}

export const downloads = {
  downloads: {
    noDownloads: 'No downloads for this product',
    documentTitle: 'Document Title',
    originalFileName: 'File Name',
    languages: 'Languages',
    readableTypeMime: 'Mime Type',
    fileSize: 'File Size',
    download: 'Download',
    toolbox: 'More in Toolbox',
    allDocuments: 'All',
    languagesDropdown: 'Language:'
  }
}

export const productReferences = {
  productReferences: {
    installationvariants: 'Set-up variations',
    feedingsystems: 'Loading Systems',
    groomingaccessoires: 'Care Products',
    cookaccessoire: 'Cooking Accessories',
    cookaccessoirespecial: 'Special accessories',
    extractorhood: 'Hoods',
    deviceinstallation: 'Unit Installation'
  }
}

export const productImageZoom = {
  productImageZoomTrigger: {
    expand: 'Expand image',
  },
  productImageZoomDialog: {
    close: 'Close',
  },
}

export const product = {
  productDetails,
  productList,
  productFacetNavigation,
  productSummary,
  productReview,
  addToCart,
  TabPanelContainer,
  addToWishList,
  itemCounter,
  productView,
}
