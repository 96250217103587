export const pageMetaResolver = {
  category: {
    title: '{{count}} resultat för {{query}}',
    title_plural: '{{count}} resultat för {{query}}',
  },
  checkout: {
    title: 'Kassan',
  },
  search: {
    title: '{{count}} resultat för \'{{query}}\'',
    title_plural: '{{count}} resultat för \'{{query}}\'',
    findProductTitle: '{{count}} resultat för kupong \'{{coupon}}\'',
    findProductTitle_plural: '{{count}} resultat för kupong \'{{coupon}}\'',
    default_title: 'Alla produkter',
  },
  product: {
    description: '{{description}}',
    heading: '{{heading}}',
    title: '{{title}}',
  },
};

export const spinner = {
  loading: 'Laddar...',
};

export const navigation = {
  shopAll: 'Handla allt {{ navNode}} >',
  listPrice: {
    enableListPrice: 'Aktivera listpris',
    listPriceIsEnabled: 'Listpris är aktiverat',
    listPriceIsDisabled: 'Listpris är inaktiverat',
  },
};

export const searchBox = {
  placeholder: 'Sök här...',
  ariaLabelInput: 'Sök här...',
  ariaLabelSuggestions: 'Skrivningsförslag',
  ariaLabelProducts: 'produktresultat',
  initialDescription:
    'När autofullständiga resultat är tillgängliga använder du upp- och nedpilarna för att granska och enter för att välja. Användare av pekdatorer kan utforska med pekning eller svepgester.',
  suggestionsResult: '{{count }} förslag och',
  suggestionsResult_plural: '{{count }} förslag och',
  productsResult: '{{count }} produkt är tillgänglig.',
  productsResult_plural: '{{count }} produkter finns tillgängliga.',
  resetLabel: 'Återställ sökning',
  help: {
    insufficientChars: 'Vänligen skriv fler tecken',
    noMatch: 'Vi kunde inte hitta några resultat',
    exactMatch: '{{ term }}',
    empty: 'Fråga oss vad som helst',
  },
};

export const sorting = {
  date: 'Datum',
  orderNumber: 'Ordernummer',
  rma: 'Returnummer',
  replenishmentNumber: 'Påfyllningsnummer',
  nextOrderDate: 'Nästa beställningsdatum',
};

export const httpHandlers = {
  badGateway: 'Ett serverfel inträffade. Vänligen försök igen senare.',
  badRequestPleaseLoginAgain: '{{ errorMessage }}. Vänligen logga in igen.',
  badRequestOldPasswordIncorrect: 'Det gamla lösenordet är felaktigt.',
  badRequestGuestDuplicateEmail:
    '{{ errorMessage }} E-postadressen finns redan. Checka ut med en annan e-postadress för att registrera dig med ett gästkonto.',
  conflict: 'Finns redan.',
  forbidden:
    'Du är inte behörig att utföra den här åtgärden. Kontakta din administratör om du tror att det här är ett misstag.',
  gatewayTimeout: 'Servern svarade inte, vänligen försök igen senare.',
  internalServerError:
    'Ett internt serverfel uppstod. Försök igen senare.',
  sessionExpired: 'Din session har löpt ut. Vänligen logga in igen.',
  unknownError: 'Ett okänt fel inträffade',
  unauthorized: {
    common: 'Ett okänt auktoriseringsfel inträffade',
    invalid_client: 'Dåliga klientuppgifter',
  },
  validationErrors: {
    missing: {
      card_cardType:
        'Det valda kreditkortet stöds inte. Vänligen välj ett annat.',
      card_accountNumber:
        'Det angivna kreditkortsnumret är inte giltigt.',
      card_cvNumber: 'Den angivna säkerhetskoden är inte giltig.',
      card_expirationMonth:
        'Det angivna utgångsdatumet för kreditkortet är inte giltigt.',
      card_expirationYear:
        'Det angivna utgångsdatumet för kreditkortet är inte giltigt.',
      billTo_firstName: 'Det angivna förnamnet är inte giltigt.',
      billTo_lastName: 'Det angivna efternamnet är inte giltigt.',
      billTo_street1: 'Den angivna adressen är inte giltig.',
      billTo_street2: 'Den angivna adressen är inte giltig.',
      billTo_city: 'Den angivna staden är inte giltig för det här kreditkortet.',
      billTo_state: 'Den angivna staten/provinsen är inte giltig för detta kreditkort.',
      billTo_country: 'Det angivna landet/regionen är inte giltigt för detta kreditkort.',
      billTo_postalCode: 'Postnumret är inte giltigt för det här kreditkortet.',
      country: {
        isocode: 'Saknar land',
      },
    },
    invalid: {
      card_expirationMonth:
        'Det angivna utgångsdatumet för kreditkortet är inte giltigt.',
      firstName: 'Det angivna förnamnet är inte giltigt.',
      lastName: 'Det angivna efternamnet är inte giltigt.',
      password: 'Det angivna lösenordet är inte giltigt.',
      uid: 'UID är inte giltigt.',
      code: 'Koden är inte giltig.',
      email: 'E-postadressen är inte giltig.',
    },
  },
  cartNotFound: 'Kundvagnen hittades inte.',
  invalidCodeProvided: 'Ogiltig kod angiven.',
};

export const miniCart = {
  item: '{Antal artiklar som för närvarande finns i din kundvagn',
  item_plural: '{Antal artiklar som för närvarande finns i din kundvagn',
  total: '{{total}}',
  count: '{{count}}',
};

export const skipLink = {
  skipTo: 'Hoppa till',
  labels: {
    header: 'Sidhuvud',
    main: 'Huvudinnehåll',
    footer: 'Sidfot',
    productFacets: 'Produktaspekter',
    productList: 'Produktlista',
  },
};

export const formErrors = {
  globalMessage: 'Det formulär du försöker skicka in innehåller fel.',
  required: 'Detta fält är obligatoriskt',
  cxInvalidEmail: 'Detta är inte ett giltigt e-postformat',
  cxInvalidPassword: 'Detta är inte ett giltigt lösenordsformat',
  cxPasswordsMustMatch: 'Lösenordsfälten måste matcha',
  cxEmailsMustMatch: 'E-postfält måste matcha',
  cxStarRatingEmpty: 'Fältet för betyg är obligatoriskt',
  cxNoSelectedItemToCancel: 'Välj minst ett objekt',
  cxNegativeAmount: 'Beloppet måste vara lika med eller större än noll',
  cxContainsSpecialCharacters: 'Fältet får inte innehålla specialtecken',
  date: {
    required: 'Detta fält är obligatoriskt',
    min: 'Datumet får inte vara före {{min}}',
    max: 'Datumet kan inte vara efter {{max}}',
    pattern: 'Använd datumformat ååååå-mm-dd',
    invalid: 'Använd ett giltigt datum',
  },
  file: {
    invalidQunatity:
      'Ogiltig kvantitet för produkt i rader: {{ index }}',
    missingArticleNumber:
      'Saknar artikelnummer i rader: {{ index }}',
    importCartReopenDialogueText:
      'Stäng dialogrutan för import av kundvagn, uppdatera filen och försök ladda upp på nytt.',
  },
};

export const errorHandlers = {
  scriptFailedToLoad: 'Skriptet kunde inte laddas.',
  refreshThePage: 'Uppdatera sidan.',
};

export const common = {
  pageMetaResolver,
  spinner,
  navigation,
  searchBox,
  sorting,
  httpHandlers,
  miniCart,
  skipLink,
  formErrors,
  errorHandlers,
  common: {
    cancel: 'Avbryt',
    delete: 'Ta bort',
    remove: 'Ta bort',
    edit: 'Redigera',
    restore: 'Återställ',
    back: 'Tillbaka',
    submit: 'Skicka in',
    continue: 'Fortsätt',
    save: 'Spara',
    done: 'Klar',
    home: 'Hem',
    noData: 'Inga data.',
    confirm: 'Bekräfta',
    more: 'mer',
    close: 'Stäng',
    loading: 'Laddar',
    menu: 'Meny',
    reset: 'Återställ',
    search: 'Sök i',
    add: 'Lägg till',
    userGreeting: 'Välkommen till',
    privacySettings: 'Inställningar för sekretess',
  },
};
