<form [formGroup]="filterForm">
  <div class="row cx-order-history-filters cx-filter-nav"
    [attr.aria-label]="'unitLevelOrderHistory.filterBy' | cxTranslate">
    <div class="col-md-4">
      <div>
        <label class="cx-order-history-filter-label">{{"orderHistory.filters.orderNumber" | cxTranslate}}</label>
      </div>
      <div>
        <input formControlName="orderNumberFilter" class="form-control cx-order-history-filter-value"
          [attr.aria-label]="'orderHistory.filters.orderNumber' | cxTranslate"
          (keyup.enter) = "emitFilterEvent()"
          placeholder="{{ 'orderHistory.filters.orderNumberPlaceholder' | cxTranslate }}" />
      </div>
    </div>

    <div class="col-md-4">
      <div>
        <label class="cx-order-history-filter-label">{{"orderHistory.filters.PONumber" | cxTranslate}}</label>
      </div>
      <div>
        <input formControlName="poNumberFilter" class="form-control cx-order-history-filter-value "
          [attr.aria-label]="'orderHistory.filters.PONumber' | cxTranslate"
          (keyup.enter) = "emitFilterEvent()"
          placeholder="{{ 'orderHistory.filters.PONumberPlaceholder' | cxTranslate }}" />
      </div>
    </div>
    <div class="col-md-4">
      <div>
        <label class="cx-order-history-filter-label">{{"orderHistory.filters.dateRange" | cxTranslate}}</label>
      </div>
      <div>
        <div class="cx-order-history-filter-value date-range-select">
          <ng-select [searchable]="false" [clearable]="false"
            placeholder="{{ 'orderHistory.filters.dateRange' | cxTranslate }}" [(ngModel)]="dateRangeFilter"
            formControlName="dateRangeFilter">
            <ng-option *ngFor="let dateRange of dateRangeOptions" [value]="dateRange">{{
              "orderHistory.filters.dateRanges." + dateRange | cxTranslate
              }}</ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="row cx-filters-actions justify-content-end">
    <div class="col-xs-12 col-md-4 offset-col-4 cx-apply-filters-btn-container">
      <button class="btn btn-block btn-action" type="button" (click)="emitFilterEvent()">
        {{ "orderHistory.filters.applyFilters" | cxTranslate }}
      </button>
    </div>

    <div class="col-xs-12 col-md-4 cx-clear-filters-btn-container">
      <button class="btn btn-block btn-action" type="button" (click)="clearAll()">
        {{ "orderHistory.filters.clearFilters" | cxTranslate }}
      </button>
    </div>
  </div>

</form>
