export const orderDetails = {
    statusDisplay_open: 'Ännu inte bearbetad',
    statusDisplay_processing: 'Delvis bearbetad',
    statusDisplay_completed: 'Fullständigt bearbetad',
    purchaseOrderNumber: 'Ditt inköpsordernummer',
    orderNumber: 'Webbutikens ordernummer',
    methodOfPayment: 'Betalningsmetod',
    shippingMethod: 'Leveransmetod',
    status: "Status på konto",
    placedOn: 'Placerad på',
    moreInformation: 'Mer information',
    orderDocuments: 'Beställningsdokument',
    billing: 'Fakturering',
    invoiceNumber: 'Fakturans nummer',
    invoiceDate: 'Datum på fakturan',
    netAmount: 'Nettobelopp',
    noBillingInvoices: 'Ingen faktureringsinformation tillgänglig ännu.',
    noOrderDocuments: 'Inga orderdokument tillgängliga ännu.',
    noInvoiceDocuments: 'Inga faktureringsdokument tillgängliga ännu.',
    noShipmentDocuments: 'Inga leveransdokument tillgängliga ännu.',
    shipping: {
      shipping: 'Frakt',
      shippingId: 'Id för leverans',
      deliveryDate: 'Avsändnings-/hämtningsdatum',
      quantity: 'Kvantitet',
      tracking: 'Spårning',
      noShippingInfo: 'Ingen leveransinformation tillgänglig ännu.',
      noTrackingInfo: 'Ingen spårningsinformation tillgänglig ännu.'
    },
    orderId: "Order #",
    replenishmentId: "Påfyllning #",
    purchaseOrderId: "Inköpsorder #",
    emptyPurchaseOrderId: "Ingen",
    none: "Ingen {{value}}",
    placed: "Placerad",
    placedBy: "Placerad av",
    unit: "Enhet",
    costCenter: "Kostnadsställe",
    costCenterAndUnit: 'Kostnadsställe / Enhet',
    costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
    payByAccount: "Betala via konto",
    paidByCreditCard: "(betalas med kreditkort)",
    active: "Aktiv",
    shippedOn: "Skickad på",
    startOn: "Börja på",
    nextOrderDate: "Datum för nästa order",
    frequency: "Frekvens",
    cancelled: "Avbeställd",
    deliveryStatus_IN_TRANSIT: "I transit",
    deliveryStatus_READY_FOR_PICKUP: "Redo för upphämtning",
    deliveryStatus_READY_FOR_SHIPPING: "Redo för leverans",
    deliveryStatus_WAITING: "Väntar på",
    deliveryStatus_DELIVERING: "Levererar",
    deliveryStatus_PICKPACK: "Förbereder för leverans",
    deliveryStatus_PICKUP_COMPLETE: "Upphämtning klar",
    deliveryStatus_DELIVERY_COMPLETED: "Leverans slutförd",
    deliveryStatus_PAYMENT_NOT_CAPTURED: "Betalning utfärdad",
    deliveryStatus_READY: "I process",
    deliveryStatus_DELIVERY_REJECTED: "Leverans avvisad",
    deliveryStatus_SHIPPED: "Avsänd",
    deliveryStatus_TAX_NOT_COMMITTED: "Skattefråga",
    deliveryStatus_CANCELLED: "Annullerad",
    statusDisplay_cancelled: "Annullerad",
    statusDisplay_cancelling: "Avbeställning Väntar",
    statusDisplay_confirmed: "Order bekräftad",
    statusDisplay_created: "Order skapad",
    statusDisplay_error: "Avvaktande",
    statusDisplay_Error: "Avvaktar",
    statusDisplay_pending: {
        approval: "Avvaktande godkännande",
        merchant: {
            Approval: "Väntar på godkännande av handlare"
        }
    },
    statusDisplay_approved: "Godkänd",
    statusDisplay_rejected: "Avvisad",
    statusDisplay_merchant: {
        Approved: "Handlare godkänd",
        Rejected: "Handlare avvisad",
    },
    statusDisplay_assigned: {
        admin: "Tilldelad till administratör"
    },
    consignmentTracking: {
      action: "Spåra paket",
      dialog: {
        header: "Spårningsinformation",
        shipped: "Skickat",
        estimate: "Beräknad leverans",
        carrier: "Leveransservice",
        trackingId: "Spårningsnummer",
        noTracking: "Paketet har inte skickats från lagret. Spårningsinformationen kommer att vara tillgänglig när paketet har skickats.",
        loadingHeader: "Spårning av sändning"
      }
    },
    cancellationAndReturn: {
      returnAction: "Begär en retur",
      cancelAction: "Avbeställ artiklar",
      item: "Artikel",
      itemPrice: "Pris för artikel",
      quantity: "Max antal",
      returnQty: "Kvantitet att returnera",
      cancelQty: "Kvantitet att avbeställa",
      setAll: "Ställ in alla kvantiteter till max",
      totalPrice: "Totalt antal",
      submit: "Skicka begäran",
      returnSuccess: "Din returbegäran ({{rma}}) har skickats.",
      cancelSuccess: "Din avbokningsbegäran skickades (originalorder {{orderCode}} kommer att uppdateras)"
    },
    cancelReplenishment: {
      title: "Avbryt påfyllning",
      description: "Avboka en framtida påfyllningsorder?",
      accept: "Ja",
      reject: "Nej",
      cancelSuccess: "Påfyllningsorder #{{replenishmentOrderCode}} har framgångsrikt annullerats."
    }
  };
  
  export const orderHistory = {
    orderHistory: 'Orderhistorik',
    orderId: 'Ordernummer',
    date: 'datum',
    status: 'Status på ordern',
    PONumber: 'P.O. nummer',
    total: 'Totalt',
    noOrders: 'Vi har inte orderdata för detta konto ännu.',
    startShopping: 'Börja shoppa',
    sortBy: 'Sortera efter',
    sortOrders: 'Sortera beställningar',
    purchaseOrderNumber: 'P.O. nummer',
    notFound: 'Inga beställningar hittades',
    actions: 'Åtgärder',
    filters: {
      orderNumber: 'Beställningsnummer',
      orderNumberPlaceholder: 'Ange ordernummer',
      PONumber: 'Eget ordernummer',
      PONumberPlaceholder: 'Ange ditt eget ordernummer',
      dateRange: 'Tidsperiod',
      dateRanges: {
        LAST_30_DAYS: 'Senaste 30 dagar',
        LAST_3_MONTHS: 'Senaste 3 månaderna',
        LAST_6_MONTHS:'Senaste 6 månaderna',
        CURRENT_YEAR:'Innevarande år',
        LAST_YEAR: 'Förra året',
        LAST_2_YEARS: 'Året före det senaste'
      },
      applyFilters: 'Använd filter',
      clearFilters: 'Återställ filter',
    },
    jumpToPageLabel: 'Hoppa till',
    jumpToPageInput: 'Sidnummer'
  };
  
  export const AccountOrderHistoryTabContainer = {
    tabs: {
      AccountOrderHistoryComponent: 'ALLA ORDER ({{param}})'
    }
  };
  
  export const order = {
    orderDetails,
    orderHistory,
    AccountOrderHistoryTabContainer
  };
  