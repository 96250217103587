export const exportEntries = {
    exportEntries: {
      exportToCsv: "CSV'ye Aktar",
      exportMessage: "CSV dosyası cihazınıza otomatik olarak indirilecektir, indirilmezse lütfen tarayıcı ayarlarınızı kontrol edin",
      columnNames: {
        code: "Kod",
        quantity: "Miktar",
        name: "İsim",
        price: "Fiyat"
      },
      exportProductToCsv: "Sepeti CSV'ye Aktar"
    }
  }
  
  export const importEntries = {
    importEntries: {
      importProducts: "İthalat Sepeti"
    }
  }
  
  export const importEntriesDialog = {
    importEntriesDialog: {
      importProducts: "Bir .CSV veya .XLSX dosyasını içe aktararak ürünleri içe aktarın",
      importProductsSubtitle: "Bir .CSV veya .XLSX dosyasını içe aktararak ürünleri içe aktarın",
      importProductsNewSavedCartSubtitle: "'İçe aktarılan dosya, Miktar ve ürün numarası sütunlarının noktalı virgülle ayrıldığı bir ürün listesi içermelidir.",
      importProductFileDetails: "Dosya Seçiniz",
      selectFile: "Kaydedilen Sepet Adı",
      savedCartName: "Kaydedilen Sepet Açıklaması",
      savedCartDescription: "isteğe bağlı",
      charactersLeft: "karakter kaldı: {{count}}",
      cancel: "İptal",
      upload: "Yükle",
      close: "Kapat",
      summary: {
        info: "Ürünler içe aktarılırken bu pencereyi kapatmayın veya yenilemeyin.",
        loadedToCart: "Ürünler sepete yüklendi {{cartName}}",
        loaded: "Ürünler yüklendi",
        loading: "Ürünler işleniyor... ({{count}}/{{total}})",
        successes: "'{{successesCount}}' üründen {{total}} başarıyla içe aktarıldı.",
        warning: "{{count}} ürünü tamamen içe aktarılmadı.",
        warning_other: "{{count}} ürünü tamamen ithal edilmedi.",
        error: "{{count}} ürünü ithal edilmedi.",
        error_other: "{{count}} ürünleri içe aktarılmadı.",
        messages: {
          unknownIdentifier: "Ürün SKU'su '{{productCode}}' mevcut değil.",
          lowStock: "'{{productName}}' için miktar: {{quantity}}, {{quantityAdded}}'ye düşürüldü.",
          noStock: "{{productName}} şu anda stokta yok.",
          unknownError: "'{{productCode}}' ile ilgili tanınmayan sorun.",
          limitExceeded: "'{{productCode}}' eklenemiyor. Limit aşıldı."
        },
        show: "Göster",
        hide: "Gizle"
      }
    }
  }
  