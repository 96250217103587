import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Configurator, ConfiguratorOverviewAttributeComponent } from '@spartacus/product-configurator/rulebased';
import { BreakpointService } from '@spartacus/storefront';

@Component({
  selector: 'rational-configurator-overview-attribute',
  templateUrl: './rational-configurator-overview-attribute.component.html',
  styleUrl: './rational-configurator-overview-attribute.component.scss',
  encapsulation: ViewEncapsulation.None
})

export class RationalConfiguratorOverviewAttributeComponent extends ConfiguratorOverviewAttributeComponent {

  @Input()
  override attributeOverview!: Configurator.AttributeOverview;

  constructor(
    protected override breakpointService: BreakpointService) {
    super(breakpointService);
  }

}
