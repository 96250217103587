import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { rationaOCCEndpoints } from '../../rational-backend-occ-endpoint/rational-backend-occ-endpoint.module';

@Injectable({
  providedIn: 'root',
})
export class RationalOrderDetailsService {
  constructor(
    protected http: HttpClient,
    protected userIdService: UserIdService,
    protected occEndpoints: OccEndpointsService
  ) {}

  public getOrderItemShippingItems(
    orderId: string,
    orderItem: string
  ): Observable<Object> {
    let url = '';

    this.userIdService.takeUserId().subscribe((userId) => {
      url = this.getOrderItemShippingItemsUrl(userId, orderId, orderItem);
    });

    return this.http.get(url);
  }

  protected getOrderItemShippingItemsUrl(
    userId: string,
    orderId: string,
    orderItem: string
  ) {
    const url = this.occEndpoints.buildUrl(
      rationaOCCEndpoints.orderManagmentDetails.getOrderItemShippingItems,
      { urlParams: { userId, orderId, orderItem } }
    );
    return url;
  }
}
