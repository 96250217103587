import {NgModule} from '@angular/core';
import {RationalProductPageMetaResolver} from "./product/rational-product-page-meta.resolver";
import {ProductPageMetaResolver} from "@spartacus/core";

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
    {
      provide: ProductPageMetaResolver,
      useClass: RationalProductPageMetaResolver,
    },
  ],
  exports: [],
})
export class MetaresolverModule {
}
