<button
  #expandButton
  class="btn cx-action-link"
  (click)="triggerZoom()"
>
  <span>
    {{ 'productImageZoomTrigger.expand' | cxTranslate }}
    <cx-icon [type]="iconType.EXPAND_ARROWS"></cx-icon
  ></span>
</button>
