import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Cart, CartOutlets, ActiveCartFacade } from "@spartacus/cart/base/root";
import { OrderDetailsService } from "@spartacus/order/components";
import { Order } from "@spartacus/order/root";
import { Observable } from "rxjs";


@Component({
    selector: 'cx-confirmation-order-summary',
    templateUrl: './rational-confirmation-order-summary.component.html',
    styleUrls: ['./rational-confirmation-order-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
export class RationalConfirmationOrderSummaryComponent{

    
  order$: Observable<Order>;

  readonly cartOutlets = CartOutlets;

  constructor(protected orderDetailsService: OrderDetailsService) {
    this.order$ = this.orderDetailsService.getOrderDetails();
  }



}