import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { OutletModule, CarouselModule, IconModule, KeyboardFocusModule } from '@spartacus/storefront';
import { RationalMediaModule } from '../../cmscomponents/media/rational-media.module';
import { RationalProductImageZoomProductImagesComponent } from './rational-product-image-zoom-product-images/rational-product-image-zoom-product-images.component';
import { RationalProductImageZoomTriggerComponent } from './rational-product-image-zoom-trigger/rational-product-image-zoom-trigger.component';
import { RationalProductImageZoomViewComponent } from './rational-product-image-zoom-view/rational-product-image-zoom-view.component';
import { RationalProductImageZoomThumbnailsComponent } from './rational-product-image-zoom-thumbnails/rational-product-image-zoom-thumbnails.component';
import { RationalProductImageZoomDialogComponent } from './rational-product-image-zoom-dialog/rational-product-image-zoom-dialog.component';
import { rationalDefaultProductImageZoomLayoutConfig } from './rational-product-image-zoom-layout.config';


@NgModule({
  declarations: [
    RationalProductImageZoomDialogComponent,
    RationalProductImageZoomProductImagesComponent, 
    RationalProductImageZoomThumbnailsComponent,
    RationalProductImageZoomTriggerComponent, 
    RationalProductImageZoomViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    RationalMediaModule,
    OutletModule,
    CarouselModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
  ],
  providers: [
    provideConfig(rationalDefaultProductImageZoomLayoutConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductImagesComponent: {
          component: RationalProductImageZoomProductImagesComponent,
        },
      },
    }),
  ],
  exports: [
    RationalProductImageZoomDialogComponent,
    RationalProductImageZoomProductImagesComponent, 
    RationalProductImageZoomThumbnailsComponent,
    RationalProductImageZoomTriggerComponent, 
    RationalProductImageZoomViewComponent,
    ]
})
export class RationalProductImagesModule { }
