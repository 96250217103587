import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RationalCheckoutLockInterceptor } from './rational-checkout-lock-interceptor';
import { RouterModule } from '@angular/router';
import { RationalCheckoutDeliveryDateInterceptor } from './rational-checkout-delivery-date-interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: RationalCheckoutLockInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: RationalCheckoutDeliveryDateInterceptor,
      multi: true,
    }
  ]
})
export class HttpInterceptorsModule { }
