import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ICON_TYPE } from "@spartacus/storefront";
import { Observable } from "rxjs";
import { RationalOrderDocument } from "../../model/rational-order-document.model";
import { RationalOrderDocumentService } from "../order-document/rational-order-document.service";


@Component({
  selector: 'cx-order-documents-list',
  templateUrl: './rational-order-documents-list.component.html',
  styleUrls: ['./rational-order-document-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalOrderDocumentsListComponent implements OnInit {
  iconTypes = ICON_TYPE;

  @Input()
  orderDocumentCategoryType: string | undefined;

  @Input()
  documentId: string | undefined;

  @Input()
  showTableHead: boolean= false

  documentType : string | undefined;


  constructor(
    protected rationalOrderDocumentService: RationalOrderDocumentService
  ) {
  }

  ngOnInit(): void {
    this.documents$ = this.rationalOrderDocumentService.getOrderDocuments(this.documentId ?? "", this.orderDocumentCategoryType ?? "");
    this.documentType = this.detectDocumentType();
  }

  documents$: Observable<RationalOrderDocument[]> | undefined = undefined;

  getFileNameWithType(document: RationalOrderDocument) {
    return document.Filename.concat('.').concat(document.MimeType.split("/").pop()!);
  }

  downloadDocument(orderDocument: RationalOrderDocument) {
    const a = document.createElement('a');
    return this.rationalOrderDocumentService.downloadDocument(orderDocument).subscribe((response) => {
      const file = new Blob([response], { type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      a.href = fileURL;
      a.download = this.getFileNameWithType(orderDocument);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileURL);
    });
  }

  detectDocumentType() {
    if(this.orderDocumentCategoryType == 'C') { return 'order'; }
    if(this.orderDocumentCategoryType == 'M') { return 'invoice'; }
    if(this.orderDocumentCategoryType == 'J') { return 'shipping'; }
    return '';
  }
}
