<!-- Item Start Outlet -->
<ng-template [cxOutlet]="CartOutlets.LIST_ITEM">
    <td role="cell">
      <ng-template
        [cxOutlet]="CartOutlets.ITEM_CONFIGURATOR_ISSUES"
      ></ng-template>
      <ng-container *cxFeatureLevel="'4.2'">
        <rational-cart-item-validation-warning
        [code]="item.product?.code!"
      ></rational-cart-item-validation-warning>
      </ng-container>
      <div class="cx-table-item-container">
        <!-- Item Image -->
        <!-- <a
          [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
          tabindex="0"
        >
          <cx-media
            [container]="item.product?.images?.['PRIMARY']"
            format="cartIcon"
          ></cx-media>
        </a> -->
        <div class="cx-info">
          <div *ngIf="item.product?.name" class="cx-name">
            <a
              class="cx-link"
              [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl"
              >{{ item.product?.name }}</a
            ><rational-product-description [product]="item.product"></rational-product-description>
          </div>
          <div *ngIf="item.product?.code" class="cx-code">
            {{ item.product?.code }}
          </div>
          <!-- Item Price -->
          <div *ngIf="!isItOrderPage() && item.basePrice" class="cx-price">
            <div *ngIf="item.basePrice" class="cx-value" [ngClass]="item.product?.isBaseProduct ? 'configured-product-price': ''">
              {{ item.basePrice.formattedValue }}
            </div>
          </div>
          <div *ngIf="isItCartPage(); else notCartPage">
            <!-- <a class="referenceHeader">{{ 'cartItems.reference' | cxTranslate }}</a> -->
            <textarea
              class="form-control referenceText"
              maxlength="40" #reference
              placeholder="{{ 'cartItems.referencePlaceHolder' | cxTranslate }}"
              (blur)="setEntryReference(item.entryNumber!, reference.value)"
              id="reference" name="reference" value="{{item.reference}}"
              >{{item.reference}}
            </textarea>
          </div>
          <ng-template #notCartPage>
            <div *ngIf="item.reference">
              <div class="referenceHeader">{{ 'cartItems.reference' | cxTranslate }}</div>
              <a class="referenceText">{{item.reference}}</a>
            </div>
          </ng-template>
            </div>
          </div>
      <div class="cx-cart-addons">

        <!-- Item Details Outlet -->
        <ng-template [cxOutlet]="RationalCartOutlets.DETAILS"> </ng-template>

        <rational-schedule-lines></rational-schedule-lines>

        <!-- Item Bundle Details Outlet -->
        <ng-template [cxOutlet]="CartOutlets.ITEM_BUNDLE_DETAILS"> </ng-template>

        <!-- Promotion -->
        <!-- <cx-promotions [promotions]="item.promotions"></cx-promotions> -->
      </div>

      <!-- Variants -->
      <!-- <ng-container *ngIf="item.product?.baseOptions?.length"> -->
        <!-- <div
          *ngFor="
            let variant of item.product?.baseOptions[0]?.selected
              ?.variantOptionQualifiers
          "
          class="cx-property"
        > -->
          <!-- cx-mobile-header -->
          <!-- <div class="cx-label" *ngIf="variant.name && variant.value">
            {{ variant.name }}: {{ variant.value }}
          </div>
        </div>
      </ng-container> -->
    </td>
    <!-- Mobile Item Price -->

    <td role="cell" *ngIf="!isItOrderPage() && item.basePrice" class="cx-price cx-mobile-only">
      <div class="cx-mobile-header">
        {{ 'cartItems.itemPrice' | cxTranslate }}
      </div>
      <div *ngIf="item.basePrice" class="cx-value">
        {{ item.basePrice.formattedValue }}
      </div>
    </td>


    <!-- Item Quantity -->
    <td role="cell" class="cx-quantity">
      <div
      class="cx-mobile-header"
      placement="left"
      title="{{ 'cartItems.quantityTitle' | cxTranslate }}"
      >
      {{ 'cartItems.quantity' | cxTranslate }}
    </div>
    <div class="cx-value" [class.readonly-value]="readonly" [class]="quantityControl.dirty ? 'row' : '' ">
        <div class="search-box-spinner" *ngIf="quantityControl.dirty">
          <div class="loader-container">
            <div class="loader"></div>
          </div>
        </div>
        <cx-item-counter
          [control]="quantityControl"
          [readonly]="!item.updateable || readonly! || options.isSaveForLater!"
          [max]="item.product?.stock?.stockLevel!"
          [allowZero]="true"
          class="item-counter"
        >
        </cx-item-counter>
      </div>
    </td>

    <!-- SalesUnit -->
    <ng-container>
      <td role="cell" class="cx-total">
        <div class="cx-mobile-header">
          {{ 'cartItems.salesUnit' | cxTranslate }}
        </div>
        <div *ngIf="item.product?.salesUnit; else noSalesUnit" class="cx-value">
          {{item.product?.salesUnit}}
        </div>
        <ng-template #noSalesUnit>
          <div class="cx-value">
            -
          </div>
        </ng-template>
      </td>
    </ng-container>

    <!-- Total -->
    <ng-container *ngIf="options.isSaveForLater; else total">
      <td role="cell" class="cx-total">
        <div class="cx-mobile-header">
          {{ 'saveForLaterItems.stock' | cxTranslate }}
        </div>
        <div
          *ngIf="item.product?.stock?.stockLevel! >= 0; else forceInstock"
          class="cx-value"
        >
          {{ item.product?.stock?.stockLevel }}
        </div>
        <ng-template #forceInstock>
          <div class="cx-value">
            {{ 'saveForLaterItems.forceInStock' | cxTranslate }}
          </div>
        </ng-template>
      </td>
    </ng-container>

    <!-- Billing Link -->
    <ng-container *ngIf="showOrderInformationLinks" #element>
      <td role="cell">
        <div class="cx-mobile-header">
          {{ 'orderDetails.moreInformation' | cxTranslate }}
        </div>
        <div class="column">
          <div class="billing-link" (click)="showBillingInvoices($event, item.product?.code);" >
              {{ 'orderDetails.billing' | cxTranslate }}
          </div>
          <div class="billing-link" (click)="showShippingItems($event, item.orderCode, item.entryNumber);" >
              {{ 'orderDetails.shipping.shipping' | cxTranslate }}
          </div>
        </div>
      </td>
    </ng-container>

    <td
      role="cell"
      *ngIf="
        (!readonly || options.isSaveForLater || options.displayAddToCart) &&
        item.updateable
      "
      class="cx-actions"
    >
      <ng-container *ngIf="!isProductOutOfStock(item.product)">
        <ng-container
          *ngTemplateOutlet="
            options.optionalBtn;
            context: {
              $implicit: {
                loading: quantityControl.disabled,
                item: this.item,
                options: this.options
              }
            }
          "
        ></ng-container>
      </ng-container>

      <!-- Availability -->
      <span
        role="cell"
        *ngIf="isProductOutOfStock(item.product)"
        class="cx-availability"
      >
        {{ 'addToCart.outOfStock' | cxTranslate }}
      </span>

      <button
        *ngIf="!readonly"
        (click)="removeItem()"
        [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate"
        [disabled]="false"
        attr.aria-label="{{ 'addToCart.removeFromCart' | cxTranslate }}"
        class="link cx-action-link cx-remove-btn"
      >
        {{ 'common.remove' | cxTranslate }}
      </button>
    </td>
  </ng-template>
  <ng-template #total>
    <td role="cell" *ngIf="item.totalPrice" class="cx-total">
      <div class="cx-mobile-header">
        {{ 'cartItems.total' | cxTranslate }}
      </div>
      <div class="cx-value" [ngClass]="item.product?.isBaseProduct ? 'configured-product-price': ''">{{ item.totalPrice.formattedValue }}</div>
    </td>
  </ng-template>
