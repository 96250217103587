import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, FeaturesConfigModule, I18nModule, provideConfig, provideDefaultConfig } from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  KeyboardFocusModule,
} from '@spartacus/storefront';
import { RationalSavedCartFormDialogComponent } from './saved-cart-form-dialog.component';
import { rationalSavedCartFormLayoutConfig } from './default-saved-cart-form-layout.config';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
    FeaturesConfigModule,
  ],
  declarations: [RationalSavedCartFormDialogComponent],
  exports: [RationalSavedCartFormDialogComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        SavedCartFormDialogComponent: {
          component: RationalSavedCartFormDialogComponent,
        },
      },
    }),
    provideConfig(rationalSavedCartFormLayoutConfig),
  ],
})
export class RationalSavedCartFormDialogModule {}
