import { HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AppRoutingModule } from "@spartacus/storefront";
import { AppComponent } from './app.component';
import { SpartacusModule } from './spartacus/spartacus.module';
import { CustomModule } from "./custom/custom.module";

import { RationalMultiCartEffects } from "./custom/store/effects/rational-multi-cart.effect";
import { provideDefaultConfig } from "@spartacus/core";
import { rationalCustomRoutingConfig } from "./spartacus/features/product/rational-custom-routing-config";
import { RouterModule } from "@angular/router";
import { EndpointComponent } from "./custom/context-switcher/endpoint/endpoint.component";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot([]),
    EffectsModule.forRoot([
      RationalMultiCartEffects
    ]),
    SpartacusModule,
    CustomModule,
    RouterModule.forChild([{
      path: 'context/:b2bUnitId',
      component: EndpointComponent
    }])
  ],
  providers: [
    { provide: "SavedCartEffects", useClass: RationalMultiCartEffects },
    provideDefaultConfig(rationalCustomRoutingConfig),
    provideAnimationsAsync()

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
