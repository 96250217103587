export const quickOrderCartForm = {
    entriesWasAdded: "({{ quantity }}) {{ product }} sepete eklendi",
    entryWasAdded: "{{ product }} sepete eklendi",
    noResults: "Herhangi bir ürün bulamadık",
    stockLevelReached: "Maksimum stok seviyesine ulaşıldı",
    title: "Hızlı sepete ekle",
    productCodePlaceholder: "Makale Numarasını Girin",
    entryProductCode: "Hızlı Sipariş için Makale Numarasını Girin",
    addToCart: "Hızlı sepete ekle",
    product: "Ürün",
    products: "Ürünler",
    productCodeLabel: "Ürün Kimliği",
    quantityLabel: "Miktar",
    placeholder: "Ürün SKU'sunu Girin",
    listLimitReached: "Ürün limitine ulaşıldı",
    id: "Makale Numarası {{ id }}"
};

export const quickOrderList = {
    addToCart: "Sepete Ekle",
    emptyList: "Boş liste",
    header: "Ürün Ekle/Skus",
    subHeader: "Bir seferde en fazla {{limit }} geçerli SKU ekleyebilirsiniz.",
    errorProceedingToCart: "Hata Sepete ilerleniyor.",
    warningProceedingToCart: "Uyarı Sepete ekleniyor.",
    successfullyAddedToCart: "Sepete başarıyla eklendi.",
    errors: {
        productIsOutOfStock: "{{name }} (#{{code}}) stokta yok."
    },
    warnings: {
        productWasReduced: "{{name }} için miktar (#{{code}}) {{quantityAdded}}'ye düşürüldü."
    },
    successes: {
        productAddedToCart: "{{name }} (#{{code}}) sepete eklendi."
    }
};

export const quickOrderTable = {
    product: "Ürün",
    actions: "Eylemler",
    id: "Makale Numarası",
    price: "Fiyat",
    quantity: "MIKTAR",
    itemPrice: "Ürün fiyatı",
    qty: "Miktar"
};

export const quickOrder = {
    quickOrderCartForm,
    quickOrderList,
    quickOrderTable
};
