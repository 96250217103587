import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ORDER_CORE_FEATURE, Order, OrderFacade } from '@spartacus/order/root';
import { facadeFactory } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
  useFactory: () =>
    facadeFactory({
      facade: RationalOrderFacade,
      feature: ORDER_CORE_FEATURE,
      methods: [
        'getOrderDetails',
        'clearPlacedOrder',
        'setPlacedOrder',
        'placeOrder',
        'getPickupEntries',
        'getDeliveryEntries',
        'placeQuoteOrder'
      ],
    }),
})
export abstract class RationalOrderFacade extends OrderFacade {
  /**
   * Places a Quote Order
   */
  abstract placeQuoteOrder(termsChecked: boolean): Observable<Order>;

}
