import { ChangeDetectionStrategy, Component } from '@angular/core';
import { B2BCheckoutReviewSubmitComponent } from '@spartacus/checkout/b2b/components';
import { RationalCartItemUpdateService } from '../../cart/base/cart-item/rational-cart-item-list-row/rational-cart-item-update.service';
import { ActiveCartFacade, Cart, DeliveryMode } from '@spartacus/cart/base/root';
import { CheckoutCostCenterFacade, CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade, CheckoutPaymentFacade } from '@spartacus/checkout/base/root';
import { Address, TranslationService, UserCostCenterService, WindowRef } from '@spartacus/core';
import { RationalCart } from '../../model/rational-cart.model';
import { Card } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { rationalDeliveryModeCard } from '../utils/rational-order-card-utils';

@Component({
  selector: 'cx-review-submit',
  templateUrl: './checkout-review-submit.component.html',
  styleUrls: ['./checkout-review-submit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCheckoutReviewSubmitComponent extends B2BCheckoutReviewSubmitComponent {

  constructor(_checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade, 
    _checkoutPaymentFacade: CheckoutPaymentFacade, 
    _activeCartFacade: ActiveCartFacade, 
    _translationService: TranslationService, 
    _checkoutStepService: CheckoutStepService, 
    _checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade, 
    _checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade, 
    _checkoutCostCenterFacade: CheckoutCostCenterFacade, 
    _userCostCenterService: UserCostCenterService,
    protected rationalCartItemUpdateService : RationalCartItemUpdateService,
    protected winRef: WindowRef) {
      super(_checkoutDeliveryAddressFacade, _checkoutPaymentFacade, _activeCartFacade, _translationService, _checkoutStepService, _checkoutDeliveryModesFacade, _checkoutPaymentTypeFacade, _checkoutCostCenterFacade, _userCostCenterService);
    }

    updateCartComment(comment: string) {
      this.rationalCartItemUpdateService.updateCartComment(comment).subscribe();
    }
    
    updateCartAdditionalContactEmail(additionalContactEmail: string) {
      const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

      if (!emailRegex.test(additionalContactEmail)) {
        this.winRef.document.getElementById('additionalContactEmailWarning')!.style.display = 'block';
        this.rationalCartItemUpdateService.updateCartAdditionalContactEmail("").subscribe();
      } else {
        this.winRef.document.getElementById('additionalContactEmailWarning')!.style.display = 'none';
        this.rationalCartItemUpdateService.updateCartAdditionalContactEmail(additionalContactEmail).subscribe();
      }
    }
    
    getRequestedDeliveryDate(cart: Cart): Date {
      return new Date(((cart as RationalCart).requestedRetrievalAt) as string);
    }
    
  
    getRequestedDeliveryDateContent(requestedRetrievalAt: string | null): Observable<Card> {
      return this.translationService.translate('cartItems.requestedDeliveryDateTitle').pipe(
        filter(() => Boolean(requestedRetrievalAt)),
        map((textTitle) => {
          return {
            title: textTitle,
            text: [requestedRetrievalAt],
          } as Card;
        })
      );
    }

  override getDeliveryModeCard(deliveryMode: DeliveryMode): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutMode.deliveryMethod'),
    ]).pipe(map(([textTitle]) => rationalDeliveryModeCard(textTitle, deliveryMode)));
  }

  override getDeliveryAddressCard(
    deliveryAddress: Address,
  ): Observable<Card> {
    return this.translationService.translate('addressCard.shipTo').pipe(
      filter(() => Boolean(deliveryAddress)),
      map((textTitle) => {
        let commaSeparator = '';
        if(deliveryAddress.postalCode && deliveryAddress.town){
          commaSeparator = ', '
        }        
        return {
          title: textTitle,
          textBold: `${deliveryAddress.companyName ?? ''}`,
          text: [(deliveryAddress.firstName ?? '').concat(' ').concat(deliveryAddress.lastName?? '') ?? '', deliveryAddress.line1 ?? '', (deliveryAddress.postalCode ?? '') + commaSeparator + (deliveryAddress.town ?? ''), deliveryAddress.country?.name ?? ''],
        } as Card;
      })
    );
  }
}
