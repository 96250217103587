<ng-container *ngIf="savedCart$ | async as cart">
    <ng-container *ngIf="cart?.entries?.length! > 0; else emptyCartItems">
        <div role="status" [attr.aria-label]="'common.loaded' | cxTranslate"></div>
        <div class="cart-details-wrapper">
            <rational-saved-cart-detials-item-list
                [cartId]="cart.code!"
                [cartIsLoading]="!(cartLoaded$ | async)"
                [items]="cart.entries!"
                [promotionLocation]="CartLocation.SavedCart"
                [options]="{
                    displayAddToCart: true,
                    addToCartString: (buyItAgainTranslation$ | async)!,
                    optionalBtn: addToCartBtn
                }"
            ></rational-saved-cart-detials-item-list>
            <div class="row">
                <div class="offset-md-8 col-md-4">
                    <cx-cart-quick-order-form></cx-cart-quick-order-form>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #emptyCartItems>
    <div class="cx-spinner">
        <cx-spinner></cx-spinner>
    </div>
</ng-template>

<ng-template let-ctx #addToCartBtn>
    <cx-add-to-cart [productCode]="ctx.item.product?.code" [product]="ctx.item.product" [showQuantity]="false"
        [options]="ctx.options">
    </cx-add-to-cart>
</ng-template>
