import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { SavedCartFormDialogComponent } from '@spartacus/cart/saved-cart/components';
import { SavedCartFacade, SavedCartFormType } from '@spartacus/cart/saved-cart/root';
import { EventService, GlobalMessageService, RoutingService, UserIdService } from '@spartacus/core';
import { FormUtils, LaunchDialogService } from '@spartacus/storefront';
import { RationalSavedCartService } from '../../saved-cart/rational-saved-cart.service';
import { Observable } from 'rxjs';

export interface SavedCartFormDialogOptions {
  cart: Cart;
  layoutOption?: string;
}

@Component({
  selector: 'cx-saved-cart-form-dialog',
  templateUrl: './saved-cart-form-dialog.component.html',
  styleUrls: ['./saved-cart-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalSavedCartFormDialogComponent extends SavedCartFormDialogComponent {
  userId: string | undefined;
  isSharedWithB2BUnit: boolean = false;
  sharedCart$: Observable<Cart> = this.rationalSavedCartService.getSharedCart();
  isCopySharedCartCheckBox: boolean = false;


  constructor(
    protected override launchDialogService: LaunchDialogService,
    protected override el: ElementRef,
    protected override savedCartService: SavedCartFacade,
    protected override eventService: EventService,
    protected override routingService: RoutingService,
    protected override globalMessageService: GlobalMessageService,
    protected rationalSavedCartService: RationalSavedCartService,
    protected userIdService: UserIdService
  ) {
    super(launchDialogService, el, savedCartService, eventService, routingService, globalMessageService)
    this.sharedCart$.subscribe((cart) => {
      this.isCopySharedCartCheckBox = this.cart.code === cart.code
    });
  }

  // close function override to remove its default behaviour
  override close(reason: string): void {

  }

  // add implementation to close only when clicking close
  closeDialog(reason: string) {
    this.launchDialogService.closeDialog(reason);
  }



  toggleIsSharedCart() {
    return (this.isSharedWithB2BUnit = !this.isSharedWithB2BUnit);
  }

  override saveOrEditCart(cartId: string): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      FormUtils.deepUpdateValueAndValidity(this.form);
    } else {
      const name = this.form.get('name')?.value;
      // TODO(#12660): Remove default value once backend is updated
      const description = this.form.get('description')?.value || '-';

      switch (this.layoutOption) {
        case SavedCartFormType.SAVE: {
          this.rationalSavedCartService.saveCart({
            cartId,
            saveCartName: name,
            saveCartDescription: description,
            isSharedWithB2BUnit: this.isSharedWithB2BUnit
          });

          break;
        }

        case SavedCartFormType.EDIT: {
          this.savedCartService.editSavedCart({
            cartId,
            saveCartName: name,
            saveCartDescription: description,
          });

          break;
        }
      }
    }
  }
}
