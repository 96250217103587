export const orderDetails = {
    statusDisplay_open: 'Zatím nezpracováno',
    statusDisplay_processing: 'Částečně zpracováno',
    statusDisplay_completed: 'Zcela zpracováno',
    purchaseOrderNumber: 'Číslo vaší objednávky',
    orderNumber: 'Číslo objednávky ve webovém obchodě',
    methodOfPayment: 'Způsob platby',
    shippingMethod: 'Způsob dopravy',
    status: "Stav",
    placedOn: 'Umístěno na',
    moreInformation: 'Další informace',
    orderDocuments: 'Objednávkové dokumenty',
    billing: 'Fakturace',
    invoiceNumber: 'Číslo faktury',
    invoiceDate: 'Datum',
    netAmount: 'Čistá částka',
    noBillingInvoices: 'Zatím nejsou k dispozici žádné fakturační údaje.',
    noOrderDocuments: 'Zatím nejsou k dispozici žádné doklady k objednávce.',
    noInvoiceDocuments: 'Zatím nejsou k dispozici žádné fakturační doklady.',
    noShipmentDocuments: 'Zatím nejsou k dispozici žádné přepravní doklady.',
    shipping: {
      shipping: 'Přeprava',
      shippingId: 'Id přepravy',
      deliveryDate: 'Datum odeslání/vyzvednutí',
      quantity: 'Množství',
      tracking: 'Sledování',
      noShippingInfo: 'Zatím nejsou k dispozici žádné informace o přepravě.',
      noTrackingInfo: 'Zatím nejsou k dispozici žádné informace o sledování.'
    },
    orderId: "Objednávka #",
    replenishmentId: "Doplnění #",
    purchaseOrderId: "Objednávka #",
    emptyPurchaseOrderId: "Žádné",
    none: "Žádný {{value}}",
    placed: "Umístěno",
    placedBy: "Zadáno",
    unit: "Jednotka",
    costCenter: "Nákladové středisko",
    costCenterAndUnit: 'Nákladové středisko / jednotka',
    costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
    payByAccount: "Platba podle účtu",
    paidByCreditCard: "(placeno kreditní kartou)",
    active: "Aktivní",
    shippedOn: "Odesláno dne",
    startOn: "Začít na",
    nextOrderDate: "Datum příští objednávky",
    frequency: "Frekvence",
    cancelled: "Zrušeno",
    deliveryStatus_IN_TRANSIT: "V tranzitu",
    deliveryStatus_READY_FOR_PICKUP: "Připraveno k vyzvednutí",
    deliveryStatus_READY_FOR_SHIPPING: "Připraveno k odeslání",
    deliveryStatus_WAITING: "Čeká na",
    deliveryStatus_DELIVERING: "Doručení",
    deliveryStatus_PICKPACK: "Příprava k odeslání",
    deliveryStatus_PICKUP_COMPLETE: "Vyzvednutí dokončeno",
    deliveryStatus_DELIVERY_COMPLETED: "Doručení dokončeno",
    deliveryStatus_PAYMENT_NOT_CAPTURED: "Vystavení platebního dokladu",
    deliveryStatus_READY: "V procesu",
    deliveryStatus_DELIVERY_REJECTED: "Dodávka odmítnuta",
    deliveryStatus_SHIPPED: "Odesláno",
    deliveryStatus_TAX_NOT_COMMITTED: "Daňový problém",
    deliveryStatus_CANCELLED: "Zrušeno",
    statusDisplay_cancelled: "Zrušeno",
    statusDisplay_cancelling: "Zrušit čeká na vyřízení",
    statusDisplay_confirmed: "Objednávka potvrzena",
    statusDisplay_created: "Vytvořená objednávka",
    statusDisplay_error: "Čeká na vyřízení",
    statusDisplay_Error: "Čeká na vyřízení",
    statusDisplay_pending: {
        approval: "Čeká na schválení",
        merchant: {
            Approval: "Čeká na schválení obchodníkem"
        }
    },
    statusDisplay_approved: "Schváleno",
    statusDisplay_rejected: "Zamítnuto",
    statusDisplay_merchant: {
        Approved: "Obchodník schválen",
        Rejected: "Obchodník zamítnuto",
    },
    statusDisplay_assigned: {
        admin: "Přiřazeno správci"
    },
    consignmentTracking: {
      action: "Sledovat balíček",
      dialog: {
        header: "Informace o sledování",
        shipped: "Odesláno",
        estimate: "Odhadované doručení",
        carrier: "Doručovací služba",
        trackingId: "Sledovací číslo",
        noTracking: "Balíček nebyl odeslán ze skladu. Informace o sledování budou k dispozici po odeslání balíčku.",
        loadingHeader: "Sledování zásilky"
      }
    },
    cancellationAndReturn: {
      returnAction: "Žádost o vrácení zboží",
      cancelAction: "Zrušení položek",
      item: "Položka",
      itemPrice: "Cena položky",
      quantity: "Maximální množství",
      returnQty: "Množství k vrácení",
      cancelQty: "Množství ke zrušení",
      setAll: "Nastavte všechna množství na maximální hodnotu",
      totalPrice: "Celkem",
      submit: "Odeslat požadavek",
      returnSuccess: "Váš požadavek na vrácení zboží ({{rma}}) byl odeslán",
      cancelSuccess: "Byl odeslán váš požadavek na zrušení objednávky (původní objednávka {{orderCode}} bude aktualizována)."
    },
    cancelReplenishment: {
      title: "Zrušit doplnění",
      description: "Zrušit jakoukoli budoucí objednávku na doplnění?",
      accept: "Ano",
      reject: "Ne",
      cancelSuccess: "Objednávka doplnění #{{replenishmentOrderCode}} byla úspěšně zrušena."
    }
  };
  
  export const orderHistory = {
    orderHistory: 'Historie objednávek',
    orderId: 'Číslo objednávky',
    date: 'Datum',
    status: 'Stav',
    PONumber: 'P.O. číslo',
    total: 'Celkem',
    noOrders: 'Pro tento účet zatím nemáme k dispozici údaje o objednávce.',
    startShopping: 'Začít nakupovat',
    sortBy: 'Seřadit podle',
    sortOrders: 'Seřadit objednávky',
    purchaseOrderNumber: 'P.O. číslo',
    notFound: 'Nebyly nalezeny žádné objednávky',
    actions: 'Akce',
    filters: {
      orderNumber: 'Objednací číslo',
      orderNumberPlaceholder: 'Zadejte číslo objednávky',
      PONumber: 'Vlastní číslo objednávky',
      PONumberPlaceholder: 'Zadejte vlastní číslo objednávky',
      dateRange: 'Časové období',
      dateRanges: {
        LAST_30_DAYS: 'Posledních 30 dní',
        LAST_3_MONTHS: 'Poslední 3 měsíce',
        LAST_6_MONTHS:'Posledních 6 měsíců',
        CURRENT_YEAR:'Aktuální rok',
        LAST_YEAR: 'Minulý rok',
        LAST_2_YEARS: 'Předminulý rok'
      },
      applyFilters: 'Použít filtr',
      clearFilters: 'Vynulovat filtr',
    },
    jumpToPageLabel: 'Přejít na',
    jumpToPageInput: 'Číslo stránky'
  };
  
  export const AccountOrderHistoryTabContainer = {
    tabs: {
      AccountOrderHistoryComponent: 'VŠECHNY OBJEDNÁVKY ({{param}})'
    }
  };
  
  export const order = {
    orderDetails,
    orderHistory,
    AccountOrderHistoryTabContainer
  };
  