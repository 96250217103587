import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CartItemListComponent } from '@spartacus/cart/base/components';

@Component({
  selector: 'rational-cart-item-list',
  templateUrl: './rational-cart-item-list.component.html',
  styleUrls: ['./rational-cart-item-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalCartItemListComponent extends CartItemListComponent {

  @Input() showOrderInformationLinks : Boolean = false;

}
