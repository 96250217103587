const visualPicking = {
    visualPickingProductList: {
      id: '商品番号'
    },
    visualPickingProductFilter: {
      input: {
        placeholder: '名前または商品番号でフィルター'
      }
    }
  }
  
  export const epdVisualization = {
    epdVisualization: {
      visualPicking
    }
  };
  