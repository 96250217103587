import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalAnonymousConsentManagementBannerComponent } from './rational-anonymous-consent-management-banner/rational-anonymous-consent-management-banner.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';



@NgModule({
  declarations: [
    RationalAnonymousConsentManagementBannerComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        AnonymousConsentManagementBannerComponent: {
          component: RationalAnonymousConsentManagementBannerComponent,
        },
      },
    }),
  ]
})

export class RationalAnonymousConsentManagementModule { }
