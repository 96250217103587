import { Component, ViewEncapsulation } from '@angular/core';
import { ProductService } from '@spartacus/core';
import { ConfiguratorRouterExtractorService } from '@spartacus/product-configurator/common';
import { ConfiguratorCommonsService, ConfiguratorExpertModeService, ConfiguratorProductTitleComponent } from '@spartacus/product-configurator/rulebased';

@Component({
  selector: 'rational-configurator-product-title',
  templateUrl: './rational-configurator-product-title.component.html',
  styleUrl: './rational-configurator-product-title.component.scss',
  encapsulation: ViewEncapsulation.None

})
export class RationalConfiguratorProductTitleComponent extends ConfiguratorProductTitleComponent {

  constructor(
    protected override configuratorCommonsService: ConfiguratorCommonsService,
    protected override configRouterExtractorService: ConfiguratorRouterExtractorService,
    protected override productService: ProductService,
    protected override configExpertModeService: ConfiguratorExpertModeService
  ) {
    super(configuratorCommonsService, configRouterExtractorService, productService, configExpertModeService)
  }

}
