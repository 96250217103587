export const cartDetails = {
  id: 'ID',
  proceedToCheckout: 'Weiter zur Kasse',
  createQuoteFromCart: 'Angebot anfragen',
  cartName: 'Warenkorb #{{code}}',
  minOrderNotReached: 'Der Mindestbestellwert liegt bei {{minOrderValue}}{{currency}}.',
  orderLocked: 'Achtung: Sie können derzeit keine Bestellungen anlegen! Bitte wenden Sie sich an Ihren Kundendienst. Es gibt Probleme mit diesem Nutzerkonto.',
  requestedRetrievalDateEmpty: 'Sie müssen ein gewünschtes Versand-/Abholdatum auswählen',
  requestedRetrievalDateAdded: 'Sie haben erfolgreich ein gewünschtes Versand-/Abholdatum hinzugefügt',
  requestedRetrievalDateInvalid: 'Gewünschtes Versand-/Abholdatum ist ungültig	',
  s4omScheduleLinesQuantity: '-  Qty',
  s4omScheduleLinesEntryInfo: 'Bestätigtes Versand-/Abholdatum von - {{quantity}} Stück  - ist {{date}}',
  cartSubjectToPriceChange: 'Achtung! Die Preise für neu konfigurierte Geräte sind Listenpreise. Ihren kundenspezifischen Preis erhalten Sie mit der formellen Auftragsbestätigung.',
  removeInvalidEntries: 'Attention: Cart has been recalculated as some products are not available any more.'
}

export const cartItems = {
  id: 'Artikelnummer',
  description: 'Beschreibung',
  item: 'Artikel',
  itemPrice: 'Artikel Preis',
  quantity: 'Menge',
  equipmentNumber: 'Maschinennummer: ',
  quantityTitle: 'Die Menge stellt die Gesamtanzahl dieses Artikels in Ihrem Warenkorb dar.',
  total: 'Gesamt',
  cartTotal: 'Warenkorb gesamt ({{count}} Artikel)',
  cartTotal_plural: 'Warenkorb gesamt ({{count}} Artikel)',
  actions: 'Aktionen',
  salesUnit: 'Maßeinheit',
  reference: 'Referenz:',
  referencePlaceHolder: 'Referenz',
  additionalContactEmail : 'Zusätzliche Kontakt E-Mail-Adresse:',
  additionalContactEmailPlaceHolder : 'Zusätzliche Kontakt E-Mail-Adresse',
  comment : 'Kommentar:',
  commentPlaceHolder : 'Kommentar',
  additionalContactEmailWarning : 'Bitte geben Sie eine gültige E-Mail-Adresse ein. (Ungültige E-Mail-Adresse werden nicht gespeichert.)',
  requestedDeliveryDate : 'Gewünschtes Lieferdatum:',
  requestedDeliveryDateTitle : 'Gewünschtes Versand-/Abholdatum',
  substitutionProductNotFound : 'Dieses Produkt wird durch ein anderes ersetzt, aber das Ersatzprodukt ist derzeit nicht verfügbar'
}

export const orderCost = {
  orderSummary: 'Bestellübersicht',
  subtotal: 'Zwischensumme:',
  shipping: 'Versand:',
  estimatedShipping: 'Das geschätzte Versanddatum:',
  discount: 'Sie Sparen:',
  salesTax: 'Mehrwertsteuer:',
  grossTax: 'Die Auftragssumme beinhaltet nicht die MwSt in höhe von',
  grossIncludeTax: 'Die Auftragssumme beinhaltet die MwSt in höhe von',
  total: 'Summe:',
  toBeDetermined: 'TBD',
  requestedDeliveryDate: 'Gewünschtes Versand-/Abholdatum*',
  taxStatement: '*Alle angezeigten Preise sind kundenspezifische Nettopreise ohne Steuern. Sie werden mit der Auftragsbestätigung per E-Mail über Ihren finalen Gesamtnettopreis inkl. Steuern informiert, da zum Zeitpunkt der Bestellung die tagesaktuellen Nettopreise erneut ermittelt werden.',
  taxStatement2: 'Bei der Bestellung von Zubehör oder Ersatzteilen kann ein Mindestmengenzuschlag erhoben werden, wenn der Nettowert ohne Mehrwertsteuer den Mindestbestellwert nicht erreicht.'
}

export const voucher = {
  coupon: 'Haben Sie einen Gutschein?',
  coupon_plural: 'Gutscheincode',
  apply: 'Anwenden',
  placeholder: 'Gutscheincode',
  applyVoucherSuccess: '{{voucherCode}} wurde angewendet.',
  removeVoucherSuccess: '{{voucherCode}} wurde entfernt.',
  anchorLabel: 'Gutscheincode eingeben oder entfernen',
  vouchersApplied: 'Angewendete Gutscheine',
  availableCoupons: 'Verfügbare Gutscheine',
  availableCouponsLabel: 'Sie können diese Gutscheine zu Ihrem Auftrag hinzufügen.'
}

export const saveForLaterItems = {
  itemTotal: 'Für später gesichterte Artikel: {{count}}',
  itemTotal_plural: 'Für später gesicherte Artikel {{count}}',
  cartTitle: 'Warenkorb',
  saveForLater: 'Für später sichern',
  moveToCart: 'Warenkorb öffnen',
  stock: 'Lager',
  forceInStock: 'Auf Lager'
}

export const cart = {
  cartDetails,
  cartItems,
  orderCost,
  voucher,
  saveForLaterItems
}
