export const orderApprovalDetails= {
  back: 'Torna all\'elenco',
  showForm_APPROVE: 'Confermare l\'ordine...',
  showForm_REJECT: 'Annullare l\'ordine...',
  form: {
    title_APPROVE: 'Confermare l\'ordine {{orderCode}} con il valore {{orderTotal}}',
    title_REJECT: 'Annullare l\'ordine {{orderCode}} con il valore {{orderTotal}}',
    submit_APPROVE: 'Confermare',
    submit_REJECT: 'Annullare',
    cancel: 'Cancellare',
    comment_APPROVE: {
      label: 'Commento (facoltativo,massimo 255 caratteri)',
      placeholder: ''
    },
    comment_REJECT: {
      label: 'Commento (massimo 255 caratteri)',
      placeholder: ''
    }
  },
  permissionResults: {
    header: 'Dettagli dell\'ordine del cliente',
    permission: 'Autorizzazione',
    approver: 'Approvazione',
    status: 'Stato',
    approverComments: 'Commenti ',
    noApprovalComments: 'Nessuno',
    permissionType_B2BBudgetExceededPermission: 'Il budget associato al centro di costo scelto è stato superato',
    permissionType_B2BOrderThresholdPermission: 'Il totale dell\'ordine ha superato il limite ordine',
    permissionType_B2BOrderThresholdTimespanPermission: 'Il totale dell\'ordine ha superato il limite per intervallo di tempo'
  }
}

export const  orderApprovalList= {
  orderCode: 'Ordine #',
  POCode: 'ORDINE DI ACQUISTO #',
  placedBy: 'Piazzato da',
  date: 'Data',
  status: 'Stato',
  total: 'Totale',
  none: 'Non ci sono ordini da approvare',
  emptyList: 'Non ci sono ordini da approvare in questo momento.'
}

export const   orderApprovalGlobal= {
  notification: {
    noSufficientPermissions: 'Non ci sono permessi sufficienti per accedere a questa pagina.'
  }
}

export const orderApproval = {
  orderApprovalDetails,
  orderApprovalList,
  orderApprovalGlobal
}
