export const visualPicking = {
  visualPickingProductList: {
    id: 'Numéro d\'article',
  },
  visualPickingProductFilter: {
    input: {
      placeholder: 'Filtrer par nom ou par numéro d\'article',
    }
  }
}

export const epdVisualization = {
  epdVisualization: {
    visualPicking
  }
}
