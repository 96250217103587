import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { B2BUnit } from "@spartacus/core";
import { ContextSwitcherService } from "../service/context-switcher.service";


@Component({
    selector: 'endpoint-context-switcher',
    templateUrl: './endpoint.component.html',
    styleUrls: ['./endpoint.component.scss']
})
export class EndpointComponent implements AfterViewInit {

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected contextSwitcherService: ContextSwitcherService,
    ) {}
    
    ngAfterViewInit(): void {
        const b2bUnit: B2BUnit = {
            uid: this.activatedRoute.snapshot.params['b2bUnitId']
        }
        
        // i don't know why, but wihtout the setTimout the redirect does not work
        setTimeout(() => {
            this.contextSwitcherService.refreshLogin(b2bUnit)
        }, 1)
    }
}