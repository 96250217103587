import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { B2BUnit, BASE_SITE_CONTEXT_ID, GlobalMessageService, GlobalMessageType, RoutingService, SiteContextParamsService } from '@spartacus/core';
import { ContextSwitcherService } from './service/context-switcher.service';
import { SiteContextComponentService, SiteContextType } from '@spartacus/storefront';

export const SUCCESS_CONTEXT_SWITCHING = 'success-context-switching-msg';

@Component({
  selector: 'context-switcher',
  templateUrl: './context-switcher.component.html',
  styleUrls: ['./context-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContextSwitcherComponent implements OnInit {
  constructor(
    protected contextSwitcherService: ContextSwitcherService,
    protected globalMessageService: GlobalMessageService,
    protected siteContextParams: SiteContextParamsService,
    protected routing: RoutingService,
    protected componentService: SiteContextComponentService
  ) { }

  selectB2BUnitId: string | undefined;
  activeCurrency: string = '';
  options$: B2BUnit[] | null = null;

  ngOnInit() {
    this.populateCurrentUserContextOptionsAndSelectedContext();
  }

  populateCurrentUserContextOptionsAndSelectedContext(): void {
    this.contextSwitcherService.populateCurrentUserContextOptionsAndSelectedContext().subscribe(
      (response) => {
        if (response) {
          this.options$ = response.options ?? null;
          this.selectB2BUnitId = response.selectedContext?.uid;

          this.activeCurrency = response.selectedContext?.currency ?? '';
          this.updateActiveCurrency(this.activeCurrency);

          this.showMsgIfExits();
        }
      }
    );
  }

  public updateCurrentUserContext(selectedB2Bunit: B2BUnit): void {
    this.contextSwitcherService.refreshLogin(selectedB2Bunit);
  }

  private updateActiveCurrency(value: string) {
    this.componentService.setActive(value, SiteContextType.CURRENCY);
  }

  private showMsgIfExits(): void {
    const contextSwitchingMsg: string | null = window.localStorage.getItem(SUCCESS_CONTEXT_SWITCHING) ?? null;
    if (contextSwitchingMsg) {
      window.localStorage.removeItem(SUCCESS_CONTEXT_SWITCHING);
      this.globalMessageService.add(
        contextSwitchingMsg,
        GlobalMessageType.MSG_TYPE_CONFIRMATION
      );
    }
  }

  public resolveDisplayName(uid: string): string {
    if (!uid) {
      return "";
    }

    const uidParts = uid.split("_");
    if (uidParts.length <= 1) {
      return uid;
    }

    return uid.split("_")[1] + " - " + uid.split("_")[0].replace(/\b0+/g, '');
  }

}
