export const pageMetaResolver = {
  category: {
    title: '{{count}} wyników dla {{query}}',
    title_plural: '{{count}} wyników dla {{query}}',
  },
  checkout: {
    title: 'Kasa',
  },
  search: {
    title: '{{count}} wynik dla \'{{query}}\'',
    title_plural: '{{count}} wyników dla \'{{query}}\'',
    findProductTitle: '{{count}} wyników dla kupon \'{{coupon}}\'',
    findProductTitle_plural: '{{count}} wyników dla kupon \'{{coupon}}\'',
    default_title: 'Wszystkie produkty',
  },
  product: {
    description: '{{description}}',
    heading: '{{heading}}',
    title: '{{title}}',
  },
}

export const spinner = {
  loading: 'Ładowanie...',
}

export const navigation = {
  shopAll: 'Kup wszystko {{ navNode}} >',
  listPrice: {
    enableListPrice: 'Włącz cenę katalogową',
    listPriceIsEnabled: 'Cena katalogowa jest włączona',
    listPriceIsDisabled: 'Cena katalogowa jest wyłączona'
  }
}

export const searchBox = {
  placeholder: 'Szukaj tutaj...',
  ariaLabelInput: 'Szukaj tutaj...',
  ariaLabelSuggestions: 'sugestie wpisywania',
  ariaLabelProducts: 'wyniki produktów',
  initialDescription: 'Gdy dostępne są wyniki autouzupełniania, użyj strzałek w górę i w dół, aby je przejrzeć i wprowadź, aby wybrać. Użytkownicy urządzeń dotykowych mogą wyszukiwać za pomocą dotyku lub gestów machnięcia.',
  suggestionsResult: '{{ count }} sugestia i ',
  suggestionsResult_plural: '{{ count }} sugestii i ',
  productsResult: '{{ count }} produkt jest dostępny.',
  productsResult_plural: '{{ count }} produktów jest dostępnych.',
  resetLabel: 'Zresetuj wyszukiwanie',
  help: {
    insufficientChars: 'Wpisz więcej znaków',
    noMatch: 'Nie mogliśmy znaleźć żadnych wyników',
    exactMatch: '{{ term }}',
    empty: 'Zapytaj nas o cokolwiek',
  },
}

export const sorting = {
  date: 'Data',
  orderNumber: 'Numer zamówienia',
  rma: 'Numer zwrotu',
  replenishmentNumber: 'Numer uzupełnienia',
  nextOrderDate: 'Data następnego zamówienia',
}

export const httpHandlers = {
  badGateway: 'Wystąpił błąd serwera. Spróbuj ponownie później.',
  badRequestPleaseLoginAgain: '{{ errorMessage }}. Zaloguj się ponownie.',
  badRequestOldPasswordIncorrect: 'Stare hasło jest nieprawidłowe.',
  badRequestGuestDuplicateEmail:
    'Adres e-mail {{ errorMessage }} już istnieje. Podaj inny adres e-mail, aby zarejestrować się przy użyciu konta gościa.',
  conflict: 'już istnieje.',
  forbidden:
    'Nie masz uprawnień do wykonania tej czynności. Skontaktuj się z administratorem, jeśli uważasz, że to błąd.',
  gatewayTimeout: 'Serwer nie odpowiedział, spróbuj ponownie później.',
  internalServerError:
    'Wystąpił wewnętrzny błąd serwera. Spróbuj ponownie później.',
  sessionExpired: 'Twoja sesja wygasła. Zaloguj się ponownie.',
  unknownError: 'Wystąpił nieznany błąd',
  unauthorized: {
    common: 'Wystąpił nieznany błąd autoryzacji',
    invalid_client: 'Nieprawidłowe dane uwierzytelniające klienta',
  },
  validationErrors: {
    missing: {
      card_cardType:
        'Wybrana karta kredytowa nie jest obsługiwana. Wybierz inną.',
      card_accountNumber:
        'Wprowadzony numer karty kredytowej jest nieprawidłowy.',
      card_cvNumber: 'Wprowadzony kod zabezpieczający jest nieprawidłowy.',
      card_expirationMonth:
        'Wprowadzona data ważności karty kredytowej jest nieprawidłowa.',
      card_expirationYear:
        'Wprowadzona data ważności karty kredytowej jest nieprawidłowa.',
      billTo_firstName: 'Wprowadzone imię jest nieprawidłowe.',
      billTo_lastName: 'Wprowadzone nazwisko jest nieprawidłowe.',
      billTo_street1: 'Wprowadzony adres jest nieprawidłowy.',
      billTo_street2: 'Wprowadzony adres jest nieprawidłowy.',
      billTo_city:
        'Wprowadzone miasto jest nieprawidłowe dla tej karty kredytowej.',
      billTo_state:
        'Wprowadzony stan/prowincja nie jest ważny dla tej karty kredytowej.',
      billTo_country:
        'Wprowadzony kraj/region jest nieprawidłowy dla tej karty kredytowej.',
      billTo_postalCode:
        'Wprowadzony kod pocztowy jest nieprawidłowy dla tej karty kredytowej.',
      country: {
        isocode: 'Brakujący kraj',
      },
    },
    invalid: {
      card_expirationMonth:
        'Wprowadzona data ważności karty kredytowej jest nieprawidłowa.',
      firstName: 'Wprowadzone imię jest nieprawidłowe.',
      lastName: 'Wprowadzone nazwisko jest nieprawidłowe.',
      password: 'Wprowadzone hasło jest nieprawidłowe.',
      uid: 'Wprowadzony identyfikator UID jest nieprawidłowy.',
      code: 'Wprowadzony kod jest nieprawidłowy.',
      email: 'Adres e-mail jest nieprawidłowy.',
    },
  },
  cartNotFound: 'Nie znaleziono koszyka.',
  invalidCodeProvided: 'Podano nieprawidłowy kod.',
}

export const miniCart = {
  item: '{{count}} produktów obecnie w koszyku',
  item_plural: '{{count}} produktów obecnie w koszyku',
  total: '{{total}}',
  count: '{{count}}',
}

export const skipLink = {
  skipTo: 'Przejdź do',
  labels: {
    header: 'Nagłówek',
    main: 'Treść główna',
    footer: 'Stopka',
    productFacets: 'Aspekty produktu',
    productList: 'Lista produktów',
  }
}

export const formErrors = {
  globalMessage: 'Formularz, który próbujesz przesłać, zawiera błędy.',
  required: 'To pole jest wymagane',
  cxInvalidEmail: 'To nie jest prawidłowy format wiadomości e-mail',
  cxInvalidPassword: 'To nie jest prawidłowy format hasła',
  cxPasswordsMustMatch: 'Pola hasła muszą się zgadzać',
  cxEmailsMustMatch: 'Pola adresu e-mail muszą być zgodne',
  cxStarRatingEmpty: 'Pole oceny jest wymagane',
  cxNoSelectedItemToCancel: 'Wybierz co najmniej jedną pozycję',
  cxNegativeAmount: 'Kwota musi być równa lub większa od zera',
  cxContainsSpecialCharacters: 'Pole nie może zawierać znaków specjalnych',
  date: {
    required: 'To pole jest wymagane',
    min: 'Data nie może być wcześniejsza niż {{min}}',
    max: 'Data nie może być późniejsza niż  {{max}}',
    pattern: 'Użyj formatu daty rrrr-mm-dd',
    invalid: 'Użyj prawidłowej daty',
  },
  file: {
    invalidQunatity: 'Nieprawidłowa ilość produktu w liniach: {{ indexes }}',
    missingArticleNumber: 'Brakujący numer artykułu w wierszach: {{ indexes }}',
    importCartReopenDialogueText:'Zamknij okno dialogowe importu koszyka, a następnie zaktualizuj plik i ponów próbę przesłania.',
  },
}

export const errorHandlers = {
  scriptFailedToLoad: 'Nie udało się załadować skryptu.',
  refreshThePage: 'Odśwież stronę.',
}

export const common = {
  pageMetaResolver,
  spinner,
  navigation,
  searchBox,
  sorting,
  httpHandlers,
  miniCart,
  skipLink,
  formErrors,
  errorHandlers,
  common: {
    cancel: 'Anuluj',
    delete: 'Usuń',
    remove: 'Usuń',
    edit: 'Edytuj',
    restore: 'Przywróć',
    back: 'Wstecz',
    submit: 'Prześlij',
    continue: 'Kontynuuj',
    save: 'Zapisz',
    done: 'Gotowe',
    home: 'Strona główna',
    noData: 'Brak danych.',
    confirm: 'Potwierdź',
    more: 'więcej',
    close: 'Zamknij',
    loading: 'Ładowanie',
    menu: 'Menu',
    reset: 'Resetuj',
    search: 'Wyszukiwanie',
    add: 'Dodaj',
    userGreeting: 'Witamy',
    privacySettings: 'Ustawienia prywatności',
  }
}
