import { Component } from '@angular/core';
import { ProductImageZoomProductImagesComponent } from '@spartacus/product/image-zoom/components';

@Component({
  selector: 'cx-product-images',
  templateUrl: './rational-product-image-zoom-product-images.component.html',
  styleUrls: ['./rational-product-image-zoom-product-images.component.scss']
})
export class RationalProductImageZoomProductImagesComponent extends ProductImageZoomProductImagesComponent {
}
