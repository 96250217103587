<div class="cx-billing-invoices-dialog"  (esc)="close('Escape clicked')"  [cxFocus]="focusConfig">
  <div class="cx-product-invoice-container" #dialogElement>


    <!-- Modal Header -->
    <div class="modal-header cx-product-invoice-header">
      <div class="cx-product-invoice-title modal-title">
        {{ 'orderDetails.billing' | cxTranslate }}
      </div>

      <a (click)="close('Close Product Invoices Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
        class="close" type="button">
        <span aria-hidden="true">
          <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
        </span>
      </a>
    </div>

    <!-- Modal Body -->
    <div class="cx-product-invoice-body">
      <ng-container *ngIf="productInvoices$ | async as productInvoices; else loadingSpinner" >
        <ng-container *ngIf="productInvoices && productInvoices.length > 0; else noBillingInvoices">

          <table role="table">
            <thead>
              <tr role="row" class="cx-item-list-header">
                <th role="columnheader" class="cx-item-list-desc">
                  {{ 'orderDetails.invoiceNumber' | cxTranslate }}
                </th>
                <th role="columnheader" class="cx-item-list-qty">
                  {{ 'orderDetails.invoiceDate' | cxTranslate }}
                </th>
                <th role="columnheader" class="cx-item-list-qty">
                  {{ 'orderDetails.netAmount' | cxTranslate }}
                </th>
                <th role="columnheader" class="cx-item-list-qty">
                  {{ 'downloads.download' | cxTranslate }}
                </th>
              </tr>
            </thead>


            <tbody class="cx-item-list-items">
              <ng-container *ngFor="let invoice of productInvoices; let i = index">
                <tr>
                  <td role="cell">
                    <div class="cx-mobile-header"> {{ 'orderDetails.invoiceNumber' | cxTranslate }}</div>
                    <div class="cx-value">{{invoice.invoiceId}} </div>
                  </td>

                  <td role="cell">
                    <div class="cx-mobile-header">{{ 'orderDetails.invoiceDate' | cxTranslate }}</div>
                    <div class="cx-value">{{invoice.createdAt | cxDate: 'dd.MM.yyyy'}}</div>
                  </td>

                  <td role="cell">
                    <div class="cx-mobile-header">{{ 'orderDetails.netAmount' | cxTranslate }}</div>
                    <div class="cx-value">{{invoice.netAmount.formattedValue}}</div>
                  </td>

                  <td role="cell" class="downloadLink">
                    <div class="cx-mobile-header">{{ 'downloads.download' | cxTranslate }}</div>
                    <div class="cx-value" (click)="showInvoiceDocuments(i)" [class.is-active]="visibleLineIndex == i">
                      <cx-icon class="icon-raticons icon-arrow_down"></cx-icon>
                    </div>
                  </td>
                </tr>


                <ng-container *ngIf="visibleLineIndex == i">
                  <tr>
                    <td colspan="4">
                      <cx-order-documents-list  [documentId]="invoice.invoiceId" [orderDocumentCategoryType]="orderInvoiceDocumentCategoryType" />
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </ng-container>

        <ng-template #noBillingInvoices>
          <div>
            {{ 'orderDetails.noBillingInvoices' | cxTranslate }}
          </div>
        </ng-template>
      </ng-container>

      <ng-template #loadingSpinner>
        <div class="cx-spinner">
            <cx-spinner></cx-spinner>
        </div>
    </ng-template>
    </div>
  </div>
</div>
