<ng-container>
  <div class="container refComponent-container"
    *ngIf="((variants$ | async)?.length ?? 0) > 0 && (variants$ | async) as items">
    <h3>
      {{ 'configurator.variantCarousel.title' | cxTranslate }}
    </h3>

    <cx-carousel [items]="items" [template]="carouselItem"></cx-carousel>

  </div>

  <ng-template #carouselItem let-item="item">
    <a class="refText" tabindex="0" [routerLink]="{ cxRoute: 'product', params: { name: item.name , code: item.productCode } } | cxUrl">
      <cx-media class="refImg" [container]="item.imageData" format="product"></cx-media>
      <h4>{{ item.name }}</h4>
      <h4>{{ item.productCode }}</h4>
    </a>
  </ng-template>
</ng-container>
