export const visualPicking = {
  visualPickingProductList: {
    id: 'Número de artículo',
  },
  visualPickingProductFilter: {
    input: {
      placeholder: 'Filtrar por nombre o número de artículo',
    },
  },
}

export const epdVisualization = {
  epdVisualization: {
    visualPicking
  }
};
