export const checkoutAddress = {
  shippingAddress: 'Dirección de envío',
  selectYourShippingAddress: 'Seleccione su dirección de envío:',
  selectYourDeliveryAddress: 'Seleccione su Dirección de Envío:',
  defaultShippingAddress: 'Dirección de envío por defecto',
  addNewAddress: 'Añadir nueva dirección',
  shipToThisAddress: 'Enviar a esta dirección',
  addressSearch: 'Buscar dirección',
  addressSearchMinLength: 'El texto de la búsqueda debe tener al menos {{length}} caracteres',
  submitAddressSearch: 'Introduzca',
  errorSearchingSalesforce: 'No se puede completar la búsqueda en este momento,por favor inténtelo de nuevo más tarde',
  deliveryAddressSelected: 'Dirección de entrega seleccionada'
}

export const checkoutOrderConfirmation = {
  confirmationOfOrder: 'Confirmación de pedido:',
  confirmationOfQuote: 'Confirmación de recepción del presupuesto:',
  thankYou: 'Gracias por su pedido',
  thankYouQuote: '¡Gracias por su solicitud de presupuesto!',
  invoiceHasBeenSentByEmailLine1: 'Tenga en cuenta que este pedido puede tardar hasta un día laborable en aparecer en su historial de pedidos. Una vez allí,incluirá la confirmación formal del pedido junto con la fecha de envío confirmada por RATIONAL.',
  invoiceHasBeenSentByEmailLine2: 'la información completa sobre el precio,incluidos los impuestos aplicables. Si mientras tanto tiene alguna pregunta sobre este pedido,póngase en contacto con su servicio de atención al cliente de RATIONAL y haga referencia al ID de pedido indicado arriba. ',
  quoteHasBeenSentByEmailLine1: 'Tenga en cuenta que sus solicitudes de presupuesto no se transferirán a su historial de pedidos en la tienda web.Si tiene alguna pregunta sobre esta solicitud de presupuesto,póngase en contacto con el departamento de atención al cliente de RATIONAL',
  quoteHasBeenSentByEmailLine2: 'y haga referencia al número de presupuesto de la tienda online que aparece más arriba o a su número de pedido.',
  orderItems: 'Artículos del pedido',
  orderPlacedSuccessfully: 'Pedido realizado correctamente',
  createAccount: '¿Crear una cuenta?',
  createAccountForNext: 'Cree una cuenta para <{{email}}> para agilizar el proceso de compra en su próxima visita.',
}

export const checkoutReview = {
  review: 'Resumen del pedido',
  orderItems: 'Pedir artículos',
  autoReplenishOrder: 'Pedido de reposición automática',
  confirmThatRead: 'Confirmo que he leído y estoy de acuerdo con la Política de privacidad.',
  placeOrder: 'Realizar pedido',
  placeQuoteOrder: 'Solicitar presupuesto',
  scheduleReplenishmentOrder: 'Programar pedido de reposición',
  orderType_PLACE_ORDER: 'Ninguno (pedido único)',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Pedido de reposición',
  termsAndConditions: 'Términos y condiciones',
  editShippingAddress: 'Editar dirección de envío',
  poNumber: 'Número de pedido',
  every: 'todo',
  startOn: 'Empezar el',
  dayOfMonth: 'El día',
  repeatOnDays: 'Repetir en los días siguientes',
  recurrencePeriodType_DAILY: 'Día(s)',
  recurrencePeriodType_WEEKLY: 'Semana(s)',
  recurrencePeriodType_MONTHLY: 'Mes',
}

export const checkoutShipping = {
  shippingMethod: 'Método de envío',
  standardDelivery: 'Entrega estándar',
  premiumDelivery: 'Entrega Premium',
}

export const checkoutPO = {
  noPoNumber: 'Ninguno',
  poNumber: 'Su número de pedido',
  costCenter: 'Centro de coste',
  placeholder: 'Introduzca su número de pedido / orden de compra',
  availableLabel: 'Las direcciones de envío disponibles dependen del centro de coste.',
}

export const checkoutProgress = {
  methodOfPayment: 'Forma de pago',
  deliveryAddress: 'Dirección de envío',
  deliveryMode: 'Modo de envío',
  paymentDetails: 'Detalles del pago',
  reviewOrder: 'Resumen del pedido',
}

export const checkoutB2B = {
  checkoutB2B: {
    poNumber: 'Su número de pedido*',
    placeholder: 'Introduzca su propio número de pedido...',
    costCenter: 'Centro de coste',
    availableLabel: 'Las direcciones de envío disponibles dependen del centro de coste.',
    methodOfPayment: {
      paymentType: 'Tipo de Pago',
    },
    progress: {
      methodOfPayment: 'Forma de pago',
    },
    review: {
      poNumber: 'Su número de pedido',
    },
  },
}

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: 'Incoterms',
    paymentConditions: 'Condiciones de pago',
  },
}

export const checkoutMode = {
  deliveryMethod: 'Método de entrega',
  deliveryEntries: 'Artículos a enviar',
  specialShippingInstructions: 'Instrucciones especiales de envío',
  specialShippingInstructionsPlaceholder: 'Instrucciones especiales de envío'
}

export const checkout = {
 checkoutAddress,
  checkoutOrderConfirmation,
  checkoutReview,
  checkoutShipping,
  checkoutPO,
  checkoutProgress,
  checkoutMode,
  checkout : {
    backToCart: 'Volver al carrito',
    invalid: {
      accountType: 'Su cuenta no le permite pagar aquí.',
    }
  }
}
