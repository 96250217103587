import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';
import { RationalSavedCartFormDialogComponent } from './saved-cart-form-dialog.component';

export const rationalSavedCartFormLayoutConfig: LayoutConfig = {
  launch: {
    SAVED_CART: {
      inline: true,
      component: RationalSavedCartFormDialogComponent,
      dialogType: DIALOG_TYPE.DIALOG,
    },
  },
};
