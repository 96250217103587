<ng-container *ngIf="relationList$ | async as relationList">
  <div class="container refComponent-container">
    <h3>{{ 'productDetails.productRelations.productRelationsHeader' | cxTranslate}}</h3>

    <div *ngIf="isRelationsLoaded | async">

      <div *ngFor="let relation of relationList.references" class="refComponent">
        <div class="refComponent-title">
          <h4 class="refTitle">{{ ('productReferences.' + relation.referenceType) | cxTranslate }}</h4>
        </div>

        <cx-carousel [items]="getProductsForRelation(relation)" [template]="carouselItem">
        </cx-carousel>
      </div>

    </div>
  </div>


  <ng-template #carouselItem let-item="item">
    <a class="refText" tabindex="0" [routerLink]="{ cxRoute: 'product', params: item } | cxUrl">
      <cx-media class="refImg" [container]="item.images" format="product"></cx-media>
      <h4>{{ item.name }}</h4>
      <h4>{{ item.code }}</h4>
    </a>
  </ng-template>
</ng-container>