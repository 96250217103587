import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'rationalImage',
})
export class RationalImagePipe implements PipeTransform {
    constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

    transform(url: string): Observable<SafeUrl | string> {
        if (url && !url.startsWith('https://hcms.rational-online.com')) {
            return of(url);
        }

        url.replace("http://", "https://");
        
        const accessCredentials = environment.censhareUserName + ":" + environment.censharePassword;

        const authenticationValue: string = btoa(accessCredentials);
        const headers = new HttpHeaders()
            .set("Authorization", "Basic " + authenticationValue);

        return this.http.get(url, { headers, responseType: 'blob' })
            .pipe(
                map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
    }
}