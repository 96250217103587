import { Component, Optional, ViewEncapsulation } from '@angular/core';
import { CartItemContext } from '@spartacus/cart/base/root';
import { CommonConfiguratorUtilsService, ConfiguratorCartEntryInfoComponent } from '@spartacus/product-configurator/common';

@Component({
  selector: 'rational-configurator-cart-entry-info',
  templateUrl: './rational-configurator-cart-entry-info.component.html',
  styleUrl: './rational-configurator-cart-entry-info.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RationalConfiguratorCartEntryInfoComponent extends ConfiguratorCartEntryInfoComponent {

  constructor(
    @Optional() protected override cartItemContext: CartItemContext,
    protected override commonConfigUtilsService: CommonConfiguratorUtilsService
  ) {
    super(cartItemContext, commonConfigUtilsService)

    }

}
