export const paymentForm = {
  payment: 'Payment',
  choosePaymentMethod: 'Choose a payment method',
  paymentType: 'Method of Payment',
  accountHolderName: {
    label: 'Account Holder Name',
    placeholder: 'Account Holder Name'
  },
  cardNumber: 'Card Number',
  expirationDate: 'Expiration Date',
  securityCode: 'Security code (CVV)',
  securityCodeTitle: 'Card Verification Value',
  saveAsDefault: 'Save as default',
  setAsDefault: 'Set as default',
  billingAddress: 'Billing address',
  sameAsShippingAddress: 'Same as shipping address',
  selectOne: 'Select One...',
  monthMask: 'MM',
  yearMask: 'YYYY',
  expirationYear: 'Expiration year {{ selected }}',
  expirationMonth: 'Expiration month {{ selected }}',
  useThisPayment: 'Use this payment',
  addNewPayment: 'Add New Payment',
  changePayment: 'Change Payment'
}

export const paymentMethods = {
  paymentMethods: 'Payment methods',
  newPaymentMethodsAreAddedDuringCheckout: 'New payment methods are added during checkout.',
  invalidField: 'InvalidField: {{ field }}'
}

export const paymentCard = {
  deleteConfirmation: 'Are you sure you want to delete this payment method?',
  setAsDefault: 'Set as default',
  expires: 'Expires: {{ month }}/{{ year }}',
  defaultPaymentMethod: '✓ DEFAULT',
  selected: 'Selected'
}

export const paymentTypes = {
  title: 'Payment method',
  paymentType_CARD: 'Credit Card',
  paymentType_ACCOUNT: 'Account',
  paymentType_INVOICE: 'Invoice'
}

export const payment = {
  paymentForm,
  paymentMethods,
  paymentCard,
  paymentTypes
}
