export const savedCartDetails = {
  cartName: 'Nombre',
  cartDescription: 'Descripción',
  cartId: 'Identificación',
  dateSaved: 'Fecha guardada',
  items: 'Artículos',
  quantity: 'Cantidad',
  total: 'Total',
  editSavedCart: 'Editar cesta guardada',
  deleteSavedCart: 'Borrar cesta guardada',
  addSelectedItems: 'Añadir artículos seleccionados',
  emptyCartItems: 'Artículos vacíos',
  selectAll: "Seleccionar todo",
  addItemsToCart: "Pedir artículos seleccionados",
  quickAddToCart: "Añadir a la cesta guardada rápidamente",
  productAddedToCart: "{{product}} ha sido añadido a la cesta guardada.",
  selectedItemsAddedToCart: "Los artículos seleccionados se han añadido correctamente a la cesta.",
  selectedItemsAreEmpty: "Debe seleccionar al menos un artículo para añadirlo a la cesta.",
}

export const savedCartList = {
  savedCarts: 'Cesta guardada ({{count}})',
  cartName: 'Nombre',
  cartId: 'Identificación',
  dateSaved: 'Fecha de guardado',
  cartDescription: 'Descripción',
  quantity: 'Cantidad',
  total: 'Total',
  actions: 'Acciones',
  makeCartActive: 'Activar cesta.',
  notFound: 'No se han encontrado cestas guardadas.',
  swapCartNoActiveCart: 'la cesta existente se ha activado correctamente.',
  swapCartWithActiveCart: 'La cesta existente se ha activado con {{cartName}} correctamente. Sus artículos anteriores se han guardado en una cesta {{previousCartName}}.',
  editCart: 'Editar cesta'
}

export const savedCartCartPage = {
  messages: {
    cartSaved: 'Los artículos de su cesta se han guardado correctamente para más tarde {{cartName}}.',
  },
  back: 'Volver a la vista general',
}

export const savedCartDialog = {
  saveForLater: 'Guardar para más tarde',
  itemsSavedForLater: 'Todos los artículos de su cesta se guardarán para más tarde',
  savedCartName: 'Nombre de la cesta guardada',
  savedCartDescription: 'Descripción de la cesta guardada',
  optional: 'opcional',
  charactersLeft: 'caracteres restantes: {{count}}',
  cancel: 'Cancelar',
  save: 'Guardar',
  restore: 'Restaurar',
  followingCartDelete: 'La siguiente cesta guardada será eliminado',
  followingCartRestore: 'La siguiente cesta guardada se restaurará como carrito activo',
  delete: 'Borrar',
  deleteCartSuccess: 'Cesta eliminada correctamente',
  editCartSuccess: 'Cesta editada correctamente',
  editSavedCart: 'Editar cesta guardada',
  deleteSavedCart: 'Eliminar cesta guardada',
  restoreSavedCart: 'Restaurar cesta guardada',
  name: 'Nombre',
  id: 'Identificación',
  description: 'Descripción',
  quantity: 'Cantidad',
  total: 'Total',
  keepCopySavedCart: 'Guarde una copia de esta cesta en la lista de carritos guardados',
  isSharedCart: 'Compartir este carrito con su organización',
  defaultCloneCartName: 'Copia de {{name}}',
  nameOfCloneCart: 'Nombre de la cesta copiada',
}

export const addToSavedCart = {
  savedCarts: 'Cestas guardadas',
  saveCartForLater: 'Guardar cesta para más tarde',
  sharedCartMessageStart :'Un carrito compartido está disponible creado por {{userName}} ({{userMail}}). Haga clic ',
  sharedCartMessageMiddle :'aquí ',
  sharedCartMessageEnd :' para activar la cesta compartida. Al activar el carrito compartido, se eliminará del nivel de la empresa hasta que vuelva a compartirlo, y también sobrescribirá su carrito personal actual.'
}

export const savedCart = {
  savedCartDetails,
  savedCartList,
  savedCartCartPage,
  savedCartDialog,
  addToSavedCart
}
