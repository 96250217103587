import { Component, ViewEncapsulation } from '@angular/core';
import { SavedCartDetailsActionComponent } from '@spartacus/cart/saved-cart/components';

@Component({
  selector: 'rational-saved-cart-detials-action',
  templateUrl: './rational-saved-cart-detials-action.component.html',
  styleUrls: ['./rational-saved-cart-detials-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RationalSavedCartDetialsActionComponent extends SavedCartDetailsActionComponent {

}
