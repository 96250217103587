<ng-container *ngIf="form$ | async as form">

  <div class="order-return-hearder">
    {{ 'orderDetails.cancellationAndReturn.returnRequestTitle' | cxTranslate }}
  </div>

  <form [formGroup]="form">
    <!-- Items list -->
    <rational-amend-order-items *ngIf="entries$ | async as entries" [entries]="entries"></rational-amend-order-items>

    <!-- return commment -->
    <div class="field-conatiner return-comment">
      <div class="field-header">
        {{ 'orderDetails.cancellationAndReturn.commentForReturn' | cxTranslate }}
      </div>
      <textarea formControlName="returnComment" class="form-control" maxlength="250"></textarea>
    </div>


    <!-- return attachments -->
    <div class="field-conatiner attachments">
      <div class="field-header">
        {{ 'orderDetails.cancellationAndReturn.attachments' | cxTranslate }}
      </div>
      <div *ngIf="form.get('attachments')">
        <cx-file-upload [multiple]="true" [accept]="attachmentsAcceptedTypes" (change)="onFileSelect($event)" formControlName="attachments">
          {{ 'orderDetails.cancellationAndReturn.attach' | cxTranslate }}
        </cx-file-upload>

        <div class="attachmentsWarnings">
          <p *ngFor="let attachmentWarning of attachmentsWarnings | keyvalue">
            {{ 'orderDetails.cancellationAndReturn.' + attachmentWarning.key | cxTranslate : { value: attachmentWarning.value } }}
          </p>
        </div>

        <div class="attachedFiles">
          <p *ngFor="let file of form.get('attachments')?.value">
            {{ file?.name }}
          </p>
        </div>

      </div>
    </div>

    <!-- form errors -->
    <cx-form-errors [control]="form.get('entries')"></cx-form-errors>


    <!-- return guidelines link -->
    <div class="field-conatiner">
      <div class="return-guidelines">
        {{ 'orderDetails.cancellationAndReturn.returnGuidelines' | cxTranslate }}
        <a href="{{ 'orderDetails.cancellationAndReturn.returnGuidlelinesLink' | cxTranslate }}" target="_blank">
          <span>{{ 'orderDetails.cancellationAndReturn.returnGuidlelinesLinkText' | cxTranslate }}</span>
        </a>
      </div>
    </div>

    <!-- back & sumbit button -->
    <div class="row field-conatiner sumbit-order-return">

      <div class="col-lg-4 col-md-6 col-sm-12">
        <button class="btn btn-block btn-secondary" (click)="back()">
          {{ "common.back" | cxTranslate }}
        </button>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12">
        <button class="btn btn-block btn-primary" (click)="submit(form)">
          {{ 'orderDetails.cancellationAndReturn.confirmOrderReturn' | cxTranslate }}
        </button>
      </div>

    </div>


  </form>
</ng-container>
