export const pageMetaResolver = {
  category: {
    title: '{{count}} risultato per {{query}}',
    title_plural: '{{count}} risultati per {{query}}'
  },
  checkout: {
    title: 'Checkout'
  },
  search: {
    title: '{{count}} risultato per \'{{query}}\'',
    title_plural: '{{count}} risultati per \'{{query}}\'',
    findProductTitle: '{{count}} risultato per coupon \'{{coupon}}\'',
    findProductTitle_plural: '{{count}} risultati per coupon \'{{coupon}}\'',
    default_title: 'Tutti i prodotti'
  },
  product: {
    description: '{{description}}',
    heading: '{{heading}}',
    title: '{{title}}'
  }
}

export const spinner = {
  loading: 'Caricamento...'
}

export const navigation = {
  shopAll: 'Acquista tutti {{ navNode}} >',
  listPrice: {
    enableListPrice: 'Abilitare il prezzo di listino',
    listPriceIsEnabled: 'Il prezzo di listino è abilitato',
    listPriceIsDisabled: 'Il prezzo di listino è disabilitato'
  }
}

export const searchBox = {
  placeholder: 'Cerca qui...',
  ariaLabelInput: 'Cerca qui...',
  ariaLabelSuggestions: 'suggerimenti',
  ariaLabelProducts: 'risultati',
  initialDescription: 'Quando sono disponibili i risultati del completamento automatico, utilizzare le frecce su e giù per visualizzare e premere "invio" per selezionare. Per gli utenti che usano dispositivi touch, spostarsi con il tocco o con i gesti di scorrimento.',
  suggestionsResult: '{{ count }} suggerimento e ',
  suggestionsResult_plural: '{{ count }} suggerimenti e ',
  productsResult: '{{ count }} prodotto è disponibile.',
  productsResult_plural: '{{ count }} prodotti sono disponibili.',
  resetLabel: 'Ripristinare la ricerca',
  help: {
    insufficientChars: 'Si prega di digitare più caratteri',
    noMatch: 'Non è stato trovato alcun risultato',
    exactMatch: '{{ term }}',
    empty: 'Chiedici qualcosa',
  },
}

export const sorting = {
  date: 'Data',
  orderNumber: 'Numero d\'ordine',
  rma: 'Numero di reso',
  replenishmentNumber: 'Numero di riapprovvigionamento',
  nextOrderDate: 'Data del prossimo ordine',
}

export const httpHandlers = {
  badGateway: 'Si è verificato un errore del server. Riprovare più tardi.',
  badRequestPleaseLoginAgain: '{{ errorMessage }}. Effettuare nuovamente il login.',
  badRequestOldPasswordIncorrect: 'La vecchia password non è corretta.',
  badRequestGuestDuplicateEmail: '{{ errorMessage }} L\'email è già esistente. Effettuare il login con un\'altra email per registrarsi come account ospite.',
  conflict: 'Esiste già.',
  forbidden: 'Non sei autorizzato a eseguire questa azione. Contattare l\'amministratore se si ritiene che sia un errore.',
  gatewayTimeout: 'Il server non risponde,riprovare più tardi.',
  internalServerError: 'Si è verificato un errore interno del server. Riprovare più tardi.',
  sessionExpired: 'La sessione è scaduta. Effettuare nuovamente il login.',
  unknownError: 'Si è verificato un errore sconosciuto',
  unauthorized: {
    common: 'Si è verificato un errore di autorizzazione sconosciuto',
    invalid_client: 'Credenziali errate',
  },
  validationErrors: {
    missing: {
      card_cardType: 'La carta di credito selezionata non è supportata. Selezionare un\'altra carta.',
      card_accountNumber: 'Il numero di carta di credito inserito non è valido.',
      card_cvNumber: 'Il codice di sicurezza inserito non è valido.',
      card_expirationMonth: 'La data di scadenza della carta di credito inserita non è valida.',
      card_expirationYear: 'La data di scadenza della carta di credito inserita non è valida.',
      billTo_firstName: 'Il nome inserito non è valido.',
      billTo_lastName: 'Il cognome inserito non è valido.',
      billTo_street1: 'L\'indirizzo inserito non è valido.',
      billTo_street2: 'L\'indirizzo inserito non è valido.',
      billTo_city: 'La città inserita non è valida per questa carta di credito.',
      billTo_state: 'Lo stato/provincia inserito non è valido per questa carta di credito.',
      billTo_country: 'La nazione/regione inserita non è valida per questa carta di credito.',
      billTo_postalCode: 'Il codice postale non è valido per questa carta di credito.',
      country: {
        isocode: 'Paese mancante',
      },
    },
    invalid: {
      card_expirationMonth: 'La data di scadenza della carta di credito inserita non è valida.',
      firstName: 'Il nome inserito non è valido.',
      lastName: 'Il cognome inserito non è valido.',
      password: 'La password inserita non è valida.',
      uid: 'L\'ID utente non è valido.',
      code: 'Il codice non è valido.',
      email: 'L\'e-mail non è valida.',
    },
  },
  cartNotFound: 'Carrello non trovato.',
  invalidCodeProvided: 'Codice non valido.',
}

export const miniCart = {
  item: '{{count}} articolo attualmente nel carrello',
  item_plural: '{{count}} articoli attualmente nel carrello',
  total: '{{total}}',
  count: '{{count}}',
}

export const skipLink = {
  skipTo: 'Passare a',
  labels: {
    header: 'Intestazione',
    main: 'Contenuto principale',
    footer: 'Piè di pagina',
    productFacets: 'Facet dei prodotti',
    productList: 'Elenco dei prodotti',
  },
}

export const formErrors = {
  globalMessage: 'Il modulo che si sta tentando di inviare contiene errori.',
  required: 'Questo campo è obbligatorio',
  cxInvalidEmail: 'Il formato dell\'e-mail non è valido',
  cxInvalidPassword: 'Il formato della password non è valido',
  cxPasswordsMustMatch: 'I campi della password devono corrispondere',
  cxEmailsMustMatch: 'I campi dell\'e-mail devono corrispondere',
  cxStarRatingEmpty: 'Il campo Valutazione è obbligatorio',
  cxNoSelectedItemToCancel: 'Selezionare almeno un articolo',
  cxNegativeAmount: 'L\'importo deve essere uguale o superiore a zero',
  cxContainsSpecialCharacters: 'Il campo non può contenere caratteri speciali',
  date: {
    required: 'Questo campo è obbligatorio',
    min: 'La data non può essere precedente a {{min}}',
    max: 'La data non può essere successiva a {{max}}',
    pattern: 'Usare il formato data yyyy-mm-dd',
    invalid: 'Utilizzare una data valida',
  },
  file: {
    invalidQunatity: 'Quantità non valida per il prodotto nelle righe: {{indexes }}',
    missingArticleNumber: 'Numero di articolo mancante nelle righe: {{ indexes }}',
    importCartReopenDialogueText: 'Chiudere il pop-up per il trasferimento nel carrello,quindi aggiornare il file e riprovare il caricamento.',
  }
}

export const errorHandlers = {
  scriptFailedToLoad: 'Impossibile caricare lo script.',
  refreshThePage: 'Aggiornare la pagina.',
}

export const common = {
  pageMetaResolver,
  spinner,
  navigation,
  searchBox,
  sorting,
  httpHandlers,
  miniCart,
  skipLink,
  formErrors,
  errorHandlers,
  common: {
    cancel: 'Annullare',
    delete: 'Eliminare',
    remove: 'Rimuovere',
    edit: 'Modificare',
    restore: 'Ripristinare',
    back: 'Indietro',
    submit: 'Inviare',
    continue: 'Continuare',
    save: 'Salvare',
    done: 'Fatto',
    home: 'Home',
    noData: 'Nessun dato.',
    confirm: 'Confermare',
    more: 'visualizzare altri dettagli',
    close: 'Chiudere',
    loading: 'Caricamento',
    menu: 'Menu',
    reset: 'Reset ',
    search: 'Cercare',
    add: 'Aggiungere',
    userGreeting: 'Benvenuti',
    privacySettings: 'Impostazioni sulla privacy',
  },
};
