export const paymentForm = {
    payment: "Betalning",
    choosePaymentMethod: "Välj en betalningsmetod",
    paymentType: "Typ av betalning",
    accountHolderName: {
        label: "Kontoinnehavarens namn",
        placeholder: "Kontoinnehavarens namn"
    },
    cardNumber: "Kortnummer",
    expirationDate: "Utgångsdatum",
    securityCode: "Säkerhetskod (CVV)",
    securityCodeTitle: "Kortets verifieringsvärde",
    saveAsDefault: "Spara som standard",
    setAsDefault: "Ange som standard",
    billingAddress: "Faktureringsadress",
    sameAsShippingAddress: "Samma som leveransadress",
    selectOne: "Välj en...",
    monthMask: "MM",
    yearMask: "ÅÅÅÅÅ",
    expirationYear: "Utgångsår {{ selected }}",
    expirationMonth: "Utgångsmånad {{ selected }}",
    useThisPayment: "Använd denna betalning",
    addNewPayment: "Lägg till ny betalning",
    changePayment: "Ändra betalning"
};

export const paymentMethods = {
    paymentMethods: "Betalningsmetoder",
    newPaymentMethodsAreAddedDuringCheckout: "Nya betalningsmetoder läggs till i kassan.",
    invalidField: "Ogiltigt fält: {{ field }}"
};

export const paymentCard = {
    deleteConfirmation: "Är du säker på att du vill ta bort den här betalningsmetoden?",
    setAsDefault: "Ställ in som standard",
    expires: "Upphör att gälla: {{ month }}/{{ year }}",
    defaultPaymentMethod: "✓ FÖRVAL",
    selected: "Vald"
};

export const paymentTypes = {
    title: "Betalningsmetod",
    paymentType_CARD: "Kreditkort",
    paymentType_ACCOUNT: "Konto",
    paymentType_INVOICE: "Faktura"
};

export const payment = {
    paymentForm,
    paymentMethods,
    paymentCard,
    paymentTypes
};
