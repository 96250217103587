export const quotes = {
  created: 'Presupuesto creado',
  title: 'Presupuesto',
  code: 'Código',
  name: 'Nombre',
  date: 'Fecha',
  state: 'Estado',
  statusDisplay_BUYER_APPROVED: 'Comprador - Aprobado',
  statusDisplay_BUYER_DRAFT: 'Comprador - Borrador',
  statusDisplay_BUYER_SUBMITTED: 'Comprador - Enviado',
  statusDisplay_BUYER_OFFER: 'Comprador - Oferta',
  statusDisplay_BUYER_ACCEPTED: 'Comprador - Aceptado',
  statusDisplay_BUYER_REJECTED: 'Comprador - Rechazado',
  statusDisplay_BUYER_ORDERED: 'Comprador - Pedido',
  statusDisplay_SELLER_REQUEST: 'Vendedor - Solicitud',
  statusDisplay_SELLER_DRAFT: 'Vendedor - Borrador',
  statusDisplay_SELLER_SUBMITTED: 'Vendedor - Enviado',
  statusDisplay_SELLERAPPROVER_DRAFT: 'Vendedoraprobador - Borrador',
  statusDisplay_SELLERAPPROVER_PENDING: 'Vendedoraprobado - Pendiente',
  statusDisplay_SELLERAPPROVER_REJECTED: 'Sellerapprover - Rechazado',
  statusDisplay_SELLERAPPROVER_APPROVED: 'Sellerapprover - Aprobado',
  noQuotes: 'Keine Angebote',
  entries: {
    code: 'Código',
    name: 'Nombre',
    amount: 'Cantidad',
    price: 'Precio',
  },
  totalPriceWithoutTax: 'Precio total sin IVA:',
};
