  <div class="cx-order-documents-dialog"  (esc)="close('Escape clicked')"  [cxFocus]="focusConfig">
    <div class="cx-product-invoice-container" #dialogElement>


      <!-- Modal Header -->
      <div class="modal-header cx-product-invoice-header">
        <div class="cx-product-invoice-title modal-title">
          {{ 'orderDetails.orderDocuments' | cxTranslate }}
        </div>

        <a (click)="close('Close Product Invoices Dialog')" [attr.aria-label]="'common.close' | cxTranslate"
          class="close" type="button">
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </a>
      </div>

      <!-- Modal Body -->
      <div class="cx-product-invoice-body">
        <cx-order-documents-list [documentId]="orderCode" [orderDocumentCategoryType]="orderDocumentCategoryType" [showTableHead]="true"/>
      </div>
    </div>
  </div>


