<rational-shared-cart-notification></rational-shared-cart-notification>
<ng-container *ngIf="cart$ | async as cart">
    <ng-container *ngIf="entries$ | async as entries">
      <div *ngIf="cart?.totalItems! > 0" class="cart-details-wrapper">
        <ng-container *cxFeatureLevel="'4.2'">
          <cx-cart-validation-warnings></cx-cart-validation-warnings>
        </ng-container>
  
        <h2 class="cx-total">
          {{ 'cartDetails.cartName' | cxTranslate: { code: cart.code } }}
        </h2>

        <div *ngIf="pricesSubjectToChange" class="cart-configured-product">
          {{ 'cartDetails.cartSubjectToPriceChange' | cxTranslate}}
        </div>
  
        <cx-promotions
          [promotions]="
            (cart.appliedOrderPromotions || []).concat(
              cart.potentialOrderPromotions || []
            )
          "
        ></cx-promotions>

        <rational-cart-item-list
          [items]="entries"
          [cartIsLoading]="!(cartLoaded$ | async)"
          [promotionLocation]="promotionLocation"
          [options]="{
            isSaveForLater: false,
            optionalBtn: saveForLaterBtn
          }"
        ></rational-cart-item-list>
      </div>
    </ng-container>
  </ng-container>
  
  <ng-template let-ctx #saveForLaterBtn>
    <button
      *ngIf="selectiveCartEnabled"
      class="link cx-action-link cx-sfl-btn"
      [disabled]="ctx.loading"
      (click)="saveForLater(ctx.item)"
      type="button"
    >
      {{ 'saveForLaterItems.saveForLater' | cxTranslate }}
    </button>
  </ng-template>
  