import { HttpClient } from "@angular/common/http";
import { B2BUnit, OccEndpointsService } from "@spartacus/core";
import { Observable } from "rxjs";
import { B2BCustomerContextInfo } from "../model/customer-context-switcher.model";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class ContextSwitcherOCCService {
    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
    ) { }

    public getCurrentUserContextOptions(): Observable<B2BCustomerContextInfo> {
        return this.http.get<B2BCustomerContextInfo>(this.getContextSwitcherAddress() + "/contextOptions");
    }

    public updateCurrentUserContext(selectedB2Bunit: B2BUnit): Observable<any> {
        return this.http.put<string>(this.getContextSwitcherAddress() + "/contextSelection/" + selectedB2Bunit.uid, null);
    }

    
    protected getContextSwitcherAddress() {
        return this.occEndpoints.buildUrl('/contextswitcher/currentUser')
    }

}
