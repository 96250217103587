export const savedCartDetails = {
  cartName: 'Name',
  cartDescription: 'Beschreibung',
  cartId: 'Nummer',
  dateSaved: 'Gespeichert am',
  items: 'Artikel',
  quantity: 'Menge',
  total: 'Summe',
  editSavedCart: 'Gespeicherten Warenkorb bearbeiten',
  deleteSavedCart: 'Gespeicherten Warenkorb löschen',
  addSelectedItems: 'Ausgewählte Artikel hinzufügen',
  emptyCartItems: 'Warenkorb leeren',
  selectAll: "Alles auswählen",
  addItemsToCart: "Ausgewählte Artikel bestellen",
  quickAddToCart: "Zum Warenkorb hinzufügen",
  productAddedToCart: "{{product}} wurde in den gespeicherten Warenkorb gelegt.",
  selectedItemsAddedToCart: "Die ausgewählten Artikel wurden erfolgreich in den Warenkorb gelegt.",
  selectedItemsAreEmpty: "Sie müssen mindestens einen Artikel auswählen, um ihn in den Warenkorb zu legen.",
}

export const savedCartList = {
  savedCarts: 'Gespeicherte Warenkörbe ({{count}})',
  cartName: 'Name',
  cartId: 'Nummer',
  dateSaved: 'Gespeichert am',
  cartDescription: 'Beschreibung',
  quantity: 'Menge',
  total: 'Summe',
  actions: 'Aktionen',
  makeCartActive: 'Warenkorb verwenden',
  notFound: 'Keine gespeicherten Warenkörbe vorhanden',
  swapCartNoActiveCart: 'Der gespeicherte Warenkorb wurde erfolgreich aktiviert.',
  swapCartWithActiveCart: 'Der gespeicherte Warenkorb {{cartName}} wurde erfolgreich aktiviert. Ihre vorherigen Artikel wurden im Warenkorb {{previousCartName}} gespeichert.',
  editCart: 'Warenkorb bearbeiten'
}

export const savedCartCartPage = {
  messages: {
    cartSaved: 'Ihre Artikel wurden erfolgreich im Warenkorb \'{{cartName}}\' gespeichert'
  },
  back : 'Zurück zur Übersicht'
}

export const savedCartDialog = {
  saveForLater: 'Speichern für später',
  itemsSavedForLater: 'Alle Artikel aus Ihrem Warenkorb werden für später gespeichert',
  savedCartName: 'Warenkorb Name gespeichert',
  savedCartDescription: 'Beschreibung gespeicherter Warenkorb',
  optional: 'optional',
  charactersLeft: 'Zeichen übrig: {{count}}',
  cancel: 'Abbrechen',
  save: 'Speichern',
  restore: 'Wiederherstellen',
  followingCartDelete: 'Der folgende, gespeicherte Warenkorb wird gelöscht',
  followingCartRestore: 'Der folgende Warenkorb wird übernommen als aktiver Warenkorb',
  delete: 'Löschen',
  deleteCartSuccess: 'Warenkorb erfolgreich gelöscht',
  editCartSuccess: 'Warenkorb erfolgreich bearbeitet',
  editSavedCart: 'Gespeicherten Warenkorb bearbeiten',
  deleteSavedCart: 'Gespeicherten Warenkorb löschen',
  restoreSavedCart: 'Gespeichterten Warenkorb wiederherstellen',
  name: 'Name',
  id: 'Nummer',
  description: 'Beschreibung',
  quantity: 'Menge',
  total: 'Summe',
  keepCopySavedCart: 'Behalten Sie eine Kopie dieses Warenkorbs in der Liste \'Gespeichtere Warenkörbe\'',
  isSharedCart: 'Teilen Sie diesen Warenkorb mit Ihrer Organisation',
  defaultCloneCartName: 'Kopie von {{name}}',
  nameOfCloneCart: 'Name des kopierten Warenkorbs'
}

export const addToSavedCart = {
  savedCarts: 'Gespeicherte Warenkörbe',
  saveCartForLater: 'Warenkorb speichern',
  sharedCartMessageStart :'Der Nutzer {{userName}} ({{userMail}}) hat einen geteilten Warenkorb erstellt. Klicken Sie ',
  sharedCartMessageMiddle :'hier',
  sharedCartMessageEnd :' um den geteilten Warenkorb zu aktivieren. Wenn Sie den geteilten Warenkorb aktivieren ist er nicht mehr sichtbar für andere Kollegen bis Sie ihn erneut teilen. Zudem überschreibt er Ihren gerade aktiven, persönlichen Warenkorb.'
}

export const savedCart = {
  savedCartDetails,
  savedCartList,
  savedCartCartPage,
  savedCartDialog,
  addToSavedCart
}
