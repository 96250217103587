<div class="cx-add-to-wish-list">
  <ng-container  *ngIf="product$ | async as product">
    <ng-container *ngIf="userLoggedIn$ | async; else loginPrompt">
      <ng-container *ngIf="wishListEntries$ | async as entries">
        <ng-container>
          <div
            *ngIf="getProductInWishList(product, entries) as entry; else addItem"
          >
            <button class="btn btn-link button-remove cx-action-link" (click)="remove(entry)"
              [disabled]="loading$ | async" [cxAtMessage]="'addToWishList.removedFromWishList' | cxTranslate"
            >
              <cx-icon class="full-heart icon-raticons icon-icon_Heart_full"></cx-icon>
              <span class="button-text">
                {{'addToWishList.remove' | cxTranslate}}
              </span>
            </button>
          </div>
          <ng-template #addItem>
            <button
              class="btn btn-link button-add cx-action-link"
              (click)="add(product)"
              [disabled]="loading$ | async"
              [cxAtMessage]="'addToWishList.addedToWishList' | cxTranslate"
            >
              <cx-icon class="empty-heart icon-raticons icon-icon_Heart"></cx-icon>
              <span class="button-text">{{
                'addToWishList.add' | cxTranslate
              }}</span>
            </button>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #loginPrompt>
    <ng-container>
      <a
        class="btn btn-link button-add-link cx-action-link"
        [routerLink]="{ cxRoute: 'login' } | cxUrl"
      >
        <cx-icon [type]="iconTypes.EMPTY_HEART"></cx-icon>
        <span class="button-text">{{
          'addToWishList.anonymous' | cxTranslate
        }}</span>
      </a>
    </ng-container>
  </ng-template>
</div>
