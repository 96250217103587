import { Observable } from "rxjs";
import { ContextSwitcherOCCService } from "./context-switcher-occ.service";
import { B2BUnit } from "@spartacus/core";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { B2BCustomerContextInfo } from "../model/customer-context-switcher.model";
import { RationalAuthService } from "../../rational-id/services/auth.service";
import { RationalOAuthLibWrapperService } from "../../rational-id/services/oauth-lib-wrapper.service";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class ContextSwitcherService {
    constructor(
        protected contextSwitcherOCCService: ContextSwitcherOCCService,
        protected authService: RationalAuthService,
        protected oAuthWrapper: RationalOAuthLibWrapperService,
        protected router: Router
    ) { }


    public populateCurrentUserContextOptionsAndSelectedContext(): Observable<B2BCustomerContextInfo> {
        return this.contextSwitcherOCCService.getCurrentUserContextOptions().pipe(
            catchError((error) => {
                this.authService.logout();
                throw (error);
            }),
        )
    }

    public updateCurrentUserContext(selectedB2Bunit: B2BUnit): Observable<any> {
        return this.contextSwitcherOCCService.updateCurrentUserContext(selectedB2Bunit).pipe(
            catchError((error) => {
                this.authService.logout();
                throw (error);
            }),
        );
    }

    public refreshLogin(selectedB2Bunit: B2BUnit): void {
        this.removeCartFromLocalStorage();

        this.router.navigate(['/']).then(() => {
            const formattedB2BUnitId: string = this.formatB2BUnitId(selectedB2Bunit)

            this.oAuthWrapper.setContext(formattedB2BUnitId);
            this.authService.loginRationalId();
        })
    }


    protected formatB2BUnitId(b2bUnit: B2BUnit): string {

        // 0000217905_150_01_01 -> 150-0000217905
        return b2bUnit.uid!.split("_").slice(0, 2).reverse().join("-");
    }

    protected removeCartFromLocalStorage(){
        const key = this.generateKeyWithContext('rational-spa', 'cart')
        window.localStorage.removeItem(key);
    }

    protected generateKeyWithContext(
        context: string | Array<string>,
        key: string
    ): string {
        return `spartacus⚿${([] as Array<string>)
            .concat(context)
            .join('⚿')}⚿${key}`;
    }
}
