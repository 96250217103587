export const exportEntries = {
  exportEntries: {
    exportToCsv: "Export do CSV",
    exportMessage: "Soubor CSV se automaticky stáhne do vašeho zařízení, pokud se tak nestane, zkontrolujte prosím nastavení prohlížeče.",
    columnNames: {
      code: "Kód",
      quantity: "Množství",
      name: "Název",
      price: "Cena"
    },
    exportProductToCsv: "Exportovat košík do CSV"
  }
}

export const importEntries = {
  importEntries: {
    importProducts: "Importovat košík"
  }
}

export const importEntriesDialog = {
  importEntriesDialog: {
    importProducts: "Import produktů importem souboru .CSV nebo .XLSX",
    importProductsSubtitle: "Import produktů importem souboru .CSV nebo .XLSX",
    importProductsNewSavedCartSubtitle: "Importovaný soubor by měl obsahovat seznam produktů se sloupci Množství a Číslo výrobku oddělené středníkem.",
    importProductFileDetails: "Vyberte soubor",
    selectFile: "Název uloženého košíku",
    savedCartName: "Uložený košík Popis",
    savedCartDescription: "volitelné",
    charactersLeft: "znaky vlevo: {{count}}",
    cancel: "Zrušit",
    upload: "Nahrát",
    close: "Zavřít",
    summary: {
      info: "Během importu produktů toto okno nezavírejte ani neobnovujte.",
      loadedToCart: "Výrobky byly vloženy do košíku {{ cartName }}",
      loaded: "Produkty byly načteny",
      loading: "Produkty jsou zpracovávány... ({{ count }}/{{ total }})",
      successes: "'{{ successesCount }} z {{ total }} produktů bylo úspěšně importováno.",
      warning: "{{ count }} produkt nebyl importován celý.",
      warning_other: "{{count}} produkty nebyly importovány úplně.",
      error: "{{ count }} produkt nebyl importován.",
      error_other: "{{count}} produkty nebyly importovány.",
      messages: {
        unknownIdentifier: "SKU produktu '{{ productCode}}' neexistuje.",
        lowStock: "'Množství pro {{ productName }}: {{ množství }} bylo sníženo na {{ quantityAdded }}.",
        noStock: "{{ productName }} není momentálně skladem.",
        unknownError: "Nerozpoznaný problém s '{{ productCode }}'.",
        limitExceeded: "Nelze přidat '{{ productCode }}'. Limit překročen."
      },
      show: "Zobrazit",
      hide: "Skrýt"
    }
  }
}
