export const organization = { 
    userRoles: {
      b2bcustomergroup: 'Cliente',
      b2bapprovergroup: 'Aprovador',
      b2bmanagergroup: 'Gerente',
      b2badmingroup: 'Administrador'
    },
    breadcrumb: 'Organização',
    notification: {
      noSufficientPermissions: 'Não há permissões suficientes para acessar esta página',
      notExist: 'Este item não existe',
      disabled: 'Não é possível editar um item desativado.'
    },
    confirmation: {
      cancel: 'CANCELAR',
      confirm: 'CONFIRMAR',
      disable: 'DESATIVAR',
      delete: 'EXCLUIR'
    },
    httpHandlers: {
      conflict: {
        budget: 'O orçamento com o código {{ code }} já existe.',
        costCenter: 'O centro de custos com o código {{ code }} já existe.',
        unit: 'Unidade organizacional com uid {{ code }} já existe.',
        user: 'Usuário com e-mail {{ code }} já existe',
        userGroup: 'Grupo de usuários com id {{ code }} já existe',
        permission: 'A permissão de aprovação com o código {{ code }} já existe.',
        unknown: 'Erro de validação do servidor.'
      }
    }
};
