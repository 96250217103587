import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import {HamburgerMenuComponent, HamburgerMenuService} from "@spartacus/storefront";

@Component({
  selector: 'custom-hamburger-menu',
  templateUrl: './rational-hamburger-menu.component.html',
  styleUrls: ['./rational-hamburger-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalHamburgerMenuComponent extends HamburgerMenuComponent {

  constructor(private customHamburgerMenuService: HamburgerMenuService) {
    super(customHamburgerMenuService)
  }


}
