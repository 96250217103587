export const pageMetaResolver = {
  category: {
    title: '{{count}} resultado para {{query}}',
    title_plural: '{{count}} resultados para {{query}}',
  },
  checkout: {
    title: 'Realizar pedido',
  },
  search: {
    title: '{{count}} resultado para \'{{query}}\'',
    title_plural: '{{count}} resultados para \'{{query}}\'',
    findProductTitle: '{{count}} resultado para cupón \'{{coupon}}\'',
    findProductTitle_plural: '{{count}} resultados para cupón \'{{coupon}}\'',
    default_title: 'Todos los productos',
  },
  product: {
    description: '{{descripción}}',
    heading: '{{heading}}',
    title: '{{title}}',
  },
}

export const spinner = {
  loading: 'Cargando...',
}

export const navigation = {
  shopAll: 'Comprar todo {{ navNode}} >',
  listPrice: {
    enableListPrice: 'Habilitar Precio de Lista',
    listPriceIsEnabled: 'Precio de Lista habilitado',
    listPriceIsDisabled: 'Precio de lista desactivado'
  }
}

export const searchBox = {
  placeholder: 'Buscar aquí...',
  ariaLabelInput: 'Buscar aquí...',
  ariaLabelSuggestions: 'sugerencias',
  ariaLabelProducts: 'resultados de productos',
  initialDescription: 'Cuando estén disponibles los resultados de autocompletar,utilice las flechas arriba y abajo para revisar y ENTER para seleccionar. Usuarios de dispositivos táctiles,utilicen gestos de deslizamiento.',
  suggestionsResult: '{{ count }} sugerencias y ',
  suggestionsResult_plural: '{{ count }} sugerencias y ',
  productsResult: '{{ count }} productos disponibles.',
  productsResult_plural: '{{ count }} productos disponibles.',
  resetLabel: 'Reiniciar búsqueda',
  help: {
    insufficientChars: 'Por favor,escriba más caracteres',
    noMatch: 'No hemos encontrado ningún resultado',
    exactMatch: '{{ term }}',
    empty: 'Pregúntanos lo que quiera',
  }
}

export const sorting = {
  date: 'Fecha',
  orderNumber: 'Número de pedido',
  rma: 'Número de devolución',
  replenishmentNumber: 'Número de reposición',
  nextOrderDate: 'Fecha del próximo pedido',
}

export const httpHandlers = {
  badGateway: 'Se ha producido un error en el servidor. Vuelva a intentarlo más tarde.',
  badRequestPleaseLoginAgain: '{{ errorMessage }}. Vuelva a iniciar sesión.',
  badRequestOldPasswordIncorrect: 'La contraseña anterior es incorrecta.',
  badRequestGuestDuplicateEmail: '{{ errorMessage }} El correo electrónico ya existe. Por favor,introduzca un email diferente para registrarse usando una cuenta de invitado.',
  conflict: 'Ya existe.',
  forbidden: 'No está autorizado a realizar esta acción. Póngase en contacto con su administrador si cree que se trata de un error.',
  gatewayTimeout: 'El servidor no responde,por favor inténtelo más tarde.',
  internalServerError: 'Se ha producido un error interno del servidor. Vuelva a intentarlo más tarde.',
  sessionExpired: 'Su sesión ha expirado. Vuelva a iniciar sesión.',
  unknownError: 'Se ha producido un error desconocido',
  unauthorized: {
    common: 'Se ha producido un error de autorización desconocido',
    invalid_client: 'Credenciales de cliente incorrectas',
  },
  validationErrors: {
    missing: {
      card_cardType: 'La tarjeta de crédito seleccionada no es compatible. Por favor,seleccione otra.',
      card_accountNumber: 'El número de tarjeta de crédito introducido no es válido.',
      card_cvNumber: 'El código de seguridad introducido no es válido.',
      card_expirationMonth: 'La fecha de caducidad de la tarjeta de crédito no es válida.',
      card_expirationYear: 'La fecha de caducidad de la tarjeta de crédito introducida no es válida.',
      billTo_firstName: 'El nombre introducido no es válido.',
      billTo_lastName: 'El apellido introducido no es válido.',
      billTo_street1: 'La dirección introducida no es válida.',
      billTo_street2: 'La dirección introducida no es válida.',
      billTo_city: 'La ciudad introducida no es válida para esta tarjeta de crédito.',
      billTo_state: 'El estado/provincia introducido no es válido para esta tarjeta de crédito.',
      billTo_country: 'El país/región introducido no es válido para esta tarjeta de crédito.',
      billTo_postalCode: 'El código postal no es válido para esta tarjeta de crédito.',
      country: {
        isocode: 'Falta el país',
      },
    },
    invalid: {
      card_expirationMonth: 'La fecha de caducidad de la tarjeta de crédito introducida no es válida.',
      firstName: 'El nombre introducido no es válido.',
      lastName: 'El apellido introducido no es válido.',
      password: 'La contraseña introducida no es válida.',
      uid: 'La identificación de usuario no es válida.',
      code: 'El código no es válido.',
      email: 'El correo electrónico no es válido.',
    },
  },
  cartNotFound: 'Cesta no encontrada.',
  invalidCodeProvided: 'Código no válido.',
}

export const miniCart = {
  item: '{{count}} artículos actualmente en su cesta',
  item_plural: '{{count}} artículos actualmente en su cesta',
  total: '{{total}}',
  count: '{{count}}',
}

export const skipLink = {
  skipTo: 'Saltar a',
  labels: {
    header: 'Cabecera',
    main: 'Contenido principal',
    footer: 'Pie de página',
    productFacets: 'Productos',
    productList: 'Lista de productos',
  },
}

export const formErrors = {
  globalMessage: 'El formulario que está intentando enviar contiene errores.',
  required: 'Este campo es obligatorio',
  cxInvalidEmail: 'Este no es un formato de correo electrónico válido',
  cxInvalidPassword: 'Este no es un formato de contraseña válido',
  cxPasswordsMustMatch: 'Los campos de contraseña deben coincidir',
  cxEmailsMustMatch: 'Los campos de correo electrónico deben coincidir',
  cxStarRatingEmpty: 'El campo Calificación es obligatorio',
  cxNoSelectedItemToCancel: 'Seleccione al menos un artículo',
  cxNegativeAmount: 'El importe debe ser igual o superior a cero',
  cxContainsSpecialCharacters: 'El campo no puede contener caracteres especiales',
  date: {
    required: 'Este campo es obligatorio',
    min: 'La fecha no puede ser anterior a {{min}}',
    max: 'La fecha no puede ser posterior a {{max}}',
    pattern: 'Utilice el formato de fecha aaaa-mm-dd',
    invalid: 'Utilice una fecha válida',
  },
  file: {
    invalidQunatity: 'Cantidad inválida para producto en líneas: {{ indexes }}',
    missingArticleNumber: 'Falta el número de artículo en las líneas: {{ indexes }}',
    importCartReopenDialogueText: 'Por favor,cierre el diálogo de importación del carrito,luego actualice su archivo y vuelva a intentar subirlo.',
  }
}

export const errorHandlers = {
  scriptFailedToLoad: 'Error al cargar el script.',
  refreshThePage: 'Actualice la página.',
}

export const common = {
  pageMetaResolver,
  spinner,
  navigation,
  searchBox,
  sorting,
  httpHandlers,
  miniCart,
  skipLink,
  formErrors,
  errorHandlers,
  common: {
    cancel: 'Cancelar',
    delete: 'Eliminar',
    remove: 'Eliminar',
    edit: 'Editar',
    restore: 'Restaurar',
    back: 'Volver',
    submit: 'Enviar',
    continue: 'Continuar',
    save: 'Guardar',
    done: 'Hecho',
    home: 'Inicio',
    noData: 'No hay datos.',
    confirm: 'Confirme',
    more: 'más',
    close: 'Cerrar',
    loading: 'Cargando',
    menu: 'Menú',
    reset: 'Restablecer',
    search: 'Buscar en',
    add: 'Añadir',
    userGreeting: 'Bienvenido',
    privacySettings: 'Privacidad',
  }
}
