export const quickOrderCartForm = {
    entriesWasAdded: "({{quantity }}) {{product }} har lagts till i varukorgen",
    entryWasAdded: "{{product }} har lagts till i kundvagnen",
    noResults: "Vi kunde inte hitta några produkter",
    stockLevelReached: "Den maximala lagernivån har uppnåtts",
    title: "Lägg snabbt till i kundvagnen",
    productCodePlaceholder: "Ange artikelnummer",
    entryProductCode: "Ange artikelnummer för snabbeställning",
    addToCart: "Lägg snabbt till i kundvagnen",
    product: "Produkter",
    products: "Produkt",
    productCodeLabel: "Produkt ID",
    quantityLabel: "Antal",
    placeholder: "Ange produkt SKU",
    listLimitReached: "Produktgränsen har uppnåtts",
    id: "Artikel nummer {{ id }}"
};

export const quickOrderList = {
    addToCart: "Lägg till i kundvagn",
    emptyList: "Tom lista",
    header: "Lägg till produkter/Skus",
    subHeader: "Du kan lägga till upp till {{ limit }} giltiga SKU åt gången.",
    errorProceedingToCart: "Fel när du går till kundvagnen.",
    warningProceedingToCart: "Varning för att gå vidare till kundvagnen.",
    successfullyAddedToCart: "Framgångsrikt tillagd till kundvagnen.",
    errors: {
        productIsOutOfStock: "{{name }} (#{{ code }}) är slut i lager."
    },
    warnings: {
        productWasReduced: "Kvantiteten för {{name }} (#{{ code }}) har minskats till {{ quantityAdded }}."
    },
    successes: {
        productAddedToCart: "{{name }} (#{{ code }}) lades till i varukorgen."
    }
};

export const quickOrderTable = {
    product: "Produkt",
    actions: "Åtgärder",
    id: "Artikelnummer",
    price: "pris",
    quantity: "ANTAL",
    itemPrice: "Artikelpris",
    qty: "Antal"
};

export const quickOrder = {
    quickOrderCartForm,
    quickOrderList,
    quickOrderTable
};
