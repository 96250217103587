import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { DeliveryMode } from '@spartacus/cart/base/root';
import { Address, TranslationService } from '@spartacus/core';
import { OrderConfirmationShippingComponent, OrderDetailsService } from '@spartacus/order/components';
import { Order, OrderFacade } from '@spartacus/order/root';
import { Card } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { rationalDeliveryModeCard } from '../../checkout/utils/rational-order-card-utils';

@Component({
  selector: 'cx-order-confirmation-shipping',
  styleUrls: ['./order-confirmation-shipping.component.scss'], 
  templateUrl: './order-confirmation-shipping.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalOrderConfirmationShippingComponent extends OrderConfirmationShippingComponent {

  constructor(private orderDetailsService: OrderDetailsService, protected override orderFacade: OrderFacade,  protected override translationService: TranslationService, cd: ChangeDetectorRef) {
    super(orderFacade, translationService, cd)
  }

  override order$: Observable<Order | undefined> = this.orderDetailsService
    .getOrderDetails()
    .pipe(
      tap((order) => {
        this.entries = order?.entries?.filter(
          (entry) => entry.deliveryPointOfService === undefined
        );
      })
    );

  override getDeliveryModeCard(deliveryMode: DeliveryMode): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutMode.deliveryMethod'),
    ]).pipe(map(([textTitle]) => rationalDeliveryModeCard(textTitle, deliveryMode)));
  }

  override getDeliveryAddressCard(deliveryAddress: Address): Observable<Card> {
    return this.translationService.translate('addressCard.shipTo').pipe(
      filter(() => Boolean(deliveryAddress)),
      map((textTitle) => {
        let commaSeparator = '';
        if (deliveryAddress.postalCode && deliveryAddress.town) {
          commaSeparator = ', ';
        }

        const fullName = (deliveryAddress.firstName ?? '').concat(' ').concat(deliveryAddress.lastName ?? '') ?? ''
        const line1 = deliveryAddress.line1 ?? '';
        const address = (deliveryAddress.postalCode ?? '') + commaSeparator +(deliveryAddress.town ?? '');
        const country =  deliveryAddress.country?.name ?? '';
        return {
          title: textTitle,
          textBold: `${deliveryAddress.companyName ?? ''}`,
          text: [ fullName, line1, address, country]
        } as Card;
      })
    );
  }

}
