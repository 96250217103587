export const productDetails = {
  id: 'Numéro d\'article',
  quantity: 'Qté',
  productDetails: 'Détails du produit',
  specification: 'Caractéristiques techniques',
  reviews: 'Commentaires',
  shipping: 'Expédition',
  share: 'Partager',
  showReviews: 'Afficher les commentaires',
  noReviews: '',
  productPrice: 'Prix du produit',
  priceLoading:'Le prix du produit est calculé',
  productRelations :{
    productRelationsHeader: 'Accessoires compatibles',
  },
  disclaimer: {
    title: "Note sur l'utilisation des images :",
    text: "Les images présentées sur ce site Internet ont un caractère illustratif et ne constituent pas une représentation contraignante des caractéristiques réelles du produit ou de l'étendue de la livraison. Les spécifications exactes, les propriétés et l'étendue de la livraison des produits peuvent être trouvées dans la description du produit respectif sous forme de texte."
  }
}

export const productList = {
  filterBy: {
    label: 'Filtrer par',
    action: 'Filtrer par',
  },
  appliedFilter: 'Filtre appliqué :',
  showLess: 'Montrer moins...',
  showMore: 'Montrer plus...',
  sortBy: 'Trier par',
  backToTopBtn: 'RETOUR AU HAUT',
  showMoreBtn: 'AFFICHER PLUS',
  productSearchPagination: 'Pagination de la recherche de produits',
}

export const productFacetNavigation = {
  filterBy: {
    label: 'Filtrer par',
    action: 'Filtrer par',
  },
  appliedFilter: 'Filtre appliqué :',
  showLess: 'Montrer moins...',
  showMore: 'Afficher plus...',
  sortBy: 'Trier par',
}

export const productSummary = {
  id: 'Numéro d\'article',
  showReviews: 'Afficher les avis',
  share: 'Partager',
  newItemPrice: 'Prix du nouvel article',
  discount: 'Les prix affichés incluent votre remise individuelle d\'un montant de {{discount}}.',
  productSubstitutionAndProductExists: 'Le produit {{product1}} est remplacé par le produit {{product2}}, vous êtes donc redirigé vers le produit {{product2}}',
  productSubstitutionAndProductDoesntExist: 'Le produit {{product1}} est remplacé par le produit {{product2}}, mais {{product2}} n\'est pas disponible actuellement.',
}

export const productReview = {
  overallRating: 'Note globale',
  reviewTitle: 'Titre du commentaire',
  writeYourComments: 'Ecrivez vos commentaires',
  rating: 'Note',
  reviewerName: 'Nom de l\'auteur (facultatif)',
  writeReview: 'Écrire un commentaire',
  more: 'Afficher plus de commentaires',
  less: 'Montrer moins de commentaires',
  thankYouForReview: 'Merci pour votre commentaire ! Notez que les commentaires peuvent nécessiter un examen avant d\'apparaître ici.',
}

export const addToCart = {
  itemsAddedToYourCart: 'Article(s) ajouté(s) à votre panier',
  itemsIncrementedInYourCart: 'Cet article était déjà dans votre panier. La quantité a été mise à jour.',
  items: 'articles',
  updatingCart: 'Mise à jour du panier...',
  addToCart: 'Ajouter au panier',
  viewCart: 'Voir le panier',
  proceedToCheckout: 'Passer à la caisse',
  quantity: 'Qté',
  outOfStock: 'En rupture de stock',
  inStock: 'En stock',
  selectStyleAndSize: 'Sélectionnez le style et la taille pour vérifier le stock',
  removeFromCart: 'Retirer le produit du panier',
  closeModal: 'Fermer Modal',
}

export const TabPanelContainer = {
  tabs: {
    ProductDetailsTabComponent: 'Détails du produit',
    ProductSpecsTabComponent: 'Caractéristiques techniques',
    ProductReviewsTabComponent: 'Commentaires',
    deliveryTab: 'Expédition',
    ProductDownloadsTabComponent: 'Téléchargements',
  }
}

export const addToWishList = {
  add: 'Ajouter à la liste de souhaits',
  remove: 'Retirer de la liste de souhaits',
  anonymous: 'S\'identifier pour ajouter à la liste de souhaits',
}

export const itemCounter = {
  removeOne: 'Supprimer un produit',
  addOneMore: 'Ajouter un produit',
  quantity: 'Quantité',
}

export const productView = {
  gridView: 'Sélectionner pour passer à l\'affichage en grille',
  listView: 'Sélectionnez cette option pour passer à l\'affichage en liste',
}

export const standardDelivery = {
  standardDelivery: {
    standardDeliveryRange: 'Prêt à être expédié avec un délai de {{from}} à {{to}}',
  }
}

export const downloads = {
  downloads: {
    noDownloads: 'Aucun téléchargement pour ce produit',
    documentTitle: 'Titre du document',
    originalFileName: 'Nom du fichier',
    languages: 'Langues',
    readableTypeMime: 'Type de Mime',
    fileSize: 'Taille du fichier',
    download: 'Télécharger',
    toolbox: 'Plus dans la boîte à outils',
    allDocuments: 'Tous',
    languagesDropdown: 'Langue:'
  }
}

export const productReferences= {
  productReferences: {
    installationvariants: 'Variantes de mise en place',
    feedingsystems: 'Systèmes de chargement',
    groomingaccessoires: 'Produits d\'entretien',
    cookaccessoire: 'Accessoires de cuisson',
    cookaccessoirespecial: 'Accessoires spéciaux',
    extractorhood: 'Hottes',
    deviceinstallation: 'Installation de l\'appareil',
  }
}

export const productImageZoom = {
  productImageZoomTrigger: {
    expand: 'Agrandir l\'image',
  },
  productImageZoomDialog: {
    close: 'Fermer',
  },
}

export const product = {
  productDetails,
  productList,
  productFacetNavigation,
  productSummary,
  productReview,
  addToCart,
  TabPanelContainer,
  addToWishList,
  itemCounter,
  productView,
}
