<ng-container *ngIf="order$ | async as order">
    <div class="cx-nav row">
        <div *ngIf="order.code != undefined" class="col-xs-12 col-md-6 col-lg-5 col-xl-4">
            <button (click)="showOrderDocuments(order)" class="btn btn-block btn-secondary">
                {{ 'orderDetails.orderDocuments' | cxTranslate }}
            </button>
        </div>
        <div class="col-xs-12 col-md-6 col-lg-5 col-xl-4">
            <button [routerLink]="{ cxRoute: 'orders' } | cxUrl" class="btn btn-block btn-secondary">
                {{ 'common.back' | cxTranslate }}
            </button>
        </div>

        <!-- <div class="col-xs-12 col-md-4 col-lg-3">
            <a *ngIf="order.cancellable" [routerLink]="{cxRoute: 'orderCancel', params: order} | cxUrl" class="btn btn-block btn-secondary">
                {{ 'orderDetails.cancellationAndReturn.cancelAction' | cxTranslate }}
            </a>

            <a *ngIf="order.returnable" [routerLink]="{cxRoute: 'orderReturn',params: order} | cxUrl" class="btn btn-block btn-secondary">
                {{ 'orderDetails.cancellationAndReturn.returnAction' | cxTranslate }}
            </a>
        </div> -->
    </div>

</ng-container>
