export const orderApprovalDetails = {
    back: "Voltar à lista",
    showForm_APPROVE: "Aprovar pedido...",
    showForm_REJECT: "Rejeitar pedido...",
    form: {
        title_APPROVE: "Aprovar pedido {{orderCode}} totalizando {{orderTotal}}",
        title_REJECT: "Rejeitar pedido {{orderCode}} totalizando {{orderTotal}}",
        submit_APPROVE: "Aprovar",
        submit_REJECT: "Rejeitar",
        cancel: "Cancelar",
        comment_APPROVE: {
            label: "Comentário (opcional, máximo de 255 caracteres)",
            placeholder: "Comentário (máximo de 255 caracteres)"
        },
        comment_REJECT: {
            label: "Comentário (máximo de 255 caracteres)",
            placeholder: "Comentário (máximo de 255 caracteres)"
        }
    },
    permissionResults: {
        header: "Detalhes de aprovação do cliente",
        permission: "Permissão",
        approver: "Aprovador",
        status: "Status",
        approverComments: "Comentários do aprovador",
        noApprovalComments: "Nenhum",
        permissionType_B2BBudgetExceededPermission: "O orçamento associado ao centro de custo escolhido foi excedido",
        permissionType_B2BOrderThresholdPermission: "O total do pedido excedeu o limite por pedido",
        permissionType_B2BOrderThresholdTimespanPermission: "O total do pedido excedeu o limite por intervalo de tempo"
    }
};

export const orderApproval = {
    orderApprovalDetails,
};
