import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { CartDetailsComponent } from '@spartacus/cart/base/components';
import { CartConfigService } from '@spartacus/cart/base/core';
import { ActiveCartFacade, Cart, SelectiveCartFacade } from '@spartacus/cart/base/root';
import { SavedCartFacade, SavedCartFormType } from '@spartacus/cart/saved-cart/root';
import { AuthService, GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription, take } from 'rxjs';
import { RationalSavedCartService } from 'src/app/custom/saved-cart/rational-saved-cart.service';

@Component({
  selector: 'cx-cart-details',
  templateUrl: './rational-cart-details.component.html',
  styleUrls: ['./rational-cart-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCartDetailsComponent extends CartDetailsComponent implements AfterViewInit, OnDestroy {

  private entriesSubscription = new Subscription();
   pricesSubjectToChange: boolean = false;


  constructor(
    protected override activeCartService: ActiveCartFacade,
    protected override selectiveCartService: SelectiveCartFacade,
    protected override authService: AuthService,
    protected override routingService: RoutingService,
    protected override cartConfig: CartConfigService,
    protected globalMessageService: GlobalMessageService,
    protected rationalSavedCartService: RationalSavedCartService,
    protected savedCartService: SavedCartFacade,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef
  ) {
    super(
      activeCartService,
      selectiveCartService,
      authService,
      routingService,
      cartConfig
    );

  }

  ngAfterViewInit() {
    this.entriesSubscription = this.activeCartService
      .getEntries()
      .subscribe((entries) => {
        for (const entry of entries) {
          if (entry.product?.isBaseProduct) {
            this.pricesSubjectToChange = true;
            break;
          }
        }
      });
  }

  ngOnDestroy() {
    this.entriesSubscription.unsubscribe();
  }
}
