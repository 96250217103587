import { Component, ViewEncapsulation } from '@angular/core';
import { EventService, GlobalMessageService, GlobalMessageType, RoutingService, TranslationService, WindowRef } from "@spartacus/core";
import { CurrentProductService, ProductIntroComponent } from "@spartacus/storefront";

@Component({
  selector: 'app-rational-product-intro-component',
  templateUrl: './rational-product-intro.component.html',
  styleUrls: ['./rational-product-intro.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RationalProductIntroComponent extends ProductIntroComponent {

  constructor(
    protected override currentProductService: CurrentProductService,
    protected override translationService: TranslationService,
    protected override winRef: WindowRef,
    protected override eventService: EventService,
    protected routingService: RoutingService,
    protected globalMessageService: GlobalMessageService
  ) {
    super(currentProductService, translationService, winRef, eventService);
  }


}
