import {NgModule} from '@angular/core';
import {CmsConfig, provideConfig} from "@spartacus/core";
import {SMART_EDIT_FEATURE, SmartEditConfig, SmartEditRootModule} from "@spartacus/smartedit/root";
import {SmartEditCoreModule} from "@spartacus/smartedit/core";

@NgModule({
  declarations: [],
  imports: [
    SmartEditRootModule,
    SmartEditCoreModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [SMART_EDIT_FEATURE]: {
          module: () =>
            import('@spartacus/smartedit').then((m) => m.SmartEditModule),
        },
      }
    }),
    provideConfig(<SmartEditConfig>{
        smartEdit: {
          storefrontPreviewRoute: 'cx-preview',
          allowOrigin: 'localhost:9002, backoffice.cmwelixd5h-rationala2-d1-public.model-t.cc.commerce.ondemand.com:443, backoffice.cmwelixd5h-rationala2-s1-public.model-t.cc.commerce.ondemand.com:443, backoffice.cmwelixd5h-rationala2-p1-public.model-t.cc.commerce.ondemand.com:443',
        }
      }
    )
  ]
})
export class SmartEditFeatureModule { }
