import { Component, ViewEncapsulation } from '@angular/core';
import { AddToWishListComponent } from '@spartacus/cart/wish-list/components/add-to-wishlist';


@Component({
  selector: 'cx-add-to-wish-list',
  templateUrl: './rational-add-to-wish-list.component.html',
  styleUrls: ['./rational-add-to-wish-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalAddToWishListComponent extends AddToWishListComponent {

}
