import { Component, OnInit } from '@angular/core';
import {combineLatest, Observable} from "rxjs";
import {filter, map, switchMap} from "rxjs/operators";
import {CmsComponentData} from "@spartacus/storefront";
import {CmsService, isNotNullable} from "@spartacus/core";

@Component({
  selector: 'custom-rationalhomepagetilecontainer',
  templateUrl: './rational-homepagetile-container.component.html',
  styleUrls: ['./rational-homepagetile-container.component.scss']
})
export class RationalHomepagetileContainerComponent implements OnInit {

  components$: Observable<any> = this.componentData.data$.pipe(
    switchMap(data =>
      combineLatest(
        data.singleTilesList.split(' ').map((component: any ) =>
          this.cmsService.getComponentData<any>(component).pipe(
            map(tile => {
              return {
                ...tile,
              };
            })
          )
        )
      )
    )
  );

  constructor(public componentData: CmsComponentData<any>,
              private cmsService: CmsService) { }

  ngOnInit(): void {
    // empty OnInit method
  }

}
