import { CartAdapter, CartConnector } from "@spartacus/cart/base/core";
import { Cart } from "@spartacus/cart/base/root";
import { Observable } from "rxjs";
import { RationalCartAdapter } from "../adapter/rational-cart.adapter";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class RationalCartConnector extends CartConnector {

    constructor(adapter: RationalCartAdapter,
    private rationalCartAdapter : RationalCartAdapter
    ) {
        super(adapter);
    }

    public override save(
        userId: string,
        cartId: string,
        saveCartName?: string,
        saveCartDescription?: string,
        isSharedWithB2BUnit?: boolean
      ): Observable<Cart> {
        return this.rationalCartAdapter.saveCart(userId, cartId, saveCartName!, saveCartDescription!, isSharedWithB2BUnit!);
      }

}