import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { Address } from '@spartacus/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { rationaOCCEndpoints } from '../../rational-backend-occ-endpoint/rational-backend-occ-endpoint.module';

export interface CheckoutDeliveryAddresses {
  addresses: Address[];
}

@Injectable({
  providedIn: 'root',
})
export class CheckoutDeliveryAddressesService {
  private activeCartId?: string;
  private userId?: string;

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected activeCartFacade: ActiveCartFacade,
    protected userIdService: UserIdService,
  ) {

    this.activeCartFacade.getActiveCartId().subscribe((response) => {
      this.activeCartId = response ?? '';
    })
    this.userIdService.takeUserId().subscribe((response) => {
      this.userId = response ?? '';
    })
  }

  getDeliveryAddresses(): Observable<Address[]> {
    return this.http.get<Address[]>(this.getDeliveryAddressesUrl());

  }

  searchForDeliveryAddresses(searchText: string): Observable<Address[]> {
    return this.http.get<Address[]>(this.getSearchDeliveryAddressesUrl(searchText));
  }

  protected getDeliveryAddressesUrl() {
    const userId = this.userId;
    const cartId = this.activeCartId;
    const url = this.occEndpoints.buildUrl(rationaOCCEndpoints.rationalCheckout.deliveryAddresses,
      { urlParams: { userId, cartId } });
    return url;

  }

  protected getSearchDeliveryAddressesUrl(searchTerm: string) {
    return this.occEndpoints.buildUrl(rationaOCCEndpoints.rationalCheckout.searchSalesforceAddresses,
      { urlParams: { searchTerm } }
    );
  }

}
