import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';


@Injectable({
  providedIn: 'root',
})
export class RationalImportXlsxFileService {


  /**
   * Load Xlsx file and return data as 2D array
   *
   * @param file File we want to load as Xlsx.
   * @return {Observable<string[][]>} Imported file
   */
  loadFile(file: File): Observable<string[][]> {
    return this.loadTextFile(file)
      .pipe(map((res) => this.parse(res as string)));
  }

  parse(file: any): string[][] {
    const workBook = XLSX.read(file, { type: 'binary' });
    const jsonData = workBook.SheetNames.reduce((initial: any, name) => {
      const sheet = workBook.Sheets[name];
      initial[name] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      return initial;
    }, {});

    const data: string[][] = [];
    const array: any[] = Object.values(jsonData)[0] as any[];
    array.forEach((element, i) => {
      data[i] = [];
      data[i][0] = Object.values(element)[0] + '';
      data[i][1] = Object.values(element)[1] + '';
    });
    return data;
  }

  loadTextFile(file: File): Observable<string | ProgressEvent<FileReader>> {
    return new Observable((observer: Observer<string>) => {
      const fileReader: FileReader = new FileReader();
      fileReader.readAsBinaryString(file)
      fileReader.onload = () => {

        observer.next(fileReader.result as string);
        observer.complete();
      };
      fileReader.onerror = (error) => {
        fileReader.abort();
        observer.error(error);
      };
    });
  }
}
