<button
  class="cx-hamburger"
  type="button"
  (click)="toggle()"
  [class.is-active]="isExpanded | async"
  [attr.aria-expanded]="isExpanded | async"
  [attr.aria-label]="'common.menu' | cxTranslate"
  aria-controls="header-account-container, header-categories-container, header-locale-container"
>
  <span class="icon-raticons icon-sn_burger fs20" style="font-size: 20px;">
  </span>
</button>
