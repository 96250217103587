import { NgModule } from '@angular/core';
import { ProductDescriptionComponent } from './product-description.component';
import { IconModule } from '@spartacus/storefront';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [ProductDescriptionComponent],
  exports: [
    ProductDescriptionComponent
  ],
  imports: [
    CommonModule,
    IconModule
  ]
})
export class ProductDescriptionModule { }
