export const paymentForm = {
  payment: 'Pago',
  choosePaymentMethod: 'Elija un método de pago',
  paymentType: 'Tipo de pago',
  accountHolderName: {
    label: 'Nombre del titular de la cuenta',
    placeholder: 'Nombre del titular de la cuenta',
  },
  cardNumber: 'Número de tarjeta',
  expirationDate: 'Fecha de caducidad',
  securityCode: 'Código de seguridad (CVV)',
  securityCodeTitle: 'Valor de verificación de la tarjeta',
  saveAsDefault: 'Guardar como predeterminado',
  setAsDefault: 'Establecer como predeterminado',
  billingAddress: 'Dirección de facturación',
  sameAsShippingAddress: 'La misma que la dirección de envío',
  selectOne: 'Seleccione una...',
  monthMask: 'MM',
  yearMask: 'AAAA',
  expirationYear: 'Año de caducidad {{ selected }}',
  expirationMonth: 'Mes de caducidad {{ selected }}',
  useThisPayment: 'Utilizar este pago',
  addNewPayment: 'Añadir nuevo pago',
  changePayment: 'Modificar pago',
}

export const paymentMethods = {
  paymentMethods: 'Métodos de pago',
  newPaymentMethodsAreAddedDuringCheckout: 'Los nuevos métodos de pago se añaden durante el proceso de pago.',
  invalidField: 'Campo no válido: {{ field }}',
}

export const paymentCard = {
  deleteConfirmation: '¿Está seguro de que desea eliminar este método de pago?',
  setAsDefault: 'Establecer como predeterminado',
  expires: 'Caduca: {{ month }}/{{ year }}',
  defaultPaymentMethod: '✓ POR DEFECTO',
  selected: 'Seleccione',
}

export const paymentTypes = {
  title: 'Forma de pago',
  paymentType_CARD: 'Tarjeta de crédito',
  paymentType_ACCOUNT: 'Cuenta',
  paymentType_INVOICE: 'Factura',
}

export const payment = {
  paymentForm,
  paymentMethods,
  paymentCard,
  paymentTypes
}

