import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CartProceedToCheckoutComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RationalCart } from 'src/app/custom/model/rational-cart.model';
import { RationalOrderSummaryService } from '../order-summary/rational-order-summary.service';

@Component({
  selector: 'cx-cart-proceed-to-checkout',
  templateUrl: './rational-cart-proceed-to-checkout.component.html',
  styleUrls: ['./rational-cart-proceed-to-checkout.component.scss'],
})
export class RationalCartProceedToCheckoutComponent extends CartProceedToCheckoutComponent {
  disableProceedToCheckout = true;
  private proceedToCheckoutValidationSubscription = new Subscription();
  private orderLock: boolean = false;
  private requestedRetrievalAtValid: boolean = false;
  constructor(
    router: Router,
    private activeCartService: ActiveCartFacade,
    private globalMessageService: GlobalMessageService,
    private rationalOrderSummaryService: RationalOrderSummaryService,
    override cd: ChangeDetectorRef
  ) {
    super(router);

    this.proceedToCheckoutValidationSubscription = this.activeCartService
      .getActive()
      .pipe(map((cart) => cart as RationalCart))
      .subscribe((response) => {
        if (response.orderLock) {
          this.orderLock = true;
          this.globalMessageService.add(
            { key: 'cartDetails.orderLocked' },
            GlobalMessageType.MSG_TYPE_WARNING
          );
        }

        if (!response.requestedRetrievalAt) {
          this.requestedRetrievalAtValid = false;
          this.globalMessageService.add(
            { key: 'cartDetails.requestedRetrievalDateEmpty' },
            GlobalMessageType.MSG_TYPE_INFO
          );

          this.evaluateProceedToCheckout();
        } else {
          this.rationalOrderSummaryService
            .checkDeliveryDateIsValid()
            .subscribe((isValid: boolean) => {
              if (!isValid) {
                this.requestedRetrievalAtValid = false;
                this.globalMessageService.add(
                  { key: 'cartDetails.requestedRetrievalDateInvalid' },
                  GlobalMessageType.MSG_TYPE_ERROR
                );
              } else {
                this.requestedRetrievalAtValid = true;
                this.globalMessageService.add(
                  { key: 'cartDetails.requestedRetrievalDateAdded' },
                  GlobalMessageType.MSG_TYPE_CONFIRMATION
                );

                this.globalMessageService.remove(
                  GlobalMessageType.MSG_TYPE_INFO
                );
                
                this.globalMessageService.remove(
                  GlobalMessageType.MSG_TYPE_ERROR
                );
              }

              this.evaluateProceedToCheckout();
            });
        }
      });
  }

  private evaluateProceedToCheckout() {
    this.disableProceedToCheckout =this.orderLock || !this.requestedRetrievalAtValid;
    this.cd.detectChanges();
  }

  override ngOnDestroy(): void {
    this.proceedToCheckoutValidationSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  formatResponseDate(dateString: String): Date {
    const [year, month, day] = dateString.split('-');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  formatEarliestDate(dateString: String): Date {
    const [day, month, year] = dateString.split('.');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }
}
