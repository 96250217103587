export const orderDetails = {
  statusDisplay_open: 'Non ancora elaborato',
  statusDisplay_processing: 'Parzialmente elaborato',
  statusDisplay_completed: 'Completamente elaborato',
  purchaseOrderNumber: 'Il vostro riferimento d\'ordine',
  orderNumber: 'Numero d\'ordine Webshop',
  methodOfPayment: 'Metodo di pagamento',
  shippingMethod: 'Metodo di spedizione',
  placedOn: 'Ordinato il',
  moreInformation: 'Altre informazioni',
  orderDocuments: 'Documenti dell\'ordine',
  billing: 'Fatturazione',
  invoiceNumber: 'Numero di fattura',
  invoiceDate: 'Data',
  netAmount: 'Importo netto',
  noBillingInvoices: 'Non sono ancora disponibili informazioni sulla fatturazione.',
  noOrderDocuments: 'Non sono ancora disponibili documenti relativi all\'ordine.',
  noInvoiceDocuments:'Non sono ancora disponibili documenti di fatturazione.',
  noShipmentDocuments: 'Non sono ancora disponibili documenti relativi alla spedizione.',
  shipping: {
    shipping: 'Spedizione',
    shippingId: 'ID spedizione',
    deliveryDate: 'Data di spedizione/ritiro',
    quantity: 'Quantità',
    tracking: 'Tracking',
    noShippingInfo: 'Non sono ancora disponibili informazioni sulla spedizione.',
    noTrackingInfo: 'Non sono ancora disponibili informazioni sul tracking.'
  },
};

export const orderHistory = {
  orderHistory: 'Storico ordini',
  orderId: 'Numero d\'ordine',
  date: 'Data',
  status: 'Stato dell\'ordine',
  PONumber: 'Vostro riferimento d\'ordine',
  total: 'Totale',
  noOrders: 'Non abbiamo ancora uno storico ordini per questo account.',
  startShopping: 'Iniziare gli acquisti',
  sortBy: 'Ordinare per',
  sortOrders: 'Ordinare ordini per',
  purchaseOrderNumber: 'Vostro riferimento d\'ordine',
  notFound: 'Nessun ordine trovato',
  actions: 'Azioni',
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'TUTTI GLI ORDINI ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
