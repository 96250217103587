export const paymentForm = {
    payment: "Pagamento",
    choosePaymentMethod: "Escolha um método de pagamento",
    paymentType: "Tipo de pagamento",
    accountHolderName: {
        label: "Nome do titular da conta",
        placeholder: "Nome do titular da conta"
    },
    cardNumber: "Número do cartão",
    expirationDate: "Data de validade",
    securityCode: "Código de segurança (CVV)",
    securityCodeTitle: "Valor de verificação do cartão",
    saveAsDefault: "Salvar como padrão",
    setAsDefault: "Definir como padrão",
    billingAddress: "Endereço de cobrança",
    sameAsShippingAddress: "Igual ao endereço de envio",
    selectOne: "Selecione um...",
    monthMask: "MM",
    yearMask: "YYYY",
    expirationYear: "Ano de expiração {{ selected }}",
    expirationMonth: "Mês de vencimento {{ selected }}",
    useThisPayment: "Usar esse pagamento",
    addNewPayment: "Adicionar novo pagamento",
    changePayment: "Alterar pagamento"
};

export const paymentMethods = {
    paymentMethods: "Métodos de pagamento",
    newPaymentMethodsAreAddedDuringCheckout: "Novos métodos de pagamento são adicionados durante o checkout.",
    invalidField: "InvalidField: {{ field }}"
};

export const paymentCard = {
    deleteConfirmation: "Tem certeza de que deseja excluir esse método de pagamento?",
    setAsDefault: "Definir como padrão",
    expires: "Expires (Expira): {{ month }}/{{ year }}",
    defaultPaymentMethod: "✓ PADRÃO",
    selected: "Selecionado"
};

export const paymentTypes = {
    title: "Método de pagamento",
    paymentType_CARD: "Cartão de crédito",
    paymentType_ACCOUNT: "Conta bancária",
    paymentType_INVOICE: "Fatura"
};

export const payment = {
    paymentForm,
    paymentMethods,
    paymentCard,
    paymentTypes
};
