<ng-container *ngIf="product$ | async as product; else ghostProductTitle">
    <div class="cx-general-product-info">
      <div class="cx-title">
        <span> {{ product.name }} </span>
      </div>
    </div>
  </ng-container>
  <ng-template #ghostProductTitle>
    <div class="cx-ghost-general-product-info"></div>
  </ng-template>
  