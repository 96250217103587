import { Component, ViewEncapsulation } from '@angular/core';
import {
  RoutingService,
  GlobalMessageService,
  GlobalMessageType,
} from '@spartacus/core';
import { OrderHistoryFacade } from '@spartacus/order/root';
import {
  CommonConfiguratorUtilsService,
  ConfiguratorRouter,
  ConfiguratorRouterExtractorService,
} from '@spartacus/product-configurator/common';
import {
  Configurator,
  ConfiguratorAddToCartButtonComponent,
  ConfiguratorCartService,
  ConfiguratorCommonsService,
  ConfiguratorGroupsService,
  ConfiguratorQuantityService,
  ConfiguratorStorefrontUtilsService,
  RulebasedConfiguratorConnector,
} from '@spartacus/product-configurator/rulebased';
import { IntersectionService } from '@spartacus/storefront';
import { RationalVariant } from '../../model/rational.product-configurator.model';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { Router } from '@angular/router';

@Component({
  selector: 'cx-configurator-add-to-cart-button',
  templateUrl: './rational-configurator-add-to-cart-button.component.html',
  styleUrl: './rational-configurator-add-to-cart-button.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RationalConfiguratorAddToCartButtonComponent extends ConfiguratorAddToCartButtonComponent {
  constructor(
    protected override routingService: RoutingService,
    protected override configuratorCommonsService: ConfiguratorCommonsService,
    protected override configuratorCartService: ConfiguratorCartService,
    protected override configuratorGroupsService: ConfiguratorGroupsService,
    protected override configRouterExtractorService: ConfiguratorRouterExtractorService,
    protected override globalMessageService: GlobalMessageService,
    protected override orderHistoryFacade: OrderHistoryFacade,
    protected override commonConfiguratorUtilsService: CommonConfiguratorUtilsService,
    protected override configUtils: ConfiguratorStorefrontUtilsService,
    protected override intersectionService: IntersectionService,
    protected override configuratorQuantityService: ConfiguratorQuantityService,
    protected configuratorCommonsConnector: RulebasedConfiguratorConnector,
    protected activeCartService: ActiveCartFacade,
    protected router: Router
  ) {
    super(
      routingService,
      configuratorCommonsService,
      configuratorCartService,
      configuratorGroupsService,
      configRouterExtractorService,
      globalMessageService,
      orderHistoryFacade,
      commonConfiguratorUtilsService,
      configUtils,
      intersectionService,
      configuratorQuantityService
    );
  }

  disableAddToCart(configuration: Configurator.Configuration): boolean {
    if (
      configuration.totalNumberOfIssues &&
      configuration.totalNumberOfIssues > 0
    ) {
      return true;
    }

    return false;
  }

  override onAddToCart(
    configuration: Configurator.Configuration,
    routerData: ConfiguratorRouter.Data
  ): void {
    this.configuratorCommonsConnector
      .searchVariants(configuration)
      .subscribe((variants: RationalVariant[]) => {
        if (!variants || variants.length == 0) {
          super.onAddToCart(configuration, routerData);
          return;
        }

        if (variants.length == 1) {
          this.activeCartService.addEntry(variants[0].productCode, this.quantityControl.value);
          
          this.globalMessageService.add(
            {
              key: 'configurator.addToCart.addMatchingVariant',
              params: {
                productCode: variants[0].productCode,
              },
            },
            GlobalMessageType.MSG_TYPE_INFO,
            5000
          );

          this.router.navigateByUrl('/cart');
          return;
        }

        this.globalMessageService.add(
          { key: 'configurator.addToCart.selectVariant' },
          GlobalMessageType.MSG_TYPE_ERROR,
          5000
        );
      });
  }
}
