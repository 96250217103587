import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { OccEndpoints } from "@spartacus/core";


export interface RationaOCCEndpoints extends OccEndpoints {
  rationalCheckout: {
    deliveryAddresses: string;
    searchSalesforceAddresses : string;
  };
  orderManagmentDetails: {
    getOrderItemShippingItems: string;
  },
  orderDocuments: {
    getDocumentsList: string;
    downloadDocument : string;
  };
  orderTrackingDocuments: {
    getTrackingDocuments: string;
  };
  listPrice: {
    getListPrice: string;
    updateListPrice: string;
  };

  sharedCart: {
    getSharedCart: string,
    saveSharedCart: string,
    updateReferenceFromSavedCart: string
  };

  cartDeliveryDate: {
    requestedDeliveryDate: string,
    requestedRetrievalDate: string,
    earliestDeliveryDate: string,
    holidayDates: string
  }
}

export const rationaOCCEndpoints: RationaOCCEndpoints = {
  // Override endpoints
  product: {
    default:
      'products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType,salesUnit,substitutionProductCode,substitutionProductExists',
    list: 'products/${productCode}?fields=code,name,summary,images(DEFAULT,galleryIndex),baseProduct,salesUnit,description,isBaseProduct',
    details:
      'products/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,numberOfReviews,manufacturer,categories(FULL),multidimensional,tags,images(FULL),salesUnit,substitutionProductCode,substitutionProductExists,isBaseProduct',
    attributes: 'products/${productCode}?fields=classifications',
    price: 'products/${productCode}?fields=price(formattedValue)',
    stock: 'products/${productCode}?fields=stock(DEFAULT)'
  },
  productSearch:
          'products/search?fields=products(code,name,summary,description,configurable,configuratorType,isBaseProduct,multidimensional,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
        /* eslint-enable */
    productSuggestions: 'products/suggestions',
  createDeliveryAddress: 'orgUsers/${userId}/carts/${cartId}/addresses/delivery',
  setDeliveryAddress: 'orgUsers/${userId}/carts/${cartId}/addresses/delivery',
  // custom endpoints
  rationalCheckout: {
    deliveryAddresses: 'orgUsers/${userId}/carts/${cartId}/checkoutAddresses',
    searchSalesforceAddresses: 'searchCheckoutAddresses/${searchTerm}'
  },
  orderManagmentDetails: {
    getOrderItemShippingItems: 'users/${userId}/orders/${orderId}/item/${orderItem}/shipping',
  },
  orderDocuments: {
    getDocumentsList: 'documents/${documentId}/${categoryType}',
    downloadDocument: 'documents/${documentId}/${categoryType}/${runningNumber}'
  },
  orderTrackingDocuments: {
    getTrackingDocuments: 'users/${userId}/orders/shipping/${shippingNumber}/tracking'
  },
  listPrice: {
    getListPrice: '/listPriceOptions',
    updateListPrice: '/listPrice/${isActivated}'
  },
  sharedCart: {
    getSharedCart: 'getSharedCart',
    saveSharedCart: '/users/${userId}/carts/${cartId}/save?saveCartName=${saveCartName}&saveCartDescription=${saveCartDescription}&isSharedWithB2BUnit=${isSharedWithB2BUnit}',
    updateReferenceFromSavedCart: '/carts/${cartId}/updateReferenceFromSavedCart',
  },
   cartDeliveryDate:{
     requestedDeliveryDate: 'users/${userId}/carts/${cartId}/requesteddeliverydate?requestedRetrievalAt=${requestedRetrievalDate}',
     requestedRetrievalDate: 'users/${userId}/carts/${cartId}/requestedretrievaldate?',
     earliestDeliveryDate: 'users/${userId}/carts/${cartId}/earliestDeliveryDate',
     holidayDates: 'users/${userId}/carts/${cartId}/holidayDates'
   }
};

@NgModule({
  declarations: [],
  imports: [
    ConfigModule.withConfig({
      backend: {
        occ: {
          endpoints: rationaOCCEndpoints
        }
      }
    })
  ],
})
export class RationalBackendOccEndPointModule { }
