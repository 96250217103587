import { Injectable } from "@angular/core";
import { ActiveCartService } from "@spartacus/cart/base/core";


@Injectable()
export class RationalActiveCartService extends ActiveCartService {


  protected override loadOrMerge(
    cartId: string,
    userId: string,
    previousUserId: string
  ): void {
    this.load(cartId, userId);
  }


  protected override load(cartId: string, userId: string): void {
    if(!this.isItContextSwitchingUrl()) {
      super.load(cartId, userId);
    }
  }

  isItContextSwitchingUrl(): boolean | undefined {
    return this.winRef.nativeWindow?.location.href.includes('/context');
  }
}