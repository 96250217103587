<div class="container-fluid hidden-xs submenu_container open">
  <div class="header-container">
    <div class="container">
      <div class="sub-container">
        <div class="menu-col menu-col-1">
          <ul *ngIf="linkNode.children">
            <li *ngFor="let child of linkNode.children">
              <cx-generic-link *ngIf="child.url" [url]="child.url" [target]="child.target">
                {{ child.title }}
                <i class="icon-raticons icon-arrow_right" *ngIf="child.children && child.children.length"></i>
              </cx-generic-link>
            </li>
          </ul>
        </div>
        <div class="menu-col menu-col-2">

        </div>
        <div class="menu-col menu-col-3">

        </div>
      </div>
    </div>
  </div>
  <div class="close_menu_button">
    <div class="shadow-repair">

    </div>
    <i class="icon-raticons icon-close"></i>
  </div>
</div>
