import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CmsService, Config, ProductImageNormalizer} from '@spartacus/core';
import {CmsComponentData, MediaService} from "@spartacus/storefront";
import {combineLatest, Observable} from "rxjs";
import {first, map, switchMap} from "rxjs/operators";

@Component({
  selector: 'app-rationalhomepagetile',
  templateUrl: './rational-homepagetile.component.html',
  styleUrls: ['./rational-homepagetile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalHomepagetileComponent implements OnInit {

  data$: Observable<any> = this.componentData.data$;

  constructor(public componentData: CmsComponentData<any>,
              protected config: Config) { }


  resolveAbsoluteUrl(url: string): string {
    return !url || url.startsWith('http') || url.startsWith('//')
      ? url
      : this.getBaseUrl() + url;
  }

  protected getBaseUrl(): string {
    return (
      this.config.backend?.media?.baseUrl ??
      this.config.backend?.occ?.baseUrl ??
      ''
    );
  }

  ngOnInit(): void {
    // empty OnInit method
  }

}
