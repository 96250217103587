import { Component, OnInit } from '@angular/core';
import { OutletPosition } from '@spartacus/storefront';

@Component({
  selector: 'custom-empty-cart-page-outlet',
  templateUrl: './empty-cart-page.component.html',
  styleUrls: ['./empty-cart-page.component.scss']
})
export class EmptyCartPageComponent implements OnInit {

  outletPosition = OutletPosition;

  constructor() {
    // empty constructor method
   }

  ngOnInit(): void {
    // empty OnInit method
  }

}
