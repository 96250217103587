import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import {CartOutlets } from "@spartacus/cart/base/root";
import { OrderDetailsService } from "@spartacus/order/components";
import { Order } from "@spartacus/order/root";
import { Observable } from "rxjs";


@Component({
    selector: 'rational-confirmation-order-summary',
    templateUrl: './rational-confirmation-order-summary.component.html',
    styleUrls: ['./rational-confirmation-order-summary.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
export class RationalConfirmationOrderSummaryComponent{

  @Input() infoText: string | undefined;

    
  order$: Observable<Order>;
  protected sanitizer = inject(DomSanitizer);

  readonly cartOutlets = CartOutlets;

  constructor(protected orderDetailsService: OrderDetailsService) {
    this.order$ = this.orderDetailsService.getOrderDetails();
  }


  public bypassSecurityTrustHtml(html: string = ''): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }



}