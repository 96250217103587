export const checkoutAddress = {
  shippingAddress: 'Indirizzo di spedizione',
  selectYourShippingAddress: 'Selezionare l\'indirizzo di spedizione:',
  selectYourDeliveryAddress: 'Selezionare l\'indirizzo di consegna:',
  defaultShippingAddress: 'Indirizzo di spedizione predefinito',
  addNewAddress: 'Aggiungere un nuovo indirizzo',
  shipToThisAddress: 'Spedire a questo indirizzo',
  addressSearch: 'Ricercare indirizzo',
  addressSearchMinLength: 'Il testo della ricerca deve essere di almeno {{length}} caratteri',
  submitAddressSearch: 'Invio',
  errorSearchingSalesforce: 'Al momento non è possibile completare la ricerca, riprovare più tardi',
  deliveryAddressSelected: 'Indirizzo di consegna selezionato'
}

export const checkoutOrderConfirmation = {
  confirmationOfOrder: 'Conferma d\'ordine:',
  confirmationOfQuote: 'Conferma della ricezione dell\'offerta:',
  thankYou: 'Grazie per l\'ordine!',
  thankYouQuote: 'Grazie per la richiesta di offerta!',
  invoiceHasBeenSentByEmailLine1: 'Si prega di notare che potrebbe essere necessario un giorno lavorativo prima che l\'ordine venga visualizzato nello storico ordini. Una volta visibile, il documento conterrà la conferma d\'ordine, la data di spedizione/ritiro confermata da RATIONAL e',
  invoiceHasBeenSentByEmailLine2: ' le informazioni complete sui prezzi, comprese le tasse applicate. Per eventuali domande su questo ordine, contattate il vostro servizio clienti RATIONAL e fate riferimento all\'ID dell\'ordine sopra indicato.  ',
  quoteHasBeenSentByEmailLine1:'Si prega di notare che le richieste di offerte non vengono trasferite nello storico ordini nel webshop. Per eventuali domande su questa richiesta di offerta, si prega di contattare il servizio clienti RATIONAL',
  quoteHasBeenSentByEmailLine2:'facendo riferimento al numero di offerta del webshop indicato sopra o al vostro riferimento d\'ordine.',
  orderItems: 'Articoli dell\'ordine',
  orderPlacedSuccessfully: 'Ordine effettuato con successo',
  createAccount: 'Creare un account?',
  createAccountForNext: 'Cree una cuenta para <{{email}}> para agilizar el proceso de compra en su próxima visita.'
}

export const checkoutReview = {
  review: 'Riepilogo dell\'ordine',
  orderItems: 'Articoli dell\'ordine',
  autoReplenishOrder: 'Acquisto periodico automatico',
  confirmThatRead: 'Confermo di aver letto e accettato le condizioni di vendita di',
  placeOrder: 'Effettuare l\'ordine',
  placeQuoteOrder: 'Richiedere un\'offerta',
  scheduleReplenishmentOrder: 'Programmare l\'acquisto periodico automatico',
  orderType_PLACE_ORDER: 'Nessuno (acquisto singolo)',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Acquisto periodico automatico',
  termsAndConditions: 'Termini e condizioni',
  editShippingAddress: 'Modificare l\'indirizzo di spedizione',
  poNumber: 'Numero d\'ordine di acquisto',
  every: 'Tutti',
  startOn: 'Inizia il',
  dayOfMonth: 'Il giorno',
  repeatOnDays: 'Ripetere nei seguenti giorni ',
  recurrencePeriodType_DAILY: 'Giorno(i)',
  recurrencePeriodType_WEEKLY: 'Settimana(e)',
  recurrencePeriodType_MONTHLY: 'Mese'
}

export const checkoutShipping = {
  shippingMethod: 'Modalità di spedizione',
  standardDelivery: 'Consegna Standard',
  premiumDelivery: 'Consegna Standard'
}

export const checkoutPO = {
  noPoNumber: 'Nessuno',
  poNumber: 'Il vostro riferimento d\'ordine',
  costCenter: 'Centro di costo',
  placeholder: 'Inserire il vostro riferimento d\'ordine...',
  availableLabel: 'Gli indirizzi di spedizione per te disponibili dipendono dall\'unità del centro di costo.'
}

export const checkoutProgress = {
  methodOfPayment: 'Metodo di pagamento',
  deliveryAddress: 'Indirizzo di spedizione',
  deliveryMode: 'Modalità di consegna',
  paymentDetails: 'Dettagli del pagamento',
  reviewOrder: 'Riepilogo dell\'ordine'
}

export const checkoutB2B = {
  checkoutB2B: {
    poNumber: 'Il vostro riferimento d\'ordine*',
    placeholder: 'Inserire il vostro riferimento d\'ordine...',
    costCenter: 'Centro di costo',
    availableLabel: 'Gli indirizzi di spedizione per te disponibili dipendono dall\'unità del centro di costo.',
    methodOfPayment: {
      paymentType: 'Metodo di pagamento'
    },
    progress: {
      methodOfPayment: 'Metodo di pagamento'
    },
    review: {
      poNumber: 'Il vostro riferimento d\'ordine'
    }
  }
}

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: 'Incoterms',
    paymentConditions: 'Condizioni di pagamento'
  }
}

export const checkoutMode = {
  deliveryMethod: 'Modalità di consegna',
  deliveryEntries: 'Articoli da spedire',
  specialShippingInstructions: 'Istruzioni speciali per la spedizione',
  specialShippingInstructionsPlaceholder: 'Istruzioni speciali per la spedizione'
}

export const checkout = {
  checkoutAddress,
  checkoutOrderConfirmation,
  checkoutReview,
  checkoutShipping,
  checkoutPO,
  checkoutProgress,
  checkout: {
    backToCart: 'Tornare al carrello',
    invalid: {
      accountType: 'Il tuo account non dispone delle autorizzazioni per effettuare il check-out.'
    }
  },
  checkoutMode
}
