import { Component, ViewEncapsulation } from '@angular/core';
import { CartItemComponent, CartItemContextSource } from '@spartacus/cart/base/components';
import { CartItemContext } from '@spartacus/cart/base/root';

@Component({
  selector: 'rational-cart-item',
  templateUrl: './rational-cart-item.component.html',
  styleUrls: ['./rational-cart-item.component.scss'],
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
  encapsulation: ViewEncapsulation.None
})
export class RationalCartItemComponent extends CartItemComponent{

}
