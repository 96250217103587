export const paymentForm = {
    payment: "Platba",
    choosePaymentMethod: "Vyberte způsob platby",
    paymentType: "Typ platby",
    accountHolderName: {
        label: "Jméno majitele účtu",
        placeholder: "Jméno majitele účtu"
    },
    cardNumber: "Číslo karty",
    expirationDate: "Datum vypršení platnosti",
    securityCode: "Bezpečnostní kód (CVV)",
    securityCodeTitle: "Hodnota ověření karty",
    saveAsDefault: "Uložit jako výchozí",
    setAsDefault: "Nastavit jako výchozí",
    billingAddress: "Fakturační adresa",
    sameAsShippingAddress: "Stejná jako dodací adresa",
    selectOne: "Vyberte jednu...",
    monthMask: "MM",
    yearMask: "RRRR",
    expirationYear: "Rok vypršení platnosti {{ selected }}",
    expirationMonth: "Měsíc platnosti {{ selected }}",
    useThisPayment: "Použijte tuto platbu",
    addNewPayment: "Přidat novou platbu",
    changePayment: "Změnit platbu"
};

export const paymentMethods = {
    paymentMethods: "Způsoby platby",
    newPaymentMethodsAreAddedDuringCheckout: "Nové platební metody se přidávají během objednávky.",
    invalidField: "InvalidField: {{ field }}"
};

export const paymentCard = {
    deleteConfirmation: "Opravdu chcete tuto platební metodu odstranit?",
    setAsDefault: "Nastavit jako výchozí",
    expires: "Vyprší platnost: {{ month }}/{{ year }}",
    defaultPaymentMethod: "✓ DEFAULT",
    selected: "Vybrané"
};

export const paymentTypes = {
    title: "Způsob platby",
    paymentType_CARD: "Kreditní karta",
    paymentType_ACCOUNT: "Účet",
    paymentType_INVOICE: "Faktura"
};

export const payment = {
    paymentForm,
    paymentMethods,
    paymentCard,
    paymentTypes
};
