export const savedCartDetails = {
    cartName: "İsim",
    cartDescription: "Açıklama",
    cartId: "KIMLIK",
    dateSaved: "Kaydedilen Tarih",
    items: "Eşyalar",
    quantity: "Miktar",
    total: "Toplam",
    editSavedCart: "Kayıtlı Sepeti Düzenle",
    deleteSavedCart: "Kayıtlı Sepeti Sil",
    addSelectedItems: "Seçili öğeleri ekle",
    emptyCartItems: "Boş Sepet Öğeleri",
    addItemsToCart: "Kaydedilen öğeleri ekle",
    selectAll: "Tümünü Seçin",
    quickAddToCart: "Rychle přidat do uloženého košíku",
    productAddedToCart: "{{product}} byl přidán do uloženého košíku",
    selectedItemsAddedToCart: "Seçilen ürünler sepete başarıyla eklendi.",
    selectedItemsAreEmpty: "Sepete eklemek için en az bir ürün seçmelisiniz.",
};

export const savedCartList = {
    savedCarts: "Kaydedilen Sepetler ({{count}})",
    cartName: "İsim",
    cartId: "Kimlik",
    dateSaved: "Kaydedilen Tarih",
    cartDescription: "Açıklama",
    quantity: "Miktar",
    total: "Toplam",
    actions: "Eylemler",
    makeCartActive: "Sepeti aktif hale getir",
    notFound: "Hiçbir kaydedilen sepet bulunamadı",
    swapCartNoActiveCart: "Mevcut sepet başarıyla aktif hale getirildi.",
    swapCartWithActiveCart: "Mevcut sepet {{cartName}} ile başarıyla aktif hale getirildi. Önceki öğeleriniz {{previousCartName}} sepetine kaydedildi.",
    editCart: "Kaydedilen Sepeti Düzenle"
};

export const savedCartCartPage = {
    messages: {
        cartSaved: "Sepetinizdeki ürünler daha sonra kullanılmak üzere \'{{cartName}}\' sepetine başarıyla kaydedildi."
    },
    back: "Genel bakışa geri dön"
};

export const savedCartDialog = {
    saveForLater: "Daha Sonrası İçin Kaydet",
    itemsSavedForLater: "Sepetinizdeki tüm ürünler daha sonrası için kaydedilecektir",
    savedCartName: "Kaydedilen Sepet Adı",
    savedCartDescription: "Kaydedilen Sepet Açıklaması",
    optional: "isteğe bağlı",
    charactersLeft: "karakter kaldı: {{count}}",
    cancel: "İptal",
    save: "Kaydet",
    restore: "Geri Yükle",
    followingCartDelete: "Aşağıdaki kayıtlı sepet silinecektir",
    followingCartRestore: "Aşağıdaki kayıtlı sepet aktif sepet olarak geri yüklenecektir",
    delete: "Silme",
    deleteCartSuccess: "Sepet başarıyla silindi",
    editCartSuccess: "Sepet başarıyla düzenlendi",
    editSavedCart: "Kayıtlı Sepeti Düzenle",
    deleteSavedCart: "Kayıtlı Sepeti Sil",
    restoreSavedCart: "Kaydedilen Sepeti Geri Yükle",
    name: "İsim",
    id: "KIMLIK",
    description: "Açıklama",
    quantity: "MIKTAR",
    total: "Toplam",
    keepCopySavedCart: "Bu sepetin bir kopyasını kayıtlı sepetler listesinde saklayın",
    isSharedCart: "Bu sepeti kuruluşunuzla paylaşın",
    defaultCloneCartName: "Kopya {{name}}",
    nameOfCloneCart: "Kopyalanan Sepetin Adı"
};

export const addToSavedCart = {
    savedCarts: "Kaydedilen Sepetler",
    saveCartForLater: "Sepeti Daha Sonra Kaydet",
    sharedCartMessageStart: "{{userName}} tarafından oluşturulmuş bir paylaşımlı alışveriş sepeti mevcuttur ({{userMail}}). Paylaşılan sepeti etkinleştirmek için ",
    sharedCartMessageMiddle: "buraya",
    sharedCartMessageEnd: " tıklayın. Paylaşılan sepeti etkinleştirmek, onu şirket düzeyinden silecek ve ayrıca mevcut kişisel sepetinizin üzerine yazacaktır."
};

export const savedCart = {
    savedCartDetails,
    savedCartList,
    savedCartCartPage,
    savedCartDialog,
    addToSavedCart
};
