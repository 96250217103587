import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrderConfirmationItemsComponent, OrderDetailsService } from '@spartacus/order/components';
import { Observable } from 'rxjs';
import { OrderFacade } from '@spartacus/order/root';

@Component({
  selector: 'cx-order-confirmation-items',
  templateUrl: './order-confirmation-items.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalOrderConfirmationItemsComponent extends OrderConfirmationItemsComponent {
  constructor(private orderDetailsService: OrderDetailsService, protected override orderFacade: OrderFacade) {
    super(orderFacade)
  }

  override order$: Observable<any> = this.orderDetailsService.getOrderDetails();
}
