export const quickOrderCartForm = {
  entriesWasAdded: '({{ quantity }}) {{ product }} wurden Ihrem Warenkorb hinzugefügt',
  entryWasAdded: '{{ product }} wurde Ihrem Warenkorb hinzugefügt',
  noResults: 'Wir konnten keine Artikel finden',
  stockLevelReached: 'Die maximale Bestellmenge wurde erreicht',
  title: 'Zum Warenkorb hinzufügen',
  productCodePlaceholder: 'Artikelnr. eingeben',
  entryProductCode: 'Artikelnummer eingeben für eine schnelle Bestellung',
  addToCart: 'In den Warenkorb',
  product: 'Artikel',
  products: 'Artikel',
  productCodeLabel: 'Artikelnummer',
  quantityLabel: 'Menge'
}

export const quickOrderForm = {
  placeholder: 'Artikelnr. eingeben',
  listLimitReached: 'Das Artikellimit ist erreicht',
  id: 'Artikelnummer {{ id }}',
}

export const quickOrderList = {
  addToCart: 'In den Warenkorb',
  emptyList: 'Liste leeren',
  header: 'Artikel hinzufügen',
  subHeader: 'Sie können bis zu {{ limit }} gültige Artikelnummer zur selben Zeit hinzufügen.',
  errorProceedingToCart: 'Fehler beim hinzufügen zum Warenkorb.',
  warningProceedingToCart: 'Warnung beim hinzufügen zum Warenkorb.',
  successfullyAddedToCart: 'Erfolgreich zum Warenkorb hinzugefügt.',
  errors: {
    productIsOutOfStock: '{{ name }} (#{{code}}) ist nicht verfügbar.'
  },
  warnings: {
    productWasReduced: 'Die Menge für {{ name }} (#{{code}}) wurde reduziert auf {{ quantityAdded}}.'
  },
  successes: {
    productAddedToCart: '{{ name }} (#{{code}}) wurde Ihrem Warenkorb hinzugefügt.'
  }
}

export const quickOrderTable = {
  product: 'Artikel',
  actions: 'Aktionen',
  id: 'Nummer',
  price: 'Preis',
  quantity: 'Menge',
  itemPrice: 'Artikelpreis',
  qty: 'Menge',
  inStock: 'Auf Lager',
  lowStock: 'Geringer Bestand',
  outOfStock: 'Nicht verfügbar',
  listCleared: 'Die Liste der schnellen Bestellung wurde gelöscht',
  addedtoCart: 'Die Liste der schnellen Bestallung wurde dem Warenkorb hinzugefügt'
}

export const quickOrder = {
  quickOrderCartForm,
  quickOrderForm,
  quickOrderList,
  quickOrderTable
}
