export const orderDetails = {
  orderId: 'Ordine #',
  orderNumber: 'Numero ordine Webshop',
  replenishmentId: 'Numero di riapprovvigionamento',
  purchaseOrderId: 'Ordine di acquisto',
  purchaseOrderNumber: 'Il vostro riferimento d\'ordine',
  emptyPurchaseOrderId: 'Nessuno',
  none: 'Nessuno {{value}}',
  placed: 'Creato ',
  placedBy: 'Creato da',
  unit: 'Unità',
  costCenter: 'Centro di costo',
  costCenterAndUnit: 'Centro di costo / Unità',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  methodOfPayment: 'Metodo di pagamento',
  payByAccount: 'Pagamento tramite addebito su conto',
  paidByCreditCard: '(pagamento con carta di credito)',
  status: 'Stato',
  active: 'Attivo',
  shippedOn: 'Spedito il',
  shippingMethod: 'Metodo di spedizione',
  placedOn: 'Inserito il',
  startOn: 'Inizio il',
  nextOrderDate: 'Data del prossimo ordine',
  frequency: 'Frequenza',
  cancelled: ' Annullato',
  deliveryStatus_IN_TRANSIT: 'In transito',
  deliveryStatus_READY_FOR_PICKUP: 'Pronto per il ritiro',
  deliveryStatus_READY_FOR_SHIPPING: 'Pronto per la spedizione',
  deliveryStatus_WAITING: 'In attesa',
  deliveryStatus_DELIVERING: 'In consegna',
  deliveryStatus_PICKPACK: 'In preparazione per la spedizione',
  deliveryStatus_PICKUP_COMPLETE: 'Ritiro completato',
  deliveryStatus_DELIVERY_COMPLETED: 'Consegna completata',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Emissione del pagamento',
  deliveryStatus_READY: 'In corso',
  deliveryStatus_DELIVERY_REJECTED: 'Consegna rifiutata',
  deliveryStatus_SHIPPED: 'Spedito',
  deliveryStatus_TAX_NOT_COMMITTED: 'Problema fiscale',
  deliveryStatus_CANCELLED: 'Annullato',
  statusDisplay_cancelled: 'Annullato',
  statusDisplay_cancelling: 'In attesa di annullamento',
  statusDisplay_confirmed: 'Ordine confermato',
  statusDisplay_completed: 'Ordine consegnato',
  statusDisplay_created: 'Ordine creato',
  statusDisplay_error: 'In attesa',
  statusDisplay_Error: 'In attesa',
  statusDisplay_processing: 'Ordine creato',
  statusDisplay_open: 'Ordine creato',
  statusDisplay_pending: {
    approval: 'Approvazione in sospeso',
    merchant: {
      approval: 'In attesa di approvazione da parte del fornitore',
    },
  },
  statusDisplay_approved: 'Approvato',
  statusDisplay_rejected: 'Rifiutato',
  statusDisplay_merchant: {
    approved: 'Approvato dal fornitore',
    rejected: 'Rifiutato dal fornitore',
  },
  statusDisplay_assigned: {
    admin: 'Assegnato all\'amministratore',
  },
  consignmentTracking: {
    action: 'Tracking',
    dialog: {
      header: 'Informazioni sul tracking',
      shipped: 'Spedito',
      estimate: 'Consegna stimata',
      carrier: 'Servizio di consegna',
      trackingId: 'Numero di tracking',
      noTracking: 'Il pacco non è ancora stato spedito. Le informazioni sul tracking saranno disponibili dopo la spedizione del pacco.',
      loadingHeader: 'Tracking spedizone',
    },
  },
  cancellationAndReturn: {
    returnAction: 'Richiedere un reso',
    cancelAction: 'Cancellare articoli',
    item: 'Articolo',
    itemPrice: 'Prezzo dell\'articolo',
    quantity: 'Quantità massima',
    returnQty: 'Quantità da rendere',
    cancelQty: 'Quantità da cancellare',
    setAll: 'Impostare tutte le quantità al massimo',
    totalPrice: 'Totale',
    submit: 'Inviare richiesta',
    returnSuccess: 'La tua richiesta di reso ({{rma}}) è stata inoltrata',
    cancelSuccess: 'La richiesta di annullamento è stata inoltrata (l\'ordine originale {{orderCode}} verrà aggiornato)',
  },
  cancelReplenishment: {
    title: 'Annullare il riapprovvigionamento automatico',
    description: 'Annullare qualsiasi ordine di riapprovvigionamento futuro?',
    accept: 'Sì',
    reject: 'No',
    cancelSuccess: 'L\'ordine di riapprovvigionamento #{{replenishmentOrderCode}} è stato annullato con successo.',
  },
}

export const orderHistory = {
  orderHistory: 'Storico ordini',
  orderId: 'Ordine #',
  placedBy: 'Referente',
  emptyPurchaseOrderId: 'Nessuno',
  date: 'Data',
  status: 'Stato',
  total: 'Totale',
  noOrders: 'Non ci sono ordini per questo account.',
  noReplenishmentOrders: 'Non ci sono ordini di riapprovvigionamento per questo account.',
  startShopping: 'Iniziare gli acquisti',
  sortBy: 'Ordina per',
  replenishmentOrderHistory: 'Storico ordini di riapprovvigionamento',
  replenishmentOrderId: 'Riapprovvigionamento #',
  purchaseOrderNumber: 'ORDINE DI ACQUISTO #',
  startOn: 'Iniziare il',
  frequency: 'Frequenza',
  nextOrderDate: 'Data ordine successivo',
  cancel: 'Annullare',
  cancelled: 'Annullato',
  replenishmentHistory: 'Storico di riapprovvigionamento',
  notFound: 'Nessun ordine trovato',
}

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'ORDINI',
    OrderReturnRequestListComponent: 'CITAZIONI'
  },
}

export const wishlist = {
  empty: 'Non ci sono articoli nella tua lista dei preferiti',
};

export const myAccount = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer,
  wishlist
}
