export const orderDetails = {
  statusDisplay_open: 'Aún no procesado',
  statusDisplay_processing: 'Parcialmente procesada',
  statusDisplay_completed: 'Totalmente procesada',
  purchaseOrderNumber: 'Número de pedido propio',
  orderNumber: 'Número de pedido de la tienda online',
  methodOfPayment: 'Forma de pago',
  shippingMethod: 'Forma de envío',
  placedOn: 'Pedido en',
  moreInformation: 'Más información',
  orderDocuments: 'Documentos de pedido',
  billing: 'Facturación',
  invoiceNumber: 'Número de factura',
  invoiceDate: 'Fecha',
  netAmount: 'Importe neto',
  noBillingInvoices: 'Aún no hay información de facturación disponible.',
  noOrderDocuments: 'Aún no hay documentos de pedido disponibles.',
  noInvoiceDocuments:'Aún no hay documentos de facturación disponibles.',
  noShipmentDocuments: 'Aún no hay documentos de envío disponibles.',
  shipping: {
    shipping: 'Envío',
    shippingId: 'Id de envío',
    deliveryDate: 'Fecha de envío/recogida',
    quantity: 'Cantidad',
    tracking: 'Seguimiento',
    noShippingInfo: 'No hay información de envío disponible todavía.',
    noTrackingInfo: 'No hay información de seguimiento disponible todavía.'
  },
  orderId: 'Número de pedido',
  replenishmentId: 'Reaprovisionamiento #',
  purchaseOrderId: 'Número de pedido',
  emptyPurchaseOrderId: 'Ninguno',
  none: 'Ninguno {{value}}',
  placed: 'Procesado',
  placedBy: 'Procesado por',
  unit: 'Unidad',
  costCenter: 'Centro de coste',
  costCenterAndUnit: 'Centro de Coste / Unidad',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  payByAccount: 'Pago con cuenta',
  paidByCreditCard: '(pago con tarjeta de crédito)',
  status: 'Estado',
  active: 'Activo',
  shippedOn: 'Enviado el',
  startOn: 'Empezar el',
  nextOrderDate: 'Fecha del siguiente pedido',
  frequency: 'Frecuencia',
  cancelled: ' Cancelado',
  deliveryStatus_IN_TRANSIT: 'En tránsito',
  deliveryStatus_READY_FOR_PICKUP: 'Listo para Recogida',
  deliveryStatus_READY_FOR_SHIPPING: 'Listo para envío',
  deliveryStatus_WAITING: 'En espera',
  deliveryStatus_DELIVERING: 'En espera',
  deliveryStatus_PICKPACK: 'Preparando el envío',
  deliveryStatus_PICKUP_COMPLETE: 'Recogida Completa',
  deliveryStatus_DELIVERY_COMPLETED: 'Entrega Completa',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Pago Emitido',
  deliveryStatus_READY: 'En Proceso',
  deliveryStatus_DELIVERY_REJECTED: 'Entrega rechazada',
  deliveryStatus_SHIPPED: 'Enviado',
  deliveryStatus_TAX_NOT_COMMITTED: 'Problema fiscal',
  deliveryStatus_CANCELLED: 'Cancelado',
  statusDisplay_cancelled: 'Cancelado',
  statusDisplay_cancelling: 'Cancelado Pendiente',
  statusDisplay_confirmed: 'Pedido confirmado',
  statusDisplay_created: 'Pedido creado',
  statusDisplay_error: 'Pendiente',
  statusDisplay_Error: 'Pendiente',
  statusDisplay_pending: {
    approval: 'Pendiente de aprobación',
    merchant: {
      approval: 'Pendiente de aprobación por el comerciante',
    },
  },
  statusDisplay_approved: 'Aprobado',
  statusDisplay_rejected: 'Rechazado',
  statusDisplay_merchant: {
    approved: 'Comerciante Aprobado',
    rejected: 'Comerciante rechazado',
  },
  statusDisplay_assigned: {
    admin: 'Asignado al administrador',
  },
  consignmentTracking: {
    action: 'Rastrear paquete',
    dialog: {
      header: 'Información de seguimiento',
      shipped: 'Enviado',
      estimate: 'Entrega estimada',
      carrier: 'Servicio de entrega',
      trackingId: 'Número de seguimiento',
      noTracking: 'El paquete no ha salido del almacén. La información de seguimiento estará disponible cuándo se haya realizado el envío.',
      loadingHeader: 'Seguimiento de envíos',
    },
  },
  cancellationAndReturn: {
    returnAction: 'Solicitar una devolución',
    cancelAction: 'Cancelar artículos',
    item: 'Artículo',
    itemPrice: 'Precio del artículo',
    quantity: 'Cantidad máxima',
    returnQty: 'Cantidad a devolver',
    cancelQty: 'Cantidad a Cancelar',
    setAll: 'Poner todas las cantidades al máximo',
    totalPrice: 'Total',
    submit: 'Enviar solicitud',
    returnSuccess: 'Su solicitud de devolución ({{rma}}) ha sido enviada',
    cancelSuccess: 'Se ha enviado su solicitud de cancelación (se actualizará el pedido original {{orderCode}})',
  },
  cancelReplenishment: {
    title: 'Cancelar Reaprovisionamiento',
    description: '¿Cancelar cualquier pedido de Reaprovisionamiento futuro?',
    accept: 'Sí',
    reject: 'No',
    cancelSuccess: 'El pedido de Reaprovisionamiento #{{orderCode}} se ha cancelado correctamente',
  }
};

export const orderHistory = {
  orderHistory: 'Historial del pedido',
  orderId: 'Número de pedido',
  date: 'Fecha',
  status: 'Estado',
  PONumber: 'Número de pedido',
  total: 'Total',
  noOrders: 'Aún no tenemos datos de pedidos para esta cuenta.',
  startShopping: 'Empezar a comprar',
  sortBy: 'Ordenar por',
  sortOrders: 'Ordenar pedidos',
  purchaseOrderNumber: 'Número de pedido',
  notFound: 'No se han encontrado pedidos',
  actions: 'Acciones',
  filters: {
    orderNumber: 'Número de pedido',
    orderNumberPlaceholder: 'Introduzca el número de pedido',
    PONumber: 'Número de pedido propio',
    PONumberPlaceholder: 'Introduzca su propio número de pedido',
    dateRange: 'Periodo de tiempo',
    dateRanges: {
      LAST_30_DAYS: 'Últimos 30 días',
      LAST_3_MONTHS: 'Últimos 3 meses',
      LAST_6_MONTHS:'Últimos 6 meses',
      CURRENT_YEAR:'Año en curso',
      LAST_YEAR: 'Año anterior',
      LAST_2_YEARS: 'Antepenúltimo año'
    },
    applyFilters: 'Aplicar filtro',
    clearFilters: 'Restablecer filtro',
  },
  jumpToPageLabel: 'Saltar a',
  jumpToPageInput: 'Número de página'
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'TODOS LOS PEDIDOS ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
