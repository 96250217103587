import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CartQuickOrderFormComponent } from '@spartacus/cart/quick-order/components';


@Component({
  selector: 'cx-cart-quick-order-form',
  templateUrl: './rational-cart-quick-order-form.component.html',
  styleUrls: ['./rational-cart-quick-order-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCartQuickOrderFormComponent extends CartQuickOrderFormComponent {

    override applyQuickOrder(): void {
      if (this.quickOrderForm.invalid) {
        this.quickOrderForm.markAllAsTouched();
        return;
      }

      const productCode = this.quickOrderForm.get('productCode')?.value;
      const quantity = this.quickOrderForm.get('quantity')?.value;

      this.watchAddEntrySuccessEvent();

      if (productCode && quantity) {
        this.activeCartService.addEntry(productCode, quantity);
      }
    }

}
