<ng-container *ngIf="order$ | async as order">
    <ng-container *ngIf="!(isOrderLoading$ | async); else orderLoadingSpinner ">

        <ng-container *ngIf="order.consignments?.length || order.unconsignedEntries?.length">
            <cx-promotions [promotions]="order.appliedOrderPromotions || []"></cx-promotions>
        </ng-container>

        <!-- pickup consigned entries -->
        <div *ngIf="pickupConsignments?.length" class="cx-pickup-order-consigned-entries-header">
            {{ 'deliveryPointOfServiceDetails.itemsToBePickUp' | cxTranslate }}
        </div>

        <rational-order-consigned-entries *ngIf="pickupConsignments"
            [order]="order"
            [consignments]="pickupConsignments"
            [enableAddToCart]="false"
            [buyItAgainTranslation]="'addToCart.buyItAgain' | cxTranslate"
            [showOrderInformationLinks]="true">
        </rational-order-consigned-entries>

        <!-- delivery consignment address and delivery mode -->
        <ng-template *ngIf=" deliveryConsignments && deliveryConsignments.length > 0 && (groupCartItems$ | async)"
            [cxOutlet]="OrderOutlets.CONSIGNMENT_DELIVERY_INFO"
            [cxOutletContext]="{
                showItemList: false,
                order: order
            }"
        >
        </ng-template>

        <!-- delivery consigned entries -->
        <rational-order-consigned-entries *ngIf="deliveryConsignments"
            [order]="order"
            [consignments]="deliveryConsignments"
            [enableAddToCart]="false"
            [buyItAgainTranslation]="'addToCart.buyItAgain' | cxTranslate"
            [showOrderInformationLinks]="true">
        </rational-order-consigned-entries>


        <!-- unconsigned entries -->
        <ng-container *ngIf="order?.unconsignedEntries?.length">
            <div *ngIf="order?.statusDisplay" class="cx-list-header">
                <div class="cx-list-status">
                    {{ 'orderDetails.statusDisplay_' + order?.statusDisplay | cxTranslate }}
                </div>
            </div>

            <!-- delivery unconsigned entries -->
            <ng-container *ngIf="deliveryUnConsignedEntries?.length">
                <h2 *ngIf="groupCartItems$ | async" class="cx-review-header">
                    {{ 'checkoutMode.deliveryEntries' | cxTranslate }}
                </h2>

                <div class="cx-list-item">
                    <ng-container *ngIf="deliveryUnConsignedEntries">
                        <rational-cart-item-list [cxAbstractOrderContext]="{id: order.code,type: abstractOrderType.ORDER}"
                        [items]="deliveryUnConsignedEntries"
                        [readonly]="true"
                        [promotionLocation]="promotionLocation"
                        [options]="{
                        displayAddToCart: false,
                        addToCartString: 'addToCart.buyItAgain' | cxTranslate,
                        optionalBtn: addToCartBtn
                        }"
                        [showOrderInformationLinks]="true"
                    ></rational-cart-item-list>
                    </ng-container>

                </div>
            </ng-container>

            <!-- pickup unconsigned entries, b2b does not have pickup items -->
            <ng-container *ngIf="pickupUnconsignedEntries?.length">
                <h2 class="cx-review-header">
                    {{ 'checkoutPickupInStore.heading' | cxTranslate }}
                </h2>

                <div class="cx-list-item">
                    <ng-template [cxOutlet]="CartOutlets.CART_ITEM_LIST"
                        [cxOutletContext]="{
                            items: pickupUnconsignedEntries,
                            readonly: true,
                            promotionLocation: promotionLocation,
                            options: {
                                displayAddToCart: enableAddToCart$ | async,
                                addToCartString: 'addToCart.buyItAgain' | cxTranslate,
                                optionalBtn: addToCartBtn
                            }
                        }"
                    >
                    </ng-template>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-template let-ctx #addToCartBtn>
        <cx-add-to-cart class="add-to-cart"
            [productCode]="ctx.item.product?.code"
            [product]="ctx.item.product"
            [showQuantity]="false"
            [options]="ctx.options"
            [pickupStore]="ctx.item.deliveryPointOfService?.name"
        >
        </cx-add-to-cart>
    </ng-template>
</ng-container>

<ng-template #orderLoadingSpinner>
    <div class="cx-spinner">
        <cx-spinner></cx-spinner>
    </div>
</ng-template>
