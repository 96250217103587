import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService, WindowRef } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { RationalIdListPriceProperties } from './list-price-properties.model';
import { rationaOCCEndpoints } from 'src/app/custom/rational-backend-occ-endpoint/rational-backend-occ-endpoint.module';
import { RationalActiveCartService } from 'src/app/custom/cart/base/rational-active-cart.service';
import { RationalCurrentProductService } from 'src/app/custom/product/rational-current-product.service';

@Injectable({
  providedIn: 'root',
})
export class RationalIdListPriceService {
  showListPriceOnly: Boolean = true;
  listPriceOptionIsActivated: Boolean = false;
  listPriceOptionIsActivated$ = new Subject<Boolean>();

  constructor(
    private occEndpoints: OccEndpointsService,
    protected http: HttpClient,
    protected winRef: WindowRef,
    protected router: Router,
    protected globalMessageService: GlobalMessageService,
    protected rationalActiveCartService: RationalActiveCartService,
    protected rationalCurrentProductService: RationalCurrentProductService
  ) {
    this.getAndUpdateListPriceProperties();
  }

  getAndUpdateListPriceProperties(): void {
    this.getListPriceProperties().subscribe(
      (data: RationalIdListPriceProperties) => {
        this.showListPriceOnly = data.showListPriceOnly;
        this.listPriceOptionIsActivated = data.listPriceOptionIsActivated;

        this.listPriceOptionIsActivated$.next(this.listPriceOptionIsActivated);
      }
    );
  }

  getListPriceProperties(): Observable<RationalIdListPriceProperties> {
    const url = this.getListPriceUrl();
    return this.http.get(url, {}) as Observable<RationalIdListPriceProperties>;
  }

  toggleShowListPrice() {
    if (!this.showListPriceOnly) {
      const url = this.getUpdateListPriceUrl(!this.listPriceOptionIsActivated);

      this.http.put(url, {}).subscribe((isActivated) => {
        this.listPriceOptionIsActivated = isActivated as boolean;
        this.listPriceOptionIsActivated$.next(isActivated as boolean);
        this.rationalActiveCartService.reloadActiveCart();

        if (this.isItPDPPage()) {
          this.rationalCurrentProductService.reloadCurrentProductPrice();
        }

        this.globalMessageService.add(
          { key: (isActivated as boolean) ? 'navigation.listPrice.listPriceIsEnabled' : 'navigation.listPrice.listPriceIsDisabled'},
          GlobalMessageType.MSG_TYPE_INFO
        );
      });
    }
  }

  getListPriceUrl() {
    return this.occEndpoints.buildUrl(
      rationaOCCEndpoints.listPrice.getListPrice
    );
  }

  getUpdateListPriceUrl(isActivated: boolean) {
    return this.occEndpoints.buildUrl(
      rationaOCCEndpoints.listPrice.updateListPrice,
      { urlParams: { isActivated } }
    );
  }

  isItPDPPage(): boolean | undefined {
    const currentUrl = this.winRef.nativeWindow?.location.href;
    return currentUrl?.includes('/product');
  }
}
