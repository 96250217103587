import { en } from './en';
import { de } from './de';
import { fr } from './fr';
import { it } from './it';
import { es } from './es';
import { pl } from './pl';


export const RationalTranslations = {
    en, de, fr, it, es, pl
};