import { en } from './en';
import { de } from './de';
import { fr } from './fr';
import { it } from './it';
import { es } from './es';
import { pl } from './pl';
import { pt } from './pt';
import { cs } from './cs';
import { ja } from './ja';
import { zh } from './zh';
import { sv } from './sv';
import { tr } from './tr';

export const RationalTranslations = {
    en, de, fr, it, es, pl, pt, cs, ja, zh, sv, tr
};
