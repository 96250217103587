export const quotes = {
    created: 'Le devis a été créé',
    title: 'Devis',
    code: 'Code',
    name: 'Nom de l\'entreprise',
    date: 'Date',
    state: 'Statut',
    statusDisplay_BUYER_APPROVED: 'Acheteur - Approuvé',
    statusDisplay_BUYER_DRAFT: 'Acheteur - Projet',
    statusDisplay_BUYER_SUBMITTED: 'Acheteur - Soumis',
    statusDisplay_BUYER_OFFER: 'Acheteur - Offre',
    statusDisplay_BUYER_ACCEPTED: 'Acheteur - Accepté',
    statusDisplay_BUYER_REJECTED: 'Acheteur - Rejeté',
    statusDisplay_BUYER_ORDERED: 'Acheteur - Commande',
    statusDisplay_SELLER_REQUEST: 'Vendeur - Demande',
    statusDisplay_SELLER_DRAFT: 'Vendeur - Projet',
    statusDisplay_SELLER_SUBMITTED: 'Vendeur - Soumis',
    statusDisplay_SELLERAPPROVER_DRAFT: 'Approuveur du vendeur - Brouillon',
    statusDisplay_SELLERAPPROVER_PENDING: 'Vendeur - En attente',
    statusDisplay_SELLERAPPROVER_REJECTED: 'Sellerapprover - Rejeté',
    statusDisplay_SELLERAPPROVER_APPROVED: 'Sellerapprover - Approuvé',
    noQuotes: 'Pas d\'offre',
    entries: {
      code: 'Code',
      name: 'Nom du produit',
      amount: 'Quantité',
      price: 'Prix'
    },
    totalPriceWithoutTax: 'Prix total hors taxe :'
  }

