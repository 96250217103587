export const orderDetails = {
  orderId: 'Número de pedido',
  orderNumber: 'Número de pedido Webshop',
  replenishmentId: 'Reaprovisionamiento #',
  purchaseOrderId: 'Número de pedido',
  purchaseOrderNumber: 'Su número de pedido',
  emptyPurchaseOrderId: 'Ninguno',
  none: 'Ninguno {{value}}',
  placed: 'Procesado',
  placedBy: 'Procesado por',
  unit: 'Unidad',
  costCenter: 'Centro de coste',
  costCenterAndUnit: 'Centro de Coste / Unidad',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  methodOfPayment: 'Forma de pago',
  payByAccount: 'Pago con cuenta',
  paidByCreditCard: '(pago con tarjeta de crédito)',
  status: 'Estado',
  active: 'Activo',
  shippedOn: 'Enviado el',
  shippingMethod: 'Método de envío',
  placedOn: 'Colocado en',
  startOn: 'Empezar el',
  nextOrderDate: 'Fecha del siguiente pedido',
  frequency: 'Frecuencia',
  cancelled: ' Cancelado',
  deliveryStatus_IN_TRANSIT: 'En tránsito',
  deliveryStatus_READY_FOR_PICKUP: 'Listo para Recogida',
  deliveryStatus_READY_FOR_SHIPPING: 'Listo para envío',
  deliveryStatus_WAITING: 'En espera',
  deliveryStatus_DELIVERING: 'En espera',
  deliveryStatus_PICKPACK: 'Preparando el envío',
  deliveryStatus_PICKUP_COMPLETE: 'Recogida Completa',
  deliveryStatus_DELIVERY_COMPLETED: 'Entrega Completa',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Pago Emitido',
  deliveryStatus_READY: 'En Proceso',
  deliveryStatus_DELIVERY_REJECTED: 'Entrega rechazada',
  deliveryStatus_SHIPPED: 'Enviado',
  deliveryStatus_TAX_NOT_COMMITTED: 'Problema fiscal',
  deliveryStatus_CANCELLED: 'Cancelado',
  statusDisplay_cancelled: 'Cancelado',
  statusDisplay_cancelling: 'Cancelado Pendiente',
  statusDisplay_confirmed: 'Pedido confirmado',
  statusDisplay_completed: 'Pedido entregado',
  statusDisplay_created: 'Pedido creado',
  statusDisplay_error: 'Pendiente',
  statusDisplay_Error: 'Pendiente',
  statusDisplay_processing: 'Pedido Creado',
  statusDisplay_open: 'Pedido creado',
  statusDisplay_pending: {
    approval: 'Pendiente de aprobación',
    merchant: {
      approval: 'Pendiente de aprobación por el comerciante',
    },
  },
  statusDisplay_approved: 'Aprobado',
  statusDisplay_rejected: 'Rechazado',
  statusDisplay_merchant: {
    approved: 'Comerciante Aprobado',
    rejected: 'Comerciante rechazado',
  },
  statusDisplay_assigned: {
    admin: 'Asignado al administrador',
  },
  consignmentTracking: {
    action: 'Rastrear paquete',
    dialog: {
      header: 'Información de seguimiento',
      shipped: 'Enviado',
      estimate: 'Entrega estimada',
      carrier: 'Servicio de entrega',
      trackingId: 'Número de seguimiento',
      noTracking: 'El paquete no ha salido del almacén. La información de seguimiento estará disponible cuándo se haya realizado el envío.',
      loadingHeader: 'Seguimiento de envíos',
    },
  },
  cancellationAndReturn: {
    returnAction: 'Solicitar una devolución',
    cancelAction: 'Cancelar artículos',
    item: 'Artículo',
    itemPrice: 'Precio del artículo',
    quantity: 'Cantidad máxima',
    returnQty: 'Cantidad a devolver',
    cancelQty: 'Cantidad a Cancelar',
    setAll: 'Poner todas las cantidades al máximo',
    totalPrice: 'Total',
    submit: 'Enviar solicitud',
    returnSuccess: 'Su solicitud de devolución ({{rma}}) ha sido enviada',
    cancelSuccess: 'Se ha enviado su solicitud de cancelación (se actualizará el pedido original {{orderCode}})',
  },
  cancelReplenishment: {
    title: 'Cancelar Reaprovisionamiento',
    description: '¿Cancelar cualquier pedido de Reaprovisionamiento futuro?',
    accept: 'Sí',
    reject: 'No',
    cancelSuccess: 'El pedido de Reaprovisionamiento #{{orderCode}} se ha cancelado correctamente',
  }
}

export const orderHistory = {
  orderHistory: 'Historial de pedidos',
  orderId: 'Pedido',
  placedBy: 'Persona de contacto',
  emptyPurchaseOrderId: 'Ninguno',
  date: 'Fecha',
  status: 'Estado',
  total: 'Total',
  noOrders: 'No tenemos registro de pedidos para esta cuenta.',
  noReplenishmentOrders: 'No tenemos registro de pedidos de reaprovisionamiento para esta cuenta.',
  startShopping: 'Iniciar compra',
  sortBy: 'Ordenar por',
  replenishmentOrderHistory: 'Historial de pedidos de reaprovisionamiento',
  replenishmentOrderId: 'Reaprovisionamiento #',
  purchaseOrderNumber: 'PEDIDO #',
  startOn: 'Inicio',
  frequency: 'Frecuencia',
  nextOrderDate: 'Fecha siguiente pedido',
  cancel: 'Cancelar',
  cancelled: 'Cancelado',
  replenishmentHistory: 'Historial de reaprovisionamiento',
  notFound: 'No se han encontrado pedidos',
}

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'PEDIDOS',
    OrderReturnRequestListComponent: 'PRESUPUESTOS'
  },
}

export const wishlist = {
  empty: 'No hay artículos en tu lista de deseos',
}

export const myAccount = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer,
  wishlist
}
