export const orderApprovalDetails = {
  back: 'Powrót do listy',
  showForm_APPROVE: 'Potwierdź zamówienie...',
  showForm_REJECT: 'Anuluj zamówienie...',
  form: {
    title_APPROVE: 'Potwierdź zamówienie {{orderCode}} z wartością {{orderTotal}}.',
    title_REJECT: 'Anuluj zamówienie {{orderCode}} z wartością {{orderTotal}}',
    submit_APPROVE: 'Potwierdź',
    submit_REJECT: 'Anuluj',
    cancel: 'Anuluj',
    comment_APPROVE: {
      label: 'Komentarz (opcjonalnie,maksymalnie 255 znaków)',
      placeholder: ''
    },
    comment_REJECT: {
      label: 'Komentarz (maksymalnie 255 znaków)',
      placeholder: ''
    }
  },
  permissionResults: {
    header: 'Szczegóły zamówienia klienta',
    permission: 'Autoryzacja',
    approver: 'Zatwierdzający',
    status: 'Status',
    approverComments: 'Komentarze Zatwierdzający',
    noApprovalComments: 'Brak',
    permissionType_B2BBudgetExceededPermission: 'Przekroczono budżet powiązany z wybranym centrum kosztów.',
    permissionType_B2BOrderThresholdPermission: 'Suma zamówienia przekroczyła limit na zamówienie',
    permissionType_B2BOrderThresholdTimespanPermission: 'Suma zamówienia przekroczyła limit przedziału czasowego'
  }
}

export const  orderApprovalList = {
  orderCode: 'Zamówienie #',
  POCode: 'ZAMÓWIENIE #',
  placedBy: 'Złożone do',
  date: 'Data',
  status: 'Status',
  total: 'Razem',
  none: 'Brak',
  emptyList: 'W tej chwili nie ma żadnych zamówień do zatwierdzenia.'
}

export const  orderApprovalGlobal = {
  notification: {
    noSufficientPermissions: 'Brak wystarczających uprawnień, aby uzyskać dostęp do tej strony.'
  }
}

export const orderApproval = {
  orderApprovalDetails,
  orderApprovalList,
  orderApprovalGlobal
}
