import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalConfiguratorAddToCartButtonComponent } from './rational-configurator-add-to-cart-button.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { ItemCounterModule, IconModule } from '@spartacus/storefront';

@NgModule({
  declarations: [RationalConfiguratorAddToCartButtonComponent],
  exports: [RationalConfiguratorAddToCartButtonComponent],
  imports: [CommonModule, I18nModule, ItemCounterModule, IconModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ConfiguratorAddToCartButton: {
          component: RationalConfiguratorAddToCartButtonComponent,
        },
      },
    }),
  ],
})
export class RationalConfiguratorAddToCartButtonModule {}
