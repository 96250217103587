import { environment } from 'src/environments/environment';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import {
  AuthConfigService,
  OAuthLibWrapperService,
  OccEndpointsService,
  TranslationService,
  WindowRef,
} from '@spartacus/core';
import {
  AuthConfig,
  NullValidationHandler,
  OAuthErrorEvent,
  OAuthService,
} from 'angular-oauth2-oidc';
import { authConfig } from '../config/rational-id-auth-config';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export const ERROR_CONTEXT_SWITCHING = 'error-context-switching-msg';

@Injectable({
  providedIn: 'root',
})
export class RationalOAuthLibWrapperService extends OAuthLibWrapperService {
  OAUTH_REDIRECT_FLOW_KEY: string = 'oAuthRedirectCodeFlow';

  constructor(
    protected override oAuthService: OAuthService,
    protected override authConfigService: AuthConfigService,
    @Inject(PLATFORM_ID) protected override platformId: Object,
    protected override winRef: WindowRef,
    protected occEndpoints: OccEndpointsService,
    protected translation: TranslationService,
  ) {
    super(oAuthService, authConfigService, platformId, winRef);

    this.oAuthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        this.processError(event);
      } else {
        console.warn(event);
      }
    });
  }

  protected loadAuthConfig(url: string): Observable<AuthConfig> {
    return new Observable((observer) => {
      fetch(url)
        .then((res) => res.json())
        .then((body) => {
          environment.censhareUserName = body["censhareUserName"];
          environment.censharePassword = body["censharePassword"];
          //console.log(environment.censhareUserName);
          //console.log(environment.censharePassword);
          observer.next(body);
          observer.complete();
        })
        .catch((err) => observer.error(err));
    });
  }

  protected occLogout( url: string, authToken: string): Observable<void> {
    return new Observable((observer) => {
      fetch(url, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
        .then((res) => res.json())
        .then((body) => {
          observer.next(body);
          observer.complete();
        })
        .catch((err) => observer.error(err));
    });
  }

  protected override initialize(): void {
    const endpoints = inject(OccEndpointsService);

    const url = endpoints.buildUrl('/sso/authconfig');
    this.loadAuthConfig(url).subscribe((config) => {
      if (config) {
        this.oAuthService.configure({
          ...authConfig,
          ...config,
        });
      }
    });

    this.oAuthService.configure(authConfig); // this is necacerry to provide default config while loadConfig is not available
    this.oAuthService.tokenEndpoint = endpoints.buildUrl('/sso/token');
    this.oAuthService.logoutUrl = endpoints.buildUrl('/sso/logout');
    this.oAuthService.tokenValidationHandler = new NullValidationHandler();
  }

  override initLoginFlow(): void {
    if (this.winRef.localStorage) {
      this.winRef.localStorage?.setItem(this.OAUTH_REDIRECT_FLOW_KEY, 'true');
    }

    return this.oAuthService.initLoginFlow();
  }

  override revokeAndLogout(): Promise<void> {
    console.log('revoke and logout', this.oAuthService.getAccessToken());

    const authToken = this.oAuthService.getAccessToken();
    const logoutUrl = this.occEndpoints.buildUrl('/sso/logout');
    this.occLogout(logoutUrl, authToken).subscribe(
      (item) => {},
      (error) => console.error('Not able to complete rational id logout', error)
    );

    return super.revokeAndLogout();
  }

  setContext(context: string): void {
    this.oAuthService.customQueryParams = {
      "group_id": context
    }
  }

  protected processError(err: OAuthErrorEvent): void {
    console.error(err); //debug purposes
    if (err.reason instanceof HttpErrorResponse) {
      const httpErr: HttpErrorResponse = err.reason;
      const type: string = httpErr.error.errors[0].type;

      if (type == 'ContextSwitcherSelectionError') {
        this.translation.translate(`debitorSelection.selectedDebitorNotAvailable`).subscribe((errorMsg) => {
          window.localStorage.setItem(
            ERROR_CONTEXT_SWITCHING,
            errorMsg
          );
        })
      } else {
        window.localStorage.setItem(ERROR_CONTEXT_SWITCHING, type);
      }
    } else {
      if (err.type == 'token_revoke_error') {
        console.warn("frontend token was not revoked")
      } else {
        window.localStorage.setItem(ERROR_CONTEXT_SWITCHING, err.type);
      }
    }

    // reload to get back to login page
    window.location.reload();
  }
}
