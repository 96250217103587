export const productDetails = {
    id: "商品番号",
    quantity: "数量",
    productDetails: "商品詳細",
    specification: "技術的な特徴",
    reviews: "レビュー",
    shipping: "配送",
    share: "シェア",
    showReviews: "レビューを表示する",
    noReviews: "",
    productPrice: "製品価格",
    priceLoading: "製品価格は計算されます",
    productRelations: {
        productRelationsHeader: "対応アクセサリー"
    },
    disclaimer: {
        title: '画像使用についての注意:',
        text: 'このウェブサイトに掲載された画像は、参考目的のみであり、製品の実際の仕様や提供内容を正確に示すものではありません。製品の詳細な仕様、特性、提供内容については、製品の説明文に記載された情報をご参照ください。'
    }
};

export const productList = {
    filterBy: {
        label: "フィルター",
        action: "フィルター"
    },
    appliedFilter: "フィルターを適用：",
    showLess: "少なく表示...",
    showMore: "もっと表示...",
    sortBy: "並べ替え",
    backToTopBtn: "トップに戻る",
    showMoreBtn: "もっと見る",
    productSearchPagination: "製品検索のページ割り"
};

export const productFacetNavigation = {
    filterBy: {
        label: "フィルター",
        action: "フィルター"
    },
    appliedFilter: "フィルターを適用：",
    showLess: "少なく表示...",
    showMore: "もっと表示...",
    sortBy: "並べ替え"
};

export const productSummary = {
    id: "商品番号",
    showReviews: "レビューを表示する",
    share: "シェア",
    newItemPrice: "新しい商品の価格",
    discount: "表示価格には{{discount}}の個別割引が含まれています。",
    productSubstitutionAndProductExists: "商品{{product1}}は商品{{product2}}で代用されているため、商品{{product2}}にリダイレクトされます。",
    productSubstitutionAndProductDoesntExist: "商品 {{product1}} は商品 {{product2}} で代用できますが、{{product2}} は現在ご利用いただけません。"
};

export const productReview = {
    overallRating: "総合評価",
    reviewTitle: "レビュータイトル",
    writeYourComments: "コメントを書く",
    rating: "評価",
    reviewerName: "レビュアー名（任意）",
    writeReview: "レビューを書く",
    more: "レビューをもっと見る",
    less: "レビューの表示を減らす",
    thankYouForReview: "レビューありがとうございました！レビューの掲載には審査が必要な場合があります。"
};

export const addToCart = {
    itemsAddedToYourCart: "カートに追加された商品",
    itemsIncrementedInYourCart: "この商品は既にカートに入っています。数量が更新されました。",
    items: "アイテム",
    updatingCart: "カートを更新中",
    addToCart: "カートに追加",
    viewCart: "カートを見る",
    proceedToCheckout: "レジに進む",
    quantity: "数量",
    outOfStock: "在庫切れ",
    inStock: "在庫あり",
    selectStyleAndSize: "スタイルとサイズを選択して在庫を確認する",
    removeFromCart: "カートから商品を削除する",
    closeModal: "ウィンドウを閉じる"
};

export const TabPanelContainer = {
    tabs: {
        ProductDetailsTabComponent: "製品詳細",
        ProductSpecsTabComponent: "技術的な特徴",
        ProductReviewsTabComponent: "レビュー",
        deliveryTab: "配送",
        ProductDownloadsTabComponent: "ダウンロード"
    }
};

export const addToWishList = {
    add: "ウィッシュリストに追加",
    remove: "ウィッシュリストから削除",
    anonymous: "ログインしてウィッシュリストに追加する"
};

export const itemCounter = {
    removeOne: "1つ削除する",
    addOneMore: "1つ追加する",
    quantity: "数量"
};

export const productView = {
    gridView: "グリッドビューに切り替える",
    listView: "リストビューに変更する"
};

export const standardDelivery = {
    standardDeliveryRange: "{{from}} 日から {{to}} 日で出荷準備"
};

export const downloads = {
    downloads: {
        noDownloads: "この商品のダウンロードはありません",
        documentTitle: "文書タイトル",
        toolbox: "ツールボックスの詳細",
        allDocuments: "すべて",
        languagesDropdown: "言語：",
        originalFileName: "ファイル名",
        languages: "言語",
        readableTypeMime: "Mimeタイプ",
        fileSize: "ファイルサイズ",
        download: "ダウンロード"
    }
};

export const productReferences = {
    productReferences: {
        installationvariants: "セットアップのバリエーション",
        feedingsystems: "ローディングシステム",
        groomingaccessoires: "ケア製品",
        cookaccessoire: "調理用アクセサリー",
        cookaccessoirespecial: "スペシャルアクセサリー",
        extractorhood: "フード",
        deviceinstallation: "ユニット取り付け"
    }
};

export const product = {
    productDetails,
    productList,
    productFacetNavigation,
    productSummary,
    productReview,
    addToCart,
    TabPanelContainer,
    addToWishList,
    itemCounter,
    productView,
};
