export const orderDetails = {
  statusDisplay_open: 'Aún no procesado',
  statusDisplay_processing: 'Parcialmente procesada',
  statusDisplay_completed: 'Totalmente procesada',
  purchaseOrderNumber: 'Número de pedido propio',
  orderNumber: 'Número de pedido de la tienda online',
  methodOfPayment: 'Forma de pago',
  shippingMethod: 'Forma de envío',
  placedOn: 'Pedido en',
  moreInformation: 'Más información',
  orderDocuments: 'Dokumenty zamówienia',
  billing: 'Facturación',
  invoiceNumber: 'Número de factura',
  invoiceDate: 'Fecha',
  netAmount: 'Importe neto',
  noBillingInvoices: 'Aún no hay información de facturación disponible.',
  noOrderDocuments: 'Aún no hay documentos de pedido disponibles.',
  noInvoiceDocuments:'Aún no hay documentos de facturación disponibles.',
  noShipmentDocuments: 'Aún no hay documentos de envío disponibles.',
  shipping: {
    shipping: 'Envío',
    shippingId: 'Id de envío',
    deliveryDate: 'Data wysyłki/odbioru',
    quantity: 'Cantidad',
    tracking: 'Seguimiento',
    noShippingInfo: 'No hay información de envío disponible todavía.',
    noTrackingInfo: 'No hay información de seguimiento disponible todavía.'
  },
};

export const orderHistory = {
  orderHistory: 'Historial del pedido',
  orderId: 'Número de pedido',
  date: 'Fecha',
  status: 'Estado',
  PONumber: 'Número de pedido',
  total: 'Total',
  noOrders: 'Aún no tenemos datos de pedidos para esta cuenta.',
  startShopping: 'Empezar a comprar',
  sortBy: 'Ordenar por',
  sortOrders: 'Ordenar pedidos',
  purchaseOrderNumber: 'Número de pedido',
  notFound: 'No se han encontrado pedidos',
  actions: 'Acciones',
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'TODOS LOS PEDIDOS ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
