export const configurator = {
  configurator: {
    attribute: {
      id: 'Article Number'
    },
    addToCart: {
      selectVariant: 'More than one existing variant was found for your configuration. In this case, please contact customer service to select the correct variant.',
      addMatchingVariant: 'The existing variant for your configuration has been added to the shopping cart.'
    },
  }
}