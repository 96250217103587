export const exportEntries = {
  exportEntries: {
    exportToCsv: "Exportar para CSV",
    exportMessage: "O arquivo CSV será baixado automaticamente em seu dispositivo; se isso não acontecer, verifique as configurações do navegador",
    columnNames: {
      code: "Código",
      quantity: "Quantidade",
      name: "Nome do produto",
      price: "Preço"
    },
    exportProductToCsv: "Exportar carrinho para CSV"
  }
}

export const importEntries = {
  importEntries: {
    importProducts: "Importar carrinho"
  }
}

export const importEntriesDialog = {
  importEntriesDialog: {
    importProducts: "Importar produtos por meio da importação de um arquivo .CSV ou .XLSX",
    importProductsSubtitle: "Importar produtos por meio da importação de um arquivo .CSV ou .XLSX",
    importProductsNewSavedCartSubtitle: "O arquivo importado deve incluir uma lista de produtos com as colunas Quantidade e número do artigo separadas por ponto e vírgula.",
    importProductFileDetails: "Selecionar arquivo",
    selectFile: "Nome do carrinho salvo",
    savedCartName: "Descrição do carrinho salvo",
    savedCartDescription: "opcional",
    charactersLeft: "caracteres restantes: {{count}}",
    cancel: "Cancelar",
    upload: "Enviar",
    close: "Fechar",
    summary: {
      info: "Não feche ou atualize esta janela enquanto os produtos estiverem sendo importados.",
      loadedToCart: "Os produtos foram carregados no carrinho {{ cartName }}",
      loaded: "Os produtos foram carregados",
      loading: "Os produtos estão sendo processados... ({{ count }}/{{{ total }}))",
      successes: "'{{ successesCount }} dos {{ total }} produtos foram importados com sucesso.",
      warning: "{{ count }} produto não foi totalmente importado.",
      warning_other: "{{count}} produtos não foram totalmente importados.",
      error: "{{ count }} produto não foi importado.",
      error_other: "{{count}} produtos não foram importados.",
      messages: {
        unknownIdentifier: "Este SKU '{{ productCode}}' não existe.",
        lowStock: "'Quantidade para {{ productName }}: {{ quantity }} foi reduzida para {{ quantityAdded }}.",
        noStock: "{{ productName }} está fora de estoque no momento.",
        unknownError: "Problema não reconhecido com '{{ productCode }}'.",
        limitExceeded: "Não é possível adicionar '{{ productCode }}'. Limite excedido."
      },
      show: "Mostrar",
      hide: "Ocultar"
    }
  }
}
