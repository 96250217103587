import { Component, OnInit } from '@angular/core';
import { CartItemContextSource, CartItemListRowComponent } from '@spartacus/cart/base/components';
import { RationalSavedCartService } from '../../rational-saved-cart.service';
import { CartItemContext, OrderEntry } from '@spartacus/cart/base/root';
import { RationalCartItemUpdateService } from 'src/app/custom/cart/base/cart-item/rational-cart-item-list-row/rational-cart-item-update.service';
import { BehaviorSubject } from 'rxjs';
import { RationalAutoCompleteService } from 'src/app/custom/services/rational-auto-complete-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector:
    '[rational-saved-cart-detials-item-list-row], rational-saved-cart-detials-item-list-row',
  templateUrl: './rational-saved-cart-detials-item-list-row.component.html',
  styleUrls: ['./rational-saved-cart-detials-item-list-row.component.scss'],
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
})
export class RationalSavedCartDetialsItemListRowComponent
  extends CartItemListRowComponent
  implements OnInit
{
  isSelected: boolean | undefined;
  showSuggestions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private hideSuggestionsTimeout: any;
  private selectedEntries: OrderEntry[] = [];
  public filteredReferences: string[] = [];
  private key = 'cartReference';

  constructor(
    private savedCartService: RationalSavedCartService,
    private rationalCartItemUpdateService: RationalCartItemUpdateService,
    private autoCompleteService: RationalAutoCompleteService,
    private route: ActivatedRoute,
    cartItemContextSource: CartItemContextSource
  ) {
    super(cartItemContextSource);
    this.autoCompleteService.loadUserEnteredValues(this.key);
  }

  ngOnInit(): void {
    this.savedCartService.selectedEntries.subscribe((entries) => {
      this.selectedEntries = entries;
      if (entries.includes(this.item)) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    });
  }

  toggleSelection() {
    if (this.selectedEntries.includes(this.item)) {
      const index = this.selectedEntries.indexOf(this.item);
      if (index > -1) {
        this.selectedEntries.splice(index, 1);
      }
    } else {
      this.selectedEntries.push(this.item);
    }

    this.savedCartService.selectedEntries.next(this.selectedEntries);
  }

  setEntryReference(entryNumber: number, reference: string): void {
    //stop sending updating saved-cart reference if reference text not changed
    if (reference.trim() === this.item.reference) {
      return;
    }

    const cartId = this.route.snapshot.paramMap.get('savedCartId');

    //save reference value in local storage to be used after that in auto complete
    this.autoCompleteService.addUserEnteredValue(this.key, reference);

    this.rationalCartItemUpdateService
      .updateSavedCartOrderEntry(entryNumber, reference, cartId!)
      .subscribe();
  }

  hideSuggestions(): void {
    clearTimeout(this.hideSuggestionsTimeout);
    this.hideSuggestionsTimeout = setTimeout(() => {
      this.filteredReferences = [];
      this.showSuggestions.next(false);
    }, 200);
  }

  onReferenceInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (value.length > 1) {
      this.filteredReferences = this.autoCompleteService.getFilteredSuggestions(
        this.key,
        value
      );
      this.showSuggestions.next(true);
    } else {
      this.filteredReferences = [];
      this.showSuggestions.next(false);
    }
  }
  showAllSuggestions(): void {
    this.filteredReferences = this.autoCompleteService.getAllSuggestions(
      this.key
    );
    this.showSuggestions.next(true);
  }

  selectSuggestion(suggestion: string, entryNumber: number): void {
    const inputElements = document.getElementsByName(
      'reference'.concat(entryNumber!.toString())
    )[0] as HTMLInputElement;
    inputElements.value = suggestion;
  }
}
