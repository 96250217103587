import { address } from './address';
import { bulkPricing } from './bulkPricing';
import { cart } from './cart';
import { checkout, checkoutB2B, checkoutTermsAndConditions, checkoutMode, checkoutProgress } from './checkout';
import { common } from './common';
import { configurator } from './configurator-common';
import { epdVisualization } from './epdVisualization';
import { importEntriesDialog } from './import-export';
import { importEntries } from './import-export';
import { exportEntries } from './import-export';
import { myAccount } from './myAccount';
import { orderApproval } from './orderApproval';
import { organization } from './organization';
import { payment } from './payment';
import { product, standardDelivery, downloads, productReferences } from './product';
import { quickOrder } from './quickOrder';
import { productVariants } from './productVariants'
import { pwa } from './pwa';
import { quotes } from './quotes';
import { savedCart } from './savedCart';
import { wishlist } from './wish-list';
import { order } from './order';

export const it = {
  address,
  bulkPricing,
  cart,
  checkout,
  checkoutB2B,
  checkoutTermsAndConditions,
  checkoutMode,
  checkoutProgress,
  common,
  configurator,
  epdVisualization,
  importEntries,
  importEntriesDialog,
  exportEntries,
  myAccount,
  order,
  orderApproval,
  organization,
  payment,
  product,
  productVariants,
  pwa,
  quickOrder,
  quotes,
  savedCart,
  standardDelivery,
  downloads,
  wishlist,
  productReferences
}
