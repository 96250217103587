export const quickOrderCartForm = {
    entriesWasAdded: "({{ quantity }}) {{ product }} がカートに追加されました。",
    entryWasAdded: "{{ product }} がカートに追加されました。",
    noResults: "商品が見つかりませんでした",
    stockLevelReached: "在庫数が上限に達しました",
    title: "すぐにカートに追加",
    productCodePlaceholder: "商品番号を入力してください",
    entryProductCode: "クイックオーダーのために商品番号を入力してください。",
    addToCart: "すぐにカートに追加",
    product: "製品",
    products: "製品",
    productCodeLabel: "製品ID",
    quantityLabel: "数量",
    placeholder: "商品SKUを入力",
    listLimitReached: "商品数が上限に達しました",
    id: "商品番号 {{ id }}"
};

export const quickOrderList = {
    addToCart: "カートに入れる",
    emptyList: "空リスト",
    header: "商品/SKUを追加する",
    subHeader: "有効なSKUは一度に{{ limit }} まで追加できます。",
    errorProceedingToCart: "カートに進む前にエラーが発生しました。",
    warningProceedingToCart: "カートに進むと警告が表示されます。",
    successfullyAddedToCart: "正常にカートに追加されました。",
    errors: {
        productIsOutOfStock: "{{ name }} (#{{ code }}) は在庫切れです。"
    },
    warnings: {
        productWasReduced: "{{ name }} (#{{ code }})の数量が {{ quantityAdded }} に減らされました."
    },
    successes: {
        productAddedToCart: "{{ name }} (#{{ code }}) がカートに追加されました。"
    }
};

export const quickOrderTable = {
    product: "製品詳細",
    actions: "アクション",
    id: "商品番号",
    price: "価格",
    quantity: "数量",
    itemPrice: "商品価格",
    qty: "数量"
};

export const quickOrder = {
    quickOrderCartForm,
    quickOrderList,
    quickOrderTable
};
