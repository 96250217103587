import { Component, ComponentRef, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ProductImageZoomDialogComponent } from '@spartacus/product/image-zoom/components';
import { ICON_TYPE, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription, combineLatest } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'cx-product-image-zoom-trigger',
  templateUrl: './rational-product-image-zoom-trigger.component.html',
  styleUrls: ['./rational-product-image-zoom-trigger.component.scss']
})
export class RationalProductImageZoomTriggerComponent implements OnDestroy {

  iconType = ICON_TYPE;
  protected subscriptions = new Subscription();

  //Expose the expand button so it can gain focus on closing the zoom window
  @ViewChild('expandButton') expandButton: ElementRef | undefined;

  @Input() galleryIndex: number | undefined;
  @Input() set expandImage(expand: boolean) {
    if (expand) {
      this.triggerZoom();
    }
  }

  @Output() dialogClose = new EventEmitter<void>();

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef
  ) {}

  triggerZoom(): void {
    const component = this.launchDialogService.launch(
      LAUNCH_CALLER.PRODUCT_IMAGE_ZOOM,
      this.vcr
    );
    if (component) {
      this.subscriptions.add(
        combineLatest([component, this.launchDialogService.dialogClose])
          .pipe(
            tap(([comp]) => {
              if (this.galleryIndex) {
                (
                  comp as ComponentRef<ProductImageZoomDialogComponent>
                ).instance.galleryIndex = this.galleryIndex;
              }
            }),
            filter(([, close]) => Boolean(close)),
            tap(([comp]) => {
              this.launchDialogService.clear(LAUNCH_CALLER.PRODUCT_IMAGE_ZOOM);
              comp?.destroy();
              this.dialogClose.emit();
              this.expandButton?.nativeElement.focus();
            })
          )
          .subscribe()
      );
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
