import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { RationalCart } from '../../model/rational-cart.model';

@Component({
  selector: 'rational-incoterms-and-payment-conditions',
  templateUrl: './checkout-incoterms-and-payment-conditions.component.html',
  styleUrls: ['./checkout-incoterms-and-payment-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalIncotermsAndPaymentConditionsComponent {

  constructor(protected activeCartFacade: ActiveCartFacade) { }


  get cart$(): Observable<RationalCart> {
    return this.activeCartFacade.getActive() as Observable<RationalCart>;
  }
}
