export const organization = {
  organization: {
    enabled: 'Actif',
    disabled: 'Désactivé',
    enable: 'Activer',
    disable: 'Désactivé',
    name: 'Nom du code',
    code: 'Code',
    done: 'effectué',
    cancel: 'Annuler',
    add: 'Ajouter',
    create: 'Créer {{name}}',
    edit: 'Modifier',
    save: 'Sauvegarder {{name}}',
    delete: 'Supprimer',
    assign: 'Gérer',
    active: 'Actif',
    status: 'Statut',
    details: 'Détails',
    messages: {
      emptyList: 'La liste est vide',
    },
    userRoles: {
      b2bcustomergroup: 'Client',
      b2bapprovergroup: 'Approbateur',
      b2bmanagergroup: 'Gestionnaire',
      b2badmingroup: 'Administrateur',
    },
    breadcrumb: 'Organisation',
    notification: {
      noSufficientPermissions:
        'Pas de permissions suffisantes pour accéder à cette page',
      notExist: 'Cet élément n\'existe pas',
      disabled: 'Vous ne pouvez pas modifier un élément désactivé',
    },
    confirmation: {
      cancel: 'ANNULER',
      confirm: 'CONFIRMER',
      disable: 'DÉSACTIVER',
      delete: 'EFFACER',
    },
    httpHandlers: {
      conflict: {
        budget: 'Le budget avec le code {{ code }} existe déjà.',
        costCenter: 'Le centre de coûts avec le code {{ code }} existe déjà.',
        unit: 'L\'unité organisationnelle avec l\'uid {{ code }} existe déjà.',
        user: 'L\'utilisateur dont l\'adresse électronique est {{ code }} existe déjà.',
        userGroup: 'Le groupe d\'utilisateurs avec l\'id {{ code }} existe déjà.',
        permission: 'L\'autorisation d\'approbation avec le code {{ code }} existe déjà.',
        unknown: 'Erreur de validation du serveur.',
      },
    },
  },
};
