export const addressForm = {
  title: 'Tytuł',
  defaultTitle: 'Tytuł',
  firstName: {
    label: 'Imię',
    placeholder: 'Imię',
  },
  lastName: {
    label: 'Nazwisko',
    placeholder: 'Nazwisko',
  },
  companyName: 'Nazwa firmy',
  companyNamePlaceholder: 'Wyszukaj nazwę firmy',
  address1: 'Ulica + numer domu',
  address2: 'Dodatkowy adres',
  country: 'Kraj',
  city: {
    label: 'Miasto',
    placeholder: 'Miasto',
  },
  state: 'Państwo',
  zipCode: {
    label: 'Kod pocztowy',
    placeholder: 'Kod pocztowy/Zip',
  },
  phoneNumber: {
    label: 'Numer telefonu (opcjonalnie)',
    placeholder: '(555) 555 - 0123',
  },
  saveAsDefault: 'Zapisz jako domyślny',
  chooseAddress: 'Wybierz adres',
  streetAddress: 'Ulica + numer domu',
  aptSuite: 'Dodatkowy adres',
  selectOne: 'Wybierz jeden...',
  setAsDefault: 'Ustaw jako domyślny',
  titleRequired: 'Tytuł jest wymagany.',
  userAddressAddSuccess: 'Nowy adres został dodany pomyślnie!',
  userAddressUpdateSuccess: 'Pomyślnie zaktualizowano adres!',
  userAddressDeleteSuccess: 'Adres został pomyślnie usunięty!',
  invalidAddress: 'Nieprawidłowy adres',
}

export const addressBook = {
  addNewShippingAddress: 'Dodaj nowy adres wysyłki',
  editShippingAddress: 'Edytuj adres wysyłki',
  areYouSureToDeleteAddress: 'Czy na pewno chcesz usunąć ten adres?',
  addNewAddress: 'Dodaj nowy adres',
  addAddress: 'Dodaj adres',
  updateAddress: 'Zaktualizuj adres',
  backToAddressList: 'Powrót do listy adresów',
}

export const addressCard = {
  default: 'DOMYŚLNY',
  selected: 'Wybrany',
  setAsDefault: 'Ustaw jako domyślny',
  shipTo: 'Wysyłka do',
  billTo: 'Bill To',
}

export const addressSuggestion = {
  verifyYourAddress: 'Zweryfikuj swój adres',
  ensureAccuracySuggestChange: 'Aby zapewnić dokładność dostawy, sugerujemy zmianę wybraną poniżej.',
  chooseAddressToUse: 'Wybierz adres, którego chcesz użyć:',
  suggestedAddress: 'Sugerowany adres',
  enteredAddress: 'Wprowadzony adres',
  editAddress: 'Edytuj adres',
  saveAddress: 'Zapisz adres',
}

export const address = {
  addressForm,
  addressBook,
  addressSuggestion,
  addressCard
}

