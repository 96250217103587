export const exportEntries = {
  exportEntries: {
    exportToCsv: 'Exportar a CSV',
    exportMessage:'El archivo CSV se descargará automáticamente en su dispositivo. Si no lo hace,compruebe la configuración de su navegador.',
    columnNames: {
      code: 'Código',
      quantity: 'Cantidad',
      name: 'Nombre',
      price: 'Precio',
    },
    exportProductToCsv: 'Exportar cesta a CSV',
  },
}

export const importEntries= {
  importEntries: {
    importProducts: 'Importar cesta'
  }
}

export const importEntriesDialog = {
  importEntriesDialog: {
    importProducts: 'Importar cesta',
    importProductsSubtitle: 'Cargar productos importando un archivo .CSV o .XLSX',
    importProductsNewSavedCartSubtitle: 'Cargar productos importando un archivo .CSV o .XLSX',
    importProductFileDetails: 'El archivo importado debe incluir una lista de productos con las columnas Cantidad y número de artículo separadas por punto y coma.',
    selectFile: 'Seleccionar archivo',
    savedCartName: 'Nombre de la cesta guardada',
    savedCartDescription: 'Descripción de la cesta guardada',
    optional: 'opcional',
    charactersLeft: 'caracteres sobrantes: {{count}}',
    cancel: 'Cancelar',
    upload: 'Subir',
    close: 'Cerrar',
    summary: {
      info: 'No cierre ni actualice esta ventana mientras se importan los productos.',
      loadedToCart: 'Los productos se han cargado en la cesta {{ cartName }}',
      loaded: 'Los productos se han cargado',
      loading: 'Los productos se están procesando... ({{ count }}/{{ total }})',
      successes: '{{ successesCount }} de {{ total }} productos se han importado correctamente.',
      warning: '{{ count }} productos no se importaron totalmente.',
      warning_other: '{{ count }} productos no se han importado totalmente.',
      error: '{{ count }} producto no se ha importado.',
      error_other: '{{ count }} productos no se han importado.',
      messages: {
        unknownIdentifier: 'El SKU del producto \"{{ productCode}}\" no existe.',
        lowStock: 'La cantidad de {{ productName }}: {{ quantity }} se ha reducido a {{ quantityAdded }}.',
        noStock: '{{ productName }} está agotado.',
        unknownError: 'Problema no reconocido con \"{{ productCode }}\".',
        limitExceeded: 'No se puede añadir \"{{ productCode }}\". Límite superado.',
      },
      show: 'Mostrar',
      hide: 'Ocultar',
    },
  },
}
