import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartCouponModule } from '@spartacus/cart/base/components';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { AtMessageModule, IconModule, ItemCounterModule, OutletModule, PromotionsModule } from '@spartacus/storefront';
import { RationalCartItemComponent } from './rational-cart-item/rational-cart-item.component';
import { RationalCartItemValidationWarningModule } from 'src/app/custom/validation/rational-cart-item-validation-warning/rational-cart-item-validation-warning.module';
import { RationalCartItemListRowComponent } from './rational-cart-item-list-row/rational-cart-item-list-row.component';
import { RationalCartItemListComponent } from './rational-cart-item-list/rational-cart-item-list.component';
import { RationalMediaModule } from 'src/app/custom/cmscomponents/media/rational-media.module';
import { ProductDescriptionModule } from 'src/app/custom/product/product-description/product-description.module';
import { RationalScheduleLinesModule } from 'src/app/custom/schedule-lines/rational-schedule-lines.module';



@NgModule({
  declarations: [
    RationalCartItemComponent,
    RationalCartItemListRowComponent,
    RationalCartItemListComponent
  ],
  exports: [
    RationalCartItemComponent,
    RationalCartItemListRowComponent,
    RationalCartItemListComponent
  ],
  imports: [
    AtMessageModule,
    CartCouponModule,
    CommonModule,
    FeaturesConfigModule,
    I18nModule,
    IconModule,
    ItemCounterModule,
    RationalMediaModule,
    OutletModule,
    PromotionsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    AddToCartModule,
    RationalCartItemValidationWarningModule,
    ProductDescriptionModule,
    RationalScheduleLinesModule
  ]
})
export class RationalCartSharedModule { }
