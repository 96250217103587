export const cartDetails = {
  id: 'Identificación',
  proceedToCheckout: 'Proceder a la compra',
  createQuoteFromCart: 'Solicitar presupuesto',
  cartName: 'Cesta #{{code}}',
  minOrderNotReached:'El valor mínimo del pedido es {{minOrderValue}}{{currency}}.',
  orderLocked: 'Atención: No puede realizar pedidos en este momento. Póngase en contacto con su servicio de atención al cliente. Hay problemas con esta cuenta de usuario.',
  requestedRetrievalDateEmpty: 'Debe elegir una fecha de envío/recogida solicitada',
  requestedRetrievalDateAdded: 'Ha añadido correctamente una fecha de envío/recogida solicitada',
  requestedRetrievalDateInvalid: 'La fecha de envío/recogida solicitada no es válida',
  s4omScheduleLinesQuantity: 'Cantidad',
  s4omScheduleLinesEntryInfo: 'La fecha confirmada de envío/recogida de - {{ quantity }} artículo(s) - es {{ date }}',
  cartSubjectToPriceChange: 'Atención: Los precios de los dispositivos recién configurados son precios de catálogo. Recibirá el precio específico para su cliente con la confirmación formal del pedido.'
}

export const cartItems = {
  id: 'Número de artículo',
  description: 'Descripción',
  item: 'Artículo',
  itemPrice: 'Precio',
  quantity: 'Cantidad',
  equipmentNumber: 'Número del equipo: ',
  quantityTitle: 'La cantidad representa el número total de este artículo en su carrito.',
  total: 'Total',
  cartTotal: 'Total de la cesta ({{count}} artículo)',
  cartTotal_plural: 'Total de la cesta ({{count}} artículos)',
  actions: 'Acciones',
  salesUnit: 'UOM',
  reference: 'Referencia',
  referencePlaceHolder: 'Referencia',
  additionalContactEmail: 'correo electrónico adicional',
  additionalContactEmailPlaceHolder: 'correo electrónico adiicional',
  comment: 'Comentario',
  commentPlaceHolder: 'Comentario',
  additionalContactEmailWarning: 'Introduzca una dirección de correo electrónico válida. (Los correos electrónicos no válidos no se guardarán). ',
  requestedDeliveryDate: 'Fecha de envío',
  requestedDeliveryDateTitle: 'Fecha de envío',
  substitutionProductNotFound: 'Este producto se sustituye por otro,pero el nuevo producto no está disponible actualmente',
}

export const orderCost = {
  orderSummary: 'Resumen del pedido',
  subtotal: 'Subtotal',
  shipping: 'Envío:',
  estimatedShipping: 'Envío estimado:',
  discount: 'ha ahorrado:',
  salesTax: 'I.V.A:',
  grossTax: 'El total del pedido no incluye IVA',
  grossIncludeTax: 'El total del pedido incluye IVA',
  total: 'Total:',
  toBeDetermined: 'PENDIENTE DE DETERMINAR',
  requestedDeliveryDate: 'Fecha de envío solicitada*',
  taxStatement: '*Todos los precios indicados son precios netos específicos para cada cliente,impuestos no incluidos. Se le informará del precio neto total final,impuestos incluidos,por correo electrónico con la confirmación del pedido,ya que los precios netos,actualizados diariamente,se recalculan en el momento de realizar el pedido.',
  taxStatement2: 'Al pedir accesorios o piezas de recambio,puede aplicarse un recargo por cantidad mínima si el valor neto sin IVA no alcanza el valor mínimo del pedido.',
}

export const voucher = {
  coupon: '¿Tiene un cupón?',
  coupon_plural: 'Códigos de cupón',
  apply: 'Aplicar',
  placeholder: 'Código promocional',
  applyVoucherSuccess: '{{voucherCode}} se ha aplicado.',
  removeVoucherSuccess: '{{voucherCode}} ha sido eliminado.',
  anchorLabel: 'Introduce o elimina tu código de cupón',
  vouchersApplied: 'Cupones aplicados',
  availableCoupons: 'Cupones disponibles',
  availableCouponsLabel: 'Puede añadir estos cupones a este pedido.',
}

export const saveForLaterItems = {
  itemTotal: 'Guardado para más tarde ({{count}} artículo)',
  itemTotal_plural: 'Guardado para más tarde ({{count}} artículos)',
  cartTitle: 'Cesta',
  saveForLater: 'Guardar para más tarde',
  moveToCart: 'Mover al carrito',
  stock: 'Stock',
  forceInStock: 'Disponible',
}

export const cart = {
  cartDetails,
  cartItems,
  orderCost,
  voucher,
  saveForLaterItems
}

