export const orderDetails = {
  statusDisplay_open: '注文作成',
  statusDisplay_processing: '注文作成',
  statusDisplay_completed: '納品済の注文',
  purchaseOrderNumber: '貴社注文番号#',
  orderNumber: 'Webshop注文番号',
  methodOfPayment: '支払い方法',
  shippingMethod: '発送方法',
  status: "ステータス",
  placedOn: '注文日',
  moreInformation: 'その他の情報',
  orderDocuments: '注文書類',
  billing: '請求先',
  invoiceNumber: '請求書番号',
  invoiceDate: '日付',
  netAmount: '正味金額',
  noBillingInvoices: '請求情報はまだありません。',
  noOrderDocuments: '注文書類はまだありません。',
  noInvoiceDocuments: '請求書類はまだありません。',
  noShipmentDocuments: '出荷書類はまだありません。',
  shipping: {
    shipping: '出荷',
    shippingId: '出荷ID',
    deliveryDate: '発送日/集荷日',
    quantity: '数量',
    tracking: '追跡',
    noShippingInfo: '配送情報はまだありません。',
    noTrackingInfo: '追跡情報はまだありません。'
  },
  orderId: "注文番号#",
    replenishmentId: "補充番号#",
    purchaseOrderId: "注文番号#",
    emptyPurchaseOrderId: "なし",
    none: "{{value}}無し",
    placed: "入力済",
    placedBy: "入力者",
    unit: "単位",
    costCenter: "コストセンター",
    costCenterAndUnit: 'コストセンター / 単位',
    costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
    payByAccount: "口座払い",
    paidByCreditCard: "(クレジットカード払い）",
    active: "アクティブ",
    shippedOn: "発送日",
    startOn: "開始日",
    nextOrderDate: "次回注文日",
    frequency: "頻度",
    cancelled: "キャンセル",
    deliveryStatus_IN_TRANSIT: "輸送中",
    deliveryStatus_READY_FOR_PICKUP: "集荷準備完了",
    deliveryStatus_READY_FOR_SHIPPING: "発送準備完了",
    deliveryStatus_WAITING: "待機中",
    deliveryStatus_DELIVERING: "配送中",
    deliveryStatus_PICKPACK: "出荷準備中",
    deliveryStatus_PICKUP_COMPLETE: "集荷完了",
    deliveryStatus_DELIVERY_COMPLETED: "配達完了",
    deliveryStatus_PAYMENT_NOT_CAPTURED: "支払いエラー",
    deliveryStatus_READY: "処理中",
    deliveryStatus_DELIVERY_REJECTED: "配達拒否",
    deliveryStatus_SHIPPED: "発送済み",
    deliveryStatus_TAX_NOT_COMMITTED: "税金エラー",
    deliveryStatus_CANCELLED: "キャンセル",
    statusDisplay_cancelled: "キャンセル",
    statusDisplay_cancelling: "キャンセル保留",
    statusDisplay_confirmed: "注文確定",
    statusDisplay_created: "注文作成",
    statusDisplay_error: "保留",
    statusDisplay_Error: "保留",
    statusDisplay_pending: {
        approval: "承認待ち",
        merchant: {
            Approval: "加盟店の承認待ち"
        }
    },
    statusDisplay_approved: "承認済み",
    statusDisplay_rejected: "拒否",
    statusDisplay_merchant: {
        Approved: "加盟店承認済み",
        Rejected: "加盟店拒否",
    },
    statusDisplay_assigned: {
        admin: "管理者に割り当て"
    },
    consignmentTracking: {
      action: "パッケージの追跡",
      dialog: {
        header: "追跡情報",
        shipped: "発送済み",
        estimate: "配送予定",
        carrier: "配送サービス",
        trackingId: "追跡番号",
        noTracking: "荷物は倉庫から発送されていません。追跡情報は、荷物の発送後に確認できます。",
        loadingHeader: "荷受追跡"
      }
    },
    cancellationAndReturn: {
      returnAction: "返品依頼",
      cancelAction: "商品のキャンセル",
      item: "商品",
      itemPrice: "商品価格",
      quantity: "最大数量",
      returnQty: "返品数量",
      cancelQty: "キャンセル数量",
      setAll: "すべての数量を最大にする",
      totalPrice: "合計",
      submit: "リクエストを送信",
      returnSuccess: "返品リクエスト ({{rma}}) が送信されました。",
      cancelSuccess: "キャンセルリクエストが送信されました (元の注文 {{orderCode}} が更新されます)"
    },
    cancelReplenishment: {
      title: "補充をキャンセルする",
      description: "今後の補充注文をキャンセルしますか？",
      accept: "はい",
      reject: "いいえ",
      cancelSuccess: "補充注文 #{{replenishmentOrderCode}} は正常にキャンセルされました。"
    },
};

export const orderHistory = {
  orderHistory: '注文履歴',
  orderId: '注文番号',
  date: '日付',
  status: 'ステータス',
  PONumber: '注文番号',
  total: '合計',
  noOrders: 'このアカウントの注文データはまだありません。',
  startShopping: 'ショッピングを開始する',
  sortBy: '並べ替え',
  sortOrders: '注文の並べ替え',
  purchaseOrderNumber: '注文番号',
  notFound: '注文が見つかりません',
  actions: 'アクション',
  filters: {
    orderNumber: '注文番号',
    orderNumberPlaceholder: '注文番号の入力',
    PONumber: 'ご注文番号',
    PONumberPlaceholder: 'ご自身の注文番号を入力',
    dateRange: '期間',
    dateRanges: {
      LAST_30_DAYS: '過去30日間',
      LAST_3_MONTHS: '過去3ヶ月',
      LAST_6_MONTHS:'過去6ヶ月',
      CURRENT_YEAR:'現在の年',
      LAST_YEAR: '昨年',
      LAST_2_YEARS: '一昨年'
    },
    applyFilters: 'フィルタを適用する',
    clearFilters: 'フィルタをリセットする',
  },
  jumpToPageLabel: 'ジャンプ先',
  jumpToPageInput: 'ページ番号'
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'すべての注文 ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
