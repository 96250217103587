import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { CartItemListComponent } from '@spartacus/cart/base/components';
import { OrderEntry } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'rational-saved-cart-detials-item-list',
  templateUrl: './rational-saved-cart-detials-item-list.component.html',
  styleUrls: ['./rational-saved-cart-detials-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RationalSavedCartDetialsItemListComponent extends CartItemListComponent {

  getQuantity(item: OrderEntry): UntypedFormControl{
    // @ts-ignore
    return this.form.get(this.getControlName(item))?.get('quantity');
  }

  override getControl(item: OrderEntry): Observable<UntypedFormGroup> | undefined {
    return this.form.get(this.getControlName(item))?.valueChanges.pipe(
      // eslint-disable-next-line import/no-deprecated
      startWith(null),
      tap((value) => {
        if (item.updateable && value && !this.readonly) {
          if (this.options.isSaveForLater) {
            this.selectiveCartService.updateEntry(
              value.entryNumber,
              value.quantity
            );
          } else if (this.cartId && this.userId) {
            this.multiCartService.updateEntry(
              this.userId,
              this.cartId,
              value.entryNumber,
              value.quantity
            );
          } else {
            this.activeCartService.updateEntry(
              value.entryNumber,
              value.quantity
            );
          }
        }
      }),
      map(() => <UntypedFormGroup>this.form.get(this.getControlName(item)))
    );
  }
}
