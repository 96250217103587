import { Component, ViewEncapsulation } from '@angular/core';
import { LoadingOverlayService } from './loading-ovarlay.service';

@Component({
  selector: 'loading-overlay-component',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RationalLoadingOverlayComponent {
  isLoading$ = this.loadingOverlayService.isLoading();

  constructor(
    protected loadingOverlayService: LoadingOverlayService
  ) {
  }
}
