import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { AtMessageModule, OutletModule, PromotionsModule } from '@spartacus/storefront';
import { RationalCheckoutPlaceOrderComponent } from './checkout-place-order.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { OrderOccModule } from '@spartacus/order/occ';
import { CartNotEmptyGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';

@NgModule({
  imports: [
    AtMessageModule,
    UrlModule,
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    PromotionsModule,
    I18nModule,
    OutletModule,
    HttpClientModule,
    OrderOccModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPlaceOrder: {
          component: RationalCheckoutPlaceOrderComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
  declarations: [RationalCheckoutPlaceOrderComponent],
  exports: [RationalCheckoutPlaceOrderComponent],
})
export class RationalCheckoutPlaceOrderModule { }
