export const visualPicking = {
  visualPickingProductList: {
    id: 'Numer artykułu',
  },
  visualPickingProductFilter: {
    input: {
      placeholder: 'Filtrowanie według nazwy lub numeru artykułu',
    },
  },
}

export const epdVisualization = {
  epdVisualization: {
    visualPicking
  }
}
