export const productDetails = {
    id: "Número do artigo",
    quantity: "Quantidade",
    productDetails: "Detalhes do produto",
    specification: "Características técnicas",
    reviews: "Comentários",
    shipping: "Envio",
    share: "Compartilhe",
    showReviews: "Mostrar comentários",
    noReviews: "",
    productPrice: "Preço do produto",
    priceLoading: "O preço do produto foi calculado",
    productRelations: {
        productRelationsHeader: "Acessórios compatíveis"
    },
    disclaimer: {
        title: 'Observação sobre o uso de imagens:',
        text: 'As imagens apresentadas neste sítio Web servem apenas para fins ilustrativos e não constituem uma representação vinculativa das caraterísticas reais do produto ou do âmbito de fornecimento. As especificações exactas, as propriedades e o âmbito de fornecimento dos produtos podem ser consultados na respectiva descrição do produto sob a forma de texto.'
    }
};

export const productList = {
    filterBy: {
        label: "Filtrar por",
        action: "Filtrar por"
    },
    appliedFilter: "Filtro aplicado:",
    showLess: "Mostrar menos...",
    showMore: "Mostrar mais...",
    sortBy: "Ordenar por",
    backToTopBtn: "VOLTAR AO TOPO",
    showMoreBtn: "MOSTRAR MAIS",
    productSearchPagination: "Paginação da pesquisa de produtos"
};

export const productFacetNavigation = {
    filterBy: {
        label: "Filtrar por",
        action: "Filtrar por"
    },
    appliedFilter: "Filtro aplicado:",
    showLess: "Mostrar menos...",
    showMore: "Mostrar mais...",
    sortBy: "Ordenar por"
};

export const productSummary = {
    id: "Número do artigo",
    showReviews: "Mostrar comentários",
    share: "Compartilhar",
    newItemPrice: "Preço do novo item",
    discount: "Os preços exibidos incluem seu desconto individual no valor de {{discount}}.",
    productSubstitutionAndProductExists: "O produto {{product1}} foi substituído pelo produto {{product2}}, portanto, você será redirecionado para o produto {{product2}}",
    productSubstitutionAndProductDoesntExist: "O produto {{product1}} é substituído pelo produto {{product2}}, mas {{product2}} não está disponível no momento"
};

export const productReview = {
    overallRating: "Avaliação geral",
    reviewTitle: "Título da avaliação",
    writeYourComments: "Escreva seus comentários",
    rating: "Avaliação",
    reviewerName: "Nome do avaliador (opcional)",
    writeReview: "Escreva um comentário",
    more: "Mostrar mais avaliações",
    less: "Mostrar menos avaliações",
    thankYouForReview: "Obrigado pela avaliação! Observe que as avaliações podem exigir revisão antes de serem exibidas aqui."
};

export const addToCart = {
    itemsAddedToYourCart: "Item(ns) adicionado(s) ao seu carrinho",
    itemsIncrementedInYourCart: "Este item já estava em seu carrinho. A quantidade foi atualizada.",
    items: "itens",
    updatingCart: "Atualizando o carrinho...",
    addToCart: "Adicionar ao carrinho",
    viewCart: "Ver carrinho",
    proceedToCheckout: "Prosseguir para o checkout",
    quantity: "Quantidade",
    outOfStock: "Fora de estoque",
    inStock: "Em estoque",
    selectStyleAndSize: "Selecione o estilo e o tamanho para verificar o estoque",
    removeFromCart: "Remover produto do carrinho",
    closeModal: "Fechar Modal"
};

export const TabPanelContainer = {
    tabs: {
        ProductDetailsTabComponent: "Detalhes do produto",
        ProductSpecsTabComponent: "Características técnicas",
        ProductReviewsTabComponent: "Comentários",
        deliveryTab: "Remessa",
        ProductDownloadsTabComponent: "Downloads"
    }
};

export const addToWishList = {
    add: "Adicionar à lista de desejos",
    remove: "Remover da lista de desejos",
    anonymous: "Faça login para adicionar à lista de desejos"
};

export const itemCounter = {
    removeOne: "Remover um",
    addOneMore: "Adicionar mais um",
    quantity: "Quantidade"
};

export const productView = {
    gridView: "Selecione para mudar para a visualização em grade",
    listView: "Selecione para mudar para a visualização de lista"
};

export const standardDelivery = {
    standardDeliveryRange: "Pronto para envio com {{from}} a {{to}} dias"
};

export const downloads = {
    downloads: {
        noDownloads: "Não há downloads para este produto",
        documentTitle: "Título do documento",
        toolbox: "Mais em Caixa de ferramentas",
        allDocuments: "Todos os documentos",
        languagesDropdown: "Idioma:",
        originalFileName: "Nome do arquivo",
        languages: "Idiomas",
        readableTypeMime: "Tipo de Mime",
        fileSize: "Tamanho do arquivo",
        download: "Download"
    }
};

export const productReferences = {
    productReferences: {
        installationvariants: "Variações de configuração",
        feedingsystems: "Sistemas de carregamento",
        groomingaccessoires: "Produtos de cuidados",
        cookaccessoire: "Acessórios de cozimento",
        cookaccessoirespecial: "Acessórios especiais",
        extractorhood: "Coifas",
        deviceinstallation: "Instalação da unidade"
    }
};

export const product = {
    productDetails,
    productList,
    productFacetNavigation,
    productSummary,
    productReview,
    addToCart,
    TabPanelContainer,
    addToWishList,
    itemCounter,
    productView,
};
