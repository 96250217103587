import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalProductSummaryComponent } from './rational-product-summary.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { OutletModule, SpinnerModule } from '@spartacus/storefront';

@NgModule({
  declarations: [RationalProductSummaryComponent],
  exports: [RationalProductSummaryComponent],
  imports: [CommonModule, OutletModule, I18nModule, SpinnerModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductSummaryComponent: {
          component: RationalProductSummaryComponent,
        },
      },
    }),
  ],
})
export class RationalProductSummaryModule {}
