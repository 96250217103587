import {
  Component,
  DoCheck,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  GlobalMessageService,
  GlobalMessageType,
  Product,
  RoutingService,
} from '@spartacus/core';
import { ProductSummaryComponent } from '@spartacus/storefront';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { RationalCurrentProductService } from '../rational-current-product.service';

@Component({
  selector: 'cx-product-summary',
  templateUrl: './rational-product-summary.component.html',
  styleUrl: './rational-product-summary.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class RationalProductSummaryComponent extends ProductSummaryComponent {
  private productCode: string | undefined = undefined;
  isPriceLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  override product$: Observable<Product | null> = of(null);

  constructor(
    protected override currentProductService: RationalCurrentProductService,
    protected routingService: RoutingService,
    protected globalMessageService: GlobalMessageService,
    protected elementRef: ElementRef
  ) {
    super(currentProductService);

    this.isProductChanged();

    this.getProductPrice();

    currentProductService.priceChanged$.subscribe((product)=>{
      this.product$ = of(product);
      this.isPriceLoaded$.next(true);
    })
  }

  private getProductPrice(): void {
    this.currentProductService.getCurrentProductPrice().subscribe(
      (product) => {
        if (!product) {
          this.product$ = of(null);
          this.isPriceLoaded$.next(true);
          return;
        }

        // if product has substitution
        if (product?.substitutionProductCode != undefined) {
          this.productSubstitution(product);
          return;
        }

        this.product$ = of(product);
        this.isPriceLoaded$.next(true);
      },

      (err) => {
        this.product$ = of(null);
        this.isPriceLoaded$.next(true);
      }
    );
  }

  private isProductChanged(): void {
    this.currentProductService.getCurrentProductCode().subscribe((data) => {
      if (data) {
        if (this.productCode && this.productCode !== data) {
          this.isPriceLoaded$.next(false);
        }
        this.productCode = data;
      } else {
        this.productCode = undefined;
      }
    });
  }

  private productSubstitution(product: Product) {
    if (product.substitutionProductExists) {
      //this.isPriceLoaded$.next(false);
      this.rediectToSubstitutionProduct(product.substitutionProductCode);
      this.showRedirectionMessage(
        'productSummary.productSubstitutionAndProductExists',
        product.code ?? '',
        product.substitutionProductCode,
        GlobalMessageType.MSG_TYPE_INFO
      );
    } else {
      this.showRedirectionMessage(
        'productSummary.productSubstitutionAndProductDoesntExist',
        product.code ?? '',
        product.substitutionProductCode,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  private rediectToSubstitutionProduct(substitutionProductCode: string) {
    this.routingService.go({
      cxRoute: 'product',
      params: { code: substitutionProductCode },
    });
  }

  private showRedirectionMessage(
    messageKey: string,
    originalProductCode: string,
    substitutionProductCode: string,
    messageType: GlobalMessageType
  ) {
    this.globalMessageService.add(
      {
        key: messageKey,
        params: {
          product1: originalProductCode,
          product2: substitutionProductCode,
        },
      },
      messageType
    );
  }
}
