import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CancelOrReturnItemsComponent, OrderAmendService } from '@spartacus/order/components';

@Component({
  selector: 'rational-amend-order-items',
  templateUrl: './amend-order-items.component.html',
  styleUrls: ['./amend-order-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCancelOrReturnItemsComponent extends CancelOrReturnItemsComponent {

  constructor(protected _orderAmendService: OrderAmendService) {
    super(_orderAmendService);
  }
}