<table role="table">
  <div class="select-all-mobile">
    <div class="row">
      <div class="col-1">
        <input type="checkbox" class="saved-cart-checkbox" [checked]="isAllEntriesSelected" (change)="toggleSelectAllEntries()"/>
      </div>
      <div class="col-6">
        <p>{{ 'savedCartDetails.selectAll' | cxTranslate }}</p>
      </div>
    </div>
  </div>
  <caption class="cx-visually-hidden">
    {{
    'cartItems.caption' | cxTranslate
    }}
  </caption>
  <thead *ngIf="hasHeader">
  <tr role="row" class="cx-item-list-header">
    <th role="columnheader" class="cx-item-list-checkbox">
      <input type="checkbox" class="saved-cart-checkbox" [checked]="isAllEntriesSelected" (change)="toggleSelectAllEntries()"/>
    </th>
    
    <th role="columnheader" class="cx-item-list-desc">
      {{ 'cartItems.description' | cxTranslate }}
    </th>
    <th role="columnheader" class="cx-item-list-qty">
      {{ 'cartItems.quantity' | cxTranslate }}
    </th>

    <th role="columnheader" class="cx-item-list-qty">
      {{ 'cartItems.salesUnit' | cxTranslate }}
    </th>
    
    <ng-container *ngIf="!readonly || options.isSaveForLater || options.displayAddToCart">
      <th role="columnheader" class="cx-item-list-actions">
        {{ 'cartItems.actions' | cxTranslate }}
      </th>
    </ng-container>
  </tr>
  </thead>
  <tbody class="cx-item-list-items">
  <ng-container *ngFor="let item of items; let i = index">
    <ng-container *ngIf="getControl(item) | async as control" [class.is-changed]="control.get('quantity')!.disabled">
      <tr rational-saved-cart-detials-item-list-row
          role="row"
          class="cx-item-list-row"
          [item]="item"
          [quantityControl]="getQuantity(item)"
          [readonly]="readonly"
          [promotionLocation]="promotionLocation"
          [options]="options"></tr>
    </ng-container>
  </ng-container>
  </tbody>
</table>