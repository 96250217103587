import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FeaturesConfigModule, I18nModule } from '@spartacus/core';
import { FormErrorsModule, ItemCounterModule} from '@spartacus/storefront';
import { RationalCancelOrReturnItemsComponent } from './amend-order-items.component';
import { AmendOrderItemsModule } from '@spartacus/order/components';
import { CmsConfig, provideDefaultConfig } from "@spartacus/core";
import { RationalMediaModule } from 'src/app/custom/cmscomponents/media/rational-media.module';

@NgModule({
  imports: [
    AmendOrderItemsModule,
    CommonModule,
    ReactiveFormsModule,
    I18nModule,
    RationalMediaModule,
    ItemCounterModule,
    FeaturesConfigModule,
    FormErrorsModule,
  ],
  declarations: [RationalCancelOrReturnItemsComponent],
  exports: [RationalCancelOrReturnItemsComponent],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        CancelOrReturnItemsComponent: {
          component: RationalCancelOrReturnItemsComponent,
        }
      },
    }),
  ],
})
export class RationalAmendOrderItemsModule { }
