const visualPicking = {
    visualPickingProductList: {
      id: 'Número do artigo'
    },
    visualPickingProductFilter: {
      input: {
        placeholder: 'Filtrar por nome ou número de artigo'
      }
    }
  }
  
  export const epdVisualization = {
    epdVisualization: {
      visualPicking
    }
  };
  