export const exportEntries = {
  exportEntries: {
    exportToCsv: 'Als CSV exportieren',
    exportMessage: 'Die CSV-Datei wird automatisch auf Ihr Gerät heruntergeladen. Sollte dies nicht der Fall sein, überprüfen Sie bitte Ihre Browsereinstellungen.',
    columnNames: {
      code: 'Code',
      quantity: 'Menge',
      name: 'Name',
      price: 'Preis',
    },
    exportProductToCsv: 'Warenkorb als CSV exportieren'
  }
}

export const importEntries = {
  importEntries: {
    importProducts: 'Warenkorb importieren'
  }
}

export const importEntriesDialog = {
  importEntriesDialog: {
    importProducts: 'Warenkorb importieren',
    importProductsSubtitle: 'Produkte importieren, indem Sie eine .CSV- oder eine .XLSX-Datei importieren',
    importProductsNewSavedCartSubtitle: 'Produkte importieren, indem Sie eine .CSV- oder eine .XLSX-Datei importieren',
    importProductFileDetails: 'Die importierte Datei sollte eine Liste von Produkten mit den Spalten Menge und Artikelnummer, getrennt durch ein Semikolon, enthalten.',
    selectFile: 'Datei auswählen',
    savedCartName: 'Gespeicherter Warenkorb Name',
    savedCartDescription: 'Gespeicherter Warenkorb Beschreibung',
    optional: 'optional',
    charactersLeft: 'Zeichen übrig: {{count}}',
    cancel: 'Abbrechen',
    upload: 'Hochladen',
    close: 'Schließen',
    summary: {
      info: 'Schließen oder aktualisieren Sie dieses Fenster nicht, während die Produkte importiert werden.',
      loadedToCart: 'Produkte wurden in den Warenkorb {{ cartName }} geladen ',
      loaded: 'Produkte wurden geladen',
      loading: 'Produkte werden verarbeitet... ({{ count }}/{{ total }})',
      successes: '{{ successesCount }} von {{ total }} Produkten wurden erfolgreich importiert.',
      warning: '{{ count }} Produkt wurde nicht vollständig importiert.',
      warning_other: '{{ count }} Produkte wurden nicht vollständig importiert.',
      error: '{{ count }} Produkt wurde nicht importiert.',
      error_other: '{{ count }} Produkte wurden nicht importiert.',
      messages: {
        unknownIdentifier: 'Produkt SKU \'{{ productCode}}\' existiert nicht.',
        lowStock: 'Die Menge für {{ productName }}: {{ quantity }} has been reduced to {{ quantityAdded }}.',
        noStock: '{{ productName }} ist derzeit nicht auf Lager.',
        unknownError: 'Unerkanntes Problem mit \'{{ productCode }}\'.',
        limitExceeded: 'Kann \'{{ productCode }}\' nicht hinzufügen. Limit überschritten.',
      },
      show: 'Anzeigen',
      hide: 'Ausblenden',
    }
  }
}
