import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  RoutingService,
  StateWithProcess,
  UserIdService,
} from '@spartacus/core';
import { OrderHistoryService, StateWithOrder } from '@spartacus/order/core';
import { OrderHistoryList } from '@spartacus/order/root';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { RationalOccOrderHistoryAdapter } from './rational-occ-order-history.adapter';
import { RationalOrderHistoryFilters } from './rational-order-history-filters/rational-order-history-filters.model';

@Injectable()
export class RationalOrderHistoryService extends OrderHistoryService {
  private ORDER_HISTORY_PAGE_SIZE = 25;
  constructor(
    override store: Store<StateWithOrder>,
    override processStateStore: Store<StateWithProcess<void>>,
    override userIdService: UserIdService,
    override routingService: RoutingService,
    protected rationalOccOrderHistoryAdapter: RationalOccOrderHistoryAdapter
  ) {
    super(store, processStateStore, userIdService, routingService);
  }

  override getOrderHistoryList(
    pageSize: number
  ): Observable<OrderHistoryList | undefined> {
    return this.loadHistoryWithSpecialFilters(this.ORDER_HISTORY_PAGE_SIZE);
  }

  loadHistoryWithSpecialFilters(
    pageSize?: number,
    currentPage?: number,
    sort?: string,
    filters?: RationalOrderHistoryFilters
  ): Observable<OrderHistoryList> {
    const userId$ = this.userIdService.takeUserId();
    return combineLatest([userId$]).pipe(
      switchMap(([userId]) => {
        return this.rationalOccOrderHistoryAdapter.loadHistory(
          userId,
          this.ORDER_HISTORY_PAGE_SIZE,
          currentPage,
          sort,
          filters
        );
      })
    );
  }
}
