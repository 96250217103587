<custom-minicart-outlet></custom-minicart-outlet>
<ng-template cxOutletRef="cx-header">
  <header id="rational-header" class="rational-header"
          cxSkipLink="cx-header"
          [cxFocus]="{ disableMouseFocus: true }"
          [class.is-expanded]="isExpanded$ | async"
          (keydown.escape)="collapseMenu()"
          (click)="collapseMenuIfClickOutside($event)">
    <div class="rational-container">

      <div class="header-row justify-right">
        <!-- <div style="margin-right: 20px"> -->
          <div>
          <cx-page-slot *ngIf="isLoggedIn$ | async" position="SearchBox"></cx-page-slot>
        </div>
        <div class="content right right-cotent-display">
          <div *ngIf="user$ | async as user" class="user-data">
            <span class="user-greeting">
              {{ "common.userGreeting" | cxTranslate }} {{ user?.firstName }} 
            </span>
            <context-switcher></context-switcher>
          </div>
          <div class="shop-icons">
            <div class="element">
              <cx-page-slot position="SiteContext"></cx-page-slot>
            </div>
            <div class="element">
              <cx-page-slot position="HeaderLinks"></cx-page-slot>
            </div>
            <div class="element">
              <cx-page-slot *ngIf="isLoggedIn$ | async" position="MiniCart"></cx-page-slot>
            </div>
          </div>
        </div>
      </div>
      <div class="header-row">
        <cx-page-slot position="SiteLogo"></cx-page-slot>
        <cx-page-slot *ngIf="isLoggedIn$ | async" position="NavigationBar"></cx-page-slot>
      </div>
    </div>

    <div class="rational-container-mobile">
      <cx-page-slot position="SiteLogo"></cx-page-slot>
      <div class="left-content-continer">
        <cx-page-slot *ngIf="isLoggedIn$ | async" position="SearchBox"></cx-page-slot>
        <div class="element">
          <cx-page-slot position="HeaderLinks"></cx-page-slot>
          <cx-page-slot *ngIf="isLoggedIn$ | async" position="MiniCart"></cx-page-slot>
        </div>
        <cx-page-slot *ngIf="isLoggedIn$ | async" position="PreHeader"></cx-page-slot>
      </div>
    </div>

    <cx-page-layout section="navigation"></cx-page-layout>
  </header>
  <cx-page-slot
    *ngIf="isLoggedIn$ | async"
    position="BottomHeaderSlot"
  ></cx-page-slot>
  <div #globalMsg>
    <cx-global-message [class.stickyGlobalMsg]="globalMsgElementPositionIsAbsolute"></cx-global-message>

  </div>
</ng-template>
