import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DeliveryMode } from '@spartacus/cart/base/root';
import { Address } from '@spartacus/core';
import { OrderOverviewComponent } from '@spartacus/order/components';
import { Card } from '@spartacus/storefront';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { rationalDeliveryModeCard } from '../../checkout/utils/rational-order-card-utils';

@Component({
  selector: 'cx-order-overview',
  templateUrl: './order-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalOrderOverviewComponent extends OrderOverviewComponent{

  override getAddressCardContent(deliveryAddress: Address): Observable<Card> {
    return this.translation.translate('addressCard.shipTo').pipe(
      filter(() => Boolean(deliveryAddress)),
      map((textTitle) => {
        const formattedAddress = this.normalizeFormattedAddresss(
          deliveryAddress.formattedAddress ?? ''
        );

        let commaSeparator = '';
        if(deliveryAddress.postalCode && deliveryAddress.town){
          commaSeparator = ', '
        }

        return {
          title: textTitle,
          textBold: `${deliveryAddress.companyName ?? ''}`,
          text: [(deliveryAddress.firstName ?? '').concat(' ').concat(deliveryAddress.lastName?? '') ?? '', deliveryAddress.line1 ?? '', (deliveryAddress.postalCode ?? '') + commaSeparator + (deliveryAddress.town ?? ''), deliveryAddress.country?.name ?? ''],
        } as Card;
      })
    );
  }

  private normalizeFormattedAddresss(formattedAddress: string): string {
    const addresses = formattedAddress
      .split(',')
      .map((address) => address.trim());

    return addresses.filter(Boolean).join(', ');
  }

  getRequestedDeliveryDate(requestedRetrievalAt: string): Date {
    return new Date(requestedRetrievalAt);
  }

  getRequestedDeliveryDateContent(requestedRetrievalAt: string | null): Observable<Card> {
    return this.translation.translate('cartItems.requestedDeliveryDateTitle').pipe(
      filter(() => Boolean(requestedRetrievalAt)),
      map((textTitle) => {
        return {
          title: textTitle,
          text: [requestedRetrievalAt],
        } as Card;
      })
    );
  }

  override getDeliveryModeCardContent(deliveryMode: DeliveryMode): Observable<Card> {
    return combineLatest([
      this.translation.translate('orderDetails.shippingMethod'),
    ]).pipe(
      filter(() => Boolean(deliveryMode)),
      map(([textTitle]) => rationalDeliveryModeCard(textTitle, deliveryMode)));
  }

  getPaymentConditions(paymentConditions?: String[]):Observable<Card>{
    const condition = (paymentConditions && paymentConditions.length) ? paymentConditions[0] : "";

    return this.translation.translate('checkoutTermsAndConditions.paymentConditions')
    .pipe(
      filter(() => Boolean(condition)),
      map((textTitle) => {
        return {
          title: textTitle,
          text: [condition],
        } as Card;
      })
    );
  }

}
