<ng-container *ngIf="container$ | async as container">
  <ng-container *ngIf="!container.routerData.displayOnly; else displayOnly">
    <div class="cx-add-to-cart-btn-container">
      <ng-container *ngIf="!isCartEntry(container.routerData)">
        <div class="cx-quantity-add-to-cart-container">
          <div class="cx-quantity-add-to-cart-row">
            <div class="cx-quantity">
              <label>{{
                'configurator.addToCart.quantity' | cxTranslate
              }}</label>
              <cx-item-counter [control]="quantityControl"></cx-item-counter>
            </div>
            <button [disabled]="disableAddToCart(container.configuration)"
              class="cx-btn btn btn-block btn-primary cx-add-to-cart-btn"
              (click)="
                onAddToCart(container.configuration, container.routerData)
              "
              [attr.aria-label]="
                (getButtonResourceKey(
                  container.routerData,
                  container.configuration
                ) | cxTranslate) +
                ' ' +
                ('configurator.a11y.addToCartPrices'
                  | cxTranslate: extractConfigPrices(container.configuration))
              "
              title="{{
                getButtonResourceKey(
                  container.routerData,
                  container.configuration
                ) | cxTranslate
              }}"
            >
              <cx-icon [type]="iconType.CART_PLUS"></cx-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isCartEntry(container.routerData)">
        <button
          class="cx-btn btn btn-block btn-primary cx-add-to-cart-btn"
          (click)="onAddToCart(container.configuration, container.routerData)"
          [attr.aria-label]="
            (getButtonResourceKey(
              container.routerData,
              container.configuration,
              isQuoteCartActive() | async
            ) | cxTranslate) +
            ' ' +
            ('configurator.a11y.addToCartPrices'
              | cxTranslate: extractConfigPrices(container.configuration))
          "
        >
          {{
            getButtonResourceKey(
              container.routerData,
              container.configuration,
              isQuoteCartActive() | async
            ) | cxTranslate
          }}
        </button>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #displayOnly>
    <div class="cx-display-only-btn-container">
      <button
        class="cx-btn btn btn-block btn-secondary cx-display-only-btn"
        (click)="leaveConfigurationOverview()"
      >
        {{ 'configurator.addToCart.buttonClose' | cxTranslate }}
      </button>
    </div>
  </ng-template>
</ng-container>
