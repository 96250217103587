import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { FeatureConfigService, WindowRef } from '@spartacus/core';
import { HamburgerMenuService, NavigationUIComponent } from '@spartacus/storefront';
import { RationalIdListPriceService } from './list-price/rational-id-list-price-service';

@Component({
  selector: 'custom-header-links-ui',
  templateUrl: './header-links-ui.component.html',
  styleUrls: ['./header-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HeaderLinksUiComponent extends NavigationUIComponent {
  listPriceOptionIsActivated: Boolean = false;

  constructor(
    router: Router,
    renderer: Renderer2,
    elemRef: ElementRef,
    hamburgerMenuService: HamburgerMenuService,
    winRef: WindowRef,
    protected rationalIdListPriceService: RationalIdListPriceService,

    featureConfigService?: FeatureConfigService
  ) {
    super(
      router,
      renderer,
      elemRef,
      hamburgerMenuService,
      winRef,
      featureConfigService
    );

    this.rationalIdListPriceService.listPriceOptionIsActivated$.subscribe(
      (isActivated) => {
        this.listPriceOptionIsActivated = isActivated;
      }
    );
  }

  showListPriceOnly(): Boolean {
    return this.rationalIdListPriceService.showListPriceOnly;
  }

  toggleShowListPrice() {
    this.rationalIdListPriceService.toggleShowListPrice();
  }

}
