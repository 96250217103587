import { DIALOG_TYPE, LayoutConfig } from "@spartacus/storefront";
import { RationalProductImageZoomDialogComponent } from "./rational-product-image-zoom-dialog/rational-product-image-zoom-dialog.component";

export const rationalDefaultProductImageZoomLayoutConfig: LayoutConfig = {
    launch: {
      PRODUCT_IMAGE_ZOOM: {
        inline: true,
        component: RationalProductImageZoomDialogComponent,
        dialogType: DIALOG_TYPE.DIALOG,
      },
    },
  };