export const orderDetails = {
  statusDisplay_open: 'Jeszcze nie przetworzono',
  statusDisplay_processing: 'Częściowo przetworzone',
  statusDisplay_completed: 'W pełni przetworzone',
  purchaseOrderNumber: 'Własny numer zamówienia',
  orderNumber: 'Numer zamówienia w sklepie internetowym',
  methodOfPayment: 'Metoda płatności',
  shippingMethod: 'Metoda wysyłki',
  placedOn: 'Zamówiono na',
  moreInformation: 'Więcej informacji',
  orderDocuments: 'Dokumenty zamówienia',
  billing: 'Fakturowanie',
  invoiceNumber: 'Numer faktury',
  invoiceDate: 'Data',
  netAmount: 'Kwota netto',
  noBillingInvoices: 'Brak dostępnych informacji rozliczeniowych.',
  noOrderDocuments: 'Dokumenty zamówienia nie są jeszcze dostępne.',
  noInvoiceDocuments: 'Dokumenty rozliczeniowe nie są jeszcze dostępne.',
  noShipmentDocuments: 'Dokumenty wysyłki nie są jeszcze dostępne.',
  shipping: {
    shipping: 'Wysyłka',
    shippingId: 'Identyfikator wysyłki',
    deliveryDate: 'Data wysyłki',
    quantity: 'Ilość',
    tracking: 'Śledzenie',
    noShippingInfo: 'Informacje o wysyłce nie są jeszcze dostępne.',
    noTrackingInfo: 'Informacje o śledzeniu przesyłki nie są jeszcze dostępne.',
  },
  orderId: 'Zamówienie #',
  replenishmentId: 'Uzupełnienie #',
  purchaseOrderId: 'Zamówienie zakupu #',
  emptyPurchaseOrderId: 'Brak',
  none: 'Brak {{value}}',
  placed: 'Umieszczone',
  placedBy: 'Złożone przez',
  unit: 'Jednostka',
  costCenter: 'Centrum kosztów',
  costCenterAndUnit: 'Centrum kosztów / jednostka',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  payByAccount: 'Zapłać przez konto',
  paidByCreditCard: '(płatność kartą kredytową)',
  status: 'Status',
  active: 'Aktywny',
  shippedOn: 'Wysłano w dniu',
  startOn: 'Data rozpoczęcia',
  nextOrderDate: 'Data następnego zamówienia',
  frequency: 'Częstotliwość',
  cancelled: ' Anulowane',
  deliveryStatus_IN_TRANSIT: 'W tranzycie',
  deliveryStatus_READY_FOR_PICKUP: 'Gotowe do odbioru',
  deliveryStatus_READY_FOR_SHIPPING: 'Gotowe do wysyłki',
  deliveryStatus_WAITING: 'Oczekiwanie',
  deliveryStatus_DELIVERING: 'Dostarczanie',
  deliveryStatus_PICKPACK: 'Przygotowanie do wysyłki',
  deliveryStatus_PICKUP_COMPLETE: 'Odbiór zakończony',
  deliveryStatus_DELIVERY_COMPLETED: 'Dostawa zakończona',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Wystawienie płatności',
  deliveryStatus_READY: 'W trakcie realizacji',
  deliveryStatus_DELIVERY_REJECTED: 'Dostawa odrzucona',
  deliveryStatus_SHIPPED: 'Wysłano',
  deliveryStatus_TAX_NOT_COMMITTED: 'Kwestia podatku',
  deliveryStatus_CANCELLED: 'Anulowano',
  statusDisplay_cancelled: 'Anulowano',
  statusDisplay_cancelling: 'Anulowanie oczekujące',
  statusDisplay_confirmed: 'Zamówienie potwierdzone',
  statusDisplay_created: 'Zamówienie utworzone',
  statusDisplay_error: 'Oczekujące',
  statusDisplay_Error: 'Oczekujące',
  statusDisplay_pending: {
    approval: 'Oczekujące na zatwierdzenie',
    merchant: {
      approval: 'Oczekuje na zatwierdzenie sprzedawcy',
    },
  },
  statusDisplay_approved: 'Zatwierdzone',
  statusDisplay_rejected: 'Odrzucone',
  statusDisplay_merchant: {
    approved: 'Sprzedawca zatwierdzony',
    rejected: 'Sprzedawca odrzucony',
  },
  statusDisplay_assigned: {
    admin: 'Przypisany do administratora',
  },
  consignmentTracking: {
    action: 'Śledzenie paczki',
    dialog: {
      header: 'Informacje o śledzeniu',
      shipped: 'Wysłano',
      estimate: 'Szacowana dostawa',
      carrier: 'Usługa doręczenia',
      trackingId: 'Numer przesyłki',
      noTracking: 'Paczka nie została wysłana z magazynu. Informacje o śledzeniu będą dostępne po wysłaniu paczki.',
      loadingHeader: 'Śledzenie przesyłki',
    },
  },
  cancellationAndReturn: {
    returnAction: 'Żądanie zwrotu',
    cancelAction: 'Anulowanie przesyłki',
    item: 'Pozycja',
    itemPrice: 'Cena przedmiotu',
    quantity: 'Maksymalna ilość',
    returnQty: 'Ilość do zwrotu',
    cancelQty: 'Ilość do anulowania',
    setAll: 'Ustaw wszystkie ilości na maksimum',
    totalPrice: 'Łącznie',
    submit: 'Prześlij żądanie',
    returnSuccess: 'Żądanie zwrotu ({{rma}}) zostało przesłane.',
    cancelSuccess:'Żądanie anulowania zostało przesłane (oryginalne zamówienie {{orderCode}} zostanie zaktualizowane)',
  },
  cancelReplenishment: {
    title: 'Anuluj uzupełnienie',
    description: 'Anulować przyszłe zamówienie uzupełnienia?',
    accept: 'Tak',
    reject: 'Nie',
    cancelSuccess: 'Zamówienie uzupełnienia #{{replenishmentOrderCode}} zostało pomyślnie anulowane.',
  },
};

export const orderHistory = {
  orderHistory: 'Historia zamówień',
  orderId: 'Numer zamówienia',
  date: 'Data',
  status: 'Status',
  PONumber: 'Numer P.O.',
  total: 'Razem',
  noOrders: 'Nie mamy jeszcze danych zamówienia dla tego konta.',
  startShopping: 'Rozpocznij zakupy',
  sortBy: 'Sortuj według',
  sortOrders: 'Sortuj zamówienia',
  purchaseOrderNumber: 'Numer P.O.',
  notFound: 'Nie znaleziono zamówień',
  actions: 'Działania',
  filters: {
    orderNumber: 'Numer zamówienia',
    orderNumberPlaceholder: 'Wprowadź numer zamówienia',
    PONumber: 'Numer P.O.',
    PONumberPlaceholder: 'Wprowadź numer P.O.',
    dateRange: 'Zakres dat',
    dateRanges: {
      LAST_30_DAYS: 'Ostatnie 30 dni',
      LAST_3_MONTHS: 'Ostatnie 3 miesiące',
      LAST_6_MONTHS: 'Ostatnie 6 miesięcy',
      CURRENT_YEAR: 'Bieżący rok',
      LAST_YEAR: 'Ostatni rok',
      LAST_2_YEARS: 'Ostatnie 2 lata',
    },
    applyFilters: 'Zastosuj filtry',
    clearFilters: 'Zresetuj filtry',
  },
  jumpToPageLabel: 'Przejdź do',
  jumpToPageInput: 'numer strony',
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'WSZYSTKIE ZAMÓWIENIA ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
