import {Component, ViewEncapsulation, Optional, HostListener, ViewChild, ElementRef } from '@angular/core';
import {CmsSearchBoxComponent, RoutingService, WindowRef} from '@spartacus/core';
import {SearchBoxComponent, SearchBoxComponentService, CmsComponentData} from '@spartacus/storefront';
import {BehaviorSubject} from 'rxjs';
import {RationalAutoCompleteService} from '../../../services/rational-auto-complete-service';

@Component({
  selector: 'cx-searchbox',
  templateUrl: './rational-search-box.component.html',
  styleUrls: ['./rational-search-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalSearchBoxComponent extends SearchBoxComponent {
  public filteredSuggestions: string[] = [];
  private key = 'searchBox';
  showSuggestions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private hideSuggestionsTimeout: any;

  @ViewChild('searchInput') searchInputField!: ElementRef;
  @ViewChild('searchBoxLabel') searchBoxLabel!: ElementRef;

  constructor(protected override searchBoxComponentService: SearchBoxComponentService,
              @Optional()
              protected override componentData: CmsComponentData<CmsSearchBoxComponent>,
              protected override winRef: WindowRef,
              protected override routingService: RoutingService,
              private autoCompleteService: RationalAutoCompleteService) {
    super(searchBoxComponentService, componentData, winRef, routingService);

    this.autoCompleteService.loadUserEnteredValues(this.key);

  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    if (!this.searchBoxLabel?.nativeElement.contains(clickedElement)) {
      this.hideSuggestions();
    }
  }

  onSearchInput(event: Event): void {
    const input = event.target as HTMLInputElement; // Cast event.target as HTMLInputElement
    const value = input.value;

    if (value.length >= 1) {
      this.filteredSuggestions = this.autoCompleteService.getFilteredSuggestions(this.key, value);
      this.autoCompleteService.addUserEnteredValue(this.key, value);
      this.showSuggestions.next(true);
    } else {
      this.filteredSuggestions = [];
      this.showSuggestions.next(false);

    }

  }

  // Handle selection of a suggestion
  selectSuggestion(suggestion: string): void {
    if (suggestion && suggestion !== '' && suggestion.length >= 1) {
      this.searchInputField.nativeElement.value = suggestion;
      this.search(suggestion);

    }
    this.hideSuggestions();
  }

  // Hide suggestions when the input loses focus
  hideSuggestions(): void {
    clearTimeout(this.hideSuggestionsTimeout);
    this.hideSuggestionsTimeout = setTimeout(() => {
      this.filteredSuggestions = [];
      this.showSuggestions.next(false);
    }, 200);
  }

  showAllSuggestedWords(): void {
    this.filteredSuggestions = this.autoCompleteService.getAllSuggestions(this.key);
    this.showSuggestions.next(true);
  }

}
