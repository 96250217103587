export const orderDetails = {
  statusDisplay_open: 'pas encore traité',
  statusDisplay_processing: 'Partiellement traité',
  statusDisplay_completed: 'Complètement traitée',
  purchaseOrderNumber: 'Propre numéro de commande',
  orderNumber: 'Numéro de commande de la boutique en ligne',
  methodOfPayment: 'Mode de paiement',
  shippingMethod: 'Méthode d\'expédition',
  placedOn: 'Commandé le',
  moreInformation: 'Détails de la commande',
  orderDocuments: 'Documents de commande',
  billing: 'Facturation',
  invoiceNumber: 'Numéro de facture',
  invoiceDate: 'Date',
  netAmount: 'Montant net',
  noBillingInvoices: 'Aucune information de facturation disponible pour l\'instant.',
  noOrderDocuments: 'Aucun document de commande n\'est encore disponible.',
  noInvoiceDocuments:'Aucun document de facturation n\'est disponible pour l\'instant.',
  noShipmentDocuments: 'Aucun document d\'expédition n\'est encore disponible.',
  shipping: {
    shipping: 'Expédition',
    shippingId: 'Id d\'expédition',
    deliveryDate: 'Date d\'expédition',
    quantity: 'Quantité',
    tracking: 'Suivi',
    noShippingInfo: 'Aucune information sur l\'expédition n\'est encore disponible.',
    noTrackingInfo: 'Aucune information de suivi n\'est disponible pour l\'instant.'
  },
  orderId: 'Numéro de commande',
  replenishmentId: 'Numéro de réapprovisionnement',
  purchaseOrderId: 'Numéro de commande',
  emptyPurchaseOrderId: 'Aucun',
  none: 'Aucun {{value}}',
  placed: 'Placé',
  placedBy: 'Placé par',
  unit: 'Unité',
  costCenter: 'Centre de coût',
  costCenterAndUnit: 'Centre de coût / Unité',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  payByAccount: 'Payer par compte',
  paidByCreditCard: '(payé par carte de crédit)',
  status: 'Statut',
  active: 'Actif',
  shippedOn: 'Expédié le',
  startOn: 'Début le',
  nextOrderDate: 'Date de la prochaine commande',
  frequency: 'Fréquence',
  cancelled: ' Annulé',
  deliveryStatus_IN_TRANSIT: 'En transit',
  deliveryStatus_READY_FOR_PICKUP: 'Prêt pour l\'enlèvement',
  deliveryStatus_READY_FOR_SHIPPING: 'Prêt pour l\'expédition',
  deliveryStatus_WAITING: 'En attente',
  deliveryStatus_DELIVERING: 'Livraison',
  deliveryStatus_PICKPACK: 'Préparation de l\'envoi',
  deliveryStatus_PICKUP_COMPLETE: 'Enlèvement terminé',
  deliveryStatus_DELIVERY_COMPLETED: 'Livraison terminée',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Émission du paiement',
  deliveryStatus_READY: 'En cours',
  deliveryStatus_DELIVERY_REJECTED: 'Livraison refusée',
  deliveryStatus_SHIPPED: 'Expédié',
  deliveryStatus_TAX_NOT_COMMITTED: 'Question fiscale',
  deliveryStatus_CANCELLED: 'Annulé',
  statusDisplay_cancelled: 'Annulé',
  statusDisplay_cancelling: 'Annulation en attente',
  statusDisplay_confirmed: 'Commande confirmée',
  statusDisplay_created: 'Ordre créé',
  statusDisplay_error: 'En attente',
  statusDisplay_Error: 'En attente',
  statusDisplay_pending: {
    approval: 'Approbation en attente',
    merchant: {
      approval: 'En attente de l\'approbation du commerçant',
    },
  },
  statusDisplay_approved: 'Approuvé',
  statusDisplay_rejected: 'Rejeté',
  statusDisplay_merchant: {
    approved: 'Commerçant approuvé',
    rejected: 'Commerçant rejeté',
  },
  statusDisplay_assigned: {
    admin: 'Attribué à l\'administrateur',
  },
  consignmentTracking: {
    action: 'Suivre le colis',
    dialog: {
      header: 'Informations de suivi',
      shipped: 'Expédié',
      estimate: 'Estimation de la livraison',
      carrier: 'Service de livraison',
      trackingId: 'Numéro de suivi',
      noTracking: 'Le colis n\'a pas été expédié de l\'entrepôt. Les informations de suivi seront disponibles après l\'expédition du colis.',
      loadingHeader: 'Suivi des envois',
    },
  },
  cancellationAndReturn: {
    returnAction: 'Demander un retour',
    cancelAction: 'Annuler un article',
    item: 'Article',
    itemPrice: 'Prix de l\'article',
    quantity: 'Quantité maximale',
    returnQty: 'Quantité à retourner',
    cancelQty: 'Quantité à annuler',
    setAll: 'Fixer toutes les quantités au maximum',
    totalPrice: 'Total',
    submit: 'Soumettre la demande',
    returnSuccess: 'Votre demande de retour ({{rma}}) a été soumise',
    cancelSuccess: 'Votre demande d\'annulation a été soumise (la commande originale {{orderCode}} sera mise à jour)',
  },
  cancelReplenishment: {
    title: 'Annuler le réapprovisionnement',
    description: 'Annuler une future commande de réapprovisionnement ?',
    accept: 'Oui',
    reject: 'Non',
    cancelSuccess: 'La commande de réapprovisionnement #{{replenishmentOrderCode}} a été annulée avec succès.',
  }
};

export const orderHistory = {
  orderHistory: 'Historique de la commande',
  orderId: 'Numéro de commande',
  date: 'Date de la commande',
  status: 'Statut de la commande',
  PONumber: 'Numéro de commande',
  total: 'Total',
  noOrders: 'Nous n\'avons pas encore de données de commande pour ce compte.',
  startShopping: 'Commencer les achats',
  sortBy: 'Trier par',
  sortOrders: 'Trier les commandes',
  purchaseOrderNumber: 'Numéro de commande',
  notFound: 'Aucune commande trouvée',
  actions: 'Actions',
  filters: {
    orderNumber: 'Numéro de commande',
    orderNumberPlaceholder: 'Saisir le numéro de commande',
    PONumber: 'Propre numéro de commande',
    PONumberPlaceholder: 'Saisir son propre numéro de commande',
    dateRange: 'Période',
    dateRanges: {
      LAST_30_DAYS: '30 derniers jours',
      LAST_3_MONTHS: '3 derniers mois',
      LAST_6_MONTHS:'6 derniers mois',
      CURRENT_YEAR:'Année en cours',
      LAST_YEAR: 'Dernière année',
      LAST_2_YEARS: 'Avant-dernière année'
    },
    applyFilters: 'Appliquer le filtre',
    clearFilters: 'Réinitialiser le filtre',
  },
  jumpToPageLabel: 'Sauter à',
  jumpToPageInput: 'Numéro de page'
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'TOUTES LES COMMANDES ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
