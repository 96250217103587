<ng-container
  *ngIf="{
    orderHistory: orders$ | async,
    replenishmentOrder: hasReplenishmentOrder$ | async
  } as type"
>
  <ng-container *ngIf="type.orderHistory">
    <div>
      <!-- HEADER -->
      <div
        [ngClass]="
          type.replenishmentOrder
            ? 'cx-replenishment-details-order-history-header'
            : 'cx-order-history-header'
        "
      >
        <h4 *ngIf="type.replenishmentOrder">
          {{ 'orderHistory.replenishmentHistory' | cxTranslate }}
        </h4>
        <h2 *ngIf="!type.replenishmentOrder">
          {{ 'orderHistory.orderHistory' | cxTranslate }}
        </h2>
      </div>

      <rational-order-history-filters
        (filterListEvent)="changeFilters($event)">
      </rational-order-history-filters>


      <!-- BODY -->
      <div class="cx-order-history-body">
        <ng-container
          *ngIf="(type?.orderHistory?.pagination?.totalResults)! > 0; else noOrder">
          <!-- Select Form and Pagination Top -->
          <div class="row cx-order-history-sort top justify-content-end">
            <ng-container
              *ngIf="(type?.orderHistory?.pagination?.totalPages)! > 1"
            >
              <div class="col-xs-12 col-sm-8 col-md-9 col-lg-10 pr-0">
                <cx-pagination
                [pagination]="type.orderHistory.pagination"
                (viewPageEvent)="pageChange($event)"
                ></cx-pagination>
              </div>

              <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 pl-0 pr-0">
                <label class="cx-order-history-form-group form-group cx-order-history-pagination-jump-to">
                  <span>{{'orderHistory.jumpToPageLabel' | cxTranslate}}</span>
                  <form [formGroup]="filterForm">
                    <input
                      formControlName="paginationNumber"
                      class="form-control "
                      [attr.aria-label]="'orderHistory.jumpToPageInput' | cxTranslate"
                      (keydown.enter)="jumpToPage()"
                    />
                  </form>
                </label>
              </div>

            </ng-container>
          </div>

          <table
            role="table"
            id="order-history-table"
            class="table cx-order-history-table"
            [ngClass]="{ 'cx-order-history-table-po': hasPONumber }"
          >
            <caption class="cx-visually-hidden">
              {{
                'orderHistory.orderHistory' | cxTranslate
              }}
            </caption>
            <thead class="cx-order-history-thead-mobile">
              <tr role="row">
                <th role="columnheader">
                  {{ 'orderHistory.orderId' | cxTranslate }}
                </th>
                <ng-container *ngIf="hasPONumber">
                  <th role="columnheader">
                    {{ 'orderHistory.PONumber' | cxTranslate }}
                  </th>
                  <!-- <th role="columnheader">
                    {{ 'orderHistory.costCenter' | cxTranslate }}
                  </th> -->
                </ng-container>
                <th role="columnheader">
                  {{ 'orderHistory.date' | cxTranslate }}
                </th>
                <th role="columnheader">
                  {{ 'orderHistory.status' | cxTranslate }}
                </th>
                <th role="columnheader">
                  {{ 'orderHistory.total' | cxTranslate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                role="row"
                *ngFor="let order of type.orderHistory.orders"
                (click)="goToOrderDetail(order)"
              >
                <td role="cell" class="cx-order-history-code">
                  <div class="cx-order-history-label">
                    {{ 'orderHistory.orderId' | cxTranslate }}
                  </div>
                  <a
                    [routerLink]="
                      {
                        cxRoute: 'orderDetails',
                        params: order
                      } | cxUrl
                    "
                    class="cx-order-history-value"
                  >
                    {{ order?.code }}</a
                  >
                </td>
                <ng-container *ngIf="hasPONumber">
                  <td role="cell" class="cx-order-history-po">
                    <div class="cx-order-history-label">
                      {{ 'orderHistory.PONumber' | cxTranslate }}
                    </div>
                    <a
                      *ngIf="order.purchaseOrderNumber"
                      [routerLink]="
                        {
                          cxRoute: 'orderDetails',
                          params: order
                        } | cxUrl
                      "
                      class="cx-order-history-value"
                    >
                      {{ order.purchaseOrderNumber }}</a
                    >
                  </td>
                  <!-- <td role="cell" class="cx-order-history-cost-center">
                    <div class="cx-order-history-label">
                      {{ 'orderHistory.costCenter' | cxTranslate }}
                    </div>
                    <a
                      *ngIf="order.costCenter?.name"
                      [routerLink]="
                        {
                          cxRoute: 'orderDetails',
                          params: order
                        } | cxUrl
                      "
                      class="cx-order-history-value"
                    >
                      {{ order.costCenter?.name }}</a
                    >
                  </td> -->
                </ng-container>

                <td role="cell" class="cx-order-history-placed">
                  <div class="cx-order-history-label">
                    {{ 'orderHistory.date' | cxTranslate }}
                  </div>
                  <a
                    [routerLink]="
                      {
                        cxRoute: 'orderDetails',
                        params: order
                      } | cxUrl
                    "
                    class="cx-order-history-value"
                    >{{ order?.placed | cxDate: 'dd.MM.yyyy' }}</a
                  >
                </td>
                <td role="cell" class="cx-order-history-status">
                  <div class="cx-order-history-label">
                    {{ 'orderHistory.status' | cxTranslate }}
                  </div>
                  <a
                    [routerLink]="
                      {
                        cxRoute: 'orderDetails',
                        params: order
                      } | cxUrl
                    "
                    class="cx-order-history-value"
                  >
                    {{
                      'orderDetails.statusDisplay_' + order?.statusDisplay
                        | cxTranslate
                    }}</a
                  >
                </td>
                <td role="cell" class="cx-order-history-total">
                  <div class="cx-order-history-label">
                    {{ 'orderHistory.total' | cxTranslate }}
                  </div>
                  <a
                    [routerLink]="
                      {
                        cxRoute: 'orderDetails',
                        params: order
                      } | cxUrl
                    "
                    class="cx-order-history-value"
                  >
                    {{ order?.total?.formattedValue }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Select Form and Pagination Bottom -->
          <div class="cx-order-history-sort bottom">
            <div
              *ngIf="(type.orderHistory.pagination?.totalPages)! > 1"
              class="cx-order-history-pagination"
            >
              <cx-pagination
                [pagination]="type.orderHistory.pagination"
                (viewPageEvent)="pageChange($event)"
              ></cx-pagination>
            </div>
          </div>
        </ng-container>

        <!-- NO ORDER CONTAINER -->
        <ng-template #noOrder>
          <div
            *ngIf="isLoaded$ | async"
            [ngClass]="
              type.replenishmentOrder
                ? 'cx-replenishment-details-order-history-no-order'
                : 'cx-order-history-no-order'
            "
          >
            <div>
              <ng-container *ngIf="type.replenishmentOrder; else otherOrder">
                <div>{{ 'orderHistory.notFound' | cxTranslate }}</div>
              </ng-container>

              <ng-template #otherOrder>
                <div>{{ 'orderHistory.noOrders' | cxTranslate }}</div>
                <a
                  [routerLink]="{ cxRoute: 'home' } | cxUrl"
                  routerLinkActive="active"
                  class="btn btn-primary btn-block"
                  >{{ 'orderHistory.startShopping' | cxTranslate }}</a
                >
              </ng-template>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>
