<div *ngFor="let consignment of consignments" class="cx-list row">
    <ng-template
        [cxOutlet]="OrderOutlets.ORDER_CONSIGNMENT"
        [cxOutletContext]="{ item: consignment, order: order }">

        <div class="cx-list-header col-12">
<!--            <div class="cx-list-status">-->
<!--                <span *ngIf="consignment">-->
<!--                    {{ 'orderDetails.deliveryStatus_' + consignment?.status | cxTranslate }}-->
<!--                </span>-->
<!--            </div>-->

            <div *ngIf="consignment?.statusDate" class="cx-list-date">
                <div>{{ consignment?.statusDate | cxDate: 'dd.MM.yyyy' }}</div>
            </div>

            <!-- <cx-consignment-tracking
                [orderCode]="order.code!"
                [consignment]="consignment"
                *cxFeature="'consignmentTracking'">
            </cx-consignment-tracking> -->
        </div>
    </ng-template>

    <div class="cx-list-item col-12">
        <rational-cart-item-list
          [items]="consignment.entries!"
          [readonly]="true"
          [promotionLocation]="promotionLocation"
          [options]="{
            displayAddToCart: enableAddToCart,
            addToCartString: buyItAgainTranslation,
            optionalBtn: addToCartBtn
          }"
          [showOrderInformationLinks]="showOrderInformationLinks"
        ></rational-cart-item-list>
    </div>
</div>

<ng-template let-ctx #addToCartBtn>
    <cx-add-to-cart class="add-to-cart"
        [productCode]="ctx.item.product?.code"
        [product]="ctx.item.product"
        [showQuantity]="false"
        [options]="ctx.options"
        [pickupStore]="ctx.item.deliveryPointOfService?.name">
    </cx-add-to-cart>
</ng-template>
