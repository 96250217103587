import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { BackendConfig, FeaturesConfig, I18nConfig, OccConfig, provideConfig, SiteContextConfig } from "@spartacus/core";
import { defaultB2bOccConfig } from "@spartacus/setup";
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from "@spartacus/storefront";
import { environment } from "../../environments/environment";
import { RationalTranslationConfig } from "../../assets/i18n-assets/RationalTransalationsConfig";

const backendConfig: BackendConfig = { occ: {} }
const occConfig: OccConfig = { backend: backendConfig };

if (environment.occBaseUrl) {
  if (occConfig.backend) {
    if (occConfig.backend.occ) {
      occConfig.backend.occ.baseUrl = environment.occBaseUrl;
    }
  }
}

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    provideConfig(layoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig(<OccConfig>{
      backend: occConfig.backend,
    }),
    provideConfig(<SiteContextConfig>{
      context: {
        urlParameters: ['language'],
        baseSite: ['rational-spa'],
        language: ['de', 'en', 'fr', 'it', 'es', 'pl'],
      
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en'
      },
    }),
    provideConfig({
      i18n: RationalTranslationConfig
    }),
    provideConfig(<FeaturesConfig>{
      features: {
        level: '5.2'
      }
    }),
    provideConfig(defaultB2bOccConfig)]
})
export class SpartacusConfigurationModule {
}
