import { Component, ViewEncapsulation } from '@angular/core';
import { FooterNavigationComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-footer-navigation',
  templateUrl: './rational-footer-navigation.component.html',
  styleUrls: ['./rational-footer-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalFooterNavigationComponent extends  FooterNavigationComponent{

}
