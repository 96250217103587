import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Cart, CartOutlets, ActiveCartFacade } from "@spartacus/cart/base/root";
import { CmsComponentData } from "@spartacus/storefront";
import { Observable, Subscription } from "rxjs";
import { CmsRationalCheckoutOrderSummaryComponent } from "src/app/custom/model/rational-cms.model";


@Component({
  selector: 'cx-checkout-order-summary',
  templateUrl: './rational-checkout-order-summary.component.html',
  styleUrls: ['./rational-checkout-order-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCheckoutOrderSummaryComponent {

  cart$: Observable<Cart>;
  public componentData$: Observable<CmsRationalCheckoutOrderSummaryComponent> = this.componentData.data$;
  public subscription: Subscription = new Subscription;
  public infoText: string | undefined;
  protected sanitizer = inject(DomSanitizer);


  readonly cartOutlets = CartOutlets;

  constructor(protected activeCartFacade: ActiveCartFacade, protected componentData: CmsComponentData<CmsRationalCheckoutOrderSummaryComponent>
  ) {
    this.cart$ = this.activeCartFacade.getActive();
  }


  public bypassSecurityTrustHtml(html: string = ''): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }


}