export const orderDetails = {
    statusDisplay_open: 'Ainda não processado',
    statusDisplay_processing: 'Parcialmente processado',
    statusDisplay_completed: 'Completamente processado',
    purchaseOrderNumber: 'Seu número de pedido de compra',
    orderNumber: 'Número do pedido da loja virtual',
    methodOfPayment: 'Método de pagamento',
    shippingMethod: 'Método de envio',
    status: "Status",
    placedOn: 'Colocado em',
    moreInformation: 'Mais informações',
    orderDocuments: 'Documentos do pedido',
    billing: 'Faturamento',
    invoiceNumber: 'Número da fatura',
    invoiceDate: 'Data da fatura',
    netAmount: 'Valor líquido',
    noBillingInvoices: 'Ainda não há informações de faturamento disponíveis.',
    noOrderDocuments: 'Ainda não há documentos de pedido disponíveis.',
    noInvoiceDocuments: 'Ainda não há documentos de faturamento disponíveis.',
    noShipmentDocuments: 'Ainda não há documentos de remessa disponíveis.',
    shipping: {
      shipping: 'Envio',
      shippingId: 'Identificação da remessa',
      deliveryDate: 'Data de expedição/retirada',
      quantity: 'Quantidade',
      tracking: 'Rastreamento',
      noShippingInfo: 'Ainda não há informações de envio disponíveis.',
      noTrackingInfo: 'Ainda não há informações de rastreamento disponíveis.'
    },
    orderId: "Número do pedido",
  replenishmentId: "Número de reabastecimento",
  purchaseOrderId: "Número do pedido de compra",
  emptyPurchaseOrderId: "Nenhum",
  none: "Nenhum {{value}}",
  placed: "Inserido",
  placedBy: "Inserido por",
  unit: "Unidade",
  costCenter: "Centro de custo",
  costCenterAndUnit: 'Centro de custo / unidade',
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  payByAccount: "Pagamento por conta",
  paidByCreditCard: "(pago com cartão de crédito)",
  active: "Ativo",
  shippedOn: "Enviado em",
  startOn: "Início em",
  nextOrderDate: "Data do próximo pedido",
  frequency: "Frequência",
  cancelled: "Cancelado",
  deliveryStatus_IN_TRANSIT: "Em trânsito",
  deliveryStatus_READY_FOR_PICKUP: "Pronto para coleta",
  deliveryStatus_READY_FOR_SHIPPING: "Pronto para envio",
  deliveryStatus_WAITING: "Em espera",
  deliveryStatus_DELIVERING: "Entrega",
  deliveryStatus_PICKPACK: "Preparando para remessa",
  deliveryStatus_PICKUP_COMPLETE: "Retirada concluída",
  deliveryStatus_DELIVERY_COMPLETED: "Entrega concluída",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "Emissão de pagamento",
  deliveryStatus_READY: "Em processo",
  deliveryStatus_DELIVERY_REJECTED: "Entrega rejeitada",
  deliveryStatus_SHIPPED: "Enviado",
  deliveryStatus_TAX_NOT_COMMITTED: "Emissão de imposto",
  deliveryStatus_CANCELLED: "Cancelado",
  statusDisplay_cancelled: "Cancelado",
  statusDisplay_cancelling: "Cancelamento pendente",
  statusDisplay_confirmed: "Pedido confirmado",
  statusDisplay_created: "Pedido criado",
  statusDisplay_error: "Pendente",
  statusDisplay_Error: "Pendente",
  statusDisplay_pending: {
      approval: "Aprovação pendente",
      merchant: {
          Approval: "Aprovação pendente do comerciante"
      }
  },
  statusDisplay_approved: "Aprovado",
  statusDisplay_rejected: "Rejeitado",
  statusDisplay_merchant: {
      Approved: "Comerciante aprovado",
      Rejected: "Comerciante rejeitado",
  },
  statusDisplay_assigned: {
      admin: "Atribuído ao administrador"
  },
  consignmentTracking: {
    action: "Rastrear pacote",
    dialog: {
      header: "Informações de rastreamento",
      shipped: "Enviado",
      estimate: "Entrega estimada",
      carrier: "Serviço de entrega",
      trackingId: "Número de rastreamento",
      noTracking: "O pacote ainda não foi despachado do armazém. As informações de rastreamento estarão disponíveis depois que o pacote for enviado.",
      loadingHeader: "Rastreamento de consignação"
    }
  },
  cancellationAndReturn: {
    returnAction: "Solicitar uma devolução",
    cancelAction: "Cancelar itens",
    item: "Item",
    itemPrice: "Preço do item",
    quantity: "Quantidade máxima",
    returnQty: "Quantidade a ser devolvida",
    cancelQty: "Quantidade a ser cancelada",
    setAll: "Definir todas as quantidades como máximas",
    totalPrice: "Total",
    submit: "Enviar solicitação",
    returnSuccess: "Sua solicitação de devolução ({{rma}}) foi enviada",
    cancelSuccess: "Sua solicitação de cancelamento foi enviada (o pedido original {{orderCode}} será atualizado)"
  },
  cancelReplenishment: {
    title: "Cancelar reabastecimento",
    description: "Cancelar qualquer pedido de reabastecimento futuro?",
    accept: "Sim",
    reject: "Não",
    cancelSuccess: "O pedido de reabastecimento #{{replenishmentOrderCode}} foi cancelado com sucesso"
  }
  };
  
  export const orderHistory = {
    orderHistory: 'Histórico do pedido',
    orderId: 'Número do pedido',
    date: 'Data do pedido',
    status: 'Status do pedido',
    PONumber: 'Número da ordem de compra',
    total: 'Total',
    noOrders: 'Ainda não temos dados de pedidos para essa conta.',
    startShopping: 'Iniciar compras',
    sortBy: 'Ordenar por',
    sortOrders: 'Ordenar pedidos',
    purchaseOrderNumber: 'Número da ordem de compra',
    notFound: 'Nenhum pedido encontrado',
    actions: 'Ações',
    filters: {
      orderNumber: 'Número do pedido',
      orderNumberPlaceholder: 'Digite o número do pedido',
      PONumber: 'Número da ordem de compra',
      PONumberPlaceholder: 'Digite o número da ordem de compra',
      dateRange: 'Intervalo de datas',
      dateRanges: {
        LAST_30_DAYS: 'Últimos 30 dias',
        LAST_3_MONTHS: 'Últimos 3 meses',
        LAST_6_MONTHS:'Últimos 6 meses',
        CURRENT_YEAR:'Ano atual',
        LAST_YEAR: 'Último ano',
        LAST_2_YEARS: 'Ano anterior ao último'
      },
      applyFilters: 'Aplicar filtros',
      clearFilters: 'Redefinir filtros',
    },
    jumpToPageLabel: 'Ir para',
    jumpToPageInput: 'Número da página'
  };
  
  export const AccountOrderHistoryTabContainer = {
    tabs: {
      AccountOrderHistoryComponent: 'TODOS OS PEDIDOS ({{param}})'
    }
  };
  
  export const order = {
    orderDetails,
    orderHistory,
    AccountOrderHistoryTabContainer
  };
  