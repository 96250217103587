export const orderApprovalDetails = {
    back: "リストに戻る",
    showForm_APPROVE: "注文を承認する",
    showForm_REJECT: "注文を拒否する",
    form: {
        title_APPROVE: "注文 {{orderCode}} の合計 {{orderTotal}} を承認します。",
        title_REJECT: "注文{{orderCode}}の合計{{orderTotal}}を拒否します。",
        submit_APPROVE: "承認",
        submit_REJECT: "拒否",
        cancel: "キャンセル",
        comment_APPROVE: {
            label: "コメント（オプション、最大255文字）",
            placeholder: "コメント（最大255文字）"
        },
        comment_REJECT: {
            label: "コメント（最大255文字）",
            placeholder: "コメント（最大255文字）"
        }
    },
    permissionResults: {
        header: "顧客承認の詳細",
        permission: "許可",
        approver: "承認者",
        status: "ステータス",
        approverComments: "承認者コメント",
        noApprovalComments: "該当なし",
        permissionType_B2BBudgetExceededPermission: "選択したコストセンターに関連する予算が超過している",
        permissionType_B2BOrderThresholdPermission: "注文の合計が注文ごとの制限を超えました",
        permissionType_B2BOrderThresholdTimespanPermission: "注文の合計が期間ごとの制限を超えました"
    }
};

export const orderApproval = {
    orderApprovalDetails,
};
