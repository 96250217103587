import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
} from '@spartacus/checkout/base/components';
import {
  CmsConfig,
  I18nModule,
  UrlModule,
  provideConfig,
} from '@spartacus/core';
import {
  CardModule,
  
  IconModule,
  OutletModule,
  PromotionsModule,
} from '@spartacus/storefront';
import { RationalCheckoutReviewSubmitComponent } from './checkout-review-submit.component';
import { RationalCartSharedModule } from '../../cart/base/cart-item/rational-cart-shared.module';

@NgModule({
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                CheckoutReviewOrder: {
                    component: RationalCheckoutReviewSubmitComponent,
                    guards: [CheckoutAuthGuard, CartNotEmptyGuard],
                },
            },
        })
    ],
    declarations: [RationalCheckoutReviewSubmitComponent],
    exports: [RationalCheckoutReviewSubmitComponent],
    imports: [
        CommonModule,
        CardModule,
        I18nModule,
        UrlModule,
        RouterModule,
        PromotionsModule,
        IconModule,
        OutletModule,
        RationalCartSharedModule
    ]
})
export class RationalCheckoutReviewSubmitModule {}
