import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {AddToCartComponent} from "@spartacus/cart/base/components/add-to-cart";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import { filter } from 'rxjs/operators';
import { isNotNullable } from '@spartacus/core';

@Component({
  selector: 'cx-add-to-cart',
  templateUrl: './rational-add-to-cart.component.html',
  styleUrls: ['./rational-add-to-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class RationalAddToCartComponent extends AddToCartComponent {
  salesUnit!: string;
  hideAddToCartBtn:boolean = false;

  override quantity = 1;
  
  override ngOnInit() {
    if (this.product) {
      this.productCode = this.product.code ?? '';
      this.setStockInfo(this.product);
      this.cd.markForCheck();
      this.hideAddToCartBtn = this.product.isBaseProduct;
    } else if (this.productCode) {
      // force hasStock and quantity for the time being, as we do not have more info:
      this.quantity = 1;
      this.hasStock = true;
      this.cd.markForCheck();
    } else {
      this.subscription = (this.productListItemContext? this.productListItemContext.product$: this.currentProductService.getProduct())
        .pipe(filter(isNotNullable))
        .subscribe((product) => {
          this.salesUnit = product?.salesUnit ?? '';
          this.productCode = product!.code ?? '';
          this.setStockInfo(product!);
          this.hideAddToCartBtn = product!.isBaseProduct;
          this.cd.markForCheck();
        });
    }
  }

  override addToCartForm = new UntypedFormGroup({
    quantity: new UntypedFormControl(1, { updateOn: 'blur' }, null),
  });

}
