import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WishListItemComponent } from '@spartacus/cart/wish-list/components';
import { ProductListItemContext, ProductListItemContextSource } from '@spartacus/storefront';

@Component({
  selector: '[cx-wish-list-item], cx-wish-list-item',
  templateUrl: './rational-wish-list-item.component.html',
  styleUrls: ['./rational-wish-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class RationalWishListItemComponent extends WishListItemComponent {

}
