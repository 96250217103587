export const orderDetails = {
  statusDisplay_open: 'Jeszcze nie przetworzono',
  statusDisplay_processing: 'Częściowo przetworzone',
  statusDisplay_completed: 'W pełni przetworzone',
  purchaseOrderNumber: 'Własny numer zamówienia',
  orderNumber: 'Numer zamówienia w sklepie internetowym',
  methodOfPayment: 'Metoda płatności',
  shippingMethod: 'Metoda wysyłki',
  placedOn: 'Zamówiono na',
  moreInformation: 'Więcej informacji',
  orderDocuments: 'Dokumenty zamówienia',
  billing: 'Fakturowanie',
  invoiceNumber: 'Numer faktury',
  invoiceDate: 'Data',
  netAmount: 'Kwota netto',
  noBillingInvoices: 'Brak dostępnych informacji rozliczeniowych.',
  noOrderDocuments: 'Dokumenty zamówienia nie są jeszcze dostępne.',
  noInvoiceDocuments:'Dokumenty rozliczeniowe nie są jeszcze dostępne.',
  noShipmentDocuments: 'Dokumenty wysyłki nie są jeszcze dostępne.',
  shipping: {
    shipping: 'Wysyłka',
    shippingId: 'Identyfikator wysyłki',
    deliveryDate: 'Data wysyłki',
    quantity: 'Ilość',
    tracking: 'Śledzenie',
    noShippingInfo: 'Informacje o wysyłce nie są jeszcze dostępne.',
    noTrackingInfo: 'Informacje o śledzeniu przesyłki nie są jeszcze dostępne.'
  },
};

export const orderHistory = {
  orderHistory: 'Historia zamówień',
  orderId: 'Numer zamówienia',
  date: 'Data',
  status: 'Status',
  PONumber: 'Numer P.O.',
  total: 'Razem',
  noOrders: 'Nie mamy jeszcze danych zamówienia dla tego konta.',
  startShopping: 'Rozpocznij zakupy',
  sortBy: 'Sortuj według',
  sortOrders: 'Sortuj zamówienia',
  purchaseOrderNumber: 'Numer P.O.',
  notFound: 'Nie znaleziono zamówień',
  actions: 'Działania',
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'WSZYSTKIE ZAMÓWIENIA ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
