export const quotes = {
  created: 'Angebot wurde erstellt',
  title: 'Angebote',
  code: 'Code',
  name: 'Name',
  date: 'Datum',
  state: 'Status',
  statusDisplay_BUYER_APPROVED: 'Käufer - Genehmight',
  statusDisplay_BUYER_DRAFT: 'Käufer - Entwurf',
  statusDisplay_BUYER_SUBMITTED: 'Käufer - Eingereicht',
  statusDisplay_BUYER_OFFER: 'Käufer - Angebot',
  statusDisplay_BUYER_ACCEPTED: 'Käufer - Akzeptiert',
  statusDisplay_BUYER_REJECTED: 'Käufer - Abgelehnt',
  statusDisplay_BUYER_ORDERED: 'Käufer - Bestellt',
  statusDisplay_SELLER_REQUEST: 'Verkäufer - Anfrage',
  statusDisplay_SELLER_DRAFT: 'Verkäufer - Entwurf',
  statusDisplay_SELLER_SUBMITTED: 'Verkäufer Eingereicht',
  statusDisplay_SELLERAPPROVER_DRAFT: 'Genehmigender Verkäufer - Entwurf',
  statusDisplay_SELLERAPPROVER_PENDING: 'Genehmigender Verkäufer - Ausstehend',
  statusDisplay_SELLERAPPROVER_REJECTED: 'Genehmigender Verkäufer - Abgelehnt',
  statusDisplay_SELLERAPPROVER_APPROVED: 'Genehmigender Verkäufer - Akzeptiert',
  noQuotes: 'Keine Angebote',
  entries: {
    code: 'Code',
    name: 'Name',
    amount: 'Menge',
    price: 'Preis'
  },
  totalPriceWithoutTax: 'Gesamtpreis ohne Steuer:'
}

