export const exportEntries = {
  exportEntries: {
    exportToCsv: "导出为 CSV文件",
    exportMessage: "CSV 文件将自动下载到您的设备，如果没有下载，请检查您的浏览器设置",
    columnNames: {
      code: "代码",
      quantity: "数量",
      name: "名称",
      price: "价格"
    },
    exportProductToCsv: "将购物车导出为 CSV文件"
  }
}

export const importEntries = {
  importEntries: {
    importProducts: "导入购物车"
  }
}

export const importEntriesDialog = {
  importEntriesDialog: {
    importProducts: "通过导入 .CSV 或 .XLSX 文件导入产品",
    importProductsSubtitle: "通过导入 .CSV 或 .XLSX 文件导入产品",
    importProductsNewSavedCartSubtitle: "导入的文件应包括产品列表，其中数量和货号列用分号隔开。",
    importProductFileDetails: "选择文件",
    selectFile: "保存的购物车名称",
    savedCartName: "保存的购物车描述",
    savedCartDescription: "可选",
    charactersLeft: "剩余字符数： {{count}}",
    cancel: "取消",
    upload: "上传",
    close: "关闭",
    summary: {
      info: "导入产品时，请勿关闭或刷新此窗口。",
      loadedToCart: "产品已载入购物车 {{ cartName }}",
      loaded: "产品已载入",
      loading: "正在处理产品... ({{ count }}/{{ total }})",
      successes: "'{{ successesCount }} {{ total }} 中的 {{ successesCount }} 产品已成功导入。",
      warning: "{{ count }} 产品未全部导入。",
      warning_other: "{{ count }} 产品未全部导入。",
      error: "未导入 {{ count }} 产品。",
      error_other: "未导入 {{ count }} 产品。",
      messages: {
        unknownIdentifier: "产品 SKU '{{ productCode}}' 不存在。",
        lowStock: "{{ productName }} 的数量： {{ quantity }} 已减少为 {{ quantityAdded }}。",
        noStock: "{{ productName }} 当前缺货。",
        unknownError: "无法识别'{{ productCode }}'的问题。",
        limitExceeded: "无法添加‘{{ productCode }}’。已超过限制。"
      },
      show: "显示",
      hide: "隐藏"
    }
  }
}
