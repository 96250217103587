import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { RationalConfiguratorProductTitleComponent } from './rational-configurator-product-title.component';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        CommonModule,
        I18nModule,
        IconModule,
        MediaModule,
        ConfigModule.withConfig({
            cmsComponents: {
                ConfiguratorProductTitle: {
                    component: RationalConfiguratorProductTitleComponent,
                },
            }
        } as CmsConfig)
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                ConfiguratorProductTitle: {
                    component: RationalConfiguratorProductTitleComponent,
                },
            },
        }),
    ],
    declarations: [RationalConfiguratorProductTitleComponent],
    exports: [RationalConfiguratorProductTitleComponent],
})
export class RationalConfiguratorProductTitleModule { }
