export const addressForm = {
  title: 'Název',
  defaultTitle: 'Název',
  firstName: {
    label: 'Jméno',
    placeholder: 'Křestní jméno',
  },
  lastName: {
    label: 'Příjmení',
    placeholder: 'Příjmení',
  },
  companyName: 'Název společnosti',
  companyNamePlaceholder: 'Vyhledat název společnosti',
  address1: 'Adresa 1',
  address2: 'Adresa 2 (nepovinné)',
  country: 'Země',
  city: {
    label: 'Město',
    placeholder: 'Město',
  },
  state: 'Stát',
  zipCode: {
    label: 'Poštovní směrovací číslo',
    placeholder: 'Poštovní směrovací číslo / PSČ',
  },
  phoneNumber: {
    label: 'Telefonní číslo',
    placeholder: '(555) 555 - 0123',
  },
  saveAsDefault: 'Uložit jako výchozí',
  chooseAddress: 'Zvolte adresu',
  streetAddress: 'Ulice',
  aptSuite: 'Číslo',
  selectOne: 'Vyberte jednu z nich...',
  setAsDefault: 'Nastavit jako výchozí',
  titleRequired: 'Je vyžadován název.',
  userAddressAddSuccess: 'Nová adresa byla úspěšně přidána!',
  userAddressUpdateSuccess: 'Adresa byla úspěšně aktualizována!',
  userAddressDeleteSuccess: 'Adresa byla úspěšně odstraněna!',
  invalidAddress: 'Neplatná adresa',
};

export const addressBook = {
  addNewShippingAddress: 'Přidání nové doručovací adresy',
  editShippingAddress: 'Upravit dodací adresu',
  areYouSureToDeleteAddress: 'Opravdu chcete tuto adresu smazat?',
  addNewAddress: 'Přidat novou adresu',
  addAddress: 'Přidat adresu',
  updateAddress: 'Aktualizovat adresu',
  backToAddressList: 'Zpět na seznam adres',
};

export const addressCard = {
  default: 'DEFAULT',
  selected: 'Vybrané',
  setAsDefault: 'Nastavit jako výchozí',
  shipTo: 'Odeslat do',
  billTo: 'Fakturovat na',
};

export const addressSuggestion = {
  verifyYourAddress: 'Ověřte svou adresu',
  ensureAccuracySuggestChange: 'Pro zajištění přesnosti doručení doporučujeme níže zvolenou změnu.',
  chooseAddressToUse: 'Vyberte prosím, kterou adresu chcete použít:',
  suggestedAddress: 'Navrhovaná adresa',
  enteredAddress: 'Zadaná adresa',
  editAddress: 'Upravit adresu',
  saveAddress: 'Uložit adresu',
};

export const address = {
    addressForm,
    addressBook,
    addressSuggestion,
    addressCard
  }
