export const productDetails = {
    id: "Artikelnummer",
    quantity: "Antal",
    productDetails: "Produktdetaljer",
    specification: "Tekniska egenskaper",
    reviews: "Recensioner",
    shipping: "Frakt",
    share: "Aktie",
    showReviews: "Visa recensioner",
    noReviews: "",
    productPrice: "Produktens pris",
    priceLoading: "Produktens pris är beräknat",
    productRelations: {
        productRelationsHeader: "Kompatibla tillbehör"
    },
    disclaimer: {
        title: 'Bildanvändning Disclaimer:',
        text: 'De bilder som visas på denna webbplats är endast för referensändamål och representerar inte de faktiska egenskaperna eller omfattningen av produkterna. För detaljerade specifikationer, egenskaper och tillgång till produkterna, se produktens textbeskrivning.'
    }
};

export const productList = {
    filterBy: {
        label: "Filtrera efter",
        action: "Filtrera efter"
    },
    appliedFilter: "Tillämpat filter:",
    showLess: "Visa mindre...",
    showMore: "Visa mer...",
    sortBy: "Sortera efter",
    backToTopBtn: "TILLBAKA TILL TOPPEN",
    showMoreBtn: "VISA MER",
    productSearchPagination: "Paginering av produktsökning"
};

export const productFacetNavigation = {
    filterBy: {
        label: "Filtrera efter",
        action: "Filtrera efter"
    },
    appliedFilter: "Tillämpat filter:",
    showLess: "Visa mindre...",
    showMore: "Visa mer av...",
    sortBy: "Sortera efter"
};

export const productSummary = {
    id: "Artikelnummer",
    showReviews: "Visa recensioner",
    share: "Dela",
    newItemPrice: "Pris för ny artikel",
    discount: "De visade priserna inkluderar din individuella rabatt på {{discount}}.",
    productSubstitutionAndProductExists: "Produkt {{product1}} ersätts med produkt {{product2}}, så du omdirigeras till produkt {{product2}}.",
    productSubstitutionAndProductDoesntExist: "Produkt {{product1}} ersätts med produkt {{product2}}, men {{product2}} är för närvarande inte tillgänglig"
};

export const productReview = {
    overallRating: "Övergripande betyg",
    reviewTitle: "Titel på recension",
    writeYourComments: "Skriv dina kommentarer",
    rating: "Betyg",
    reviewerName: "Granskarens namn (valfritt)",
    writeReview: "Skriv en recension",
    more: "Visa fler recensioner",
    less: "Visa färre recensioner",
    thankYouForReview: "Tack för recensionen! Observera att recensioner kan behöva granskas innan de visas här."
};

export const addToCart = {
    itemsAddedToYourCart: "Artikel(n) tillagd i din kundvagn",
    itemsIncrementedInYourCart: "Den här artikeln fanns redan i din varukorg. Kvantiteten uppdaterades.",
    items: "Artikel",
    updatingCart: "Uppdaterar kundvagnen...",
    addToCart: "Lägg till i kundvagn",
    viewCart: "Visa varukorg",
    proceedToCheckout: "Fortsätt till kassan",
    quantity: "Antal",
    outOfStock: "Slut på lager",
    inStock: "I lager",
    selectStyleAndSize: "Välj modell och storlek för att kontrollera lager",
    removeFromCart: "Ta bort produkten från varukorgen",
    closeModal: "Stäng dialogrutan"
};

export const TabPanelContainer = {
    tabs: {
        ProductDetailsTabComponent: "Produktdetaljer",
        ProductSpecsTabComponent: "Tekniska egenskaper",
        ProductReviewsTabComponent: "Recensioner",
        deliveryTab: "Frakt",
        ProductDownloadsTabComponent: "Nedladdningar"
    }
};

export const addToWishList = {
    add: "Lägg till i önskelistan",
    remove: "Ta bort från önskelistan",
    anonymous: "Logga in för att lägga till i önskelistan"
};

export const itemCounter = {
    removeOne: "Ta bort en",
    addOneMore: "Lägg till ytterligare en",
    quantity: "Kvantitet"
};

export const productView = {
    gridView: "Välj för att ändra till rutnätsvy",
    listView: "Välj för att ändra till listvy"
};

export const standardDelivery = {
    standardDeliveryRange: "Redo att skickas med {{from}} till {{to}} dagar"
};

export const downloads = {
    downloads: {
        noDownloads: "Inga nedladdningar för denna produkt",
        documentTitle: "Dokumentets titel",
        toolbox: "Mer i verktygslådan",
        allDocuments: "Alla dokument",
        languagesDropdown: "Språk",
        originalFileName: "Filnamn",
        languages: "Språk",
        readableTypeMime: "Mime-typ",
        fileSize: "Filstorlek",
        download: "Nedladdning"
    }
};

export const productReferences = {
    productReferences: {
        installationvariants: "Variationer i uppställning",
        feedingsystems: "Lastningssystem",
        groomingaccessoires: "Underhållsprodukter",
        cookaccessoire: "Matlagningstillbehör",
        cookaccessoirespecial: "Specialtillbehör",
        extractorhood: "Ventilationskåpor",
        deviceinstallation: "Enhet Installation"
    }
};

export const product = {
    productDetails,
    productList,
    productFacetNavigation,
    productSummary,
    productReview,
    addToCart,
    TabPanelContainer,
    addToWishList,
    itemCounter,
    productView,
};
