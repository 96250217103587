import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressButtonModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { RationalSharedCartNotificationComponent } from './shared-cart-notification.component';


@NgModule({
  declarations: [RationalSharedCartNotificationComponent],
  exports: [RationalSharedCartNotificationComponent],
  imports: [
    CommonModule,
    ProgressButtonModule,
    RouterModule,
    I18nModule,
    UrlModule,
  ],
  providers: [],
})
export class RationalSharedCartNotificationModule { }
