export const wishlist = {
  wishlist: {
    caption: 'Lista dei preferiti',
    empty: 'Non ci sono ancora prodotti nella tua lista dei preferiti'
  },
  cartItems: {
    actions: 'Azioni'
  }
}

