<div *ngIf="trackingDocuments$ | async as trackingDocuments; else shippingsloadingSpinner">
    <ng-container *ngIf="trackingDocuments && trackingDocuments.length > 0; else noTrackingInfo">
        <ng-container *ngFor="let trackingDocument of trackingDocuments; let i = index">
            <div class="trackingNumber">
                <a href="{{trackingDocument.trackingUrl}}" class="trackingNumberLink" target="_blank">{{ trackingDocument.trackingNumber}}</a><br>
            </div>
        </ng-container>
    </ng-container>
</div>
<ng-template #shippingsloadingSpinner>
    <div class="cx-spinner">
        <cx-spinner></cx-spinner>
    </div>
</ng-template>
<ng-template #noTrackingInfo>
    <div class="emptyMessage">
        {{ 'orderDetails.shipping.noTrackingInfo' | cxTranslate }}
    </div>
</ng-template>