export const paymentForm = {
    payment: "Ödeme",
    choosePaymentMethod: "Bir ödeme yöntemi seçin",
    paymentType: "Ödeme Tipi",
    accountHolderName: {
        label: "Hesap Sahibinin Adı",
        placeholder: "Hesap Sahibinin Adı"
    },
    cardNumber: "Kart Numarası",
    expirationDate: "Son Kullanma Tarihi",
    securityCode: "Güvenlik kodu (CVV)",
    securityCodeTitle: "Kart Doğrulama Değeri",
    saveAsDefault: "Varsayılan olarak kaydet",
    setAsDefault: "Varsayılan olarak ayarla",
    billingAddress: "Fatura adresi",
    sameAsShippingAddress: "Gönderi adresi ile aynı",
    selectOne: "Birini Seçin...",
    monthMask: "AA",
    yearMask: "YYYY",
    expirationYear: "Sona erme yılı {{ selected }}",
    expirationMonth: "Son kullanma ayı {{ selected }}",
    useThisPayment: "Bu ödemeyi kullanın",
    addNewPayment: "Yeni Ödeme Ekle",
    changePayment: "Ödeme Değişikliği"
};

export const paymentMethods = {
    paymentMethods: "Ödeme yöntemleri",
    newPaymentMethodsAreAddedDuringCheckout: "Ödeme sırasında yeni ödeme yöntemleri eklenir.",
    invalidField: "InvalidField: {{ field }}"
};

export const paymentCard = {
    deleteConfirmation: "Bu ödeme yöntemini silmek istediğinizden emin misiniz?",
    setAsDefault: "Varsayılan olarak ayarla",
    expires: "Son kullanma tarihi: {{ month }}/{{ year }}",
    defaultPaymentMethod: "✓ DEFAULT",
    selected: "Seçilmiş"
};

export const paymentTypes = {
    title: "Ödeme yöntemi",
    paymentType_CARD: "Kredi Kartı",
    paymentType_ACCOUNT: "Hesap",
    paymentType_INVOICE: "Fatura"
};

export const payment = {
    paymentForm,
    paymentMethods,
    paymentCard,
    paymentTypes
};
