 export const cartDetails= {
  id: 'ID',
  proceedToCheckout: 'Procedere all\'acquisto',
  createQuoteFromCart: 'Richiedere un\'offerta',
  cartName: 'Carrello #{{code}}',
  minOrderNotReached: 'Il valore minimo dell\'ordine è {{minOrderValue}}{{currency}}.',
  orderLocked: 'Attenzione: Al momento non è possibile effettuare ordini! Si prega di contattare il servizio clienti. Abbiamo riscontrato dei problemi con questo profilo utente.',
  requestedRetrievalDateEmpty: 'È necessario scegliere una data di spedizione/ritiro desiderata',
  requestedRetrievalDateAdded: 'La data di spedizione/ritiro richiesta è stata aggiunta con successo',
  requestedRetrievalDateInvalid: 'La data di spedizione/ritiro richiesta non è valida',
  s4omScheduleLinesQuantity: 'Quantità',
  s4omScheduleLinesEntryInfo: 'La data di spedizione/ritiro confermata di - {{ quantity }} articolo/i - è {{ date }}',
  cartSubjectToPriceChange: 'Attenzione: i prezzi degli apparecchi appena configurati sono prezzi di listino. Riceverete il prezzo a voi riservato con la conferma d\'ordine.'
}

export const cartItems = {
  id: 'Codice articolo',
  description: 'Descrizione',
  item: 'Articolo',
  itemPrice: 'Prezzo articolo',
  quantity: 'Quantità',
  equipmentNumber: 'Numero dell\'articolo: ',
  quantityTitle: 'La quantità corrisponde al numero totale di questo articolo nel carrello.',
  total: 'Totale',
  cartTotal: 'Totale carrello ({{count}} articolo)',
  cartTotal_plural: 'Totale carrello ({{count}} articoli)',
  actions: 'Azioni',
  salesUnit: 'UM',
  reference: 'Riferimento:',
  referencePlaceHolder : 'Riferimento',
  additionalContactEmail : 'Email aggiuntiva:',
  additionalContactEmailPlaceHolder : 'Email aggiuntiva',
  comment : 'Note:',
  commentPlaceHolder : 'Note',
  additionalContactEmailWarning : 'Inserire un indirizzo e-mail valido. (Le e-mail non valide non verranno salvate).',
  requestedDeliveryDate : 'Data di spedizione richiesta/Ritiro:',
  requestedDeliveryDateTitle : 'Data di spedizione richiesta/Ritiro',
  substitutionProductNotFound : 'Questo articolo è stato sostituito con un altro codice ma non è attualmente disponibile'
}

export const orderCost = {
  orderSummary: 'Riepilogo dell\'ordine',
  subtotal: 'Subtotale:',
  shipping: 'Spedizione:',
  estimatedShipping: 'Data di spedizione stimata:',
  discount: 'Hai risparmiato:',
  salesTax: 'IVA:',
  grossTax: 'Il totale dell\'ordine non include l\'IVA di',
  grossIncludeTax: 'Il totale dell\'ordine include l\'IVA di',
  total: 'Totale:',
  toBeDetermined: 'Da definire',
  requestedDeliveryDate: 'Data di spedizione/Ritiro richiesta*',
  taxStatement: '*Tutti i prezzi indicati sono netti e specifici per il cliente, imposte escluse. Il prezzo totale finale, imposte incluse, vi verrà comunicato via e-mail con la conferma d\'ordine poiché i prezzi  sono ricalcolati al momento dell\'elaborazione dell\'ordine.',
  taxStatement2: 'Quando si ordinano accessori o parti di ricambio può essere applicato un supplemento se il valore netto, IVA esclusa, non raggiunge il valore minimo dell\'ordine.'
}

export const voucher = {
  coupon: 'Avete un coupon?',
  coupon_plural: 'Codici coupon',
  apply: 'Applicare',
  placeholder: 'Codice promozionale',
  applyVoucherSuccess: '{{voucherCode}} è stato applicato.',
  removeVoucherSuccess: 'Il codice {{voucherCode}} è stato rimosso.',
  anchorLabel: 'Inserisci o rimuovi il tuo codice coupon',
  vouchersApplied: 'Tagliandi applicati',
  availableCoupons: 'Tagliandi disponibili',
  availableCouponsLabel: 'È possibile aggiungere questi coupon a questo ordine.'
}

export const saveForLaterItems = {
  itemTotal: 'Salvato per dopo ({{count}} articolo)',
  itemTotal_plural: 'Salvato per dopo ({{count}} articoli)',
  cartTitle: 'Carrello',
  saveForLater: 'Salvare per dopo',
  moveToCart: 'Spostare nel carrello',
  stock: 'Disponibilità',
  forceInStock: 'Disponibile'
}

export const cart = {
  cartDetails,
  cartItems,
  orderCost,
  voucher,
  saveForLaterItems
}
