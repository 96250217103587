import { Injectable } from '@angular/core';
import { ProductDownloadsOCCService } from './product-downloads-occ.service';
import { catchError } from 'rxjs/operators';
import { ProductDownloadsResponse } from './product-downloads-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductDownloadsService {
  constructor(private productDownloadsOCCService: ProductDownloadsOCCService) {}

  getProductDownloads(productCode: String, languageIsoCode: String): Observable<ProductDownloadsResponse> {
    return this.productDownloadsOCCService
      .getProductDownloads(productCode, languageIsoCode)
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }
}
