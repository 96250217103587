import { Component } from '@angular/core';
import { AddedToCartDialogComponent } from '@spartacus/cart/base/components';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RationalCart } from 'src/app/custom/model/rational-cart.model';


@Component({
  selector: 'cx-added-to-cart-dialog',
  templateUrl: './rational-added-to-cart-dialog.component.html',
  styleUrls: ['./rational-added-to-cart-dialog.component.scss']
})
export class RationalAddedToCartDialogComponent extends AddedToCartDialogComponent {
  rationalCart$: Observable<RationalCart> = this.activeCartFacade.getActive().pipe(
    map((cart) => (cart as RationalCart))
  );
}
