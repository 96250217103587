import { Component } from '@angular/core';
import { WishListComponent } from '@spartacus/cart/wish-list/components';
import { Observable } from 'rxjs';

@Component({
  selector: 'cx-wish-list',
  templateUrl: './rational-wish-list.component.html',
  styleUrls: ['./rational-wish-list.component.scss']
})
export class RationalWishListComponent extends WishListComponent{
}
