export const orderDetails = {
    orderId: "Objednávka #",
    orderNumber: "Číslo objednávky ve webovém obchodě",
    replenishmentId: "Doplnění #",
    purchaseOrderId: "Objednávka #",
    purchaseOrderNumber: "Číslo vaší nákupní objednávky",
    emptyPurchaseOrderId: "Žádné",
    none: "Žádný {{value}}",
    placed: "Umístěno",
    placedBy: "Zadáno",
    unit: "Jednotka",
    costCenter: "Nákladové středisko",
    costCenterAndUnit: 'Nákladové středisko / jednotka',
    costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
    methodOfPayment: "Způsob platby",
    payByAccount: "Platba podle účtu",
    paidByCreditCard: "(placeno kreditní kartou)",
    status: "Stav",
    active: "Aktivní",
    shippedOn: "Odesláno dne",
    shippingMethod: "Způsob dopravy",
    placedOn: "Uloženo na",
    startOn: "Začít na",
    nextOrderDate: "Datum příští objednávky",
    frequency: "Frekvence",
    cancelled: "Zrušeno",
    deliveryStatus_IN_TRANSIT: "V tranzitu",
    deliveryStatus_READY_FOR_PICKUP: "Připraveno k vyzvednutí",
    deliveryStatus_READY_FOR_SHIPPING: "Připraveno k odeslání",
    deliveryStatus_WAITING: "Čeká na",
    deliveryStatus_DELIVERING: "Doručení",
    deliveryStatus_PICKPACK: "Příprava k odeslání",
    deliveryStatus_PICKUP_COMPLETE: "Vyzvednutí dokončeno",
    deliveryStatus_DELIVERY_COMPLETED: "Doručení dokončeno",
    deliveryStatus_PAYMENT_NOT_CAPTURED: "Vystavení platebního dokladu",
    deliveryStatus_READY: "V procesu",
    deliveryStatus_DELIVERY_REJECTED: "Dodávka odmítnuta",
    deliveryStatus_SHIPPED: "Odesláno",
    deliveryStatus_TAX_NOT_COMMITTED: "Daňový problém",
    deliveryStatus_CANCELLED: "Zrušeno",
    statusDisplay_cancelled: "Zrušeno",
    statusDisplay_cancelling: "Zrušit čeká na vyřízení",
    statusDisplay_confirmed: "Objednávka potvrzena",
    statusDisplay_completed: "Objednávka doručena",
    statusDisplay_created: "Vytvořená objednávka",
    statusDisplay_error: "Čeká na vyřízení",
    statusDisplay_Error: "Čeká na vyřízení",
    statusDisplay_processing: "Vytvořená objednávka",
    statusDisplay_open: "Vytvořená objednávka",
    statusDisplay_pending: {
        approval: "Čeká na schválení",
        merchant: {
            Approval: "Čeká na schválení obchodníkem"
        }
    },
    statusDisplay_approved: "Schváleno",
    statusDisplay_rejected: "Zamítnuto",
    statusDisplay_merchant: {
        Approved: "Obchodník schválen",
        Rejected: "Obchodník zamítnuto",
    },
    statusDisplay_assigned: {
        admin: "Přiřazeno správci"
    },
    consignmentTracking: {
      action: "Sledovat balíček",
      dialog: {
        header: "Informace o sledování",
        shipped: "Odesláno",
        estimate: "Odhadované doručení",
        carrier: "Doručovací služba",
        trackingId: "Sledovací číslo",
        noTracking: "Balíček nebyl odeslán ze skladu. Informace o sledování budou k dispozici po odeslání balíčku.",
        loadingHeader: "Sledování zásilky"
      }
    },
    cancellationAndReturn: {
      returnAction: "Žádost o vrácení zboží",
      cancelAction: "Zrušení položek",
      item: "Položka",
      itemPrice: "Cena položky",
      quantity: "Maximální množství",
      returnQty: "Množství k vrácení",
      cancelQty: "Množství ke zrušení",
      setAll: "Nastavte všechna množství na maximální hodnotu",
      totalPrice: "Celkem",
      submit: "Odeslat požadavek",
      returnSuccess: "Váš požadavek na vrácení zboží ({{rma}}) byl odeslán",
      cancelSuccess: "Byl odeslán váš požadavek na zrušení objednávky (původní objednávka {{orderCode}} bude aktualizována)."
    },
    cancelReplenishment: {
      title: "Zrušit doplnění",
      description: "Zrušit jakoukoli budoucí objednávku na doplnění?",
      accept: "Ano",
      reject: "Ne",
      cancelSuccess: "Objednávka doplnění #{{replenishmentOrderCode}} byla úspěšně zrušena."
    }
  };
  
export const orderHistory = {
    orderHistory: "Historie objednávek",
    orderId: "Objednávka #",
    placedBy: "Kontaktní osoba",
    emptyPurchaseOrderId: "Žádný",
    date: "Datum",
    status: "Stav",
    total: "Celkem",
    noOrders: "K tomuto účtu nemáme žádné záznamy o objednávkách.",
    noReplenishmentOrders: "Pro tento účet nemáme žádné záznamy o objednávkách na doplnění.",
    startShopping: "Zahájení nakupování",
    sortBy: "Seřadit podle",
    replenishmentOrderHistory: "Historie objednávek doplnění",
    replenishmentOrderId: "Doplnění #",
    purchaseOrderNumber: "PO #",
    startOn: "Začít v",
    frequency: "Frekvence",
    nextOrderDate: "Datum příští objednávky",
    cancel: "Zrušit",
    cancelled: "Zrušeno",
    replenishmentHistory: "Historie doplňování",
    notFound: "Nebyly nalezeny žádné objednávky"
  };
  
export const wishlist = {
    empty: "Ve vašem seznamu přání zatím nejsou žádné produkty"
  };
  
export const myAccount = {
    orderDetails,
    orderHistory,
    wishlist
};
