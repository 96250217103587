import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AddToCartModule } from "@spartacus/cart/base/components/add-to-cart";
import { CmsConfig, FeaturesConfigModule, I18nModule, provideConfig, UrlModule } from "@spartacus/core";
import {
  AtMessageModule,
  CardModule,
  IconModule, ItemCounterModule,
  OutletModule, PromotionsModule,
  provideOutlet,
  SpinnerModule
} from "@spartacus/storefront";
import {
  RationalSavedCartDetialsOverviewComponent
} from "./rational-saved-cart-detials-overview/rational-saved-cart-detials-overview.component";
import {
  RationalSavedCartDetialsItemsComponent
} from './rational-saved-cart-detials-items/rational-saved-cart-detials-items.component';
import {
  RationalSavedCartDetialsItemListComponent
} from './rational-saved-cart-detials-item-list/rational-saved-cart-detials-item-list.component';
import {
  RationalSavedCartDetialsItemListRowComponent
} from './rational-saved-cart-detials-item-list-row/rational-saved-cart-detials-item-list-row.component';
import { SavedCartOutlets } from "../savedcart-outlets.module";
import { CartCouponModule, CartSharedModule } from "@spartacus/cart/base/components";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RationalCartItemValidationWarningModule } from "../../validation/rational-cart-item-validation-warning/rational-cart-item-validation-warning.module";
import { RationalSavedCartDetialsActionComponent } from './rational-saved-cart-detials-action/rational-saved-cart-detials-action.component';
import { RationalMediaModule } from "../../cmscomponents/media/rational-media.module";
import { ProductDescriptionModule } from "../../product/product-description/product-description.module";
import { RationalSavedCartDetailsExportEntriesComponent } from "./rational-saved-cart-details-export-entries/rational-saved-cart-details-export-entries.component";
import { ExportOrderEntriesModule } from "@spartacus/cart/import-export/components";
import { RationalCartQuickOrderFormModule } from "../../cart/quick-order/rational-cart-quick-order-form/rational-cart-quick-order-form.module";


@NgModule({
  imports: [
    I18nModule,
    UrlModule,
    RouterModule,
    CardModule,
    IconModule,
    SpinnerModule,
    OutletModule,
    AddToCartModule,
    CommonModule,
    FormsModule,
    //CartSharedModule,
    AtMessageModule,
    CartCouponModule,
    FeaturesConfigModule,
    ItemCounterModule,
    PromotionsModule,
    ReactiveFormsModule,
    RationalCartItemValidationWarningModule,
    RationalMediaModule,
    ProductDescriptionModule,
    ExportOrderEntriesModule,
    RationalCartQuickOrderFormModule
  ],
  declarations: [
    RationalSavedCartDetialsOverviewComponent,
    RationalSavedCartDetialsItemsComponent,
    RationalSavedCartDetialsItemListComponent,
    RationalSavedCartDetialsItemListRowComponent,
    RationalSavedCartDetialsActionComponent,
    RationalSavedCartDetailsExportEntriesComponent
  ],
  exports: [
    RationalSavedCartDetialsOverviewComponent,
    RationalSavedCartDetialsItemsComponent,
    RationalSavedCartDetialsItemListComponent,
    RationalSavedCartDetialsItemListRowComponent,
    RationalSavedCartDetialsActionComponent,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        SavedCartDetailsOverviewComponent: {
          component: RationalSavedCartDetialsOverviewComponent,
        },
        SavedCartDetailsItemsComponent: {
          component: RationalSavedCartDetialsItemsComponent,
        },
        SavedCartDetailsActionComponent: {
          component: RationalSavedCartDetialsActionComponent,
        },
      },
    }),

    provideOutlet({
      id: SavedCartOutlets.CART_ITEM_LIST,
      component: RationalSavedCartDetialsItemListComponent,
    }),
  ],
})
export class RationalSavedCartDetailsModule {

}
