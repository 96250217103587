export const orderDetails = {
  orderId: 'Auftrag #',
  orderNumber: 'RATIONAL Bestellnummer',
  replenishmentId: 'Nachtrag #',
  purchaseOrderId: 'Auftragsbestätigung #',
  purchaseOrderNumber: 'Eigene Bestellnummer',
  emptyPurchaseOrderId: 'Keine',
  none: 'Keine {{value}}',
  placed: 'Angelegt',
  placedBy: 'Angelegt von',
  unit: 'Unit',
  costCenter: 'Kostenstelle',
  costCenterAndUnit: 'Kostenstelle und Einheit',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  methodOfPayment: 'Zahlungsmethode',
  payByAccount: 'Zahlen auf Rechnung',
  paidByCreditCard: '(mit Kreditkarte gezahlt)',
  status: 'Status',
  active: 'Aktiv',
  shippedOn: 'Versanddatum',
  shippingMethod: 'Versandmethode',
  placedOn: 'Bestellt am',
  startOn: 'Startdatum',
  nextOrderDate: 'Nächster Bestelltermin',
  frequency: 'Zeitabstand',
  cancelled: 'Abgebrochen',
  deliveryStatus_IN_TRANSIT: 'In Bearbeitung',
  deliveryStatus_READY_FOR_PICKUP: 'Abholbereit',
  deliveryStatus_READY_FOR_SHIPPING: 'Versandfertig',
  deliveryStatus_WAITING: 'Wartezustand',
  deliveryStatus_DELIVERING: 'In Zustellung',
  deliveryStatus_PICKPACK: 'Vorbereitung für den Versand',
  deliveryStatus_PICKUP_COMPLETE: 'Abholung erfolgt',
  deliveryStatus_DELIVERY_COMPLETED: 'Lieferung komplett',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Zahlungsfehler',
  deliveryStatus_READY: 'In Arbeit',
  deliveryStatus_DELIVERY_REJECTED: 'Lieferung abgelehnt',
  deliveryStatus_SHIPPED: 'Versand',
  deliveryStatus_TAX_NOT_COMMITTED: 'Steuer fehlerhaft',
  deliveryStatus_CANCELLED: 'Abgebrochen',
  statusDisplay_cancelled: 'Abgebrochen',
  statusDisplay_cancelling: 'Abbruch läuft',
  statusDisplay_confirmed: 'Bestellung bestätigt',
  statusDisplay_completed: 'Bestellung geliefert',
  statusDisplay_created: 'Bestellung angelegt',
  statusDisplay_error: 'Ausstehend',
  statusDisplay_Error: 'Ausstehend',
  statusDisplay_processing: 'Bestellung angelegt',
  statusDisplay_open: 'Bestellung angelegt',
  statusDisplay_pending: {
    approval: 'Genehmigung ausstehend',
    merchant: {
      approval: 'Händlergenehmigung steht aus'
    }
  },
  statusDisplay_approved: 'Genehmigt',
  statusDisplay_rejected: 'Abgelehnt',
  statusDisplay_merchant: {
    approved: 'Kunde Genehmigt',
    rejected: 'Kunde Abgelehnt'
  },
  statusDisplay_assigned: {
    admin: 'Dem Administrator zugewiesen'
  },
  consignmentTracking: {
    action: 'Sendungsverfolgung',
    dialog: {
      header: 'Tracking Informationen',
      shipped: 'Versendet',
      estimate: 'Vorraussichtliche Lieferung',
      carrier: 'Zustelldienst',
      trackingId: 'Tracking Nummer',
      noTracking: 'Das Paket hat das Lager noch nicht verlassen. Die Tracking Information steht nach dem Versand zur Verfügung.',
      loadingHeader: 'Sendungsverfolgung'
    }
  },
  cancellationAndReturn: {
    returnAction: 'Retoure anmelden',
    cancelAction: 'Artikel stornieren',
    item: 'Artikel',
    itemPrice: 'Artikel Preis',
    quantity: 'Max Menge',
    returnQty: 'Menge zum retounieren',
    cancelQty: 'Zu stornierende Menge',
    setAll: 'Alle Mengen maximieren',
    totalPrice: 'Gesamt',
    submit: 'Anfrage bestätigen',
    returnSuccess: 'Ihre Retoure ({{rma}}) wurde bestätigt',
    cancelSuccess: 'Ihre Stornierung wurde bestätigt (der Auftrag {{orderCode}} wird aktualisiert)'
  },
  cancelReplenishment: {
    title: 'Auffüllauftrag stornieren',
    description: 'Möchten Sie einen späteren Auffüllauftrag stornieren?',
    accept: 'Ja',
    reject: 'Nein',
    cancelSuccess: 'Auffüllauftrag #{{replenishmentOrderCode}} erfolgreich abgebrochen'
  }
}

export const orderHistory = {
  orderHistory: 'Bestellhistorie',
  orderId: 'Auftrag #',
  placedBy: 'Ansprechpartner',
  emptyPurchaseOrderId: 'Keine',
  date: 'Datum',
  status: 'Status',
  total: 'Gesamt',
  noOrders: 'Für den Kunden wurden keine Bestellungen gefunden.',
  noReplenishmentOrders: 'Für den Kunden wurde kein Auffüllauftrag gefunden.',
  startShopping: 'Einkauf starten',
  sortBy: 'Sortieren nach',
  replenishmentOrderHistory: 'Auffüllauftrag Historie',
  replenishmentOrderId: 'Auffüllauftrag #',
  purchaseOrderNumber: 'PO #',
  startOn: 'Startdatum',
  frequency: 'Zeitabstand',
  nextOrderDate: 'Nächster Bestelltermin',
  cancel: 'Abbrechen',
  cancelled: 'Abgebrochen',
  replenishmentHistory: 'Auffüllung historie',
  notFound: 'Keine Aufträge gefunden'
}

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'Eigene',
    OrderReturnRequestListComponent: 'ANGEBOTE'
  }
}

export const wishlist = {
  empty: 'Es befinden sich keine Artikel in Ihrer Wunschliste'
}

export const myAccount = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer,
  wishlist
}
