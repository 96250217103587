import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CartActions } from '@spartacus/cart/base/core';
import { Cart } from '@spartacus/cart/base/root';
import { LoggerService, normalizeHttpError } from '@spartacus/core';
import { RationalSavedCartService } from '../../saved-cart/rational-saved-cart.service';
import { RationalSavedCartActions } from '../../saved-cart/action/index';
import { SavedCartActions } from '@spartacus/cart/saved-cart/core';
import { RationalCartConnector } from '../../saved-cart/connector/rational-cart.connector';


/**
 * This effect is responsible for reloading the cart after a saved cart is successfully saved.
 * @remarks
 * This effect listens to the SAVE_CART_SUCCESS action and creates a new effect that clones the saved cart.
 * The cloned saved cart is then used to reload the cart, which updates the cart with the latest saved cart data.
 */

@Injectable()
export class RationalMultiCartEffects {
  constructor(
    private actions$: Actions,
    protected rationalSavedCartService: RationalSavedCartService,
    private rationalCartConnector: RationalCartConnector
  ) { }

  protected logger = inject(LoggerService);



  /**
   * An observable that listens to the SAVE_CART_SUCCESS action and triggers a clone of the saved cart.
   * @returns An array containing the cloned saved cart action.
   */
  reloadCartAfterSafe$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(SavedCartActions.SAVE_CART_SUCCESS),

      switchMap((action: SavedCartActions.SaveCartSuccess) => {

        const userId = action.payload.userId;
        const cartId = action.payload.cartId;
        const saveCartName = action.payload.saveCartName;


        return [
          new SavedCartActions.CloneSavedCart({
            userId, cartId, saveCartName
          })
        ]
      })));


    saveCarts$: Observable<
    | SavedCartActions.SaveCartFail
    | SavedCartActions.SaveCartSuccess
    | SavedCartActions.SaveCart
    | CartActions.LoadCartSuccess
    | CartActions.ClearCartState
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(RationalSavedCartActions.SAVE_SHARED_CART),
      map((action: RationalSavedCartActions.SaveCart) => action.payload),
      switchMap(({ userId, cartId, saveCartName, saveCartDescription, isSharedWithB2BUnit}) => {
        return this.rationalCartConnector
          .save(userId, cartId, saveCartName, saveCartDescription, isSharedWithB2BUnit)
          .pipe(
            switchMap((savedCart: Cart) => {
              return [
                new CartActions.ClearCartState(),
                new CartActions.LoadCartSuccess({
                  userId,
                  cartId,
                  cart: savedCart,
                }),
                new SavedCartActions.SaveCartSuccess({
                  userId,
                  cartId,
                  saveCartName,
                  saveCartDescription,
                }),
              ];
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                new SavedCartActions.SaveCartFail({
                  userId,
                  cartId,
                  saveCartName,
                  saveCartDescription,
                  error: normalizeHttpError(error, this.logger),
                })
              )
            )
          );
      })
    )
  );
    
}