import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { RationalIncotermsAndPaymentConditionsComponent } from './checkout-incoterms-and-payment-conditions.component';

@NgModule({
  imports: [CommonModule, I18nModule],
  declarations: [RationalIncotermsAndPaymentConditionsComponent],
  exports: [RationalIncotermsAndPaymentConditionsComponent],
})
export class RationalIncotermsAndPaymentConditionsModule {}
