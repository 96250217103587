export const exportEntries = {
  exportEntries: {
    exportToCsv: 'Esportare in CSV',
    exportMessage: 'Il file CSV verrà scaricato automaticamente sul dispositivo',
    columnNames: {
      code: 'Codice',
      quantity: 'Quantità',
      name: 'Nome',
      price: 'Prezzo',
    },
    exportProductToCsv: 'Esportare il carrello in CSV',
  }
}

export const importEntries = {
  importEntries: {
    importProducts: 'Importazione del carrello',
  }
}

export const importEntriesDialog = {
  importEntriesDialog: {
    importProducts: 'Importazione del carrello',
    importProductsSubtitle: 'Aggiungere i prodotti caricando un file .CSV o .XLSX',
    importProductsNewSavedCartSubtitle: 'Aggiungere i prodotti caricando un file .CSV o .XLSX',
    importProductFileDetails: 'Il file importato deve includere un elenco di prodotti con le colonne Quantità e Codice Articolo separati da un punto e virgola.',
    selectFile: 'Selezionare il file',
    savedCartName: 'Nome del carrello salvato',
    savedCartDescription: 'Descrizione del carrello salvato',
    optional: 'opzionale',
    charactersLeft: 'caratteri rimasti: {{count}}',
    cancel: 'Annullare',
    upload: 'Caricare',
    close: 'Chiudere',
    summary: {
      info: 'Non chiudere o aggiornare questa finestra durante il trasferimento dei prodotti.',
      loadedToCart: 'I prodotti sono stati caricati nel carrello {{ cartName }}',
      loaded: 'I prodotti sono stati caricati',
      loading: 'I prodotti sono in fase di elaborazione... ({{ count }}/{{ total }})',
      successes: '{{ successesCount }} prodotti su {{ total }} sono stati trasferiti con successo.',
      warning: '{{ count }} prodotto non è stato trasferito completamente.',
      warning_other: '{{ count }} prodotti non sono stati trasferiti completamente.',
      error: '{{ count }} prodotto non è stato trasferito.',
      error_other: '{{ count }} prodotti non sono stati trasferiti.',
      messages: {
        unknownIdentifier: 'Il codice prodotto SKU "{{ productCode}}" non esiste.',
        lowStock: 'La quantità per {{productName }}: {{ quantity }} è stata ridotta a {{ quantityAdded }}.',
        noStock: '{{productName }} è attualmente esaurito.',
        unknownError: 'Problema non riconosciuto con "{{ productCode }}".',
        limitExceeded: 'Impossibile aggiungere "{{ productCode }}". Limite superato.',
      },
      show: 'Mostrare',
      hide: 'Nascondere',
    },
  },
};
