import { NgModule } from '@angular/core';
import { LayoutModule } from "./layout/layout.module";
import { CmsComponentsModule } from "./cmscomponents/cmscomponents.module";
import { SearchresultModule } from "./searchresult/searchresult.module";
import { MetaresolverModule } from "./metaresolver/metaresolver.module";
import { ProductModule } from "./product/product.module";
import { ExportOrderEntriesModule } from './cart/import-export/components/export-entries/export-order-entries.module';
import { RationalImportOrderEntriesModule } from './cart/import-export/components/import-entries/rational-import-order-entries.module';
import { RationalLoginModule } from './login/rational-login-module';
import { RationalSavedCartDetailsModule } from './saved-cart/details/rational-saved-cart-details.module';
import { RationalSavedCartListModule } from './saved-cart/list/saved-cart-list.module';
import { RationalCartQuickOrderFormModule } from './cart/quick-order/rational-cart-quick-order-form/rational-cart-quick-order-form.module';
import { RationalCheckoutReviewSubmitModule } from './checkout/checkout-review-submit/checkout-review-submit.module';
import { RationalOrderConfirmationModule } from './order/order-confirmation/order-confirmation.module';
import { RationalWishListModule } from './wish-list/rational-wish-list.module';
import { RationalCartBaseModule } from './cart/base/cart-base.module';
import { RationalAnonymousConsentManagementModule } from './cmscomponents/rational-anonymous-consent-management/rational-anonymous-consent-management.module';
import { RationalIdModule } from './rational-id/rational-id.module';
import { RationalBackendOccEndPointModule } from './rational-backend-occ-endpoint/rational-backend-occ-endpoint.module';
import { RationalReturnOrderModule } from './order/return-order/return-order.module';
import { RationalProductRelationsModule } from "./product/rational-product-relations/rational-product-relations.module";
import { RationalCheckoutPaymentTypeModule } from './checkout/checkout-payment-type/checkout-payment-type.module';
import { HttpInterceptorsModule } from './http-interceptors/http-interceptors.module';
import { RationalIncotermsAndPaymentConditionsModule } from './checkout/checkout-incoterms-and-payment-conditions/checkout-incoterms-and-payment-conditions.module';
import { RationalCheckoutDeliveryAddressModule } from './checkout/checkout-delivery-address/checkout-delivery-address.module';
import { RationalOrderOverviewModule } from './order/order-overview/order-overview.module';
import { RationalCheckoutPlaceOrderModule } from './order/checkout-place-order/checkout-place-order.module';
import { OrderFacade } from '@spartacus/order/root';
import { RationalOrderFacade } from './order/checkout-place-order/checkout-place-order.facade';
import { RationalOrderService } from './order/checkout-place-order/checkout-place-order.service';
import { OrderConnector, OrderService } from '@spartacus/order/core';
import { RationalOrderConnector } from './order/checkout-place-order/checkout-place-order.connector';
import { RationalOrderHistoryModule } from './order/order-history/rational-order-history.module';
import { provideConfig } from '@spartacus/core';
import { CART_BASE_FEATURE } from '@spartacus/cart/base/root';
import { RationalOrderDetailsModule } from './order/order-details/rational-order-details.module';
import { RationalProductSummaryModule } from './product/product-summary/rational-product-summary.module';
import { RationalOrderDocumentModule } from './order/order-document/rational-order-document.module';
import { RationalSavedCartService } from './saved-cart/rational-saved-cart.service';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { RationalCheckoutDeliveryModeModule } from './checkout/checkout-delivery-mode/checkout-delivery-mode.module';
import { RationalConfiguratorPriceSummaryModule } from './product-configurator/price-summary/rational-configurator-price-summary.module';
import { RationalVariantCarouselModule } from './product-configurator/variant-carousel/rational-variant-carousel.module';
import { RationalConfiguratorOverviewAttributeModule } from './product-configurator/overview-attribute/rational-overview-attribute/rational-configurator-overview-attribute.module';
import { RationalConfiguratorOverviewFormModule } from './product-configurator/overview-form/rational-configurator-overview-form/rational-configurator-overview-form.module';
import { RationalSavedCartFormDialogModule } from './cart/saved-cart-form-dialog/saved-cart-form-dialog.module';
import { RationalImportExportOrderEntriesModule } from './cart/import-export/components/import-entries/entries';
import { RationalConfiguratorProductTitleModule } from './product-configurator/product-title/rational-configurator-product-title/rational-configurator-product-title.module';
import { RationalConfiguratorCartEntryInfoModule } from './product-configurator/rational-configurator-cart-entry-info/rational-configurator-cart-entry-info.module';
import { RationalConfiguratorAddToCartButtonModule } from './product-configurator/add-to-cart-button/rational-configurator-add-to-cart-button.module';

@NgModule({
  providers: [
    {
      provide: OrderFacade,
      useExisting: RationalOrderFacade,
    },
    {
      provide: RationalOrderFacade,
      useExisting: RationalOrderService,
    },
    {
      provide: OrderConnector,
      useExisting: RationalOrderConnector,
    },
    {
      provide: OrderService,
      useExisting: RationalOrderService,
    },
    RationalSavedCartService,
    {
      provide: SavedCartFacade,
      useExisting: RationalSavedCartService,
    },
    provideConfig({
      featureModules: {
        [CART_BASE_FEATURE]: {
          module: () =>
            import('./cart/base/cart-entry/rational-cart-entry.module').then(
              (m) => m.RationalCartEntryModule
            ),
        },
      },
    })
  ],
  imports: [
    LayoutModule,
    CmsComponentsModule,
    SearchresultModule,
    MetaresolverModule,
    ProductModule,
    ExportOrderEntriesModule,
    RationalImportOrderEntriesModule,
    RationalSavedCartDetailsModule,
    RationalSavedCartListModule,
    RationalCartQuickOrderFormModule,
    RationalCheckoutReviewSubmitModule,
    RationalCheckoutDeliveryAddressModule,
    RationalCheckoutPaymentTypeModule,
    RationalCheckoutDeliveryModeModule,
    RationalIncotermsAndPaymentConditionsModule,
    RationalOrderConfirmationModule,
    RationalOrderOverviewModule,
    RationalCheckoutPlaceOrderModule,
    RationalLoginModule,
    RationalWishListModule,
    RationalCartBaseModule,
    RationalAnonymousConsentManagementModule,
    RationalBackendOccEndPointModule,
    RationalReturnOrderModule,
    RationalAnonymousConsentManagementModule,
    RationalIdModule,
    RationalProductRelationsModule,
    HttpInterceptorsModule,
    RationalOrderHistoryModule,
    RationalOrderDetailsModule,
    RationalProductSummaryModule,
    RationalOrderDocumentModule,
    RationalConfiguratorPriceSummaryModule,
    RationalVariantCarouselModule,
    RationalConfiguratorOverviewAttributeModule,
    RationalConfiguratorOverviewFormModule,
    RationalConfiguratorProductTitleModule,
    RationalVariantCarouselModule,
    RationalSavedCartFormDialogModule,
    RationalImportExportOrderEntriesModule,
    RationalConfiguratorCartEntryInfoModule,
    RationalConfiguratorAddToCartButtonModule
  ],
  exports: [
    LayoutModule,
    CmsComponentsModule,
    SearchresultModule,
    MetaresolverModule,
    ProductModule,
    ExportOrderEntriesModule,
    RationalImportOrderEntriesModule,
    RationalSavedCartDetailsModule,
    RationalSavedCartListModule,
    RationalLoginModule,
    RationalCartQuickOrderFormModule,
    RationalCheckoutReviewSubmitModule,
    RationalCheckoutDeliveryAddressModule,
    RationalCheckoutPaymentTypeModule,
    RationalCheckoutDeliveryModeModule,
    RationalIncotermsAndPaymentConditionsModule,
    RationalOrderConfirmationModule,
    RationalOrderOverviewModule,
    RationalCheckoutPlaceOrderModule,
    RationalWishListModule,
    RationalCartBaseModule,
    RationalAnonymousConsentManagementModule,
    RationalReturnOrderModule,
    RationalAnonymousConsentManagementModule,
    RationalIdModule,
    HttpInterceptorsModule,
    RationalOrderHistoryModule,
    RationalOrderDetailsModule,
    RationalProductSummaryModule,
    RationalOrderDocumentModule,
    RationalConfiguratorPriceSummaryModule,
    RationalVariantCarouselModule,
    RationalConfiguratorOverviewAttributeModule,
    RationalConfiguratorOverviewFormModule,
    RationalSavedCartFormDialogModule,
    RationalImportExportOrderEntriesModule,
    RationalConfiguratorProductTitleModule,
    RationalConfiguratorCartEntryInfoModule,
    RationalConfiguratorAddToCartButtonModule
   ]
})
export class CustomModule { }
