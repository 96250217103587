import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { ProductVariantColorSelectorModule, ProductVariantSizeSelectorModule, ProductVariantStyleSelectorModule } from '@spartacus/product/variants/components';
import { RationalProductVariantsContainerComponent } from './rational-product-variants-container.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ProductVariantStyleSelectorModule,
    ProductVariantSizeSelectorModule,
    ProductVariantColorSelectorModule,
  ],
  declarations: [RationalProductVariantsContainerComponent],
})
export class RationalProductVariantsContainerModule {}
