export const configurator = {
  configurator: {
    header: {
      consistent: "Konzistentní",
      complete: "Kompletní",
      configId: "ID konfigurace",
      toconfig: "Konfigurace",
      toConfigReadOnly: "Zobrazit podrobnosti",
      editConfiguration: "Upravit konfiguraci",
      displayConfiguration: "Zobrazit konfiguraci",
      resolveIssues: "Vyřešit problémy",
      resolveIssue: "Vyřešit problém",
      resolveConflicts: "Vyřešit konflikty",
      conflictWarning: "Konflikt musí být vyřešen, aby bylo možné pokračovat",
      updateMessage: "Konfigurace se aktualizuje na pozadí",
      conflictsResolved: "Konflikty byly vyřešeny",
      showMore: "Zobrazit více",
      showLess: "zobrazit méně",
      items: "{{count}} položka",
      items_other: "{{count}} položky",
      show: "Zobrazit",
      hide: "skrýt",
      multipleWarnings: "Existuje více varování.",
      reviewWarnings: "Prohlédněte si tato varování",
      multipleErrors: "Existuje více chyb.",
      reviewErrors: "Prohlédněte si tyto chyby",
      kbKeyName: "Název kb",
      kbKeyLogsys: "Logický systém Kb",
      kbKeyVersion: "Verze Kb",
      kbKeyBuildNr: "Číslo sestavení Kb",
    },
    tabBar: {
      configuration: "Konfigurace",
      overview: "Přehled",
    },
    notificationBanner: {
      numberOfIssues: "{{count}} Problém musí být vyřešen před odhlášením.",
      numberOfIssues_other: "{{count}} Problémy musí být vyřešeny před odbavením.",
      numberOfConflicts: "{{count}} Konflikt musí být vyřešen před odbavením.",
      numberOfConflicts_other: "{{count}} Konflikty musí být vyřešeny před odhlášením.",
    },
    attribute: {
      id: "ID",
      quantity: "Množství",
      caption: "Atributy",
      notSupported: "Typ atributu není podporován.",
      requiredAttribute: "{{param}} povinné",
      defaultRequiredMessage: "Zadejte hodnotu pro požadované pole",
      singleSelectRequiredMessage: "Vyberte hodnotu",
      singleSelectAdditionalRequiredMessage: "Vyberte nebo zadejte hodnotu",
      multiSelectRequiredMessage: "Vyberte jednu nebo více hodnot",
      wrongNumericFormat: "Špatný formát, tento číselný atribut by měl být zadán podle vzoru {{Muster}}.",
      wrongNumericFormatMessage: "Zadejte číslo v následujícím formátu",
      wrongIntervalFormat: "Zadejte hodnotu v uvedených mezích",
      deselectionNotPossible: "Přidejte jiný produkt před odstraněním tohoto",
      dropDownSelectMsg: "Proveďte výběr",
      noOptionSelectedMsg: "Není vybrána žádná možnost",
      notVisibleAttributeMsg: "Tento atribut není pro uživatele viditelný",
    },
    button: {
      previous: "Předchozí",
      next: "Další",
      back: "Zpět",
      more: "více",
      less: "méně",
      deselect: "Zrušte výběr",
      select: "Vyberte",
      add: "Přidat",
      remove: "Odebrat",
      exit: "Ukončit konfiguraci",
      exitMobile: "Exit",
      cancelConfiguration: "Zrušit konfiguraci",
      cancelConfigurationMobile: "Zrušit",
      filterOverview: "Filtr",
      filterOverviewWithCount: "Filtr ({{numAppliedFilters}})",
    },
    icon: {
      groupComplete: "Dokončit",
      groupIncomplete: "Neúplný",
      groupConflict: "Konflikt",
      subgroup: "má podskupiny",
    },
    priceSummary: {
      basePrice: "Základní cena",
      selectedOptions: "Vybrané možnosti",
      totalPrice: "Celkem",
      totalPricePerItem: "Celkem za položku",
    },
    addToCart: {
      button: "Přidat do košíku",
      buttonAfterAddToCart: "Pokračovat do košíku",
      buttonForQuote: "Pokračovat k nabídce",
      buttonUpdateCart: "Hotovo",
      buttonDisplayOnly: "Hotovo",
      buttonClose: "Zavřít",
      confirmation: "Konfigurace byla přidána do košíku",
      confirmationUpdate: "Košík byl doplněn o konfiguraci",
      confirmationQuoteUpdate: "Nabídka byla aktualizována konfigurací",
      selectVariant: "Vyberte prosím jednu z variant",
      addMatchingVariant: "Pro vaši konfiguraci bylo nalezeno více než jedna existující varianta. V takovém případě kontaktujte zákaznický servis a vyberte správnou variantu.",
      quantity: "Množství",
    },
    overviewForm: {
      noAttributeHeader: "Žádné výsledky",
      noAttributeText: "Odeberte filtr(y) pro zobrazení obsahu přehledu",
      itemPrice: "Cena položky",
    },
    overviewSidebar: {
      menu: "Nabídka",
      filter: "Filtr",
    },
    overviewFilter: {
      title: "Vybrat filtry",
      byOption: "Filtrovat podle možnosti",
      byGroup: "Filtrovat podle skupiny",
      byPrice: "Cenově relevantní možnosti",
      mySelections: "Moje výběry",
      removeAll: "Odstranit vše",
      removeAllFilters: "Odstranění všech filtrů",
      removeByPrice: "Odstranění cenově relevantního filtru",
      removeMySelections: "Odstranění filtru Moje výběry",
      removeByGroup: "Odstranění skupinového filtru pro skupinu {{group}}",
      noFiltersAvailable: "Nejsou k dispozici žádné filtry",
    },
    group: {
      general: "Obecné",
      conflictHeader: "Vyřešit konflikty",
      conflictGroup: "Konflikt pro {{attribute}}",
    },
    conflict: {
      suggestionTitle: "Návrh {{number}}",
      suggestionText: "Změnit hodnotu pro \\{{attribute }}\\",
      viewConflictDetails: "Zjištěn konflikt - Zobrazit podrobnosti",
      conflictDetected: "Zjištěn konflikt",
      viewConfigurationDetails: "Zobrazit v konfiguraci",
    },
    restartDialog: {
      title: "Nedokončená konfigurace",
      description: "Existuje nedokončená konfigurace z předchozí relace. Chcete pokračovat nebo spustit novou konfiguraci?",
      restartButton: "Spustit novou",
      resumeButton: "Obnovit",
    },
    httpHandlers: {
      makeToStockBaseProductIssue: "Tento produkt je základním produktem a nelze jej přidat do košíku; místo toho vyberte variantu produktu.",
    },
    a11y: {
      configureProduct: "Konfigurace produktu",
      showDetailsProduct: "Zobrazit podrobnosti o produktu",
      cartEntryBundleInfo: "Existuje položka",
      cartEntryBundleInfo_other: "Existují položky {{items}}",
      cartEntryBundleName: "Položka {{name }}",
      cartEntryBundleNameWithQuantity: "Položka {{name }} množství položky {{quantity}}",
      cartEntryBundleNameWithPrice: "Položka {{name }} cena položky {{price}}",
      cartEntryBundleNameWithPriceAndQuantity: "Položka {{name }} cena položky {{price}} množství položky {{quantity}}",
      cartEntryBundle: "Produkt má následující atributy a vybrané hodnoty",
      cartEntryInfoIntro: "Atribut {{attribute }} má vybranou hodnotu {{value}}",
      cartEntryInfo: "Název atributu",
      nameOfAttribute: "Hodnota atributu {{attribute }}",
      valueOfAttribute: "{{value}} pro atribut {{attribute}}",
      forAttribute: "Hodnota {{value}} atributu {{attribute}}",
      valueOfAttributeFull: "Hodnota {{value}} atributu {{attribute}}",
      valueOfAttributeFullWithPrice: "Hodnota {{value}} atributu {{attribute}}, Příplatek {{price}}",
      selectedValueOfAttributeFull: "Vybraná hodnota {{value}} atributu {{attribute}}",
      selectedValueOfAttributeFullWithPrice: "Vybraná hodnota {{value}} atributu {{attribute}}, Příplatek {{price}}",
      readOnlyValueOfAttributeFullWithPrice: "Pouze pro čtení hodnota {{value}} atributu {{attribute}}, Příplatek {{price}}",
      readOnlyValueOfAttributeFull: "Pouze pro čtení hodnota {{value}} atributu {{attribute}}",
      valueOfAttributeBlank: "Hodnota atributu {{attribute}} je prázdná.",
      value: "Hodnota {{value}}",
      attribute: "Atribut {{attribute}}",
      requiredAttribute: "Atribut {{param}} je povinný",
      listOfAttributesAndValues: "Seznam atributů a jejich hodnot",
      editAttributesAndValues: "Úprava hodnot atributů",
      group: "{{group}} skupina atributů",
      itemOfAttributeSelected: "Položka {{item}} atributu {{attribute}} je vybrána. {{itemIndex}} z {{itemCount}}",
      itemOfAttributeSelectedWithPrice: "Položka {{item}} atributu {{attribute}} je vybrána, Příplatek {{price}}. {{itemIndex}} of {{itemCount}}",
      itemOfAttributeSelectedPressToUnselect: "Položka {{item}} atributu {{attribute}} je vybrána. Stisknutím tlačítka výběr zrušíte. {{itemIndex}} of {{itemCount}}",
      itemOfAttributeSelectedPressToUnselectWithPrice: "Položka {{item}} atributu {{attribute}} je vybrána, Příplatek {{price}} Stisknutím tlačítka výběr zrušíte. {{itemIndex}} of {{itemCount}}",
      itemOfAttributeUnselected: "Položka {{item}} atributu {{attribute}} není vybrána. Stiskněte tlačítko pro výběr. {{itemIndex}} z {{itemCount}}",
      itemOfAttributeUnselectedWithPrice: "Položka {{item}} atributu {{attribute}} není vybrána, Příplatek {{price}}. Stiskněte tlačítko pro výběr. {{itemIndex}} of {{itemCount}}",
      selectNoItemOfAttribute: "Pro výběr žádné položky atributu {{attribute}} stiskněte tlačítko . {{itemIndex}} of {{itemCount}}",
      itemOfAttribute: "Položka atributu {{attribute}}",
      itemOfAttributeFull: "Položka {{item}} atributu {{attribute}}",
      itemOfAttributeFullWithPrice: "Položka {{item}} atributu {{attribute}}, Příplatek {{price}}",
      itemOfAttributeFullWithQuantity: "Položka {{item}} atributu {{attribute}}, Množství {{quantity}}",
      itemOfAttributeFullWithPriceAndQuantity: "Položka {{item}} atributu {{attribute}}, Množství {{quantity}}, Příplatek {{price}}",
      itemDescription: "Popis položky {{item}}",
      listbox: "Seznamové pole s hodnotami {{number}}.",
      valueSurcharge: "Hodnota příplatku",
      conflictDetected: "Zjištěn konflikt! Zkontrolujte konflikty v horní části seznamu skupin.",
      conflictsInConfiguration: "Ve vaší konfiguraci jsou konflikty. Počet konfliktů",
      listOfGroups: "Seznam skupin",
      inListOfGroups: "Nacházíte se v seznamu skupin",
      groupName: "Skupina {{group}}",
      groupBack: "Jste v podskupině. Výběrem se vrátíte zpět.",
      conflictBack: "Nacházíte se v řešiteli konfliktů. Výběrem se vrátíte zpět nebo vyberete další karty pro řešení konfliktů.",
      iconConflict: "Skupina má konflikty.",
      iconIncomplete: "Skupina má požadované atributy bez vybraných hodnot.",
      iconComplete: "Skupina je kompletní.",
      iconSubGroup: "Skupina má podskupinu.",
      next: "Přejděte na další skupinu.",
      previous: "Přejděte na předchozí skupinu.",
      showMoreProductInfo: "Zobrazit další informace o produktu {{product}} nebo pokračovat v konfiguraci.",
      showLessProductInfo: "Zobrazit méně informací o produktu {{product}} nebo pokračovat v konfiguraci.",
      productName: "Název produktu",
      productCode: "Kód produktu",
      productDescription: "Popis produktu",
      kbKeyName: "Název kb {{name}}",
      kbKeyLogsys: "Kb logický systém {{logsys}}",
      kbKeyVersion: "Kb verze {{version}}",
      kbKeyBuildNr: "Kb číslo sestavení {{number}}",
      configurationPage: "Nacházíte se na stránce konfigurace.",
      configurationPageLink: "Přejděte na stránku konfigurace.",
      overviewPage: "Nacházíte se na stránce s přehledem. Zkontrolujte atributy a jejich vybrané hodnoty pomocí režimu čtení obrazovky.",
      overviewPageLink: "Přejděte na stránku s přehledem konfigurace.",
      navigateToConflict: "Podle odkazu získáte další podrobnosti o konfliktu pro atribut {{attribute}}.",
      numericIntervalStandard: "Zadejte hodnotu mezi {{minValue}} a {{maxValue}}.",
      numericIntervalStandardOpen: "(Koncové body nejsou zahrnuty)",
      numericIntervalStandardLowerEndpointNotIncluded: "(Dolní koncový bod není zahrnut)",
      numericIntervalStandardUpperEndpointNotIncluded: "(Horní koncový bod není zahrnut)",
      numericInfiniteIntervalMinValueIncluded: "Zadejte hodnotu větší nebo rovnou {{value}}.",
      numericInfiniteIntervalMinValue: "Zadejte hodnotu větší než {{value}}",
      numericInfiniteIntervalMaxValueIncluded: "Zadejte hodnotu menší nebo rovnou {{value}}",
      numericInfiniteIntervalMaxValue: "Zadejte hodnotu menší než {{value}}",
      numericIntervalSingleValue: "Zadejte {{value}}",
      combinedIntervalsText: "{{combinedInterval}} nebo {{newInterval}}",
      additionalValue: "Můžete také zadat další hodnotu.",
      addToCartPrices: "Základní cena {{basePrice}}, Vybrané možnosti {{selectedOptions}}, Celková cena {{totalPrice}}",
      filterOverview: "Otevřít nabídku filtru přehledu konfigurace",
      filterOverviewWithCount: "Otevření nabídky filtrů přehledu konfigurace - {{numAppliedFilters}} aktivní filtry.",
      closeFilterMenu: "Zavřít nabídku filtrů přehledu konfigurace a použít vybrané filtry",
      filterOverviewByPrice: "Filtrování přehledu konfigurace podle cenově relevantních možností",
      filterOverviewByMySelections: "Filtrování přehledu konfigurace podle vašich voleb",
      filterOverviewByGroup: "Filtrovat přehled konfigurace podle skupiny {{groupName}}",
      closeConflictSolverModal: "Zavřít modální okno pro řešení konfliktů",
      closeRestartDialog: "Zavřete dialogové okno \\Nedokončená konfigurace\\ a přejděte zpět na stránku s podrobnostmi o produktu.",
      description: "Kliknutím zobrazíte popis této hodnoty",
    },
      variantCarousel: {
        title: "Předkonfigurované verze",
      }
}
}
