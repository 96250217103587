export const cartDetails = {
  id: 'ID',
  proceedToCheckout: 'Prosseguir para o checkout',
  createQuoteFromCart: 'Solicitar orçamento',
  cartName: 'Carrinho #{{code}}',
  minOrderNotReached: 'O valor mínimo de pedido é {{minOrderValue}}{{{currency}}}.',
  orderLocked: 'Atenção: Não é possível fazer pedidos no momento! Entre em contato com o serviço de atendimento ao cliente. Há problemas com essa conta de usuário.',
  requestedRetrievalDateEmpty: 'Você deve escolher uma data de entrega solicitada',
  requestedRetrievalDateAdded: 'Você adicionou com sucesso uma data de entrega solicitada',
  requestedRetrievalDateInvalid: 'A data de entrega solicitada é inválida',
  s4omScheduleLinesQuantity: 'Quantidade',
  s4omScheduleLinesEntryInfo: 'A data de envio/coleta confirmada de - {{quantity}} item(ns) - é {{date}}',
  cartSubjectToPriceChange: 'Atenção: Os preços dos dispositivos recém-configurados são preços de tabela. Você receberá seu preço específico de compra com a confirmação formal do pedido.',
};

export const cartItems = {
  id: 'Número do artigo',
  description: 'Descrição do item',
  item: 'Item',
  itemPrice: 'Preço do item',
  quantity: 'Quantidade',
  equipmentNumber: 'Número do equipamento:',
  quantityTitle: 'A quantidade representa o número total desse item em seu carrinho.',
  total: 'Total do carrinho',
  cartTotal: 'Total do carrinho ({{count}} item)',
  cartTotal_plural: 'Total do carrinho ({{count}} items)',
  actions: 'Ações',
  salesUnit: 'UOM',
  reference: 'Referência:',
  referencePlaceHolder: 'Referência',
  comment: 'Comentário:',
  commentPlaceHolder: 'Comentário',
  additionalContactEmailWarning: 'Digite um endereço de e-mail válido. (E-mails inválidos não serão salvos).',
  requestedDeliveryDate: 'Data de envio/coleta solicitada:',
  requestedDeliveryDateTitle: 'Data de envio/coleta solicitada',
  substitutionProductNotFound: 'Este produto foi substituído por outro que não encontra-se disponível no momento',
};

export const orderCost = {
  orderSummary: 'Resumo do pedido',
  subtotal: 'Subtotal:',
  shipping: 'Frete:',
  estimatedShipping: 'Estimativa de envio:',
  discount: 'Você economizou:',
  salesTax: 'Imposto sobre vendas:',
  grossTax: 'O total do pedido não inclui imposto de',
  grossIncludeTax: 'O total do pedido inclui imposto de',
  total: 'Total:',
  toBeDetermined: 'A ser determinado',
  requestedDeliveryDate: 'Data de envio/coleta solicitada*',
  taxStatement: '*Todos os preços mostrados são preços líquidos específicos do cliente, excluindo impostos. Você será informado sobre o preço líquido total final, incluindo impostos, por e-mail com a confirmação do pedido, pois os preços líquidos atualizados diariamente são recalculados no momento do pedido.',
  taxStatement2: 'Ao fazer o pedido de acessórios ou peças sobressalentes, poderá ser cobrada uma sobretaxa de quantidade mínima se o valor líquido, excluindo impostos, não atingir o valor mínimo do pedido.',
};

export const saveForLaterItems = {
  itemTotal: 'Salvo para mais tarde ({{count}} item)',
  itemTotal_plural: 'Salvo para mais tarde ({{count}} items)',
  cartTitle: 'Carrinho',
  saveForLater: 'Salvar para depois',
  moveToCart: 'Mover para o carrinho',
  stock: 'Em estoque',
  forceInStock: 'Em estoque',
};
  
  export const cart = {
    cartDetails,
    cartItems,
    orderCost,
    saveForLaterItems
  }