import { OrderEntriesSource } from "@spartacus/cart/base/root";
import { CartNameSource } from "@spartacus/cart/import-export/core";
import { RationalImportExportConfig } from "./rational-import-export-config";

export const rationalImportExportConfig: RationalImportExportConfig  = {
    cartImportExport: {
        file: {
            separator: ';',
        },
        import: {
            fileValidity: {
                maxSize: 1, //file size in
                maxEntries: {
                    [OrderEntriesSource.NEW_SAVED_CART]: 100,
                    [OrderEntriesSource.SAVED_CART]: 100,
                    [OrderEntriesSource.ACTIVE_CART]: 500,
                    [OrderEntriesSource.QUICK_ORDER]: 10,
                },
                allowedTypes: [
                    '.txt',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                    'text/csv',
                    '.csv',
                ],
            },
            cartNameGeneration: {
                source: CartNameSource.FILE_NAME,
            },
        },
        export: {
            additionalColumns: [
                {
                    name: {
                        key: 'name',
                    },
                    value: 'product.name',
                },
                {
                    name: {
                        key: 'price',
                    },
                    value: 'totalPrice.formattedValue',
                },
            ],
            messageEnabled: true,
            downloadDelay: 1000,
            maxEntries: 1000,
            fileOptions: {
                fileName: 'cart',
                extension: 'csv',
                type: 'text/csv',
            },
        },
    },
};