import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CheckoutPaymentTypeComponent } from '@spartacus/checkout/b2b/components';
import { CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { GlobalMessageService } from '@spartacus/core';
import { BehaviorSubject } from 'rxjs';
import { RationalAutoCompleteService } from '../../services/rational-auto-complete-service';


@Component({
  selector: 'cx-payment-type',
  templateUrl: './checkout-payment-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalCheckoutPaymentTypeComponent extends CheckoutPaymentTypeComponent {

  public paymentForm : UntypedFormGroup =  this.fb.group({
    poNumber: ['', Validators.required]
  });
  public filteredPONumbers: string[] = [];
  private key = 'purchaseOrderNumber';
  showSuggestions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private hideSuggestionsTimeout: any;

  constructor(
    protected _checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected _checkoutStepService: CheckoutStepService,
    protected _activatedRoute: ActivatedRoute,
    protected fb: UntypedFormBuilder,
    protected _globalMessageService: GlobalMessageService,
    private autoCompleteService: RationalAutoCompleteService
  ) {
      super(_checkoutPaymentTypeFacade, _checkoutStepService, _activatedRoute, _globalMessageService);
      this.cartPoNumber$.subscribe((poNumber) =>
        this.paymentForm.controls['poNumber'].setValue(poNumber)
      );
    this.autoCompleteService.loadUserEnteredValues(this.key);
  }


  onPoNumberInput(event: Event): void {
    const input = event.target as HTMLInputElement; // Cast event.target as HTMLInputElement
    const value = input.value;

    if (value.length > 1) {
      this.filteredPONumbers = this.autoCompleteService.getFilteredSuggestions(this.key, value);
      this.showSuggestions.next(true);
    } else {
      this.filteredPONumbers = [];
      this.showSuggestions.next(false);
    }

  }

  // Handle selection of a suggestion
  selectPONumber(suggestion: string): void {
    this.paymentForm.controls['poNumber'].setValue(suggestion);

  }

  // Hide suggestions when the input loses focus
  hideSuggestedPO(): void {
    clearTimeout(this.hideSuggestionsTimeout);
    this.hideSuggestionsTimeout = setTimeout(() => {
      this.filteredPONumbers = [];
      this.showSuggestions.next(false);
    }, 200);
  }

  showAllSuggestedPONumbers(): void {
    this.filteredPONumbers = this.autoCompleteService.getAllSuggestions(this.key); // Show all suggestions
    this.showSuggestions.next(true);
  }


  override next(): void {
    if (!this.typeSelected || this.paymentForm.get('poNumber')?.value == '') {
      return;
    }

    //save poNumber value in local storage to be used after that in auto complete
    this.autoCompleteService.addUserEnteredValue(this.key, this.paymentForm.get('poNumber')?.value);

    super.next();
  }
}
