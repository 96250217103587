import { NgModule } from '@angular/core';
import { I18nModule, provideConfig } from '@spartacus/core';

import { CommonModule } from '@angular/common';
import { IconModule, KeyboardFocusModule, SpinnerModule } from '@spartacus/storefront';
import { OrderItemShippingDialogComponent } from './order-item-shipping.component';
import { defaultOrderInformationLayoutConfig } from '../order-information-layout.config';
import { RationalOrderDocumentsListModule } from '../../order-documents-list/rational-order-document-list.module';
import { OrderTrackingDocumentsModule } from "../order-tracking-documents/order-tracking-documents.module";

@NgModule({
  declarations: [OrderItemShippingDialogComponent],
  exports: [OrderItemShippingDialogComponent],
  imports: [CommonModule, I18nModule, IconModule, KeyboardFocusModule, SpinnerModule, RationalOrderDocumentsListModule, OrderTrackingDocumentsModule],
  providers: [
    provideConfig(defaultOrderInformationLayoutConfig),
  ],
})
export class OrderItemShippingModule { }
