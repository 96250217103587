import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isCartError } from '@spartacus/cart/base/core';
import {
    ErrorModel,
    GlobalMessageType,
    HttpErrorHandler,
    HttpResponseStatus,
    Priority,
} from '@spartacus/core';

@Injectable({
    providedIn: 'root',
})
export class BadContextSwitcherRequestHandler extends HttpErrorHandler {
    override responseStatus = HttpResponseStatus.INTERNAL_SERVER_ERROR;

    getPriority(): Priority {
        return Priority.NORMAL;
    }

    override hasMatch(errorResponse: HttpErrorResponse): boolean {
        return (
            super.hasMatch(errorResponse) &&
            this.getErrors(errorResponse).some(this.isContextSwitcherError)
        );
    }

    handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
        this.handleContextSwitcherError(request, response);
    }

    protected handleContextSwitcherError(_request: HttpRequest<any>, response: HttpErrorResponse): void {
        this.getErrors(response).forEach((error) => {
            this.globalMessageService.add(
                error.message ? error.message : { key: 'httpHandlers.internalServerError' },
                GlobalMessageType.MSG_TYPE_ERROR
            );
        });
    }


    protected getErrors(response: HttpErrorResponse): ErrorModel[] {
        return (response.error?.errors || []).filter(
            (error: any) => error.type !== 'JaloObjectNoLongerValidError'
        );
    }

    isContextSwitcherError(error: ErrorModel): boolean {
        return (
            error.type === 'ContextSwitcherSelectionError' ||
            error.type === 'ContextSwitcherPopulationError'
        );
    }
}