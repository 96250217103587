import { Component, OnInit } from '@angular/core';
import {
  CmsProductReferencesComponent,
  LanguageService,
  Product,
} from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Relation, RelationList } from '../model/product-relations.model';
import { RationalProductRelationsService } from '../rational-product-relations.service';
import { RationalCurrentProductService } from '../../rational-current-product.service';

@Component({
  selector: 'app-rational-product-reference',
  templateUrl: './rational-product-relations.component.html',
  styleUrls: ['./rational-product-relations.component.scss'],
})
export class RationalProductRelationsComponent implements OnInit {
  relationsProductsMap: Map<String, Observable<Product>[]> = new Map<
    String,
    Observable<Product>[]
  >();
  relationList$: Observable<RelationList> | undefined;

  productCode: string = '';
  isRelationsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    protected cmsComponentData: CmsComponentData<CmsProductReferencesComponent>,
    protected currentProductService: RationalCurrentProductService,
    protected productReferenceService: RationalProductRelationsService,
    protected languageService: LanguageService
  ) {
    this.languageService.getActive().subscribe(() => {
      this.relationList$ = this.productReferenceService.getRelationList(
        this.productCode
      );
      this.fillRelationsProductsMap();
    });
  }

  ngOnInit(): void {
    this.getCode().subscribe((productCode) => {
      this.productCode = productCode;
      this.relationList$ =
        this.productReferenceService.getRelationList(productCode);
      this.fillRelationsProductsMap();
    });
  }

  protected getCode(): Observable<string> {
    return this.currentProductService.getCurrentProductCode();
  }

  private fillRelationsProductsMap(): void {
    this.relationList$?.subscribe((relationList) => {
      if (relationList && relationList.references) {
        relationList.references.forEach((relation) => {
          this.relationsProductsMap.set(
            relation.referenceType ?? '',
            this.constructProductsListForRelation(relation)
          );
        });
      }

      this.isRelationsLoaded.next(this.relationsProductsMap.size > 0);
    });
  }

  private constructProductsListForRelation(
    relation: Relation
  ): Observable<Product>[] {
    const obsProducts: Observable<Product>[] = [];
    relation.target?.forEach((product) => {
      obsProducts.push(of(product));
    });
    return obsProducts;
  }

  getProductsForRelation(relation: Relation): Observable<Product>[] {
    return this.relationsProductsMap.get(relation.referenceType ?? '') ?? [];
  }
}
