export const checkoutAddress = {
    shippingAddress: 'Gönderi Adresi',
    selectYourShippingAddress: 'Gönderi Adresinizi Seçiniz:',
    selectYourDeliveryAddress: 'Teslimat Adresinizi Seçin:',
    defaultShippingAddress: 'Varsayılan Gönderi Adresi',
    addNewAddress: 'Yeni Adres Ekle',
    shipToThisAddress: 'Bu adrese gönderin',
    addressSearch: 'Adres arama',
    addressSearchMinLength: 'Arama metni en az {{length}} karakter olmalıdır',
    submitAddressSearch: 'Girin',
    errorSearchingSalesforce: 'Şu anda arama tamamlanamıyor, lütfen daha sonra tekrar deneyin',
    deliveryAddressSelected: 'Teslimat adresi seçildi',
  };
  
  export const checkoutOrderConfirmation = {
    confirmationOfOrder: 'Sipariş Onayı:',
    confirmationOfQuote: 'Teklif Alındı Onayı:',
    thankYou: 'Siparişiniz için teşekkür ederiz!',
    thankYouQuote: 'Teklif talebiniz için teşekkür ederiz!',
    invoiceHasBeenSentByEmailLine1: 'Bu siparişin sipariş geçmişinizde görünmesinin bir iş günü sürebileceğini lütfen unutmayın. Bir kez orada, RATIONAL tarafından onaylanan sevkiyat/teslim alma tarihi ile birlikte resmi sipariş onayını içerecektir ve geçerli vergiler de dahil olmak üzere tüm fiyatlandırma bilgileri.',
    invoiceHasBeenSentByEmailLine2: 'Bu arada bu siparişle ilgili herhangi bir sorunuz varsa, lütfen RATIONAL müşteri hizmetlerinizle iletişime geçin ve yukarıda gösterilen sipariş kimliğini referans gösterin.',
    quoteHasBeenSentByEmailLine1: 'Fiyat teklifi taleplerinizin web mağazasındaki sipariş geçmişinize aktarılmayacağını lütfen unutmayın.',
    quoteHasBeenSentByEmailLine2: 'Bu fiyat teklifi talebiyle ilgili herhangi bir sorunuz varsa, lütfen RATIONAL müşteri hizmetlerinizle iletişime geçin ve yukarıda gösterilen web mağazası teklif numarasını veya Posta numaranızı referans gösterin.',
    orderItems: 'Sipariş Kalemleri',
    orderPlacedSuccessfully: 'Sipariş başarıyla verildi',
    createAccount: 'Hesap mı oluşturdunuz?',
    createAccountForNext: 'Bir sonraki ziyaretinizde daha hızlı ödeme yapmak için <{{email}}> için bir hesap oluşturun.',
  };
  
  export const checkoutReview = {
    review: 'Sipariş Özeti',
    orderItems: 'Sipariş Kalemleri',
    autoReplenishOrder: 'Otomatik Yenileme Siparişi',
    confirmThatRead: 'Bu belgeyi okuduğumu ve kabul ettiğimi onaylıyorum.',
    placeOrder: 'Sipariş Verin',
    placeQuoteOrder: 'Teklif İste',
    scheduleReplenishmentOrder: 'İkmal Emri Planlayın',
    orderType_PLACE_ORDER: 'Yok (sadece tek seferlik sipariş)',
    orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Sipariş Yenileme',
    termsAndConditions: 'Şartlar ve Koşullar',
    editShippingAddress: 'Gönderi adresini düzenle',
    poNumber: 'Satınalma Sipariş Numarası',
    every: 'Her',
    startOn: 'Başla',
    dayOfMonth: 'O gün',
    repeatOnDays: 'Sonraki günlerde tekrarlayın',
    recurrencePeriodType_DAILY: 'Gün(ler)',
    recurrencePeriodType_WEEKLY: 'Hafta(lar)',
    recurrencePeriodType_MONTHLY: 'Ay',
  };
  
  export const checkoutShipping = {
    shippingMethod: 'Nakliye Yöntemi',
    standardDelivery: 'Standart Teslimat',
    premiumDelivery: 'Premium Teslimat',
  };
  
  export const checkoutPO = {
    noPoNumber: 'Hiçbiri',
    poNumber: 'Satınalma Sipariş Numaranız',
    costCenter: 'Maliyet Merkezi',
    placeholder: 'Kendi posta numaranızı girin...',
    availableLabel: 'Kullanabileceğiniz nakliye adresleri maliyet merkezinin birimine bağlıdır',
  };
  
  export const checkoutProgress = {
    methodOfPayment: 'Ödeme Yöntemi',
    deliveryAddress: 'Gönderi Adresi',
    deliveryMode: 'Teslimat Modu',
    paymentDetails: 'Ödeme Detayları',
    reviewOrder: 'Sipariş Özeti',
  };
  
  export const checkoutB2B = {
    checkoutB2B: {
    poNumber: 'Satınalma Sipariş Numaranız',
    placeholder: 'Kendi P.O. numaranızı girin...',
    methodOfPayment: {
      paymentType: 'Ödeme Tipi',
    },
    progress: {
      methodOfPayment: 'Ödeme Yöntemi',
    },
    review: {
      poNumber: 'Satınalma Sipariş Numaranız',
    },
  }
  };
  
  export const checkoutTermsAndConditions = {
    checkoutTermsAndConditions: {
      incoterms: 'Incoterms',
      paymentConditions: 'Ödeme Koşulları',
    }
  };
  
  export const checkoutMode = {
    deliveryMethod: 'Teslimat Yöntemi',
    deliveryEntries: 'Gönderilecek Ürünler',
    specialShippingInstructions: 'Özel Nakliye Talimatları',
    specialShippingInstructionsPlaceholder: 'Özel Nakliye Talimatları',
  };
  
  export const checkout = {
    checkoutAddress,
    checkoutOrderConfirmation,
    checkoutReview,
    checkoutShipping,
    checkoutPO,
    checkoutProgress,
    checkout: {
      backToCart: 'Sepete geri dön',
      invalid: {
        accountType: 'Hesabınız buradan ödeme yapmanıza izin vermiyor.'
      }
    },
    checkoutMode
  };
  