import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalMediaComponent } from './rational-media.component';
import { RationalImagePipe } from './rational-image.pipe';



@NgModule({
  declarations: [RationalMediaComponent, RationalImagePipe],
  exports: [RationalMediaComponent, RationalImagePipe ],
  imports: [
    CommonModule,
  ],
})
export class RationalMediaModule { }
