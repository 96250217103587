import { Component, ViewEncapsulation } from '@angular/core';
import { ExportOrderEntriesComponent } from '@spartacus/cart/import-export/components';

@Component({
  selector: 'rational-saved-cart-details-export-entries',
  templateUrl: './rational-saved-cart-details-export-entries.component.html',
  styleUrl: './rational-saved-cart-details-export-entries.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RationalSavedCartDetailsExportEntriesComponent extends ExportOrderEntriesComponent{

}
