export const quickOrderCartForm = {
    entriesWasAdded: "({{ quantity }}) {{ product }} byl přidán do košíku",
    entryWasAdded: "{{ product }} byl přidán do košíku",
    noResults: "Nepodařilo se nám najít žádné produkty",
    stockLevelReached: "Bylo dosaženo maximální úrovně zásob",
    title: "Rychlé přidání do košíku",
    productCodePlaceholder: "Zadejte číslo zboží",
    entryProductCode: "Zadejte číslo článku pro rychlou objednávku",
    addToCart: "Rychlé přidání do košíku",
    product: "Produkt",
    products: "Produkty",
    productCodeLabel: "ID produktu",
    quantityLabel: "Množství",
    placeholder: "Zadejte SKU produktu",
    listLimitReached: "Limit produktu byl dosažen",
    id: "Číslo výrobku {{ id }}"
};

export const quickOrderList = {
    addToCart: "Přidat do košíku",
    emptyList: "Prázdný seznam",
    header: "Přidat produkty/Skus",
    subHeader: "Najednou můžete přidat až {{ limit }} platných SKU.",
    errorProceedingToCart: "Chyba při přechodu do košíku.",
    warningProceedingToCart: "Varování při přechodu do Košíku.",
    successfullyAddedToCart: "Úspěšně přidáno do Košíku.",
    errors: {
        productIsOutOfStock: "{{name }} (#{{code}}) není skladem."
    },
    warnings: {
        productWasReduced: "Množství pro {{name }} (#{{code }}) bylo sníženo na {{quantityAdded}}."
    },
    successes: {
        productAddedToCart: "{{name }} (#{{code }}) bylo přidáno do košíku."
    }
};

export const quickOrderTable = {
    product: "Produkt",
    actions: "Akce",
    id: "Číslo článku",
    price: "Cena",
    quantity: "MNOŽSTVÍ",
    itemPrice: "Cena položky",
    qty: "Množství"
};

export const quickOrder = {
    quickOrderCartForm,
    quickOrderList,
    quickOrderTable
};
