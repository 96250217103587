import { NgModule } from '@angular/core';
import { I18nModule, provideConfig } from '@spartacus/core';

import { CommonModule } from '@angular/common';
import { IconModule, KeyboardFocusModule, SpinnerModule } from '@spartacus/storefront';
import { defaultOrderInformationLayoutConfig } from '../order-information-layout.config';
import { RationalOrderDocumentsListModule } from '../../order-documents-list/rational-order-document-list.module';
import { OrderTrackingDocumentsDialogComponent } from './order-tracking-documents.component';

@NgModule({
  declarations: [OrderTrackingDocumentsDialogComponent],
  exports: [OrderTrackingDocumentsDialogComponent],
  imports: [CommonModule, I18nModule, IconModule, KeyboardFocusModule, SpinnerModule, RationalOrderDocumentsListModule],
  providers: [
    provideConfig(defaultOrderInformationLayoutConfig),
  ],
})
export class OrderTrackingDocumentsModule { }
