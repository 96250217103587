import { Product } from '@spartacus/core';

declare module '@spartacus/core' {
  interface Product {
    salesUnit: string;
    returnable: boolean;
    substitutionProductCode: string;
    substitutionProductExists: boolean;
    isBaseProduct: boolean;
  }
}
