export const orderDetails = {
    statusDisplay_open: '已创建订单',
    statusDisplay_processing: '已创建订单',
    statusDisplay_completed: '订单已送达',
    purchaseOrderNumber: '您的采购单号',
    orderNumber: '网店订单号',
    methodOfPayment: '付款方式',
    shippingMethod: '发货方式',
    placedOn: '订购日期',
    moreInformation: '更多信息',
    orderDocuments: '订单文件',
    billing: '账单',
    invoiceNumber: '发票号码',
    invoiceDate: '日期',
    netAmount: '净额',
    noBillingInvoices: '暂无账单信息。',
    noOrderDocuments: '暂无订单文件。',
    noInvoiceDocuments: '尚无账单文件。',
    noShipmentDocuments: '尚无发货文件。',
    shipping: {
      shipping: '发货',
      shippingId: '发货编号',
      deliveryDate: '发货/取货日期',
      quantity: '数量',
      tracking: '跟踪',
      noShippingInfo: '暂无发货信息。',
      noTrackingInfo: '暂无跟踪信息。'
    },
    orderId: "订单号",
  replenishmentId: "补货编号",
  purchaseOrderId: "采购单号",
  emptyPurchaseOrderId: "无",
  none: "无 {{value}}",
  placed: "订购",
  placedBy: "订购者",
  unit: "单位",
  costCenter: "成本中心",
  costCenterAndUnit: '成本中心/单位',
  costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
  payByAccount: "通过账户支付",
  paidByCreditCard: "(用信用卡支付）",
  status: "状态",
  active: "已激活",
  shippedOn: "已发货日期",
  startOn: "开始日期",
  nextOrderDate: "下次订购日期",
  frequency: "频率",
  cancelled: "取消",
  deliveryStatus_IN_TRANSIT: "转运中",
  deliveryStatus_READY_FOR_PICKUP: "准备取货",
  deliveryStatus_READY_FOR_SHIPPING: "准备发货",
  deliveryStatus_WAITING: "等待",
  deliveryStatus_DELIVERING: "发货",
  deliveryStatus_PICKPACK: "准备装运",
  deliveryStatus_PICKUP_COMPLETE: "取货完成",
  deliveryStatus_DELIVERY_COMPLETED: "交货完成",
  deliveryStatus_PAYMENT_NOT_CAPTURED: "付款问题",
  deliveryStatus_READY: "处理中",
  deliveryStatus_DELIVERY_REJECTED: "拒绝发货",
  deliveryStatus_SHIPPED: "已发货",
  deliveryStatus_TAX_NOT_COMMITTED: "税务问题",
  deliveryStatus_CANCELLED: "取消",
  statusDisplay_cancelled: "取消",
  statusDisplay_cancelling: "取消待定",
  statusDisplay_confirmed: "订单已确认",
  statusDisplay_created: "创建订单",
  statusDisplay_error: "待处理",
  statusDisplay_Error: "待处理",
  statusDisplay_pending: {
      approval: "待审批",
      merchant: {
          Approval: "待商户批准"
      }
  },
  statusDisplay_approved: "已批准",
  statusDisplay_rejected: "拒绝",
  statusDisplay_merchant: {
      Approved: "商家已批准",
      Rejected: "商家拒绝",
  },
  statusDisplay_assigned: {
      admin: "分配给管理员"
  },
  consignmentTracking: {
    action: "跟踪包裹",
    dialog: {
      header: "跟踪信息",
      shipped: "已发货",
      estimate: "预计送达",
      carrier: "送货服务",
      trackingId: "跟踪号码",
      noTracking: "包裹尚未从仓库发出。跟踪信息将在包裹寄出后提供。",
      loadingHeader: "货物跟踪"
    }
  },
  cancellationAndReturn: {
    returnAction: "申请退货",
    cancelAction: "取消项目",
    item: "项目",
    itemPrice: "项目价格",
    quantity: "最大数量",
    returnQty: "退货数量",
    cancelQty: "取消数量",
    setAll: "将所有数量设为最大值",
    totalPrice: "总计",
    submit: "提交退货申请",
    returnSuccess: "您的退货申请（{{rma}}）已提交。",
    cancelSuccess: "您的取消订单申请已提交（原始订单 {{orderCode}} 将被更新）"
  },
  cancelReplenishment: {
    title: "取消补货",
    description: "取消今后的补货订单？",
    accept: "是",
    reject: "否",
    cancelSuccess: "补货订单 #{{replenishmentOrderCode}} 已成功取消。"
  }
  };
  
  export const orderHistory = {
    orderHistory: '订单历史',
    orderId: '订单号',
    date: '日期',
    status: '状态',
    PONumber: '采购单号',
    total: '总计',
    noOrders: '我们还没有此账户的订单数据。',
    startShopping: '开始购物',
    sortBy: '排序方式',
    sortOrders: '订单排序',
    purchaseOrderNumber: '采购单号',
    notFound: '未找到订单',
    actions: '行动',
    filters: {
      orderNumber: '订单号',
      orderNumberPlaceholder: '输入订单号',
      PONumber: '自己的订单号',
      PONumberPlaceholder: '输入您自己的订单号',
      dateRange: '时间段',
      dateRanges: {
        LAST_30_DAYS: '最近 30 天',
        LAST_3_MONTHS: '最近 3 个月',
        LAST_6_MONTHS:'最近 6 个月',
        CURRENT_YEAR:'当年',
        LAST_YEAR: '去年',
        LAST_2_YEARS: '前一年'
      },
      applyFilters: '应用筛选器',
      clearFilters: '重置筛选器',
    },
    jumpToPageLabel: '跳转到',
    jumpToPageInput: '页码'
  };
  
  export const AccountOrderHistoryTabContainer = {
    tabs: {
      AccountOrderHistoryComponent: '所有订单 ({{param}})'
    }
  };
  
  export const order = {
    orderDetails,
    orderHistory,
    AccountOrderHistoryTabContainer
  };
  