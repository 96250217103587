import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges, ViewEncapsulation,
} from '@angular/core';
import {
  PageLayoutService,
  ProductListComponent,
  ProductListComponentService,
  ViewConfig
} from "@spartacus/storefront";
import {GlobalMessageService} from "@spartacus/core";

@Component({
  selector: 'rational-product-list',
  styleUrls: ['./rational-product-list.component.scss'],
  templateUrl: './rational-product-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RationalProductListComponent extends ProductListComponent {

  constructor(
    private customPageLayoutService: PageLayoutService,
    private customProductListComponentService: ProductListComponentService,
    private customGlobalMessageService: GlobalMessageService,
    public customScrollConfig: ViewConfig
  ) {
    super(customPageLayoutService, customProductListComponentService, customGlobalMessageService, customScrollConfig)
  }
}
