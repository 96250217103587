import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { OrderEntry } from '@spartacus/cart/base/root';
import { GlobalMessageType, RoutingService } from '@spartacus/core';
import { OrderAmendService } from '@spartacus/order/components';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'cx-return-order',
  templateUrl: './return-order.component.html',
  styleUrls: ['./return-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalReturnOrderComponent {
  orderCode: string = '';

  maximumAttachmentsCount = 5;
  maximumAttachmentsSizeInMB = 15;
  attachmentsAcceptedTypes = ['image/*', 'application/pdf'];
  attachmentsWarnings: Map<ATTACHMENTS_WARNING, number> = new Map<ATTACHMENTS_WARNING, number>();

  globalMessageType = GlobalMessageType;
  actualForm: UntypedFormGroup = new UntypedFormGroup({});

  form$: Observable<UntypedFormGroup> = this.orderAmendService
    .getForm()
    .pipe(
      tap((form) => {
        this.actualForm = form;
        this.orderCode = form.value.orderCode
      }));

  entries$: Observable<OrderEntry[]> = this.orderAmendService.getEntries();

  constructor(protected orderAmendService: OrderAmendService, protected routing: RoutingService) { }


  onFileSelect(event: any) {
    this.attachmentsWarnings.clear;
    if (event.target.files.length > 0) {
      const newFiles = event.target.files;
      let attachments = [];

      for (let i = 0, len = newFiles.length; i < len; i++) {

        if (attachments.length == this.maximumAttachmentsCount) {
          this.attachmentsWarnings.set(ATTACHMENTS_WARNING.ATTACHMENTS_COUNT_EXCEEDED, this.maximumAttachmentsCount);
          break;
        }

        const file = newFiles[i];
        const fileSize: number = file.size / 1024 / 1024;
        if (fileSize <= this.maximumAttachmentsSizeInMB) {
          attachments.push(file);
        } else {
          this.attachmentsWarnings.set(ATTACHMENTS_WARNING.ATTACHMENT_SIZE_EXCEEDED, this.maximumAttachmentsSizeInMB);
        }
      }

      const attachementsControl = this.actualForm?.get('attachments');
      attachementsControl?.setValue(attachments);
    }
  }


  submit(form: UntypedFormGroup) {
    if (form.valid) {
      this.orderAmendService.save();
    } else {
      form.markAllAsTouched();
    }
  }


  back() {
    this.routing.go({
      cxRoute: 'orderDetails',
      params: { code: this.orderCode },
    });
  }
}

export enum ATTACHMENTS_WARNING {
  ATTACHMENTS_COUNT_EXCEEDED = "attachmentCountExceeded",
  ATTACHMENT_SIZE_EXCEEDED = "attachmentSizeExceeded"
}
