import { NgModule } from "@angular/core";
import { RationalOrderDocumentComponent } from "./rational-order-document.component";
import { CommonModule } from "@angular/common";
import { I18nModule, provideConfig } from "@spartacus/core";
import { IconModule, KeyboardFocusModule, SpinnerModule } from "@spartacus/storefront";
import { orderDocumentsLayoutConfig } from "./rational-order-document-layout.config";
import { RationalOrderDocumentsListModule } from "../order-documents-list/rational-order-document-list.module";


@NgModule({
  declarations: [
    RationalOrderDocumentComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    SpinnerModule,
    KeyboardFocusModule,
    RationalOrderDocumentsListModule
   ],
  exports: [
    RationalOrderDocumentComponent
  ],
  providers: [
    provideConfig(orderDocumentsLayoutConfig),
  ],
})
export class RationalOrderDocumentModule {}
