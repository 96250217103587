export const orderDetails = {
  statusDisplay_open: 'noch nicht bearbeitet',
  statusDisplay_processing: 'Teilweise bearbeitet',
  statusDisplay_completed: 'Vollständig bearbeitet',
  purchaseOrderNumber: 'Eigene Bestellnummer',
  orderNumber: 'RATIONAL Bestellnummer',
  methodOfPayment: 'Zahlungsmethode',
  shippingMethod: 'Versandmethode',
  placedOn: 'Bestellt am',
  moreInformation: 'Mehr Informationen',
  orderDocuments: 'Bestelldokumente',
  billing: 'Rechnung',
  invoiceNumber: 'Rechnungsnummer',
  invoiceDate: 'Datum',
  netAmount: 'Nettopreis',
  noBillingInvoices: 'Noch keine Rechnungsinformationen verfügbar.',
  noOrderDocuments: 'Noch keine Auftragsdokumente verfügbar.',
  noInvoiceDocuments:'Noch keine Rechnungsdokumente verfügbar.',
  noShipmentDocuments: 'Noch keine Lieferdokumente verfügbar.',
  shipping: {
    shipping: 'Lieferung',
    shippingId: 'Lieferung Id',
    deliveryDate: 'Versand-/Abholdatum',
    quantity: 'Menge',
    tracking: 'Sendungsverfolgung',
    noShippingInfo: 'Noch keine Lieferinformationen verfügbar.',
    noTrackingInfo: 'Noch keine Sendungsverfolgung verfügbar.'
  },
};

export const orderHistory = {
  orderHistory: 'Bestellhistorie',
  orderId: 'Bestellnummer',
  date: 'Datum',
  status: 'Status',
  PONumber: 'Eigene Bestellnummer',
  total: 'Gesamt',
  noOrders: 'Wir haben keine Auftragsdaten für dieses Konto.',
  startShopping: 'Einkaufen starten',
  sortBy: 'Sortieren nach',
  sortOrders: 'Aufträge sortieren',
  purchaseOrderNumber: 'Eigene Bestellnummer',
  notFound: 'Keine Aufträge gefunden',
  actions: 'Aktionen',
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'ALLE BESTELLUNGEN ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
