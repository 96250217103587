export const organization = {
  organization: {
    enabled: 'Aktiv',
    disabled: 'Deaktiviert',
    enable: 'Aktivieren',
    disable: 'Deaktivieren',
    name: 'Name',
    code: 'Code',
    done: 'erledigt',
    cancel: 'Abbrechen',
    add: 'Hinzufügen',
    create: 'Erstellen {{name}}',
    edit: 'Bearbeiten',
    save: 'Speichern {{name}}',
    delete: 'Löschen',
    assign: 'Verwalten',
    active: 'Aktiv',
    status: 'Status',
    details: 'Details',
    messages: {
      emptyList: 'Die Liste ist leer'
    },
    userRoles: {
      b2bcustomergroup: 'Kunde',
      b2bapprovergroup: 'Genehmiger',
      b2bmanagergroup: 'Manager',
      b2badmingroup: 'Admin'
    },
    breadcrumb: 'Organisation',
    notification: {
      noSufficientPermissions: 'Keine ausreichenden Berechtigungen für den Zugriff auf diese Seite',
      notExist: 'Dieser Artikel existiert nicht',
      disabled: 'Sie können keinen deaktivierten Artikel bearbeiten.'
    },
    confirmation: {
      cancel: 'ABBRECHEN',
      confirm: 'BESTÄTIGEN',
      disable: 'DEAKTIVEREN',
      delete: 'LÖSCHEN'
    },
    httpHandlers: {
      conflict: {
        budget: 'Das Budget {{ code }} existiert bereits.',
        costCenter: 'Die Kostenstelle {{ code }} existiert bereits.',
        unit: 'Die Organisations {{ code }} existiert bereits.',
        user: 'Der User mit der E-Mail {{ code }} existiert bereits',
        userGroup: 'Die benutzergruppe {{ code }} existiert bereits',
        permission: 'Die Genehmigungserlaubnis {{ code }} exstiert bereits.',
        unknown: 'Server Validierungsfehler'
      }
    }
  }
}
