export const productDetails = {
  id: 'Número de artículo',
  quantity: 'Cantidad',
  productDetails: 'Detalles del producto',
  specification: 'Características técnicas',
  reviews: 'Reseñas',
  shipping: 'Envío',
  share: 'Compartir',
  showReviews: 'Mostrar opiniones',
  noReviews: '',
  productPrice: 'Precio del producto',
  priceLoading:'Se calcula el precio del producto',
  productRelations :{
    productRelationsHeader: 'Accesorios compatibles',
  }
}

export const productList = {
  filterBy: {
    label: 'Filtrar por',
    action: 'Filtrar por',
  },
  appliedFilter: 'Filtro aplicado:',
  showLess: 'Mostrar menos...',
  showMore: 'Mostrar más...',
  sortBy: 'Ordenar por',
  backToTopBtn: 'VOLVER ARRIBA',
  showMoreBtn: 'VER MÁS',
  productSearchPagination: 'Paginación de la búsqueda de productos',
}

export const productFacetNavigation = {
  filterBy: {
    label: 'Filtrar por',
    action: 'Filtrar por',
  },
  appliedFilter: 'Filtro aplicado:',
  showLess: 'Mostrar menos...',
  showMore: 'Mostrar más...',
  sortBy: 'Ordenar por',
}

export const productSummary = {
  id: 'Número de artículo',
  showReviews: 'Mostrar opiniones',
  share: 'Compartir',
  newItemPrice: 'Nuevo precio del artículo',
  discount: 'Los precios mostrados incluyen su descuento individual en la cantidad de {{discount}}.',
  productSubstitutionAndProductExists: 'El producto {{product1}} se sustituye por el producto {{product2}},por lo que se le redirige al producto {{product2}}',
  productSubstitutionAndProductDoesntExist: 'El producto {{product1}} se sustituye por el producto {{product2}},pero {{product2}} no está disponible en este momento',
}

export const productReview = {
  overallRating: 'Valoración general',
  reviewTitle: 'Título de la reseña',
  writeYourComments: 'Escriba sus comentarios',
  rating: 'Valoración',
  reviewerName: 'Nombre del revisor (opcional)',
  writeReview: 'Escribe una opinión',
  more: 'Mostrar más opiniones',
  less: 'Mostrar menos opiniones',
  thankYouForReview: 'Gracias por su opinión. Tenga en cuenta que las opiniones pueden requerir revisión antes de aparecer aquí.',
}

export const addToCart = {
  itemsAddedToYourCart: 'Artículo(s) añadido(s) a su cesta',
  itemsIncrementedInYourCart: 'Este artículo ya estaba en su cesta. La cantidad fue actualizada.',
  items: 'artículos',
  updatingCart: 'Actualizando carrito...',
  addToCart: 'Añadir al carrito',
  viewCart: 'Ver Cesta',
  proceedToCheckout: 'Proceder a la compra',
  quantity: 'Cantidad',
  outOfStock: 'Agotado',
  inStock: 'En stock',
  selectStyleAndSize: 'Seleccione el estilo y la talla para comprobar el stock',
  removeFromCart: 'Quitar producto de la cesta',
  closeModal: 'Cerrar Modal',
}

export const TabPanelContainer = {
  tabs: {
    ProductDetailsTabComponent: 'Detalles del producto',
    ProductSpecsTabComponent: 'Características técnicas',
    ProductReviewsTabComponent: 'Reseñas',
    deliveryTab: 'Envío',
    ProductDownloadsTabComponent: 'Descargas',
  },
}

export const addToWishList = {
  add: 'Añadir a la lista de deseos',
  remove: 'Eliminar de la lista de deseos',
  anonymous: 'Iniciar sesión para añadir a la lista de deseos',
}

export const itemCounter = {
  removeOne: 'Eliminar uno',
  addOneMore: 'Añadir uno más',
  quantity: 'Cantidad',
}

export const productView = {
  gridView: 'Seleccione para cambiar a la vista de cuadrícula',
  listView: 'Seleccione para cambiar a vista de lista',
}

export const standardDelivery = {
  standardDelivery: {
    standardDeliveryRange: 'Listo para enviar con {{from}} a {{to}} días',
  },
}

export const downloads = {
  downloads: {
    noDownloads: 'No hay descargas para este producto',
    documentTitle: 'Título del documento',
    originalFileName: 'Nombre del archivo',
    languages: 'Idiomas',
    readableTypeMime: 'Tipo Mime',
    fileSize: 'Tamaño del archivo',
    download: 'Descargar'
  },
}

export const productReferences = {
  productReferences: {
    installationvariants: 'Variantes de configuración',
    feedingsystems: 'Sistemas de carga',
    groomingaccessoires: 'Productos de cuidado',
    cookaccessoire: 'Accesorios de cocina',
    cookaccessoirespecial: 'Accesorios especiales',
    extractorhood: 'Campanas',
    deviceinstallation: 'Instalación',
  }
}

export const product = {
  productDetails,
  productList,
  productFacetNavigation,
  productSummary,
  productReview,
  addToCart,
  TabPanelContainer,
  addToWishList,
  itemCounter,
  productView,
}
