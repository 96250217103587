export const orderDetails = {
  orderId: 'Zamówienie #',
  orderNumber: 'Numer zamówienia w sklepie internetowym',
  replenishmentId: 'Uzupełnienie #',
  purchaseOrderId: 'Zamówienie zakupu #',
  purchaseOrderNumber: 'Numer zamówienia zakupu',
  emptyPurchaseOrderId: 'Brak',
  none: 'Brak {{value}}',
  placed: 'Umieszczone',
  placedBy: 'Złożone przez',
  unit: 'Jednostka',
  costCenter: 'Centrum kosztów',
  costCenterAndUnit: 'Centrum kosztów / jednostka',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  methodOfPayment: 'Metoda płatności',
  payByAccount: 'Zapłać przez konto',
  paidByCreditCard: '(płatność kartą kredytową)',
  status: 'Status',
  active: 'Aktywny',
  shippedOn: 'Wysłano w dniu',
  shippingMethod: 'Metoda wysyłki',
  placedOn: 'Umieszczono na',
  startOn: 'Data rozpoczęcia',
  nextOrderDate: 'Data następnego zamówienia',
  frequency: 'Częstotliwość',
  cancelled: ' Anulowane',
  deliveryStatus_IN_TRANSIT: 'W tranzycie',
  deliveryStatus_READY_FOR_PICKUP: 'Gotowe do odbioru',
  deliveryStatus_READY_FOR_SHIPPING: 'Gotowe do wysyłki',
  deliveryStatus_WAITING: 'Oczekiwanie',
  deliveryStatus_DELIVERING: 'Dostarczanie',
  deliveryStatus_PICKPACK: 'Przygotowanie do wysyłki',
  deliveryStatus_PICKUP_COMPLETE: 'Odbiór zakończony',
  deliveryStatus_DELIVERY_COMPLETED: 'Dostawa zakończona',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Wystawienie płatności',
  deliveryStatus_READY: 'W trakcie realizacji',
  deliveryStatus_DELIVERY_REJECTED: 'Dostawa odrzucona',
  deliveryStatus_SHIPPED: 'Wysłano',
  deliveryStatus_TAX_NOT_COMMITTED: 'Kwestia podatku',
  deliveryStatus_CANCELLED: 'Anulowano',
  statusDisplay_cancelled: 'Anulowano',
  statusDisplay_cancelling: 'Anulowanie oczekujące',
  statusDisplay_confirmed: 'Zamówienie potwierdzone',
  statusDisplay_completed: 'Zamówienie dostarczone',
  statusDisplay_created: 'Zamówienie utworzone',
  statusDisplay_error: 'Oczekujące',
  statusDisplay_Error: 'Oczekujące',
  statusDisplay_processing: 'Utworzone zamówienie',
  statusDisplay_open: 'Utworzono zamówienie',
  statusDisplay_pending: {
    approval: 'Oczekujące na zatwierdzenie',
    merchant: {
      approval: 'Oczekuje na zatwierdzenie sprzedawcy',
    },
  },
  statusDisplay_approved: 'Zatwierdzone',
  statusDisplay_rejected: 'Odrzucone',
  statusDisplay_merchant: {
    approved: 'Sprzedawca zatwierdzony',
    rejected: 'Sprzedawca odrzucony',
  },
  statusDisplay_assigned: {
    admin: 'Przypisany do administratora',
  },
  consignmentTracking: {
    action: 'Śledzenie paczki',
    dialog: {
      header: 'Informacje o śledzeniu',
      shipped: 'Wysłano',
      estimate: 'Szacowana dostawa',
      carrier: 'Usługa doręczenia',
      trackingId: 'Numer przesyłki',
      noTracking: 'Paczka nie została wysłana z magazynu. Informacje o śledzeniu będą dostępne po wysłaniu paczki.',
      loadingHeader: 'Śledzenie przesyłki',
    },
  },
  cancellationAndReturn: {
    returnAction: 'Żądanie zwrotu',
    cancelAction: 'Anulowanie przesyłki',
    item: 'Pozycja',
    itemPrice: 'Cena przedmiotu',
    quantity: 'Maksymalna ilość',
    returnQty: 'Ilość do zwrotu',
    cancelQty: 'Ilość do anulowania',
    setAll: 'Ustaw wszystkie ilości na maksimum',
    totalPrice: 'Łącznie',
    submit: 'Prześlij żądanie',
    returnSuccess: 'Żądanie zwrotu ({{rma}}) zostało przesłane.',
    cancelSuccess:'Żądanie anulowania zostało przesłane (oryginalne zamówienie {{orderCode}} zostanie zaktualizowane)',
  },
  cancelReplenishment: {
    title: 'Anuluj uzupełnienie',
    description: 'Anulować przyszłe zamówienie uzupełnienia?',
    accept: 'Tak',
    reject: 'Nie',
    cancelSuccess: 'Zamówienie uzupełnienia #{{replenishmentOrderCode}} zostało pomyślnie anulowane.',
  },
};

export const orderHistory = {
  orderHistory: 'Historia zamówień',
  orderId: 'Zamówienie #',
  placedBy: 'Osoba kontaktowa',
  emptyPurchaseOrderId: 'Brak',
  date: 'Data',
  status: 'Status',
  total: 'Razem',
  noOrders: 'Nie mamy rekordów zamówień dla tego konta.',
  noReplenishmentOrders: 'Nie mamy żadnych rekordów zamówień uzupełnienia dla tego konta.',
  startShopping: 'Rozpocznij zakupy',
  sortBy: 'Sortuj według',
  replenishmentOrderHistory: 'Historia zamówień uzupełnienia',
  replenishmentOrderId: 'Uzupełnienie #',
  purchaseOrderNumber: 'PO #',
  startOn: 'Data rozpoczęcia',
  frequency: 'Częstotliwość',
  nextOrderDate: 'Data następnego zamówienia',
  cancel: 'Anulowane',
  cancelled: 'Anulowane',
  replenishmentHistory: 'Historia uzupełnień',
  notFound: 'Nie znaleziono zamówień',
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'ZAMÓWIENIA',
    OrderReturnRequestListComponent: 'OFERTY'
  }
}

export const wishlist = {
  empty: 'Na liście życzeń nie ma żadnych pozycji'
}

export const myAccount = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer,
  wishlist
}
