export const orderApprovalDetails = {
    back: "Zpět na seznam",
    showForm_APPROVE: "Schválit objednávku...",
    showForm_REJECT: "Zamítnout objednávku...",
    form: {
        title_APPROVE: "Schválit objednávku {{orderCode}} v celkové hodnotě {{orderTotal}}",
        title_REJECT: "Zamítnout objednávku {{orderCode}} v celkové hodnotě {{orderTotal}}",
        submit_APPROVE: "Schválit",
        submit_REJECT: "Zamítnout",
        cancel: "Zrušit",
        comment_APPROVE: {
            label: "Komentář (nepovinný, maximálně 255 znaků)",
            placeholder: "Komentář (maximálně 255 znaků)"
        },
        comment_REJECT: {
            label: "Komentář (maximálně 255 znaků)",
            placeholder: "Komentář (maximálně 255 znaků)"
        }
    },
    permissionResults: {
        header: "Údaje o schválení zákazníka",
        permission: "Povolení",
        approver: "Schvalovatel",
        status: "Stav",
        approverComments: "Komentář schvalovatele",
        noApprovalComments: "Žádné",
        permissionType_B2BBudgetExceededPermission: "Rozpočet spojený se zvoleným nákladovým střediskem byl překročen.",
        permissionType_B2BOrderThresholdPermission: "Celková částka objednávky překročila limit na objednávku",
        permissionType_B2BOrderThresholdTimespanPermission: "Celková hodnota objednávky překročila limit na časový úsek"
    }
};

export const orderApproval = {
    orderApprovalDetails,
};
