
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import {ICON_TYPE} from "@spartacus/storefront";
import {ActiveCartFacade} from "@spartacus/cart/base/root";

@Component({
  selector: 'custom-minicart-outlet',
  templateUrl: './minicart-outlet.component.html',
  styleUrls: ['./minicart-outlet.component.scss']
})
export class MinicartOutletComponent implements OnInit{
  iconTypes = ICON_TYPE;

  quantity$: Observable<number> = this.activeCartFacade.getActive().pipe(
    startWith({ deliveryItemsQuantity: 0 }),
    map((cart) => cart.deliveryItemsQuantity || 0)
  );

  constructor(protected activeCartFacade: ActiveCartFacade) {}

  ngOnInit(): void {
    // empty OnInit method
  }

}
