export const orderApprovalDetails = {
  back: 'Retour à la liste',
  showForm_APPROVE: 'Confirmer la commande...',
  showForm_REJECT: 'Annuler la commande...',
  form: {
    title_APPROVE: 'Confirmer la commande {{orderCode}} avec la valeur {{orderTotal}}.',
    title_REJECT: 'Annuler la commande {{orderCode}} avec la valeur {{orderTotal}}.',
    submit_APPROVE: 'Confirmer',
    submit_REJECT: 'Annuler',
    cancel: 'Annuler',
    comment_APPROVE: {
      label: 'Commentaire (facultatif, 255 caractères maximum)',
      placeholder: '',
    },
    comment_REJECT: {
      label: 'Commentaire (255 caractères maximum)',
      placeholder: '',
    },
  },
  permissionResults: {
    header: 'Client Détails de la commande',
    permission: 'Approbation',
    approver: 'Approbateur',
    status: 'Statut',
    approverComments: 'Commentaires Approbateur',
    noApprovalComments: 'Aucun',
    permissionType_B2BBudgetExceededPermission: 'Le budget associé au centre de coûts sélectionné a été dépassé.',
    permissionType_B2BOrderThresholdPermission: 'Le total de la commande a dépassé la limite par commande',
    permissionType_B2BOrderThresholdTimespanPermission: 'Le total de la commande a dépassé la limite par période',
  }
}

export const orderApprovalList = {
  orderCode: 'N° de commande',
  POCode: 'N° DE COMMANDE',
  placedBy: 'Passé par',
  date: 'Date de la commande',
  status: 'Statut',
  total: 'Total',
  none: 'Aucune',
  emptyList: 'Il n\'y a aucune commande à approuver pour le moment.',
}

export const orderApprovalGlobal = {
  notification: {
    noSufficientPermissions: 'Il n\'y a pas de permissions suffisantes pour accéder à cette page.',
  }
}

export const orderApproval = {
  orderApprovalDetails,
  orderApprovalList,
  orderApprovalGlobal
}
