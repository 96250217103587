export const configurator = {
  configurator: {
    header: {
      consistent: "一致性",
      complete: "完整",
      configId: "配置 ID",
      toconfig: "配置",
      toConfigReadOnly: "显示详细信息",
      editConfiguration: "编辑配置、",
      displayConfiguration: "显示配置",
      resolveIssues: "解决问题",
      resolveIssue: "解决问题",
      resolveConflicts: "解决冲突",
      conflictWarning: "必须解决冲突才能继续",
      updateMessage: "配置正在后台更新",
      conflictsResolved: "冲突已解决",
      showMore: "显示更多",
      showLess: "显示更少",
      items: "{{count}}项",
      items_other: "{{count}}项",
      show: "显示",
      hide: "隐藏",
      multipleWarnings: "有多个警告。",
      reviewWarnings: "查看这些警告",
      multipleErrors: "有多个错误。",
      reviewErrors: "查看这些错误",
      kbKeyName: "Kb 名称",
      kbKeyLogsys: "Kb 逻辑系统",
      kbKeyVersion: "Kb 版本",
      kbKeyBuildNr: "Kb 版本号",
    },
    tabBar: {
      configuration: "配置",
      overview: "概述",
    },
    notificationBanner: {
      numberOfIssues: "必须在结账前解决 {{count}} 问题。",
      numberOfIssues_other: "必须在结账前解决 {{count}} 问题。",
      numberOfConflicts: "必须在结账前解决 {{count}} 冲突。",
      numberOfConflicts_other: "必须在结账前解决 {{count}} 冲突。",
    },
    attribute: {
      id: "ID",
      quantity: "数量",
      caption: "属性",
      notSupported: "不支持属性类型。",
      requiredAttribute: "{{param}} 必填",
      defaultRequiredMessage: "为必填字段输入值",
      singleSelectRequiredMessage: "选择一个值",
      singleSelectAdditionalRequiredMessage: "选择或输入一个值",
      multiSelectRequiredMessage: "选择一个或多个值",
      wrongNumericFormat: "格式错误，应按照 {{pattern}} 模式输入数字",
      wrongNumericFormatMessage: "按以下格式输入数字",
      wrongIntervalFormat: "在指定范围内输入数值",
      deselectionNotPossible: "在删除此产品前添加另一个产品",
      dropDownSelectMsg: "进行选择",
      noOptionSelectedMsg: "未选择选项",
      notVisibleAttributeMsg: "用户看不到此属性",
    },
    button: {
      previous: "上一个",
      next: "下一个",
      back: "返回",
      more: "更多",
      less: "更少",
      deselect: "取消选择",
      select: "选择",
      add: "添加",
      remove: "删除",
      exit: "退出配置",
      exitMobile: "退出",
      cancelConfiguration: "取消配置",
      cancelConfigurationMobile: "取消",
      filterOverview: "筛选",
      filterOverviewWithCount: "筛选（{{numAppliedFilters}}）。",
    },
    icon: {
      groupComplete: "完成",
      groupIncomplete: "不完整",
      groupConflict: "冲突",
      subgroup: "有子类别",
    },
    priceSummary: {
      basePrice: "基本价格",
      selectedOptions: "选定选项",
      totalPrice: "总价",
      totalPricePerItem: "每项总价",
    },
    addToCart: {
      button: "添加到购物车",
      buttonAfterAddToCart: "继续购物车",
      buttonForQuote: "继续报价",
      buttonUpdateCart: "已完成",
      buttonDisplayOnly: "已完成",
      buttonClose: "关闭",
      confirmation: "配置已添加到购物车",
      confirmationUpdate: "购物车已更新配置",
      confirmationQuoteUpdate: "报价已根据配置更新",
      selectVariant: "为您的配置找到多个现有变体。在这种情况下，请联系客户服务部门选择正确的变体。",
      addMatchingVariant: "您配置的现有变体已添加到购物车。",
      quantity: "数量",
    },
    overviewForm: {
      noAttributeHeader: "无结果、",
      noAttributeText: "移除过滤器以查看概述内容、",
      itemPrice: "项目价格",
    },
    overviewSidebar: {
      menu: "菜单",
      filter: "筛选",
    },
    overviewFilter: {
      title: "选择过滤器",
      byOption: "按选项筛选",
      byGroup: "按组筛选",
      byPrice: "价格相关选项",
      mySelections: "我的选择",
      removeAll: "全部删除",
      removeAllFilters: "移除所有筛选器",
      removeByPrice: "移除价格相关筛选器",
      removeMySelections: "移除 “我的 ”筛选器",
      removeByGroup: "移除{{group}}的群组筛选器",
      noFiltersAvailable: "没有可用的筛选器",
    },
    group: {
      general: "常规",
      conflictHeader: "解决冲突",
      conflictGroup: "{{attribute}}的冲突",
    },
    conflict: {
      suggestionTitle: "建议 {{number}",
      suggestionText: "更改 {{ attribute }} 的值",
      viewConflictDetails: "检测到冲突 - 查看详情",
      conflictDetected: "检测到冲突",
      viewConfigurationDetails: "查看配置",
    },
    restartDialog: {
      title: "选择过滤器",
      description: "上一个会话中有一个未完成的配置。您想继续还是开始新配置？",
      restartButton: "开始新配置",
      resumeButton: "恢复",
    },
    httpHandlers: {
      makeToStockBaseProductIssue: "该产品是基础产品，不能添加到购物车；请选择产品变量",
    },
    a11y: {
      configureProduct: "配置产品",
      showDetailsProduct: "显示产品详细信息",
      cartEntryBundleInfo: "有一个项目",
      cartEntryBundleInfo_other: "有 {{items}} 项目",
      cartEntryBundleName: "项目 {{name}}",
      cartEntryBundleNameWithQuantity: "项目 {{name}} 项目数量 {{quantity}}",
      cartEntryBundleNameWithPrice: "项目 {{name}} 项目价格 {{price}}",
      cartEntryBundleNameWithPriceAndQuantity: "项目 {{name}} 项目价格 {{price}} 项目数量 {{quantity}}",
      cartEntryBundle: "产品具有以下属性和选定值",
      cartEntryInfoIntro: "属性 {{attribute}} 的选定值为 {{value}}",
      cartEntryInfo: "属性名称",
      nameOfAttribute: "属性 {{attribute}} 的值",
      valueOfAttribute: "属性 {{attribute}} 的值 {{value}}",
      forAttribute: "属性 {{attribute}} 的 {{value}} 值",
      valueOfAttributeFull: "属性 {{attribute}} 的值 {{value}}",
      valueOfAttributeFullWithPrice: "属性 {{attribute}} 的值 {{value}}}, 附加费 {{price}}",
      selectedValueOfAttributeFull: "属性 {{attribute}} 的选定值 {{value}}",
      selectedValueOfAttributeFullWithPrice: "属性 {{attribute}} 的选定值 {{value}}}，附加费 {{price}}、",
      readOnlyValueOfAttributeFullWithPrice: "属性 {{attribute}} 的只读值 {{value}}}，附加费 {{price}}、",
      readOnlyValueOfAttributeFull: "属性 {{attribute}} 的只读值 {{value}}",
      valueOfAttributeBlank: "属性 {{attribute}} 的值为空",
      value: "值 {{value}}",
      attribute: "属性 {{attribute}}",
      requiredAttribute: "{{param}} 必填",
      listOfAttributesAndValues: "属性及其值列表",
      editAttributesAndValues: "编辑属性值",
      group: "{{group}} 属性组",
      itemOfAttributeSelected: "属性 {{attribute}} 的 {{item}} 项被选中。{{itemCount}} 的 {{itemIndex}} 项",
      itemOfAttributeSelectedWithPrice: "属性 {{attribute}} 的项目 {{item}} 已选定，附加费 {{price}}。属性 {{attribute}} 的项目 {{itemIndex}} 被选中，附加费为 {{price}}。",
      itemOfAttributeSelectedPressToUnselect: "属性 {{attribute}} 的项目 {{item}} 已被选中。按下按钮取消选择。{{itemCount}} 的 {{itemIndex}} 被选中。",
      itemOfAttributeSelectedPressToUnselectWithPrice: "属性 {{attribute}} 的项目 {{item}} 已被选中，附加费 {{price}} 已被选中。按下按钮取消选择。{{itemCount}} 的 {{itemIndex}} 被取消选择。",
      itemOfAttributeUnselected: "属性 {{attribute}} 的项目 {{item}} 未被选中。按下按钮选择。属性 {{attribute}} 的 {{itemIndex}} 未被选中。",
      itemOfAttributeUnselectedWithPrice: "属性 {{attribute}} 的项目 {{item}} 未被选中，附加费 {{price}}。按键选择。{{itemCount}} 的 {{itemIndex}}",
      selectNoItemOfAttribute: "要不选择属性 {{attribute}} 的任何项目，请按按钮。{{itemCount}} 的 {{itemIndex}} {{itemCount}}",
      itemOfAttribute: "属性 {{attribute}} 的项目",
      itemOfAttributeFull: "属性 {{attribute}} 的 {{item}} 项",
      itemOfAttributeFullWithPrice: "属性 {{attribute}} 的项目 {{item}}, 附加费 {{price}}",
      itemOfAttributeFullWithQuantity: "属性 {{attribute}} 的项目 {{item}}, 数量 {{quantity}}",
      itemOfAttributeFullWithPriceAndQuantity: "属性 {{attribute}} 的项目 {{item}}, 数量 {{quantity}}, 附加费 {{price}}",
      itemDescription: "项目 {{item}} 的描述",
      listbox: "包含 {{count}} 值的列表框。",
      valueSurcharge: "附加费",
      conflictDetected: "检测到冲突",
      conflictsInConfiguration: "您的配置中存在冲突。冲突数量",
      listOfGroups: "组列表",
      inListOfGroups: "您在组列表中",
      groupName: "组 {{group}}",
      groupBack: "您所在的是子组。选择返回。",
      conflictBack: "您正处于冲突解决程序中。选择返回或选择下一项以解决冲突。",
      iconConflict: "组有冲突。",
      iconIncomplete: "组中有所需属性但未选中值。",
      iconComplete: "组已完成。",
      iconSubGroup: "组有一个子组。",
      next: "下一个",
      previous: "上一个",
      showMoreProductInfo: "显示产品 {{product}} 的更多信息或继续配置。",
      showLessProductInfo: "显示更少产品信息 {{product}} 或继续配置。",
      productName: "产品名称",
      productCode: "产品代码",
      productDescription: "产品描述",
      kbKeyName: "Kb 名称",
      kbKeyLogsys: "Kb 逻辑系统",
      kbKeyVersion: "Kb 版本",
      kbKeyBuildNr: "Kb 版本号",
      configurationPage: "您正在配置页面。",
      configurationPageLink: "导航至配置页面。",
      overviewPage: "您正在概览页面。使用屏幕阅读器的阅读模式检查属性及其选定值。",
      overviewPageLink: "导航至配置概览页面。",
      navigateToConflict: "按照链接获取属性 {{attribute}} 冲突的更多详情。",
      numericIntervalStandard: "输入介于 {{minValue}} 和 {{maxValue}} 之间的值。",
      numericIntervalStandardOpen: "(不包括端点）",
      numericIntervalStandardLowerEndpointNotIncluded: "(不包括下端点）",
      numericIntervalStandardUpperEndpointNotIncluded: "(不包括上端点）",
      numericInfiniteIntervalMinValueIncluded: "输入大于或等于 {{value}} 的值",
      numericInfiniteIntervalMinValue: "输入大于 {{value}} 的值",
      numericInfiniteIntervalMaxValueIncluded: "输入小于或等于 {{value}} 的值",
      numericInfiniteIntervalMaxValue: "输入小于 {{value}} 的值",
      numericIntervalSingleValue: "输入 {{value}}",
      combinedIntervalsText: "{{combinedInterval}} 或 {{newInterval}}。",
      additionalValue: "您还可以输入附加值。",
      addToCartPrices: "基本价格 {{basePrice}}, 所选选项 {{selectedOptions}}, 总价 {{totalPrice}}",
      filterOverview: "筛选",
      filterOverviewWithCount: "筛选（{{numAppliedFilters}}）。",
      closeFilterMenu: "关闭配置概览筛选器菜单并应用所选筛选器",
      filterOverviewByPrice: "根据价格相关选项筛选配置概览",
      filterOverviewByMySelections: "按您的选择过滤配置概览",
      filterOverviewByGroup: "按组 {{groupName}} 过滤配置概览",
      closeConflictSolverModal: "关闭冲突解决模式",
      closeRestartDialog: "关闭未完成配置对话框并返回产品详细信息页面",
      description: "上一个会话中有一个未完成的配置。您想继续还是开始新配置？",
    },
      variantCarousel: {
        title: "预配置版本",
      },
}
}
