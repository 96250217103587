import { CartAdapter } from "@spartacus/cart/base/core";
import { OccCartAdapter } from "@spartacus/cart/base/occ";
import { Cart, SaveCartResult, CART_NORMALIZER } from "@spartacus/cart/base/root";
import { Occ } from "@spartacus/core";
import { Observable, map } from "rxjs";
import { rationaOCCEndpoints } from "../../rational-backend-occ-endpoint/rational-backend-occ-endpoint.module";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class RationalCartAdapter extends OccCartAdapter {

    saveCart(
        userId: string,
        cartId: string,
        saveCartName: string,
        saveCartDescription: string,
        isSharedWithB2BUnit: boolean
      ): Observable<Cart> {
        const endpoint = this.occEndpointsService.buildUrl(rationaOCCEndpoints.sharedCart.saveSharedCart, {
            urlParams: {
              userId,
              cartId,
              saveCartName,
              saveCartDescription,
              isSharedWithB2BUnit
            },
          });
        return this.http.patch<Occ.Cart>(endpoint, cartId).pipe(
          map((cartResponse) => (cartResponse as SaveCartResult).savedCartData),
          this.converterService.pipeable(CART_NORMALIZER)
        );
      }
}
