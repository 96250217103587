export const configurator = {
  configurator: {
    header: {
      consistent: 'Consistente',
      complete: 'Completo',
      configId: 'ID de configuración',
      toconfig: 'Configurar',
      toConfigReadOnly: 'Mostrar detalles',
      editConfiguration: 'Editar Configuración,',
      displayConfiguration: 'Mostrar configuración',
      resolveIssues: 'Resolver problemas',
      resolveIssue: 'Resolver problemas',
      resolveConflicts: 'Resolver conflictos',
      conflictWarning: 'El conflicto debe resolverse para continuar',
      updateMessage: 'La configuración se está actualizando en segundo plano',
      conflictsResolved: 'Los conflictos se han resuelto',
      showMore: 'Mostrar más',
      showLess: 'mostrar menos',
      items: '{{count}} elemento',
      items_other: '{{count}} elemento',
      show: 'mostrar',
      hide: 'ocultar',
      multipleWarnings: 'Hay varias advertencias.',
      reviewWarnings: 'Revise estas advertencias',
      multipleErrors: 'Hay varios errores.',
      reviewErrors: 'Revise estos errores',
      kbKeyName: 'Kb nombre',
      kbKeyLogsys: 'Kb sistema lógico',
      kbKeyVersion: 'Versión de Kb',
      kbKeyBuildNr: 'Kb número de compilación',
    },
    tabBar: {
      configuration: 'Configuración',
      overview: 'Resumen',
    },
    notificationBanner: {
      numberOfIssues: '{{count}} problema debe ser resuelto antes de la salida.',
      numberOfIssues_other: '{{count}} los problemas deben resolverse antes de pagar.',
      numberOfConflicts: '{{count}} conflicto debe ser resuelto antes de la salida.',
      numberOfConflicts_other: '{{count}} los conflictos deben resolverse antes de pasar por caja.',
    },
    attribute: {
      id: 'Número de artículo',
      quantity: 'Cantidad',
      caption: 'Atributos',
      notSupported: 'No se admite el tipo de atributo.',
      requiredAttribute: '{{param}} obligatorio',
      defaultRequiredMessage: 'Introduzca un valor para el campo obligatorio',
      singleSelectRequiredMessage: 'Seleccione un valor',
      singleSelectAdditionalRequiredMessage: 'Seleccione o introduzca un valor',
      multiSelectRequiredMessage: 'Seleccione uno o más valores',
      wrongNumericFormat: 'Formato incorrecto, este atributo numérico debe introducirse según el patrón {{pattern}}',
      wrongNumericFormatMessage: 'Introduzca el número con el siguiente formato',
      wrongIntervalFormat: 'Introduzca un valor dentro de los límites indicados',
      deselectionNotPossible: 'Añada otro producto antes de eliminar éste',
      dropDownSelectMsg: 'Haga una selección',
      noOptionSelectedMsg: 'Ninguna opción seleccionada',
      notVisibleAttributeMsg: 'Este atributo no es visible para el usuario',
    },
    button: {
      previous: 'Anterior',
      next: 'Siguiente',
      back: 'Atrás',
      more: 'más',
      less: 'menos',
      deselect: 'Deseleccionar',
      select: 'Seleccionar',
      add: 'Añadir',
      remove: 'Eliminar',
      exit: 'Salir de la configuración',
      exitMobile: 'Salir de',
      cancelConfiguration: 'Cancelar la configuración',
      cancelConfigurationMobile: 'Cancelar',
      filterOverview: 'Filtro',
      filterOverviewWithCount: 'Filtrar ({{numAppliedFilters}})',
    },
    icon: {
      groupComplete: 'Completo',
      groupIncomplete: 'Incompleto',
      groupConflict: 'Conflicto',
      subgroup: 'tiene subgrupos',
    },
    priceSummary: {
      basePrice: 'Precio base',
      selectedOptions: 'Opciones seleccionadas',
      totalPrice: 'Total',
      totalPricePerItem: 'Total por artículo',
    },
    addToCart: {
      button: 'Añadir a la cesta',
      buttonAfterAddToCart: 'Continuar con la cesta',
      buttonForQuote: 'Continuar con el presupuesto',
      buttonUpdateCart: 'Hecho',
      buttonDisplayOnly: 'Hecho',
      buttonClose: 'Cerrar',
      confirmation: 'La configuración se ha añadido al carrito',
      confirmationUpdate: 'El carrito se ha actualizado con la configuración',
      confirmationQuoteUpdate: 'El presupuesto se ha actualizado con la configuración',
      quantity: 'Cantidad',
      selectVariant: 'Se ha encontrado más de una variante para su configuración. En este caso, ponte en contacto con el servicio de atención al cliente para seleccionar la variante correcta.',
      addMatchingVariant: 'La variante existente para su configuración se ha añadido a la cesta de la compra.'
    },
    overviewForm: {
      noAttributeHeader: 'No hay resultados,',
      noAttributeText: 'Quitar filtro(s) para ver el contenido general,',
      itemPrice: 'Precio del artículo',
    },
    overviewSidebar: {
      menu: 'Menú',
      filter: 'Filtrar',
    },
    overviewFilter: {
      title: 'Seleccionar filtros',
      byOption: 'Filtrar por opción',
      byGroup: 'Filtrar por grupo',
      byPrice: 'Opciones relevantes para el precio',
      mySelections: 'Mis selecciones',
      removeAll: 'Eliminar todo',
      removeAllFilters: 'Eliminar todos los filtros',
      removeByPrice: 'Quitar el filtro de precios',
      removeMySelections: 'Quitar el filtro Mis selecciones',
      removeByGroup: 'Quitar filtro de grupo para el grupo {{group}}',
      noFiltersAvailable: 'No hay filtros disponibles'
    },
    group: {
      general: 'General',
      conflictHeader: 'Resolver conflictos',
      conflictGroup: 'Conflicto para {{attribute}}',
    },
    conflict: {
      suggestionTitle: 'Sugerencia {{number}}',
      suggestionText: 'Cambiar el valor de {{attribute}}',
      viewConflictDetails: 'Conflicto detectado - Ver detalles',
      conflictDetected: 'Conflicto detectado',
      viewConfigurationDetails: 'Ver en configuración',
    },
    restartDialog: {
      title: 'Configuración inacabada',
      description: 'Hay una configuración inacabada de una sesión anterior. ¿Desea reanudar o iniciar una nueva configuración?',
      restartButton: 'Iniciar nueva',
      resumeButton: 'Reanudar',
    },
    httpHandlers: {
      makeToStockBaseProductIssue: 'Este producto es un producto base y no puede añadirse a la cesta; elija una variante de producto en su lugar',
    },
    a11y: {
      configureProduct: 'Configurar producto',
      showDetailsProduct: 'Mostrar detalles del producto',
      cartEntryBundleInfo: 'Hay un artículo',
      cartEntryBundleInfo_other: 'Hay artículos {{items}}',
      cartEntryBundleName: 'Artículo {{ number }}',
      cartEntryBundleNameWithQuantity: 'Artículo {{number }} artículo cantidad {{quantity}}',
      cartEntryBundleNameWithPrice: 'Artículo {{ name }} precio del artículo {{price}}',
      cartEntryBundle: 'Artículo {{number }} precio del artículo {{price}} cantidad del artículo {{quantity}}',
      cartEntryInfoIntro: 'El producto tiene los siguientes atributos y valores seleccionados',
      cartEntryInfo: 'Atributo {{ attribute }} tiene valor seleccionado {{value}}',
      nameOfAttribute: 'Nombre del atributo',
      valueOfAttribute: 'Valor del atributo {{ attribute }}',
      forAttribute: '{{value}} del atributo {{attribute}}',
      valueOfAttributeFull: 'Valor {{ value }} del atributo {{ attribute }}',
      valueOfAttributeFullWithPrice: 'Valor {{ value }} del atributo {{ attribute }}, Recargo {{ price }}',
      selectedValueOfAttributeFull: 'Valor {{value}} seleccionado del atributo {{attribute}}',
      selectedValueOfAttributeFullWithPrice: 'Valor {{value}} seleccionado del atributo {{attribute}}, Recargo {{ price }},',
      readOnlyValueOfAttributeFullWithPrice: 'Valor de sólo lectura {{value}} del atributo {{attribute}}, Recargo {{ price }},',
      readOnlyValueOfAttributeFull: 'Valor de sólo lectura {{ value }} del atributo {{ attribute }}',
      valueOfAttributeBlank: 'Valor {{value}} del atributo {{attribute}} está en blanco',
      value: 'Valor {{ value }}',
      attribute: 'Atributo {{ attribute }}',
      requiredAttribute: 'El atributo {{param}} es obligatorio',
      listOfAttributesAndValues: 'Lista de atributos y sus valores',
      editAttributesAndValues: 'Editar valores de atributos',
      group: '{{group}} grupo de atributos',
      itemOfAttributeSelected: 'El elemento {{ item }} del atributo {{ attribute }} está seleccionado. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttributeSelectedWithPrice: 'Se selecciona el elemento {{ item }} del atributo {{ attribute }}, Recargo {{ price }}. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttributeSelectedPressToUnselect: 'Se ha seleccionado el artículo {{ item }} del atributo {{ attribute }}. Pulse el botón para anular la selección. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttributeSelectedPressToUnselectWithPrice: 'Se ha seleccionado el artículo {{ item }} del atributo {{ attribute }}, Recargo {{ price }}. Pulse el botón para anular la selección. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttributeUnselected: 'El artículo {{ item }} del atributo {{ attribute }} no está seleccionado. Pulse el botón para seleccionar. {{ itemIndex }} de {{ itemCount }}',
      itemOfAttributeUnselectedWithPrice: 'Artículo {{ item }} del atributo {{ attribute }} no está seleccionado, Recargo {{ price }}. Pulse el botón para seleccionar. {{ itemIndex }} de {{ itemCount }}',
      selectNoItemOfAttribute: 'Para no seleccionar ningún elemento del atributo {{ attribute }}, pulse el botón . {{ itemIndex }} de {{ itemCount }}',
      itemOfAttribute: 'Elemento del atributo {{ attribute }}',
      itemOfAttributeFull: 'Elemento {{ item }} del atributo {{attribute}}',
      itemOfAttributeFullWithPrice: 'Artículo {{ item }} del atributo {{ attribute }}, Recargo {{ price }}',
      itemOfAttributeFullWithQuantity: 'Artículo {{item}} del atributo {{attribute}}, Cantidad {{ quantity }}',
      itemOfAttributeFullWithPriceAndQuantity: 'Artículo {{item}} de atributo {{attribute}}, Cantidad {{ quantity }}, Recargo {{ precio }}',
      itemDescription: 'Descripción del elemento {{ item }}',
      listbox: 'Listbox con {{ count }} valores.',
      valueSurcharge: 'Recargo de valor',
      conflictDetected: '¡Conflicto detectado! Compruebe los conflictos en la parte superior de la lista de grupos.',
      conflictsInConfiguration: 'Hay conflictos en su configuración. Número de conflictos',
      listOfGroups: 'Lista de grupos',
      inListOfGroups: 'Estás en la lista de grupos',
      groupName: 'Grupo {{group}}',
      groupBack: 'Se encuentra en un subgrupo. Seleccione para volver atrás.',
      conflictBack: 'Se encuentra en el solucionador de conflictos. Seleccione para volver atrás o seleccione las siguientes pestañas para resolver conflictos.',
      iconConflict: 'El grupo tiene conflictos.',
      iconIncomplete: 'El grupo tiene atributos requeridos sin valores seleccionados.',
      iconComplete: 'El grupo está completo.',
      iconSubGroup: 'El grupo tiene un subgrupo.',
      next: 'Navegar al grupo siguiente.',
      previous: 'Ir al grupo anterior.',
      showMoreProductInfo: 'Mostrar más información sobre el producto {{ product }} o continuar con la configuración.',
      showLessProductInfo: 'Mostrar menos información sobre el producto {{ product }} o continuar con la configuración.',
      productName: 'Nombre del producto',
      productCode: 'Código del producto',
      productDescription: 'Descripción del producto',
      kbKeyName: 'Kb nombre {{name}}',
      kbKeyLogsys: 'Kb sistema lógico {{logsys}}',
      kbKeyVersion: 'Kb versión {{version}}',
      kbKeyBuildNr: 'Número de compilación Kb {{number}}',
      configurationPage: 'Se encuentra en la página de configuración.',
      configurationPageLink: 'Vaya a la página de configuración.',
      overviewPage: 'Se encuentra en la página de vista general. Compruebe los atributos y sus valores seleccionados con el modo de lectura de su lector de pantalla.',
      overviewPageLink: 'Vaya a la página de resumen de la configuración.',
      navigateToConflict: 'Siga el enlace para obtener más detalles sobre el conflicto del atributo {{ attribute }}.',
      numericIntervalStandard: 'Introduzca un valor entre {{ minValue }} y {{ maxValue }}.',
      numericIntervalStandardOpen: '(No se incluyen los puntos finales)',
      numericIntervalStandardLowerEndpointNotIncluded: '(No se incluye el extremo inferior)',
      numericIntervalStandardUpperEndpointNotIncluded: '(No se incluye el punto final superior)',
      numericInfiniteIntervalMinValueIncluded: 'Introduzca un valor mayor o igual que {{ value }}',
      numericInfiniteIntervalMinValue: 'Introduzca un valor mayor que {{ value }}',
      numericInfiniteIntervalMaxValueIncluded: 'Introduzca un valor inferior o igual a {{ value }}',
      numericInfiniteIntervalMaxValue: 'Introduzca un valor inferior a {{ value }}',
      numericIntervalSingleValue: 'Introduzca {{value}}',
      combinedIntervalsText: '{{ combinedInterval }} o {{ newInterval }}',
      additionalValue: 'También puede introducir un valor adicional.',
      addToCartPrices: 'Precio base {{basePrice}}, Opciones seleccionadas {{selectedOptions}}, Precio total {{totalPrice}}',
      filterOverview: 'Abrir el menú de filtros de la vista general de la configuración',
      filterOverviewWithCount: 'Abrir el menú de filtros de la vista general de la configuración - {{numAppliedFilters}} filtros activos',
      closeFilterMenu: 'Cerrar el menú de filtros de la vista general de la configuración y aplicar los filtros seleccionados',
      filterOverviewByPrice: 'Filtrar el resumen de configuración por las opciones relevantes para el precio',
      filterOverviewByMySelections: 'Filtrar el resumen de configuración por sus selecciones',
      filterOverviewByGroup: 'Filtrar el resumen de configuración por grupo {{groupName}}',
      closeConflictSolverModal: 'Cerrar el modal del solucionador de conflictos',
      closeRestartDialog: 'Cierre el cuadro de diálogo «Configuración inacabada» y vuelva a la página de detalles del producto.',
      description: 'Kliknij, aby zobaczyć opis tej wartości',
    },
    variantCarousel: {
      title: 'Wstępnie skonfigurowane wersje',
    }
  }
};
