import { Component, ViewEncapsulation } from '@angular/core';
import { SavedCartDetailsOverviewComponent } from '@spartacus/cart/saved-cart/components';

@Component({
  selector: 'rational-saved-cart-details-overview',
  templateUrl: './rational-saved-cart-detials-overview.component.html',
  styleUrls: ['./rational-saved-cart-detials-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RationalSavedCartDetialsOverviewComponent extends SavedCartDetailsOverviewComponent{

}
