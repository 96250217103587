export const pageMetaResolver = {
  category: {
    title: '{{count}} résultats pour {{query}}',
    title_plural: '{{count}} résultats pour {{query}}',
  },
  checkout: {
    title: 'Passer à la caisse',
  },
  search: {
    title: '{{count}} résultat pour \'{{query}}\'',
    title_plural: '{{count}} résultats pour \'{{query}}\'',
    findProductTitle: '{{count}} résultat pour le coupon \'{{coupon}}\'',
    findProductTitle_plural: '{{count}} résultats pour coupon \'{{coupon}}\'',
    default_title: 'Tous les produits',
  },
  product: {
    description: '{{description}}',
    heading: '{{heading}}',
    title: '{{title}}',
  }
}

export const spinner = {
  loading: 'Chargement...',
}

export const navigation = {
  shopAll: 'Tout acheter {{ navNode}} >',
  listPrice: {
    enableListPrice: 'Activer le prix de catalogue',
    listPriceIsEnabled: 'Le prix de catalogue est activé',
    listPriceIsDisabled: 'Le prix de catalogue est désactivé'
  }
}

export const searchBox = {
  placeholder: 'Rechercher ici...',
  ariaLabelInput: 'Rechercher ici...',
  ariaLabelSuggestions: 'suggestions de saisie',
  ariaLabelProducts: 'résultats des produits',
  initialDescription: 'Lorsque les résultats de la saisie semi-automatique sont disponibles, utilisez les flèches vers le haut et vers le bas pour passer en revue les résultats et entrez pour les sélectionner. Utilisateurs d\'appareils tactiles, explorez par le toucher ou avec des gestes de balayage.',
  suggestionsResult: '{{ count }} suggestions et ',
  suggestionsResult_plural: '{{ count }} suggestions et ',
  productsResult: 'Le produit {{ count }} est disponible.',
  productsResult_plural: 'Les produits {{ count }} sont disponibles.',
  resetLabel: 'Réinitialiser la recherche',
  help: {
    insufficientChars: 'Veuillez saisir plus de caractères',
    noMatch: 'Nous n\'avons trouvé aucun résultat',
    exactMatch: '{{ term }}',
    empty: 'Demandez-nous quelque chose',
  }
}

export const sorting = {
  date: 'Date',
  orderNumber: 'Numéro de commande',
  rma: 'Numéro de retour',
  replenishmentNumber: 'Numéro de réapprovisionnement',
  nextOrderDate: 'Date de la prochaine commande',
}

export const httpHandlers = {
  badGateway: 'Une erreur de serveur s\'est produite. Veuillez réessayer plus tard.',
  badRequestPleaseLoginAgain: '{{ errorMessage }}. Veuillez vous connecter à nouveau.',
  badRequestOldPasswordIncorrect: 'L\'ancien mot de passe est incorrect.',
  badRequestGuestDuplicateEmail: 'L\'email de {{ errorMessage }} existe déjà. Veuillez saisir un autre e-mail pour vous enregistrer en tant qu\'invité.',
  conflict: 'Existe déjà.',
  forbidden: 'Vous n\'êtes pas autorisé à effectuer cette action. Veuillez contacter votre administrateur si vous pensez qu\'il s\'agit d\'une erreur.',
  gatewayTimeout: 'Le serveur n\'a pas répondu, veuillez réessayer plus tard.',
  internalServerError: 'Une erreur interne du serveur s\'est produite. Veuillez réessayer plus tard.',
  sessionExpired: 'Votre session a expiré. Veuillez vous connecter à nouveau.',
  unknownError: 'Une erreur inconnue s\'est produite',
  unauthorized: {
    common: 'Une erreur d\'autorisation inconnue s\'est produite',
    invalid_client: 'Mauvaises informations d\'identification du client',
  },
  validationErrors: {
    missing: {
      card_cardType: 'La carte de crédit sélectionnée n\'est pas prise en charge. Veuillez en sélectionner une autre.',
      card_accountNumber: 'Le numéro de carte de crédit saisie n\'est pas valide.',
      card_cvNumber: 'Le code de sécurité saisie n\'est pas valide.',
      card_expirationMonth: 'La date d\'expiration de la carte de crédit saisie n\'est pas valide.',
      card_expirationYear: 'La date d\'expiration de la carte de crédit saisie n\'est pas valide.',
      billTo_firstName: 'Le prénom saisi n\'est pas valide.',
      billTo_lastName: 'Le nom de famille saisi n\'est pas valide.',
      billTo_street1: 'L\'adresse saisie n\'est pas valide.',
      billTo_street2: 'L\'adresse saisie n\'est pas valide.',
      billTo_city: 'La ville indiquée n\'est pas valable pour cette carte de crédit.',
      billTo_state: 'L\'état/province indiqué n\'est pas valable pour cette carte de crédit.',
      billTo_country: 'Le pays/la région indiqué(e) n\'est pas valable pour cette carte de crédit.',
      billTo_postalCode: 'Le code postal n\'est pas valide pour cette carte de crédit.',
      country: {
        isocode: 'Pays manquant',
      },
    },
    invalid: {
      card_expirationMonth: 'La date d\'expiration de la carte de crédit saisie n\'est pas valide.',
      firstName: 'Le prénom saisi n\'est pas valide.',
      lastName: 'Le nom de famille saisi n\'est pas valide.',
      password: 'Le mot de passe saisi n\'est pas valide.',
      uid: 'L\'UID n\'est pas valide.',
      code: 'Le code n\'est pas valide.',
      email: 'L\'email n\'est pas valide.',
    },
  },
  cartNotFound: 'Panier non trouvé.',
  invalidCodeProvided: 'Code invalide fourni.',
}

export const miniCart = {
  item: 'Le panier contient actuellement {{count}} articles',
  item_plural: 'Les articles de {{count}} se trouvent actuellement dans votre panier.',
  total: '{{total}}',
  count: '{{count}}',
}

export const skipLink = {
  skipTo: 'Passer à',
  labels: {
    header: 'En-tête',
    main: 'Contenu principal',
    footer: 'Pied de page',
    productFacets: 'Facettes produits',
    productList: 'Liste des produits',
  },
}

export const formErrors = {
  globalMessage: 'Le formulaire que vous essayez de soumettre contient des erreurs.',
  required: 'Ce champ est obligatoire',
  cxInvalidEmail: 'Ce n\'est pas un format d\'email valide',
  cxInvalidPassword: 'Le format de ce mot de passe n\'est pas valide',
  cxPasswordsMustMatch: 'Les champs du mot de passe doivent correspondre',
  cxEmailsMustMatch: 'Les champs de l\'e-mail doivent correspondre',
  cxStarRatingEmpty: 'Le champ Classement est obligatoire',
  cxNoSelectedItemToCancel: 'Sélectionner au moins un élément',
  cxNegativeAmount: 'Le montant doit être égal ou supérieur à zéro',
  cxContainsSpecialCharacters: 'Le champ ne peut pas contenir de caractères spéciaux',
  date: {
    required: 'Ce champ est obligatoire',
    min: 'La date ne peut être antérieure à {{min}}',
    max: 'La date ne peut être postérieure à {{max}}',
    pattern: 'Utiliser le format de date aaaa-mm-jj',
    invalid: 'Utiliser une date valide',
  },
  file: {
    invalidQunatity: 'Quantité non valide pour le produit dans les lignes : {{ index }}',
    missingArticleNumber: 'Numéro d\'article manquant dans les lignes : {{ index }}',
    importCartReopenDialogueText: 'Veuillez fermer la boîte de dialogue d\'importation du panier, puis mettre à jour votre fichier et réessayer le téléchargement.',
  },
}

export const errorHandlers = {
  scriptFailedToLoad: 'Échec du chargement du script.',
  refreshThePage: 'Veuillez actualiser la page.',
}

export const common = {
  pageMetaResolver,
  spinner,
  navigation,
  searchBox,
  sorting,
  httpHandlers,
  miniCart,
  skipLink,
  formErrors,
  errorHandlers,
  common: {
    cancel: 'Annuler',
    delete: 'Supprimer',
    remove: 'Supprimer',
    edit: 'Modifier',
    restore: 'Restaurer',
    back: 'Retourner',
    submit: 'Soumettre',
    continue: 'Continuer',
    save: 'Sauvegarder',
    done: 'Terminé',
    home: 'Accueil',
    noData: 'Pas de données.',
    confirm: 'Confirmer',
    more: 'plus',
    close: 'Fermer',
    loading: 'Chargement',
    menu: 'Menu',
    reset: 'Réinitialiser',
    search: 'Rechercher',
    add: 'Ajouter',
    userGreeting: 'Bienvenue',
    privacySettings: 'Paramètres de confidentialité',
  },
}
