
import { NgModule } from '@angular/core';
import { I18nModule, provideConfig } from '@spartacus/core';

import { CommonModule } from '@angular/common';
import { IconModule, KeyboardFocusModule, SpinnerModule } from '@spartacus/storefront';
import { OrderBillingInvoicesDialogComponent } from './order-billing-invoices.component';
import { defaultOrderInformationLayoutConfig } from '../order-information-layout.config';
import { RationalOrderDocumentsListModule } from '../../order-documents-list/rational-order-document-list.module';

@NgModule({
  declarations: [OrderBillingInvoicesDialogComponent],
  exports: [OrderBillingInvoicesDialogComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    SpinnerModule,
    KeyboardFocusModule,
    RationalOrderDocumentsListModule
  ],
  providers: [
    provideConfig(defaultOrderInformationLayoutConfig),
  ],
})
export class OrderBillingInvoicesModule { }
