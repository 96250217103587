export const checkoutAddress = {
  shippingAddress: 'Leveransadress',
  selectYourShippingAddress: 'Välj din leveransadress:',
  selectYourDeliveryAddress: 'Välj din leveransadress:',
  defaultShippingAddress: 'Standard leveransadress',
  addNewAddress: 'Lägg till ny adress',
  shipToThisAddress: 'Skicka till den här adressen',
  addressSearch: 'Sökning av adress',
  addressSearchMinLength: 'Söktexten ska vara minst {{length}} tecken',
  submitAddressSearch: 'Ange',
  errorSearchingSalesforce: 'Kan inte slutföra sökningen just nu, vänligen försök igen senare',
  deliveryAddressSelected: 'Leveransadress vald',
};

export const checkoutOrderConfirmation = {
  confirmationOfOrder: 'Bekräftelse av order:',
  confirmationOfQuote: 'Bekräftelse av mottagande av offert:',
  thankYou: 'Tack för din beställning!',
  thankYouQuote: 'Tack för din offertförfrågan!',
  invoiceHasBeenSentByEmailLine1: 'Observera att det kan ta upp till en arbetsdag innan denna order visas i din orderhistorik. När den väl finns där kommer den att innehålla den formella orderbekräftelsen tillsammans med det avsändnings-/hämtningsdatum som bekräftats av RATIONAL och komplett prisinformation inklusive tillämpliga skatter. Om du har några frågor om denna order under tiden, vänligen kontakta din RATIONAL kundtjänst och hänvisa till order-ID som visas ovan.',
  invoiceHasBeenSentByEmailLine2: 'Observera att dina offertförfrågningar inte kommer att överföras till din orderhistorik i webbshoppen. Om du har några frågor om denna offertförfrågan, vänligen kontakta din RATIONAL-kundtjänst och hänvisa till webbshopens offertnummer som visas ovan eller ditt postnummer.',
  quoteHasBeenSentByEmailLine1: 'Observera att dina offertförfrågningar inte kommer att överföras till din orderhistorik i webbshoppen.',
  quoteHasBeenSentByEmailLine2: 'Om du har några frågor om denna offertförfrågan, vänligen kontakta din RATIONAL-kundtjänst och hänvisa till webbshopens offertnummer som visas ovan eller ditt postnummer.',
  orderItems: 'Orderobjekt',
  orderPlacedSuccessfully: 'Beställningen har genomförts framgångsrikt',
  createAccount: 'Skapa ett konto?',
  createAccountForNext: 'Skapa ett konto för <{{email}}> för en snabbare utcheckning vid ditt nästa besök.',
};

export const checkoutReview = {
  review: 'Sammanfattning av order',
  orderItems: 'Beställ artiklar',
  autoReplenishOrder: 'Beställning av automatisk påfyllning',
  confirmThatRead: 'Jag bekräftar att jag har läst och godkänt villkoren för',
  placeOrder: 'Gör en beställning',
  placeQuoteOrder: 'Begär offert',
  scheduleReplenishmentOrder: 'Schemalägg beställning av påfyllning',
  orderType_PLACE_ORDER: 'Ingen (endast engångsbeställning)',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Fyll på order',
  termsAndConditions: 'Villkor och bestämmelser',
  editShippingAddress: 'Redigera leveransadress',
  poNumber: 'Nummer på inköpsorder',
  every: 'Alla',
  startOn: 'Börja på',
  dayOfMonth: 'På dagen',
  repeatOnDays: 'Upprepas följande dagar',
  recurrencePeriodType_DAILY: 'Dag(ar)',
  recurrencePeriodType_WEEKLY: 'Vecka(n)',
  recurrencePeriodType_MONTHLY: 'Månad',
};

export const checkoutShipping = {
  shippingMethod: 'Leveransmetod',
  standardDelivery: 'Standardleverans',
  premiumDelivery: 'Premium-leverans',
};

export const checkoutPO = {
  noPoNumber: 'Ingen',
  poNumber: 'Ditt inköpsordernummer',
  costCenter: 'Kostnadscenter',
  placeholder: 'Ange ditt eget P.O.-nummer...',
  availableLabel: 'Tillgängliga leveransadresser för dig beror på kostnadsställets enhet',
};

export const checkoutProgress = {
  methodOfPayment: 'Metod för betalning',
  deliveryAddress: 'Leveransadress',
  deliveryMode: 'Leveranssätt',
  paymentDetails: 'Detaljer om betalning',
  reviewOrder: 'Sammanfattning av order',
};

export const checkoutB2B = {
  checkoutB2B: {
  poNumber: 'Ditt inköpsordernummer',
  placeholder: 'Ange ditt eget P.O.-nummer...',
  methodOfPayment: {
    paymentType: 'Metod för betalning',
  },
  progress: {
    methodOfPayment: 'Metod för betalning',
  },
  review: {
    poNumber: 'Ditt inköpsordernummer',
  },
}
};

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: 'Incoterms',
    paymentConditions: 'Betalningsvillkor',
  }
};

export const checkoutMode = {
  deliveryMethod: 'Leveransmetod',
  deliveryEntries: 'Artiklar som ska skickas',
  specialShippingInstructions: 'Särskilda leveransinstruktioner',
  specialShippingInstructionsPlaceholder: 'Särskilda leveransinstruktioner',
};

  export const checkout = {
    checkoutAddress,
    checkoutOrderConfirmation,
    checkoutReview,
    checkoutShipping,
    checkoutPO,
    checkoutProgress,
    checkout: {
      backToCart: 'Tillbaka till varukorgen',
      invalid: {
        accountType: 'Ditt konto tillåter dig inte att gå till kassan här.'
      }
    },
    checkoutMode
  }