export const cartDetails = {
  id: 'ID',
  proceedToCheckout: 'チェックアウトに進む',
  createQuoteFromCart: '見積依頼',
  cartName: 'カート #{{code}}',
  minOrderNotReached: '最低注文金額は{{minOrderValue}}{{currency}}です。',
  orderLocked: '注意：現在、ご注文いただけません。ユーザーアカウントに問題がある可能性がございますので、カスタマーサービスにお問い合わせ下さい。',
  requestedRetrievalDateEmpty: '配送希望日を選択してください',
  requestedRetrievalDateAdded: '配送希望日の追加に成功しました',
  requestedRetrievalDateInvalid: '配送希望日が無効です',
  s4omScheduleLinesQuantity: '数量',
  s4omScheduleLinesEntryInfo: '{{ quantity }}商品の発送／集荷日は{{ date }}です。',
  cartSubjectToPriceChange: '注意：新規に構成された機器の価格は標準小売価格です。お客様への販売価格は、正式な受注確認書でご案内致します。',
};

export const cartItems = {
  id: '商品番号',
  description: '商品説明',
  item: '商品名',
  itemPrice: '価格',
  quantity: '数量',
  equipmentNumber: '設備番号',
  quantityTitle: '数量は、カート内にあるこのアイテムの合計数を表します。',
  total: '合計',
  cartTotal: 'カート合計 ({{count}} アイテム)',
  cartTotal_plural: 'カート合計 ({{count}}アイテム)',
  actions: 'アクション',
  salesUnit: '単位',
  reference: '参照',
  referencePlaceHolder: '参照',
  comment: 'コメント',
  commentPlaceHolder: 'コメント',
  additionalContactEmailWarning: '有効なEメールアドレスを入力してください。(無効なメールアドレスは保存されません)',
  requestedDeliveryDate: '発送・集荷希望日',
  requestedDeliveryDateTitle: '発送・集荷希望日',
  substitutionProductNotFound: 'この商品は代替商品に置き換えられていますが、現在、代替商品をご用意する事が出来ません。',
};

export const orderCost = {
  orderSummary: 'ご注文内容',
  subtotal: '小計：',
  shipping: '送料：',
  estimatedShipping: '発送予定：',
  discount: 'ディスカウント：',
  salesTax: '消費税：',
  grossTax: '金額合計には消費税が含まれておりません。',
  grossIncludeTax: '合計金額には消費税が含まれております。',
  total: '合計：',
  toBeDetermined: '未定',
  requestedDeliveryDate: '発送・集荷希望日*',
  taxStatement: '※表示価格は全てお客様個別の税抜販売価格です。販売価格はご注文時に再計算されますので、最終的な税込販売価格はご注文確認メールにてご案内致します。',
  taxStatement2: 'アクセサリー類やスペアパーツのご注文時、税抜ご注文金額が最低注文金額に達しない場合、追加料金が発生する場合がございます。',
};

export const saveForLaterItems = {
  itemTotal: '後で保存 ({{count}} アイテム)',
  itemTotal_plural: '後で保存 ({{count}}アイテム)',
  cartTitle: 'カート',
  saveForLater: '後で保存',
  moveToCart: 'カートに移動',
  stock: '在庫',
  forceInStock: '在庫あり',
};

  export const cart = {
    cartDetails,
    cartItems,
    orderCost,
    saveForLaterItems
  }