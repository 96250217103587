export const exportEntries = {
  exportEntries: {
    exportToCsv: "Exportera till CSV",
    exportMessage: "CSV-filen laddas ner automatiskt till din enhet, om den inte gör det, kontrollera dina webbläsarinställningar",
    columnNames: {
      code: "Kod",
      quantity: "Kvantitet",
      name: "Namn på artikel",
      price: "Namn Pris"
    },
    exportProductToCsv: "Exportera kundvagn till CSV"
  }
}

export const importEntries = {
  importEntries: {
    importProducts: "Importera kundvagn"
  }
}

export const importEntriesDialog = {
  importEntriesDialog: {
    importProducts: "Importera produkter genom att importera en .CSV- eller en .XLSX-fil",
    importProductsSubtitle: "Importera produkter genom att importera en .CSV- eller en .XLSX-fil",
    importProductsNewSavedCartSubtitle: "Den importerade filen ska innehålla en lista över produkter med kolumnerna Kvantitet och Artikelnummer åtskilda med semikolon.",
    importProductFileDetails: "Välj fil",
    selectFile: "Namn på sparad kundvagn",
    savedCartName: "Beskrivning av sparad kundvagn",
    savedCartDescription: "valfritt",
    charactersLeft: "tecken kvar: {[count}}",
    cancel: "Avbryt",
    upload: "Ladda upp",
    close: "Stäng",
    summary: {
      info: "Stäng inte eller uppdatera inte detta fönster medan produkter importeras.",
      loadedToCart: "Produkter har laddats till kundvagn {{ kundvagnsnamn }}",
      loaded: "Produkter har laddats",
      loading: "Produkter håller på att bearbetas... ({[count }}/{{ total }})",
      successes: "'{{ successesCount }} av {{ total }} produkter har importerats framgångsrikt.",
      warning: "{{ count }} produkt importerades inte helt.",
      warning_other: "{{ count }} produkter har inte importerats helt och hållet.",
      error: "{{ count }} produkter har inte importerats.",
      error_other: "{{ count }} produkter har inte importerats.",
      messages: {
        unknownIdentifier: "Produkt SKU '{{ productCode}}' finns inte.",
        lowStock: "Kvantiteten för {{ productName }}: {{ quantity }} har minskats till {{ quantityAdded }}.",
        noStock: "{{ productName }} är för närvarande slut i lager.",
        unknownError: "Oigenkänt problem med '{{ productCode }}'.",
        limitExceeded: "Kan inte lägga till '{{ productCode }}'. Gränsen har överskridits."
      },
      show: "Visa",
      hide: "Dölj"
    }
  }
}
