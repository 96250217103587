import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalWishListComponent } from './rational-wish-list/rational-wish-list.component';
import { RationalWishListItemComponent } from './rational-wish-list-item/rational-wish-list-item.component';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { AtMessageModule, IconModule, ItemCounterModule, PageComponentModule, StarRatingModule } from '@spartacus/storefront';
import { RationalAddToWishListComponent } from './rational-add-to-wish-list/rational-add-to-wish-list.component';
import { RationalMediaModule } from '../cmscomponents/media/rational-media.module';
import { ProductDescriptionModule } from '../product/product-description/product-description.module';


@NgModule({
  imports: [
    CommonModule,
    AtMessageModule,
    I18nModule,
    ItemCounterModule,
    PageComponentModule,
    RouterModule,
    StarRatingModule,
    UrlModule,
    IconModule,
    RationalMediaModule,
    ProductDescriptionModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        AddToWishListComponent: {
          component: RationalAddToWishListComponent,
        },
        WishListComponent: {
          component: RationalWishListComponent,
        },
        WishListItemComponent: {
          component: RationalWishListItemComponent,
        }
      },
    }),
  ],
  declarations: [
    RationalWishListComponent,
    RationalWishListItemComponent,
    RationalAddToWishListComponent
  ],
  exports: [
    RationalWishListComponent,
    RationalWishListItemComponent,
    RationalAddToWishListComponent
  ]
})
export class RationalWishListModule { }
