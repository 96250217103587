import { Component, ViewEncapsulation } from '@angular/core';
import { OrderDetailTotalsComponent } from '@spartacus/order/components';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'cx-order-summary',
  templateUrl: './rational-order-detail-totals.component.html',
  styleUrl: './rational-order-detail-totals.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RationalOrderDetailTotalsComponent extends OrderDetailTotalsComponent {
  isOrderLoading$: Observable<boolean> = typeof this.orderDetailsService.isOrderDetailsLoading === 'function' ? this.orderDetailsService.isOrderDetailsLoading() : of(false);
}
