<div class="rational-order-summary">
  <div class="requested-delivery-date-heading">
    {{ 'orderCost.requestedDeliveryDate' | cxTranslate }}
  </div>
  <form class="row row-cols-sm-auto">
    <div class="col-12 delivery-date-picker-input">
      <div class="input-group row">
        <input
          class="delivery-date-picker"
          name="dp"
          [(ngModel)]="model"
          ngbDatepicker
          #d="ngbDatepicker"
          [markDisabled]="isWeekend"
          (dateSelect)="onDateSelect($event)"
          [minDate]="minDate"
          (keydown)="disableTyping($event)"
          navigation="arrows"
        />
        
        <button class="btn btn-outline-secondary calendar-btn" (click)="d.toggle()" type="button"><i class="fa-solid fa-calendar-days"></i></button>
      </div>
    </div>
  </form>

  <div class="cx-summary-heading">
      {{ 'orderCost.orderSummary' | cxTranslate }}
    </div>
    
  <div class="cx-summary-partials" *ngIf="cart">
  
    <div class="cx-summary-row cx-summary-total">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.total' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.totalPriceWithTax?.formattedValue }}
      </div>
    </div>

    <ng-template #cartWithoutNet>
      <div class="cx-summary-row">
        {{
          cart.totalPriceWithTax?.value !== cart.totalPrice?.value
            ? ('orderCost.grossTax' | cxTranslate)
            : ('orderCost.grossIncludeTax' | cxTranslate)
        }}
        {{ cart.totalTax?.formattedValue }}.
      </div>
    </ng-template>
  </div>
</div>