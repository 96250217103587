export const productDetails = {
  id: 'Numero articolo',
  quantity: 'Quantità',
  productDetails: 'Dettagli del prodotto',
  specification: 'Caratteristiche tecniche',
  reviews: 'Recensioni',
  shipping: 'Spedizione',
  share: 'Condividere',
  showReviews: 'Mostrare le recensioni',
  noReviews: '',
  productPrice: 'Prezzo del prodotto',
  priceLoading:'Il prezzo del prodotto è calcolato',
  productRelations :{
    productRelationsHeader: 'Accessori compatibili',
  }
}

export const productList = {
  filterBy: {
    label: 'Filtrare per',
    action: 'Filtrare per',
  },
  appliedFilter: 'Filtro applicato:',
  showLess: 'Mostrare meno...',
  showMore: 'Mostrare di più...',
  sortBy: 'Ordinare per',
  backToTopBtn: 'TORNARE SOPRA',
  showMoreBtn: 'MOSTRARE DI PIÙ',
  productSearchPagination: 'Ricerca prodotti ',
}

export const productFacetNavigation = {
  filterBy: {
    label: 'Filtrare per',
    action: 'Filtrare per',
  },
  appliedFilter: 'Filtro applicato:',
  showLess: 'Mostrare meno...',
  showMore: 'Mostrare di più...',
  sortBy: 'Ordinare per',
}

export const productSummary = {
  id: 'Numero di articolo',
  showReviews: 'Mostrare le recensioni',
  share: 'Condividere',
  newItemPrice: 'Prezzo del nuovo articolo',
  discount: 'I prezzi visualizzati includono lo sconto individuale pari a {{discount}}.',
  productSubstitutionAndProductExists: 'Il prodotto {{product1}} è stato sostituito con il prodotto {{product2}},pertanto verrete reindirizzati al prodotto {{product2}}.',
  productSubstitutionAndProductDoesntExist: 'Il prodotto {{product1}} è stato sostituito dal prodotto {{product2}},ma il prodotto {{product2}} non è attualmente disponibile.',
}

export const productReview = {
  overallRating: 'Valutazione complessiva',
  reviewTitle: 'Titolo della recensione',
  writeYourComments: 'Scrivi i tuoi commenti',
  rating: 'Valutazione',
  reviewerName: 'Il vostro nome (facoltativo)',
  writeReview: 'Scrivere una recensione',
  more: 'Mostrare più recensioni',
  less: 'Mostrare meno recensioni',
  thankYouForReview: 'Grazie per la recensione! Si prega di notare che le recensioni potrebbero essere verificate prima della pubblicazione.',
}

export const addToCart = {
  itemsAddedToYourCart: 'Articolo/i aggiunto/i al carrello',
  itemsIncrementedInYourCart: 'Questo articolo era già presente nel carrello. La quantità è stata aggiornata.',
  items: 'articoli',
  updatingCart: 'Aggiornamento del carrello...',
  addToCart: 'Aggiungere al carrello',
  viewCart: 'Visualizzare il carrello',
  proceedToCheckout: 'Procedere all\'acquisto',
  quantity: 'Quantità',
  outOfStock: 'Esaurito',
  inStock: 'Disponibile',
  selectStyleAndSize: 'Seleziona la tipologia e la misura per controllare la disponibilità',
  removeFromCart: 'Rimuovere il prodotto dal carrello',
  closeModal: 'Chiudere Modal',
}

export const TabPanelContainer = {
  tabs: {
    ProductDetailsTabComponent: 'Dettagli del prodotto',
    ProductSpecsTabComponent: 'Caratteristiche tecniche',
    ProductReviewsTabComponent: 'Recensioni',
    deliveryTab: 'Spedizione',
    ProductDownloadsTabComponent: 'Download',
  },
}

export const addToWishList = {
  add: 'Aggiungere alla lista dei preferiti',
  remove: 'Rimuovere dalla lista dei preferiti',
  anonymous: 'Accedere per aggiungere alla lista dei preferiti',
}

export const itemCounter = {
  removeOne: 'Rimuoverne uno',
  addOneMore: 'Aggiungerne uno',
  quantity: 'Quantità',
}

export const productView = {
  gridView: 'Selezionare per passare alla visualizzazione a griglia',
  listView: 'Selezionare per passare alla visualizzazione a elenco',
}

export const standardDelivery = {
  standardDelivery: {
    standardDeliveryRange: 'Pronto per la spedizione in {{from}} a {{to}} giorni',
  },
}

export const downloads = {
  downloads: {
    noDownloads: 'Nessun download per questo prodotto',
    documentTitle: 'Titolo del documento',
    originalFileName: 'Nome del file',
    languages: 'Lingue',
    readableTypeMime: 'Tipo di Mime',
    fileSize: 'Dimensione del file',
    download: 'Download'
  },
}

export const productReferences = {
  productReferences: {
    installationvariants: 'Varianti di installazione',
    feedingsystems: 'Alimentazione',
    groomingaccessoires: 'Prodotti per la cura',
    cookaccessoire: 'Accessori per la cottura',
    cookaccessoirespecial: 'Accessori speciali',
    extractorhood: 'Cappe',
    deviceinstallation: 'Installazione dell\'unità',
  },
}

export const product = {
  productDetails,
  productList,
  productFacetNavigation,
  productSummary,
  productReview,
  addToCart,
  TabPanelContainer,
  addToWishList,
  itemCounter,
  productView,
}
