import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalScheduleLinesComponent } from './rational-schedule-lines.component';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [RationalScheduleLinesComponent],
  exports: [RationalScheduleLinesComponent],
  imports: [CommonModule, I18nModule],
})
export class RationalScheduleLinesModule {}
