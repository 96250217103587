import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CmsConfig, I18nModule, UrlModule, provideConfig } from "@spartacus/core";
import { PageSlotModule } from "@spartacus/storefront";
import { RationalLoginComponent } from "./rational-login/rational-login.component";
import { ContextSwitcherModule } from "../context-switcher/context-switcher.module";

@NgModule({
    declarations: [RationalLoginComponent],
    imports: [CommonModule, RouterModule, UrlModule, PageSlotModule, I18nModule, ContextSwitcherModule],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                LoginComponent: {
                    component: RationalLoginComponent,
                },
            },
        }),
    ]
})
export class RationalLoginModule {

}