export const checkoutAddress = {
  shippingAddress: 'Shipping Address',
  selectYourShippingAddress: 'Select your Shipping Address:',
  selectYourDeliveryAddress: 'Select your Delivery Address:',
  defaultShippingAddress: 'Default Shipping Address',
  addNewAddress: 'Add New Address',
  shipToThisAddress: 'Ship to this address',
  addressSearch: 'Address search',
  addressSearchMinLength: 'Search text should be at least {{length}} characters',
  submitAddressSearch: 'Enter',
  errorSearchingSalesforce: 'Can not complete search at the moment, please try again later',
  deliveryAddressSelected: 'Delivery address selected'
}

export const checkoutOrderConfirmation = {
  confirmationOfOrder: 'Confirmation of Order:',
  confirmationOfQuote: 'Confirmation of Quote Receipt:',
  thankYou: 'Thank you for your order!',
  thankYouQuote: 'Thank you for your quote request!',
  invoiceHasBeenSentByEmailLine1: 'Please note that it may take up to one business day for this order to appear in your order history. Once there, it will include the formal order confirmation alongside the dispatch/pickup date confirmed by RATIONAL and',
  invoiceHasBeenSentByEmailLine2: 'the complete pricing information including applicable taxes. If you have any questions about this order meanwhile, please contact your RATIONAL customer service and reference the order ID shown above. ',
  quoteHasBeenSentByEmailLine1:'Please note that your quote requests will not be transferred to your order history in the webshop. If you have any questions about this quote request, please contact your RATIONAL customer service',
  quoteHasBeenSentByEmailLine2:'and reference the webshop quote number shown above or your P.O. number.',
  orderItems: 'Order Items',
  orderPlacedSuccessfully: 'Order placed successfully',
  createAccount: 'Create an account?',
  createAccountForNext: 'Create an account for <{{email}}> for a faster checkout on your next visit.'
}

export const checkoutReview = {
  review: 'Order Summary',
  orderItems: 'Order Items',
  autoReplenishOrder: 'Auto Replenish Order',
  confirmThatRead: 'I am confirming that I have read and agreed with the',
  placeOrder: 'Place Order',
  placeQuoteOrder: 'Request Quote',
  scheduleReplenishmentOrder: 'Schedule Replenishment Order',
  orderType_PLACE_ORDER: 'None (one time order only)',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Replenish Order',
  termsAndConditions: 'Terms & Conditions',
  editShippingAddress: 'Edit shipping address',
  poNumber: 'Purchase Order Number',
  every: 'Every',
  startOn: 'Start on',
  dayOfMonth: 'On day',
  repeatOnDays: 'Repeat on the following days',
  recurrencePeriodType_DAILY: 'Day(s)',
  recurrencePeriodType_WEEKLY: 'Week(s)',
  recurrencePeriodType_MONTHLY: 'Month'
}

export const checkoutShipping = {
  shippingMethod: 'Shipping Method',
  standardDelivery: 'Standard Delivery',
  premiumDelivery: 'Premium Delivery'
}

export const checkoutPO = {
  noPoNumber: 'None',
  poNumber: 'Your Purchase Order Number',
  costCenter: 'Cost Center',
  placeholder: 'Enter your own P.O. number...',
  availableLabel: 'Shipping addresses available to you depend on the cost center\'s unit'
}

export const checkoutProgress = {
  methodOfPayment: 'Method of Payment',
  deliveryAddress: 'Shipping Address',
  deliveryMode: 'Delivery Method',
  paymentDetails: 'Payment Details',
  reviewOrder: 'Order Summary'
}

export const checkoutB2B = {
  checkoutB2B: {
    poNumber: 'Your Purchase Order Number*',
    placeholder: 'Enter your own P.O. number...',
    costCenter: 'Cost Center',
    availableLabel: 'Shipping addresses available to you depend on the cost center\'s unit',
    methodOfPayment: {
      paymentType: 'Method of Payment'
    },
    progress: {
      methodOfPayment: 'Method of Payment'
    },
    review: {
      poNumber: 'Your Purchase Order Number'
    }
  }
}

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: 'Incoterms',
    paymentConditions: 'Payment Conditions'
  }
}

export const checkoutMode = {
  deliveryMethod: 'Delivery Method',
  deliveryEntries: 'Items to be Shipped',
  specialShippingInstructions: 'Special Shipping Instructions',
  specialShippingInstructionsPlaceholder: 'Special Shipping Instructions',
}

export const checkout = {
  checkoutAddress,
  checkoutOrderConfirmation,
  checkoutReview,
  checkoutShipping,
  checkoutPO,
  checkoutProgress,
  checkout: {
    backToCart: 'Back to cart',
    invalid: {
      accountType: 'Your account does not allow you to checkout here.'
    }
  },
  checkoutMode
}
