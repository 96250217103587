<div
  *ngIf="flyout && node?.children && (node?.children)!.length > 1"
  class="back is-open"
  (click)="back()"
>
  <span>
    <cx-icon [type]="iconType.CARET_LEFT"></cx-icon>
    {{ "common.back" | cxTranslate }}
  </span>
</div>

<ng-container *ngFor="let child of node?.children">
  <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }">
  </ng-container>
</ng-container>

<!-- we generate links in a recursive manner -->
<ng-template #nav let-node="node" let-depth="depth">
  <nav
    (click)="toggleOpen($event)"
    (mouseenter)="onMouseEnter($event)"
    (keydown.esc)="back()"
  >
    <cx-generic-link
      *ngIf="
        node.url && (!node.children || node.children?.length === 0);
        else heading
      "
      [url]="node.url"
      [target]="node.target"
      [style]="node.styleAttributes"
      [class]="node.styleClasses"
    >
      {{ node.title }}
      <cx-icon
        *ngIf="flyout && node.children?.length > 0"
        [type]="iconType.CARET_DOWN"
      ></cx-icon>
    </cx-generic-link>

    <ng-template #heading>
      <span
        [attr.aria-label]="node.title"
        [attr.tabindex]="flyout && (depth === 0 || node.url) ? 0 : -1"
      >
        <cx-icon
          *ngIf="flyout && node.children?.length > 0"
          class="icon-raticons icon-Icons_Profil"
        ></cx-icon>
      </span>
    </ng-template>

    <!-- we add a wrapper to allow for better layout handling in CSS -->
    <div class="wrapper" *ngIf="node.children?.length > 0">
      <cx-generic-link
        *ngIf="node.url"
        [url]="node.url"
        [target]="node.target"
        class="all"
      >
        {{ "navigation.shopAll" | cxTranslate: { navNode: node.title } }}
      </cx-generic-link>
      <div
        class="childs"
        [attr.depth]="getTotalDepth(node)"
        [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
        [attr.columns]="getColumnCount(node.children?.length)"
      >
        <ng-container *ngFor="let child of node.children">
          <ng-container
            *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1 }"
          >
          </ng-container>
        </ng-container>

        <!-- List price toggle button -->
        <nav class="enableListPriceLabel">
          <ng-container *ngIf="!showListPriceOnly()">
            {{ "navigation.listPrice.enableListPrice" | cxTranslate }}
            <ng-toggle
              [(ngModel)]="listPriceOptionIsActivated"
              (input)="toggleShowListPrice()"
            ></ng-toggle>
          </ng-container>
        </nav>

      </div>
    </div>
  </nav>
</ng-template>
