import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CartItemListComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade, MultiCartFacade, OrderEntry, SelectiveCartFacade } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { RationalSavedCartService } from '../../rational-saved-cart.service';
import { UserIdService } from '@spartacus/core';

@Component({
  selector: 'rational-saved-cart-detials-item-list',
  templateUrl: './rational-saved-cart-detials-item-list.component.html',
  styleUrls: ['./rational-saved-cart-detials-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RationalSavedCartDetialsItemListComponent
  extends CartItemListComponent
  implements OnInit
{
  constructor(
    private savedCartService: RationalSavedCartService,
    protected override activeCartService: ActiveCartFacade,
    protected override selectiveCartService: SelectiveCartFacade,
    protected override userIdService: UserIdService,
    protected override multiCartService: MultiCartFacade,
    protected override cd: ChangeDetectorRef
  ) {
    super(
      activeCartService,
      selectiveCartService,
      userIdService,
      multiCartService,
      cd
    );
  }

  isAllEntriesSelected: boolean = false;

  getQuantity(item: OrderEntry): UntypedFormControl {
    // @ts-ignore
    return this.form.get(this.getControlName(item))?.get('quantity');
  }

  override ngOnInit(): void {
    this.savedCartService.selectedEntries.subscribe((entries) => {
      if (entries.length !== this.items.length) {
        this.isAllEntriesSelected = false;
      } else {
        this.isAllEntriesSelected = true;
      }
    });
    super.ngOnInit();
  }

  toggleSelectAllEntries() {
    const selectedItems: OrderEntry[] = [];
    this.isAllEntriesSelected = !this.isAllEntriesSelected;
    if (this.isAllEntriesSelected) {
      selectedItems.push(...this.items);
    }
    this.savedCartService.selectedEntries.next(selectedItems);
  }

  override getControl(
    item: OrderEntry
  ): Observable<UntypedFormGroup> | undefined {
    return this.form.get(this.getControlName(item))?.valueChanges.pipe(
      // eslint-disable-next-line import/no-deprecated
      startWith(null),
      tap((value) => {
        if (item.updateable && value && !this.readonly) {
          if (this.options.isSaveForLater) {
            this.selectiveCartService.updateEntry(
              value.entryNumber,
              value.quantity
            );
          } else if (this.cartId && this.userId) {
            this.multiCartService.updateEntry(
              this.userId,
              this.cartId,
              value.entryNumber,
              value.quantity
            );
          } else {
            this.activeCartService.updateEntry(
              value.entryNumber,
              value.quantity
            );
          }
        }
      }),
      map(() => <UntypedFormGroup>this.form.get(this.getControlName(item)))
    );
  }
}
