import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrderHistoryComponent } from '@spartacus/order/components';
import '../../model/rational.order.model';

@Component({
  selector: 'cx-order-history',
  templateUrl: './rational-order-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RationalOrderHistoryComponent extends OrderHistoryComponent {
    
}
