export const configurator = {
  configurator: {
    header: {
      consistent: 'Spójny',
      complete: 'Kompletny',
      configId: 'Identyfikator konfiguracji',
      toconfig: 'Konfiguruj',
      toConfigReadOnly: 'Pokaż szczegóły',
      editConfiguration: 'Edytuj konfigurację,',
      displayConfiguration: 'Wyświetlanie konfiguracji',
      resolveIssues: 'Rozwiązywanie problemów',
      resolveIssue: 'Rozwiązywanie problemu',
      resolveConflicts: 'Rozwiązywanie konfliktów',
      conflictWarning: 'Konflikt musi zostać rozwiązany, aby kontynuować',
      updateMessage: 'Konfiguracja jest aktualizowana w tle',
      conflictsResolved: 'Konflikty zostały rozwiązane',
      showMore: 'pokaż więcej',
      showLess: 'pokaż mniej',
      items: '{{count}} element',
      items_other: '{{count}} elementów',
      show: 'pokaż',
      hide: 'ukryj',
      multipleWarnings: 'Istnieje wiele ostrzeżeń.',
      reviewWarnings: 'Przejrzyj te ostrzeżenia',
      multipleErrors: 'Występuje wiele błędów.',
      reviewErrors: 'Przejrzyj te błędy',
      kbKeyName: 'Nazwa Kb',
      kbKeyLogsys: 'System logiczny Kb',
      kbKeyVersion: 'Wersja Kb',
      kbKeyBuildNr: 'Numer kompilacji Kb',
    },
    tabBar: {
      configuration: 'Konfiguracja',
      overview: 'Przegląd',
    },
    notificationBanner: {
      numberOfIssues: '{{count}} problem musi zostać rozwiązany przed pobraniem.',
      numberOfIssues_other: '{{count}} problemy muszą zostać rozwiązane przed realizacją transakcji.',
      numberOfConflicts: '{{count}} konflikt musi zostać rozwiązany przed pobraniem.',
      numberOfConflicts_other: '{{count}} konflikty muszą zostać rozwiązane przed realizacją transakcji.',
    },
    attribute: {
      id: 'Numer artykułu',
      quantity: 'Ilość',
      caption: 'Atrybuty',
      notSupported: 'Typ atrybutu nie jest obsługiwany.',
      requiredAttribute: '{{param}} wymagane',
      defaultRequiredMessage: 'Wprowadź wartość dla wymaganego pola',
      singleSelectRequiredMessage: 'Wybierz wartość',
      singleSelectAdditionalRequiredMessage: 'Wybierz lub wprowadź wartość',
      multiSelectRequiredMessage: 'Wybierz jedną lub więcej wartości',
      wrongNumericFormat: 'Nieprawidłowy format, ten atrybut liczbowy powinien być wprowadzony zgodnie ze wzorcem {{wzorzec}}.',
      wrongNumericFormatMessage: 'Wprowadź liczbę w następującym formacie',
      wrongIntervalFormat: 'Wprowadź wartość we wskazanych granicach',
      deselectionNotPossible: 'Dodaj inny produkt przed usunięciem tego',
      dropDownSelectMsg: 'Dokonaj wyboru',
      noOptionSelectedMsg: 'Nie wybrano żadnej opcji',
      notVisibleAttributeMsg: 'Ten atrybut nie jest widoczny dla użytkownika',
    },
    button: {
      previous: 'Poprzedni',
      next: 'Następny',
      back: 'Wstecz',
      more: 'więcej',
      less: 'mniej',
      deselect: 'Odznacz',
      select: 'Wybierz',
      add: 'Dodaj',
      remove: 'Usuń',
      exit: 'Wyjście z konfiguracji',
      exitMobile: 'Wyjście',
      cancelConfiguration: 'Anulowanie konfiguracji',
      cancelConfigurationMobile: 'Anuluj',
      filterOverview: 'Filtr',
      filterOverviewWithCount: 'Filtr ({{numAppliedFilters}})',
    },
    icon: {
      groupComplete: 'Kompletny',
      groupIncomplete: 'Niekompletny',
      groupConflict: 'Konflikt',
      subgroup: 'ma podgrupy',
    },
    priceSummary: {
      basePrice: 'Cena podstawowa',
      selectedOptions: 'Wybrane opcje',
      totalPrice: 'Łącznie',
      totalPricePerItem: 'Łącznie za przedmiot',
    },
    addToCart: {
      button: 'Dodaj do koszyka',
      buttonAfterAddToCart: 'Przejdź do koszyka',
      buttonForQuote: 'Przejdź do wyceny',
      buttonUpdateCart: 'Gotowe',
      buttonDisplayOnly: 'Gotowe',
      buttonClose: 'Zamknij',
      confirmation: 'Konfiguracja została dodana do koszyka',
      confirmationUpdate: 'Koszyk został zaktualizowany o konfigurację',
      confirmationQuoteUpdate: 'Wycena została zaktualizowana o konfigurację',
      quantity: 'Ilość',
      selectVariant: 'Dla danej konfiguracji znaleziono więcej niż jeden istniejący wariant. W takim przypadku prosimy o kontakt z działem obsługi klienta w celu wybrania właściwego wariantu.',
      addMatchingVariant: 'Istniejący wariant dla danej konfiguracji został dodany do koszyka.'
    },
    overviewForm: {
      noAttributeHeader: 'Brak wyników,',
      noAttributeText: 'Usuń filtry, aby zobaczyć zawartość przeglądu,',
      itemPrice: 'Cena pozycji',
    },
    overviewSidebar: {
      menu: 'Menu',
      filter: 'Filtr',
    },
    overviewFilter: {
      title: 'Wybierz filtry',
      byOption: 'Filtruj według opcji',
      byGroup: 'Filtruj według grupy',
      byPrice: 'Opcje związane z ceną',
      mySelections: 'Moje wybory',
      removeAll: 'Usuń wszystko',
      removeAllFilters: 'Usuń wszystkie filtry',
      removeByPrice: 'Usuń filtr powiązany z ceną',
      removeMySelections: 'Usuń filtr Moje wybory',
      removeByGroup: 'Usuń filtr grupy dla grupy {{group}}',
      noFiltersAvailable: 'Brak dostępnych filtrów'
    },
    group: {
      general: 'Ogólne',
      conflictHeader: 'Rozwiązywanie konfliktów',
      conflictGroup: 'Konflikt dla {{attribute}}'
    },
    conflict: {
      suggestionTitle: 'Sugestia {{number}}',
      suggestionText: 'Zmień wartość dla \{{attribute}}.',
      viewConflictDetails: 'Wykryto konflikt - wyświetl szczegóły',
      conflictDetected: 'Wykryto konflikt',
      viewConfigurationDetails: 'Widok w konfiguracji'
    },
    restartDialog: {
      title: 'Niedokończona konfiguracja',
      description: 'Istnieje niedokończona konfiguracja z poprzedniej sesji. Czy chcesz wznowić lub rozpocząć nową konfigurację?',
      restartButton: 'Rozpocznij nową',
      resumeButton: 'Wznów',
    },
    httpHandlers: {
      makeToStockBaseProductIssue: 'Ten produkt jest produktem podstawowym i nie można go dodać do koszyka; zamiast tego wybierz wariant produktu.',
    },
    a11y: {
      configureProduct: 'Skonfiguruj produkt',
      showDetailsProduct: 'Pokaż szczegóły produktu',
      cartEntryBundleInfo: 'Istnieje produkt ',
      cartEntryBundleInfo_other: 'Istnieją {{items}} przedmioty ',
      cartEntryBundleName: 'Pozycja {{ name }}',
      cartEntryBundleNameWithQuantity: 'Pozycja {{ name }} ilość pozycji {{price}}',
      cartEntryBundleNameWithPrice: 'Pozycja {{ name }} cena pozycji {{ price}}',
      cartEntryBundle: 'Pozycja {{ name }} cena pozycji {{ price}} ilość pozycji {{quantity}}',
      cartEntryInfoIntro: 'Produkt ma następujące atrybuty i wybrane wartości',
      cartEntryInfo: 'Atrybut {{ attribute }} ma wybraną wartość {{value}}.',
      nameOfAttribute: 'Nazwa atrybutu',
      valueOfAttribute: 'Wartość atrybutu {{ attribute}} jest pusta',
      forAttribute: '{{ value }} dla atrybutu {{ attribute }}',
      valueOfAttributeFull: 'Wartość {{ value }} atrybutu {{ attribute }}',
      valueOfAttributeFullWithPrice: 'Wartość {{ value }} atrybutu {{attribute }}, Dopłata {{ price }}',
      selectedValueOfAttributeFull: 'Wybrana wartość {{ value }} atrybutu {{ attribute }}',
      selectedValueOfAttributeFullWithPrice: 'Wybrana wartość {{value }} atrybutu {{ attribute }}, Dopłata {{ price }},',
      readOnlyValueOfAttributeFullWithPrice: 'Wartość tylko do odczytu {{ value }} atrybutu {{ attribute }}, Dopłata {{ price }},',
      readOnlyValueOfAttributeFull: 'Wartość tylko do odczytu {{ value }} atrybutu {{ attribute }}',
      valueOfAttributeBlank: 'Wartość atrybutu {{ attribute}} jest pusta',
      value: 'Wartość {{value }}',
      attribute: 'Atrybut {{ attribute}}',
      requiredAttribute: 'Atrybut {{param}} jest wymagany',
      listOfAttributesAndValues: 'Lista atrybutów i ich wartości',
      editAttributesAndValues: 'Edycja wartości atrybutów',
      group: 'Grupa atrybutów {{ group }}',
      itemOfAttributeSelected: 'Wybrano element {{ item }} atrybutu {{ attribute }}. {{ itemIndex }} z {{ itemCount }}',
      itemOfAttributeSelectedWithPrice: 'Pozycja {{ item }} atrybutu {{ attribute }} jest zaznaczona, Dopłata {{ price }}. {{ itemIndex }} z {{ itemCount }}.',
      itemOfAttributeSelectedPressToUnselect: 'Pozycja {{ item }} atrybutu {{ attribute }} jest zaznaczona. Naciśnij przycisk, aby usunąć zaznaczenie. {{ itemIndex }} z {{ itemCount }}',
      itemOfAttributeSelectedPressToUnselectWithPrice: 'Pozycja {{ item }} atrybutu {{ attribute }} jest zaznaczona, Dopłata {{ price }}. Naciśnij przycisk, aby usunąć zaznaczenie. {{ itemIndex }} z {{ itemCount }}',
      itemOfAttributeUnselected: 'Pozycja {{ item }} atrybutu {{ attribute }} jest odznaczona. Naciśnij przycisk, aby wybrać. {{ itemIndex }} z {{ itemCount }}.',
      itemOfAttributeUnselectedWithPrice: 'Pozycja {{ item }} atrybutu {{ attribute }} jest niezaznaczona, Dopłata {{ price }}. Naciśnij przycisk, aby wybrać. {{ itemIndex }} z {{ itemCount }}.',
      selectNoItemOfAttribute: 'Aby nie wybrać żadnego elementu dla atrybutu {{ attribute }}, naciśnij przycisk. {{ itemIndex }} z {{ itemCount }}',
      itemOfAttribute: 'Pozycja atrybutu {{ attribute }}',
      itemOfAttributeFull: 'Pozycja {{ item }} atrybutu {{ attribute }}',
      itemOfAttributeFullWithPrice: 'Pozycja {{ item }} atrybutu {{ attribute}}, Dopłata {{ price }}',
      itemOfAttributeFullWithQuantity: 'Pozycja {{ item }} atrybutu {{ attribute }}, Ilość {{ quantity }}',
      itemOfAttributeFullWithPriceAndQuantity: 'Pozycja {{ item }} atrybutu {{ attribute }}, Ilość {{ quantuty }}, Dopłata {{ price }}',
      itemDescription: 'Opis dla elementu {{ item }}',
      listbox: 'Pole listy z wartościami {{ count }}.',
      valueSurcharge: 'Dopłata za wartość',
      conflictDetected: 'Wykryto konflikt! Sprawdź konflikty na górze listy grup.',
      conflictsInConfiguration: 'W konfiguracji występują konflikty. Liczba konfliktów',
      listOfGroups: 'Lista grup',
      inListOfGroups: 'Jesteś na liście grup',
      groupName: 'Grupa {{ group }}',
      groupBack: 'Jesteś w podgrupie. Wybierz, aby wrócić.',
      conflictBack: 'Znajdujesz się w narzędziu do rozwiązywania konfliktów. Wybierz, aby wrócić lub wybierz kolejne karty, aby rozwiązać konflikty.',
      iconConflict: 'W grupie występują konflikty.',
      iconIncomplete: 'Grupa ma wymagane atrybuty bez wybranych wartości.',
      iconComplete: 'Grupa jest kompletna.',
      iconSubGroup: 'Grupa ma podgrupę.',
      next: 'Przejdź do następnej grupy.',
      previous: 'Przejdź do poprzedniej grupy.',
      showMoreProductInfo: 'Pokaż więcej informacji o produkcie {{ product }} lub przejdź do konfiguracji.',
      showLessProductInfo: 'Pokaż mniej informacji o produkcie {{ product }} lub przejdź do konfiguracji.',
      productName: 'Nazwa produktu',
      productCode: 'Kod produktu',
      productDescription: 'Opis produktu',
      kbKeyName: 'Nazwa Kb {{name}}',
      kbKeyLogsys: 'System logiczny Kb {{logsys}}',
      kbKeyVersion: 'Kb wersja {{version}}',
      kbKeyBuildNr: 'Numer kompilacji Kb {{number}}',
      configurationPage: 'Jesteś na stronie konfiguracji.',
      configurationPageLink: 'Przejdź do strony konfiguracji.',
      overviewPage: 'Znajdujesz się na stronie przeglądu. Sprawdź atrybuty i ich wybrane wartości za pomocą trybu odczytu czytnika ekranu.',
      overviewPageLink: 'Przejdź do strony przeglądu konfiguracji.',
      navigateToConflict: 'Kliknij link, aby uzyskać więcej informacji na temat konfliktu dla atrybutu {{attribute }}.',
      numericIntervalStandard: 'Wprowadź wartość pomiędzy {{ minValue }} i {{ maxValue }}.',
      numericIntervalStandardOpen: '(Punkty końcowe nie są uwzględniane)',
      numericIntervalStandardLowerEndpointNotIncluded: '(Dolny punkt końcowy nie jest uwzględniony)',
      numericIntervalStandardUpperEndpointNotIncluded: '(Górny punkt końcowy nie jest uwzględniony)',
      numericInfiniteIntervalMinValueIncluded: 'Wprowadź wartość większą lub równą {{ value }}',
      numericInfiniteIntervalMinValue: 'Wprowadź wartość większą lub równą {{ value }}',
      numericInfiniteIntervalMaxValueIncluded: 'Wprowadź wartość mniejszą lub równą {{ value }}',
      numericInfiniteIntervalMaxValue: 'Wprowadź wartość mniejszą niż {{ value }}',
      numericIntervalSingleValue: 'Wprowadź {{ value }}',
      combinedIntervalsText: '{{ combinedInterval }} lub {{ newInterval }}.',
      additionalValue: 'Można również wprowadzić dodatkową wartość.',
      addToCartPrices: 'Cena bazowa {{basePrice}}, Wybrane opcje {{selectedOptions}}, Cena całkowita {{totalPrice}}.',
      filterOverview: 'Otwórz menu filtrów przeglądu konfiguracji',
      filterOverviewWithCount: 'Otwarcie menu filtrów przeglądu konfiguracji - {{numAppliedFilters}} aktywne filtry',
      closeFilterMenu: 'Zamknij menu filtrów przeglądu konfiguracji i zastosuj wybrane filtry',
      filterOverviewByPrice: 'Filtrowanie przeglądu konfiguracji według opcji związanych z ceną',
      filterOverviewByMySelections: 'Filtrowanie przeglądu konfiguracji według wybranych opcji',
      filterOverviewByGroup: 'Filtrowanie przeglądu konfiguracji według grupy {{groupName}}',
      closeConflictSolverModal: 'Zamknij modalne rozwiązanie konfliktu',
      closeRestartDialog: 'Zamknij okno dialogowe „Niedokończona konfiguracja” i wróć do strony szczegółów produktu.',
      description: 'Kliknij, aby zobaczyć opis tej wartości',
    },
    variantCarousel: {
      title: 'Wstępnie skonfigurowane urządzenia',
    },
  },
};
