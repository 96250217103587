export const pageMetaResolver = {
  category: {
    title: '{{query}} の検索結果 {{count}}',
    title_plural: '{{query}} の検索結果 {{count}}',
  },
  checkout: {
    title: 'チェックアウト',
  },
  search: {
    title: "'{{query}}' の検索結果 {{count}}",
    title_plural: "'{{query}}' の検索結果 {{count}}",
    findProductTitle: "クーポン '{{coupon}}'の検索結果 {{count}}",
    findProductTitle_plural: "クーポン '{{coupon}}'の検索結果 {{count}}",
    default_title: '全ての商品',
  },
  product: {
    description: '{{description}}',
    heading: '{{heading}}',
    title: '{{title}}',
  },
};

export const spinner = {
  loading: 'Loading...',
};

export const navigation = {
  shopAll: '全ての{{ navNode}} を見る >',
  listPrice: {
    enableListPrice: '標準小売価格を有効にする',
    listPriceIsEnabled: '標準小売価格が有効',
    listPriceIsDisabled: '標準小売価格が無効',
  },
};

export const searchBox = {
  placeholder: 'ここで検索...',
  ariaLabelInput: 'ここで検索...',
  ariaLabelSuggestions: '入力候補',
  ariaLabelProducts: '商品検索結果',
  initialDescription:
    'オートコンプリートの結果が利用可能な場合は、上下の矢印で確認し、Enterで選択します。タッチデバイスをお使いの方は、タッチまたはスワイプジェスチャーで検索してください。',
  suggestionsResult: '{{ count }} 提案と',
  suggestionsResult_plural: '{{ count }} 提案と',
  productsResult: '{{ count }}商品があります。',
  productsResult_plural: '{{ count }}商品があります。',
  resetLabel: '検索をリセット',
  help: {
    insufficientChars: 'もっと多くの文字を入力してください',
    noMatch: '検索結果は見つかりませんでした',
    exactMatch: '{{term}}',
    empty: '何でもご相談ください',
  },
};

export const sorting = {
  date: '日付',
  orderNumber: '注文番号',
  rma: '返品番号',
  replenishmentNumber: '補充番号',
  nextOrderDate: '次回注文日',
};

export const httpHandlers = {
  badGateway: 'サーバーエラーが発生しました。後ほどもう一度お試しください。',
  badRequestPleaseLoginAgain: '{{ errorMessage }}　再度ログインしてください。',
  badRequestOldPasswordIncorrect: '古いパスワードが正しくありません。',
  badRequestGuestDuplicateEmail:
    '{{ errorMessage }} このE-mailアドレスはすでに存在しています。ゲストアカウントを使用して登録するには、別のE-mailアドレスでチェックアウトしてください。',
  conflict: '既に存在しています。',
  forbidden:
    'この操作を実行する権限がありません。エラーと思われる場合は、カスタマーサービスにご連絡ください。',
  gatewayTimeout: 'サーバーが応答しませんでした。後ほどもう一度お試しください。',
  internalServerError:
    '内部サーバーエラーが発生しました。後ほどもう一度お試しください。',
  sessionExpired: 'セッションの有効期限が切れました。再度ログインしてください。',
  unknownError: '不明なエラーが発生しました',
  unauthorized: {
    common: '不明な認証エラーが発生しました',
    invalid_client: '不正なクライアント認証情報',
  },
  validationErrors: {
    missing: {
      card_cardType:
        '選択されたクレジットカードはサポートされていません。別のカードを選択してください。',
      card_accountNumber:
        '入力されたクレジットカード番号が無効です。',
      card_cvNumber: '入力されたセキュリティコードが無効です。',
      card_expirationMonth:
        '入力されたクレジットカードの有効期限が無効です。',
      card_expirationYear:
        '入力されたクレジットカードの有効期限が無効です。',
      billTo_firstName: '入力された名が無効です。',
      billTo_lastName: '入力された姓が無効です。',
      billTo_street1: '入力された住所が無効です。',
      billTo_street2: '入力された住所が無効です。',
      billTo_city: '入力された都市名はこのクレジットカードでは無効です。',
      billTo_state: '入力された都道府県はこのクレジットカードでは無効です。',
      billTo_country: '入力された国/地域はこのクレジットカードでは無効です。',
      billTo_postalCode: '入力された郵便番号はこのクレジットカードでは無効です。',
      country: {
        isocode: '国名が入力されていません。',
      },
    },
    invalid: {
      card_expirationMonth:
        '入力されたクレジットカードの有効期限が無効です。',
      firstName: '入力された名が無効です。',
      lastName: '入力された姓が無効です。',
      password: '入力されたパスワードが無効です。',
      uid: '入力されたUIDが無効です。',
      code: 'コードが無効です。',
      email: 'E-mailアドレスが無効です。',
    },
  },
  cartNotFound: 'カートが見つかりません。',
  invalidCodeProvided: '無効なコードが使用されています。',
};

export const miniCart = {
  item: '現在カートある商品は{{count}}点です。',
  item_plural: '現在カートある商品は{{count}}点です。',
  total: '{{total}}',
  count: '{{count}}',
};

export const skipLink = {
  skipTo: 'スキップする',
  labels: {
    header: 'ヘッダー',
    main: 'メインコンテンツ',
    footer: 'フッター',
    productFacets: '商品ファセット',
    productList: '商品リスト',
  },
};

export const formErrors = {
  globalMessage: '送信しようとしているフォームにエラーが含まれています。',
  required: 'このフィールドは必須です',
  cxInvalidEmail: '有効なEメール形式ではありません',
  cxInvalidPassword: '有効なパスワード形式ではありません',
  cxPasswordsMustMatch: 'パスワードフィールドは一致する必要があります',
  cxEmailsMustMatch: '電子メールフィールドが一致する必要があります',
  cxStarRatingEmpty: '評価欄は必須項目です',
  cxNoSelectedItemToCancel: '少なくとも1つの項目を選択してください',
  cxNegativeAmount: '金額はゼロ以上でなければなりません',
  cxContainsSpecialCharacters: 'フィールドに特殊文字を含めることはできません',
  date: {
    required: 'このフィールドは必須です',
    min: '日付は{{min}}より前にはできません',
    max: '日付は{{max}}より後にはできません。',
    pattern: '日付形式はyyyy-mm-ddを使用してください。',
    invalid: '有効な日付を使用してください',
  },
  file: {
    invalidQunatity:
      '{{ indexes }}行の商品数量が無効です',
    missingArticleNumber:
      '{{ indexes }}行に商品番号がありません。',
    importCartReopenDialogueText:
      'カートのインポートダイアログを閉じ、ファイルを更新してからアップロードをやり直して下さい。',
  },
};

export const errorHandlers = {
  scriptFailedToLoad: 'スクリプトの読み込みに失敗しました。',
  refreshThePage: 'ページを更新してください。',
};

export const common = {
  pageMetaResolver,
  spinner,
  navigation,
  searchBox,
  sorting,
  httpHandlers,
  miniCart,
  skipLink,
  formErrors,
  errorHandlers,
  common: {
    cancel: 'キャンセル',
    delete: '削除',
    remove: '削除する',
    edit: '編集',
    restore: '復元',
    back: '戻る',
    submit: '投稿する',
    continue: '続行',
    save: '保存',
    done: '完了',
    home: 'ホーム',
    noData: 'データがありません。',
    confirm: '確認',
    more: 'もっと見る',
    close: '閉じる',
    loading: '読み込み中',
    menu: 'メニュー',
    reset: 'リセット',
    search: '検索',
    add: '追加',
    userGreeting: 'ようこそ',
    privacySettings: 'プライバシー設定',
  },
};
