<ng-template cxOutletRef="cx-footer">
  <footer cxSkipLink="cx-footer" [cxFocus]="{ disableMouseFocus: true }">
    <div class="footer-container">
      <div class="row">
        <cx-page-layout class="col-xs-12 col-md-9" section="footer"></cx-page-layout>
        <div class="col-xs-12 col-md-3 socialBar">
          <div class="row socialBar">
            <div class="">
              <ul class="iconBar">
                <li>
                  <a href="https://www.connectedcooking.com" target="_blank">
                    <span class="icon-raticons icon-share_club">
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/user/RATIONALAG" target="_blank">
                    <span class="icon-raticons icon-share_youtube">
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/RATIONAL.AG" target="_blank">
                    <span class="icon-raticons icon-share_facebook"></span>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/rational_ag/" target="_blank">
                    <span class="icon-raticons icon-share_instagram">
                    </span>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/rational-ag" target="_blank">
                    <span class="icon-raticons icon-share_linkedin">
                    </span>
                  </a>
                </li>
                <li style="display:none;" id="kununuLink">
                  <a href="https://www.kununu.com/de/rational" target="_blank">
                    <span class="icon-raticons icon-share_kununu"></span>
                  </a>
                </li>
                <li>
                  <a href="https://www.ktchnrebel.com/de/" target="_blank">
                    <span class="icon-raticons icon-share_ktchnrebel">
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row socialNav-container">
        <div class="col-md-9">
          <ul class="socialNav">
            <li data-info="footerlink__intern">
              <a class="privacySettings" onclick="UC_UI.showSecondLayer();">{{"common.privacySettings" | cxTranslate}}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <p class="copyright">© {{currentYear}} RATIONAL. All rights reserved. </p>
        </div>
      </div>
    </div>
  </footer>
</ng-template>