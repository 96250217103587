export const orderDetails = {
  statusDisplay_open: 'Non ancora elaborato',
  statusDisplay_processing: 'Parzialmente elaborato',
  statusDisplay_completed: 'Completamente elaborato',
  purchaseOrderNumber: 'Il vostro riferimento d\'ordine',
  orderNumber: 'Numero d\'ordine Webshop',
  methodOfPayment: 'Metodo di pagamento',
  shippingMethod: 'Metodo di spedizione',
  placedOn: 'Ordinato il',
  moreInformation: 'Altre informazioni',
  orderDocuments: 'Conferma d\'ordine',
  billing: 'Fatturazione',
  invoiceNumber: 'Numero di fattura',
  invoiceDate: 'Data',
  netAmount: 'Importo netto',
  noBillingInvoices: 'Non sono ancora disponibili informazioni sulla fatturazione.',
  noOrderDocuments: 'Non sono ancora disponibili documenti relativi all\'ordine.',
  noInvoiceDocuments:'Non sono ancora disponibili documenti di fatturazione.',
  noShipmentDocuments: 'Non sono ancora disponibili documenti relativi alla spedizione.',
  shipping: {
    shipping: 'Spedizione',
    shippingId: 'ID spedizione',
    deliveryDate: 'Data di spedizione/ritiro',
    quantity: 'Quantità',
    tracking: 'Tracking',
    noShippingInfo: 'Non sono ancora disponibili informazioni sulla spedizione.',
    noTrackingInfo: 'Non sono ancora disponibili informazioni sul tracking.'
  },
  orderId: 'Ordine #',
  replenishmentId: 'Numero di riapprovvigionamento',
  purchaseOrderId: 'Ordine di acquisto',
  emptyPurchaseOrderId: 'Nessuno',
  none: 'Nessuno {{value}}',
  placed: 'Creato ',
  placedBy: 'Creato da',
  unit: 'Unità',
  costCenter: 'Centro di costo',
  costCenterAndUnit: 'Centro di costo / Unità',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  payByAccount: 'Pagamento tramite addebito su conto',
  paidByCreditCard: '(pagamento con carta di credito)',
  status: 'Stato',
  active: 'Attivo',
  shippedOn: 'Spedito il',
  startOn: 'Inizio il',
  nextOrderDate: 'Data del prossimo ordine',
  frequency: 'Frequenza',
  cancelled: ' Annullato',
  deliveryStatus_IN_TRANSIT: 'In transito',
  deliveryStatus_READY_FOR_PICKUP: 'Pronto per il ritiro',
  deliveryStatus_READY_FOR_SHIPPING: 'Pronto per la spedizione',
  deliveryStatus_WAITING: 'In attesa',
  deliveryStatus_DELIVERING: 'In consegna',
  deliveryStatus_PICKPACK: 'In preparazione per la spedizione',
  deliveryStatus_PICKUP_COMPLETE: 'Ritiro completato',
  deliveryStatus_DELIVERY_COMPLETED: 'Consegna completata',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Emissione del pagamento',
  deliveryStatus_READY: 'In corso',
  deliveryStatus_DELIVERY_REJECTED: 'Consegna rifiutata',
  deliveryStatus_SHIPPED: 'Spedito',
  deliveryStatus_TAX_NOT_COMMITTED: 'Problema fiscale',
  deliveryStatus_CANCELLED: 'Annullato',
  statusDisplay_cancelled: 'Annullato',
  statusDisplay_cancelling: 'In attesa di annullamento',
  statusDisplay_confirmed: 'Ordine confermato',
  statusDisplay_created: 'Ordine creato',
  statusDisplay_error: 'In attesa',
  statusDisplay_Error: 'In attesa',
  statusDisplay_pending: {
    approval: 'Approvazione in sospeso',
    merchant: {
      approval: 'In attesa di approvazione da parte del fornitore',
    },
  },
  statusDisplay_approved: 'Approvato',
  statusDisplay_rejected: 'Rifiutato',
  statusDisplay_merchant: {
    approved: 'Approvato dal fornitore',
    rejected: 'Rifiutato dal fornitore',
  },
  statusDisplay_assigned: {
    admin: 'Assegnato all\'amministratore',
  },
  consignmentTracking: {
    action: 'Tracking',
    dialog: {
      header: 'Informazioni sul tracking',
      shipped: 'Spedito',
      estimate: 'Consegna stimata',
      carrier: 'Servizio di consegna',
      trackingId: 'Numero di tracking',
      noTracking: 'Il pacco non è ancora stato spedito. Le informazioni sul tracking saranno disponibili dopo la spedizione del pacco.',
      loadingHeader: 'Tracking spedizone',
    },
  },
  cancellationAndReturn: {
    returnAction: 'Richiedere un reso',
    cancelAction: 'Cancellare articoli',
    item: 'Articolo',
    itemPrice: 'Prezzo dell\'articolo',
    quantity: 'Quantità massima',
    returnQty: 'Quantità da rendere',
    cancelQty: 'Quantità da cancellare',
    setAll: 'Impostare tutte le quantità al massimo',
    totalPrice: 'Totale',
    submit: 'Inviare richiesta',
    returnSuccess: 'La tua richiesta di reso ({{rma}}) è stata inoltrata',
    cancelSuccess: 'La richiesta di annullamento è stata inoltrata (l\'ordine originale {{orderCode}} verrà aggiornato)',
  },
  cancelReplenishment: {
    title: 'Annullare il riapprovvigionamento automatico',
    description: 'Annullare qualsiasi ordine di riapprovvigionamento futuro?',
    accept: 'Sì',
    reject: 'No',
    cancelSuccess: 'L\'ordine di riapprovvigionamento #{{replenishmentOrderCode}} è stato annullato con successo.',
  },
};

export const orderHistory = {
  orderHistory: 'Storico ordini',
  orderId: 'Numero d\'ordine',
  date: 'Data',
  status: 'Stato dell\'ordine',
  PONumber: 'Vostro riferimento d\'ordine',
  total: 'Totale',
  noOrders: 'Non abbiamo ancora uno storico ordini per questo account.',
  startShopping: 'Iniziare gli acquisti',
  sortBy: 'Ordinare per',
  sortOrders: 'Ordinare ordini per',
  purchaseOrderNumber: 'Vostro riferimento d\'ordine',
  notFound: 'Nessun ordine trovato',
  actions: 'Azioni',
  filters: {
    orderNumber: "Numero d'ordine",
    orderNumberPlaceholder: "Inserire il numero d'ordine",
    PONumber: "Numero d'ordine proprio",
    PONumberPlaceholder: "Inserire il proprio numero d'ordine",
    dateRange: 'Periodo di tempo',
    dateRanges: {
      LAST_30_DAYS: 'Ultimi 30 giorni',
      LAST_3_MONTHS: 'Ultimi 3 mesi',
      LAST_6_MONTHS:'Ultimi 6 mesi',
      CURRENT_YEAR:'Anno corrente',
      LAST_YEAR: 'Anno precedente',
      LAST_2_YEARS: "Anno precedente all'ultimo"
    },
    applyFilters: 'Applicare il filtro',
    clearFilters: 'Azzera il filtro',
  },
  jumpToPageLabel: 'Vai a',
  jumpToPageInput: 'Numero di pagina'
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'TUTTI GLI ORDINI ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
