import { Component, ViewEncapsulation } from '@angular/core';
import { ConfiguratorRouterExtractorService } from '@spartacus/product-configurator/common';
import { ConfiguratorCommonsService, ConfiguratorOverviewFormComponent, ConfiguratorStorefrontUtilsService } from '@spartacus/product-configurator/rulebased';

@Component({
  selector: 'rational-configurator-overview-form',
  templateUrl: './rational-configurator-overview-form.component.html',
  styleUrl: './rational-configurator-overview-form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class RationalConfiguratorOverviewFormComponent extends ConfiguratorOverviewFormComponent{


  constructor(
    protected override configuratorCommonsService: ConfiguratorCommonsService,
    protected override configRouterExtractorService: ConfiguratorRouterExtractorService,
    protected override configuratorStorefrontUtilsService: ConfiguratorStorefrontUtilsService
  ) {
    super(configuratorCommonsService,configRouterExtractorService, configuratorStorefrontUtilsService );
  }


}
