export const addressForm = {
    title: 'Başlık',
    defaultTitle: 'Başlık',
    firstName: {
      label: 'İlk isim',
      placeholder: 'İlk isim',
    },
    lastName: {
      label: 'Soyadı',
      placeholder: 'Soyadı',
    },
    companyName: 'Şirket adı',
    companyNamePlaceholder: 'Şirket adı için arama yapın',
    address1: 'Adres 1',
    address2: 'Adres 2 (isteğe bağlı)',
    country: 'Ülke',
    city: {
      label: 'Şehir',
      placeholder: 'Şehir',
    },
    state: 'Eyalet',
    zipCode: {
      label: 'Posta kodu',
      placeholder: 'Posta kodu/Zip',
    },
    phoneNumber: {
      label: 'Telefon numarası',
      placeholder: '(555) 555 - 0123',
    },
    saveAsDefault: 'Varsayılan olarak kaydet',
    chooseAddress: 'Adres seçin',
    streetAddress: 'Sokak adresi',
    aptSuite: 'Apt, Süit',
    selectOne: 'Birini seçin...',
    setAsDefault: 'Varsayılan olarak ayarla',
    titleRequired: 'Başlık gereklidir.',
    userAddressAddSuccess: 'Yeni adres başarıyla eklendi!',
    userAddressUpdateSuccess: 'Adres başarıyla güncellendi!',
    userAddressDeleteSuccess: 'Adres başarıyla silindi!',
    invalidAddress: 'Geçersiz Adres',
  };

  export const addressBook = {
    addNewShippingAddress: 'Yeni bir gönderim adresi ekleyin',
    editShippingAddress: 'Gönderi adresini düzenle',
    areYouSureToDeleteAddress: 'Bu adresi silmek istediğinizden emin misiniz?',
    addNewAddress: 'Yeni adres ekle',
    addAddress: 'Adres ekle',
    updateAddress: 'Güncelleme adresi',
    backToAddressList: 'Adres listesine geri dön',
  };

  export const addressCard = {
    default: 'DEFAULT',
    selected: 'Seçilmiş',
    setAsDefault: 'Varsayılan olarak ayarla',
    shipTo: 'Gönderilecek',
    billTo: 'Bill To',
  };

  export const addressSuggestion = {
    verifyYourAddress: 'Adresinizi doğrulayın',
    ensureAccuracySuggestChange: 'Teslimat doğruluğunu sağlamak için, aşağıda seçilen değişikliği öneriyoruz.',
    chooseAddressToUse: 'Lütfen hangi adresi kullanmak istediğinizi seçiniz:',
    suggestedAddress: 'Önerilen adres',
    enteredAddress: 'Girilen adres',
    editAddress: 'Adresi düzenle',
    saveAddress: 'Adres kaydet',
  };

  export const address = {
      addressForm,
      addressBook,
      addressSuggestion,
      addressCard
    }
