<!-- Item Start Outlet -->
<ng-template [cxOutlet]="CartOutlets.LIST_ITEM">
  <td role="cell" class="cx-checkbox">
    <div>
      <input type="checkbox" class="saved-cart-checkbox" [(ngModel)]="isSelected" (change)="toggleSelection()"/>
    </div>
  </td>

  <td role="cell" class="cx-description">
    <ng-template [cxOutlet]="CartOutlets.ITEM_CONFIGURATOR_ISSUES"></ng-template>
    <ng-container *cxFeatureLevel="'4.2'">
      <rational-cart-item-validation-warning [code]="item.product?.code!"></rational-cart-item-validation-warning>
    </ng-container>
    <div>
      <div class="cx-info">
        <div *ngIf="item.product?.name" class="cx-name">
          <a class="cx-link" [routerLink]="{ cxRoute: 'product', params: item.product } | cxUrl">{{ item.product?.name
            }}</a><rational-product-description [product]="item.product"></rational-product-description>
        </div>
        <div *ngIf="item.product?.code" class="cx-code">
          {{ item.product?.code }}
        </div>

        <!-- item reference-->
        <div class="cx-reference">
          <textarea
          class="form-control referenceText"
          maxlength="1000" #reference
          placeholder="{{ 'cartItems.referencePlaceHolder' | cxTranslate }}"
          (blur)="setEntryReference(item.entryNumber!, reference.value); hideSuggestions()"
          id="reference" value="{{item.reference}}"
          rows="2"
          [name]="'reference'.concat(item.entryNumber!.toString())"
          (input)="onReferenceInput($event)"
          (focus)="showAllSuggestions()"
          ></textarea>
              <div *ngIf="filteredReferences.length  && (showSuggestions | async)" class="autocomplete-items">
                <div
                  *ngFor="let suggestion of filteredReferences"
                  (click)="selectSuggestion(suggestion, item.entryNumber!)"
                  (pointerdown)="selectSuggestion(suggestion, item.entryNumber!)"
                  class="autocomplete-item"
                >
                  {{ suggestion }}
                </div>
              </div>
        </div>
        <!-- item reference-->
      </div>
    </div>
    <div class="cx-cart-addons">
      <!-- Item Details Outlet -->
      <ng-template [cxOutlet]="CartOutlets.ITEM_DETAILS"> </ng-template>

      <!-- Item Bundle Details Outlet -->
      <ng-template [cxOutlet]="CartOutlets.ITEM_BUNDLE_DETAILS"> </ng-template>

      <!-- Promotion -->
      <cx-promotions [promotions]="item.promotions!"></cx-promotions>
    </div>

    <!-- Variants 
    <ng-container *ngIf="item.product?.baseOptions?.length">
      <div
        *ngFor="
          let variant of item.product.baseOptions[0].selected.variantOptionQualifiers
        "
        class="cx-property"
      >
        
        <div class="cx-label" *ngIf="variant.name && variant.value">
          {{ variant.name }}: {{ variant.value }}
        </div>
      </div>
    </ng-container>
    
  </td>
  -->

  <!-- Mobile Item Price -->
  <!--
    <td role="cell" *ngIf="item.basePrice" class="cx-price cx-mobile-only">
      <div class="cx-mobile-header">
        {{ 'cartItems.itemPrice' | cxTranslate }}
      </div>
      <div *ngIf="item.basePrice" class="cx-value">
        {{ item.basePrice.formattedValue }}
      </div>
    </td>
  -->

  <!-- Item Quantity -->
  <td role="cell" class="cx-quantity">
    <div class="cx-mobile-header" placement="left" title="{{ 'cartItems.quantityTitle' | cxTranslate }}">
      {{ 'cartItems.quantity' | cxTranslate }}
    </div>
    <div class="cx-value" [class.readonly-value]="readonly">
      <cx-item-counter [control]="quantityControl"
        [readonly]="!item.updateable! || readonly || options.isSaveForLater!" [max]="item.product?.stock?.stockLevel!"
        [allowZero]="true">
      </cx-item-counter>
    </div>
  </td>

  <td role="cell" class="cx-quantity">
    <div class="cx-mobile-header" placement="left" title="{{ 'cartItems.salesUnit' | cxTranslate }}">
      {{ 'cartItems.salesUnit' | cxTranslate }}
    </div>
    <div class="cx-value" >
      {{ item.product?.salesUnit }}
    </div>
  </td>

  <!-- item Total Price-->
  <!--
  <ng-container *ngIf="options.isSaveForLater; else total">
    <td role="cell" class="cx-total">
      <div class="cx-mobile-header">
        {{ 'saveForLaterItems.stock' | cxTranslate }}
      </div>
      <div
        *ngIf="item?.product?.stock?.stockLevel! >= 0; else forceInstock"
        class="cx-value"
      >
        {{ item?.product?.stock?.stockLevel }}
      </div>
      <ng-template #forceInstock>
        <div class="cx-value">
          {{ 'saveForLaterItems.forceInStock' | cxTranslate }}
        </div>
      </ng-template>
    </td>
  </ng-container> 
  -->

  <td role="cell" *ngIf="
      (!readonly || options.isSaveForLater || options.displayAddToCart) &&
      item.updateable
    " class="cx-actions">

    <!-- add item to cart btn-->
    <!--
    <ng-container *ngIf="!isProductOutOfStock(item.product)">
      <ng-container *ngTemplateOutlet="
          options.optionalBtn;
          context: {
            $implicit: {
              loading: quantityControl.disabled,
              item: this.item,
              options: this.options
            }
          }
        "></ng-container>
    </ng-container>
    -->

    <!-- Availability -->
    <span role="cell" *ngIf="isProductOutOfStock(item.product)" class="cx-availability">
      {{ 'addToCart.outOfStock' | cxTranslate }}
    </span>

    <button *ngIf="!readonly" (click)="removeItem()" [cxAtMessage]="'cartItems.itemRemoved' | cxTranslate"
      [disabled]="false" attr.aria-label="{{ 'addToCart.removeFromCart' | cxTranslate }}"
      class="link cx-action-link cx-remove-btn">
      {{ 'common.remove' | cxTranslate }}
    </button>
  </td>
</ng-template>

<!--Item total price-->
<!--
<ng-template #total>
  <td role="cell" *ngIf="item.totalPrice" class="cx-total">
    <div class="cx-mobile-header">
      {{ 'cartItems.total' | cxTranslate }}
    </div>
    <div class="cx-value">{{ item.totalPrice.formattedValue }}</div>
  </td>
</ng-template>
-->