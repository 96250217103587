export const pageMetaResolver = {
  category: {
    title: '{{count}} výsledek pro {{query}}',
    title_plural: '{{count}} výsledky pro {{query}}',
  },
  checkout: {
    title: 'Pokladna',
  },
  search: {
    title: "{{count}} výsledek pro '{{query}}'",
    title_plural: "{{count}} výsledky pro '{{query}}'",
    findProductTitle: "{{count}} výsledek pro kupón '{{coupon}}'",
    findProductTitle_plural: "{{count}} výsledky pro kupon '{{coupon}}'",
    default_title: 'Všechny produkty',
  },
  product: {
    description: '{{{description}}}',
    heading: '{{heading}}',
    title: '{{title}}',
  },
};

export const spinner = {
  loading: 'Načítá se...',
};

export const navigation = {
  shopAll: 'Nakupovat vše {{ navNode}} >',
  listPrice: {
    enableListPrice: 'Povolit ceníkovou cenu',
    listPriceIsEnabled: 'Cenniková cena je povolena',
    listPriceIsDisabled: 'Cenníková cena je zakázána',
  },
};

export const searchBox = {
  placeholder: 'Hledat zde...',
  ariaLabelInput: 'Hledat zde...',
  ariaLabelSuggestions: 'návrhy na zadávání',
  ariaLabelProducts: 'výsledky hledání',
  initialDescription:
    'Pokud jsou k dispozici automaticky vyplněné výsledky, použijte šipky nahoru a dolů k prohlížení a enter k výběru. Uživatelé dotykových zařízení, prozkoumejte dotykem nebo gesty přejetí.',
  suggestionsResult: '{{ count }} návrh a',
  suggestionsResult_plural: '{{ count }} návrhy a',
  productsResult: '{{ count }} produkt je k dispozici.',
  productsResult_plural: '{{ count }} produktů je k dispozici.',
  resetLabel: 'Obnovit vyhledávání',
  help: {
    insufficientChars: 'Zadejte prosím více znaků',
    noMatch: 'Nepodařilo se nám najít žádné výsledky',
    exactMatch: '{{term }}',
    empty: 'Zeptejte se nás na cokoli',
  },
};

export const sorting = {
  date: 'Datum',
  orderNumber: 'Číslo objednávky',
  rma: 'Číslo vrácení',
  replenishmentNumber: 'Číslo doplnění',
  nextOrderDate: 'Datum příští objednávky',
};

export const httpHandlers = {
  badGateway: 'Došlo k chybě serveru. Zkuste to prosím později.',
  badRequestPleaseLoginAgain: '{{ errorMessage }}. Přihlaste se prosím znovu.',
  badRequestOldPasswordIncorrect: 'Staré heslo je nesprávné.',
  badRequestGuestDuplicateEmail:
    '{{ errorMessage }} e-mail již existuje. Zaregistrujte se prosím pomocí jiného e-mailu a zaregistrujte se pomocí účtu hosta.',
  conflict: 'Již existuje.',
  forbidden:
    'K provedení této akce nejste oprávněni. Pokud se domníváte, že se jedná o chybu, kontaktujte prosím svého správce.',
  gatewayTimeout: 'Server neodpověděl, zkuste to prosím později.',
  internalServerError:
    'Došlo k vnitřní chybě serveru. Zkuste to prosím později.',
  sessionExpired: 'Platnost vaší relace vypršela. Přihlaste se prosím znovu.',
  unknownError: 'Došlo k neznámé chybě',
  unauthorized: {
    common: 'Došlo k neznámé chybě autorizace',
    invalid_client: 'Špatné přihlašovací údaje klienta',
  },
  validationErrors: {
    missing: {
      card_cardType:
        'Vybraná platební karta není podporována. Vyberte prosím jinou.',
      card_accountNumber:
        'Zadané číslo kreditní karty není platné.',
      card_cvNumber: 'Zadaný bezpečnostní kód není platný.',
      card_expirationMonth:
        'Zadané datum vypršení platnosti kreditní karty není platné.',
      card_expirationYear:
        'Zadané datum vypršení platnosti kreditní karty není platné.',
      billTo_firstName: 'Zadané křestní jméno není platné.',
      billTo_lastName: 'Zadané příjmení není platné.',
      billTo_street1: 'Zadaná adresa není platná.',
      billTo_street2: 'Zadaná adresa není platná.',
      billTo_city:
        'Zadané město není pro tuto kreditní kartu platné.',
      billTo_state:
        'Zadaný stát/provincie není pro tuto kreditní kartu platný.',
      billTo_country:
        'Zadaná země/oblast není pro tuto kreditní kartu platná.',
      billTo_postalCode:
        'Zadané poštovní směrovací číslo není pro tuto kreditní kartu platné.',
      country: {
        isocode: 'Chybějící země',
      },
    },
    invalid: {
      card_expirationMonth:
        'Zadané datum vypršení platnosti kreditní karty není platné.',
      firstName: 'Zadané křestní jméno není platné.',
      lastName: 'Zadané příjmení není platné.',
      password: 'Zadané heslo není platné.',
      uid: 'UID není platné.',
      code: 'Kód není platný.',
      email: 'E-mail není platný.',
    },
  },
  cartNotFound: 'Košík nebyl nalezen.',
  invalidCodeProvided: 'Zadán neplatný kód.',
};

export const miniCart = {
  item: '{{count}} položka se právě nachází v košíku',
  item_plural: '{{count}} položky aktuálně v košíku',
  total: '{{{total}}}',
  count: '{{count}}',
};

export const skipLink = {
  skipTo: 'Přeskočit na',
  labels: {
    header: 'Záhlaví',
    main: 'Hlavní obsah',
    footer: 'Zápatí',
    productFacets: 'Aspekty produktu',
    productList: 'Seznam produktů',
  },
};

export const formErrors = {
  globalMessage: 'Formulář, který se pokoušíte odeslat, obsahuje chyby.',
  required: 'Toto pole je povinné',
  cxInvalidEmail: 'Toto není platný formát e-mailu',
  cxInvalidPassword: 'Toto není platný formát hesla',
  cxPasswordsMustMatch: 'Pole s heslem se musí shodovat',
  cxEmailsMustMatch: 'E-mailová pole se musí shodovat',
  cxStarRatingEmpty: 'Pole Hodnocení je povinné',
  cxNoSelectedItemToCancel: 'Vyberte alespoň jednu položku',
  cxNegativeAmount: 'Částka musí být rovna nebo větší než nula',
  cxContainsSpecialCharacters: 'Pole nesmí obsahovat speciální znaky',
  date: {
    required: 'Toto pole je povinné',
    min: 'Datum nesmí být před {{min}}',
    max: 'Datum nesmí být po {{max}}',
    pattern: 'Použijte formát data rrrr-mm-dd',
    invalid: 'Použijte platné datum',
  },
  file: {
    invalidQunatity:
      'Neplatné množství pro produkt v řádcích: {{ indexes }}',
    missingArticleNumber: 'Chybějící číslo výrobku v řádcích: {{ indexes }}',
    importCartReopenDialogueText:
      'Zavřete prosím dialog pro import košíku, poté aktualizujte soubor a zkuste nahrávání znovu.',
  },
};

export const errorHandlers = {
  scriptFailedToLoad: 'Nepodařilo se nahrát skript.',
  refreshThePage: 'Obnovte prosím stránku.',
};

export const common = {
  pageMetaResolver,
  spinner,
  navigation,
  searchBox,
  sorting,
  httpHandlers,
  miniCart,
  skipLink,
  formErrors,
  errorHandlers,
  common: {
    cancel: 'Zrušit',
    delete: 'Odstranit',
    remove: 'Odstranit',
    edit: 'Upravit',
    restore: 'Obnovit',
    back: 'Zpět',
    submit: 'Odeslat',
    continue: 'Pokračovat',
    save: 'Uložit',
    done: 'Hotovo',
    home: 'Hlavní stránka',
    noData: 'Žádná data.',
    confirm: 'Potvrďte',
    more: 'více',
    close: 'Zavřít',
    loading: 'Načítání',
    menu: 'Nabídka',
    reset: 'Obnovit',
    search: 'Vyhledávání',
    add: 'Přidat',
    userGreeting: 'Vítejte na',
    privacySettings: 'Nastavení ochrany osobních údajů',
  },
};
