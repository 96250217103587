import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SavedCartFormType } from '@spartacus/cart/saved-cart/root';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { RationalSavedCartService } from 'src/app/custom/saved-cart/rational-saved-cart.service';
import { RationalActiveCartService } from '../rational-active-cart.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rational-shared-cart-notification',
  templateUrl: './shared-cart-notification.component.html',
  styleUrls: ['./shared-cart-notification.component.scss']
})
export class RationalSharedCartNotificationComponent implements OnInit{

  cartShared: boolean =  true;
  private cartRestoredSubscription!: Subscription;

  constructor(
    protected rationalSavedCartService: RationalSavedCartService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected activeCartService: ActiveCartFacade,
    protected rationalActiveCartService: RationalActiveCartService,
    protected router: Router) {

    this.setSharedCart();
  }

  ngOnInit(): void {
    this.cart$ = this.activeCartService.getActive();
    this.cartRestoredSubscription = this.rationalSavedCartService.cartRestoredevent$.subscribe((cartShared: boolean) => {
      this.cartShared = cartShared;
    });
  }

  @ViewChild('element') element: ElementRef | undefined;
  private subscription = new Subscription();

  setSharedCart() {
    this.sharedCart$ = this.rationalSavedCartService.getSharedCart();
  }

  sharedCart$: Observable<Cart> | undefined ;
  cart$: Observable<Cart> | undefined;
  savedCartFormType = SavedCartFormType;

  openDialog(cart: Cart, type: SavedCartFormType): void {
    const dialog = this.launchDialogService.openDialog(
      LAUNCH_CALLER.SAVED_CART,
      this.element,
      this.vcr,
      { cart, layoutOption: type }
    );

    if (dialog) {
      this.subscription.add(dialog.pipe(take(1)).subscribe(() => {
        this.setSharedCart();
        this.router.navigateByUrl('/cart');
        this.rationalActiveCartService.reloadActiveCart();
      }));
    
    }
  }

  ngOnDestroy() {
    this.cartRestoredSubscription.unsubscribe();
  }
}