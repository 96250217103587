import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  FeaturesConfigModule,
  I18nModule,
  ProductService,
  UrlModule,
  provideConfig,
} from '@spartacus/core';
import {
  CurrentProductService,
  IconModule,
  ItemCounterModule,
  KeyboardFocusModule,
  PromotionsModule,
  SpinnerModule
} from "@spartacus/storefront";
import '../model/rational.product.model';
import { RationalAddToCartComponent } from "./add-to-cart/rational-add-to-cart.component";
import { ProductDownloadsModule } from './product-downloads/product-downloads/product-downloads.module';
import { RationalProductImagesModule } from './product-images/rational-product-images.module';
import { RationalCurrentProductService } from './rational-current-product.service';
import { RationalProductVariantsContainerComponent } from './rational-product-variants-container/rational-product-variants-container.component';
import { RationalProductVariantsContainerModule } from './rational-product-variants-container/rational-product-variants-container.module';
import { StandardDeliveryComponent } from './standard-delivery/standard-delivery.component';
import { ProductDescriptionModule } from './product-description/product-description.module';

@NgModule({
  imports: [
    ProductDownloadsModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SpinnerModule,
    PromotionsModule,
    FeaturesConfigModule,
    UrlModule,
    IconModule,
    I18nModule,
    ItemCounterModule,
    KeyboardFocusModule,
    RationalProductImagesModule,
    RationalProductVariantsContainerModule,
    ProductDescriptionModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductAddToCartComponent: {
          component: RationalAddToCartComponent,
          data: {
            inventoryDisplay: false,
          },
        },
        StandardDeliveryComponent: {
          component: StandardDeliveryComponent,
        },
        ProductVariantSelectorComponent: {
          component: RationalProductVariantsContainerComponent
        }
      },
    }),
    { provide: CurrentProductService, useClass: RationalCurrentProductService },
  ],
  declarations: [RationalAddToCartComponent, StandardDeliveryComponent],
  exports: [RationalAddToCartComponent],
})
export class ProductModule { }
