export const  savedCartDetails = {
  cartName: 'Nome',
  cartDescription: 'Descrizione',
  cartId: 'ID',
  dateSaved: 'Data di salvataggio',
  items: 'Articoli',
  quantity: 'Quantità',
  total: 'Totale',
  editSavedCart: 'Modificare carrello salvato',
  deleteSavedCart: 'Eliminare carrello salvato',
  addSelectedItems: 'Aggiungere gli articoli selezionati',
  emptyCartItems: 'Carrello vuoto',
  selectAll: "Seleziona tutti",
  addItemsToCart: "Ordinare gli articoli selezionatis",
  quickAddToCart: "Aggiungi rapidamente al carrello",
  productAddedToCart: "{{product}} è stato aggiunto al carrello Salvato.",
  selectedItemsAddedToCart: "Gli articoli selezionati sono stati aggiunti al carrello.",
  selectedItemsAreEmpty: "È necessario selezionare almeno un articolo da aggiungere al carrello.",
}

export const savedCartList = {
  savedCarts: 'Carrelli salvati ({{count}})',
  cartName: 'Nome',
  cartId: 'Id',
  dateSaved: 'Data di salvataggio',
  cartDescription: 'Descrizione',
  quantity: 'Quantità',
  total: 'Totale',
  actions: 'Azioni',
  makeCartActive: 'Rendere attivo il carrello',
  notFound: 'Nessun carrello salvato è stato trovato',
  swapCartNoActiveCart: 'Il carrello esistente è stato attivato con successo.',
  swapCartWithActiveCart: 'Il carrello esistente è stato attivato con successo col nome di {{cartName}}. Gli articoli precedenti sono stati salvati nel carrello {{previousCartName}}.',
  editCart: 'Modifica del carrello',
}

export const savedCartCartPage = {
  messages: {
    cartSaved: 'Gli articoli del carrello sono stati salvati per un secondo momento nel carrello {{cartName}}.'
  },
  back: 'Tornare alla panoramica'
}

export const savedCartDialog = {
  saveForLater: 'Salvare per dopo',
  itemsSavedForLater: 'Tutti gli articoli del carrello vengono salvati per un secondo momento.',
  savedCartName: 'Nome del carrello salvato',
  savedCartDescription: 'Descrizione del carrello salvato',
  optional: 'opzionale',
  charactersLeft: 'caratteri rimasti: {{count}}',
  cancel: 'Annullare',
  save: 'Salvare',
  restore: 'Ripristinare',
  followingCartDelete: 'Il seguente carrello salvato verrà eliminato',
  followingCartRestore: 'Il seguente carrello salvato verrà ripristinato come carrello attivo',
  delete: 'Eliminare',
  deleteCartSuccess: 'Carrello eliminato con successo',
  editCartSuccess: 'Carrello modificato con successo',
  editSavedCart: 'Modifica carrello salvato',
  deleteSavedCart: 'Eliminare il carrello salvato',
  restoreSavedCart: 'Ripristinare il carrello salvato',
  name: 'Nome',
  id: 'ID',
  description: 'Descrizione',
  quantity: 'QUANTITÀ',
  total: 'Totale',
  keepCopySavedCart: 'Conservare una copia di questo carrello nell\'elenco dei carrelli salvati',
  isSharedCart: 'Condividi questo carrello con la tua organizzazione',
  defaultCloneCartName: 'Copia di {{name}}',
  nameOfCloneCart: 'Nome del carrello copiato'
}

export const addToSavedCart = {
  savedCarts: 'Carrelli salvati',
  saveCartForLater: 'Salvare il carrello per un secondo momento',
  sharedCartMessageStart :'È disponibile un carrello condiviso creato da {{userName}}. ({{userMail}}). Fare clic ',
  sharedCartMessageMiddle :'qui',
  sharedCartMessageEnd :' per attivare il carrello condiviso. L\'attivazione del carrello condiviso lo cancellerà a livello aziendale fino a quando non lo si condividerà di nuovo, e sovrascriverà anche il carrello personale attuale.'
}

export const savedCart = {
  savedCartDetails,
  savedCartList,
  savedCartCartPage,
  savedCartDialog,
  addToSavedCart
}
