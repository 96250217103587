export const savedCartDetails = {
    cartName: "名称",
    cartDescription: "描述",
    cartId: "ID",
    dateSaved: "保存日期",
    items: "项目",
    quantity: "数量",
    total: "总计",
    editSavedCart: "编辑已保存的购物车",
    deleteSavedCart: "删除购物车",
    addSelectedItems: "添加所选物品",
    emptyCartItems: "清空购物车项目",
    addItemsToCart: "添加所选物品",
    selectAll: "全部选择",
    quickAddToCart: "快速添加到保存的购物车",
    productAddedToCart: "{{product}} 已添加到保存的购物车。",
    selectedItemsAddedToCart: "所选商品已成功添加到购物车。",
    selectedItemsAreEmpty: "您必须至少选择一件商品加入购物车。",
};

export const savedCartList = {
    savedCarts: "已保存的购物车 ({{count}})",
    cartName: "购物车名称",
    cartId: "ID",
    dateSaved: "保存日期",
    cartDescription: "描述",
    quantity: "数量",
    total: "总计",
    actions: "操作",
    makeCartActive: "激活购物车",
    notFound: "未找到保存的购物车",
    swapCartNoActiveCart: "现有购物车已成功激活。",
    swapCartWithActiveCart: "现有购物车已由 {{cartName}} 成功激活。您之前的购物车项目已保存在购物车 {{previousCartName}} 中。",
    editCart: "编辑已保存购物车"
};

export const savedCartCartPage = {
    messages: {
        cartSaved: "您的购物车中的商品已被成功保存到{{cartName}}的购物车中。"
    },
    back: "返回概览"
};

export const savedCartDialog = {
    saveForLater: "稍后保存",
    itemsSavedForLater: "您购物车中的所有商品都将被保存以备日后使用",
    savedCartName: "保存的购物车名称",
    savedCartDescription: "保存购物车描述",
    optional: "可选",
    charactersLeft: "剩余字符数： {{count}}",
    cancel: "取消",
    save: "保存",
    restore: "恢复",
    followingCartDelete: "将删除以下已保存的购物车",
    followingCartRestore: "以下已保存购物车将恢复为活动购物车",
    delete: "删除",
    deleteCartSuccess: "成功删除购物车",
    editCartSuccess: "成功编辑购物车",
    editSavedCart: "编辑已保存购物车",
    deleteSavedCart: "删除已保存购物车",
    restoreSavedCart: "恢复购物车",
    name: "购物车名称",
    id: "ID",
    description: "描述",
    quantity: "数量",
    total: "总计",
    keepCopySavedCart: "在保存的购物车列表中保留此购物车的副本",
    isSharedCart: "与您的组织共享此购物车",
    defaultCloneCartName: "复制 {{name}}",
    nameOfCloneCart: "复制购物车的名称"
};

export const addToSavedCart = {
    savedCarts: "保存的购物车",
    saveCartForLater: "保存购物车以备后用",
    sharedCartMessageStart: "{{userName}}创建的共享购物车可用。({{userMail}})创建的共享购物车。单击",
    sharedCartMessageMiddle: "此处",
    sharedCartMessageEnd: "激活共享购物车。 激活共享购物车后，将从公司层面删除该购物车，直到您再次共享该购物车，同时也会覆盖您当前的个人购物车。"
};

export const savedCart = {
    savedCartDetails,
    savedCartList,
    savedCartCartPage,
    savedCartDialog,
    addToSavedCart
};
