export const checkoutAddress = {
  shippingAddress: 'Adresse de livraison',
  selectYourShippingAddress: 'Sélectionnez votre adresse de livraison :',
  selectYourDeliveryAddress: 'Sélectionnez votre adresse de livraison :',
  defaultShippingAddress: 'Adresse de livraison par défaut',
  addNewAddress: 'Ajouter une nouvelle adresse',
  shipToThisAddress: 'Expédier à cette adresse',
  addressSearch: 'Recherche d\'adresse',
  addressSearchMinLength: 'Le texte de la recherche doit comporter au moins {{length}} caractères',
  submitAddressSearch: 'Entrer',
  errorSearchingSalesforce: 'La recherche ne peut pas être complétée pour le moment, veuillez réessayer plus tard.',
  deliveryAddressSelected: 'Adresse de livraison sélectionnée'
}

export const checkoutOrderConfirmation = {
  confirmationOfOrder: 'Confirmation de la commande :',
  confirmationOfQuote: 'Confirmation de la réception du devis :',
  thankYou: 'Merci pour votre commande !',
  thankYouQuote: 'Merci pour votre demande de devis !',
  invoiceHasBeenSentByEmailLine1: 'Veuillez noter qu\'il peut s\'écouler jusqu\'à un jour ouvrable avant que cette commande n\'apparaisse dans votre historique de commandes. Vous y trouverez la confirmation formelle de la commande, la date d\'expédition et d\'enlèvement confirmée par RATIONAL, ainsi que',
  invoiceHasBeenSentByEmailLine2: 'les informations tarifaires complètes, y compris les taxes applicables. Si vous avez des questions concernant cette commande entre-temps, veuillez contacter votre service clientèle RATIONAL et faire référence à l\'identifiant de commande indiqué ci-dessus.',
  quoteHasBeenSentByEmailLine1: 'Veuillez noter que vos demandes de devis ne seront pas transférées dans l\'historique de vos commandes dans la boutique en ligne.Si vous avez des questions concernant cette demande de devis, veuillez contacter votre service clientèle RATIONAL',
  quoteHasBeenSentByEmailLine2: 'et mentionnez le numéro de devis de la boutique en ligne indiqué ci-dessus ou votre numéro de commande.',
  orderItems: 'Articles de la commande',
  orderPlacedSuccessfully: 'Commande passée avec succès',
  createAccount: 'Créer un compte ?',
  createAccountForNext: 'Créez un compte pour <{{email}}> afin de passer plus rapidement à la caisse lors de votre prochaine visite.',
}

export const checkoutReview = {
  review: 'Résumé de la commande',
  orderItems: 'Commander des articles',
  autoReplenishOrder: 'Commande de réapprovisionnement automatique',
  confirmThatRead: 'Je confirme que j\'ai lu et accepté les conditions d\'utilisation du site.',
  placeOrder: 'Passer la commande',
  placeQuoteOrder: 'Demande de devis',
  scheduleReplenishmentOrder: 'Programmer la commande de réapprovisionnement',
  orderType_PLACE_ORDER: 'Aucun (commande unique)',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Réapprovisionner la commande',
  termsAndConditions: 'Conditions générales de vente',
  editShippingAddress: 'Modifier l\'adresse de livraison',
  poNumber: 'Numéro de commande',
  every: 'Chaque',
  startOn: 'Commencer le',
  dayOfMonth: 'Le jour',
  repeatOnDays: 'Répéter les jours suivants',
  recurrencePeriodType_DAILY: 'Jour(s)',
  recurrencePeriodType_WEEKLY: 'Semaine(s)',
  recurrencePeriodType_MONTHLY: 'Mois',
}

export const checkoutShipping = {
  shippingMethod: 'Mode d\'expédition',
  standardDelivery: 'Livraison standard',
  premiumDelivery: 'Livraison Premium',
}

export const checkoutPO = {
  noPoNumber: 'Aucun',
  poNumber: 'Votre numéro de commande',
  costCenter: 'Centre de coût',
  placeholder: 'Entrez votre propre numéro de bon de commande...',
  availableLabel: 'Les adresses d\'expédition disponibles dépendent de l\'unité du centre de coûts.',
}

export const checkoutProgress= {
  methodOfPayment: 'Mode de paiement',
  deliveryAddress: 'Adresse de livraison',
  deliveryMode: 'Mode de livraison',
  paymentDetails: 'Détails du paiement',
  reviewOrder: 'Résumé de la commande',
}

export const checkoutB2B= {
  checkoutB2B: {
    poNumber: 'Votre numéro de commande*',
    placeholder: 'Entrez votre propre numéro de bon de commande...',
    costCenter: 'Centre de coût',
    availableLabel: 'Les adresses d\'expédition disponibles dépendent de l\'unité du centre de coûts.',
    methodOfPayment: {
      paymentType: 'Type de paiement',
    },
    progress: {
      methodOfPayment: 'Mode de paiement',
    },
    review: {
      poNumber: 'Votre numéro de commande',
    }
  }
}

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: 'Incoterms',
    paymentConditions: 'Conditions de paiement',
  }
}

export const checkoutMode = {
  deliveryMethod: 'Mode de livraison',
  deliveryEntries: 'Articles à expédier',
  specialShippingInstructions: 'Instructions spéciales d\'expédition',
  specialShippingInstructionsPlaceholder: 'Instructions spéciales d\'expédition'
}

export const checkout = {
  checkoutAddress,
  checkoutOrderConfirmation,
  checkoutReview,
  checkoutShipping,
  checkoutPO,
  checkoutProgress,
  checkoutMode,
  checkout : {
    backToCart: 'Retour au panier',
    invalid: {
      accountType: 'Votre compte ne vous permet pas de passer au paiement ici.',
    }
  }
}
