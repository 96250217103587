import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges, ViewEncapsulation,
} from '@angular/core';
import {ProductGridItemComponent, ProductListItemContext, ProductListItemContextSource} from '@spartacus/storefront';

@Component({
  selector: 'rational-product-grid-item',
  templateUrl: './rational-product-grid-item.component.html',
  styleUrls: ['./rational-product-grid-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    ProductListItemContextSource,
    {
      provide: ProductListItemContext,
      useExisting: ProductListItemContextSource,
    },
  ],
})
export class RationalProductGridItemComponent extends ProductGridItemComponent implements OnInit {
  hideAddToCartBtn:boolean = false;
  @Input() override product: any;

  constructor(
    protected override productListItemContextSource: ProductListItemContextSource
  ) {
    super(productListItemContextSource)
  }

  ngOnInit(): void {
    this.hideAddToCartBtn = this.product.isBaseProduct;
  }

}
