import { Component, EventEmitter, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ICON_TYPE } from '@spartacus/storefront';
import { RationalOrderHistoryFilters } from './rational-order-history-filters.model';
import { DatePipe } from '@angular/common';

export enum DATE_RANGE_OPTIONS {
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_3_MONTHS = 'LAST_3_MONTHS',
  LAST_6_MONTHS = 'LAST_6_MONTHS',
  CURRENT_YEAR = 'CURRENT_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  LAST_2_YEARS = 'LAST_2_YEARS',
}

@Component({
  selector: 'rational-order-history-filters',
  styleUrls: ['./rational-order-history-filters.component.scss'],
  templateUrl: './rational-order-history-filters.component.html',
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class RationalOrderHistoryFiltersComponent {
  iconTypes = ICON_TYPE;
  encodedFilter: string = '';
  dateRangeFilter: any;

  private DATE_FORMAT = 'yyyy-MM-ddThh:mm:ss.SSS';

  filterForm: UntypedFormGroup = this.fb.group({
    orderNumberFilter: [''],
    poNumberFilter: [''],
    dateRangeFilter: [''],
  });

  dateRangeOptions: Array<DATE_RANGE_OPTIONS> =
    Object.values(DATE_RANGE_OPTIONS);

  @Output()
  filterListEvent = new EventEmitter<RationalOrderHistoryFilters>();

  constructor(
    protected renderer: Renderer2,
    protected fb: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {}

  emitFilterEvent(): void {
    const orderNumber = this.filterForm.get('orderNumberFilter')?.value;
    const poNumber = this.filterForm.get('poNumberFilter')?.value;
    const dateRange = this.filterForm.get('dateRangeFilter')?.value;

    const orderHistoryFilters: RationalOrderHistoryFilters = {};

    if (orderNumber != null && orderNumber?.trim().length) {
      orderHistoryFilters.salesOrder = orderNumber.trim();
    }
    if (poNumber != null && poNumber?.trim().length) {
      orderHistoryFilters.purchaseOrderByCustomer = poNumber.trim();
    }
    if (dateRange?.length) {
      this.addDateFilters(orderHistoryFilters, dateRange);
    }

    this.filterListEvent.emit(orderHistoryFilters);
  }

  clearAll(): void {
    this.filterForm.reset();
    this.filterListEvent.emit({});
  }

  private addDateFilters(
    filters: RationalOrderHistoryFilters,
    dateRange: string
  ): void {
    const today: Date = new Date();

    switch (dateRange) {
      case DATE_RANGE_OPTIONS.LAST_30_DAYS:
        filters.maxCreationDate = this.formateDate(today);
        const minDate30Days = new Date();
        minDate30Days.setMonth(today.getMonth() - 1);
        filters.minCreationDate = this.formateDate(minDate30Days, true, false);
        break;

      case DATE_RANGE_OPTIONS.LAST_3_MONTHS:
        filters.maxCreationDate = this.formateDate(today);
        const minDate3Months = new Date();
        minDate3Months.setMonth(today.getMonth() - 3);
        filters.minCreationDate = this.formateDate(minDate3Months, true, false);
        break;

      case DATE_RANGE_OPTIONS.LAST_6_MONTHS:
        filters.maxCreationDate = this.formateDate(today);
        const minDate6Months = new Date();
        minDate6Months.setMonth(today.getMonth() - 6);
        filters.minCreationDate = this.formateDate(minDate6Months, true, false);
        break;

      case DATE_RANGE_OPTIONS.CURRENT_YEAR:
        filters.maxCreationDate = this.formateDate(today);
        const minDateCurrentYear = new Date(today.getFullYear(), 0, 1);
        filters.minCreationDate = this.formateDate(minDateCurrentYear, true, false);
        break;

      case DATE_RANGE_OPTIONS.LAST_YEAR:
        filters.maxCreationDate = this.formateDate(new Date(today.getFullYear() - 1, 11, 31), false, true);
        filters.minCreationDate = this.formateDate(new Date(today.getFullYear() - 1, 0, 1), true, false);
        break;

      case DATE_RANGE_OPTIONS.LAST_2_YEARS:
        filters.maxCreationDate = this.formateDate(new Date(today.getFullYear() - 2, 11, 31), false, true);
        filters.minCreationDate = this.formateDate(new Date(today.getFullYear() - 2, 0, 1), true, false);
        break;

      default:
        return;
    }
  }

  formateDate = (date: Date, beginingOfDay?: boolean, endOfDay?: boolean) => {
    if (beginingOfDay) {
      date.setHours(0, 0, 0, 0);
    }
    if (endOfDay) {
      date.setHours(23, 59, 59, 999);
    }

    return this.datePipe.transform(date, this.DATE_FORMAT) ?? '';
  };
}
