<div class="rational-cx-checkout-order-summary">

  <div class="cx-summary-heading">
    {{ 'orderCost.orderSummary' | cxTranslate }}
  </div>

  <div class="cx-summary-partials" *ngIf="cart$ |async as cart">
    <div class="cx-summary-row">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.subtotal' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.subTotal?.formattedValue }}
      </div>
    </div>
    <div class="cx-summary-row cx-summary-total">
      <div class="col-6 cx-summary-label">
        {{ 'orderCost.total' | cxTranslate }}
      </div>
      <div class="col-6 cx-summary-amount">
        {{ cart.totalPriceWithTax?.formattedValue }}
      </div>
    </div>

    
    <div class="cx-summary-row cx-summary-statement" *ngIf="componentData.data$ | async as data"
      [innerHTML]="bypassSecurityTrustHtml(data.infoText)"></div>

    <ng-template #cartWithoutNet>
      <div class="cx-summary-row">
        {{
        cart.totalPriceWithTax?.value !== cart.totalPrice?.value
        ? ('orderCost.grossTax' | cxTranslate)
        : ('orderCost.grossIncludeTax' | cxTranslate)
        }}
        {{ cart.totalTax?.formattedValue }}.
      </div>
    </ng-template>
  </div>
