export const quickOrderCartForm = {
    entriesWasAdded: "({{ quantidade }}) {{ produto }} foi adicionado ao carrinho",
    entryWasAdded: "{{ product }} foi adicionado ao carrinho",
    noResults: "Não foi possível encontrar nenhum produto",
    stockLevelReached: "O nível máximo de estoque foi atingido",
    title: "Adicionar rapidamente ao carrinho",
    productCodePlaceholder: "Digite o número do artigo",
    entryProductCode: "Insira o número do artigo para pedido rápido",
    addToCart: "Adicionar rapidamente ao carrinho",
    product: "Produtos",
    products: "Produtos",
    productCodeLabel: "ID do produto",
    quantityLabel: "Quantidade",
    placeholder: "Inserir SKU do produto",
    listLimitReached: "O limite de produtos foi atingido",
    id: "Número do artigo {{ id }}"
};

export const quickOrderList = {
    addToCart: "Adicionar ao carrinho",
    emptyList: "Lista vazia",
    header: "Adicionar produtos/Skus",
    subHeader: "Você pode adicionar até {{limit }} SKU válidos de cada vez.",
    errorProceedingToCart: "Erro ao acessar o carrinho.",
    warningProceedingToCart: "Aviso ao prosseguir para o carrinho.",
    successfullyAddedToCart: "Adicionado com sucesso ao carrinho.",
    errors: {
        productIsOutOfStock: "{{name }} (#{{code}}) está fora de estoque."
    },
    warnings: {
        productWasReduced: "A quantidade de {{name }} (#{{code}}) foi reduzida para {{ quantityAdded}}."
    },
    successes: {
        productAddedToCart: "{{name }} (#{{code}}) foi adicionado ao carrinho."
    }
};

export const quickOrderTable = {
    product: "Produto",
    actions: "Ações",
    id: "Número do artigo",
    price: "Preço",
    quantity: "QTD",
    itemPrice: "Preço do item",
    qty: "Quantidade"
};

export const quickOrder = {
    quickOrderCartForm,
    quickOrderList,
    quickOrderTable
};
