export const configurator = {
  configurator: {
    header: {
      consistent: 'Coerente',
      complete: 'Completo',
      configId: 'ID di configurazione',
      toconfig: 'Configurare',
      toConfigReadOnly: 'Mostrare dettagli',
      editConfiguration: 'Modificare configurazione,',
      displayConfiguration: 'Visualizzare la configurazione',
      resolveIssues: 'Risolvere i problemi',
      resolveIssue: 'Risolvere un problema',
      resolveConflicts: 'Risolvere i conflitti',
      conflictWarning: 'Il conflitto deve essere risolto per continuare',
      updateMessage: 'La configurazione viene aggiornata in background',
      conflictsResolved: 'I conflitti sono stati risolti',
      showMore: 'mostrare di più',
      showLess: 'mostrare meno',
      items: '{{count}} elemento',
      items_other: '{{count}} elementi',
      show: 'Mostrare',
      hide: 'nascondere',
      multipleWarnings: 'Ci sono più avvisi.',
      reviewWarnings: 'Esaminare queste avvertenze',
      multipleErrors: 'Ci sono più errori.',
      reviewErrors: 'Esaminare questi errori',
      kbKeyName: 'Nome Kb',
      kbKeyLogsys: 'Sistema logico Kb',
      kbKeyVersion: 'Versione Kb',
      kbKeyBuildNr: 'Numero di build del Kb',
    },
    tabBar: {
      configuration: 'Configurazione',
      overview: 'Riepilogo',
    },
    notificationBanner: {
      numberOfIssues: '{{count}} problema deve essere risolto prima del checkout.',
      numberOfIssues_other: '{{count}} problemi devono essere risolti prima del checkout.',
      numberOfConflicts: '{{count}} conflitto deve essere risolto prima del checkout.',
      numberOfConflicts_other: '{{count}} conflitti devono essere risolti prima del checkout.',
    },
    attribute: {
      id: 'Codice articolo',
      quantity: 'Quantità',
      caption: 'Attributi',
      notSupported: 'Il tipo di attributo non è supportato.',
      requiredAttribute: '{{param}} obbligatorio',
      defaultRequiredMessage: 'Inserire un valore per il campo obbligatorio',
      singleSelectRequiredMessage: 'Selezionare un valore',
      singleSelectAdditionalRequiredMessage: 'Selezionare o inserire un valore',
      multiSelectRequiredMessage: 'Selezionare uno o più valori',
      wrongNumericFormat: 'Formato errato, questo attributo numerico deve essere inserito secondo il modello {{pattern}}',
      wrongNumericFormatMessage: 'Inserire il numero nel seguente formato',
      wrongIntervalFormat: 'Inserire un valore entro i limiti indicati',
      deselectionNotPossible: 'Aggiungere un altro prodotto prima di rimuovere questo',
      dropDownSelectMsg: 'Effettuare una selezione',
      noOptionSelectedMsg: 'Nessuna opzione selezionata',
      notVisibleAttributeMsg: 'Questo attributo non è visibile all\'utente',
    },
    button: {
      previous: 'Precedente',
      next: 'Avanti',
      back: 'Indietro',
      more: 'di più',
      less: 'meno',
      deselect: 'Deselezionare',
      select: 'Selezionare',
      add: 'Aggiungere',
      remove: 'Rimuovere',
      exit: 'Uscire dalla configurazione',
      exitMobile: 'Uscire',
      cancelConfiguration: 'Annullare la configurazione',
      cancelConfigurationMobile: 'Annullare',
      filterOverview: 'Filtro',
      filterOverviewWithCount: 'Filtro ({{numAppliedFilters}})',
    },
    icon: {
      groupComplete: 'Completo',
      groupIncomplete: 'Incompleto',
      groupConflict: 'Conflitto',
      subgroup: 'ha sottogruppi',
    },
    priceSummary: {
      basePrice: 'Prezzo base',
      selectedOptions: 'Opzioni selezionate',
      totalPrice: 'Totale',
      totalPricePerItem: 'Totale per articolo',
    },
    addToCart: {
      button: 'Aggiungere al carrello',
      buttonAfterAddToCart: 'Andare al carrello',
      buttonForQuote: 'Continuare con l\'offerta',
      buttonUpdateCart: 'Fatto',
      buttonDisplayOnly: 'Fatto',
      buttonClose: 'Chiudere',
      confirmation: 'La configurazione è stata aggiunta al carrello',
      confirmationUpdate: 'Il carrello è stato aggiornato con la configurazione',
      confirmationQuoteUpdate: 'L\'offerta è stata aggiornata con la configurazione',
      quantity: 'Quantità',
    },
    overviewForm: {
      noAttributeHeader: 'Nessun risultato,',
      noAttributeText: 'Rimuovere i filtri per visualizzare il contenuto del riepilogo,',
      itemPrice: 'Prezzo dell\'articolo',
    },
    overviewSidebar: {
      menu: 'Menu',
      filter: 'Filtrare',
    },
    overviewFilter: {
      title: 'Selezionare i filtri',
      byOption: 'Filtrare per opzione',
      byGroup: 'Filtrare per gruppo',
      byPrice: 'Opzioni con sovrapprezzo',
      mySelections: 'Le mie selezioni',
      removeAll: 'Rimuovere tutto',
      removeAllFilters: 'Rimuovere tutti i filtri',
      removeByPrice: 'Rimuovere il filtro relativo al prezzo',
      removeMySelections: 'Rimuovere il filtro Le mie selezioni',
      removeByGroup: 'Rimuovere il filtro di gruppo per il gruppo {{group}}',
      noFiltersAvailable: 'Nessun filtro disponibile'
    },
    group: {
      general: 'Generale',
      conflictHeader: 'Risolvere i conflitti',
      conflictGroup: 'Conflitto per {{attribute}}'
    },
    conflict: {
      suggestionTitle: 'Suggerimento {{number}}',
      suggestionText: 'Cambiare il valore di {{attribute}}',
      viewConflictDetails: 'Conflitto rilevato - Visualizzare i dettagli',
      conflictDetected: 'Conflitto rilevato',
      viewConfigurationDetails: 'Visualizzare nella configurazione'
    },
    restartDialog: {
      title: 'Configurazione incompleta',
      description: 'C\'è una configurazione incompleta da una sessione precedente. Si desidera riprendere o avviare una nuova configurazione?',
      restartButton: 'Avviare una nuova configurazione',
      resumeButton: 'Riprendere',
    },
    httpHandlers: {
      makeToStockBaseProductIssue: 'Questo prodotto è un prodotto base e non può essere aggiunto al carrello; scegliere invece una variante di prodotto',
    },
    a11y: {
      configureProduct: 'Configurare prodotto',
      showDetailsProduct: 'Mostrare i dettagli del prodotto',
      cartEntryBundleInfo: 'C\'è un articolo ',
      cartEntryBundleInfo_other: 'Ci sono {{items}} articoli ',
      cartEntryBundleName: 'Articolo {{name}}',
      cartEntryBundleNameWithQuantity: 'Articolo {{name}} quantità dell\'articolo {{quantity}}',
      cartEntryBundleNameWithPrice: 'Articolo {{name}} prezzo articolo {{price}}',
      cartEntryBundle: 'Articolo {{name}} prezzo articolo {{price}} quantità dell\'articolo {{quantity}}',
      cartEntryInfoIntro: 'Il prodotto ha i seguenti attributi e valori selezionati',
      cartEntryInfo: 'L\'attributo {{attribute}} ha il valore selezionato {{value}}.',
      nameOfAttribute: 'Nome dell\'attributo',
      valueOfAttribute: 'Valore dell\'attributo {{attribute}}',
      forAttribute: '{{value}} per l\'attributo {{attribute}}',
      valueOfAttributeFull: 'Valore {{value}} dell\'attributo {{attribute}}',
      valueOfAttributeFullWithPrice: 'Valore {{value}} dell\'attributo {{attribute}}, Sovrapprezzo {{price}}',
      selectedValueOfAttributeFull: 'Valore selezionato {{value}} dell\'attributo {{attribute}}',
      selectedValueOfAttributeFullWithPrice: 'Valore selezionato {{value}} dell\'attributo {{attribute}}, Sovrapprezzo {{price}},',
      readOnlyValueOfAttributeFullWithPrice: 'Valore di sola lettura {{value}} dell\'attributo {{attribute}}, Sovrapprezzo {{price}},',
      readOnlyValueOfAttributeFull: 'Valore di sola lettura {{value}} dell\'attributo {{attribute}}',
      valueOfAttributeBlank: 'Il valore dell\'attributo {{attribute}} è vuoto',
      value: 'Valore {{value}}',
      attribute: 'Attributo {{attribute}}',
      requiredAttribute: 'L\'attributo {{param}} è obbligatorio',
      listOfAttributesAndValues: 'Elenco degli attributi e dei loro valori',
      editAttributesAndValues: 'Modifica dei valori degli attributi',
      group: '{{group}} gruppo di attributi',
      itemOfAttributeSelected: 'L\'elemento {{ item }} dell\'attributo {{attribute}} è selezionato. {{ itemIndex }} di {{ itemCount }}',
      itemOfAttributeSelectedWithPrice: 'L\'elemento {{item}} dell\'attributo {{attribute}} è selezionato, Sovrapprezzo {{price}}. {{ itemIndex }} di {{ itemCount }}',
      itemOfAttributeSelectedPressToUnselect: 'L\'articolo {{item}} dell\'attributo {{attribute}} è selezionato. Premere il pulsante per deselezionare. Articolo {{ itemIndex }} di {{ itemCount }}',
      itemOfAttributeSelectedPressToUnselectWithPrice: 'L\'articolo {{item}} dell\'attributo {{attribute}} è selezionato, sovrapprezzo {{price}}. Premere il pulsante per deselezionare. {{ itemIndex }} di {{ itemCount }}',
      itemOfAttributeUnselected: 'L\'elemento {{item}} dell\'attributo {{attribute}} è deselezionato. Premere il pulsante per selezionare.  {{ itemIndex }} di {{ itemCount }}',
      itemOfAttributeUnselectedWithPrice: 'L\'articolo {{item}} dell\'attributo {{attribute}} è deselezionato, Sovrapprezzo {{price}}. Premere il pulsante per selezionare. {{ itemIndex }} di {{ itemCount }}',
      selectNoItemOfAttribute: 'Per non selezionare alcun elemento per l\'attributo {{attribute}}, premere il pulsante . {{itemIndex}} di {{itemCount }}',
      itemOfAttribute: 'Voce dell\'attributo {{attribute}}',
      itemOfAttributeFull: 'Voce {{item}} dell\'attributo {{attribute}}',
      itemOfAttributeFullWithPrice: 'Voce {{item}} dell\'attributo {{attribute}}, Sovrapprezzo {{price}}',
      itemOfAttributeFullWithQuantity: 'Voce {{item}} dell\'attributo {{attribute}}, Quantità {{quantity}}',
      itemOfAttributeFullWithPriceAndQuantity: 'Voce {{item}} dell\'attributo {{attribute}}, Quantità {{quantity}}, Sovrapprezzo {{price}}',
      itemDescription: 'Descrizione dell\'elemento {{ item }}',
      listbox: 'Casella di riepilogo con {{ count }} valori ',
      valueSurcharge: 'Sovrapprezzo del valore',
      conflictDetected: 'Conflitto rilevato! Controllare i conflitti in cima alla lista dei gruppi.',
      conflictsInConfiguration: 'Ci sono conflitti nella configurazione. Numero di conflitti',
      listOfGroups: 'Elenco dei gruppi',
      inListOfGroups: 'Siete nell\'elenco dei gruppi',
      groupName: 'Gruppo {{group}}',
      groupBack: 'Vi trovate in un sottogruppo. Selezionare per tornare indietro.',
      conflictBack: 'Vi trovate nel risolutore di conflitti. Selezionare per tornare indietro o selezionare le schede successive per risolvere i conflitti.',
      iconConflict: 'Il gruppo ha conflitti.',
      iconIncomplete: 'Il gruppo ha attributi richiesti senza valori selezionati.',
      iconComplete: 'Il gruppo è completo.',
      iconSubGroup: 'Il gruppo ha un sottogruppo.',
      next: 'Passare al gruppo successivo.',
      previous: 'Tornare al gruppo precedente.',
      showMoreProductInfo: 'Mostrare più informazioni per il prodotto {{ prodotto }} o continuare la configurazione.',
      showLessProductInfo: 'Mostrare meno informazioni per il prodotto {{ prodotto }} o continuare la configurazione.',
      productName: 'Nome del prodotto',
      productCode: 'Codice prodotto',
      productDescription: 'Descrizione del prodotto',
      kbKeyName: 'Nome Kb {{name}}',
      kbKeyLogsys: 'Sistema logico Kb {{logsys}}',
      kbKeyVersion: 'Versione Kb {{version}}',
      kbKeyBuildNr: 'Numero di build del Kb {{number}}',
      configurationPage: 'Vi trovate nella pagina di configurazione.',
      configurationPageLink: 'Passare alla pagina di configurazione.',
      overviewPage: 'Vi trovate nella pagina di riepilogo. Controllare gli attributi e i valori selezionati con la modalità lettura',
      overviewPageLink: 'Passare alla pagina di riepilogo della configurazione.',
      navigateToConflict: 'Seguire il link per ottenere maggiori dettagli sul conflitto per l\'attributo {{attribute}}.',
      numericIntervalStandard: 'Inserire un valore compreso tra {{minValue}} e {{maxValue}}.',
      numericIntervalStandardOpen: '(Gli endpoint non sono inclusi)',
      numericIntervalStandardLowerEndpointNotIncluded: '(L\'endpoint inferiore non è incluso)',
      numericIntervalStandardUpperEndpointNotIncluded: '(L\'endpoint superiore non è incluso)',
      numericInfiniteIntervalMinValueIncluded: 'Inserire un valore maggiore o uguale a {{value}}',
      numericInfiniteIntervalMinValue: 'Inserire un valore maggiore di {{value}}',
      numericInfiniteIntervalMaxValueIncluded: 'Inserire un valore inferiore o uguale a {{value}}',
      numericInfiniteIntervalMaxValue: 'Inserire un valore inferiore a {{value}}',
      numericIntervalSingleValue: 'Inserire {{value}}',
      combinedIntervalsText: '{{combinedInterval}} o {{newInterval }}.',
      additionalValue: 'È inoltre possibile inserire un valore aggiuntivo.',
      addToCartPrices: 'Prezzo base {{basePrice}}, Opzioni selezionate {{selectedOptions}}, Prezzo totale {{totalPrice}}.',
      filterOverview: 'Aprire il menu dei filtri del riepilogo della configurazione',
      filterOverviewWithCount: 'Aprire il menu dei filtri del riepilogo della configurazione - {{numAppliedFilters}} filtri attivi',
      closeFilterMenu: 'Chiudere il menu dei filtri del riepilogo della configurazione e applicare i filtri selezionati',
      filterOverviewByPrice: 'Filtrare il riepilogo della configurazione in base alle opzioni con sovrapprezzo',
      filterOverviewByMySelections: 'Filtrare il riepilogo della configurazione in base alle proprie selezioni',
      filterOverviewByGroup: 'Filtrare il riepilogo della configurazione per gruppo {{groupName}}',
      closeConflictSolverModal: 'Chiudere la finestra del risolutore di conflitti',
      closeRestartDialog: 'Chiudere la finestra di dialogo ´Configurazione non completata´ e tornare alla pagina dei dettagli del prodotto',
      description: 'Fare clic per visualizzare la descrizione di questo valore',
    },
    variantCarousel: {
      title: 'Versioni preconfigurate',
    },
  },
};
