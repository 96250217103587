import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalOrderDetailsItemsComponent } from './order-details-items/rational-order-details-items.component';
import {
  AuthGuard,
  CmsConfig,
  FeaturesConfig,
  I18nModule,
  UrlModule,
  provideConfig,
} from '@spartacus/core';
import { OutletModule, PromotionsModule, SpinnerModule } from '@spartacus/storefront';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { RationalOrderConsignedEntriesComponent } from './order-consigned-entries/rational-order-consigned-entries.component';
import { RouterModule } from '@angular/router';
import { RationalCartSharedModule } from '../../cart/base/cart-item/rational-cart-shared.module';
import { RationalOrderDetailTotalsComponent } from './order-detail-totals/rational-order-detail-totals.component';
import { RationalOrderDetailActionsComponent } from './order-detail-actions/rational-order-detail-actions.component';
import { RationalOrderOverviewComponent } from '../order-overview/order-overview.component';
import { OrderBillingInvoicesModule } from './order-billing-invoices/order-billing-invoices.module';
import { OrderItemShippingModule } from './order-item-shipping/order-item-shipping.module';
import { RationalOrderDocumentModule } from "../order-document/rational-order-document.module";
import { AbstractOrderContextModule } from '@spartacus/cart/base/components';

@NgModule({
    declarations: [
        RationalOrderDetailsItemsComponent,
        RationalOrderConsignedEntriesComponent,
        RationalOrderDetailTotalsComponent,
        RationalOrderDetailActionsComponent
    ],
    exports: [
        RationalOrderDetailsItemsComponent,
        RationalOrderConsignedEntriesComponent,
        RationalOrderDetailTotalsComponent,
        RationalOrderDetailActionsComponent
    ],
    imports: [
        CommonModule,
        PromotionsModule,
        I18nModule,
        AddToCartModule,
        OutletModule,
        RouterModule,
        UrlModule,
        SpinnerModule,
        RationalCartSharedModule,
        OrderBillingInvoicesModule,
        OrderItemShippingModule,
        RationalOrderDocumentModule,
        AbstractOrderContextModule
    ],
    providers: [
        provideConfig(<CmsConfig | FeaturesConfig>{
            cmsComponents: {
                AccountOrderDetailsOverviewComponent: {
                    component: RationalOrderOverviewComponent,
                    guards: [AuthGuard],
                },
                AccountOrderDetailsActionsComponent: {
                    component: RationalOrderDetailActionsComponent,
                    guards: [AuthGuard],
                },
                AccountOrderDetailsTotalsComponent: {
                    component: RationalOrderDetailTotalsComponent,
                    guards: [AuthGuard],
                },
                AccountOrderDetailsItemsComponent: {
                    component: RationalOrderDetailsItemsComponent,
                    guards: [AuthGuard],
                    data: {
                        enableAddToCart: false,
                    },
                },
            },
        }),
    ],
})
export class RationalOrderDetailsModule {}
