import { Component } from '@angular/core';
import { CartItemContextSource, CartItemListRowComponent } from '@spartacus/cart/base/components';
import { CartItemContext } from '@spartacus/cart/base/root';

@Component({
  selector: '[rational-saved-cart-detials-item-list-row], rational-saved-cart-detials-item-list-row',
  templateUrl: './rational-saved-cart-detials-item-list-row.component.html',
  styleUrls: ['./rational-saved-cart-detials-item-list-row.component.scss'],
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
})
export class RationalSavedCartDetialsItemListRowComponent extends CartItemListRowComponent {

}
