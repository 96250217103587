import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig, provideDefaultConfig, UrlModule } from '@spartacus/core';
import {
  GenericLinkModule,
  GlobalMessageComponentModule, HamburgerMenuModule,
  IconModule, KeyboardFocusModule,
  OutletModule,
  OutletRefModule, PageLayoutModule,
  PageSlotModule,
  SkipLinkModule, NavigationModule,
} from '@spartacus/storefront';
import { HeaderOutletComponent } from "./header/header-outlet.component";
import { MinicartOutletComponent } from "./minicart/minicart-outlet.component";
import { HeaderLinksComponent } from "./header-links/header-links.component";
import { HeaderLinksUiComponent } from "./header-links/header-links-ui.component";
import { RationalProductIntroComponent } from "./product-intro-component/rational-product-intro.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderLinkFlyoutComponent } from "./header-link-flyout/header-link-flyout.component";
import { RationalHamburgerMenuComponent } from "./hamburger-menu/rational-hamburger-menu.component";
import { EmptyCartPageComponent } from './empty-cart-page/empty-cart-page.component';
import { RationalFooterNavigationComponent } from './footer/rational-footer-navigation/rational-footer-navigation.component';
import { RationalCategoryNavigationComponent } from './rational-category-navigation/rational-category-navigation.component';
import { ContextSwitcherModule } from '../context-switcher/context-switcher.module';
import { RationalLoadingOverlayModule } from './loading-overlay/loading-overlay.module';
import { NgToggleModule } from 'ng-toggle-button';
import { FormsModule } from '@angular/forms';
import { RationalIdListPriceService } from './header-links/list-price/rational-id-list-price-service';


@NgModule({
  declarations: [
    HeaderOutletComponent,
    FooterComponent,
    MinicartOutletComponent,
    HeaderLinksComponent,
    HeaderLinksUiComponent,
    RationalProductIntroComponent,
    HeaderLinkFlyoutComponent,
    RationalHamburgerMenuComponent,
    EmptyCartPageComponent,
    RationalFooterNavigationComponent,
    RationalCategoryNavigationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    IconModule,
    I18nModule,
    NgToggleModule,
    FormsModule,
    PageSlotModule,
    GlobalMessageComponentModule,
    OutletRefModule,
    OutletModule,
    PageLayoutModule,
    SkipLinkModule,
    KeyboardFocusModule,
    HamburgerMenuModule,
    GenericLinkModule,
    NavigationModule,
    ContextSwitcherModule
  ],
  providers: [
    RationalIdListPriceService,
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        NavigationComponent: {
          component: HeaderLinksComponent,
        },
        ProductIntroComponent: {
          component: RationalProductIntroComponent,
        },
        HamburgerMenuComponent: {
          component: RationalHamburgerMenuComponent,
        },
        FooterNavigationComponent: {
          component: RationalFooterNavigationComponent,
        },
        CategoryNavigationComponent: {
          component: RationalCategoryNavigationComponent,
          data: {
            resetMenuOnClose: true,
          },
        }
      },
    }),
  ],
  exports: [
    RationalCategoryNavigationComponent,
    HeaderOutletComponent,
    FooterComponent,
    RationalFooterNavigationComponent,
    MinicartOutletComponent,
    HeaderLinksComponent,
    HeaderLinksUiComponent,
    RationalProductIntroComponent,
    HeaderLinkFlyoutComponent,
    RationalHamburgerMenuComponent,
    EmptyCartPageComponent,
    RationalLoadingOverlayModule
  ]
})
export class LayoutModule { }
