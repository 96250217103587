import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { I18nModule } from "@spartacus/core";
import { IconModule, KeyboardFocusModule, SpinnerModule } from "@spartacus/storefront";
import { RationalOrderDocumentsListComponent } from "./rational-order-document-list.component";


@NgModule({
  declarations: [
    RationalOrderDocumentsListComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    SpinnerModule,
    KeyboardFocusModule
   ],
  exports: [
    RationalOrderDocumentsListComponent
  ],

})
export class RationalOrderDocumentsListModule {}
