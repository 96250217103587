export const orderDetails = {
    statusDisplay_open: 'Henüz işlenmedi',
    statusDisplay_processing: 'Kısmen işlendi',
    statusDisplay_completed: 'Tamamen işlenmiş',
    purchaseOrderNumber: 'Satınalma Sipariş Numaranız',
    orderNumber: 'Webshop Sipariş Numarası',
    methodOfPayment: 'Ödeme Yöntemi',
    shippingMethod: 'Nakliye Yöntemi',
    status: "Durum",
    placedOn: 'Üzerine yerleştirildi',
    moreInformation: 'Daha Fazla Bilgi',
    orderDocuments: 'Sipariş Belgeleri',
    billing: 'Faturalandırma',
    invoiceNumber: 'Fatura Numarası',
    invoiceDate: 'Tarih',
    netAmount: 'Net Tutar',
    noBillingInvoices: 'Henüz fatura bilgisi mevcut değil.',
    noOrderDocuments: 'Henüz sipariş belgeleri mevcut değil.',
    noInvoiceDocuments: 'Henüz mevcut fatura belgesi yok.',
    noShipmentDocuments: 'Henüz nakliye belgeleri mevcut değil.',
    shipping: {
      shipping: 'Nakliye',
      shippingId: 'Nakliye Kimliği',
      deliveryDate: 'Sevk/Teslim Alma Tarihi',
      quantity: 'Miktar',
      tracking: 'Takip',
      noShippingInfo: 'Henüz gönderim bilgisi mevcut değil.',
      noTrackingInfo: 'Henüz takip bilgisi mevcut değil.'
    },
    orderId: "Sipariş #",
    replenishmentId: "İkmal #",
    purchaseOrderId: "Satınalma Siparişi #",
    emptyPurchaseOrderId: "Hiçbiri",
    none: "Hiçbiri {{value}}",
    placed: "Yerleştirildi",
    placedBy: "Tarafından yerleştirildi",
    unit: "Birim",
    costCenter: "Maliyet Merkezi",
    costCenterAndUnit: 'Maliyet Merkezi / Birim',
    costCenterAndUnitValue: "{{costCenterName}} / {{unitName}}",
    payByAccount: "Hesaba Göre Ödeme",
    paidByCreditCard: "(kredi kartı ile ödenir)",
    active: "Aktif",
    shippedOn: "Üzerinde sevk",
    startOn: "Başla",
    nextOrderDate: "Sonraki Sipariş Tarihi",
    frequency: "Frekans",
    cancelled: "İptal Edildi",
    deliveryStatus_IN_TRANSIT: "Transit Halinde",
    deliveryStatus_READY_FOR_PICKUP: "Teslim Almaya Hazır",
    deliveryStatus_READY_FOR_SHIPPING: "Sevkiyata Hazır",
    deliveryStatus_WAITING: "Bekliyorum",
    deliveryStatus_DELIVERING: "Teslimat",
    deliveryStatus_PICKPACK: "Sevkiyat için Hazırlık",
    deliveryStatus_PICKUP_COMPLETE: "Pikap Tamamlandı",
    deliveryStatus_DELIVERY_COMPLETED: "Teslimat Tamamlandı",
    deliveryStatus_PAYMENT_NOT_CAPTURED: "Ödeme Sorunu",
    deliveryStatus_READY: "İşlem Devam Ediyor",
    deliveryStatus_DELIVERY_REJECTED: "Teslimat Reddedildi",
    deliveryStatus_SHIPPED: "Sevkiyat",
    deliveryStatus_TAX_NOT_COMMITTED: "Vergi Sorunu",
    deliveryStatus_CANCELLED: "İptal Edildi",
    statusDisplay_cancelled: "İptal Edildi",
    statusDisplay_cancelling: "Beklemede İptal",
    statusDisplay_confirmed: "Sipariş onaylandı",
    statusDisplay_created: "Sipariş oluşturuldu",
    statusDisplay_error: "Beklemede",
    statusDisplay_Error: "Beklemede",
    statusDisplay_pending: {
        approval: "Onay Bekliyor",
        merchant: {
            Approval: "Satıcı Onayı Bekleniyor"
        }
    },
    statusDisplay_approved: "Onaylandı",
    statusDisplay_rejected: "Reddedildi",
    statusDisplay_merchant: {
        Approved: "Tüccar Onaylı",
        Rejected: "Tüccar Reddedildi",
    },
    statusDisplay_assigned: {
        admin: "Yöneticiye Atandı"
    },
    consignmentTracking: {
      action: "İzleme Bilgileri",
      dialog: {
        header: "Sevkiyat Takibi",
        shipped: "Sevkiyat",
        estimate: "Tahmini Teslimat",
        carrier: "Teslimat Hizmeti",
        trackingId: "Takip Numarası",
        noTracking: "Paket depodan gönderilmemiştir. Takip bilgileri, paket gönderildikten sonra mevcut olacaktır.",
        loadingHeader: "Kargo Takibi"
      }
    },
    cancellationAndReturn: {
      returnAction: "İade Talebi",
      cancelAction: "Öğeleri İptal Et",
      item: "Öğe",
      itemPrice: "Ürün Fiyatı",
      quantity: "Maksimum Miktar",
      returnQty: "İade Edilecek Miktar",
      cancelQty: "İptal Edilecek Miktar",
      setAll: "Tüm miktarları maksimuma ayarlayın",
      totalPrice: "Toplam",
      submit: "Talep Gönder",
      returnSuccess: "İade talebiniz ({{rma}}) gönderildi",
      cancelSuccess: "İptal talebiniz gönderildi (orijinal sipariş {{orderCode}} güncellenecektir)"
    },
    cancelReplenishment: {
      title: "Yenilemeyi İptal Et",
      description: "Gelecekteki ikmal siparişlerini iptal edeyim mi?",
      accept: "Evet",
      reject: "Hayır",
      cancelSuccess: "İkmal siparişi #{{replenishmentOrderCode}} başarıyla iptal edildi."
    }
};

export const orderHistory = {
    orderHistory: 'Sipariş geçmişi',
    orderId: 'Sipariş numarası',
    date: 'Tarih',
    status: 'Durum',
    PONumber: 'P.O. numarası',
    total: 'Toplam',
    noOrders: 'Bu hesap için henüz sipariş verisi yok.',
    startShopping: 'Alışverişe Başlayın',
    sortBy: 'Göre sırala',
    sortOrders: 'Sıralama Düzenleri',
    purchaseOrderNumber: 'Sipariş Numarası',
    notFound: 'Sipariş Bulunamadı',
    actions: 'Eylemler',
    filters: {
      orderNumber: 'Sipariş numarası',
      orderNumberPlaceholder: 'Sipariş numarasını girin',
      PONumber: 'Kendi sipariş numarası',
      PONumberPlaceholder: 'Kendi sipariş numaranızı girin',
      dateRange: 'Zaman dilimi',
      dateRanges: {
        LAST_30_DAYS: 'Son 30 gün',
        LAST_3_MONTHS: 'Son 3 ay',
        LAST_6_MONTHS: 'Son 6 ay',
        CURRENT_YEAR: 'Cari yıl ',
        LAST_YEAR: 'Geçen yıl',
        LAST_2_YEARS: 'Geçen yıldan önceki yıl'
      },
      applyFilters: 'Filtre uygula',
      clearFilters: 'Filtreyi sıfırla',
    },
    jumpToPageLabel: 'Şuraya atla',
    jumpToPageInput: 'Sayfa numarası'
};

export const AccountOrderHistoryTabContainer = {
    tabs: {
      AccountOrderHistoryComponent: 'TÜM SİPARİŞLER ({{param}})'
    }
};

export const order = {
    orderDetails,
    orderHistory,
    AccountOrderHistoryTabContainer
};
