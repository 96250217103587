export const configurator = {
    configurator: {
        header: {
            consistent: "Tutarlı",
            complete: "Tamamlandı",
            configId: "Yapılandırma Kimliği",
            toconfig: "Yapılandırma",
            toConfigReadOnly: "Detayları göster",
            editConfiguration: "Yapılandırmayı Düzenle",
            displayConfiguration: "Ekran Yapılandırması",
            resolveIssues: "Sorunları Çözün",
            resolveIssue: "Sorunu Çözün",
            resolveConflicts: "Çatışmaları Çözün",
            conflictWarning: "Çatışmanın devam etmesi için çözülmesi gerekir",
            updateMessage: "Yapılandırma arka planda güncelleniyor",
            conflictsResolved: "Çatışmalar çözüldü",
            showMore: "daha fazla göster",
            showLess: "daha az göster",
            items: "{{count}} öğe",
            items_other: "{{count}} öğeler",
            show: "göster",
            hide: "Gizle",
            multipleWarnings: "Birden fazla uyarı bulunmaktadır.",
            reviewWarnings: "Bu uyarıları gözden geçirin",
            multipleErrors: "Birden fazla hata var.",
            reviewErrors: "Bu hataları gözden geçirin",
            kbKeyName: "Kb adı",
            kbKeyLogsys: "Kb mantıksal sistem",
            kbKeyVersion: "Kb versiyonu",
            kbKeyBuildNr: "Kb yapı numarası",
          },
          tabBar: {
            configuration: "Konfigürasyon",
            overview: "Genel Bakış",
          },
          notificationBanner: {
            numberOfIssues: "{{count}} sorunu ödeme yapılmadan önce çözülmelidir.",
            numberOfIssues_other: "{{count}} sorunları ödeme yapılmadan önce çözülmelidir.",
            numberOfConflicts: "{{count}} çakışması ödeme yapılmadan önce çözülmelidir.",
            numberOfConflicts_other: "{{count}} çakışmaları ödeme yapılmadan önce çözülmelidir.",
          },
          attribute: {
            id: "KIMLIK",
            quantity: "Miktar",
            caption: "Nitelikler",
            notSupported: "Öznitelik Türü desteklenmiyor.",
            requiredAttribute: "{{param}} gerekli",
            defaultRequiredMessage: "Gerekli alan için bir değer girin",
            singleSelectRequiredMessage: "Bir değer seçin",
            singleSelectAdditionalRequiredMessage: "Bir değer seçin veya girin",
            multiSelectRequiredMessage: "Bir veya daha fazla değer seçin",
            wrongNumericFormat: "Yanlış biçim, bu sayısal özellik {{pattern}} şablonuna göre girilmelidir",
            wrongNumericFormatMessage: "Numarayı aşağıdaki formatta girin",
            wrongIntervalFormat: "Belirtilen sınırlar içinde bir değer girin",
            deselectionNotPossible: "Bunu çıkarmadan önce farklı bir ürün ekleyin",
            dropDownSelectMsg: "Bir seçim yapın",
            noOptionSelectedMsg: "Hiçbir seçenek seçilmedi",
            notVisibleAttributeMsg: "Bu özellik kullanıcı tarafından görülemez",
          },
          button: {
            previous: "Önceki",
            next: "Sonraki",
            back: "Geri",
            more: "daha fazla",
            less: "daha az",
            deselect: "Seçimi kaldır",
            select: "Seçiniz",
            add: "Ekle",
            remove: "Kaldırmak",
            exit: "Çıkış Yapılandırması",
            exitMobile: "Çıkış",
            cancelConfiguration: "Yapılandırmayı İptal Et",
            cancelConfigurationMobile: "İptal",
            filterOverview: "Filtre",
            filterOverviewWithCount: "Filtre ({{numAppliedFilters}})",
          },
          icon: {
            groupComplete: "Tamamlandı",
            groupIncomplete: "Tamamlanmamış",
            groupConflict: "Çatışma",
            subgroup: "alt gruplara sahiptir",
          },
          priceSummary: {
            basePrice: "Baz Fiyat",
            selectedOptions: "Seçilen Seçenekler",
            totalPrice: "Toplam",
            totalPricePerItem: "Ürün Başına Toplam",
          },
          addToCart: {
            button: "Sepete Ekle",
            buttonAfterAddToCart: "Sepete Devam Et",
            buttonForQuote: "Alıntıya Devam Et",
            buttonUpdateCart: "Bitti",
            buttonDisplayOnly: "Bitti",
            buttonClose: "Kapat",
            confirmation: "Konfigürasyon sepete eklendi",
            confirmationUpdate: "Sepet yapılandırma ile güncellendi",
            confirmationQuoteUpdate: "Alıntı yapılandırma ile güncellendi",
            selectVariant: "Yapılandırmanız için birden fazla mevcut varyant bulundu. Bu durumda, doğru varyantı seçmek için lütfen müşteri hizmetleriyle iletişime geçin.",
            addMatchingVariant: "Yapılandırmanız için mevcut varyant alışveriş sepetine eklendi.",
            quantity: "Miktar",
          },
          overviewForm: {
            noAttributeHeader: "Sonuç yok",
            noAttributeText: "Genel bakış içeriğini görmek için filtre(ler)i kaldırın",
            itemPrice: "Ürün Fiyatı",
          },
          overviewSidebar: {
            menu: "Menü",
            filter: "Filtre",
          },
          overviewFilter: {
            title: "Filtreleri Seçin",
            byOption: "Seçeneğe Göre Filtrele",
            byGroup: "Gruba Göre Filtrele",
            byPrice: "Fiyatla İlgili Seçenekler",
            mySelections: "Benim Seçtiklerim",
            removeAll: "Tümünü Kaldır",
            removeAllFilters: "Tüm Filtreleri Kaldır",
            removeByPrice: "Fiyatla İlgili Filtreyi Kaldır",
            removeMySelections: "Seçtiklerim Filtresini Kaldır",
            removeByGroup: "Grup için Grup Filtresini Kaldır {{group}}",
            noFiltersAvailable: "Mevcut filtre yok",
          },
          group: {
            general: "Genel",
            conflictHeader: "Çatışmaları çözün",
            conflictGroup: "{{Attribute}} için çatışma",
          },
          conflict: {
            suggestionTitle: "Öneri {{number}}",
            suggestionText: "\\{{attribute }}\\ için değeri değiştirin",
            viewConflictDetails: "Çatışma Tespit Edildi - Ayrıntıları Görüntüle",
            conflictDetected: "Çatışma Tespit Edildi",
            viewConfigurationDetails: "Yapılandırmada Görüntüle",
          },
          restartDialog: {
            title: "Tamamlanmamış Konfigürasyon",
            description: "Önceki bir oturumdan bitmemiş bir yapılandırma var. Devam etmek veya yeni bir yapılandırma başlatmak ister misiniz?",
            restartButton: "Yeni Başla",
            resumeButton: "Özgeçmiş",
          },
          httpHandlers: {
            makeToStockBaseProductIssue: "Bu ürün temel bir üründür ve sepete eklenemez; bunun yerine bir ürün çeşidi seçin.",
          },
          a11y: {
            configureProduct: "Ürünü yapılandırma",
            showDetailsProduct: "Ürün ayrıntılarını göster",
            cartEntryBundleInfo: "Bir madde var",
            cartEntryBundleInfo_other: "{{items}} öğeleri vardır",
            cartEntryBundleName: "Öğe {{name }}",
            cartEntryBundleNameWithQuantity: "Öğe {{name }} öğe miktarı {{quantity}}",
            cartEntryBundleNameWithPrice: "Ürün {{name }} ürün fiyatı {{price}}",
            cartEntryBundleNameWithPriceAndQuantity: "Ürün {{name }} ürün fiyatı {{price}} ürün miktarı {{quantity}}",
            cartEntryBundle: "Ürün aşağıdaki özniteliklere ve seçilen değerlere sahiptir",
            cartEntryInfoIntro: "Öznitelik {{attribute }} seçili değere sahip {{value}}",
            cartEntryInfo: "Öznitelik Adı",
            nameOfAttribute: "Niteliğin değeri {{attribute }}",
            valueOfAttribute: "{{value}} niteliği için {{attribute }}",
            forAttribute: "Özniteliğin değeri {{value}} {{attribute }}",
            valueOfAttributeFull: "Özniteliğin değeri {{value}} {{attribute }}",
            valueOfAttributeFullWithPrice: "Öznitelik {{attribute }}'in değeri {{value}}, Ek ücret {{price}}",
            selectedValueOfAttributeFull: "Öznitelik {{attribute }}'in seçilen değeri {{value}}",
            selectedValueOfAttributeFullWithPrice: "Öznitelik {{attribute }}'in seçilen değeri {{value}}, Ücret {{price}}",
            readOnlyValueOfAttributeFullWithPrice: "Salt okunur değer {{attribute }} özniteliğinin {{value }} değeri, Ücret {{price}}",
            readOnlyValueOfAttributeFull: "Öznitelik {{attribute }}'in salt okunur değeri {{value}}",
            valueOfAttributeBlank: "{{attribute}} özniteliğinin değeri boş",
            value: "Değer {{value}}",
            attribute: "Öznitelik {{attribute}}",
            requiredAttribute: "Öznitelik {{param}} gereklidir",
            listOfAttributesAndValues: "Özniteliklerin ve değerlerinin listesi",
            editAttributesAndValues: "Özniteliklerin değerlerini düzenleme",
            group: "{{group}} öznitelikler grubu",
            itemOfAttributeSelected: "Öznitelik {{attribute}}'in {{item}} öğesi seçildi. {{itemCount}} öğesinin {{itemIndex}} öğesi",
            itemOfAttributeSelectedWithPrice: "Öznitelik {{attribute}}'in {{item}} öğesi seçildi, Ücret {{price}}. {{itemCount}} öğesinin {{itemIndex}} öğesi",
            itemOfAttributeSelectedPressToUnselect: "Öznitelik {{attribute}}'in {{item}} öğesi seçildi. Seçimi kaldırmak için düğmeye basın. {{itemIndex}} of {{itemCount}}",
            itemOfAttributeSelectedPressToUnselectWithPrice: "Öznitelik {{attribute}}'in {{item}} öğesi seçildi, Ek Ücret {{price}} Seçimi kaldırmak için düğmeye basın. {{itemIndex}} of {{itemCount}}",
            itemOfAttributeUnselected: "Öznitelik {{attribute}}'in {{item}} öğesi seçili değil. Seçmek için düğmesine basın. {{itemIndex}} of {{itemCount}}",
            itemOfAttributeUnselectedWithPrice: "Öznitelik {{attribute}}'in {{item}} öğesi seçili değil, Ücret {{price}}. Seçmek için düğmesine basın. {{itemIndex}} of {{itemCount}}",
            selectNoItemOfAttribute: "Öznitelik {{attribute}} için hiçbir öğe seçmemek için düğmesine basın. {{itemIndex}} of {{itemCount}}",
            itemOfAttribute: "Öznitelik öğesi {{attribute}}",
            itemOfAttributeFull: "Öznitelik {{attribute}} öğesi {{item}}",
            itemOfAttributeFullWithPrice: "Öznitelik {{attribute}} öğesi {{item}}, Ücret {{price}}",
            itemOfAttributeFullWithQuantity: "Öznitelik {{attribute}} öğesi {{item}}, Miktar {{quantity}}",
            itemOfAttributeFullWithPriceAndQuantity: "Öznitelik {{attribute}} öğesi {{item}}, Miktar {{quantity}}, Ücret {{price}}",
            itemDescription: "Öğe için açıklama {{item}}",
            listbox: "{{number}} değerini içeren liste kutusu.",
            valueSurcharge: "Değer ek ücreti",
            conflictDetected: "Çatışma tespit edildi! Grup listesinin en üstündeki çakışmaları kontrol edin.",
            conflictsInConfiguration: "Yapılandırmanızda çakışmalar var. Çatışma sayısı",
            listOfGroups: "Grupların listesi",
            inListOfGroups: "Grup listesindesiniz",
            groupName: "Grup {{group}}",
            groupBack: "Bir alt gruptasınız. Geri dönmek için seçin.",
            conflictBack: "Çatışma çözücüsündesiniz. Geri dönmek için seçin veya çatışmaları çözmek için sonraki sekmeleri seçin.",
            iconConflict: "Grup çatışmalara sahip.",
            iconIncomplete: "Grup, seçili değerleri olmayan gerekli özniteliklere sahiptir.",
            iconComplete: "Grup tamamlandı.",
            iconSubGroup: "Grup, alt gruba sahiptir.",
            next: "Sonraki gruba gidin.",
            previous: "Önceki gruba gidin.",
            showMoreProductInfo: "Ürün için daha fazla bilgi göster {{product}} veya yapılandırmaya devam et.",
            showLessProductInfo: "Ürün için daha az bilgi göster {{product}} veya yapılandırmaya devam et.",
            productName: "Ürün Adı",
            productCode: "Ürün Kodu",
            productDescription: "Ürün Açıklaması",
            kbKeyName: "Kb adı {{name}}",
            kbKeyLogsys: "Kb mantıksal sistem {{logsys}}",
            kbKeyVersion: "Kb sürümü {{version}}",
            kbKeyBuildNr: "Kb yapı numarası {{number}}",
            configurationPage: "Yapılandırma sayfasındasınız.",
            configurationPageLink: "Yapılandırma sayfasına gidin.",
            overviewPage: "Genel bakış sayfasındasınız. Ekran okuyucunuzun okuma modu ile öznitelikleri ve seçilen değerlerini kontrol edin.",
            overviewPageLink: "Yapılandırmaya genel bakış sayfasına gidin.",
            navigateToConflict: "{{attribute}} özniteliğine ilişkin çakışma hakkında daha fazla bilgi almak için bağlantıyı izleyin.",
            numericIntervalStandard: "{{minValue}} ile {{maxValue}} arasında bir değer girin.",
            numericIntervalStandardOpen: "(Uç noktalar dahil değildir)",
            numericIntervalStandardLowerEndpointNotIncluded: "(Alt uç nokta dahil değildir)",
            numericIntervalStandardUpperEndpointNotIncluded: "(Üst uç nokta dahil değildir)",
            numericInfiniteIntervalMinValueIncluded: "{{value }} değerinden büyük veya eşit bir değer girin",
            numericInfiniteIntervalMinValue: "{{value }}'den büyük bir değer girin",
            numericInfiniteIntervalMaxValueIncluded: "{{value }} değerinden küçük veya eşit bir değer girin",
            numericInfiniteIntervalMaxValue: "{{value }}'den küçük bir değer girin",
            numericIntervalSingleValue: "{{value }} girin",
            combinedIntervalsText: "{{combinedInterval}} veya {{newInterval}}",
            additionalValue: "Ayrıca ek bir değer de girebilirsiniz.",
            addToCartPrices: "Baz Fiyat {{basePrice}}, Seçilen Seçenekler {{selectedOptions}}, Toplam Fiyat {{totalPrice}}",
            filterOverview: "Yapılandırmaya genel bakış filtre menüsünü açın",
            filterOverviewWithCount: "Yapılandırmaya genel bakış filtre menüsünü açın - {{numAppliedFilters}} etkin filtreler.",
            closeFilterMenu: "Yapılandırmaya genel bakış filtre menüsünü kapatın ve seçilen filtreleri uygulayın",
            filterOverviewByPrice: "Yapılandırmaya genel bakışı fiyatla ilgili seçeneklere göre filtreleme",
            filterOverviewByMySelections: "Yapılandırmaya genel bakışı seçimlerinize göre filtreleme",
            filterOverviewByGroup: "Yapılandırmaya genel bakış sayfasını {{groupName}} grubuna göre filtrele",
            closeConflictSolverModal: "Çatışma çözücü modalini kapat",
            closeRestartDialog: "Bitmemiş Yapılandırma\\ iletişim kutusunu kapatın ve ürün ayrıntıları sayfasına geri dönün",
            description: "Bu değerin açıklamasını görmek için tıklayın"
          },
          variantCarousel: {
            title: "Önceden Yapılandırılmış Sürümler"
          }
    }
}