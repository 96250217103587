import { Component, ViewEncapsulation } from '@angular/core';
import { ProductImageZoomDialogComponent } from '@spartacus/product/image-zoom/components';

@Component({
  selector: 'cx-product-image-zoom-dialog',
  templateUrl: './rational-product-image-zoom-dialog.component.html',
  styleUrls: ['./rational-product-image-zoom-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalProductImageZoomDialogComponent extends ProductImageZoomDialogComponent{

}
