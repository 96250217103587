<div class="emptyCartBody">
  <div class="sharedCartMessage" *ngIf="shouldShowSharedCartNotifications$ | async">
    <rational-shared-cart-notification></rational-shared-cart-notification>
  </div>
  <cx-import-order-entries *ngIf="shouldDisplayImport$ | async"></cx-import-order-entries>
</div>
<cx-export-order-entries *ngIf="shouldDisplayExport$ | async"></cx-export-order-entries>



