export const savedCartDetails = {
    cartName: "名称",
    cartDescription: "説明",
    cartId: "ID",
    dateSaved: "保存日",
    items: "アイテム",
    quantity: "数量",
    total: "合計",
    editSavedCart: "保存したカートの編集",
    deleteSavedCart: "保存したカートの削除",
    addSelectedItems: "選択した商品を追加",
    emptyCartItems: "空のカートアイテム",
    addItemsToCart: "選択した商品を注文",
    selectAll: "すべて選択 ",
    quickAddToCart: "カートに入れる",
    productAddedToCart: "{{product}} がカートに追加されました。",
    selectedItemsAddedToCart: "選択された商品は正常にカートに追加されました。",
    selectedItemsAreEmpty: "カートに追加する商品を1つ以上選択してください。",
};

export const savedCartList = {
    savedCarts: "保存済みカート ({{count}})",
    cartName: "名前",
    cartId: "ID",
    dateSaved: "保存日",
    cartDescription: "説明",
    quantity: "数量",
    total: "合計",
    actions: "アクション",
    makeCartActive: "カートをアクティブにする",
    notFound: "保存されたカートが見つかりません",
    swapCartNoActiveCart: "既存のカートが正常に有効化されました。",
    swapCartWithActiveCart: "既存のカートが{{cartName}}によって正常に有効化されました。以前の商品はカート{{previousCartName}}に保存されました。",
    editCart: "保存されたカートの編集"
};

export const savedCartCartPage = {
    messages: {
        cartSaved: "カートのアイテムは正常に\'{{cartName}}\'カートに保存されました。"
    },
    back: "概要に戻る"
};

export const savedCartDialog = {
    saveForLater: "後で保存する",
    itemsSavedForLater: "カート内の全てのアイテムは後で保存されます。",
    savedCartName: "保存されたカート名",
    savedCartDescription: "保存されたカートの説明",
    optional: "オプション",
    charactersLeft: "残り文字数：{{count}}",
    cancel: "キャンセル",
    save: "保存",
    restore: "復元",
    followingCartDelete: "以下の保存カートは削除されます。",
    followingCartRestore: "以下の保存カートが有効なカートとして復元されます。",
    delete: "削除",
    deleteCartSuccess: "カートの削除に成功しました",
    editCartSuccess: "カートの編集に成功しました",
    editSavedCart: "保存されたカートの編集",
    deleteSavedCart: "保存されたカートの削除",
    restoreSavedCart: "カートの復元",
    name: "名前",
    id: "ID",
    description: "詳細",
    quantity: "数量",
    total: "合計",
    keepCopySavedCart: "このカートのコピーを保存カートリストに保存する",
    isSharedCart: "このカートを組織で共有する",
    defaultCloneCartName: "{{name}}のコピー",
    nameOfCloneCart: "コピーしたカートの名前"
};

export const addToSavedCart = {
    savedCarts: "保存済カート",
    saveCartForLater: "後でカートを保存",
    sharedCartMessageStart: "{{userName}}{{userMail}} が作成した共有カートを利用できます。共有カートを有効にするには、",
    sharedCartMessageMiddle: "ここ",
    sharedCartMessageEnd: " をクリックしてください。共有カートをアクティブにすると、再度共有するまで会社レベルからカートが削除され、現在の個人カートも上書きされます。"
};

export const savedCart = {
    savedCartDetails,
    savedCartList,
    savedCartCartPage,
    savedCartDialog,
    addToSavedCart
};
