<cx-carousel [items]="(components$ | async)!" [template]="template" itemWidth="100%" class="inline-navigation">
</cx-carousel>


<ng-template #template let-item="item">
  <div class="container container-full">
    <div class="slick-slide" *ngIf="item.media && item.media.url">
      <div>
        <div class="slide-image slider-row-height relative width-100"
          style="background-image: url('{{ this.resolveAbsoluteUrl(item.media.url) }}'); width: 100%; display: inline-block;">
          <div class="gradient_overlay_linear"></div>
          <div class="header-text absolute fw-bold width-100">
            <div class="fw-bold fs-54 fs-xs-26 fc-white text-center">{{item.title}}</div>
            <div class="fw-bold fs-34 fs-xs-16 fc-white text-center">{{item.description}}</div>
            <div class="text-center p-t-15">
              <a *ngIf="item.buttonText" 
                class="rational-btn white bold"
                [attr.target]="item.openUrlInNewTab == 'true' ? '_blank': '_self'"
                href="{{item.url}}" 
                tabindex="-1"
                >
                {{item.buttonText}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>