import { Component, ViewEncapsulation } from '@angular/core';
import { ProductVariantsContainerComponent } from '@spartacus/product/variants/components';

@Component({
  selector: 'cx-product-variants-container',
  templateUrl: './rational-product-variants-container.component.html',
  styleUrls: ['./rational-product-variants-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalProductVariantsContainerComponent extends ProductVariantsContainerComponent {

}
