export const orderDetails = {
  orderId: 'Numéro de commande',
  orderNumber: 'Numéro de commande de la boutique en ligne',
  replenishmentId: 'Numéro de réapprovisionnement',
  purchaseOrderId: 'Numéro de commande',
  purchaseOrderNumber: 'Votre numéro de commande',
  emptyPurchaseOrderId: 'Aucun',
  none: 'Aucun {{value}}',
  placed: 'Placé',
  placedBy: 'Placé par',
  unit: 'Unité',
  costCenter: 'Centre de coût',
  costCenterAndUnit: 'Centre de coût / Unité',
  costCenterAndUnitValue: '{{costCenterName}} / {{unitName}}',
  methodOfPayment: 'Mode de paiement',
  payByAccount: 'Payer par compte',
  paidByCreditCard: '(payé par carte de crédit)',
  status: 'Statut',
  active: 'Actif',
  shippedOn: 'Expédié le',
  shippingMethod: 'Mode d\'expédition',
  placedOn: 'Placé le',
  startOn: 'Début le',
  nextOrderDate: 'Date de la prochaine commande',
  frequency: 'Fréquence',
  cancelled: ' Annulé',
  deliveryStatus_IN_TRANSIT: 'En transit',
  deliveryStatus_READY_FOR_PICKUP: 'Prêt pour l\'enlèvement',
  deliveryStatus_READY_FOR_SHIPPING: 'Prêt pour l\'expédition',
  deliveryStatus_WAITING: 'En attente',
  deliveryStatus_DELIVERING: 'Livraison',
  deliveryStatus_PICKPACK: 'Préparation de l\'envoi',
  deliveryStatus_PICKUP_COMPLETE: 'Enlèvement terminé',
  deliveryStatus_DELIVERY_COMPLETED: 'Livraison terminée',
  deliveryStatus_PAYMENT_NOT_CAPTURED: 'Émission du paiement',
  deliveryStatus_READY: 'En cours',
  deliveryStatus_DELIVERY_REJECTED: 'Livraison refusée',
  deliveryStatus_SHIPPED: 'Expédié',
  deliveryStatus_TAX_NOT_COMMITTED: 'Question fiscale',
  deliveryStatus_CANCELLED: 'Annulé',
  statusDisplay_cancelled: 'Annulé',
  statusDisplay_cancelling: 'Annulation en attente',
  statusDisplay_confirmed: 'Commande confirmée',
  statusDisplay_completed: 'Commande livrée',
  statusDisplay_created: 'Ordre créé',
  statusDisplay_error: 'En attente',
  statusDisplay_Error: 'En attente',
  statusDisplay_processing: 'Ordre créé',
  statusDisplay_open: 'Ordre créé',
  statusDisplay_pending: {
    approval: 'Approbation en attente',
    merchant: {
      approval: 'En attente de l\'approbation du commerçant',
    },
  },
  statusDisplay_approved: 'Approuvé',
  statusDisplay_rejected: 'Rejeté',
  statusDisplay_merchant: {
    approved: 'Commerçant approuvé',
    rejected: 'Commerçant rejeté',
  },
  statusDisplay_assigned: {
    admin: 'Attribué à l\'administrateur',
  },
  consignmentTracking: {
    action: 'Suivre le colis',
    dialog: {
      header: 'Informations de suivi',
      shipped: 'Expédié',
      estimate: 'Estimation de la livraison',
      carrier: 'Service de livraison',
      trackingId: 'Numéro de suivi',
      noTracking: 'Le colis n\'a pas été expédié de l\'entrepôt. Les informations de suivi seront disponibles après l\'expédition du colis.',
      loadingHeader: 'Suivi des envois',
    },
  },
  cancellationAndReturn: {
    returnAction: 'Demander un retour',
    cancelAction: 'Annuler un article',
    item: 'Article',
    itemPrice: 'Prix de l\'article',
    quantity: 'Quantité maximale',
    returnQty: 'Quantité à retourner',
    cancelQty: 'Quantité à annuler',
    setAll: 'Fixer toutes les quantités au maximum',
    totalPrice: 'Total',
    submit: 'Soumettre la demande',
    returnSuccess: 'Votre demande de retour ({{rma}}) a été soumise',
    cancelSuccess: 'Votre demande d\'annulation a été soumise (la commande originale {{orderCode}} sera mise à jour)',
  },
  cancelReplenishment: {
    title: 'Annuler le réapprovisionnement',
    description: 'Annuler une future commande de réapprovisionnement ?',
    accept: 'Oui',
    reject: 'Non',
    cancelSuccess: 'La commande de réapprovisionnement #{{replenishmentOrderCode}} a été annulée avec succès.',
  }
}

export const orderHistory = {
  orderHistory: 'Historique de la commande',
  orderId: 'N° de commande',
  placedBy: 'Personne de contact',
  emptyPurchaseOrderId: 'Aucune',
  date: 'Date d\'entrée en vigueur',
  status: 'Statut',
  total: 'Total',
  noOrders: 'Nous n\'avons aucun enregistrement de commande pour ce compte.',
  noReplenishmentOrders: 'Nous n\'avons aucun enregistrement de commande de réapprovisionnement pour ce compte.',
  startShopping: 'Commencer les achats',
  sortBy: 'Trier par',
  replenishmentOrderHistory: 'Historique des commandes de réapprovisionnement',
  replenishmentOrderId: 'Réapprovisionnement #',
  purchaseOrderNumber: 'N° DE COMMANDE',
  startOn: 'Commencer le',
  frequency: 'Fréquence',
  nextOrderDate: 'Date de la prochaine commande',
  cancel: 'Annulation',
  cancelled: 'Annulé',
  replenishmentHistory: 'Historique du réapprovisionnement',
  notFound: 'Aucune commande trouvée',
}

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'COMMANDES',
    OrderReturnRequestListComponent: 'CITATIONS',
  }
}

export const wishlist = {
  empty: 'Il n\'y a aucun article dans votre liste de souhaits.',
}

export const myAccount = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer,
  wishlist
}
