export const paymentForm = {
  payment: 'Zahlung',
  choosePaymentMethod: 'Wählen Sie eine Zahlungsart',
  paymentType: 'Zahlungsmethode',
  accountHolderName: {
    label: 'Name des Kontoinhabers',
    placeholder: 'Name des Kontoinhabers'
  },
  cardNumber: 'Kartennummer',
  expirationDate: 'Ablaufdatum',
  securityCode: 'Sicherheits code (CVV)',
  securityCodeTitle: 'Kartenprüfnummer',
  saveAsDefault: 'Als Standard speichern',
  setAsDefault: 'Als Standard setzen',
  billingAddress: 'Rechnungsadresse',
  sameAsShippingAddress: 'Gleich wie Lieferanschrift',
  selectOne: 'Auswählen...',
  monthMask: 'MM',
  yearMask: 'JJJJ',
  expirationYear: 'Ablaufjahr {{ selected }}',
  expirationMonth: 'Ablaufmonat {{ selected }}',
  useThisPayment: 'Diese Zahlung verwenden',
  addNewPayment: 'Neue Zahlung hinzufügen',
  changePayment: 'Zahlung ändern'
}

export const paymentMethods = {
  paymentMethods: 'Zahlungsarten',
  newPaymentMethodsAreAddedDuringCheckout: 'Neue Zahlungsarten wurden während des Checkouts hinzugefügt.',
  invalidField: 'Ungültiges Feld: {{ field }}'
}

export const paymentCard = {
  deleteConfirmation: 'Möchten Sie diese Zahlungsart wirklich löschen?',
  setAsDefault: 'Als Standard setzen',
  expires: 'Ablaufdatum: {{ month }}/{{ year }}',
  defaultPaymentMethod: '✓ STANDARD',
  selected: 'Ausgewählt'
}

export const paymentTypes = {
  title: 'Zahlungsmethode',
  paymentType_CARD: 'Kreditkarte',
  paymentType_ACCOUNT: 'Rechnung',
  paymentType_INVOICE: 'Rechnung'
}

export const payment = {
  paymentForm,
  paymentMethods,
  paymentCard,
  paymentTypes
}
