import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RationalCartQuickOrderFormComponent } from './rational-cart-quick-order-form.component';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormErrorsModule } from '@spartacus/storefront';



@NgModule({
  declarations: [RationalCartQuickOrderFormComponent],
  imports: [
    CommonModule, ReactiveFormsModule, I18nModule, FormErrorsModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CartQuickOrderFormComponent: {
          component: RationalCartQuickOrderFormComponent
        }
      }
    }),    
  ]
})
export class RationalCartQuickOrderFormModule { }
