export const orderDetails = {
  statusDisplay_open: 'pas encore traité',
  statusDisplay_processing: 'Partiellement traité',
  statusDisplay_completed: 'Complètement traitée',
  purchaseOrderNumber: 'Propre numéro de commande',
  orderNumber: 'Numéro de commande de la boutique en ligne',
  methodOfPayment: 'Mode de paiement',
  shippingMethod: 'Méthode d\'expédition',
  placedOn: 'Commandé le',
  moreInformation: 'Détails de la commande',
  orderDocuments: 'Documents de commande',
  billing: 'Facturation',
  invoiceNumber: 'Numéro de facture',
  invoiceDate: 'Date',
  netAmount: 'Montant net',
  noBillingInvoices: 'Aucune information de facturation disponible pour l\'instant.',
  noOrderDocuments: 'Aucun document de commande n\'est encore disponible.',
  noInvoiceDocuments:'Aucun document de facturation n\'est disponible pour l\'instant.',
  noShipmentDocuments: 'Aucun document d\'expédition n\'est encore disponible.',
  shipping: {
    shipping: 'Expédition',
    shippingId: 'Id d\'expédition',
    deliveryDate: 'Date d\'expédition',
    quantity: 'Quantité',
    tracking: 'Suivi',
    noShippingInfo: 'Aucune information sur l\'expédition n\'est encore disponible.',
    noTrackingInfo: 'Aucune information de suivi n\'est disponible pour l\'instant.'
  },
};

export const orderHistory = {
  orderHistory: 'Historique de la commande',
  orderId: 'Numéro de commande',
  date: 'Date de la commande',
  status: 'Statut de la commande',
  PONumber: 'Numéro de commande',
  total: 'Total',
  noOrders: 'Nous n\'avons pas encore de données de commande pour ce compte.',
  startShopping: 'Commencer les achats',
  sortBy: 'Trier par',
  sortOrders: 'Trier les commandes',
  purchaseOrderNumber: 'Numéro de commande',
  notFound: 'Aucune commande trouvée',
  actions: 'Actions',
};

export const AccountOrderHistoryTabContainer = {
  tabs: {
    AccountOrderHistoryComponent: 'TOUTES LES COMMANDES ({{param}})'
  }
};

export const order = {
  orderDetails,
  orderHistory,
  AccountOrderHistoryTabContainer
};
