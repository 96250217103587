import { HttpClient } from "@angular/common/http";
import { OccEndpointsService, RoutingService, UserIdService, GlobalMessageService, GlobalMessageType} from "@spartacus/core";
import { Observable, catchError, combineLatest, map, of, tap, switchMap, take } from "rxjs";
import { Injectable } from "@angular/core";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { RationalCart } from "src/app/custom/model/rational-cart.model";
import { rationaOCCEndpoints } from "../../../rational-backend-occ-endpoint/rational-backend-occ-endpoint.module";

@Injectable({
    providedIn: 'root',
})
export class RationalOrderSummaryService {
    userId: string | undefined
    cartId: string | undefined

    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected userIdService: UserIdService,
        protected activeCartService: ActiveCartFacade,
        protected routingService: RoutingService,
        protected globalMessageService: GlobalMessageService
    ) { }


  public updateRequestedretrievaldate(requestedRetrievalDate: string | null) {

    return combineLatest([
      this.userIdService.takeUserId(),
      this.activeCartService.getActiveCartId()

    ])
      .pipe((take(1)),
        switchMap(([userId, cartId]) => {
          this.userId = userId;
          this.cartId = cartId

          const url = this.occEndpoints.buildUrl(rationaOCCEndpoints.cartDeliveryDate.requestedDeliveryDate,
            {
              urlParams: {userId: this.userId, cartId: this.cartId, requestedRetrievalDate},
            });

          return this.http.put<boolean>(url, requestedRetrievalDate)
        })).subscribe((isValid: boolean) => {

          if (!isValid) {
            this.globalMessageService.add(
              {key: 'cartDetails.requestedRetrievalDateInvalid'}, GlobalMessageType.MSG_TYPE_ERROR);
          } else {
            this.globalMessageService.add(
              {key: 'cartDetails.requestedRetrievalDateAdded'},
              GlobalMessageType.MSG_TYPE_CONFIRMATION
            );
            this.activeCartService.reloadActiveCart();
          }
        },
        error => {
          this.globalMessageService.add(
            {key: 'cartDetails.requestedRetrievalDateInvalid'}, GlobalMessageType.MSG_TYPE_ERROR);

        }
      );
  }



  public removeRequestedretrievaldate() {
    this.userIdService.takeUserId().subscribe(userId => {
      this.userId = userId
    });

    this.activeCartService.getActiveCartId().subscribe(cartId => {
      this.cartId = cartId
      const url = this.occEndpoints.buildUrl(rationaOCCEndpoints.cartDeliveryDate.requestedRetrievalDate,
        {
          urlParams: {userId: this.userId, cartId: this.cartId},
        });
      this.http.delete<string>(url).pipe(
        catchError((error) => {
          throw (error);
        })
      );
    });
  }

    public getEarliestDeliveryDate(): Observable<String> {
      let url = ''
      this.userIdService.takeUserId().subscribe(userId => {
        this.userId = userId
      });

      this.activeCartService.getActiveCartId().subscribe(cartId => {
        this.cartId = cartId

        url = this.occEndpoints.buildUrl(rationaOCCEndpoints.cartDeliveryDate.earliestDeliveryDate, {
          urlParams: { userId: this.userId, cartId: this.cartId },
        });
      });


      return this.http.get<string>(url, { responseType: 'text' as 'json'});
  }

  public getHolidayDates(): Observable<string[]> {
    let url = ''
    this.userIdService.takeUserId().subscribe(userId => {
      this.userId = userId
    });
    this.activeCartService.getActiveCartId().subscribe(cartId => {
      this.cartId = cartId
      url = this.occEndpoints.buildUrl(rationaOCCEndpoints.cartDeliveryDate.holidayDates, {
        urlParams: {userId: this.userId, cartId: this.cartId},
      });
    });
    return this.http.get<string[]>(url, {responseType: 'text' as 'json'});
  }



  checkDeliveryDateIsValid(): Observable<boolean > {
    return combineLatest([
      this.activeCartService.getActive().pipe(
        map((cart) => (cart as RationalCart).requestedRetrievalAt)),
        this.getEarliestDeliveryDate()
    ]).pipe(
      take(1),
      switchMap(([cartDeliveryData, earliest]) => {
        if(cartDeliveryData === undefined){
          return of(false);
        }else {
          const url = this.occEndpoints.buildUrl(rationaOCCEndpoints.cartDeliveryDate.requestedDeliveryDate, {
              urlParams: {userId: this.userId, cartId: this.cartId, requestedRetrievalDate: cartDeliveryData},
            }
          );
          return this.http.put<boolean>(url, cartDeliveryData).pipe(
            map((isValid: boolean) => {
              if (this.formatResponseDate(cartDeliveryData).getTime() < this.formatEarliestDate(earliest).getTime()) {
                return false;
              } else {
                return isValid;
              }
            }),
            catchError((error) => {
              console.error("Error checking date validity:", error);
              this.routingService.go({
                cxRoute: 'cart',
              });
              return of(false)
            })
          );
        }
      })
    );
  }

  formatResponseDate(dateString: String): Date {
    const [year, month, day] = dateString.split('-');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

  formatEarliestDate(dateString: String): Date {
    const [day, month, year] = dateString.split('.');
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  }

}
