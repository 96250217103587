export const checkoutAddress = {
  shippingAddress: 'Dodací adresa',
  selectYourShippingAddress: 'Vyberte svou dodací adresu:',
  selectYourDeliveryAddress: 'Zvolte svou dodací adresu:',
  defaultShippingAddress: 'Výchozí adresa pro doručení',
  addNewAddress: 'Přidat novou adresu',
  shipToThisAddress: 'Odeslání na tuto adresu',
  addressSearch: 'Vyhledávání adres',
  addressSearchMinLength: 'Vyhledávaný text by měl mít alespoň {{length}} znaků',
  submitAddressSearch: 'Zadejte',
  errorSearchingSalesforce: 'Momentálně nelze dokončit vyhledávání, zkuste to prosím později',
  deliveryAddressSelected: 'Vybraná doručovací adresa',
};

export const checkoutOrderConfirmation = {
  confirmationOfOrder: 'Potvrzení objednávky:',
  confirmationOfQuote: 'Potvrzení o přijetí nabídky:',
  thankYou: 'Děkujeme za vaši objednávku!',
  thankYouQuote: 'Děkujeme za vaši žádost o cenovou nabídku!',
  invoiceHasBeenSentByEmailLine1: 'Upozorňujeme, že může trvat až jeden pracovní den, než se tato objednávka objeví v historii objednávek. Jakmile se tam objeví, bude obsahovat formální potvrzení objednávky spolu s datem odeslání/vyzvednutí, které potvrdila společnost RATIONAL a kompletní cenové informace včetně příslušných daní.',
  invoiceHasBeenSentByEmailLine2: 'Máte-li mezitím jakékoli dotazy týkající se této objednávky, obraťte se na zákaznický servis společnosti RATIONAL a uveďte výše uvedené ID objednávky.',
  quoteHasBeenSentByEmailLine1: 'Vezměte prosím na vědomí, že vaše požadavky na cenovou nabídku nebudou přeneseny do historie vašich objednávek v internetovém obchodě.',
  quoteHasBeenSentByEmailLine2: 'Pokud máte jakékoli dotazy týkající se tohoto požadavku na cenovou nabídku, kontaktujte prosím svůj zákaznický servis společnosti RATIONAL a uveďte výše uvedené číslo nabídky webového obchodu nebo vaše P.O. číslo.',
  orderItems: 'Položky objednávky',
  orderPlacedSuccessfully: 'Úspěšně podaná objednávka',
  createAccount: 'Vytvořit účet?',
  createAccountForNext: 'Vytvořte si účet <{{email}}> pro rychlejší odbavení při vaší příští návštěvě.',
};

export const checkoutReview = {
  review: 'Shrnutí objednávky',
  orderItems: 'Položky objednávky',
  autoReplenishOrder: 'Automatické doplnění objednávky',
  confirmThatRead: 'Potvrzuji, že jsem si přečetl(a) a souhlasím se zásadami',
  placeOrder: 'Umístit objednávku',
  placeQuoteOrder: 'Požádat o cenovou nabídku',
  scheduleReplenishmentOrder: 'Naplánovat objednávku doplnění',
  orderType_PLACE_ORDER: 'Žádné (pouze jednorázová objednávka)',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Doplnit objednávku',
  termsAndConditions: 'Podmínky a pravidla',
  editShippingAddress: 'Upravit dodací adresu',
  poNumber: 'Číslo nákupní objednávky',
  every: 'Každý',
  startOn: 'Začít na',
  dayOfMonth: 'V den',
  repeatOnDays: 'Opakovat v následujících dnech',
  recurrencePeriodType_DAILY: 'Den (Dny)',
  recurrencePeriodType_WEEKLY: 'Týden(y)',
  recurrencePeriodType_MONTHLY: 'Měsíc',
};

export const checkoutShipping = {
  shippingMethod: 'Způsob přepravy',
  standardDelivery: 'Standardní doručení',
  premiumDelivery: 'Prémiové doručení',
};

export const checkoutPO = {
  noPoNumber: 'Žádné',
  poNumber: 'Číslo vaší objednávky',
  costCenter: 'Nákladové středisko',
  placeholder: 'Zadejte vlastní číslo objednávky...',
  availableLabel: 'Dodací adresy, které máte k dispozici, závisí na jednotce nákladového střediska.',
};

export const checkoutProgress = {
  methodOfPayment: 'Způsob platby',
  deliveryAddress: 'Dodací adresa',
  deliveryMode: 'Způsob doručení',
  paymentDetails: 'Údaje o platbě',
  reviewOrder: 'Shrnutí objednávky',
};

export const checkoutB2B = {
  checkoutB2B: {
  poNumber: 'Číslo vaší objednávky',
  placeholder: 'Zadejte své vlastní číslo objednávky...',
  methodOfPayment: {
    paymentType: 'Typ platby',
  },
  progress: {
    methodOfPayment: 'Způsob platby',
  },
  review: {
    poNumber: 'Číslo vaší nákupní objednávky',
  },
}
};

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: 'Incoterms',
    paymentConditions: 'Platební podmínky',
  }
};

export const checkoutMode = {
  deliveryMethod: 'Způsob dodání',
  deliveryEntries: 'Položky, které mají být odeslány',
  specialShippingInstructions: 'Zvláštní pokyny pro přepravu',
  specialShippingInstructionsPlaceholder: 'Zvláštní pokyny pro přepravu',
};

  export const checkout = {
    checkoutAddress,
    checkoutOrderConfirmation,
    checkoutReview,
    checkoutShipping,
    checkoutPO,
    checkoutProgress,
    checkout: {
      backToCart: 'Zpět do košíku',
      invalid: {
        accountType: 'Váš účet vám neumožňuje provést objednávku zde.'
      }
    },
    checkoutMode
  }
  