export const paymentForm = {
    payment: "支払い",
    choosePaymentMethod: "支払い方法を選択してください。",
    paymentType: "支払いタイプ",
    accountHolderName: {
        label: "口座名義",
        placeholder: "口座名義"
    },
    cardNumber: "カード番号",
    expirationDate: "有効期限",
    securityCode: "セキュリティコード（CVV）",
    securityCodeTitle: "カード検証値",
    saveAsDefault: "デフォルトとして保存",
    setAsDefault: "デフォルトに設定",
    billingAddress: "請求先住所",
    sameAsShippingAddress: "配送先住所と同じ",
    selectOne: "選択してください",
    monthMask: "月",
    yearMask: "年",
    expirationYear: "有効期限年 {{ selected }}",
    expirationMonth: "有効期限月 {{ selected }}",
    useThisPayment: "この支払いを使用する",
    addNewPayment: "新しい支払いを追加",
    changePayment: "お支払いの変更"
};

export const paymentMethods = {
    paymentMethods: "支払い方法",
    newPaymentMethodsAreAddedDuringCheckout: "新しいお支払い方法はチェックアウト時に追加されます。",
    invalidField: "無効なフィールド: {{ field }}"
};

export const paymentCard = {
    deleteConfirmation: "本当にこの支払い方法を削除しますか？",
    setAsDefault: "デフォルトとして設定",
    expires: "有効期限： {{ month }}/{{ year }}",
    defaultPaymentMethod: "✓ デフォルト",
    selected: "選択"
};

export const paymentTypes = {
    title: "支払い方法",
    paymentType_CARD: "クレジットカード",
    paymentType_ACCOUNT: "アカウント",
    paymentType_INVOICE: "請求書"
};

export const payment = {
    paymentForm,
    paymentMethods,
    paymentCard,
    paymentTypes
};
