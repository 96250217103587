<div class="cx-order-summary" *ngIf="order$ | async as order">
  <ng-container *ngIf="!(isOrderLoading$ | async)">
    <div *ngIf="!(simple$ | async)" class="container">
      <ng-container *ngIf="order.replenishmentOrderCode; else otherOrder">
        <div class="cx-summary-card">
          <cx-card
            [content]="
              getReplenishmentCodeCardContent(order?.replenishmentOrderCode)
                | async
            "
          ></cx-card>

          <cx-card
            [content]="getReplenishmentActiveCardContent(order?.active) | async"
          ></cx-card>
        </div>

        <div class="cx-summary-card">
          <cx-card
            [content]="
              getReplenishmentStartOnCardContent(order?.firstDate | cxDate: 'dd.MM.yyyy')
                | async
            "
          ></cx-card>

          <cx-card
            [content]="
              getReplenishmentFrequencyCardContent(
                order?.trigger?.displayTimeTable
              ) | async
            "
          ></cx-card>

          <cx-card
            [content]="
              getReplenishmentNextDateCardContent(
                order?.trigger?.activationTime | cxDate: 'dd.MM.yyyy'
              ) | async
            "
          ></cx-card>

          <!-- TODO:(CXINT-2309) for next major release remove feature level -->
          <ng-container>
            <ng-template
              [cxOutlet]="cartOutlets.ORDER_OVERVIEW"
              [cxOutletContext]="{ item: order, readonly: true }"
            >
            </ng-template>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #otherOrder>
        <div class="cx-summary-card">
          <cx-card
            [content]="getOrderCodeCardContent(order?.code) | async"
          ></cx-card>

          <cx-card
            [content]="
              getOrderCurrentDateCardContent(order?.created | cxDate: 'dd.MM.yyyy') | async
            "
          ></cx-card>

          <cx-card
            [content]="getOrderStatusCardContent(order.statusDisplay) | async"
          ></cx-card>

          <!-- TODO:(CXINT-2309) for next major release remove feature level -->
          <ng-container>
            <ng-template
              [cxOutlet]="cartOutlets.ORDER_OVERVIEW"
              [cxOutletContext]="{ item: order, readonly: true }"
            >
            </ng-template>
          </ng-container>
        </div>
      </ng-template>

      <ng-container
        *ngIf="order.purchaseOrderNumber || order.purchaseOrderNumber === ''"
      >
        <div class="cx-summary-card">
          <cx-card [content]="getPurchaseOrderNumber(order?.purchaseOrderNumber) | async"></cx-card>
          <cx-card [content]="getMethodOfPaymentCardContent(order.paymentInfo) | async"></cx-card>
          <cx-card [content]="getPaymentConditions(order.paymentConditions) | async"></cx-card>
        </div>
      </ng-container>

      <div class="cx-summary-card">
        <ng-container *ngIf="order.deliveryAddress">
          <cx-card
            [content]="getAddressCardContent(order?.deliveryAddress) | async"
          ></cx-card>
        </ng-container>

        <ng-container *ngIf="order.deliveryMode">
          <cx-card
            [content]="getDeliveryModeCardContent(order?.deliveryMode) | async"
          ></cx-card>
        </ng-container>

        <ng-container *ngIf="order.requestedRetrievalAt">
          <cx-card
            [content]="
              getRequestedDeliveryDateContent(getRequestedDeliveryDate(order.requestedRetrievalAt) | cxDate: 'dd.MM.yyyy') | async
            "
          ></cx-card>
        </ng-container>

  </div>
      </div>

      <ng-container *ngIf="order.paymentInfo">
        <ng-container>
          <div
            class="cx-summary-card"
            *ngIf="
              order?.paymentInfo?.billingAddress ||
              isPaymentInfoCardFull(order?.paymentInfo)
            "
          >
            <ng-container *ngIf="isPaymentInfoCardFull(order?.paymentInfo)">
              <cx-card
                [content]="
                  getPaymentInfoCardContent(order?.paymentInfo) | async
                "
              ></cx-card>
            </ng-container>

            <cx-card
              [content]="
                getBillingAddressCardContent(order?.paymentInfo?.billingAddress)
                  | async
              "
            ></cx-card>
          </div>
        </ng-container>

        <ng-container>
          <div class="cx-summary-card">
            <cx-card
              [content]="getPaymentInfoCardContent(order?.paymentInfo) | async"
            ></cx-card>

            <cx-card
              [content]="
                getBillingAddressCardContent(order?.paymentInfo?.billingAddress)
                  | async
              "
            ></cx-card>
          </div>
        </ng-container>
      </ng-container>

    <div *ngIf="simple$ | async" class="container">
      <div class="cx-order-details-cards">
        <cx-card
          [content]="getOrderCodeCardContent(order?.code) | async"
        ></cx-card>

        <cx-card
          [content]="
            getOrderCurrentDateCardContent(order?.created | cxDate: 'dd.MM.yyyy') | async
          "
        ></cx-card>

        <cx-card
          [content]="getOrderStatusCardContent(order.statusDisplay) | async"
        ></cx-card>

        <!-- TODO:(CXINT-2309) for next major release remove feature level -->
        <<ng-container >
          <ng-template
            [cxOutlet]="cartOutlets.ORDER_OVERVIEW"
            [cxOutletContext]="{ item: order, readonly: true }"
          >
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
