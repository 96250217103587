import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { LoggerService, OccEndpointsService, UserIdService, normalizeHttpError } from "@spartacus/core";
import { rationaOCCEndpoints } from "../../rational-backend-occ-endpoint/rational-backend-occ-endpoint.module";
import { Observable, catchError, throwError } from "rxjs";
import { RationalOrderDocument } from "../../model/rational-order-document.model";

@Injectable({
    providedIn: 'root',
  })
export class RationalOrderDocumentService {

    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected userIdService: UserIdService
    ) {}

    protected logger = inject(LoggerService);


    getOrderDocuments(
        documentId: string,
        categoryType: string
      ): Observable<any> {
    
        return this.http.get(this.getDocumentsUrl(documentId, categoryType));
      }
      
      
      downloadDocument(
        document : RationalOrderDocument
      ): Observable<Blob> {
        const options = {
          responseType: 'blob' as 'json',
        };
    
        return this.http
          .get<Blob>(
            this.downloadDocumentUrl(document.DocumentID, document.CategoryType, document.RunningNumber),
            options
          )
          .pipe(
            catchError((error: HttpErrorResponse) =>
              throwError(normalizeHttpError(error, this.logger))
            )
          );
      }


    protected getDocumentsUrl(documentId: any, categoryType: string) {
        const url = this.occEndpoints.buildUrl(rationaOCCEndpoints.orderDocuments.getDocumentsList,
          { urlParams: { documentId, categoryType } });
        return url;
    }

    protected downloadDocumentUrl(documentId: any, categoryType: any, runningNumber: any) {
        const url = this.occEndpoints.buildUrl(rationaOCCEndpoints.orderDocuments.downloadDocument,
          { urlParams: { documentId, categoryType, runningNumber } });
        return url;
    }

    public getOrderTrackingDocuments(
      shippingNumber: string
    ): Observable<Object> {
      let url = '';
  
      this.userIdService.takeUserId().subscribe((userId) => {
        url = this.getOrderTrackingDocumentsUrl(userId, shippingNumber);
      });
  
      return this.http.get(url);
    }
  
    protected getOrderTrackingDocumentsUrl(
      userId: string,
      shippingNumber: string
    ) {
      const url = this.occEndpoints.buildUrl(
        rationaOCCEndpoints.orderTrackingDocuments.getTrackingDocuments,
        { urlParams: { userId, shippingNumber } }
      );
      return url;
    }

}