export const addressForm = {
  title: 'Anrede',
  defaultTitle: 'Anrede',
  firstName: {
    label: 'Vorname',
    placeholder: 'Vorname'
  },
  lastName: {
    label: 'Nachname',
    placeholder: 'Nachname'
  },
  companyName: 'Firmenname',
  companyNamePlaceholder: 'Nach Firmennamen suchen...',
  address1: 'Straße + Hausnummer',
  address2: 'Adresszusatz',
  country: 'Land',
  city: {
    label: 'Stadt',
    placeholder: 'Stadt'
  },
  state: 'Staat',
  zipCode: {
    label: 'Postleitzahl',
    placeholder: 'Postleitzahl'
  },
  phoneNumber: {
    label: 'Telefonnummer (optional)',
    placeholder: '(555) 555 - 0123'
  },
  saveAsDefault: 'Als Standard speichern',
  chooseAddress: 'Adresse wählen',
  streetAddress: 'Straße + Hausnummer',
  aptSuite: 'Adresszusatz',
  selectOne: 'Auswählen...',
  setAsDefault: 'Als Standard speichern',
  titleRequired: 'Anrede ist erforderlich.',
  userAddressAddSuccess: 'Neue Adresse wurde erfolgreich hinzugefügt!',
  userAddressUpdateSuccess: 'Adresse wurde erfolgreich aktualisiert!',
  userAddressDeleteSuccess: 'Adresse wurde erfolgreich gelöscht!',
  invalidAddress: 'Ungültige Adresse'
}

export const addressBook = {
  addNewShippingAddress: 'Neue Lieferanschrift hinzufügen',
  editShippingAddress: 'Lieferanschrift bearbeiten',
  areYouSureToDeleteAddress: 'Möchten Sie diese Adresse wirklich löschen??',
  addNewAddress: 'Neue Adresse hinzufügen',
  addAddress: 'Adresse hinzufügen',
  updateAddress: 'Adresse aktualisieren',
  backToAddressList: 'Zurück zur Adressliste'
}

export const addressCard = {
  default: 'STANDARD',
  selected: 'Ausgewählt',
  setAsDefault: 'Als Standard setzen',
  shipTo: 'Warenempfänger',
  billTo: 'Rechnungsempfänger',
}

export const addressSuggestion = {
  verifyYourAddress: 'Bestätigen Sie Ihre Adresse',
  ensureAccuracySuggestChange: 'Um die Liefergenauigkeit zu gewährleisten, schlagen wir die unten ausgewählte Änderung vor.',
  chooseAddressToUse: 'Bitte wählen Sie die Adresse, die Sie verwenden möchten:',
  suggestedAddress: 'Vorgeschlagene Adresse',
  enteredAddress: 'Eingegebene Adresse',
  editAddress: 'Adresse bearbeiten',
  saveAddress: 'Adresse sichern'
}

export const address = {
  addressForm,
  addressBook,
  addressSuggestion,
  addressCard
}

