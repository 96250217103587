import { NgModule } from '@angular/core';
import {
  BaseCoreModule,
  AuthService,
  I18nModule,
  OAuthLibWrapperService,
  OccEndpointsService,
  BaseOccModule,
  provideConfig,
  CmsConfig,
  NotAuthGuard,
} from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { RationalOAuthLibWrapperService } from './services/oauth-lib-wrapper.service';
import { NullValidationHandler, ValidationHandler } from 'angular-oauth2-oidc';
import { RationalAuthService } from './services/auth.service';
import { RationalIdLoginComponent } from './rational-id-login/rational-id-login.component';
import { OutletPosition, SpinnerModule, provideOutlet } from '@spartacus/storefront';

@NgModule({
  declarations: [
    RationalIdLoginComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    BaseOccModule,
    SpinnerModule
  ],
  providers: [
    BaseOccModule,
    OccEndpointsService,
    {
      provide: ValidationHandler,
      useClass: NullValidationHandler,
    },
    {
      provide: OAuthLibWrapperService,
      useExisting: RationalOAuthLibWrapperService,
    },
    {
      provide: AuthService,
      useExisting: RationalAuthService,
    },
    provideConfig(<CmsConfig> {
      cmsComponents: {
        ReturningCustomerLoginComponent: {
          component: RationalIdLoginComponent,
          guards: [NotAuthGuard]
        }
      }
    })
  ], 
})
export class RationalIdModule {}
