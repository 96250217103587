const visualPicking = {
    visualPickingProductList: {
      id: '产品编号'
    },
    visualPickingProductFilter: {
      input: {
        placeholder: '按名称或产品编号筛选'
      }
    }
  }
  
  export const epdVisualization = {
    epdVisualization: {
      visualPicking
    }
  };
  