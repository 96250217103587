export const pageMetaResolver = {
  category: {
    title: '{{query}} 的 {{count}} 结果',
    title_plural: '{{query}} 的 {{count}} 结果',
  },
  checkout: {
    title: '结账',
  },
  search: {
    title: '{{query}} 的 {{count}} 结果',
    title_plural: '{{query}} 的 {{count}} 结果',
    findProductTitle: '{{coupon}} 优惠券的 {{coupon}} 结果',
    findProductTitle_plural: '{{coupon}} 优惠券的查询结果',
    default_title: '所有产品',
  },
  product: {
    description: '{{description}}',
    heading: '{{heading}}',
    title: '{{title}}',
  },
};

export const spinner = {
  loading: '正在加载...',
};

export const navigation = {
  shopAll: '全部购物 {{ navNode}} >',
  listPrice: {
    enableListPrice: '启用原价',
    listPriceIsEnabled: '原价已启用',
    listPriceIsDisabled: '原价已禁用',
  },
};

export const searchBox = {
  placeholder: '在此搜索...',
  ariaLabelInput: '在此搜索...',
  ariaLabelSuggestions: '输入建议',
  ariaLabelProducts: '产品结果',
  initialDescription:
    '当有自动完成结果时，使用向上和向下箭头查看，并输入选择。触摸设备用户可通过触摸或轻扫手势进行搜索。',
  suggestionsResult: '{{ count }} 建议和',
  suggestionsResult_plural: '{{ count }} 建议和',
  productsResult: '{{ count }} 产品可用。',
  productsResult_plural: '{{ count }} 产品可用。',
  resetLabel: '重置搜索',
  help: {
    insufficientChars: '请输入更多字符',
    noMatch: '我们无法找到任何结果',
    exactMatch: '{{term}}',
    empty: '向我们提出任何问题',
  },
};

export const sorting = {
  date: '日期',
  orderNumber: '订单号',
  rma: '退货编号',
  replenishmentNumber: '补货编号',
  nextOrderDate: '下一次订购日期',
};

export const httpHandlers = {
  badGateway: '服务器出现错误。请稍后再试。',
  badRequestPleaseLoginAgain: '{{ error message }}。请重新登录。',
  badRequestOldPasswordIncorrect: '旧密码不正确。',
  badRequestGuestDuplicateEmail:
    '{{ errorMessage }} 电子邮件已经存在。请使用不同的电子邮件结账，以便使用访客账户注册。',
  conflict: '已存在。',
  forbidden:
    '您无权执行此操作。如果您认为这是个错误，请联系您的管理员。',
  gatewayTimeout: '服务器未响应，请稍后再试。',
  internalServerError:
    '发生服务器内部错误。请稍后再试。',
  sessionExpired: '您的会话已过期。请重新登录。',
  unknownError: '出现未知错误',
  unauthorized: {
    common: '发生未知授权错误',
    invalid_client: '客户端凭证错误',
  },
  validationErrors: {
    missing: {
      card_cardType:
        '不支持所选信用卡。请选择其他信用卡。',
      card_accountNumber:
        '输入的信用卡号无效。',
      card_cvNumber: '输入的安全码无效。',
      card_expirationMonth:
        '输入的信用卡到期日期无效。',
      card_expirationYear:
        '输入的信用卡到期日期无效。',
      billTo_firstName: '输入的名字无效。',
      billTo_lastName: '输入的姓氏无效。',
      billTo_street1: '输入的地址无效。',
      billTo_street2: '输入的地址无效。',
      billTo_city: '输入的城市对该信用卡无效。',
      billTo_state: '输入的州/省对该信用卡无效。',
      billTo_country: '输入的国家/地区对该信用卡无效。',
      billTo_postalCode: '邮政编码对该信用卡无效。',
      country: {
        isocode: '缺少国家/地区',
      },
    },
    invalid: {
      card_expirationMonth:
        '输入的信用卡到期日期无效。',
      firstName: '输入的名无效。',
      lastName: '输入的姓无效。',
      password: '输入的密码无效。',
      uid: 'UID 无效。',
      code: '密码无效。',
      email: '电子邮件无效。',
    },
  },
  cartNotFound: '未找到购物车。',
  invalidCodeProvided: '提供的代码无效。',
};

export const miniCart = {
  item: '您的购物车中当前有 {{count}} 件商品',
  item_plural: '您的购物车中当前有 {{count}} 件商品',
  total: '{{total}}',
  count: '{{count}}',
};

export const skipLink = {
  skipTo: '跳转到',
  labels: {
    header: '标题',
    main: '主要内容',
    footer: '页脚',
    productFacets: '产品面板',
    productList: '产品列表',
  },
};

export const formErrors = {
  globalMessage: '您尝试提交的表单包含错误。',
  required: '该字段为必填字段',
  cxInvalidEmail: '此电子邮件格式无效',
  cxInvalidPassword: '密码格式无效',
  cxPasswordsMustMatch: '密码字段必须匹配',
  cxEmailsMustMatch: '电子邮件字段必须匹配',
  cxStarRatingEmpty: '评级字段为必填字段',
  cxNoSelectedItemToCancel: '至少选择一个项目',
  cxNegativeAmount: '金额必须等于或大于零',
  cxContainsSpecialCharacters: '字段不能包含特殊字符',
  date: {
    required: '该字段为必填字段',
    min: '日期不能在 {{min}} 之前',
    max: '日期不能在 {{max}} 之后',
    pattern: '使用日期格式 年-月-日',
    invalid: '使用有效日期',
  },
  file: {
    invalidQunatity:
      '行中产品数量无效： {{ indexes }}',
    missingArticleNumber:
      '行中缺少文章编号： {{ indexes }}',
    importCartReopenDialogueText:
      '请关闭购物车导入对话框，然后更新文件并重新上传。',
  },
};

export const errorHandlers = {
  scriptFailedToLoad: '加载脚本失败。',
  refreshThePage: '请刷新页面。',
};

export const common = {
  pageMetaResolver,
  spinner,
  navigation,
  searchBox,
  sorting,
  httpHandlers,
  miniCart,
  skipLink,
  formErrors,
  errorHandlers,
  common: {
    cancel: '取消',
    delete: '删除',
    remove: '删除',
    edit: '编辑',
    restore: '恢复',
    back: '返回',
    submit: '提交',
    continue: '继续',
    save: '保存',
    done: '已完成',
    home: '首页',
    noData: '无数据。',
    confirm: '确认',
    more: '更多',
    close: '关闭',
    loading: '正在加载',
    menu: '菜单',
    reset: '重置',
    search: '搜索',
    add: '添加',
    userGreeting: '欢迎',
    privacySettings: '隐私设置',
  },
};
