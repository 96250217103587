export const pageMetaResolver = {
  category: {
    title: '{{count}} Ergebnisse für {{query}}',
    title_plural: '{{count}} Ergebnisse für {{query}}'
  },
  checkout: {
    title: 'Checkout'
  },
  search: {
    title: '{{count}} Ergebnisse für \'{{query}}\'',
    title_plural: '{{count}} Ergebnisse für \'{{query}}\'',
    findProductTitle: '{{count}} Ergebnisse für den Gutschein \'{{coupon}}\'',
    findProductTitle_plural: '{{count}} Ergebnisse für den Gutschein \'{{coupon}}\'',
    default_title: 'Alle Artikel'
  },
  product: {
    description: '{{description}}',
    heading: '{{heading}}',
    title: '{{title}}'
  }
}

export const spinner = {
  loading: 'Wird geladen...'
}

export const navigation = {
  shopAll: 'Alle anzeigen {{ navNode}} >',
  listPrice: {
    enableListPrice: 'Listenpreis aktivieren',
    listPriceIsEnabled: 'Listenpreis ist aktiviert',
    listPriceIsDisabled: 'Listenpreis ist deaktiviert'
  }
}

export const searchBox = {
  placeholder: 'Suche...',
  ariaLabelInput: 'Suche...',
  ariaLabelSuggestions: 'Vorschläge',
  ariaLabelProducts: 'Suchergebnisse',
  initialDescription: 'Prüfen Sie die Ergebnisse und wählen Sie eine Zeile aus.',
  suggestionsResult: '{{ count }} Vorschläge und ',
  suggestionsResult_plural: '{{ count }} Vorschläge und ',
  productsResult: '{{ count }} Artikel sind verfügbar.',
  productsResult_plural: '{{ count }} Artikel sind verfügbar.',
  resetLabel: 'Suche zurücksetzen',
  help: {
    insufficientChars: 'Bitte mehr Zeichen eingeben',
    noMatch: 'Es wurden keine Ergebnisse gefunden',
    exactMatch: '{{ term }}',
    empty: 'Kontaktieren Sie uns'
  }
}

export const sorting = {
  date: 'Datum',
  orderNumber: 'Bestellnummer',
  rma: 'Rücksendenummer',
  replenishmentNumber: 'Nachtragsnummer',
  nextOrderDate: 'Nächster Bestelltermin'
}

export const httpHandlers = {
  badGateway: 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
  badRequestPleaseLoginAgain: '{{ errorMessage }}. Bitte melden Sie sich erneut an.',
  badRequestOldPasswordIncorrect: 'Altes Passwort fehlerhaft.',
  badRequestGuestDuplicateEmail: '{{ errorMessage }} Die E-Mail existiert bereits. Bitte verwenden Sie eine andere E-Mail um sich als Gastkonto zu registrieren.',
  conflict: 'Existiert bereits.',
  forbidden: 'Sie sind nicht berechtigt diese Aktion auszuführen. Bitte kontaktieren die den Kundenservice.',
  gatewayTimeout: 'Der Server reagiert nicht, bitte versuchen Sie es später erneut.',
  internalServerError: 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
  sessionExpired: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
  unknownError: 'Ein unbekannter Fehler ist aufgetreten',
  unauthorized: {
    common: 'Ein unbekannter Berechtigungsfehler ist aufgetreten',
    invalid_client: 'Fehlerhafte Kunden-Zugangsdaten'
  },
  validationErrors: {
    missing: {
      card_cardType: 'Die eingegebene Kreditkarte wird nicht unterstützt. Bitte geben Sie eine andere ein.',
      card_accountNumber: 'Die eingegebene Kreditkartennummer ist ungültig.',
      card_cvNumber: 'Der eingegebene Sicherheitscode ist ungültig.',
      card_expirationMonth: 'Der eingegebene Ablaufmonat ist ungültig.',
      card_expirationYear: 'Das eingegebene Ablaufjahr ist ungültig.',
      billTo_firstName: 'Der eingegebene Vorname ist ungültig.',
      billTo_lastName: 'Der eingegebene Nachname ist ungültig.',
      billTo_street1: 'Die eingegebene Adresse ist ungültig für diese Kreditkarte.',
      billTo_street2: 'Die eingegebene Adresse ist ungültig für diese Kreditkarte.',
      billTo_city: 'Die eingegebene Stadt ist ungültig für diese Kreditkarte.',
      billTo_state: 'Die eingegebene Region ist ungültig für diese Kreditkarte.',
      billTo_country: 'Das eingegebene Land ist ungültig für diese Kreditkarte.',
      billTo_postalCode: 'Die eingegebene Postleitzahl ist nicht gültig für diese Kreditkarte.',
      country: {
        isocode: 'Land fehlt'
      }
    },
    invalid: {
      card_expirationMonth: 'Ein Serverfehler ist aufgetreten.',
      firstName: 'Der eingegebene Vorname ist nicht gültig.',
      lastName: 'Der eingegebene Nachname ist nicht gültig.',
      password: 'Das eingegebene Passwort ist nicht gültig.',
      uid: 'UID ist nicht gültig.',
      code: 'Der Code ist nicht gültig.',
      email: 'Die Email ist nicht gültig.'
    }
  },
  cartNotFound: 'Warenkorb nicht gefunden.',
  invalidCodeProvided: 'Ungültiger Code verwendet.'
}

export const miniCart = {
  item: 'Es befindet sich {{count}} Artikel in Ihrem Warenkorb',
  item_plural: 'Es befinden sich {{count}} Artikel in Ihrem Warenkorb',
  total: '{{total}}',
  count: '{{count}}'
}

export const skipLink = {
  skipTo: 'Überspringen',
  labels: {
    header: 'Überschrift',
    main: 'Hauptinhalt',
    footer: 'Fußzeile',
    productFacets: 'Produkt Facetten',
    productList: 'Produkt Liste'
  }
}

export const formErrors = {
  globalMessage: 'Das Formular enthält Fehler.',
  required: 'Dies ist ein Pflichtfeld',
  cxInvalidEmail: 'Ungültiges E-Mail Format',
  cxInvalidPassword: 'Ungültiges Passwort Format',
  cxPasswordsMustMatch: 'Die Passwörter müssen übereinstimmen',
  cxEmailsMustMatch: 'E-Mails müssen übereinstimmen',
  cxStarRatingEmpty: 'Das Bewertungsfeld ist pflicht',
  cxNoSelectedItemToCancel: 'Mindestens 1 Artikel auswählen',
  cxNegativeAmount: 'Die Menge muss 0 oder größer sein',
  cxContainsSpecialCharacters: 'Das Feld darf keine Sonderzeichen beinhalten',
  date: {
    required: 'Dies ist ein Pflichtfeld',
    min: 'Datum muss größer sein als {{min}}',
    max: 'Datum muss kleiner sein als {{max}}',
    pattern: 'Datumsformat JJJJ-MM-TT verwenden',
    invalid: 'Datum ungültig'
  },
  file: {
    invalidQunatity : 'Ungültige Menge für Produkt in Zeilen: {{ indexes }}',
    missingArticleNumber : 'Fehlende Artikelnummer in der Zeilen: {{ indexes }}',
    importCartReopenDialogueText: 'Bitte schließen Sie das Import Pop-Up, prüfen und aktualisieren Sie Ihre Datei und versuchen es erneut.'
  }
}

export const errorHandlers = {
  scriptFailedToLoad: 'Fehler beim laden des Skripts.',
  refreshThePage: 'Bitte Seite aktualiseren.'
}

export const common = {
  pageMetaResolver,
  spinner,
  navigation,
  searchBox,
  sorting,
  httpHandlers,
  miniCart,
  skipLink,
  formErrors,
  errorHandlers,
  common : {
    cancel: 'Abbrechen',
    delete: 'Löschen',
    remove: 'Entfernen',
    edit: 'Bearbeiten',
    restore: 'Wiederherstellen',
    back: 'Zurück',
    submit: 'Bestätigen',
    continue: 'Fortsetzen',
    save: 'Speichern',
    done: 'Erledigt',
    home: 'Startseite',
    noData: 'Keine Daten.',
    confirm: 'Bestätigen',
    more: 'weitere',
    close: 'Schließen',
    loading: 'Wird geladen',
    menu: 'Menü',
    reset: 'Zurücksetzen',
    search: 'Suche',
    add: 'Hinzufügen',
    userGreeting: 'Willkommen',
    privacySettings: 'Tracking Einstellungen'
  }
}
