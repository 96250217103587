export const checkoutAddress = {
  shippingAddress: 'Endereço de entrega',
  selectYourShippingAddress: 'Selecione seu endereço de entrega:',
  selectYourDeliveryAddress: 'Selecione seu endereço de entrega:',
  defaultShippingAddress: 'Endereço de entrega padrão',
  addNewAddress: 'Adicionar novo endereço',
  shipToThisAddress: 'Enviar para este endereço',
  addressSearch: 'Pesquisa de endereço',
  addressSearchMinLength: 'O texto da pesquisa deve ter pelo menos {{length}} caracteres',
  submitAddressSearch: 'Inserir',
  errorSearchingSalesforce: 'Não é possível concluir a pesquisa no momento; por favor, tente novamente mais tarde',
  deliveryAddressSelected: 'Endereço de entrega selecionado',
};

export const checkoutOrderConfirmation = {
  confirmationOfOrder: 'Confirmação do pedido:',
  confirmationOfQuote: 'Confirmação do recebimento da cotação:',
  thankYou: 'Obrigado por seu pedido!',
  thankYouQuote: 'Obrigado por sua solicitação de cotação!',
  invoiceHasBeenSentByEmailLine1: 'Observe que pode levar até um dia útil para que esse pedido apareça em seu histórico de pedidos. Uma vez lá, ele incluirá a confirmação formal do pedido juntamente com a data de envio/coleta confirmada pela RATIONAL e as informações completas sobre preços, incluindo os impostos aplicáveis.',
  invoiceHasBeenSentByEmailLine2: 'Se, entretanto, tiver alguma dúvida sobre esse pedido, entre em contato com o serviço de atendimento ao cliente da RATIONAL e faça referência ao ID do pedido mencionado acima.',
  quoteHasBeenSentByEmailLine1: 'Observe que suas solicitações de cotação não serão transferidas para seu histórico de pedidos na loja virtual.',
  quoteHasBeenSentByEmailLine2: 'SE houver dúvidas a respeito desta solicitação de cotação, favor contatar o responsável por seu atendimento na RATIONAL e mencione o número da cotação da loja virtual mencionado acima ou o número do seu pedido de compra.',
  orderItems: 'Itens do pedido',
  orderPlacedSuccessfully: 'Pedido feito com sucesso',
  createAccount: 'Criar uma conta?',
  createAccountForNext: 'Crie uma conta para <{{email}}> para um checkout mais rápido em sua próxima visita.',
};

export const checkoutReview = {
  review: 'Resumo do pedido',
  orderItems: 'Itens do pedido',
  autoReplenishOrder: 'Pedido de reabastecimento automático',
  confirmThatRead: 'Estou confirmando que li e concordei com os termos de',
  placeOrder: 'Fazer pedido',
  placeQuoteOrder: 'Solicitar cotação',
  scheduleReplenishmentOrder: 'Programar pedido de reabastecimento',
  orderType_PLACE_ORDER: 'Nenhum (pedido único)',
  orderType_SCHEDULE_REPLENISHMENT_ORDER: 'Pedido de reabastecimento',
  termsAndConditions: 'Termos e Condições',
  editShippingAddress: 'Editar endereço de entrega',
  poNumber: 'Número do pedido de compra',
  every: 'Todos os pedidos',
  startOn: 'Início em',
  dayOfMonth: 'No dia',
  repeatOnDays: 'Repetir nos seguintes dias',
  recurrencePeriodType_DAILY: 'Dia(s)',
  recurrencePeriodType_WEEKLY: 'Semana(s)',
  recurrencePeriodType_MONTHLY: 'Mês',
};

export const checkoutShipping = {
  shippingMethod: 'Método de envio',
  standardDelivery: 'Entrega padrão',
  premiumDelivery: 'Entrega Premium',
};

export const checkoutPO = {
  noPoNumber: 'Nenhum',
  poNumber: 'Seu número de pedido de compra',
  costCenter: 'Centro de custos',
  placeholder: 'Digite seu próprio número de ordem de compra...',
  availableLabel: 'Os endereços de remessa disponíveis para você dependem da unidade do centro de custo',
};

export const checkoutProgress = {
  methodOfPayment: 'Método de pagamento',
  deliveryAddress: 'Endereço de remessa',
  deliveryMode: 'Modo de entrega',
  paymentDetails: 'Detalhes do pagamento',
  reviewOrder: 'Resumo do pedido',
};

export const checkoutB2B = {
  checkoutB2B: {
  poNumber: 'Seu número de ordem de compra',
  placeholder: 'Digite seu próprio número de ordem de compra...',
  methodOfPayment: {
    paymentType: 'Tipo de pagamento',
  },
  progress: {
    methodOfPayment: 'Método de pagamento',
  },
  review: {
    poNumber: 'Seu número de ordem de compra',
  },
}
};

export const checkoutTermsAndConditions = {
  checkoutTermsAndConditions: {
    incoterms: 'Incoterms',
    paymentConditions: 'Condições de pagamento',
  }
};

export const checkoutMode = {
  deliveryMethod: 'Método de entrega',
  deliveryEntries: 'Itens a serem enviados',
  specialShippingInstructions: 'Instruções especiais de remessa',
  specialShippingInstructionsPlaceholder: 'Instruções especiais de remessa',
};

export const checkout = {
  checkoutAddress,
  checkoutOrderConfirmation,
  checkoutReview,
  checkoutShipping,
  checkoutPO,
  checkoutProgress,
  checkout: {
    backToCart: 'Voltar ao carrinho',
    invalid: {
      accountType: 'Sua conta não permite que você faça o checkout aqui.',
    },
  },
  checkoutMode,
};
