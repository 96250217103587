import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class LoadingOverlayService {

  private isLoading$: Subject<boolean> = new ReplaySubject();

  constructor() {
    this.isLoading$.next(false);
  }

  isLoading(): Observable<boolean> {
    return this.isLoading$;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);
  }
}