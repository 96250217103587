export const cartDetails = {
  id: 'ID',
  proceedToCheckout: 'Procéder au paiement ',
  createQuoteFromCart: 'Demander un devis',
  cartName: 'Panier #{{code}}',
  minOrderNotReached: 'La valeur minimale de la commande est de {{minOrderValue}}{{currency}}.',
  orderLocked: 'Attention : Vous ne pouvez pas passer de commandes pour le moment ! Veuillez contacter votre service clientèle. Il y a des problèmes avec ce compte d\'utilisateur.',
  requestedRetrievalDateEmpty: 'Veuillez sélectionner une date d\'expédition souhaitée',
  requestedRetrievalDateAdded: 'Vous avez ajouté avec succès une date d\'expédition',
  requestedRetrievalDateInvalid: 'La date d\'expédition demandée n\'est pas valide',
  s4omScheduleLinesQuantity: 'Qté',
  s4omScheduleLinesEntryInfo: 'La date d\'expédition/de retrait confirmée de - {{ quantity }} article(s) - est {{ date }}.',
  cartSubjectToPriceChange: 'Attention : Les prix des appareils nouvellement configurés sont des prix catalogue. Vous recevrez votre prix spécifique avec la confirmation de la commande.'
}

export const cartItems = {
  id: 'Numéro d\'article',
  description: 'Description de l\'article',
  item: 'Article',
  itemPrice: 'Prix de l\'article',
  quantity: 'Qté',
  equipmentNumber: 'Numéro d\'équipement : ',
  quantityTitle: 'La quantité représente le nombre total de cet article dans votre panier.',
  total: 'Total',
  cartTotal: 'Total du panier ({{count}} article)',
  cartTotal_plural: 'Total du panier ({{count}} articles)',
  actions: 'Actions',
  salesUnit: 'UOM',
  reference: 'Référence :',
  referencePlaceHolder: 'Référence',
  additionalContactEmail: 'Courriel du contact supplémentaire : / E-mail du contact',
  additionalContactEmailPlaceHolder: 'Courriel du contact supplémentaire : / E-mail du contact',
  comment: 'Commentaire :',
  commentPlaceHolder: 'Commentaire',
  additionalContactEmailWarning: 'Veuillez saisir une adresse électronique valide. (Les adresses électroniques non valides ne seront pas enregistrées). ',
  requestedDeliveryDate: 'Date d\'envoi/de prise en charge demandée :',
  requestedDeliveryDateTitle: 'Date d\'envoi/de prise en charge demandée',
  substitutionProductNotFound: 'Ce produit est remplacé par un autre, mais le produit remplacé n\'est pas disponible actuellement.',
}

export const orderCost = {
  orderSummary: 'Résumé de la commande',
  subtotal: 'Sous-total :',
  shipping: 'Expédition :',
  estimatedShipping: 'Estimation des frais de port :',
  discount: 'Vous avez économisé :',
  salesTax: 'Taxe de vente :',
  grossTax: 'Le total de la commande n\'inclut pas la taxe de',
  grossIncludeTax: 'Le total de la commande inclut une taxe de',
  total: 'Total:',
  toBeDetermined: 'TBD',
  requestedDeliveryDate: 'Date d\'envoi/de prise en charge demandée*',
  taxStatement: '*Tous les prix indiqués sont des prix nets hors taxes spécifiques au client. Vous serez informé de votre prix net total TTC final par e-mail avec la confirmation de commande, car les prix nets actualisés quotidiennement sont recalculés au moment de la commande.',
  taxStatement2: 'Lors de la commande d\'accessoires ou de pièces détachées, un supplément pour quantité minimale peut être perçu si la valeur nette hors TVA n\'atteint pas la valeur minimale de la commande.',
}

export const voucher = {
  coupon: 'Vous avez un bon de réduction ?',
  coupon_plural: 'Codes de réduction',
  apply: 'Appliquer',
  placeholder: 'Code promo',
  applyVoucherSuccess: '{{voucherCode}} a été appliqué.',
  removeVoucherSuccess: '{{voucherCode}} a été supprimé.',
  anchorLabel: 'Saisissez ou supprimez votre code promo',
  vouchersApplied: 'Coupons appliqués',
  availableCoupons: 'Coupons disponibles',
  availableCouponsLabel: 'Vous pouvez ajouter ces coupons à cette commande.',
}

export const saveForLaterItems = {
  itemTotal: 'Sauvegardé pour plus tard ({{count}} item)',
  itemTotal_plural: 'Sauvegardé pour plus tard ({{count}} articles)',
  cartTitle: 'Panier',
  saveForLater: 'Sauvegarder pour plus tard',
  moveToCart: 'Déplacer vers le panier',
  stock: 'En stock',
  forceInStock: 'En stock',
}

export const cart = {
  cartDetails,
  cartItems,
  orderCost,
  voucher,
  saveForLaterItems
}

