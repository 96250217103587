import { Component, Input } from '@angular/core';
import { ProductImageZoomThumbnailsComponent } from '@spartacus/product/image-zoom/components';
import { ThumbnailsGroup } from '@spartacus/product/image-zoom/root';
import { Observable } from 'rxjs';

@Component({
  selector: 'cx-product-image-zoom-thumbnails',
  templateUrl: './rational-product-image-zoom-thumbnails.component.html',
  styleUrls: ['./rational-product-image-zoom-thumbnails.component.scss']
})
export class RationalProductImageZoomThumbnailsComponent extends ProductImageZoomThumbnailsComponent {

  @Input() customThumbnails$: Observable<Observable<ThumbnailsGroup>[]> | undefined;

}
