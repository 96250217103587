export const cartDetails = {
  id: 'ID',
  proceedToCheckout: 'Fortsätt till kassan',
  createQuoteFromCart: 'Begär offert',
  cartName: 'Kundvagn #{{code}}',
  minOrderNotReached: 'Det lägsta ordervärdet är {{minOrderValue}}{{valcurrencyuta}}.',
  orderLocked: 'Var uppmärksam på detta: Du kan inte göra beställningar för tillfället! Vänligen kontakta din kundtjänst. Det finns problem med detta användarkonto.',
  requestedRetrievalDateEmpty: 'Du måste välja ett önskat leveransdatum',
  requestedRetrievalDateAdded: 'Du har framgångsrikt lagt till ett önskat leveransdatum',
  requestedRetrievalDateInvalid: 'Det begärda leveransdatumet är ogiltigt',
  s4omScheduleLinesQuantity: 'Antal',
  s4omScheduleLinesEntryInfo: 'Bekräftat avsändnings-/upphämtningsdatum för - {{ quantity }} artikel(-er) - är {{ date }}',
  cartSubjectToPriceChange: 'Observera! Priserna för nykonfigurerade enheter är listpriser. Du kommer att få ditt kundspecifika pris med den formella orderbekräftelsen.',
};

export const cartItems = {
  id: 'Artikelnummer',
  description: 'Beskrivning',
  item: 'Artikel',
  itemPrice: 'Artikel pris',
  quantity: 'Antal',
  equipmentNumber: 'Serienummer',
  quantityTitle: 'Kvantiteten representerar det totala antalet av denna artikel i din kundvagn.',
  total: 'Totalt antal',
  cartTotal: 'Totalt i varukorgen ({{count}} artiklar)',
  cartTotal_plural: 'Totalt antal i varukorgen ({{count}} artiklar)',
  actions: 'Åtgärder',
  salesUnit: 'UOM',
  reference: 'Referens:',
  referencePlaceHolder: 'Referens',
  comment: 'Kommentar:',
  commentPlaceHolder: 'Kommentar',
  additionalContactEmailWarning: 'Vänligen ange en giltig e-postadress. (Ogiltiga e-postadresser sparas inte.)',
  requestedDeliveryDate: 'Önskat avsändnings-/upphämtningsdatum:',
  requestedDeliveryDateTitle: 'Önskat avsändnings-/upphämtningsdatum:',
  substitutionProductNotFound: 'Denna produkt är ersatt med en annan produkt, men den ersatta produkten är för närvarande inte tillgänglig',
};

export const orderCost = {
  orderSummary: 'Sammanfattning av order',
  subtotal: 'Delsumma',
  shipping: 'Frakt:',
  estimatedShipping: 'Beräknad frakt:',
  discount: 'Du har sparat:',
  salesTax: 'Omsättningsskatt:',
  grossTax: 'Orderns totalbelopp inkluderar inte skatt på',
  grossIncludeTax: 'Orderns totalbelopp inkluderar skatt på',
  total: 'Totalt antal:',
  toBeDetermined: 'TBD',
  requestedDeliveryDate: 'Önskat avsändnings-/hämtningsdatum*',
  taxStatement: '*Alla priser som visas är kundspecifika nettopriser exklusive skatter. Du kommer att informeras om ditt slutliga totala nettopris inklusive skatter via e-post med orderbekräftelsen, eftersom de dagligen uppdaterade nettopriserna omräknas vid beställningstillfället.',
  taxStatement2: 'Vid beställning av tillbehör eller reservdelar kan ett minimikvantitetspåslag tas ut om nettovärdet exklusive moms inte når upp till det lägsta ordervärdet.',
};

export const saveForLaterItems = {
  itemTotal: 'Sparas till senare ({{count}} artiklar)',
  itemTotal_plural: 'Sparad till senare ({{count}} artiklar)',
  cartTitle: 'Kundvagn',
  saveForLater: 'Spara för senare',
  moveToCart: 'Flytta till kundvagn',
  stock: 'Lager',
  forceInStock: 'I lager',
};

  export const cart = {
    cartDetails,
    cartItems,
    orderCost,
    saveForLaterItems
  }