export const cartDetails = {
  id: 'ID',
  proceedToCheckout: '继续结账',
  createQuoteFromCart: '请求报价',
  cartName: '购物车 #{{code}}',
  minOrderNotReached: '最低订单金额为 {{minOrderValue}}{{currency}}。',
  orderLocked: '请注意 您目前无法下订单！请联系您的客户服务人员。此用户账户存在问题。',
  requestedRetrievalDateEmpty: '您必须选择要求的交货日期',
  requestedRetrievalDateAdded: '您已成功添加了要求的交货日期',
  requestedRetrievalDateInvalid: '请求的交货日期无效',
  s4omScheduleLinesQuantity: '数量',
  s4omScheduleLinesEntryInfo: '确认 - {{ quantity }} 项目的发货/取货日期 - 是 {{ date }}',
  cartSubjectToPriceChange: '注意： 新配置设备的价格为清单价格。您将在正式订单确认时收到客户指定的价格。',
};

export const cartItems = {
  id: '产品编号',
  description: '产品描述',
  item: '物品',
  itemPrice: '物品价格',
  quantity: '数量',
  equipmentNumber: '设备编号：',
  quantityTitle: '该数量表示您购物车中此商品的总数量。',
  total: '总计',
  cartTotal: '购物车总计（{{count}} 项目）',
  cartTotal_plural: '购物车总计（{{count}} 项目）',
  actions: '操作',
  salesUnit: '用户名',
  reference: '参考：',
  referencePlaceHolder: '参考',
  comment: '备注',
  commentPlaceHolder: '备注',
  additionalContactEmailWarning: '请输入有效的电子邮件地址。(无效电子邮件将不会被保存）。',
  requestedDeliveryDate: '要求的发货/取货日期',
  requestedDeliveryDateTitle: '要求的发货/取货日期',
  substitutionProductNotFound: '该产品被其他产品替代，但替代产品目前没有供货',
};

export const orderCost = {
  orderSummary: '订单摘要',
  subtotal: '小计',
  shipping: '运费：',
  estimatedShipping: '预计运费：',
  discount: '您节省了',
  salesTax: '销售税：',
  grossTax: '订单总额不包括增值税',
  grossIncludeTax: '订单总额包含增值税',
  total: '总计',
  toBeDetermined: '待定',
  requestedDeliveryDate: '要求的发货/提货日期*',
  taxStatement: '*显示的所有价格均为客户指定的不含税净价。',
  taxStatement2: '*所有显示的价格均为客户所定的商品的不含税净价。由于净价会在订购时重新计算，因此您将通过电子邮件取得订单确认函，并获知最终的含税总价。',
};

export const saveForLaterItems = {
  itemTotal: '稍后保存（{{count}} 项目）',
  itemTotal_plural: '稍后保存（{{count}} 项目）',
  cartTitle: '购物车',
  saveForLater: '稍后保存',
  moveToCart: '移至购物车',
  stock: '库存',
  forceInStock: '有库存',
};

  export const cart = {
    cartDetails,
    cartItems,
    orderCost,
    saveForLaterItems
  }