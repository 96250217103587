export const quickOrderCartForm = {
    entriesWasAdded: "({{ quantity }}) {{ product }} 已加入购物车",
    entryWasAdded: "{{ product }} 已添加到购物车",
    noResults: "我们找不到任何产品",
    stockLevelReached: "已达到最大库存量",
    title: "快速加入购物车",
    productCodePlaceholder: "输入产品编号",
    entryProductCode: "输入产品编号以便快速订购",
    addToCart: "快速添加到购物车",
    product: "产品",
    products: "产品",
    productCodeLabel: "产品ID",
    quantityLabel: "数量",
    placeholder: "输入产品 SKU",
    listLimitReached: "已达到产品上限",
    id: "产品编号 {{ id }}"
};

export const quickOrderList = {
    addToCart: "添加到购物车",
    emptyList: "清空列表",
    header: "添加产品/Skus",
    subHeader: "每次最多可添加 {{ limit }} 有效的 SKU。",
    errorProceedingToCart: "进入购物车时出错。",
    warningProceedingToCart: "进入购物车时出现警告。",
    successfullyAddedToCart: "成功添加到购物车。",
    errors: {
        productIsOutOfStock: "{{name }} (#{{ code }}) 已缺货。"
    },
    warnings: {
        productWasReduced: "{{name }} (#{{ code }})的数量减少至 {{ 已添加数量 }}。"
    },
    successes: {
        productAddedToCart: "{{name }} (#{{ code }}) 已加入购物车。"
    }
};

export const quickOrderTable = {
    product: "产品",
    actions: "操作",
    id: "产品编号",
    price: "价格",
    quantity: "数量",
    itemPrice: "项目价格",
    qty: "数量"
};

export const quickOrder = {
    quickOrderCartForm,
    quickOrderList,
    quickOrderTable
};
