export const cartDetails = {
    id: 'KIMLIK',
    proceedToCheckout: 'Ödeme İşlemine Geçin',
    createQuoteFromCart: 'Teklif İste',
    cartName: 'Sepet #{{code}}',
    minOrderNotReached: 'Minimum sipariş değeri {{minOrderValue}}{{currency}}\'dir.',
    orderLocked: 'Dikkat! Şu anda sipariş veremezsiniz! Lütfen müşteri hizmetlerinizle iletişime geçin. Bu kullanıcı hesabıyla ilgili sorunlar var.',
    requestedRetrievalDateEmpty: 'Talep edilen bir teslimat tarihi seçmelisiniz',
    requestedRetrievalDateAdded: 'İstenen teslim tarihini başarıyla eklediniz',
    requestedRetrievalDateInvalid: 'Talep edilen teslimat tarihi geçersiz',
    s4omScheduleLinesQuantity: 'Miktar',
    s4omScheduleLinesEntryInfo: '{{ quantity }} kalem(ler)inin onaylanan sevkiyat/teslim alma tarihi {{date }}\'tir',
    cartSubjectToPriceChange: 'Dikkat! Yeni yapılandırılan cihazlar için fiyatlar liste fiyatlarıdır. Resmi sipariş onayı ile birlikte müşteriye özel fiyatınızı alacaksınız.',
  };
  
  export const cartItems = {
    id: 'Makale Numarası',
    description: 'Açıklama',
    item: 'Öğe',
    itemPrice: 'Ürün fiyatı',
    quantity: 'Miktar',
    equipmentNumber: 'Ekipman Numarası: ',
    quantityTitle: 'Miktar, sepetinizdeki bu öğenin toplam sayısını temsil eder.',
    total: 'Toplam',
    cartTotal: 'Sepet toplamı ({{count}} öğe)',
    cartTotal_plural: 'Sepet toplamı ({{count}} öğeler)',
    actions: 'Eylemler',
    salesUnit: 'UOM',
    reference: 'Referans:',
    referencePlaceHolder: 'Referans',
    comment: 'Yorum yap:',
    commentPlaceHolder: 'Yorum',
    additionalContactEmailWarning: 'Lütfen geçerli bir E-posta adresi girin. (Geçersiz e-postalar kaydedilmeyecektir.) ',
    requestedDeliveryDate: 'Talep Edilen Sevk/Teslim Alma Tarihi:',
    requestedDeliveryDateTitle: 'Talep Edilen Sevk/Teslim Alma Tarihi',
    substitutionProductNotFound: 'Bu ürün başka bir ürünle ikame edilmiştir, ancak ikame edilen ürün şu anda mevcut değildir',
  };
  
  export const orderCost = {
    orderSummary: 'Sipariş Özeti',
    subtotal: 'Alt toplam:',
    shipping: 'Nakliye:',
    estimatedShipping: 'Tahmini gönderim:',
    discount: 'Kaydettin:',
    salesTax: 'Satış Vergisi:',
    grossTax: 'Sipariş toplamına aşağıdaki vergiler dahil değildir',
    grossIncludeTax: 'Sipariş toplamına aşağıdaki vergiler dahildir',
    total: 'Toplam:',
    toBeDetermined: 'TBD',
    requestedDeliveryDate: 'Talep Edilen Sevk/Teslim Alma Tarihi*',
    taxStatement: '*Gösterilen tüm fiyatlar vergiler hariç müşteriye özel net fiyatlardır. Günlük olarak güncellenen net fiyatlar sipariş sırasında yeniden hesaplandığından, vergiler dahil nihai toplam net fiyatınız sipariş onayı ile birlikte e-posta yoluyla size bildirilecektir.',
    taxStatement2: 'Aksesuar veya yedek parça siparişi verirken, KDV hariç net değer minimum sipariş değerine ulaşmazsa minimum miktar ek ücreti alınabilir.',
  };
  
  export const saveForLaterItems = {
    itemTotal: 'Daha sonrası için kaydedildi ({{count}} öğe)',
    itemTotal_plural: 'Daha sonrası için kaydedildi ({{count}} öğeler)',
    cartTitle: 'Araba',
    saveForLater: 'Daha Sonrası İçin Kaydet',
    moveToCart: 'Sepete Taşı',
    stock: 'Stok',
    forceInStock: 'Stokta var',
  };
  
  export const cart = {
    cartDetails,
    cartItems,
    orderCost,
    saveForLaterItems
  };
  