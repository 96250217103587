import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActiveCartFacade } from "@spartacus/cart/base/root";
import { OccEndpointsService } from "@spartacus/core";
import { Observable } from "rxjs";
@Injectable({
    providedIn: 'root',
})
export class RationalCartItemUpdateService {
    cartId: string | undefined;
    constructor(protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected activeCartService: ActiveCartFacade) {}
    
    public updateOrderEntry(entryNumber: number, reference: string) : Observable<any> {
        const url = this.occEndpoints.buildUrl('carts/updateReference');
        this.activeCartService.getActiveCartId().subscribe(value => {
            this.cartId = value;
        })
        const formData = new FormData();
        formData.append('entryNumber', entryNumber.toString());
        formData.append('reference', reference);
        formData.append('cartId', this.cartId!)
        return this.http.post(url, formData);
    }

    public updateSavedCartOrderEntry(entryNumber: number, reference: string, cartId: string) : Observable<any> {
        const url = this.occEndpoints.buildUrl('carts/updateReference');
        const formData = new FormData();
        formData.append('entryNumber', entryNumber.toString());
        formData.append('reference', reference);
        formData.append('cartId', cartId)
        return this.http.post(url, formData);
    }
    
    public updateCartComment(comment: string) : Observable<any> {
        const url = this.occEndpoints.buildUrl('carts/updateCartComment');
        this.activeCartService.getActiveCartId().subscribe(value => {
            this.cartId = value;
        })
        const formData = new FormData();
        formData.append('comment', comment);
        formData.append('cartId', this.cartId!)
        return this.http.post(url, formData);
    }
    
    public updateCartAdditionalContactEmail(additionalContactEmail: string) : Observable<any> {
        const url = this.occEndpoints.buildUrl('carts/updateCartAdditionalContactEmail');
        this.activeCartService.getActiveCartId().subscribe(value => {
            this.cartId = value;
        })
        const formData = new FormData();
        formData.append('additionalContactEmail', additionalContactEmail);
        formData.append('cartId', this.cartId!)
        return this.http.post(url, formData);
    }
    
    public updateCartSpecialShippingInstructions(specialShippingInstructions: string) : Observable<any> {
        const url = this.occEndpoints.buildUrl('carts/updateCartSpecialShippingInstructions');
        this.activeCartService.getActiveCartId().subscribe(value => {
            this.cartId = value;
        })
        const formData = new FormData();
        formData.append('specialShippingInstructions', specialShippingInstructions);
        formData.append('cartId', this.cartId!)
        return this.http.post(url, formData);
    }


}