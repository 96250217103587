export const savedCartDetails = {
  cartName: 'Nom de l\'utilisateur',
  cartDescription: 'Description',
  cartId: 'ID',
  dateSaved: 'Date d\'enregistrement',
  items: 'Articles',
  quantity: 'Quantité',
  total: 'Total',
  editSavedCart: 'Modifier le panier sauvegardé',
  deleteSavedCart: 'Supprimer le panier enregistré',
  addSelectedItems: 'Ajouter les articles sélectionnés',
  emptyCartItems: 'Vider le panier'
}

export const savedCartList = {
  savedCarts: 'Paniers sauvegardés ({{count}})',
  cartName: 'Nom',
  cartId: 'Id',
  dateSaved: 'Date d\'enregistrement',
  cartDescription: 'Description de l\'article',
  quantity: 'Qté',
  total: 'Total',
  actions: 'Actions',
  makeCartActive: 'Activer le panier',
  notFound: 'Aucun panier enregistré n\'a été trouvé',
  swapCartNoActiveCart: 'Le panier existant a été activé avec succès.',
  swapCartWithActiveCart: 'Le panier existant est activé par {{cartName}} avec succès. Vos articles précédents ont été sauvegardés dans un panier {{previousCartName}}.'
}

export const savedCartCartPage = {
  messages: {
    cartSaved: 'Les articles de votre panier ont été sauvegardés avec succès pour une utilisation ultérieure dans le panier « {{cartName}} ».'
  },
  back: 'Retour à l\'aperçu'
}

export const savedCartDialog = {
  saveForLater: 'Enregistrer pour plus tard',
  itemsSavedForLater: 'Tous les articles de votre panier seront sauvegardés pour plus tard.',
  savedCartName: 'Nom du panier sauvegardé',
  savedCartDescription: 'Description du panier enregistré',
  optional: 'optionnel',
  charactersLeft: 'caractères restants : {{count}}',
  cancel: 'Annuler',
  save: 'Sauvegarder',
  restore: 'Restaurer',
  followingCartDelete: 'Le panier sauvegardé suivant sera supprimé',
  followingCartRestore: 'Le panier sauvegardé suivant sera restauré en tant que panier actif',
  delete: 'Supprimer',
  deleteCartSuccess: 'Panier supprimé avec succès',
  editCartSuccess: 'Panier modifié avec succès',
  editSavedCart: 'Modifier le panier sauvegardé',
  deleteSavedCart: 'Supprimer le panier sauvegardé',
  restoreSavedCart: 'Restaurer le panier sauvegardé',
  name: 'Nom de l\'utilisateur',
  id: 'ID',
  description: 'Description du panier',
  quantity: 'QTÉ',
  total: 'Total',
  keepCopySavedCart: 'Conserver une copie de ce panier dans la liste des paniers sauvegardés',
  isSharedCart: 'Partager ce panier avec votre organisation',
  defaultCloneCartName: 'Copie de {{name}}',
  nameOfCloneCart: 'Nom du panier copié'
}

export const addToSavedCart = {
  savedCarts: 'Chariots sauvegardés',
  saveCartForLater: 'Sauvegarder le panier pour plus tard',
  sharedCartMessageStart :'Un panier partagé est disponible, créé par  {{userName}} ({{userMail}}). Cliquez ',
  sharedCartMessageMiddle :'ici',
  sharedCartMessageEnd :' pour activer le panier partagé. L\'activation du panier partagé le supprimera du niveau de l\'entreprise jusqu\'à ce que vous le partagiez à nouveau, et écrasera également votre panier personnel actuel.'
}

export const savedCart = {
  savedCartDetails,
  savedCartList,
  savedCartCartPage,
  savedCartDialog,
  addToSavedCart
}
