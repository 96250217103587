import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { RationalCurrentProductService } from '../../rational-current-product.service';
import { ProductDownloadData, ProductDownloadsResponse } from '../product-downloads-response';
import { ProductDownloadsService } from '../product-downloads.service';
import { switchMap, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Language, LanguageService } from '@spartacus/core';

@Component({
  selector: 'app-product-downloads',
  templateUrl: './product-downloads.component.html',
  styleUrls: ['./product-downloads.component.scss']
})
export class ProductDownloadsComponent implements OnInit {
  productDownloadsResponse$: Observable<ProductDownloadsResponse> | undefined = undefined;
  productCode!: string;
  language$: string | undefined = undefined;
  selectedLanguage!: string;
  languages: Language[] = [];




  constructor(
    private currentProductService: RationalCurrentProductService,
    private productDownloadsService: ProductDownloadsService,
    private languageService: LanguageService,
    protected httpClient: HttpClient
  ) { }




  ngOnInit() {
    this.productDownloadsResponse$ = combineLatest([
      this.currentProductService.getCurrentProductCode(),
      this.languageService.getActive()
    ]).pipe(
      switchMap(([productCode, langIsoCode]) => {
        this.selectedLanguage = langIsoCode;
        this.productCode = productCode;
        return this.productDownloadsService.getProductDownloads(productCode, langIsoCode);
      }),
      tap((productDownloadsResponse) => {
        this.languages = productDownloadsResponse.languages;
        this.sortLanguages(productDownloadsResponse.languages);
        this.setSelectedLanguage();
      })
    );
  }


  sortLanguages(languages: any[]): any[] {
    return languages.sort((a, b) => a.name.localeCompare(b.name));
  }

  setSelectedLanguage() {
    const languageExists = this.languages.some(lang => {
      return lang.isocode === this.selectedLanguage;
    });
    this.selectedLanguage = languageExists ? this.selectedLanguage : 'mul';
  }


  getProductDownloads(selectedLanguage: string) {
    this.productDownloadsResponse$ = this.productDownloadsService.getProductDownloads(this.productCode, selectedLanguage).pipe(
      map(data => {
        this.sortLanguages(data.languages);
        return data;
      })
    );
  }

  openDocument(documentData: ProductDownloadData) {
    let url = documentData.link;
    const accessCredentials = environment.censhareUserName + ':' + environment.censharePassword;

    const authenticationValue: string = btoa(accessCredentials);
    const headers = new HttpHeaders().set(
      'Authorization',
      'Basic ' + authenticationValue
    );

    url = url.replace('http:/', 'https:/');
    this.httpClient
      .get(<string>url, { headers, responseType: 'blob', observe: 'response' })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: 'application/pdf' });
        })
      )
      .subscribe((res) => {
        const fileURL = URL.createObjectURL(res);

        const anchorElement = document.createElement('a');
        document.body.appendChild(anchorElement);
        anchorElement.style.display = 'none';

        anchorElement.href = fileURL;
        anchorElement.download = documentData.localizedName + '';

        anchorElement.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(anchorElement);
      });
  }
}
