import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ExportOrderEntriesToCsvService } from "@spartacus/cart/import-export/components";
import { I18nModule, provideConfig, UrlModule } from "@spartacus/core";
import { rationalImportExportConfig } from "../../rational-default-import-export-config";
import { RationalExportOrderEntriesToCsvService } from "./rational-export-order-entries-to-csv.service";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        I18nModule,
        UrlModule,
        RouterModule,
    ],
    providers: [
        provideConfig(rationalImportExportConfig),
        { provide: ExportOrderEntriesToCsvService, useClass: RationalExportOrderEntriesToCsvService },
    ]
})
export class ExportOrderEntriesModule {
}