import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerModule } from '@spartacus/storefront';
import { RationalLoadingOverlayComponent } from './loading-overlay.component';

@NgModule({
  imports: [
    CommonModule,
    SpinnerModule,
  ],
  declarations: [RationalLoadingOverlayComponent],
  exports: [RationalLoadingOverlayComponent],
})
export class RationalLoadingOverlayModule { }
