export const addressForm = {
  title: 'Título',
  defaultTitle: 'Título',
  firstName: {
    label: 'Nome próprio',
    placeholder: 'Nome próprio',
  },
  lastName: {
    label: 'Apelido',
    placeholder: 'Apelido',
  },
  companyName: 'Nome da empresa',
  companyNamePlaceholder: 'Procurar o nome da empresa',
  address1: 'Endereço 1',
  address2: 'Endereço 2 (opcional)',
  country: 'País',
  city: {
    label: 'Cidade',
    placeholder: 'Cidade',
  },
  state: 'Estado',
  zipCode: {
    label: 'CEP',
    placeholder: 'Código postal',
  },
  phoneNumber: {
    label: 'Número de telefone',
    placeholder: '(55) 5555-5555',
  },
  saveAsDefault: 'Salvar como padrão',
  chooseAddress: 'Escolha o endereço',
  streetAddress: 'Endereço de rua',
  aptSuite: 'Apto, Suite',
  selectOne: 'Selecionar um...',
  setAsDefault: 'Definir como padrão',
  titleRequired: 'Título obrigatório.',
  userAddressAddSuccess: 'Novo endereço atualizado com sucesso!',
  userAddressUpdateSuccess: 'Endereço atualizado com sucesso!',
  userAddressDeleteSuccess: 'Endereço eliminado com êxito!',
  invalidAddress: 'Endereço inválido',
};

export const addressBook = {
  addNewShippingAddress: 'Adicionar um novo endereço de envio',
  editShippingAddress: 'Editar endereço de entrega',
  areYouSureToDeleteAddress:
    'Tem a certeza de que pretende eliminar este endereço?',
  addNewAddress: 'Adicionar novo endereço',
  addAddress: 'Adicionar endereço',
  updateAddress: 'Atualizar endereço',
  backToAddressList: 'Voltar à lista de endereços',
};

export const addressCard = {
  default: 'PADRÃO',
  selected: 'Selecionado',
  setAsDefault: 'Definir como padrão',
  shipTo: 'Enviar para',
  billTo: 'Faturar para',
};

export const addressSuggestion = {
  verifyYourAddress: 'Verificar seu endereço',
  ensureAccuracySuggestChange:
    'Para garantir a precisão da entrega, sugerimos a alteração selecionada abaixo.',
  chooseAddressToUse: 'Escolha o endereço que você gostaria de usar:',
  suggestedAddress: 'Endereço sugerido',
  enteredAddress: 'Endereço inserido',
  editAddress: 'Editar endereço',
  saveAddress: 'Salvar endereço',
};

export const address = {
  addressForm,
  addressBook,
  addressSuggestion,
  addressCard,
};
