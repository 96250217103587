import { Component,ViewEncapsulation } from '@angular/core';
import { SearchBoxComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-searchbox',
  templateUrl: './rational-search-box.component.html',
  styleUrls: ['./rational-search-box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalSearchBoxComponent extends SearchBoxComponent{

}
