export const  quotes = {
  created: 'L\'offerta è stata creata',
  title: 'Offerte',
  code: 'Codice',
  name: 'Nome',
  date: 'Data',
  state: 'Stato',
  statusDisplay_BUYER_APPROVED: 'Acquirente - Approvato',
  statusDisplay_BUYER_DRAFT: 'Acquirente - Bozza',
  statusDisplay_BUYER_SUBMITTED: 'Acquirente - Presentato',
  statusDisplay_BUYER_OFFER: 'Acquirente - Offerta',
  statusDisplay_BUYER_ACCEPTED: 'Acquirente - Accettato',
  statusDisplay_BUYER_REJECTED: 'Acquirente - Rifiutato',
  statusDisplay_BUYER_ORDERED: 'Acquirente - Ordinato',
  statusDisplay_SELLER_REQUEST: 'Venditore - Richiesta',
  statusDisplay_SELLER_DRAFT: 'Venditore - Bozza',
  statusDisplay_SELLER_SUBMITTED: 'Venditore - Inviato',
  statusDisplay_SELLERAPPROVER_DRAFT: 'Venditore - Bozza',
  statusDisplay_SELLERAPPROVER_PENDING: 'Venditore - In attesa di approvazione',
  statusDisplay_SELLERAPPROVER_REJECTED: 'Sellerapprover - Rifiutato',
  statusDisplay_SELLERAPPROVER_APPROVED: 'Venditore - Approvato',
  noQuotes: 'Nessun messaggio',
  entries: {
    code: 'Codice',
    name: 'Nome',
    amount: 'Quantità',
    price: 'Prezzo'
  },
  totalPriceWithoutTax: 'Prezzo totale senza tasse:'
}

