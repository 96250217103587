import { Component, ViewEncapsulation } from '@angular/core';
import { AnonymousConsentManagementBannerComponent } from '@spartacus/storefront';

@Component({
  selector: 'cx-anonymous-consent-management-banner',
  templateUrl: './rational-anonymous-consent-management-banner.component.html',
  styleUrls: ['./rational-anonymous-consent-management-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RationalAnonymousConsentManagementBannerComponent extends AnonymousConsentManagementBannerComponent{

}
