<div class="cx-order-items" *ngIf="order$ | async as order">
  <ng-container *ngIf="!showItemList || entries!.length > 0">
    <h2 class="cx-review-header">
      {{ 'checkoutMode.deliveryEntries' | cxTranslate }}
    </h2>

    <div class="cx-review-summary cx-review-shipping-summary">
      <!-- DELIVERY ADDRESS SECTION -->
      <div class="cx-review-summary-card-container">
        <div class="cx-review-summary-card cx-review-card-address">
          <cx-card
            [content]="getDeliveryAddressCard(order.deliveryAddress!) | async"
          ></cx-card>
        </div>
      </div>

      <!-- DELIVERY MODE SECTION -->
      <div class="cx-review-summary-card-container">
        <div class="cx-review-summary-card cx-review-card-address">
          <cx-card
            [content]="getDeliveryModeCard(order.deliveryMode!) | async"
          ></cx-card>
        </div>
      </div>
    </div>

  <!-- ADDITIONAL INFO SECTION -->
  <div class="orderAdditionalInfo" *ngIf="order$ | async as order">
    <div *ngIf="order.comment">
      <h4 class="orderComment"> {{ 'cartItems.comment' | cxTranslate }} </h4>
      <div [innerHTML]="order.comment"></div>
    </div>
    <div *ngIf="order.specialShippingInstructions">
      <h4 class="orderSpecialShippingInstructions"> {{ 'checkoutMode.specialShippingInstructions' | cxTranslate }}:</h4>
      <div [innerHTML]="order.specialShippingInstructions"></div>
    </div> 
    <div *ngIf="order.additionalContactEmail">
      <h4 class="orderAdditionalContactEmail"> {{ 'cartItems.additionalContactEmail' | cxTranslate }} </h4>
    <a> {{order.additionalContactEmail}}</a> 
    </div>
  </div>
  <!-- ADDITIONAL INFO SECTION -->
<!-- 
     CART ITEM SECTION
    <div *ngIf="showItemList" class="cx-review-cart-item">
      <ng-template
        [cxOutlet]="cartOutlets.CART_ITEM_LIST"
        [cxOutletContext]="{
          items: entries,
          readonly: true
        }"
      >
      </ng-template>
    </div> -->
  </ng-container>
</div>
