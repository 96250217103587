import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard, ConfigModule, I18nModule, provideConfig } from '@spartacus/core';
import { AmendOrderActionsModule, AmendOrderItemsModule, OrderAmendService } from '@spartacus/order/components';
import { FileUploadModule, FormErrorsModule, MessageComponentModule, } from '@spartacus/storefront';
import { RationalAmendOrderItemsModule } from '../amend-order/amend-order-items/rational-amend-order-items.module';
import { RationalReturnOrderComponent } from './return-order.component';
import { RationalOrderReturnService } from './rational-order-return.service';

import { OrderReturnRequestService } from '@spartacus/order/core';

@NgModule({
  imports: [
    FileUploadModule,
    ReactiveFormsModule,
    CommonModule,
    I18nModule,
    AmendOrderItemsModule,
    AmendOrderActionsModule,
    FormErrorsModule,
    MessageComponentModule,
    RationalAmendOrderItemsModule
  ],
  providers: [

    OrderReturnRequestService,
    { provide: OrderReturnRequestService, useExisting: RationalOrderReturnService },

    provideConfig({
      cmsComponents: {
        ReturnOrderComponent: {
          component: RationalReturnOrderComponent,
          guards: [AuthGuard],
          providers: [
            {
              provide: OrderAmendService,
              useExisting: RationalOrderReturnService,
            }
          ],
        },
      },
    }),
  ],
  declarations: [RationalReturnOrderComponent],
  exports: [RationalReturnOrderComponent],
})
export class RationalReturnOrderModule { }
