export const productDetails = {
    id: "物品编号",
    quantity: "数量",
    productDetails: "产品详情",
    specification: "技术特点",
    reviews: "产品评论",
    shipping: "运输",
    share: "分享到",
    showReviews: "显示评论",
    noReviews: "",
    productPrice: "产品价格",
    priceLoading: "产品价格已计算",
    productRelations: {
        productRelationsHeader: "兼容配件"
    },
    disclaimer: {
        title: '图片使用声明：',
        text: '网站上显示的图片仅供参考，并不代表产品的实际特征或供应范围。产品的详细规格、特性及供应范围请参阅产品的文本描述。'
    }
};

export const productList = {
    filterBy: {
        label: "筛选条件",
        action: "筛选条件"
    },
    appliedFilter: "已应用过滤器：",
    showLess: "显示更少...",
    showMore: "显示更多...",
    sortBy: "排序方式",
    backToTopBtn: "返回顶部",
    showMoreBtn: "显示更多",
    productSearchPagination: "产品搜索分页"
};

export const productFacetNavigation = {
    filterBy: {
        label: "筛选条件",
        action: "筛选条件"
    },
    appliedFilter: "已应用过滤器：",
    showLess: "显示更少...",
    showMore: "显示更多...",
    sortBy: "排序方式"
};

export const productSummary = {
    id: "产品编号",
    showReviews: "显示评论",
    share: "分享",
    newItemPrice: "新商品价格",
    discount: "显示的价格包含您的个人折扣，金额为 {{discount}}。",
    productSubstitutionAndProductExists: "产品{{product1}}被产品{{product2}}替代，因此您将被重定向到产品{{product2}}。",
    productSubstitutionAndProductDoesntExist: "产品 {{product1}} 被产品 {{product2}} 替代，但 {{product2}} 当前不可用"
};

export const productReview = {
    overallRating: "总体评价",
    reviewTitle: "评论标题",
    writeYourComments: "写下您的评论",
    rating: "评分",
    reviewerName: "评论者姓名（可选）",
    writeReview: "写评论",
    more: "显示更多评论",
    less: "显示更少评论",
    thankYouForReview: "感谢您的评论！请注意，评论可能需要审核后才能在此显示。"
};

export const addToCart = {
    itemsAddedToYourCart: "项目已添加到您的购物车",
    itemsIncrementedInYourCart: "此商品已在您的购物车中。数量已更新。",
    items: "项目",
    updatingCart: "更新购物车...",
    addToCart: "添加到购物车",
    viewCart: "查看购物车",
    proceedToCheckout: "继续结账",
    quantity: "数量",
    outOfStock: "无库存",
    inStock: "有库存",
    selectStyleAndSize: "选择款式和尺码查看库存",
    removeFromCart: "从购物车移除产品",
    closeModal: "关闭"
};

export const TabPanelContainer = {
    tabs: {
        ProductDetailsTabComponent: "产品详情",
        ProductSpecsTabComponent: "技术特点",
        ProductReviewsTabComponent: "产品评论",
        deliveryTab: "运输",
        ProductDownloadsTabComponent: "下载"
    }
};

export const addToWishList = {
    add: "添加到心愿单",
    remove: "从心愿单中删除",
    anonymous: "登录以添加到心愿单"
};

export const itemCounter = {
    removeOne: "删除一个",
    addOneMore: "再添加一个",
    quantity: "数量"
};

export const productView = {
    gridView: "选择以更改为网格视图",
    listView: "选择以更改为列表视图"
};

export const standardDelivery = {
    standardDeliveryRange: "可在 {{from}} 至 {{to}} 天内发货"
};

export const downloads = {
    downloads: {
        noDownloads: "此产品不可下载",
        documentTitle: "文件标题",
        toolbox: "工具箱中的更多内容",
        allDocuments: "全部",
        languagesDropdown: "语言",
        originalFileName: "文件名称",
        languages: "语言",
        readableTypeMime: "MIME 类型",
        fileSize: "文件大小",
        download: "下载"
    }
};

export const productReferences = {
    productReferences: {
        installationvariants: "设置变化",
        feedingsystems: "装载系统",
        groomingaccessoires: "护理产品",
        cookaccessoire: "烹饪配件",
        cookaccessoirespecial: "特殊配件",
        extractorhood: "抽油烟机",
        deviceinstallation: "设备安装"
    }
};

export const product = {
    productDetails,
    productList,
    productFacetNavigation,
    productSummary,
    productReview,
    addToCart,
    TabPanelContainer,
    addToWishList,
    itemCounter,
    productView,
};
