const visualPicking = {
  visualPickingProductList: {
    id: 'Artikelnummer'
  },
  visualPickingProductFilter: {
    input: {
      placeholder: 'Filtern Sie nach Name oder Artikelnummer'
    }
  }
}

export const epdVisualization = {
  epdVisualization: {
    visualPicking
  }
};
