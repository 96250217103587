import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import { CarouselModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { RationalProductRelationsComponent } from './component/rational-product-relations.component';
import { RationalMediaModule } from '../../cmscomponents/media/rational-media.module';



@NgModule({
  declarations: [
    RationalProductRelationsComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    RationalMediaModule,
    RouterModule,
    UrlModule,
    I18nModule
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductRelationsComponent: {
          component: RationalProductRelationsComponent,
        },
      },
    }),
  ],
})
export class RationalProductRelationsModule { }
