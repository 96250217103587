<form *ngIf="productCode" [formGroup]="addToCartForm" (submit)="addToCart()">
  <div class="quantity" *ngIf="showQuantity">
    <label>{{ 'addToCart.quantity' | cxTranslate }}</label>
    <div class="cx-counter-stock">
      <cx-item-counter

        [max]="maxQuantity"
        [control]="$any(addToCartForm).get('quantity')"
        ></cx-item-counter>

      <span class="info">
        <span *ngIf="salesUnit">{{salesUnit}}</span>
        <!-- <span *ngIf="showInventory$ | async">{{ getInventory() }}</span> -->
        <!-- {{
          hasStock
            ? ('addToCart.inStock' | cxTranslate)
            : ('addToCart.outOfStock' | cxTranslate)
        }} -->
        </span
      >
    </div>
  </div>
  <button 
    *ngIf="!hideAddToCartBtn"
    [ngClass]="(options && options.displayAddToCart) ? 'link cx-action-link': 'btn btn-primary btn-block'" type="submit"
    [disabled]="quantity <= 0 || quantity > maxQuantity"
  >
  {{ 'addToCart.addToCart' | cxTranslate }}
  </button>
</form>
