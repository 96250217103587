export const configurator = {
  configurator: {
    attribute: {
      id: 'Article Number'
    },
    addToCart: {
      selectVariant: 'Please select one of the variants',
      addMatchingVariant: '{{productCode}} product added to the cart'
    },
  }
}