import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FocusConfig,
  LaunchDialogService,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { RationalOrderDetailsService } from '../order-information.service';
import { TrackingDocument } from 'src/app/custom/model/tracking.document';
import { RationalOrderDocumentService } from '../../order-document/rational-order-document.service';

@Component({
  selector: 'cx-order-tracking-documents',
  templateUrl: './order-tracking-documents.component.html',
  styleUrls: ['./order-tracking-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderTrackingDocumentsDialogComponent implements OnInit {
  focusConfig: FocusConfig = {
    trap: true,
    block: true,
    autofocus: 'button.btn-primary',
    focusOnEscape: true,
  };


  @Input()
  shippingNumber: string | undefined;

  trackingDocuments$ : Observable<TrackingDocument[]> | undefined;

  @ViewChild('dialogElement') dialogElement: ElementRef | undefined;



  constructor(
    protected el: ElementRef,
    protected launchDialogService: LaunchDialogService,
    protected rationalOrderDetailsService: RationalOrderDetailsService,
    protected rationalOrderDocumentService : RationalOrderDocumentService
  ) {}
  ngOnInit(): void {
    console.log(this.shippingNumber);
    this.shippingNumber = this.shippingNumber;
    this.trackingDocuments$ = this.showTrackingDocuments(this.shippingNumber!);
  }

  showTrackingDocuments(
    shippingNumber: string
  ): Observable<TrackingDocument[]> {
    return this.rationalOrderDocumentService.getOrderTrackingDocuments(
      shippingNumber
    ) as Observable<TrackingDocument[]>; 
  }

}
